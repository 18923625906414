import resourceMessagingDictionaries from '../resourceDict';
import ngAuthSettings from '../environmentConfigs/environment.dev';

export default class resourceMessagingService {
    constructor(common, $cookies) {
        'ngInject';
        this.cultureTextEnglishUS = 'en-US';
        this.cultureTextFrenchCanadian = 'fr-CA';

        this.$q = common.$q;
        this.$cookies = $cookies;
        this.serviceBase = ngAuthSettings.apiServiceBaseUri;
        this.selectedCulture = this.cultureTextEnglishUS;
        this.stringsDictionary = [];
        this.pendingRequest = false;
        this.resourceMessagingDictionaries = resourceMessagingDictionaries;
        this.setCultureFromCookie(this.$cookies.getObject('forteWebCultureCode'));
    }

    getText(key) {
        return this.resourceMessagingDictionaries[this.selectedCulture][key];
    }

    setCultureFromCookie(cultureCookie) {
        switch (cultureCookie) {
            case '1':
                this.setCulture(this.cultureTextFrenchCanadian);
                break;
            default:
                this.setCulture(this.cultureTextEnglishUS);
                break;
        }
    }
    setCulture(culture) {
        this.selectedCulture = culture;
    }

    getLanguageOptions() {
        var languages = [];
        /*eslint-disable no-undef*/
        for (var currentCulture in resourceMessagingDictionaries) {
            if (resourceMessagingDictionaries.hasOwnProperty(currentCulture))
            {
                var name = this.getLanguageNameFromCulture(currentCulture);
                var isSelected = currentCulture === this.selectedCulture;
                languages.push({'culture':currentCulture,'name':name, 'isSelected': isSelected});
            }
        }
        /*eslint-enable no-undef*/
        return languages;
    }

    getLanguageNameFromCulture(culture) {
        switch (culture) {
            case this.cultureTextFrenchCanadian:
                return this.getText('frenchCanadian');
                break;
            default:
                return this.getText('english');
                break;
        }
    }

    isCultureEnglish() {
        var languages = this.getLanguageOptions();
        var i = 0;

        for (i; i < languages.length; ++i) {
            if (languages[i].isSelected === true) {
                break;
            }
        }
        if (languages[i].culture === 'fr-CA') {return false;}
        else {return true;}
    }
}
