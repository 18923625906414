
export default class programSettingsService {
    constructor(common, postService) {
        'ngInject';

        this.$q = common.$q;
        // this.serviceBase = ngAuthSettings.apiServiceBaseUri;

        this.httpRequestQueueCounter = 0;
        this.inputQueuePromise = null;
        this.somethingWaitingOnQueueToFinish = false;
        this.postService = postService;

    }

    getProgramSettingsProcessData() {
        var data = {};
        return this._requestPostEvent('GetProgramSettingsProcessData', data);
    }

    saveProgramSettingsData(data) {
        return this._requestPostEvent('SaveProgramSettingsData', data);
    }

    cultureChanged(data) {
        return this._requestPostEvent('CultureChanged', data);
    }

    resetDefaults() {
        var data = {};
        return this._requestPostEvent('ResetAllJobDefaults', data);
    }

    _requestPostEvent(programSettingsEvent, programSettingsData) {
        var path = 'api/ProgramSettings/';
        return this.postService.requestPostEvent(programSettingsEvent, programSettingsData, path);
    }

    _incrementQueueCounter() {
        this.httpRequestQueueCounter++;
    }

    _decrementQueueCounter() {
        this.httpRequestQueueCounter--;
        if (this.somethingWaitingOnQueueToFinish && this.httpRequestQueueCounter === 0) {
            this.somethingWaitingOnQueueToFinish = false;
            this.inputQueuePromise.resolve();
        }
    }

    getQueueCounter() {
        this.inputQueuePromise = this.$q.defer();
        if (this.httpRequestQueueCounter === 0) {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.somethingWaitingOnQueueToFinish = true;
        }
        return this.inputQueuePromise.promise;
    }

}
