export default class searchFilesController {
    constructor($scope, resourceMessagingService, $uibModalInstance, fileSystemService,
        searchResults, fileTree) {
        'ngInject';
        this.$scope = $scope;
        this.resourceMessagingService = resourceMessagingService;
        this.$uibModalInstance = $uibModalInstance;
        this.searchResults = searchResults;
        this.fileSystemService = fileSystemService;
        this.query = '';
        this.allTreeItems = this.fileSystemService.getFileTree().allTreeItems;
        this.displayResults = [];
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    close() {
        this.$uibModalInstance.close('cancel');
    }

    search() {
        this.fileSystemService.search(this.query).then(() => {
            this.displayResults = this.allTreeItems.filter(result => this.searchResults.results.includes(result.id));
        });
    }
    itemOpened(item) {
        //console.log('item opened:');
        let returnObj = {
            action: 'navigate',
            node: item
        };
        if (item.isFile === true) {
            //if it's a file, change the action to open.
            returnObj.action = 'open';
        }
        //close the modal and return.
        this.$uibModalInstance.close(returnObj);
    }

}
