export default class taperedEndsService {
    constructor(common, postService) {
        'ngInject';

        this.$q = common.$q();
        this.postService = postService;

    }

    getMetaData() {
        var data = {};
        return this._requestPostEvent('GetMetadata', data);
    }

    clearOneTaperedEndCut(isLeftEnd) {
        var data = {
            isLeftEnd: isLeftEnd,
            newValue: 0
        };
        return this._requestPostEvent('ClearOneTaperedEndCut', data);
    }

    modifyHeelHeight(isLeftEnd, newValue) {
        var data = {
            isLeftEnd: isLeftEnd,
            newValue: newValue
        };
        return this._requestPostEvent('ModifyHeelHeight', data);
    }

    modifyLength(isLeftEnd, newValue) {
        var data = {
            isLeftEnd: isLeftEnd,
            newValue: newValue
        };
        return this._requestPostEvent('ModifyLength', data);
    }

    modifySlope(isLeftEnd, newValue) {
        var data = {
            isLeftEnd: isLeftEnd,
            newValue: newValue
        };
        return this._requestPostEvent('ModifySlope', data);
    }

    modifyComment(isLeftEnd, newValue) {
        var data = {
            isLeftEnd: isLeftEnd,
            newValue: newValue
        };
        return this._requestPostEvent('ModifyComment', data);
    }

    modifyInputType(newValue) {
        var data = {
            newValue: newValue
        };
        return this._requestPostEvent('ModifyInputType', data);
    }


    _requestPostEvent(holeEvent, holeData) {
        var path = 'api/TaperedEnds/';
        return this.postService.requestPostEvent(holeEvent,holeData,path);
    }

}
// (function () {
//     'use strict';
//     var app = angular.module('app');
//     var serviceId = 'taperedEndsService';
//     app.factory(serviceId, ['$http', 'ngAuthSettings', 'common', 'resourceMessagingService','postService', taperedEndsService]);

//     function taperedEndsService($http, ngAuthSettings, common, resourceMessagingService, postService) {
//         var $q = common.$q;
//         var serviceBase = ngAuthSettings.apiServiceBaseUri;
//         var httpRequestQueueCounter = 0;
//         var inputQueuePromise;
//         var somethingWaitingOnQueueToFinish = false;

//         var service = {
//             getQueueCounter: getQueueCounter,
//             getMetaData: getMetaData,
//             clearOneTaperedEndCut: clearOneTaperedEndCut,
//             modifyLength: modifyLength,
//             modifyHeelHeight: modifyHeelHeight,
//             modifySlope: modifySlope,
//             modifyComment: modifyComment,
//             modifyInputType: modifyInputType
//         };


//         return service;

//         function getQueueCounter() {
//             inputQueuePromise = $q.defer();
//             if (httpRequestQueueCounter === 0)
//             {
//                 inputQueuePromise.resolve();
//                 return inputQueuePromise.promise;
//             }
//             else {
//                 somethingWaitingOnQueueToFinish = true;
//             }
//             return inputQueuePromise.promise;
//         }

//         function getMetaData() {
//             var data = {};
//             return _requestPostEvent('GetMetadata', data);
//         }

//         function clearOneTaperedEndCut(isLeftEnd) {
//             var data = {
//                 isLeftEnd: isLeftEnd,
//                 newValue: 0
//             };
//             return _requestPostEvent('ClearOneTaperedEndCut', data);
//         }

//         function modifyHeelHeight(isLeftEnd, newValue) {
//             var data = {
//                 isLeftEnd: isLeftEnd,
//                 newValue: newValue
//             };
//             return _requestPostEvent('ModifyHeelHeight', data);
//         }

//         function modifyLength(isLeftEnd, newValue) {
//             var data = {
//                 isLeftEnd: isLeftEnd,
//                 newValue: newValue
//             };
//             return _requestPostEvent('ModifyLength', data);
//         }

//         function modifySlope(isLeftEnd, newValue) {
//             var data = {
//                 isLeftEnd: isLeftEnd,
//                 newValue: newValue
//             };
//             return _requestPostEvent('ModifySlope', data);
//         }

//         function modifyComment(isLeftEnd, newValue) {
//             var data = {
//                 isLeftEnd: isLeftEnd,
//                 newValue: newValue
//             };
//             return _requestPostEvent('ModifyComment', data);
//         }

//         function modifyInputType(newValue) {
//             var data = {
//                 newValue: newValue
//             };
//             return _requestPostEvent('ModifyInputType', data);
//         }


//         function _requestPostEvent(holeEvent, holeData) {
//             var path = 'api/TaperedEnds/';
//             return postService.requestPostEvent(holeEvent,holeData,path);
//         }

//     }
// }());
