export default class heightAndSupportsController {
    constructor ($rootScope, $uibModal, $timeout, resourceMessagingService, allModelDataService, heightAndSupportsService, dirtyCheckService) {
        'ngInject';

        this.$rootScope = $rootScope;
        this.$uibModal = $uibModal;
        this.$timeout = $timeout;
        this.resourceMessagingService = resourceMessagingService;
        this.allModelDataService = allModelDataService;
        this.heightAndSupportsService = heightAndSupportsService;
        this.dirtyCheckService = dirtyCheckService;

        this.isPost = false;
        this.heightAndSupportsData = null;
        this.topPending;
        this.basePending;
        this.isLandscapModal = false;
        this.isNormalModeChart = true;

        this.activate();
    }

    activate() {
        this.heightAndSupportsService.getMetaData().then(
            (response) => {
                this.metaData = response;
                this.getHASData();
                this.setMemberData();
                this._refreshGraphic();
            }
        );
        this._refreshGraphic();
        this.resetIsLargeMobileDevice();
    }

    setMemberData() {
        var prData = this.allModelDataService.getPostResponseData();
        this.memberManagerData = prData.ApplicationData.MemberManagerData;
        var selectedContainerID = this.memberManagerData.SelectedContainerID;
        var selectedMemberID = this.memberManagerData.SelectedMemberID;
        this.memberData = this.memberManagerData.MemberContainers[selectedContainerID].Members[selectedMemberID];

        this.isPost = this.allModelDataService.getIsMemberFreeStandingPost();
        this.setPlateMaterialSelections(prData.MemberData.HeightAndSupportsData.BottomPostSupportType);
    }

    setPlateMaterialSelections(plateTypeValue) {
        if (this.isPost) {
            //Beam=0, Plate=15
            if (plateTypeValue === 15) {
                this.metaData.basePlateMaterialList = this.metaData.postPlateMaterialList;
            }
            else {
                this.metaData.basePlateMaterialList = this.metaData.postBeamMaterialList;
            }
        }
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    selectAllTextOnFocus($event) {
        $event.target.select();
    }

    modifyWallHeight() {
        this.heightAndSupportsService.modifyWallHeight(this.heightAndSupportsData.WallHeightFriendly).then(
            (response) => {
                this.getHASData();
            },
            (error) => {
            }
        );
    }

    modifyMaxULLength() {
        this.heightAndSupportsService.modifyMaxULLength(this.heightAndSupportsData.MaxULLengthFriendly).then(
            (response) => {
                this.getHASData();
            },
            (error) => {
            }
        );
    }

    modifyBracing(newValue) {
        this.heightAndSupportsService.modifyBracing(newValue)
            .then((response) => {
                //_refreshGraphic();
            }, (error) =>{

            }).finally(() => {
                this.getHASData();
            });
    }

    commentChanged(event, newComment) {
        if (this.dirtyCheckService.isDirty(event)) {
            this.heightAndSupportsService.modifyComment(newComment)
            .then(() => {
                this.getHASData();
            });
        }
    };

    modifyComment(holeComment) {
        var modalInstance = this.$uibModal.open({
            animation: true,
            templateUrl: 'modifyCommentModal.html',
            controller: 'holesModifyCommentController',
            controllerAs: 'modifyCommentCtrl',
            windowClass: 'connectorDialog',
            backdrop: 'static',
            size: 'md',
            resolve: {
                items: {
                    comment: holeComment
                }
            }
        });

        modalInstance.result.then((newComment) => {
            this.heightAndSupportsService.modifyComment(newComment).then(() => {
                this.getHASData();
            });
        });
    }

    getHASData() {
        this.heightAndSupportsService.getData().then((response) => {
            this.heightAndSupportsData = response;
        });
    }

    _refreshGraphic() {
        var prData = this.allModelDataService.getPostResponseData();
        this.$rootScope.$broadcast('refreshVerticalGraphic', prData.MemberData.GraphicOutputData);
    }

    modifyPlateSize(isTop, newValue) {
        this.togglePending(isTop);
        this.heightAndSupportsService.modifyPlateSize(isTop, newValue)
            .then((response) => {
                //_refreshGraphic();
            }, (error) => {

            }).finally(() => {
                this.getHASData();
                this.setPlateMaterialSelections(newValue);
                this.togglePending();
            });
    }

    modifyPlateMaterial(isTop, newValue) {
        this.togglePending(isTop);
        this.heightAndSupportsService.modifyPlateMaterial(isTop, newValue).then(
            (response) => {
                //_refreshGraphic();
            }, (error) => {

        }).finally(() => {
            this.getHASData();
            this.togglePending();
        });
    }

    toggleGraphicZoom() {
        this.isLandscapModal = true;
        this.isNormalModeChart = false;

        this._showGraphModal();
        //need to wait until the modal is shown because this zoomed graphic doesnt exist in the dom until the modal is shown.
        // $timeout(function() {
        //     _broadcastRefreshGraphic('refreshGraphic_zoomed');
        // },500,true);
    }

    _showGraphModal() {
        this.resetIsLargeMobileDevice();
        $('#vertGraphicToggleZoomModal').modal();
    }

    resetIsLargeMobileDevice() {
        this.isLargeMobile = false;
        if (document.documentElement.clientHeight > 811 && document.documentElement.clientHeight < 813) {
            this.isLargeMobile = true;
        }
    }

    togglePending(isTop) {
        if (isTop) {
            this.topPending = !this.topPending;
        } else {
            this.basePending = !this.basePending;
        }
    }

    keyDown(isPending, $event) {
        //ignore everything accept navigation input if the fidle is "pending"
        if (isPending) {
            if (!($event.keyCode === 9 || $event.keyCode === 16)) { //tab and shift keycodes
                $event.stopPropagation();
                $event.preventDefault();
                console.log('stopped: ' + $event.keyCode);
                return false;
            }
        }
    }
}

// (function () {
//     'use strict';
//     var app = angular.module('app');
//     var controllerId = 'heightAndSupportsController';

//     app.controller(controllerId, ['$rootScope', '$uibModal', '$timeout', 'resourceMessagingService', 'allModelDataService', 'heightAndSupportsService', 'dirtyCheckService',
//         function ($rootScope, $uibModal, $timeout, resourceMessagingService, allModelDataService, heightAndSupportsService, dirtyCheckService) {
//             //controller code goes here.

//             var vm = this;

//             vm.getText = getText;
//             vm.isGraphicVisible = false;
//             vm.modifyPlateSize = modifyPlateSize;
//             vm.modifyPlateMaterial = modifyPlateMaterial;
//             vm.modifyWallHeight = modifyWallHeight;
//             vm.modifyMaxULLength = modifyMaxULLength;
//             vm.modifyBracing = modifyBracing;
//             vm.commentChanged = commentChanged;
//             vm.modifyComment = modifyComment;
//             vm.keyDown = keyDown;
//             vm.toggleGraphicZoom = toggleGraphicZoom;
//             vm.selectAllTextOnFocus = selectAllTextOnFocus;

//             vm.isPost = false;
//             vm.heightAndSupportsData = null;

//             vm.topPending;
//             vm.basePending;

//             activate();

//             function activate() {
//                 heightAndSupportsService.getMetaData().then(
//                     function(response) {
//                         vm.metaData = response;
//                         getHASData();
//                         setMemberData();
//                         _refreshGraphic();
//                     }
//                 );
//                 _refreshGraphic();


//                 resetIsLargeMobileDevice();
//             }

//             function setMemberData() {
//                 var prData = allModelDataService.getPostResponseData();
//                 vm.memberManagerData = prData.ApplicationData.MemberManagerData;
//                 var selectedContainerID = vm.memberManagerData.SelectedContainerID;
//                 var selectedMemberID = vm.memberManagerData.SelectedMemberID;
//                 vm.memberData = vm.memberManagerData.MemberContainers[selectedContainerID].Members[selectedMemberID];

//                 vm.isPost = allModelDataService.getIsMemberFreeStandingPost();
//                 setPlateMaterialSelections(prData.MemberData.HeightAndSupportsData.BottomPlateSize);
//             }

//             function setPlateMaterialSelections(plateTypeValue) {
//                 if (vm.isPost) {
//                     //Beam=0, Plate=15
//                     if (plateTypeValue === 15) {
//                         vm.metaData.basePlateMaterialList = vm.metaData.postPlateMaterialList;
//                     }
//                     else {
//                         vm.metaData.basePlateMaterialList = vm.metaData.postBeamMaterialList;
//                     }
//                 }
//             }

//             function _refreshGraphic() {
//                 var prData = allModelDataService.getPostResponseData();
//                 $rootScope.$broadcast('refreshVerticalGraphic', prData.MemberData.GraphicOutputData);
//             }

//             function getText(key) {
//                 return resourceMessagingService.getText(key);
//             }

//             function selectAllTextOnFocus($event) {
//                 $event.target.select();
//             }

//             function modifyWallHeight() {
//                 heightAndSupportsService.modifyWallHeight(vm.heightAndSupportsData.WallHeightFriendly).then(
//                     function(response) {
//                         getHASData();
//                     },
//                     function(error) {

//                     }
//                 );
//             }

//             function modifyMaxULLength() {
//                 heightAndSupportsService.modifyMaxULLength(vm.heightAndSupportsData.MaxULLengthFriendly).then(
//                     function(response) {
//                         getHASData();
//                     },
//                     function(error) {
//                     }
//                 );
//             }

//             function modifyBracing(newValue) {
//                 heightAndSupportsService.modifyBracing(newValue)
//                     .then(function(response) {
//                         //_refreshGraphic();
//                     }, function(error) {

//                     }).finally(function() {
//                         getHASData();
//                     });
//             }

//             function commentChanged(event, newComment) {
//                 if (dirtyCheckService.isDirty(event)) {
//                     heightAndSupportsService.modifyComment(newComment)
//                     .then(function () {
//                         getHASData();
//                     });
//                 }
//             };

//             function modifyComment(holeComment) {
//                 var modalInstance = $uibModal.open({
//                     animation: true,
//                     templateUrl: 'modifyCommentModal.html',
//                     controller: 'holesModifyCommentController',
//                     windowClass: 'connectorDialog',
//                     backdrop: 'static',
//                     size: 'md',
//                     resolve: {
//                         items: {
//                             comment: holeComment
//                         }
//                     }
//                 });

//                 modalInstance.result.then(function (newComment) {
//                     heightAndSupportsService.modifyComment(newComment).then(function () {
//                         getHASData();
//                     });
//                 });
//             }

//             function getHASData() {
//                 heightAndSupportsService.getData().then(function (response) {
//                     vm.heightAndSupportsData = response;
//                 });
//             }

//             function _refreshGraphic() {
//                 var prData = allModelDataService.getPostResponseData();
//                 $rootScope.$broadcast('refreshVerticalGraphic', prData.MemberData.GraphicOutputData);
//             }

//             function modifyPlateSize(isTop, newValue) {
//                 togglePending(isTop);
//                 heightAndSupportsService.modifyPlateSize(isTop, newValue)
//                     .then(function(response) {
//                         //_refreshGraphic();
//                     }, function(error) {

//                     }).finally(function() {
//                         getHASData();
//                         setPlateMaterialSelections(newValue);
//                         togglePending();
//                     });
//             }

//             function modifyPlateMaterial(isTop, newValue) {
//                 togglePending(isTop);
//                 heightAndSupportsService.modifyPlateMaterial(isTop, newValue).then(
//                     function(response) {
//                         //_refreshGraphic();
//                     }, function(error) {

//                 }).finally(function() {
//                     getHASData();
//                     togglePending();

//                 });
//             }
//             function toggleGraphicZoom() {
//                 vm.isLandscapModal = true;
//                 vm.isNormalModeChart = false;

//                 _showGraphModal();
//                 //need to wait until the modal is shown because this zoomed graphic doesnt exist in the dom until the modal is shown.
//                 // $timeout(function() {
//                 //     _broadcastRefreshGraphic('refreshGraphic_zoomed');
//                 // },500,true);
//             }

//             function _showGraphModal() {
//                 resetIsLargeMobileDevice();
//                 $('#vertGraphicToggleZoomModal').modal();
//             }
//             function resetIsLargeMobileDevice() {
//                 vm.isLargeMobile = false;
//                 if (document.documentElement.clientHeight > 811 && document.documentElement.clientHeight < 813) {
//                     vm.isLargeMobile = true;
//                 }
//             }
//             function togglePending(isTop) {
//                 if (isTop) {
//                     vm.topPending = !vm.topPending;
//                 } else {
//                     vm.basePending = !vm.basePending;
//                 }
//             }

//             function keyDown(isPending, $event) {
//                 //ignore everything accept navigation input if the fidle is "pending"
//                 if (isPending) {
//                     if (!($event.keyCode === 9 || $event.keyCode === 16)) { //tab and shift keycodes
//                         $event.stopPropagation();
//                         $event.preventDefault();
//                         console.log('stopped: ' + $event.keyCode);
//                         return false;
//                     }
//                 }
//             }
//         }
//     ]);
// }());
