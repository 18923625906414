export default class fileMoveCopyService {
    constructor(common,  postService, resourceMessagingService, signalrService) {
        'ngInject';

        this.$q = common.$q();
        this.postService = postService;
        this.resourceMessagingService = resourceMessagingService;
        this.signalRService = signalrService;

        this.move = false;
        this.items = [];
        this.failedItems;
    }

    isMove() {
        return this.move;
    }

    itemCount() {
        return this.items.length;
    }

    initialize(_isMove, _items) {
        this.move = _isMove;
        this.items = _items;
    }

    getItems() {
        return this.items;
    }

    moveCopyComplete(locationId, receivedDataDate) {
        if (this.move) {
            //move it
            return this.moveFiles(locationId, this.getAllIds(), receivedDataDate);
        } else {
            //copy it
            return this.copyFile(locationId,this.items[0].id, receivedDataDate);
        }
    }

    getAllIds() {
        var toReturn = [];
        for (var i = 0; i < this.items.length; i++) {
            toReturn.push(this.items[i].id);
        }
        return toReturn;
    }

    moveFiles(folderToCopyToId, filesToMove, receivedDataDate) {
        var deferred = this.$q.defer();
        this.failedItems = 0;
        var commaDelimitedTreeItemIds = '';
        for (var i = 0; i < filesToMove.length; i++) {
            commaDelimitedTreeItemIds = commaDelimitedTreeItemIds + filesToMove[i] + ',';
        }
        commaDelimitedTreeItemIds = commaDelimitedTreeItemIds.substring(0, commaDelimitedTreeItemIds.length - 1);

        var data = {
            parentFolderId: folderToCopyToId.substring(1),
            treeItemIds: commaDelimitedTreeItemIds,
            receivedDataDate: receivedDataDate,
            sessionId: this.signalRService.getConnectionId()
        };
        this.showNotification(false);
        this._requestPostEvent('MoveItems', data).then(
            (response) => {    //eslint-disable-line no-loop-func
                this.showNotification(true);
                deferred.resolve();
            },
            (response) => {  //eslint-disable-line no-loop-func
                console.log('failed to move');
                deferred.reject(response);
            }
         );

        return deferred.promise;
    }
    copyFile(folderToCopyToId,fileToCopyId, receivedDataDate) {
        var deferred = this.$q.defer();
        this.failedItems = 0;
        var data = {
            folderToCopyToId: folderToCopyToId.substring(1),
            fileToCopyId: fileToCopyId.substring(1),
            receivedDataDate: receivedDataDate
        };
        this.showNotification(false);
        this._requestPostEvent('CopyFile', data)
            .then(
                (response) => {//eslint-disable-line no-loop-func
                    this.showNotification(true);
                    deferred.resolve(response);
                },
                (response) => {  //eslint-disable-line no-loop-func
                    this.showNotification(true);
                    this.failedItems += 1;
                    deferred.reject(response);
                }
             );

        return deferred.promise;
    }

    _requestPostEvent(event, data) {
        var path = 'api/FileSystem/';
        return this.postService.requestPostEvent(event,data,path);
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    showNotification(complete) {
        // var notificationText = '';
        // if (!complete) {
        //     if (isMove()) {
        //         notificationText = getText('moving');
        //     } else {
        //         notificationText = getText('copying');
        //     }
        //
        //     notificationText = notificationText + ' ' + items.length.toString() + ' ';
        //
        //     if (items.length > 1) {
        //         notificationText = notificationText + getText('items');
        //     } else {
        //         notificationText = notificationText + getText('item');
        //     }
        //     common.logger.clearToastr();
        //     common.logger.processingToast(notificationText);
        // } else {
        //     //if they all failed, show a warning
        //     if (failedItems === items.length) {
        //         if (isMove()) {
        //             notificationText = getText('failedToMove') + ' ';
        //         } else {
        //             notificationText = getText('failedToCopy') + ' ';
        //         }
        //         if (items.length > 1) {
        //             notificationText = notificationText + getText('items');
        //         } else {
        //             notificationText = notificationText + getText('item');
        //         }
        //         common.logger.clearToastr();
        //         common.logger.logWarning(notificationText, null, null, true);
        //     } else {
        //         //some passed.
        //         if (isMove()) {
        //             notificationText = getText('moved') + ' ';
        //         } else {
        //             notificationText = getText('copied') + ' ';
        //         }
        //
        //         var successfulCount = items.length - failedItems;
        //         notificationText = notificationText + ' ' + successfulCount.toString() + ' ';
        //
        //         if (successfulCount > 1) {
        //             notificationText = notificationText + getText('items');
        //         } else {
        //             notificationText = notificationText + getText('item');
        //         }
        //         common.logger.clearToastr();
        //         common.logger.logSuccess(notificationText, null, null, true);
        //     }
        // }
        //
        // return notificationText;
    }
}
// (function () {
//     'use strict';
//     var app = angular.module('app');
//     var serviceId = 'fileMoveCopyService';
//     app.factory(serviceId, ['$http', 'ngAuthSettings', 'common', 'allModelDataService', 'postService', 'resourceMessagingService', fileMoveCopyService]);

//     function fileMoveCopyService($http, ngAuthSettings, common, allModelDataService, postService, resourceMessagingService) {
//         var $q = common.$q;
//         var serviceBase = ngAuthSettings.apiServiceBaseUri;

//         var move = false;
//         var items = [];
//         var failedItems;
//         //this is the decleration of the public methods that will be accessible
//         var service = {
//             isMove: isMove,
//             itemCount: itemCount,
//             initialize: initialize,
//             getItems: getItems,
//             moveCopyComplete: moveCopyComplete
//         };

//         function isMove() {
//             return move;
//         }

//         function itemCount() {
//             return items.length;
//         }

//         function initialize(_isMove, _items) {
//             move = _isMove;
//             items = _items;
//         }

//         function getItems() {
//             return items;
//         }

//         function moveCopyComplete(locationId, receivedDataDate) {
//             if (move) {
//                 //move it
//                 return moveFiles(locationId, getAllIds(), receivedDataDate);
//             } else {
//                 //copy it
//                 return copyFile(locationId,items[0].id, receivedDataDate);
//             }
//         }

//         function getAllIds() {
//             var toReturn = [];
//             for (var i = 0; i < items.length; i++) {
//                 toReturn.push(items[i].id);
//             }
//             return toReturn;
//         }

//         function moveFiles(folderToCopyToId, filesToMove, receivedDataDate) {
//             var deferred = $q.defer();
//             failedItems = 0;
//             var commaDelimitedTreeItemIds = '';
//             for (var i = 0; i < filesToMove.length; i++) {
//                 commaDelimitedTreeItemIds = commaDelimitedTreeItemIds + filesToMove[i] + ',';
//             }
//             commaDelimitedTreeItemIds = commaDelimitedTreeItemIds.substring(0, commaDelimitedTreeItemIds.length - 1);

//             var data = {
//                 parentFolderId: folderToCopyToId,
//                 treeItemIds: commaDelimitedTreeItemIds,
//                 receivedDataDate: receivedDataDate
//             };
//             showNotification(false);
//             _requestPostEvent('MoveItems', data).then(
//                 function success(response) {    //eslint-disable-line no-loop-func
//                     showNotification(true);
//                     deferred.resolve();
//                 },
//                 function error(response) {  //eslint-disable-line no-loop-func
//                     console.log('failed to move');
//                     deferred.reject(response);
//                 }
//              );

//             return deferred.promise;
//         }
//         function copyFile(folderToCopyToId,fileToCopyId, receivedDataDate) {
//             var deferred = $q.defer();
//             failedItems = 0;
//             var data = {
//                 folderToCopyToId: folderToCopyToId,
//                 fileToCopyId: fileToCopyId,
//                 receivedDataDate: receivedDataDate
//             };
//             showNotification(false);
//             _requestPostEvent('CopyFile', data)
//                 .then(
//                      function success(response) {    //eslint-disable-line no-loop-func
//                          showNotification(true);
//                          deferred.resolve(response);
//                      },
//                      function error(response) {  //eslint-disable-line no-loop-func
//                          showNotification(true);
//                          failedItems += 1;
//                          deferred.reject(response);
//                      }
//                  );

//             return deferred.promise;
//         }

//         function _requestPostEvent(event, data) {
//             var path = 'api/FileSystem/';
//             return postService.requestPostEvent(event,data,path);
//         }

//         function getText(key) {
//             return resourceMessagingService.getText(key);
//         }

//         function showNotification(complete) {
//             // var notificationText = '';
//             // if (!complete) {
//             //     if (isMove()) {
//             //         notificationText = getText('moving');
//             //     } else {
//             //         notificationText = getText('copying');
//             //     }
//             //
//             //     notificationText = notificationText + ' ' + items.length.toString() + ' ';
//             //
//             //     if (items.length > 1) {
//             //         notificationText = notificationText + getText('items');
//             //     } else {
//             //         notificationText = notificationText + getText('item');
//             //     }
//             //     common.logger.clearToastr();
//             //     common.logger.processingToast(notificationText);
//             // } else {
//             //     //if they all failed, show a warning
//             //     if (failedItems === items.length) {
//             //         if (isMove()) {
//             //             notificationText = getText('failedToMove') + ' ';
//             //         } else {
//             //             notificationText = getText('failedToCopy') + ' ';
//             //         }
//             //         if (items.length > 1) {
//             //             notificationText = notificationText + getText('items');
//             //         } else {
//             //             notificationText = notificationText + getText('item');
//             //         }
//             //         common.logger.clearToastr();
//             //         common.logger.logWarning(notificationText, null, null, true);
//             //     } else {
//             //         //some passed.
//             //         if (isMove()) {
//             //             notificationText = getText('moved') + ' ';
//             //         } else {
//             //             notificationText = getText('copied') + ' ';
//             //         }
//             //
//             //         var successfulCount = items.length - failedItems;
//             //         notificationText = notificationText + ' ' + successfulCount.toString() + ' ';
//             //
//             //         if (successfulCount > 1) {
//             //             notificationText = notificationText + getText('items');
//             //         } else {
//             //             notificationText = notificationText + getText('item');
//             //         }
//             //         common.logger.clearToastr();
//             //         common.logger.logSuccess(notificationText, null, null, true);
//             //     }
//             // }
//             //
//             // return notificationText;
//         }


//         return service;
//     }
// }());
