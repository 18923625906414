export default class holesService {
    constructor(common, postService) {
        'ngInject';

        this.$q = common.$q();
        this.postService = postService;

        this.httpRequestQueueCounter = 0;
        this.inputQueuePromise = null;
        this.somethingWaitingOnQueueToFinish = false;
    }

    getQueueCounter() {
        this.inputQueuePromise = this.$q.defer();
        if (this.httpRequestQueueCounter === 0)
        {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.somethingWaitingOnQueueToFinish = true;
        }
        return this.inputQueuePromise.promise;
    }

    getHoleMetadata() {
        var data = {};
        return this._requestPostEvent('GetMetadata', data);
    }

    getHoleData() {
        // returns List(Of HoleSDO) from API
        var data = {};
        return this._requestPostEvent('GetHoleData', data);
    }

    addHole() {
        var data = {};
        return this._requestPostEvent('AddHole', data);
    }

    copyHole(holeIndex) {
        var data = { index: holeIndex };
        return this._requestPostEvent('CopyHole', data);
    }

    deleteHole(holeIndex) {
        var data = { index: holeIndex };
        return this._requestPostEvent('RemoveHole', data);
    }

    modifyHoleType(holeIndex, newType) {
        var data = {
            index: holeIndex,
            newValue: newType
        };
        return this._requestPostEvent('ModifyHoleType', data);
    }

    modifyHoleDirection(holeIndex, newDirection) {
        var data = {
            index: holeIndex,
            newValue: newDirection
        };
        return this._requestPostEvent('ModifyHoleDirection', data);
    }

    modifyMaxHoleSize(holeIndex, newMaxHoleSizeValue) {
        var data = {
            index: holeIndex,
            newValue: newMaxHoleSizeValue
        };
        return this._requestPostEvent('ModifyMaxHoleSize', data);
    }

    modifyHoleWidth(holeIndex, newWidth) {
        var data = {
            index: holeIndex,
            newValue: newWidth
        };
        return this._requestPostEvent('ModifyHoleWidth', data);
    }

    modifyHoleHeight(holeIndex, newHeight) {
        var data = {
            index: holeIndex,
            newValue: newHeight
        };
        return this._requestPostEvent('ModifyHoleHeight', data);
    }

    modifyHoleLocation(holeIndex, newLocation) {
        var data = {
            index: holeIndex,
            newValue: newLocation
        };
        return this._requestPostEvent('ModifyHoleLocation', data);
    }

    modifyHoleMidDepth(holeIndex, holeMidDepth) {
        var data = {
            index: holeIndex,
            newValue: holeMidDepth
        };
        return this._requestPostEvent('ModifyHoleMidDepth', data);
    }

    modifyHoleVerticalOffset(holeIndex, holeVertOffset) {
        var data = {
            index: holeIndex,
            newValue: holeVertOffset
        };
        return this._requestPostEvent('ModifyHoleVerticalOffset', data);
    }

    modifyHoleComment(holeIndex, holeComment) {
        var data = {
            index: holeIndex,
            newValue: holeComment
        };
        return this._requestPostEvent('ModifyHoleComment', data);
    }

    _requestPostEvent(holeEvent, holeData) {
        var path = 'api/Holes/';
        return this.postService.requestPostEvent(holeEvent,holeData,path);
    }

    _incrementQueueCounter() {
        this.httpRequestQueueCounter++;
    }

    _decrementQueueCounter() {
        this.httpRequestQueueCounter--;
        if (this.somethingWaitingOnQueueToFinish && this.httpRequestQueueCounter === 0) {
            this.somethingWaitingOnQueueToFinish = false;
            this.inputQueuePromise.resolve();
        }
    }

}
