export default class spinner {
    constructor($rootScope) {
        'ngInject';
        this.$rootScope = $rootScope;
        this.spinnerShowCount = 0;
    }
    spinnerHide() {
        console.log('spinnerHide:' + this.spinnerShowCount);
        if (this.spinnerShowCount > 0)
        {
            this.spinnerShowCount --;
            if (this.spinnerShowCount === 0) {
                this.spinnerToggle(false, ' ');
            }
        }
    }
    spinnerHideAll() {
        console.log('spinnerHideAll:' + this.spinnerShowCount);
        this.spinnerShowCount = 0;
        this.spinnerToggle(false, ' ');
    }
    spinnerShowIfNotShownAlready(message) {
        if (this.spinnerShowCount === 0) {
            this.spinnerShow(message);
        }
    }
    spinnerShow(message) {
        this.spinnerShowCount ++;
        console.log('spinnerShow:' + this.spinnerShowCount);
        this.spinnerToggle(true, message);
    }

    spinnerToggle(show, message) {
        var data = {
            show: show,
            message: message
        };
        this.$rootScope.$broadcast('spinner.toggle', data);
    }
}
// (function () {
//     'use strict';

//     // Must configure the common service and set its
//     // events via the commonConfigProvider

//     angular.module('common')
//         .factory('spinner', ['common', 'commonConfig', spinner]);

//     function spinner(common, commonConfig) {
//         var service = {
//             spinnerHide: spinnerHide,
//             spinnerShow: spinnerShow
//         };

//         var spinnerShowCount = 0;
//         return service;

//         function spinnerHide() {
//             if (spinnerShowCount > 0)
//             {
//                 spinnerShowCount --;
//                 if (spinnerShowCount === 0) {
//                     spinnerToggle(false, ' ');
//                 }
//             }
//         }

//         function spinnerShow(message) {
//             spinnerShowCount ++;
//             spinnerToggle(true, message);
//         }

//         function spinnerToggle(show, message) {
//             var data = {
//                 show: show,
//                 message: message
//             };
//             common.$broadcast(commonConfig.config.spinnerToggleEvent, data);
//         }
//     }
// }());
