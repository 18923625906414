export default class welcomeDialogController {
    constructor($scope, resourceMessagingService, $uibModalInstance, $sce, tourData) {
        'ngInject';
        this.$scope = $scope;
        this.resourceMessagingService = resourceMessagingService;
        this.$uibModalInstance = $uibModalInstance;
        this.title = tourData.title;
        this.showSubtext = tourData.showSubtext;
        this.videoURL = 'https://www.youtube.com/embed/' + tourData.videoURL + '?rel=0&amp;showinfo=0';
        this.$sce = $sce;
    }

    trustSrc(src) {
        return this.$sce.trustAsResourceUrl(src);
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    okClicked() {
        this.$uibModalInstance.dismiss('cancel');
    }
}
// (function () {
//     'use strict';
//     var app = angular.module('app');
//     app.controller('welcomeDialogController',
//         ['$scope', 'resourceMessagingService', '$uibModalInstance',
//             function ($scope, resourceMessagingService, $uibModalInstance) {
//                 var vm = this;

//                 vm.getText = getText;
//                 vm.okClicked = okClicked;

//                 function getText(key) {
//                     return resourceMessagingService.getText(key);
//                 }

//                 function okClicked() {
//                     $uibModalInstance.dismiss('cancel');
//                 }

//             }]);
// }());
