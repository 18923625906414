export default class materialAvailabilityService {
    constructor(common, allModelDataService, postService, materialAvailabilityStatus, forteCookieService) {
        'ngInject';

        this.$q = common.$q();

        this.allModelDataService = allModelDataService;
        this.postService = postService;
        this.materialAvailabilityStatus = materialAvailabilityStatus;
        this.forteCookieService = forteCookieService;
        this.seriesSpecialOrder = [];
        this.seriesUnavailable = [];
        this.productsSpecialOrder = [];
        this.productsNotAvailable = [];
        this.currentPostalCode = null;
    }

    getCurrentPostalCode() {
        //try to get it out of session because they must have refreshed the page since populating it.
        this._refreshDataFromSession();

        return this.currentPostalCode;
    }

    _refreshDataFromSession() {
        let sessionData = this.allModelDataService.getMaterialAvailabilityData();
        this.seriesSpecialOrder = sessionData.seriesSpecialOrder;
        this.seriesUnavailable = sessionData.seriesUnavailable;
        this.productsSpecialOrder = sessionData.productsSpecialOrder;
        this.productsNotAvailable = sessionData.productsNotAvailable;
        this.currentPostalCode = sessionData.currentPostalCode;
    }

    getMaterialAvailabilityAndPostalDataForMM() {
        let deferred = this.$q.defer();
        if (this.forteCookieService.getMaterialAvailabilityEnabled() === true) {
            this._getMaterialsAvailabilityDataAndUserZipCode().then(
                    (response) => {
                        deferred.resolve({
                            'postalCode': response.postalCode,
                            'seriesUnavailable': response.materialsAvailabilityData.SeriesNotAvailable,
                            'seriesSpecialOrder': response.materialsAvailabilityData.SeriesSpecialOrder,
                            'productsNotAvailable': response.materialsAvailabilityData.ProductsNotAvailable,
                            'productsSpecialOrder': response.materialsAvailabilityData.ProductsSpecialOrder});
                    }, (err, status) => {
                        //do something?
            });
        } else {
            //ma is disabled return empty lists.
            deferred.resolve({
                'postalCode': '00000',
                'seriesUnavailable': [],
                'seriesSpecialOrder': [],
                'productsNotAvailable': [],
                'productsSpecialOrder': []});
        }
        return deferred.promise;
    }

    getmaterialAvailabilityData() {
        let deferred = this.$q.defer();
        if (this.forteCookieService.getMaterialAvailabilityEnabled() === true) {
            let sessionData = this.allModelDataService.getMaterialAvailabilityData();
            this.seriesSpecialOrder = sessionData.seriesSpecialOrder;
            this.seriesUnavailable = sessionData.seriesUnavailable;
            this.productsSpecialOrder = sessionData.productsSpecialOrder;
            this.productsNotAvailable = sessionData.productsNotAvailable;
            this.currentPostalCode = sessionData.currentPostalCode;
            deferred.resolve({
                'seriesUnavailable': this.seriesUnavailable,
                'seriesSpecialOrder': this.seriesSpecialOrder,
                'productsNotAvailable': this.productsNotAvailable,
                'productsSpecialOrder': this.productsSpecialOrder});
        } else {
            deferred.resolve({
                'seriesUnavailable': [],
                'seriesSpecialOrder': [],
                'productsNotAvailable': [],
                'productsSpecialOrder': []});
        }
        return deferred.promise;
    }

    updatePostalCode(newPostalCode, preserveChanges = false) {
        let deferred = this.$q.defer();
        this._getDataForPostalCode(newPostalCode).then(
        (response) => {

            if (preserveChanges === true) {
                this.currentPostalCode = response.PostalCode;
                this.seriesSpecialOrder = response.SeriesSpecialOrder;
                this.seriesUnavailable = response.SeriesNotAvailable;
                this.productsSpecialOrder = response.ProductsSpecialOrder;
                this.productsNotAvailable = response.ProductsNotAvailable;

                let newMaData = {
                    'postalCode': this.currentPostalCode,
                    'seriesUnavailable': this.seriesUnavailable,
                    'seriesSpecialOrder': this.seriesSpecialOrder,
                    'productsNotAvailable': this.productsNotAvailable,
                    'productsNotAvailable': this.productsNotAvailable
                };
                this.allModelDataService.setMaterialAvailabilityData(newMaData);
            }
            deferred.resolve({
                'postalCode': response.PostalCode,
                'seriesUnavailable': response.SeriesNotAvailable,
                'seriesSpecialOrder': response.SeriesSpecialOrder,
                'productsNotAvailable': response.ProductsNotAvailable,
                'productsSpecialOrder': response.ProductsSpecialOrder
            });
        }, (err, status) => {
            //do something?
        });
        return deferred.promise;
    }
    getSeriesAvailabilityStatus(seriesId, productID) {
        this._refreshDataFromSession();
        if (this.seriesUnavailable.includes(seriesId) === true || Object.keys(this.productsNotAvailable).map(function(id) {
            return Number(id);
        }).includes(productID)) {
            return this.materialAvailabilityStatus.unavailable;
        } else if (this.seriesSpecialOrder.includes(seriesId) === true || Object.keys(this.productsSpecialOrder).map(function(id) {
            return Number(id);
        }).includes(productID)) {
            return this.materialAvailabilityStatus.specialOrder;
        } else {
            return this.materialAvailabilityStatus.available;
        }
    }

    _getMaterialsAvailabilityDataAndUserZipCode() {
        let path = 'api/MaterialsAvailability/GetMaterialsAvailabilityDataAndUserZipCode';
        let data = {};
        return this.postService.requestSimplePost(path, data);
    }
    _getDataForPostalCode(postalCode) {
        let path = 'api/MaterialsAvailability/GetMaterialsAvailabilityData';
        let data = {
            'postalCode': postalCode
        };
        return this.postService.requestSimplePost(path, data);
    }
}
