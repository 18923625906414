import angular from 'angular';
import $ from 'jquery';
import signalr from 'signalr';
import '../../../node_modules/izitoast/dist/css/iziToast.css';
import 'bootstrap';
import '../../../node_modules/bootstrap/dist/css/bootstrap.css';
import '../../../node_modules/animate.css/animate.css';
import uirouter from 'angular-ui-router';
import routes from './config.route';
import overrideTabs from './config/overrideTabs';
import idleProviderConfig from './config/idleProviderConfig';
import logProviderConfig from './config/logProviderConfig';
import ngClickDirectiveConfig from './config/ngClickDirectiveConfig';
import qProviderConfig from './config/qProviderConfig';
import exceptionHandlerConfig from './config/exceptionHandlerConfig';
import nganimate from 'angular-animate';
import ngidle from 'ng-idle';
import ngcookies from 'angular-cookies';
import ngfileupload from 'ng-file-upload';
import localstoragemodule from 'angular-local-storage';
import common from './common/common.factory';
import logger from './common/logger';
import datacontext from './services/datacontext';
import forteCookieService from './services/forteCookieService';
import uibootstrap from 'angular-ui-bootstrap';
import ngfilesaver from 'angular-file-saver';
import uitree from 'angular-ui-tree';
import mobileNavMenu from './mobileNavMenu';

import projectManagementService from './services/projectManagementService';
import resourceMessagingService from './services/ResourceMessagingService';
import postService from './services/postService';
import dataMiningService from './services/dataMiningService';
import authService from './services/authService';
import authInterceptorService from './services/authInterceptorService';
import fileSystemService from './services/fileSystemService';
import smartNavService from './services/smartNavService';
import memberManagementService from './services/memberManagementDataService';
import tabNavigationService from './services/tabNavigationService';
import memberPropertiesService from './services/memberPropertiesService';
import levelPropertiesService from './services/levelPropertiesService';
import reportService from './services/reportService';
import jobSummaryService from './services/jobSummaryService';
import dirtyCheckService from './services/dirtyCheckService';
import supportsService from './services/supportsService';
import spansService from './services/spansService';
import teamService from './services/teamService';
import fileMoveCopyService from './services/fileMoveCopyService';
import holesService from './services/holesService';
import notchedFlangeService from './services/notchedFlangeService';
import locationAnalysisService from './services/locationAnalysisService';
import jobSettingsService from './services/jobSettingsService';
import floorPerformanceService from './services/floorPerformanceService';
import productSelectionService from './services/productSelectionService';
import loadsHorizYService from './services/loadsHorizYService';
import roofGeometryService from './services/roofGeometryService';
import linkLoadService from './services/linkLoadService';
import materialsManagementService from './loggedIn/materials/materialsManagementService';
import programSettingsService from './services/programSettingsService';
import quickLoadsService from './services/quickLoadsService';
import solutionsService from './services/solutionsService';
import taperedEndsService from './services/taperedEndsService';
import connectorDialogService from './services/connectorDialogService';
import windLoadingService from './services/windLoadingService';
import seismicLoadingService from './services/seismicLoadingService';
import loadsVertZService from './services/loadsVertZService';
import loadsVertYService from './services/loadsVertYService';
import loadsHorizZService from './services/loadsHorizZService';
import loadsCllService from './services/loadsCllService';
import heightAndSupportsService from './services/heightAndSupportsService';
import materialAvailabilityService from './services/materialAvailabilityService';
import signalrService from './services/signalrService';

import headerTemplate from './loggedIn/masterPages/shell/header/header.html';
import tabNavigationTemplate from './loggedIn/masterPages/job/tabNavigation/tabNavigation.html';
import memberManagementTemplate from './loggedIn/masterPages/job/memberManagement/memberManagement.html';
import saveAsDefaultsTemplate from './loggedIn/job/defaultsButton/saveAsDefaultsButton.html';
import reportHorizontalTemplate from './loggedIn/job/report/report.html';
import welcomeTourTemplate from './loggedIn/masterPages/shell/welcomeTemplate.html';
import memberTeamFlyoutTemplate from './loggedIn/masterPages/job/memberManagement/memberTeamFlyout.html';

import spinner from './common/spinner';
import allModelDataService from './services/allModelDataService';
import notifier from './common/notifier';
import forteenumdefinitions from './app.constants';

import runTransitions from './run.transitions';
import runCloseOpenModals from './run.closeOpenModals';

import indexController from './index.controller';
import headerController from './loggedIn/masterPages/shell/header/headerController';
import loginController from './account/login/loginController';
import loadingProjectController from './loggedIn/masterPages/shell/loadingProject/loadingProjectController';
import shellController from './loggedIn/masterPages/shell/shell';
import timeoutWarningController from './loggedIn/masterPages/shell/timeoutWarningController';
import userProfileController from './loggedIn/modals/userProfile/userProfileController';
import welcomeDialogController from './loggedIn/masterPages/shell/welcomeDialogController';
import jobController from './loggedIn/masterPages/job/job';
import jobSummaryController from './loggedIn/job/jobSummary/jobSummaryController';
import memberManagementController from './loggedIn/masterPages/job/memberManagement/memberManagementController';
import tabNavigationController from './loggedIn/masterPages/job/tabNavigation/tabNavigationController';
import memberInfoController from './loggedIn/job/member/memberInfoController';
import spansAndSupportsController from './loggedIn/job/spansAndSupports/spansAndSupportsController';
import fileManagementController from './loggedIn/manage/fileManagement/fileManagementController';
import fileMoveCopyController from './loggedIn/manage/fileManagement/fileMoveCopyController';
import searchFilesController from './loggedIn/manage/fileManagement/searchFilesController';
import manageController from './loggedIn/masterPages/manage/manage';
import holesController from './loggedIn/job/holes/holesController';
import floorPerformanceController from './loggedIn/job/floorPerformance/floorPerformanceController';
import productSelectionController from './loggedIn/job/productSelection/productSelectionController';
import locationAnalysisController from './loggedIn/job/locationAnalysis/locationAnalysisController';
import saveAsDefaultButtonController from './loggedIn/job/defaultsButton/saveAsDefaultsButtonController';
import reportController from './loggedIn/job/report/reportController';
import settingsModalController from './loggedIn/modals/settings/settingsController';
import solutionsController from './loggedIn/job/solutions/solutionsController';
import linkedReactionController from './loggedIn/modals/linkedReaction/linkedReactionController';
import reportConnectorInformationController from './loggedIn/job/report/reportConnectorInformationController';
import roofGeometryController from './loggedIn/job/roofGeometry/roofGeometryController';
import connectorDialogController from './loggedIn/job/spansAndSupports/connectorDialogController';
import addTeamMemberController from './loggedIn/modals/addTeamMember/addTeamMember';
import createTeamController from './loggedIn/modals/createTeam/createTeam';
import simpleGetTextController from './loggedIn/modals/removeAdministrator/simpleGetTextController';
import materialsManagementController from './loggedIn/materials/materialsManagementController';
import teamManagementController from './loggedIn/manage/teamManagement/teamManagement';
import levelController from './loggedIn/job/level/levelController';
import importFileModalController from './loggedIn/manage/importFile/importFile';
import taperedEndsController from './loggedIn/job/taperedEnds/taperedEndsController';
import registerController from './account/register/registerController';
import forgotPasswordController from './account/forgotPassword/forgotPasswordController';
import emailSentController from './account/emailSent/emailSentController';
import resendEmailController from './account/resendEmail/resendEmailController';
import resetPasswordController from './account/resetPassword/resetPasswordController';
import emailConfirmController from './account/emailConfirm/emailConfirmController';
import jobSettingsController from './loggedIn/job/jobSettings/jobSettingsController';
import reportDamageController from './loggedIn/modals/reportDamage/reportDamageController';
import windLoadingController from './loggedIn/job/windLoading/windLoadingController';
import seismicLoadingController from './loggedIn/job/seismicLoading/seismicLoadingController';
import loadsVertController from './loggedIn/job/loadsVertical/loadsVert';
import loadsHorizController from './loggedIn/job/loads/loadsHorizController';
import heightAndSupportsController from './loggedIn/job/heightAndSupports/heightAndSupportsController';
import holesModifyCommentController from './loggedIn/job/holes/holesModifyCommentController';

import WYLegalFooterDirective from './directives/wyLegalFooter';
import RenameInPlaceDirective from './directives/renameInPlace';
import MyHorizontalGraphicDirective from './directives/myHorizGraphic';
import MyVerticalGraphicDirective from './directives/myVertGraphic';
import MyPendingColumnOverlay from './directives/myPendingColumnOverlay';
import HorizontalMemberReport from './directives/horizontalMemberReport';
import LanguageSelector from './directives/languageSelector';
import DragDrop from './directives/dragDrop';
import DragHelper from './directives/dragHelper';
import StrictEmailValidator from './directives/strictEmailValidator';
import PhoneInput from './directives/phoneInput';
import PhoneInputFilter from './directives/phoneInputFilter';
import CanadaZipInput from './directives/canadaZipInput';
import CanadaZipInputFilter from './directives/canadaZipInputFilter';
import HasScrollbar from './directives/hasScrollbar';

import '../../../node_modules/angular-ui-tree/dist/angular-ui-tree.min.css';
import '../../../node_modules/font-awesome/css/font-awesome.css';
import '../../../fortewebfont/style.css';
import '../../../node_modules/toastr/build/toastr.min.css';

import stylesLess from '../styles/styles.less';
import saveAsDefaultsButtonLess from './loggedIn/job/defaultsButton/saveAsDefaultsButton.less';
import floorPerformanceLess from './loggedIn/job/floorPerformance/floorPerformance.less';
import holesLess from './loggedIn/job/holes/holes.less';
import jobSettingsLess from './loggedIn/job/jobSettings/jobSettings.less';
import jobSummaryLess from './loggedIn/job/jobSummary/jobSummary.less';
import levelLess from './loggedIn/job/level/level.less';
import loadsLess from './loggedIn/job/loads/loads.less';
import locationAnalysisLess from './loggedIn/job/locationAnalysis/locationAnalysis.less';
import memberInfoLess from './loggedIn/job/member/memberInfo.less';
import productSelectionLess from './loggedIn/job/productSelection/productSelection.less';
import projectLess from './loggedIn/job/project/project.less';
import reportLess from './loggedIn/job/report/report.less';
import roofGeometryLess from './loggedIn/job/roofGeometry/roofGeometry.less';
import solutionsLess from './loggedIn/job/solutions/solutions.less';
import spansAndSupportsLess from './loggedIn/job/spansAndSupports/spansAndSupports.less';
import taperedEndsLess from './loggedIn/job/taperedEnds/taperedEnds.less';
import fileManagementLess from './loggedIn/manage/fileManagement/fileManagement.less';
import fileMoveCopyLess from './loggedIn/manage/fileManagement/fileMoveCopy.less';
import importFileLess from './loggedIn/manage/importFile/importFile.less';
import teamManagementLess from './loggedIn/manage/teamManagement/teamManagement.less';
import memberManagementLess from './loggedIn/masterPages/job/memberManagement/memberManagement.less';
import memberManagementMediaLess from './loggedIn/masterPages/job/memberManagement/memberManagementMedia.less';
import jobLess from './loggedIn/masterPages/job/job.less';
import manageLess from './loggedIn/masterPages/manage/manage.less';
import shellLess from './loggedIn/masterPages/shell/shell.less';
import headerLess from './loggedIn/masterPages/shell/header/header.less';
import materialsManagementLess from './loggedIn/materials/materialsManagement.less';
import addTeamMemberLess from './loggedIn/modals/addTeamMember/addTeamMember.less';
import createTeamLess from './loggedIn/modals/createTeam/createTeam.less';
import linkedReactionLess from './loggedIn/modals/linkedReaction/linkedReaction.less';
import settingsLess from './loggedIn/modals/settings/settings.less';
import userProfileLess from './loggedIn/modals/userProfile/userProfile.less';
import reportDamageLess from './loggedIn/modals/reportDamage/reportDamage.less';
import windLoadingLess from './loggedIn/job/windLoading/windLoading.less';
import seismicLoadingLess from './loggedIn/job/seismicLoading/seismicLoading.less';
import loadsVertLess from './loggedIn/job/loadsVertical/loadsVert.less';
import heightAndSupportsLess from './loggedIn/job/heightAndSupports/heightAndSupports.less';
import spansAndSupportsMediaLess from './loggedIn/job/spansAndSupports/spansAndSupportsMedia.less';
import shellMediaLess from './loggedIn/masterPages/shell/shellMedia.less';
import jobSummaryMediaLess from './loggedIn/job/jobSummary/jobSummaryMedia.less';
import forteWebFontLess from './forteWebFont.less';

let events = {
    controllerActivateSuccess: 'controller.activateSuccess',
    spinnerToggle: 'spinner.toggle',
};

let config = {
    appErrorPrefix: '[HT Error] ', //Configure the exceptionHandler decorator
    docTitle: 'ForteWEB: ',
    events: events,
    version: '1.0.0',
};

let userNameValue = {
    user: '',
};
let fileTree = {
    treeRoots: null,
    allTreeItems: null,
    timeDataWasQueried: null,
    rootItems: [],
};
let searchResults = {
    results: [],
};
const productAvailablilityStatus = {
    unknown: 0,
    available: 1,
    unavailable: 2,
    potentiallyAvailable: 3,
    specialOrder: 4,
};

export const app = angular
    .module('app', [
        uirouter,
        uibootstrap,
        ngfileupload,
        nganimate,
        ngidle,
        ngcookies,
        localstoragemodule,
        ngfilesaver,
        uitree,
        forteenumdefinitions,
    ])
    .config(routes)
    .config(overrideTabs)
    .config(idleProviderConfig)
    .config(logProviderConfig)
    .config(ngClickDirectiveConfig)
    .config(qProviderConfig)
    .config(exceptionHandlerConfig)
    .constant('materialAvailabilityStatus', productAvailablilityStatus)
    .filter('phoneInputFilter', PhoneInputFilter)
    .filter('canadaZipInputFilter', CanadaZipInputFilter)
    .value('config', config)
    .value('userNameValue', userNameValue)
    .value('fileTree', fileTree)
    .value('searchResults', searchResults)
    .service('logger', logger)
    .service('common', common)
    .service('spinner', spinner)
    .service('datacontext', datacontext)
    .service('notifier', notifier)
    .service('mobileNavMenu', mobileNavMenu)
    .service('resourceMessagingService', resourceMessagingService)
    .service('forteCookieService', forteCookieService)
    .service('smartNavService', smartNavService)
    .service('postService', postService)
    .service('dataMiningService', dataMiningService)
    .service('projectManagementService', projectManagementService)
    .service('allModelDataService', allModelDataService)
    .service('authService', authService)
    .service('fileSystemService', fileSystemService)
    .service('authInterceptorService', authInterceptorService)
    .service('memberManagementService', memberManagementService)
    .service('tabNavigationService', tabNavigationService)
    .service('memberPropertiesService', memberPropertiesService)
    .service('levelPropertiesService', levelPropertiesService)
    .service('reportService', reportService)
    .service('jobSummaryService', jobSummaryService)
    .service('dirtyCheckService', dirtyCheckService)
    .service('supportsService', supportsService)
    .service('spansService', spansService)
    .service('teamService', teamService)
    .service('fileMoveCopyService', fileMoveCopyService)
    .service('holesService', holesService)
    .service('floorPerformanceService', floorPerformanceService)
    .service('productSelectionService', productSelectionService)
    .service('notchedFlangeService', notchedFlangeService)
    .service('locationAnalysisService', locationAnalysisService)
    .service('jobSettingsService', jobSettingsService)
    .service('loadsHorizYService', loadsHorizYService)
    .service('roofGeometryService', roofGeometryService)
    .service('linkLoadService', linkLoadService)
    .service('materialsManagementService', materialsManagementService)
    .service('programSettingsService', programSettingsService)
    .service('quickLoadsService', quickLoadsService)
    .service('solutionsService', solutionsService)
    .service('taperedEndsService', taperedEndsService)
    .service('connectorDialogService', connectorDialogService)
    .service('windLoadingService', windLoadingService)
    .service('seismicLoadingService', seismicLoadingService)
    .service('loadsVertZService', loadsVertZService)
    .service('loadsVertYService', loadsVertYService)
    .service('loadsHorizZService', loadsHorizZService)
    .service('loadsCllService', loadsCllService)
    .service('heightAndSupportsService', heightAndSupportsService)
    .service('materialAvailabilityService', materialAvailabilityService)
    .service('signalrService', signalrService)
    .directive('wyLegalFooter', () => new WYLegalFooterDirective())
    .directive('renameInPlace', () => new RenameInPlaceDirective())
    .directive('myHorizGraphic', () => new MyHorizontalGraphicDirective())
    .directive('myVertGraphic', () => new MyVerticalGraphicDirective())
    .directive('myPendingColumnOverlay', () => new MyPendingColumnOverlay())
    .directive('horizontalMemberReport', () => new HorizontalMemberReport())
    .directive('languageSelector', () => new LanguageSelector())
    .directive('dragDrop', () => new DragDrop())
    .directive('strictEmailValidator', () => new StrictEmailValidator())
    .directive('phoneInput', () => new PhoneInput())
    .directive('canadaZipInput', () => new CanadaZipInput())
    .directive('hasScrollbar', () => new HasScrollbar())
    .run(runTransitions)
    .run(runCloseOpenModals)
    .controller('indexController', indexController)
    .controller('loginController', loginController)
    .controller('shellController', shellController)
    .controller('jobController', jobController)
    .controller('headerController', headerController)
    .controller('timeoutWarningController', timeoutWarningController)
    .controller('userProfileController', userProfileController)
    .controller('welcomeDialogController', welcomeDialogController)
    .controller('jobSummaryController', jobSummaryController)
    .controller('memberManagementController', memberManagementController)
    .controller('tabNavigationController', tabNavigationController)
    .controller('memberInfoController', memberInfoController)
    .controller('spansAndSupportsController', spansAndSupportsController)
    .controller('fileManagementController', fileManagementController)
    .controller('fileMoveCopyController', fileMoveCopyController)
    .controller('searchFilesController', searchFilesController)
    .controller('manageController', manageController)
    .controller('holesController', holesController)
    .controller('floorPerformanceController', floorPerformanceController)
    .controller('productSelectionController', productSelectionController)
    .controller('locationAnalysisController', locationAnalysisController)
    .controller('saveAsDefaultButtonController', saveAsDefaultButtonController)
    .controller('reportController', reportController)
    .controller('loadingProjectController', loadingProjectController)
    .controller('addTeamMemberController', addTeamMemberController)
    .controller('settingsModalController', settingsModalController)
    .controller('solutionsController', solutionsController)
    .controller('linkedReactionController', linkedReactionController)
    .controller(
        'reportConnectorInformationController',
        reportConnectorInformationController
    )
    .controller('roofGeometryController', roofGeometryController)
    .controller('connectorDialogController', connectorDialogController)
    .controller('createTeamController', createTeamController)
    .controller('simpleGetTextController', simpleGetTextController)
    .controller('materialsManagementController', materialsManagementController)
    .controller('levelController', levelController)
    .controller('teamManagementController', teamManagementController)
    .controller('importFileModalController', importFileModalController)
    .controller('taperedEndsController', taperedEndsController)
    .controller('registerController', registerController)
    .controller('forgotPasswordController', forgotPasswordController)
    .controller('emailSentController', emailSentController)
    .controller('resendEmailController', resendEmailController)
    .controller('resetPasswordController', resetPasswordController)
    .controller('emailConfirmController', emailConfirmController)
    .controller('jobSettingsController', jobSettingsController)
    .controller('reportDamageController', reportDamageController)
    .controller('windLoadingController', windLoadingController)
    .controller('seismicLoadingController', seismicLoadingController)
    .controller('loadsVertController', loadsVertController)
    .controller('loadsHorizController', loadsHorizController)
    .controller('heightAndSupportsController', heightAndSupportsController)
    .controller('holesModifyCommentController', holesModifyCommentController);
