export default class floorPerformanceService {
    constructor(common, allModelDataService, postService) {
        'ngInject';

        this.$q = common.$q();
        this.allModelDataService = allModelDataService;
        this.postService = postService;

        this.httpRequestQueueCounter = 0;
        this.inputQueuePromise = null;
        this.somethingWaitingOnQueueToFinish = false;
    }

    getMetadata() {
        var data = {};
        return this._requestPostEvent('GetMetadata', data);
    }

    getFloorPerformanceSDO() {
        var prData = this.allModelDataService.getPostResponseData();
        return prData.MemberData.FloorPerformanceData;
    }

    modifyTJProRatingAny(data) {
        return this._requestPostEvent('ModifyTJProRatingAny', data);
    }

    modifyTJProRatingValue(data) {
        return this._requestPostEvent('ModifyTJProRatingValue', data);
    }

    modifyStrapping(data) {
        return this._requestPostEvent('ModifyStrapping', data);
    }

    modifyCeiling(data) {
        return this._requestPostEvent('ModifyCeiling', data);
    }

    modifyDeckingMaterial(data) {
        return this._requestPostEvent('ModifyDeckingMaterial', data);
    }

    modifyBridgingBlocking(data) {
        return this._requestPostEvent('ModifyBridgingBlocking', data);
    }

    modifyPerpendicular(data) {
        return this._requestPostEvent('ModifyPerpendicular', data);
    }

    modifyPourFlooringOverlay(data) {
        return this._requestPostEvent('ModifyPourFlooringOverlay', data);
    }

    modifySheathingAttachment(data) {
        return this._requestPostEvent('ModifySheathingAttachment', data);
    }

    saveFloorPerformanceAsDefaults() {
        var currentPostResponseData = this.allModelDataService.getPostResponseData();
        return this._requestPostEvent('SaveFloorPerformanceAsDefaults', currentPostResponseData);
    }

    _requestPostEvent(floorPerformanceEvent, floorPerformanceData) {
        var path = 'api/FloorPerformance/';
        return this.postService.requestPostEvent(floorPerformanceEvent, floorPerformanceData, path);
    }

    _incrementQueueCounter() {
        this.httpRequestQueueCounter++;
    }

    _decrementQueueCounter() {
        this.httpRequestQueueCounter--;
        if (this.somethingWaitingOnQueueToFinish && this.httpRequestQueueCounter === 0) {
            this.somethingWaitingOnQueueToFinish = false;
            this.inputQueuePromise.resolve();
        }
    }

    getQueueCounter() {
        this.inputQueuePromise = this.$q.defer();
        if (this.httpRequestQueueCounter === 0) {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.somethingWaitingOnQueueToFinish = true;
        }
        return this.inputQueuePromise.promise;
    }
}
// (function () {
//     'use strict';
//     var app = angular.module('app');
//     var serviceId = 'floorPerformanceService';
//     app.factory(serviceId, ['$http', 'ngAuthSettings', 'common', 'allModelDataService', 'postService', floorPerformanceService]);

//     function floorPerformanceService($http, ngAuthSettings, common, allModelDataService, postService) {
//         var $q = common.$q;
//         var serviceBase = ngAuthSettings.apiServiceBaseUri;
//         var httpRequestQueueCounter = 0;
//         var inputQueuePromise;
//         var somethingWaitingOnQueueToFinish = false;
//         //public methods declarations
//         var service = {
//             getQueueCounter: getQueueCounter,
//             getMetadata: getMetadata,
//             getFloorPerformanceSDO: getFloorPerformanceSDO,
//             modifyTJProRatingAny: modifyTJProRatingAny,
//             modifyTJProRatingValue: modifyTJProRatingValue,
//             modifyStrapping: modifyStrapping,
//             modifyCeiling: modifyCeiling,
//             modifyDeckingMaterial: modifyDeckingMaterial,
//             modifyBridgingBlocking: modifyBridgingBlocking,
//             modifyPerpendicular: modifyPerpendicular,
//             modifyPourFlooringOverlay: modifyPourFlooringOverlay,
//             modifySheathingAttachment: modifySheathingAttachment,
//             saveFloorPerformanceAsDefaults: saveFloorPerformanceAsDefaults
//         };

//         function getMetadata() {
//             var data = {};
//             return _requestPostEvent('GetMetadata', data);
//         }

//         function getFloorPerformanceSDO() {
//             var prData = allModelDataService.getPostResponseData();
//             return prData.MemberData.FloorPerformanceData;
//         }

//         function modifyTJProRatingAny(data) {
//             return _requestPostEvent('ModifyTJProRatingAny', data);
//         }

//         function modifyTJProRatingValue(data) {
//             return _requestPostEvent('ModifyTJProRatingValue', data);
//         }

//         function modifyStrapping(data) {
//             return _requestPostEvent('ModifyStrapping', data);
//         }

//         function modifyCeiling(data) {
//             return _requestPostEvent('ModifyCeiling', data);
//         }

//         function modifyDeckingMaterial(data) {
//             return _requestPostEvent('ModifyDeckingMaterial', data);
//         }

//         function modifyBridgingBlocking(data) {
//             return _requestPostEvent('ModifyBridgingBlocking', data);
//         }

//         function modifyPerpendicular(data) {
//             return _requestPostEvent('ModifyPerpendicular', data);
//         }

//         function modifyPourFlooringOverlay(data) {
//             return _requestPostEvent('ModifyPourFlooringOverlay', data);
//         }

//         function modifySheathingAttachment(data) {
//             return _requestPostEvent('ModifySheathingAttachment', data);
//         }

//         function saveFloorPerformanceAsDefaults() {
//             var currentPostResponseData = allModelDataService.getPostResponseData();
//             return _requestPostEvent('SaveFloorPerformanceAsDefaults', currentPostResponseData);
//         }

//         function _requestPostEvent(floorPerformanceEvent, floorPerformanceData) {
//             var path = 'api/FloorPerformance/';
//             return postService.requestPostEvent(floorPerformanceEvent, floorPerformanceData, path);
//         }

//         function _incrementQueueCounter() {
//             httpRequestQueueCounter++;
//         }

//         function _decrementQueueCounter() {
//             httpRequestQueueCounter--;
//             if (somethingWaitingOnQueueToFinish && httpRequestQueueCounter === 0) {
//                 somethingWaitingOnQueueToFinish = false;
//                 inputQueuePromise.resolve();
//             }
//         }

//         function getQueueCounter() {
//             inputQueuePromise = $q.defer();
//             if (httpRequestQueueCounter === 0) {
//                 inputQueuePromise.resolve();
//                 return inputQueuePromise.promise;
//             }
//             else {
//                 somethingWaitingOnQueueToFinish = true;
//             }
//             return inputQueuePromise.promise;
//         }

//         return service;
//     }
// }());
