export default class memberManagementController {
    constructor($scope, $state, common, spinner, memberManagementService,
        resourceMessagingService, smartNavService, tabNavigationService, memberPropertiesService,
        fileSystemService, levelPropertiesService, allModelDataService, $timeout, notifier, reportService, mobileNavMenu) {
        'ngInject';

        this.$scope = $scope;
        this.$state = $state;
        this.common = common;
        this.spinner = spinner;
        this.memberManagementService = memberManagementService;
        this.resourceMessagingService = resourceMessagingService;
        this.smartNavService = smartNavService;
        this.tabNavigationService = tabNavigationService;
        this.memberPropertiesService = memberPropertiesService;
        this.fileSystemService = fileSystemService;
        this.levelPropertiesService = levelPropertiesService;
        this.allModelDataService = allModelDataService;
        this.$timeout = $timeout;
        this.notifier = notifier;
        this.reportService = reportService;
        this.mobileNavMenu = mobileNavMenu;


        this.selectNewlyAddedChildNode = false;
        this.selectNewlyAddedLevelNode = false;
        this.selectFirstLevel = false;
        this.selectedNodeChangedInCode = false;
        this.deletedLevelIndex = null;
        this.selectJobTreeJobNode = false;
        this.selectMovedMember = false;
        this.selectOnDeleteMember = false;
        this.selectOnCopyMember = false;
        this.allowMove = false;
        this.itemMovingItemId = null;
        this.itemMovingParentId = null;
        this.itemFromParentId = null;
        this.isCopying = false;
        this.isAddingMember = false;
        this.isAddingLevel = false;

        this.addMemberIsVisible = false;
        this.jobTreeData = null;
        this.duplicateMemberDisabled = true;
        this.deleteDisabled = true;
        this.folderSelectedBeforeAddClicked = null;
        this.folderThatMemberWasAddedTo = null;
        this.folderThatMemberWasDeletedFrom = null;
        this.folderThatMemberWasCopiedTo = null;
        this.folderThatLevelWasAddedTo = null;
        this.indexOfLevelThatContainsAddedMember = null;
        this.jobTreeStepContent = 'jobSettings_jobTreeContent';
        this.fileSContent = 'basic_fileContent';

        this.addMemberContent = this.getText('jobSettings_addMemberContent') + '<ul><li>' + this.getText('jobSettings_addMemberAction') + '</li></ul>';
        this.jobTreeShortenTitle = 'jobSettings_jobTreeTitle';
        this.jobTreeShortenContent = this.getText('jobSettings_jobTreeSContent') + '<ul><li>' + this.getText('chooseNext') + '</li></ul>';
        this.stepDirection = '';
        this.selectedNode = null;
        this.isRenaming = false;
        this.jobTreeRefreshing = false;

        this.renameComplete = this.renameComplete;

        this.treeOptions = {
            dropped: (e) => {
                var droppedMemberSDO = e.source.nodeScope.$modelValue;
                var originalParentLevelId = droppedMemberSDO.id.substring(0, droppedMemberSDO.id.indexOf('m')).substring(2);
                var destinationParentLevelId = e.dest.nodesScope.$nodeScope.$modelValue.id.substring(2);
                var newPosition = e.dest.index;
                var originalPostion = e.source.index;
                // //alert('source: ' + sourceId + ' dest: ' + destId + " index: " + destIndex);
                // //after this digest cycle, set the local storage data to what's in the updated VM
                // //alert(do drop?)
                this.moveMember(originalParentLevelId,
                    destinationParentLevelId,
                    originalPostion,
                    newPosition,
                    droppedMemberSDO);
                return true;
            },
            beforeDrag: (e) => {
                if (this.isRenaming === false) {
                    return true;
                }
                return false;
            }
        };

        //////////scope event handlers
        this.handleProjectReady = this.$scope.$on('projectReady', (event, args) => {
            this.selectJobTreeJobNode = true;
            var initialCall = false;
            if ($('#jobTreeContainer').children().length === 0) {
                initialCall = true;
            }
            $timeout(() => {
                this.refreshJobTree(initialCall, args.forgetState);
            }, 0, false);
        });
        this.handleRefreshJobTree = this.$scope.$on('refreshJobTree', (event) => {
            this.selectJobTreeJobNode = false;
            this.refreshJobTree(false);
        });
        this.handleRedrawMemberStatusColors = this.$scope.$on('redrawMemberStatusColors', (event) => {
            this.redrawMemberStatusColors();
        });
        this.handleUpdateNodeName = this.$scope.$on('updateNodeName', (event, newName, nodeId) => {
            //get the current node.
            var currentNode = $('#jobTreeContainer').jstree()
                .get_node(nodeId);
            //rename the node
            $('#jobTreeContainer').jstree('rename_node', currentNode, newName);
        });
        this.handleLocationChangeStart = this.$scope.$on('$locationChangeStart',
            (event, next, current) => {
                if (this.addMemberIsVisible) {
                    this.addMemberIsVisible = false;
                }
            }
        );
        this.handleSelectNodeFromJobSummary = this.$scope.$on('selectNodeFromJobSummary', (event, args) => {
            var levelId = parseInt(args.nodeId.charAt(2));
            var node = this._getNodeData(args.nodeId);
            if (node !== undefined) {
                $timeout(() => {
                    var levelHtmlNodes = document.getElementsByClassName('level');
                    this.jobTreeNodeSelected(node);

                    $timeout(() => {
                        angular.element(levelHtmlNodes[levelId]).scope().collapsed = false;
                    }, 0);
                }, 0, false)
                .then(() => {
                    if (!args.isDesignable) {
                        this.tabNavigationService._executeTabSwitch('application.job.productSelection', 7);
                    } else {
                        this.tabNavigationService._executeTabSwitch('application.job.report', 9);
                    }
                });
            }
        });
        this.handleAddMember = this.$scope.$on('handleAddMember', (event, memberTypeName) => {
            switch (memberTypeName) {
                case 'floorJoist':
                    this.addFloorJoist();
                    break;
                case 'floorDropBeam':
                    this.addFloorDropBeam();
                    break;
                case 'floorFlushBeam':
                    this.addFloorFlushBeam();
                    break;
                case 'wallHeader':
                    this.addWallHeader();
                    break;
                case 'windSill':
                    this.addWallWindSill();
                    break;
                case 'wallStud':
                    this.addWallStud();
                    break;
                case 'wallColumn':
                    this.addWallColumn();
                    break;
                case 'freeStandingPost':
                    this.addFreeStandingPost();
                    break;
                case 'roofJoist':
                    this.addRoofJoist();
                    break;
                case 'roofDropBeam':
                    this.addRoofDropBeam();
                    break;
                case 'roofFlushBeam':
                    this.addRoofFlushBeam();
                    break;
                case 'roofHipBeam':
                    this.addRoofHipBeam();
                    break;
                default:
                    break;
            }
        });
        this.$scope.$on('$destroy', () => {
            this.handleProjectReady();
            this.handleAddMember();
            this.handleRefreshJobTree();
            this.handleUpdateNodeName();
            this.handleLocationChangeStart();
            this.handleRedrawMemberStatusColors();
            //handleMoveMember();
        });

        this.$scope.$watch(() => {
            return this.selectedNode;
        }, (newValue, oldValue, scope) => {
            if (newValue) {
                this.allModelDataService.setSelectedJobTreeNode(newValue);
            }
        }, true);

        this.$scope.$watch(() => {
            return this.jobTreeData;
        }, (newValue, oldValue, scope) => {
            if (newValue) {
                this.allModelDataService.setJobTreeData(newValue);
            }
        }, true);

        this.activate();
    }
    //////////end scope event handlers

    activate() {

    }

    _getNodeData(nodeId) {
        var allLevels = this.jobTreeData[0].children;
        for (var levelIndex = 0; levelIndex < allLevels.length; levelIndex++) {
            for (var memberIndex = 0; memberIndex < allLevels[levelIndex].children.length; memberIndex++) {
                if (allLevels[levelIndex].children[memberIndex].id === nodeId) {
                    return allLevels[levelIndex].children[memberIndex];
                }
            }
        }
    }

    isBuildingCodeNBCC() {
        var prData = this.allModelDataService.getPostResponseData();
        var buildingCodeEnum = prData.ApplicationData.JobSettingsData.BuildingCodeForteWEB;
        if (buildingCodeEnum === 18 || buildingCodeEnum === 23 || buildingCodeEnum === 24) {
            return true;
        } else {
            return false;
        }
    }

    redrawMemberStatusColors() {
        //go get tree data from allModelDataService (tempData)
        //walk the existing vm.JobTreeData and update all the relevant fields from tempData
        var tempData = this.allModelDataService.getJobTreeViewModel();
        if (this.jobTreeData) {
            for (var i = 0; i < this.jobTreeData[0].children.length; i++) {
                if (this.jobTreeData[0].children[0].children.length > 0) {
                    for (var j = 0; j < this.jobTreeData[0].children[i].children.length; j++) {
                        var tempDataItem = tempData.children[i].children[j];
                        var jobTreeItem = this.jobTreeData[0].children[i].children[j];
                        if (tempDataItem) {
                            jobTreeItem.hasValidPaeSolution = tempDataItem.hasValidPaeSolution;
                            jobTreeItem.isCurrentlySelectedMember = tempDataItem.isCurrentlySelectedMember;
                            jobTreeItem.isDesignable = tempDataItem.isDesignable;
                            jobTreeItem.isDesigned = tempDataItem.isDesigned;
                            jobTreeItem.waitingOnDesignOfLinkedMember = tempDataItem.waitingOnDesignOfLinkedMember;
                            jobTreeItem.wouldResultInCircularLink = tempDataItem.wouldResultInCircularLink;
                        }
                    }
                }
            }
        }
    }

    getProjectManagerData() {
        this.projectManagerData = this.memberManagementService.getProjectManagerData();
    }

    addNewLevel() {
        //_setFolderThatLevelWasAddedTo();
        // vm.folderSelectedBeforeAddClicked = $('#jobTreeContainer').jstree()
        //     .get_node($('#jobTreeContainer').jstree().get_selected());
        var originalNodeIsMember = this._getNodeType(this.selectedNode.id) === 'member';
        this.beforeNavigationRedesignMemberIfInvalidated(originalNodeIsMember).then(
            (reason) => {
                //console.log('memManCtrl-addNewLevel-spinnerShow');
                this.spinner.spinnerShow(this.getText('pleaseWait'));
                this.memberManagementService.addLevel().then(
                    (response) => {
                        //refresh the job tree
                        this.selectNewlyAddedLevelNode = true;
                        // $('#jobTreeContainer').jstree('deselect_node', this.folderSelectedBeforeAddClicked);
                        //check response, find the node that should be selected and set it in this.selectedNode?
                        this.refreshJobTree(false);
                    },
                    //promise failure
                    (response) => {
                        //log the error
                    }
                );
            },
            (reason) => {
                //alert(reason);
                //dont do anything because they are hanging out and fixing the member?
                //maybe reset the selected node?
            }
        );
    }

    deleteMemberOrLevel() {
        this.showDeleteWarning = true;
        $('#jobsidenav').addClass('ignoreClose');
        this.$scope.$emit('showSplashForDeleteJobNode');
    }

    deleteWarningComplete(shouldDelete) {
        if (shouldDelete) {
            if (this.selectedNode.id.lastIndexOf('m') === -1) {
                //for use in preservation of collapsed nodes.
                this.deletedLevelIndex = this.selectedNode.id.substring(2, this.selectedNode.id.length);
                this.memberManagementService.deleteLevel().then(
                    (response) => {
                        //refresh the job tree
                        this.selectFirstLevel = true;
                        this.refreshJobTree(false);
                    },
                    (error) => {
                        //log the error
                    });
            } else {
                //member
                this.memberManagementService.deleteMember().then(
                    (response) => {
                        //refresh the job tree
                        this.selectFirstLevel = true;
                        this.refreshJobTree(false);
                    },
                    (exception) => {
                        if (exception.status === 403) {
                            //var message = getText(exception.data.ExceptionMessage);
                            var message = this.getText(exception.data.Message);
                            this.notifier.fileManagementError(this.getText('error'), message);
                        }
                    }
                );
            }
        }
        this.showDeleteWarning = false;
        $('#jobsidenav').removeClass('ignoreClose');
        this.$scope.$emit('hideSplashForDeleteJobNode');
    }

    copyMember() {
        // var currentlySelectedFolder = $('#jobTreeContainer').jstree()
        //     .get_node($('#jobTreeContainer').jstree().get_selected());
        // vm.folderThatMemberWasCopiedTo = $('#jobTreeContainer').jstree()
        //     .get_node(currentlySelectedFolder.parent);
        // vm.folderSelectedBeforeAddClicked = $('#jobTreeContainer').jstree()
        //     .get_node($('#jobTreeContainer').jstree().get_selected());

        //if (!isCopying) {
        //isCopying = true;
        var originalNodeIsMember = this._getNodeType(this.selectedNode.id) === 'member';
        this.beforeNavigationRedesignMemberIfInvalidated(originalNodeIsMember).then(
            (reason) => {
                this.memberManagementService.copyMember().then(
                        (response) => {
                            //refresh the job tree
                            //selectOnCopyMember = true;
                            this.selectNewlyAddedChildNode = true;
                            this.refreshJobTree(false);
                        },
                        (error) => {
                            //log the error
                        })
                    .finally(() => {
                        //isCopying = false;
                    });
            },
            (reason) => {
                //alert(reason);
                //dont do anything because they are hanging out and fixing the member?
                //maybe reset the selected node?
            }
        );
        //}
    }

    addFloorJoist() {
        var originalNodeIsMember = this._getNodeType(this.selectedNode.id) === 'member';
        this.beforeNavigationRedesignMemberIfInvalidated(originalNodeIsMember).then(
            (reason) => {
                this.memberManagementService.addMemberFloorJoist().then(
                    (response) => {
                        this.selectNewlyAddedChildNode = true;
                        this.toggleAddMemberDisplay();
                        this.refreshJobTree(false);
                    },
                    (response) => {}
                );
            },
            (reason) => {
                //alert(reason);
                //dont do anything because they are hanging out and fixing the member?
                //maybe reset the selected node?
            }
        );
    }

    addFloorDropBeam() {
        var originalNodeIsMember = this._getNodeType(this.selectedNode.id) === 'member';
        this.beforeNavigationRedesignMemberIfInvalidated(originalNodeIsMember).then(
            (reason) => {
                this.memberManagementService.addMemberFloorDropBeam().then(
                    (response) => {
                        this.selectNewlyAddedChildNode = true;
                        this.toggleAddMemberDisplay();
                        this.refreshJobTree(false);
                    },
                    (response) => {}
                );
            },
            (reason) => {
                //alert(reason);
                //dont do anything because they are hanging out and fixing the member?
                //maybe reset the selected node?
            }
        );
    }

    addFloorFlushBeam() {
        var originalNodeIsMember = this._getNodeType(this.selectedNode.id) === 'member';
        this.beforeNavigationRedesignMemberIfInvalidated(originalNodeIsMember).then(
            (reason) => {
                this.memberManagementService.addMemberFloorFlushBeam().then(
                    (response) => {
                        this.selectNewlyAddedChildNode = true;
                        this.toggleAddMemberDisplay();
                        this.refreshJobTree(false);
                    },
                    (response) => {}
                );
            },
            (reason) => {
                //alert(reason);
                //dont do anything because they are hanging out and fixing the member?
                //maybe reset the selected node?
            }
        );
    }

    addWallHeader() {
        var originalNodeIsMember = this._getNodeType(this.selectedNode.id) === 'member';
        this.beforeNavigationRedesignMemberIfInvalidated(originalNodeIsMember).then(
            (reason) => {
                this.memberManagementService.addMemberWallHeader().then(
                    (response) => {
                        //refresh the job tree
                        this.selectNewlyAddedChildNode = true;
                        this.toggleAddMemberDisplay();
                        this.refreshJobTree(false);
                    },
                    (response) => {}
                );
            },
            (reason) => {
                //alert(reason);
                //dont do anything because they are hanging out and fixing the member?
                //maybe reset the selected node?
            }
        );
    }

    addWallWindSill() {
        var originalNodeIsMember = this._getNodeType(this.selectedNode.id) === 'member';
        this.beforeNavigationRedesignMemberIfInvalidated(originalNodeIsMember).then(
            (reason) => {
                this.memberManagementService.addMemberWallWindSill().then(
                    (response) => {
                        //refresh the job tree
                        this.selectNewlyAddedChildNode = true;
                        this.toggleAddMemberDisplay();
                        this.refreshJobTree(false);
                    },
                    (response) => {}
                );
            },
            (reason) => {
                //alert(reason);
                //dont do anything because they are hanging out and fixing the member?
                //maybe reset the selected node?
            }
        );
    }

    addWallStud() {
        var originalNodeIsMember = this._getNodeType(this.selectedNode.id) === 'member';
        this.beforeNavigationRedesignMemberIfInvalidated(originalNodeIsMember).then(
            (reason) => {
                this.memberManagementService.addMemberWallStud().then(
                    (response) => {
                        //refresh the job tree
                        this.selectNewlyAddedChildNode = true;
                        this.toggleAddMemberDisplay();
                        this.refreshJobTree(false);
                    },
                    (response) => {}
                );
            },
            (reason) => {
                //alert(reason);
                //dont do anything because they are hanging out and fixing the member?
                //maybe reset the selected node?
            }
        );
    }

    addWallColumn() {
        var originalNodeIsMember = this._getNodeType(this.selectedNode.id) === 'member';
        this.beforeNavigationRedesignMemberIfInvalidated(originalNodeIsMember).then(
            (reason) => {
                this.memberManagementService.addMemberWallColumn().then(
                    (response) => {
                        //refresh the job tree
                        this.selectNewlyAddedChildNode = true;
                        this.toggleAddMemberDisplay();
                        this.refreshJobTree(false);
                    },
                    (response) => {}
                );
            },
            (reason) => {
                //alert(reason);
                //dont do anything because they are hanging out and fixing the member?
                //maybe reset the selected node?
            }
        );
    }

    addFreeStandingPost() {
        var originalNodeIsMember = this._getNodeType(this.selectedNode.id) === 'member';
        this.beforeNavigationRedesignMemberIfInvalidated(originalNodeIsMember).then(
            (reason) => {
                this.memberManagementService.addMemberFreeStandingPost().then(
                    (response) => {
                        //refresh the job tree
                        this.selectNewlyAddedChildNode = true;
                        this.toggleAddMemberDisplay();
                        this.refreshJobTree(false);
                    },
                    (response) => {}
                );
            },
            (reason) => {
                //alert(reason);
                //dont do anything because they are hanging out and fixing the member?
                //maybe reset the selected node?
            }
        );
    }

    addCeilingJoist() {
        var originalNodeIsMember = this._getNodeType(this.selectedNode.id) === 'member';
        this.beforeNavigationRedesignMemberIfInvalidated(originalNodeIsMember).then(
            (reason) => {
                this.memberManagementService.addMemberCeilingJoist().then(
                    (response) => {
                        this.selectNewlyAddedChildNode = true;
                        this.toggleAddMemberDisplay();
                        this.refreshJobTree(false);
                    },
                    (response) => {}
                );
            },
            (reason) => {
                //alert(reason);
                //dont do anything because they are hanging out and fixing the member?
                //maybe reset the selected node?
            }
        );
    }

    addRoofJoist() {
        var originalNodeIsMember = this._getNodeType(this.selectedNode.id) === 'member';
        this.beforeNavigationRedesignMemberIfInvalidated(originalNodeIsMember).then(
            (reason) => {
                this.memberManagementService.addMemberRoofJoist().then(
                    (response) => {
                        this.selectNewlyAddedChildNode = true;
                        this.toggleAddMemberDisplay();
                        this.refreshJobTree(false);
                    },
                    (response) => {}
                );
            },
            (reason) => {
                //alert(reason);
                //dont do anything because they are hanging out and fixing the member?
                //maybe reset the selected node?
            }
        );
    }

    addRoofDropBeam() {
        var originalNodeIsMember = this._getNodeType(this.selectedNode.id) === 'member';
        this.beforeNavigationRedesignMemberIfInvalidated(originalNodeIsMember).then(
            (reason) => {
                this.memberManagementService.addMemberRoofDropBeam().then(
                    (response) => {
                        this.selectNewlyAddedChildNode = true;
                        this.toggleAddMemberDisplay();
                        this.refreshJobTree(false);
                    },
                    (response) => {}
                );
            },
            (reason) => {
                //alert(reason);
                //dont do anything because they are hanging out and fixing the member?
                //maybe reset the selected node?
            }
        );
    }

    addRoofFlushBeam() {
        var originalNodeIsMember = this._getNodeType(this.selectedNode.id) === 'member';
        this.beforeNavigationRedesignMemberIfInvalidated(originalNodeIsMember).then(
            (reason) => {
                this.memberManagementService.addMemberRoofFlushBeam().then(
                    (response) => {
                        this.selectNewlyAddedChildNode = true;
                        this.toggleAddMemberDisplay();
                        this.refreshJobTree(false);
                    },
                    (response) => {}
                );
            },
            (reason) => {
                //alert(reason);
                //dont do anything because they are hanging out and fixing the member?
                //maybe reset the selected node?
            }
        );
    }
    addRoofHipBeam() {
        var originalNodeIsMember = this._getNodeType(this.selectedNode.id) === 'member';
        this.beforeNavigationRedesignMemberIfInvalidated(originalNodeIsMember).then(
            (reason) => {
                this.memberManagementService.addMemberRoofHipBeam().then(
                    (response) => {
                        this.selectNewlyAddedChildNode = true;
                        this.toggleAddMemberDisplay();
                        this.refreshJobTree(false);
                    },
                    (response) => {}
                );
            },
            (reason) => {
                //alert(reason);
                //dont do anything because they are hanging out and fixing the member?
                //maybe reset the selected node?
            }
        );
    }


    moveMember(fromLevelId, toLevelId, oldIndex, newIndex, droppedMemberSDO) {
        // if (allowMove && toLevelId !== null && memberId !== null && toLevelId !== fromLevelId) {
        var data = {
            'toLevelId': toLevelId,
            'fromLevelId': fromLevelId,
            'newIndex': newIndex,
            'oldIndex': oldIndex
        };
        this.showPendingColumnOverlay = true;
        this.memberPropertiesService.moveMember(data)
            .then(
                (serviceResponse) => {
                    //if the selected member is the one that was moved
                    if (this.selectedNode.id === droppedMemberSDO.id) {
                        //update the selected node so the correct one is selected after refresh
                        this.selectedNode.id = 'c_' + toLevelId + 'm_' + newIndex;
                        //update the report document if they are on the report.
                        this.smartNavService.reloadIfCurrentStateIsReport();
                    } else {
                        var prData = this.allModelDataService.getPostResponseData();
                        var currentContainerId = prData.ApplicationData.MemberManagerData.SelectedContainerID;
                        var currentMemberId = prData.ApplicationData.MemberManagerData.SelectedMemberID;
                        if (currentContainerId > -1 && currentMemberId > -1) {
                            // Member is selected
                            this.selectedNode.id = 'c_' + currentContainerId + 'm_' + currentMemberId;
                        }
                    }

                    //if selectedNode is a level, validate that the delete buttons is in the correct state
                    if (this.selectedNode.id.lastIndexOf('m') === -1 && this.selectedNode.id !== 'root') {
                        var containerId = this.selectedNode.id.substring(2, this.selectedNode.id.length);
                        this._setDeleteButtonToCorrectState(containerId);
                    }
                },
                (error) => {
                    console.log(error);
                }
            ).finally(() => {
                this.refreshJobTree(false);
            });
    }

    _preserveCollapsedNodesBeforeTreeRefresh() {
        var levelHtmlNodes = document.getElementsByClassName('level');
        this.collapsedNodesPreservation = [];
        for (var i = 0; i < levelHtmlNodes.length; i++) {
            if (this.isAddingMember && this.indexOfLevelThatContainsAddedMember === i) {
                continue;
            }
            if (angular.element(levelHtmlNodes[i]).scope().collapsed === true) {
                if (i !== this.deletedLevelIndex) {
                    if (this.deletedLevelIndex && i > this.deletedLevelIndex) {
                        this.collapsedNodesPreservation.push(i - 1);
                    } else {
                        this.collapsedNodesPreservation.push(i);
                    }
                }
            }
        }
        this.deletedLevelIndex = null;
    }

    _restoreCollapsedNodesAfterTreeRefresh() {
        var levelHtmlNodes = document.getElementsByClassName('level');
        for (var j = 0; j < this.collapsedNodesPreservation.length; j++) {
            var index = this.collapsedNodesPreservation[j];
            angular.element(levelHtmlNodes[index]).scope().collapsed = true;
        }
    }

    refreshJobTree() {
        this.showPendingColumnOverlay = true;
        this.memberManagementService.getJobTreeData().then(
                //promise success
                (response) => {
                    this.$scope.$parent.$broadcast('handleJobTreeUpdated');
                    if (this.selectNewlyAddedLevelNode) {
                        this.selectedNode = this._getNewlyAddedLevelFromResponse(response);
                        this.isAddingLevel = true;
                        this.selectNewlyAddedLevelNode = false;
                        this.selectedNodeChangedInCode = true;
                    } else if (this.selectNewlyAddedChildNode) {
                        this.selectedNode = this._getNewlyAddedMemberFromResponse(response);
                        this.isAddingMember = true;
                        this.selectNewlyAddedChildNode = false;
                        this.selectedNodeChangedInCode = true;
                    } else if (this.selectFirstLevel) {
                        this.selectedNode = response.children[0];
                        this.selectFirstLevel = false;
                        this.selectedNodeChangedInCode = true;
                    }
                    //store indexes of collapsed levels so they can be restored after the tree is refreshed.
                    this._preserveCollapsedNodesBeforeTreeRefresh();
                    //append brackets to the response to put it in a collection;
                    //this can be removed once we have the api return a collection
                    //because this control expects a collection of nodes,
                    //not just a single root node.
                    this.jobTreeData = [];
                    this.jobTreeData.push(response);
                    this.$timeout(() => {
                        //restore the collapsed nodes after digest cycle(otherwise the tree control wont be drawn yet.)
                        this._restoreCollapsedNodesAfterTreeRefresh();
                    }, 0);
                    var rootNode = response;
                    this.tryRestoreJobTreeData(rootNode);
                },
                //promise failure
                (response) => {
                    //log the error
                }
            )
            .finally(() => {
                //hide any spinner
                //console.log('memManCtrl-refreshJobTree-spinnerHide');
                this.spinner.spinnerHide();
                //isRenaming is used to prevent drag/drop and selection
                //while renaming is taking place.
                this.$timeout(() => {
                    this.isRenaming = false;
                }, 800);
                //turn off the pending column overlay
                this.showPendingColumnOverlay = false;
            });
    }

    tryRestoreJobTreeData(rootNode) {
        //if it's empty, try to get it out of local storage
        if (!this.selectedNode) {
            this.selectedNode = this.allModelDataService.tryGetSelectedJobTreeNode();
            //if we didnt have it saved then use the root node.
            if (!this.selectedNode) {
                this.selectedNode = rootNode;
            }
            //force tree node switched workflow.
            this.jobTreeNodeSetInCode();
        }
        if (this.selectedNode && this.selectedNodeChangedInCode) {
            this.selectedNodeChangedInCode = false;
            this.jobTreeNodeSetInCode();
        }
    }

    editNameButtonClicked(event, node) {
        this.renameOriginalName = node.text;
        node.showEdit = true;
        this._selectAllTextInRenameTextbox();
        event.stopPropagation();
    }

    _selectAllTextInRenameTextbox() {
        //this timeout fires immediately after digest cycle completes.
        this.$timeout(() => {
            var elementToSelctText = document.getElementById('jobTreeRenameTextBox');
            elementToSelctText.select();
        }, 0, false); //pass false here to prevent another digest cycle from firing.
    }

    renameComplete(event, node) {
        this.showPendingColumnOverlay = true;
        if (node.id === 'root') {
            var projectFileTreeID = this.allModelDataService.getPostResponseData().ApplicationData.ProjectManagerData.ProjectFileTreeID;
            if (projectFileTreeID) {
                //received data date passed in as -1 here to denote that the "did something change" comparison should be ignored in this case.
                //since this rename is being done outside of the filemanager.
                this.fileSystemService.renameFileWithTreeItemId(projectFileTreeID, projectFileTreeID, node.text, '-1')
                    .then(() => {
                        this.refreshJobTree(false);
                    });
            }
        } else if (node.id.lastIndexOf('m') > -1) {
            var lastUnderscore = node.id.lastIndexOf('_');
            var firstUnderscore = node.id.indexOf('_');
            var locationOfM = node.id.indexOf('m');
            var memberId = node.id
                .substring(lastUnderscore + 1, node.id.length);
            var containerId = node.id
                .substring(firstUnderscore + 1, locationOfM);
            var data = {
                'memberId': memberId,
                'containerId': containerId,
                'newName': node.text
            };

            this.memberPropertiesService.modifyMemberName(data)
                .then((serviceResponse) => {
                    this.refreshJobTree(false);
                    if (node.id === this.selectedNode.id) {
                        this.smartNavService.reloadIfCurrentStateIsReport();
                    }
                });
        } else {
            var containerId = node.id.substring(2, node.id.length);
            var data = {
                'containerId': containerId,
                'newName': node.text
            };

            this.levelPropertiesService.modifyLevelName(data)
                .then((serviceResponse) => {
                    this.refreshJobTree(false);
                    var selectedNodeParentId = this.selectedNode.id
                        .substring(0, this.selectedNode.id.indexOf('m'));
                    if (node.id === selectedNodeParentId) {
                        this.smartNavService.reloadIfCurrentStateIsReport();
                    }
                });
        }
    }

    jobTreeNodeSelected(node) {
        var originalNodeIsMember = this._getNodeType(this.selectedNode.id) === 'member';
        //ignore if the node is currently being renamed
        // Added checking if same node gets selected again: error notification of linked member should not show up
        if (this.isRenaming === false && this.selectedNode.id !== node.id) {
            this.beforeNavigationRedesignMemberIfInvalidated(originalNodeIsMember).then(
                (reason) => {
                    //alert(reason);
                    this.selectedNode = node;
                    var nodeType = this._getNodeType(node.id);
                    var jobTreePromise = this._getTreeNodeSelectedPromise(node.id);
                    jobTreePromise.then(() => {
                        this.$scope.$parent.$broadcast('updateVisibleTabs', nodeType);
                        $(document).ready(() => {
                            var element = $('.arrows');

                            element.scrollLeft(1000);
                        });
                    });
                },
                (reason) => {
                    //alert(reason);
                    //dont do anything because they are hanging out and fixing the member?
                    //maybe reset the selected node?
                }
            );
        }
    }

    jobTreeNodeSetInCode() {
        var nodeType = this._getNodeType(this.selectedNode.id);
        var treeNodeSelectedPromise = this._getTreeNodeSelectedPromise(this.selectedNode.id);

        treeNodeSelectedPromise.then(() => {
            this.$scope.$parent.$broadcast('updateVisibleTabs', nodeType);
            // After adding the new node to the job tree make the node editable by user
            if (this.isAddingMember || this.isAddingLevel) {
                this.$scope.$parent.$broadcast('editAddedJobTreeNode', this.selectedNode);
                this.isAddingLevel = false;
                this.isAddingMember = false;
            }
        });

    }

    beforeNavigationRedesignMemberIfInvalidated(originalNodeIsMember) {
        /////SUMMARY/////
        //If this member is linked TO another members
        //AND member is not designed
        //try to design it
        //if successfull design then proceed
        //if failed to design then
        //direct them to the appropriate tab? (loads off member, product selection, report) depending on what failed
        //show a message that asks them to fix the issue before proceeding with the job tree node switched
        //if they ignore then the "waiting on design of linked member" field will be true for any immediate dependencies (TO members)
        //if they fix it then when it gets designed again we will reset the "waiting on design of linked member" to false (ZACH: confirm this test scenario)
        /////END SUMMARY/////

        //get the current member sdo out of the post response data
        var deferred = this.common.$q().defer();
        if (originalNodeIsMember) {
            var prData = this.allModelDataService.getPostResponseData();
            var currentContainerId = prData.ApplicationData.MemberManagerData.SelectedContainerID;
            var currentMemberId = prData.ApplicationData.MemberManagerData.SelectedMemberID;
            var memberSDO = prData.ApplicationData.MemberManagerData.MemberContainers[currentContainerId].Members[currentMemberId];
            var memberHasLinksToOtherMembers = prData.ApplicationData.MemberManagerData.MemberTagToLinkedReactionDictionary[memberSDO.IDTag].ToReactions.length > 0;
            var memberHasValidPaeSolution = memberSDO.HasValidPaeSolution;
            var memberIsDesigned = memberSDO.ProblemIsDesigned;
            var buttonText = {
                ok: this.getText('ok'),
                ignore: this.getText('ignore')
            };
            if (memberHasLinksToOtherMembers && (memberIsDesigned !== true || memberHasValidPaeSolution !== true)) {
                if (memberSDO.IsDesignable === false) {
                    //prompt them
                    this.notifier.showLinkedReactionBrokenPrompt(this.getText('error'), this.getText('lrNotifierCannotDesign'), buttonText, deferred);
                } else {
                    if (this.allModelDataService.getIsMemberVertical()) {
                        //design it and check to see if HasValidPaeSolution is valid
                        this.reportService.getVertReportData().then(
                            (response) => {
                                //check the response
                                //console.log('memManCtrl-beforeNavigationRedesignMemberIfInvalidated-spinnerHide');
                                this.spinner.spinnerHide();
                                var updatedPrData = this.allModelDataService.getPostResponseData();
                                var updatedMemberSDO = updatedPrData.ApplicationData.MemberManagerData.MemberContainers[currentContainerId].Members[currentMemberId];
                                if (updatedMemberSDO.HasValidPaeSolution) {
                                    deferred.resolve();
                                } else {
                                    this.notifier.showLinkedReactionBrokenPrompt(this.getText('error'), this.getText('lrNotifierNoValidSolution'), buttonText, deferred);
                                }
                            },
                            (exception) => {
                                this.spinner.spinnerHide();
                                if (exception.status === 403) {
                                    var message = this.getText(exception.data.Message);
                                    this.notifier.fileManagementError(this.getText('error'), message);
                                }
                            }
                        );
                    } else {
                        //design it and check to see if HasValidPaeSolution is valid
                        this.reportService.getHorizReportData().then(
                            (response) => {
                                //check the response
                                //console.log('memManCtrl-beforeNavigationRedesignMemberIfInvalidated-spinnerHide');
                                this.spinner.spinnerHide();
                                var updatedPrData = this.allModelDataService.getPostResponseData();
                                var updatedMemberSDO = updatedPrData.ApplicationData.MemberManagerData.MemberContainers[currentContainerId].Members[currentMemberId];
                                if (updatedMemberSDO.HasValidPaeSolution) {
                                    deferred.resolve();
                                } else {
                                    this.notifier.showLinkedReactionBrokenPrompt(this.getText('error'), this.getText('lrNotifierNoValidSolution'), buttonText, deferred);
                                }
                            },
                            (exception) => {
                                this.spinner.spinnerHide();
                                if (exception.status === 403) {
                                    var message = this.getText(exception.data.Message);
                                    this.notifier.fileManagementError(this.getText('error'), message);
                                }
                            }
                        );
                    }
                }
            } else {
                deferred.resolve('no links or is already designed');
            }
        } else {
            deferred.resolve('original node not member');
        }
        return deferred.promise;
    }

    _getNodeType(nodeId) {
        if (nodeId === 'root') {
            return 'jobSettings';
        } else if (nodeId.lastIndexOf('m') > -1) {
            return 'member';
        } else {
            return 'level';
        }
    }

    _getTreeNodeSelectedPromise(nodeId) {
        if (nodeId === 'root') {
            return this.projectNodeSelected();
        } else if (nodeId.lastIndexOf('m') > -1) {
            return this.memberNodeSelected();
        } else {
            return this.levelNodeSelected();
        }
    }

    projectNodeSelected() {
        var deferred = this.common.$q().defer();
        //console.log('memManCtrl-projectNodeSelected');
        this.memberManagementService.selectedJobTreeNodeChanged(-1, -1).then(() => {
            this.$timeout(() => {
                this.smartNavService.navigateOrReload('application.job.jobSummary');
            }, 0, false);
            this.duplicateMemberDisabled = true;
            this.deleteDisabled = true;
            deferred.resolve();
        });
        return deferred.promise;
    }

    levelNodeSelected() {
        var deferred = this.common.$q().defer();
        // if (!selectNewlyAddedChildNode) {
        var containerId = this.selectedNode.id.substring(2, this.selectedNode.id.length);
        //console.log('memManCtrl-levelNodeSelected: ' + containerId);
        this.memberManagementService.selectedJobTreeNodeChanged(containerId, -1).then(() => {
            this.smartNavService.navigateOrReload('application.job.level');
            ////enable the correct buttons
            this.duplicateMemberDisabled = true;
            this._setDeleteButtonToCorrectState(containerId);
            deferred.resolve();
        });
        // } else {
        //     deferred.resolve();
        // }
        //
        return deferred.promise;
    }

    _setDeleteButtonToCorrectState(containerIndex) {
        if (this.jobTreeData[0].children.length > 1 &&
            this.jobTreeData[0].children[containerIndex].children.length === 0) {
            this.deleteDisabled = false;
        } else {
            this.deleteDisabled = true;
        }
    }

    memberNodeSelected() {
        // console.log('tree node changed event');
        this.allModelDataService.setSolutionsGridData(null);
        var lastUnderscore = this.selectedNode.id.lastIndexOf('_');
        var memberId = this.selectedNode.id.substring(lastUnderscore + 1, this.selectedNode.id.length);
        var containerId = this.selectedNode.id.substring(2, lastUnderscore - 1);
        var deferred = this.common.$q().defer();
        //console.log('memManCtrl-levelNodeSelected: ' + containerId + '/' +  memberId);
        this.memberManagementService.selectedJobTreeNodeChanged(containerId, memberId).then(() => {
            this.tabNavigationService.getVisibleTabsForMember();
            if (this.tabNavigationService.isCurrentTabAvailable()) {
                this.smartNavService.navigateOrReload(this.$state.current.name);
            } else {
                this.smartNavService.navigateOrReload('application.job.memberInfo');
            }
            ////enable the correct buttons
            this.duplicateMemberDisabled = false;
            this.deleteDisabled = false;
            deferred.resolve();
        });
        return deferred.promise;
    }

    _getNewlyAddedMemberFromResponse(response) {
        var returnMe;
        //determine if the selected node is the root
        if (this.selectedNode.id === 'root') {
            //get the last child of the first level
            var firstLevel = response.children[0];
            this.indexOfLevelThatContainsAddedMember = 0;
            returnMe = firstLevel.children[firstLevel.children.length - 1];
        } else if (this.selectedNode.id.lastIndexOf('m') > -1) {
            //get the last child of the parent of the selected node
            var levelThatContainsSelectedMember;
            for (var i = 0; i < response.children.length; i++) {
                var currentLevel = response.children[i];
                for (var j = 0; j < currentLevel.children.length; j++) {
                    var currentMember = currentLevel.children[j];
                    if (this.selectedNode.id === currentMember.id) {
                        levelThatContainsSelectedMember = response.children[i];
                    }
                }
            }
            this.indexOfLevelThatContainsAddedMember = parseInt(levelThatContainsSelectedMember.id.substr(levelThatContainsSelectedMember.id.length - 1));
            //get that level's last child
            returnMe = levelThatContainsSelectedMember.children[levelThatContainsSelectedMember.children.length - 1];
        } else {
            this.indexOfLevelThatContainsAddedMember = parseInt(this.selectedNode.id.substr(this.selectedNode.id.length - 1));
            //get the last child of the selected item
            for (var i = 0; i < response.children.length; i++) {
                var currentLevel = response.children[i];
                if (this.selectedNode.id === currentLevel.id) {
                    //get that level's last child
                    returnMe = currentLevel.children[currentLevel.children.length - 1];
                }
            }
        }
        return returnMe;
    }

    _getNewlyAddedLevelFromResponse(response) {
        var toReturn;
        for (var i = 0; i < response.children.length; i++) {
            if (!response.children[i].isLeafeNode) {
                toReturn = response.children[i];
            }
        }
        return toReturn;
    }

    toggleAddMemberDisplay() {
        this.addMemberIsVisible = !this.addMemberIsVisible;
    }

    getJobTreeCSSClass() {
        if (this.addMemberIsVisible) {
            return 'jobTree-hide';
        } else {
            return 'jobTree-show';
        }
    }

    getAddMemberCSSClass() {
        if (this.addMemberIsVisible) {
            return 'addMember-show';
        } else {
            return 'addMember-hide';
        }
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    // Get BreadCrumb Route or Path for selected Job/Level/Member
    /* eslint-disable max-depth */
    getBreadCrumb() {
        var node = [];
        var count = 0;

        if (this.jobTreeData) {
            for (var r = 0; r < this.jobTreeData.length; r++) {
                if (this.jobTreeData[r]) {
                    //eslint-disable-next-line eqeqeq
                    if (this.jobTreeData[r].id == this.selectedNode.id) {
                        node[count] = this.jobTreeData[r]; //Get Job Node


                        count++;
                    } else {
                        if (this.jobTreeData[r].children) {

                            for (var l = 0; l < this.jobTreeData[r].children.length; l++) {

                                if (this.jobTreeData[r].children[l]) {
                                    //eslint-disable-next-line eqeqeq
                                    if (this.jobTreeData[r].children[l].id == this.selectedNode.id) {
                                        node[count] = this.jobTreeData[r]; //Get Job Node

                                        count++;
                                        node[count] = this.jobTreeData[r].children[l]; //Get Level Node
                                        count++;
                                    } else {

                                        if (this.jobTreeData[r].children[l].children) {
                                            for (var n = 0; n < this.jobTreeData[r].children[l].children.length; n++) {
                                                if (this.jobTreeData[r].children[l].children[n]) {
                                                    //eslint-disable-next-line eqeqeq
                                                    if (this.jobTreeData[r].children[l].children[n].id == this.selectedNode.id) {
                                                        node[count] = this.jobTreeData[r]; //Get Job Node

                                                        count++;
                                                        node[count] = this.jobTreeData[r].children[l]; //Get Level Node
                                                        count++;
                                                        node[count] = this.jobTreeData[r].children[l].children[n]; //Get Member Node
                                                        count++;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return node;
    }
    openSideJobNav() {
        this.mobileNavMenu.openSideJobNav();
    }
    scrollToEnd() {
        this.mobileNavMenu.scrollToEnd();
    }
}
