export default class emailSentController {

    constructor (resourceMessagingService) {
        'ngInject';
        this.resourceMessagingService = resourceMessagingService;

        this.activate();
    }
    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    activate() {

    }
}
// (function () {
//     'use strict';
//     var app = angular.module('app');

//     app.controller('emailSentController', ['resourceMessagingService', function (resourceMessagingService) {
//         /* jshint validthis: true */
//         var vm = this;
//         vm.getText = getText;

//         function getText(key) {
//             return resourceMessagingService.getText(key);
//         }

//         function activate() {

//         }
//         activate();
//     }]);
// }());
