import { resolve } from 'bluebird';
import ngAuthSettings from '../environmentConfigs/environment.dev';

export default class signalrService {
    constructor($state, common, postService, allModelDataService, notifier, resourceMessagingService) {
        'ngInject';
        this.$state = $state;
        this.$q = common.$q();
        this.postService = postService;
        this.allModelDataService = allModelDataService;
        this.notifier = notifier;
        this.serviceBaseUrl = ngAuthSettings.apiServiceBaseUri;
        this.isconnected = false;
        this.connectionId = null;
        this.previousConnectionId = null;
        this.resourceMessagingService = resourceMessagingService;
    }

    getConnectionId() {
        if (this.connection) {
            return this.connection.id;
        }
    }

    handleIssuesWithSignalRConnection() {
        this.isConnected = false;
        this.previousConnectionId = this.connectionId;
    }
    resolvedIssuesWithSignalRConnection() {
        this.isConnected = true;
    }

    connectToSignalR() {
        const authData = this.allModelDataService.getAuthorizationData();
        this.connection = $.hubConnection();
        this.hubProxy = this.connection.createHubProxy('AuthenticatedHub');
        this.connection.qs = 'access_token=' + authData.token;
        this.connection.url = this.serviceBaseUrl + 'signalr';

        //placeholders for messages received from the API
        this.hubProxy.on('forceLogout', () => {
            const deferred = this.$q.defer();
            console.log('force logout');
            const title = this.resourceMessagingService.getText('maintenanceAlert');
            const warnMessage = this.resourceMessagingService.getText('maintenanceTimeLogout');
            this.notifier.showAutoLogoutToast(
                deferred,
                title,
                warnMessage
            );
            var currentFileId = this.allModelDataService.getCurrentFileId();
            this.notifyFileClosed(currentFileId).then(() => {
                this.notifyClientIdledOut().then(() => {
                    this.disconnect();
                    this.$state.go('account.login');
                });
            });
        });
        this.hubProxy.on('showLogoutWarning', (message) => {
            const deferred = this.$q.defer();
            console.log(message);
            const title = this.resourceMessagingService.getText('maintenanceAlert');
            const warnMessage = this.resourceMessagingService
                .getText('maintenanceTimeWarning')
                .replace('~1', message);
            this.notifier.showAutoLogoutToast(
                deferred,
                title,
                warnMessage
            );
        });
        this.connection.connectionSlow(() => {
            console.log('signalR: connection slow');
            //this.handleIssuesWithSignalRConnection();
        });
        this.connection.reconnecting(() => {
            console.log('signalR: attempting to reconnect');
            this.handleIssuesWithSignalRConnection();
        });
        this.connection.reconnected(() => {
            console.log('signalR: successfully reconnected');
            this.resolvedIssuesWithSignalRConnection();
        });
        //reconnect if accidental disconnection.
        this.connection.disconnected(() => {
            if (!this.disconnectCalled) {
                console.log('client disconnected');
                //reset the flag.
                this.disconnectCalled = false;
                this.connection
                    .start({
                        pingInterval: null,
                        transport: ['webSockets', 'foreverFrame', 'serverSentEvents']
                    })
                    .done(() => {
                        console.log('reconnected: ' + this.connection.id);
                        this.notifySessionIsMobile();
                        //if the user has a file in their PRData, let the api know that it's open so it can be tracked.
                        if (this.allModelDataService.getPostResponseData()) {
                            this.notifyFileOpen(
                                this.allModelDataService.getPostResponseData()
                                    .ApplicationData.ProjectManagerData
                                    .ProjectFileTreeID,
                                this.previousConnectionId
                            );
                        }
                    })
                    .fail(() => {
                        console.log('could not reconnect');
                        this.$state.go('account.login');
                    });
            }
        });

        //CONNECT TO SIGNALR
        this.connection
            .start({
                pingInterval: null,
                transport: ['webSockets', 'foreverFrame', 'serverSentEvents']
            })
            .done(() => {
                console.log(
                    'now connected, connectionID=' + this.connection.id
                );
                this.notifySessionIsMobile();
                //if the user has a file in their PRData, let the api know that it's open so it can be tracked.
                if (this.allModelDataService.getPostResponseData()) {
                    this.notifyFileOpen(
                        this.allModelDataService.getPostResponseData()
                            .ApplicationData.ProjectManagerData
                            .ProjectFileTreeID
                    );
                }
            })
            .fail(() => {
                console.log('could not connect');
                this.$state.go('account.login');
            });
    }

    sendLoginData() {
        //userAgent
        var data = {
            userAgent: navigator.userAgent,
            cookiesEnabled: navigator.cookieEnabled,
            windowHeight: this._getWindowHeight(),
            windowWidth: this._getWindowWidth(),
            screenHeight: screen.height,
            screenWidth: screen.width,
        };
        return this._requestPostEvent('CollectLoginData', data);
    }

    disconnect() {
        if (this.connection) {
            this.disconnectCalled = true;
            this.connection.stop(false);
            this.connection = null;
        }
    }

    notifyClientIdledOut() {
        var deferred = this.$q.defer();
        try {
            this.hubProxy.invoke('ClientIdledOut').done(() => {
                deferred.resolve({});
            })
            .fail(() => {
                deferred.reject();
            });
        } catch (error) {
            this.$state.go('account.login');
        }
        return deferred.promise;
    }

    notifySessionIsMobile() {
        try {
            this.hubProxy.invoke('SetSessionIsMobile')
            .done(() => {})
            .fail(() => {});
        } catch (error) {
            this.$state.go('account.login');
        }
    }

    notifyFileClosed(fileId) {
        var deferred = this.$q.defer();
        try {
            this.hubProxy.invoke('FileClosed', fileId)
            .done(() => {
                deferred.resolve({});
            })
            .fail(() => {
                deferred.reject();
            });
        } catch (error) {
            this.$state.go('account.login');
        }
        return deferred.promise;
    }
    notifyFileOpen(fileId, previousConnectionId = null) {
        try {
            this.hubProxy.invoke('fileOpened', { fileId, previousConnectionId })
            .done(() => {
                //
            })
            .fail(() => {
                //??should probably do something here?
            });
        } catch (error) {
            this.$state.go('account.login');
        }
    }

    unlockFile(fileId) {
        try {
            this.hubProxy.invoke('fileClosed', fileId)
            .done(() => {
                //
            })
            .fail(() => {
                //??should probably do something here?
            });
        } catch (error) {
            this.$state.go('account.login');
        }
    }
}
