export default class solutionsController {
    constructor(
        $timeout,
        solutionsService,
        spinner,
        allModelDataService,
        forteCookieService,
        reportService,
        resourceMessagingService,
        FileSaver,
        tabNavigationService,
        materialAvailabilityService
    ) {
        'ngInject';

        this.$timeout = $timeout;
        this.solutionsService = solutionsService;
        this.spinner = spinner;
        this.allModelDataService = allModelDataService;
        this.reportService = reportService;
        this.resourceMessagingService = resourceMessagingService;
        this.FileSaver = FileSaver;
        this.tabNavigationService = tabNavigationService;
        this.materialAvailabilityService = materialAvailabilityService;
        this.forteCookieService = forteCookieService;

        this.solutionsTabStepContent = 'solutionsTabStepContent';
        this.isMemberVertical = false;
        this.designResults = null;
        this.verticalDesignResults = null;
        this.productName = '';
        this.resultText = '';
        this.productPasses;
        this.colWidths;
        this.dummyValue = 0;
        this.scrollIsVisible;
        this.solutionsData;
        this.showTJPro;
        this.showSpacing;

        this.activate();
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    naString(msg) {
        if (msg === 'na') {
            return this.resourceMessagingService.getText(msg);
        } else {
            return msg;
        }
    }

    formatResult(result) {
        // Currently NOT called:
        // Solutions does not display design results for selected product
        return this.reportService.assembleMessage(result);
    }

    printPDF() {
        this.spinner.spinnerShow(this.getText('mainform_menu_print'));
        this.reportService
            .updateAnyJobLevelInfoThatMightHaveChanged()
            .then(() => {
                this.prData = this.allModelDataService.getPostResponseData();
                this.prData.ApplicationData.JobSettingsData.JobNotes =
                    this.prData.ApplicationData.JobSettingsData.JobNotes.replace(
                        /\n/g,
                        '<br/>'
                    );
                // print after digest cycle
                this.$timeout(
                    () => {
                        this.printElementPDF(
                            document.getElementById('reportDocument'),
                            true
                        );
                    },
                    0,
                    false
                );
            });
    }

    activate() {
        this.isMemberVertical = this.allModelDataService.getIsMemberVertical();

        this._initColumnWidths();
        this.spinner.spinnerShow(this.getText('jobSettingsResetProcessing'));

        var solutionsGridData = this.allModelDataService.getSolutionsGridData();
        if (this.isMemberVertical === true) {
            if (solutionsGridData === null) {
                let suggestSolutions =
                    this.forteCookieService.getSuggestedSolutionsEnabled();
                this.solutionsService
                    .getVerticalSolutionsData(suggestSolutions)
                    .then((verticalResponse) => {
                        this.solutionsData = verticalResponse.gridData;
                        this.allModelDataService.setSolutionsGridData(
                            this.solutionsData
                        );
                        this.verticalDesignResults =
                            verticalResponse.verticalMemberReport.VerticalDesignResultsDTO;
                        this.setSolutionsData(
                            verticalResponse.verticalMemberReport
                                .MiscellaneousDataDTO[0]
                        );
                    });
            } else {
                this.solutionsData = solutionsGridData;
                var prData = this.allModelDataService.getPostResponseData();
                var reportData =
                    prData.MemberData.ReportOutputVerticalData
                        .VerticalMemberReportData;
                this.verticalDesignResults =
                    reportData.VerticalDesignResultsDTO;
                this.setSolutionsData(reportData.MiscellaneousDataDTO[0]);
            }
        } else {
            if (solutionsGridData === null) {
                let suggestSolutions =
                    this.forteCookieService.getSuggestedSolutionsEnabled();
                this.solutionsService
                    .getSolutionsData(suggestSolutions)
                    .then((horizontalResponse) => {
                        //var memberReportDTO = response.postResponseData.MemberData.ReportOutputHorizontalData.HorizontalMemberReportData;
                        this.solutionsData = horizontalResponse.gridData;
                        this.allModelDataService.setSolutionsGridData(
                            this.solutionsData
                        );
                        this.designResults =
                            horizontalResponse.memberReport.DesignResults;
                        this.setSolutionsData(
                            horizontalResponse.memberReport.MiscellaneousData[0]
                        );
                    });
            } else {
                this.solutionsData = solutionsGridData;
                var prData = this.allModelDataService.getPostResponseData();
                var reportData =
                    prData.MemberData.ReportOutputHorizontalData
                        .HorizontalMemberReportData;
                this.designResults = reportData.DesignResults;
                this.setSolutionsData(reportData.MiscellaneousData[0]);
            }
        }
    }

    setSolutionsData(miscData) {
        this.productName = miscData.ProductName;
        this.resultText = miscData.PassFailText;
        this.showOnlyPassingProducts =
            !this.allModelDataService.getPostResponseData().MemberData
                .SolutionsData.ShowAllProducts;

        if (this.solutionsData.allProductSolutions.length > 0) {
            this.productPasses =
                this.solutionsData.allProductSolutions[
                    this.solutionsData.selectedProductIndex
                ].productPasses;
        } else {
            this.productPasses = false; // no solutions - design failed
        }

        this.materialAvailabilityService
            .getmaterialAvailabilityData()
            .then((materialAvailabilityResponse) => {
                this.seriesUnavailable =
                    materialAvailabilityResponse.seriesUnavailable;
                this.seriesSpecialOrder =
                    materialAvailabilityResponse.seriesSpecialOrder;
                this.productsNotAvailable = Object.keys(materialAvailabilityResponse.productsNotAvailable).map(function(id) {
                    return Number(id);
                });
                this.productsSpecialOrder = Object.keys(materialAvailabilityResponse.productsSpecialOrder).map(function(id) {
                    return Number(id);
                });
                this._refreshAllProductSolutions();
                this.spinner.spinnerHide();
            });
    }

    showOnlyPassingChanged() {
        var prData = this.allModelDataService.getPostResponseData();
        prData.MemberData.SolutionsData.ShowAllProducts =
            !this.showOnlyPassingProducts;
        this.allModelDataService.setPostResponseData(prData);
    }

    getHeaderMargin() {
        if (this.colWidths[0]) {
            return { 'margin-top': this.colWidths[0].height.toString() + 'px' };
        }
    }

    setScrollVisibility(value) {
        this.scrollIsVisible = value;
    }

    changeSort(index) {
        this.solutionsService.changeSort(index).then((response) => {
            this.solutionsData = response.gridData;
        });
    }

    getSortClass(id) {
        if (this.solutionsData) {
            if (this.solutionsData.sortCriteria.SortItem === id) {
                if (this.solutionsData.sortCriteria.Order === 1) {
                    return 'sortDescending';
                } else {
                    return 'sortAscending';
                }
            }
        }
        return '';
    }

    selectSolution(rowIndex) {
        this.spinner.spinnerShow(this.getText('designSelectedProduct'));

        var selectedProduct = this.solutionsData.allProductSolutions[rowIndex];
        if (this.isMemberVertical === true) {
            let suggestSolutions =
                this.forteCookieService.getSuggestedSolutionsEnabled();
            this.solutionsService
                .setSelectedVerticalSolution(
                    selectedProduct.depth,
                    selectedProduct.plies,
                    selectedProduct.productId,
                    selectedProduct.spacing,
                    selectedProduct.isPlank,
                    suggestSolutions
                )
                .then(() => {
                    //highlight the newly selected product: resave modified Solutions GridData
                    this.solutionsData.selectedProductIndex = rowIndex;
                    this.allModelDataService.setSolutionsGridData(
                        this.solutionsData
                    );
                    var postResponseData =
                        this.allModelDataService.getPostResponseData();
                    var verticalMemberReport =
                        postResponseData.MemberData.ReportOutputVerticalData
                            .VerticalMemberReportData;
                    var miscData = verticalMemberReport.MiscellaneousDataDTO[0];
                    this.productName = miscData.ProductName;
                    this.resultText = miscData.PassFailText;
                    this.verticalDesignResults =
                        verticalMemberReport.VerticalDesignResultsDTO;
                    this.productPasses =
                        this.solutionsData.allProductSolutions[
                            rowIndex
                        ].productPasses;
                    this.spinner.spinnerHide();
                });
        } else {
            let suggestSolutions =
                this.forteCookieService.getSuggestedSolutionsEnabled();
            this.solutionsService
                .setSelectedSolution(
                    selectedProduct.depth,
                    selectedProduct.plies,
                    selectedProduct.productId,
                    selectedProduct.spacing,
                    selectedProduct.isPlank,
                    suggestSolutions
                )
                .then(() => {
                    //highlight the newly selected product: resave modified Solutions GridData
                    this.solutionsData.selectedProductIndex = rowIndex;
                    this.allModelDataService.setSolutionsGridData(
                        this.solutionsData
                    );
                    var postResponseData =
                        this.allModelDataService.getPostResponseData();
                    var memberReport =
                        postResponseData.MemberData.ReportOutputHorizontalData
                            .HorizontalMemberReportData;
                    var miscData = memberReport.MiscellaneousData[0];
                    this.productName = miscData.ProductName;
                    this.resultText = miscData.PassFailText;
                    this.designResults = memberReport.DesignResults;
                    this.productPasses =
                        this.solutionsData.allProductSolutions[
                            rowIndex
                        ].productPasses;
                    this.spinner.spinnerHide();
                });
        }

        this.tabNavigationService._executeTabSwitch(
            'application.job.report',
            9
        );
    }

    _refreshAllProductSolutions() {
        switch (this.solutionsData.columnCount) {
            case 4:
                this.showSpacing = false;
                this.showTJPro = false;
                break;
            case 5:
                this.showSpacing = true;
                this.showTJPro = false;
                break;
            default:
                this.showSpacing = true;
                this.showTJPro = true;
                break;
        }
    }

    _initColumnWidths() {
        this.colWidths = [];
        this.scrollIsVisible = false;
        for (var i = 0; i < 7; i++) {
            this.colWidths[i] = 0;
        }
    }

    printElementPDF(elem, append, delimiter) {
        if (!this.graphicDataURL) {
            this.graphicDataURL = this.allModelDataService.getGraphicDataURL();
        }
        var prData = this.allModelDataService.getPostResponseData();
        var projectName = prData.ApplicationData.ProjectManagerData.ProjectName;
        var memberName =
            prData.ApplicationData.MemberManagerData.MemberContainers[
                prData.ApplicationData.MemberManagerData.SelectedContainerID
            ].Members[prData.ApplicationData.MemberManagerData.SelectedMemberID]
                .MemberName;
        var solutionsText = this.getText('mainform_component_solutions');
        var pdfFileName =
            projectName + '_' + memberName + '_' + solutionsText + '.pdf';
        // eslint-disable-next-line quotes
        pdfFileName = pdfFileName.replace(/["]/g, "''");
        var cleanedName = pdfFileName.replace(/[|&;$%@"<>()+,]/g, '');
        this.solutionsService
            .getSolutionsPDF(
                this.graphicDataURL,
                this.allModelDataService.getGraphicOverlayLabels(),
                this.solutionsData
            )
            .then((response) => {
                var fileData = new Blob([response.data], {
                    type: 'application/pdf',
                });
                this.FileSaver.saveAs(fileData, cleanedName);

                this.spinner.spinnerHide();
            });
    }
}
// (function () {
//     'use strict';
//     var app = angular.module('app');

//     app.controller('solutionsController', ['$scope', '$location', '$timeout', '$state', 'uiTourService',
//         'tabNavigationService', 'solutionsService', 'spinner', 'allModelDataService', 'reportService',
//         'resourceMessagingService', 'FileSaver', 'notifier',
//         function ($scope, $location, $timeout, $state, uiTourService, tabNavigationService, solutionsService,
//             spinner, allModelDataService, reportService, resourceMessagingService, FileSaver, notifier) {
//             //controller code goes here.
//             /* jshint validthis: true */
//             var vm = this;
//             //methods
//             vm.getText = getText;
//             vm.selectSolution = selectSolution;
//             vm.getHeaderMargin = getHeaderMargin;
//             vm.setScrollVisibility = setScrollVisibility;
//             vm.getSortClass = getSortClass;
//             vm.changeSort = changeSort;
//             vm.showOnlyPassingChanged = showOnlyPassingChanged;
//             vm.startTabsTour = startTabsTour;
//             vm.setActionBtn = setActionBtn;
//             vm.nextForExistingUser = nextForExistingUser;
//             vm.formatResult = formatResult;
//             vm.printPDF = printPDF;
//             vm.naString = naString;

//             //variables
//             vm.isMemberVertical = false;
//             vm.designResults = null;
//             vm.verticalDesignResults = null;
//             vm.productName = '';
//             vm.resultText = '';
//             vm.productPasses;
//             vm.colWidths;
//             vm.dummyValue = 0;
//             vm.scrollIsVisible;
//             vm.solutionsData;
//             vm.showTJPro;
//             vm.showSpacing;

//             vm.solutionsTabStepContent = 'solutionsTabStepContent';
//             var solutionsTour = uiTourService.getTourByName('solutionsTour');
//             solutionsTour.previousBtn = getText('prev');
//             solutionsTour.nextBtn = getText('laNext');
//             solutionsTour.endTourBtn = getText('endTour');
//             var tabsTour = uiTourService.getTourByName('tabsTour');
//             tabsTour.previousBtn = getText('prev');
//             tabsTour.nextBtn = getText('laNext');
//             tabsTour.endTourBtn = getText('endTour');

//             activate();

//             function getText(key) {
//                 return resourceMessagingService.getText(key);
//             }

//             function naString(msg) {
//                 if (msg === 'na') {
//                     return resourceMessagingService.getText(msg);
//                 } else {
//                     return msg;
//                 }
//             }

//             function formatResult(result) {
//                 // Currently NOT called:
//                 // Solutions does not display design results for selected product
//                 return reportService.assembleMessage(result);
//             }

//             function setActionBtn(prev, next) {
//                 solutionsTour.prevDisabled = prev;
//                 solutionsTour.nextDisabled = next;
//             }

//             function printPDF() {
//                 spinner.spinnerShow(vm.getText('mainform_menu_print'));
//                 reportService.updateAnyJobLevelInfoThatMightHaveChanged().then(function () {
//                     vm.prData = allModelDataService.getPostResponseData();
//                     vm.prData.ApplicationData.JobSettingsData.JobNotes = vm.prData.ApplicationData.JobSettingsData.JobNotes.replace(/\n/g, '<br/>');
//                     //$scope.JobNotesTrustedHtml = $sce.trustAsHtml(vm.prData.ApplicationData.JobSettingsData.JobNotes);
//                     // print after digest cycle
//                     $timeout(function () {
//                         //printElement(document.getElementById('reportDocument'), true);
//                         printElementPDF(document.getElementById('reportDocument'), true);
//                     }, 0, false);
//                 });
//             }

//             function nextForExistingUser() {
//                 if (solutionsTour.isExistingUser === 'true') {
//                     tabsTour.previousStep = 'solutionsStep0';
//                     startTabsTour();
//                 }
//             }

//             function startTabsTour() {
//                 solutionsTour.end().then(function () {
//                     tabsTour.previousBtn = getText('prev');
//                     tabsTour.nextBtn = getText('laNext');
//                     tabsTour.endTourBtn = getText('endTour');
//                     tabsTour.start();
//                 });
//             }

//             function activate() {
//                 vm.isMemberVertical = allModelDataService.getIsMemberVertical();

//                 if (tabsTour.getStatus() === 1 && tabsTour.isExistingUser !== 'true') {
//                     tabsTour.end().then(function () {
//                         solutionsTour.start();
//                     });
//                 } else if (tabsTour.getStatus() === 1 && tabsTour.isExistingUser === 'true') {
//                     tabsTour.end().then(function () {
//                         vm.solutionsTabStepContent = 'solutionsTabStep2Content';
//                         solutionsTour.startAt('solutionsStep0');
//                         tabsTour.prevClicked = false;
//                         tabsTour.nextClicked = false;
//                         tabsTour.nextStepIs = '';
//                     });
//                 }
//                 _initColumnWidths();
//                 spinner.spinnerShow(getText('jobSettingsResetProcessing'));

//                 if (vm.isMemberVertical === true) {
//                     solutionsService.getVerticalSolutionsData().then(function (verticalResponse) {
//                         vm.solutionsData = verticalResponse.gridData;
//                         vm.verticalDesignResults = verticalResponse.verticalMemberReport.VerticalDesignResultsDTO;
//                         setSolutionsData(verticalResponse.verticalMemberReport.MiscellaneousDataDTO[0]);
//                     });
//                 } else {
//                     solutionsService.getSolutionsData().then(function (horizontalResponse) {
//                         //var memberReportDTO = response.postResponseData.MemberData.ReportOutputHorizontalData.HorizontalMemberReportData;
//                         vm.solutionsData = horizontalResponse.gridData;
//                         vm.designResults = horizontalResponse.memberReport.DesignResults;
//                         setSolutionsData(horizontalResponse.memberReport.MiscellaneousData[0]);
//                     });
//                 }

//                 // solutionsService.getSolutionsData().then(function (response) {
//                 //     vm.solutionsData = response.gridData;
//                 //     //vm.designResults = response.memberReport.DesignResults;
//                 //     //var miscData = response.memberReport.MiscellaneousData[0];
//                 //     var memberReportDTO = response.postResponseData.MemberData.ReportOutputHorizontalData.HorizontalMemberReportData;
//                 //     vm.designResults = memberReportDTO.DesignResults;
//                 //     var miscData = memberReportDTO.MiscellaneousData[0];
//                 //     vm.showOnlyPassingProducts = !allModelDataService.getPostResponseData().MemberData.SolutionsData.ShowAllProducts;
//                 //     vm.productName = miscData.ProductName;
//                 //     vm.resultText = miscData.PassFailText;
//                 //     if (vm.solutionsData.allProductSolutions.length > 0)
//                 //     {
//                 //         vm.productPasses = vm.solutionsData.allProductSolutions[vm.solutionsData.selectedProductIndex].productPasses;
//                 //     }
//                 //     else {
//                 //         //if there are no solutions in all, then the design failed
//                 //         vm.productPasses = false;
//                 //     }
//                 //     _refreshAllProductSolutions();
//                 //     spinner.spinnerHide();
//                 // });
//             }

//             function setSolutionsData(miscData) {
//                 vm.productName = miscData.ProductName;
//                 vm.resultText = miscData.PassFailText;
//                 vm.showOnlyPassingProducts = !allModelDataService.getPostResponseData().MemberData.SolutionsData.ShowAllProducts;

//                 if (vm.solutionsData.allProductSolutions.length > 0) {
//                     vm.productPasses = vm.solutionsData.allProductSolutions[vm.solutionsData.selectedProductIndex].productPasses;
//                 } else {
//                     vm.productPasses = false; // no solutions - design failed
//                 }

//                 _refreshAllProductSolutions();
//                 spinner.spinnerHide();
//             }

//             function showOnlyPassingChanged() {
//                 var prData = allModelDataService.getPostResponseData();
//                 prData.MemberData.SolutionsData.ShowAllProducts = !vm.showOnlyPassingProducts;
//                 allModelDataService.setPostResponseData(prData);
//             }

//             function getHeaderMargin() {
//                 if (vm.colWidths[0]) {
//                     return {
//                         'margin-top': vm.colWidths[0].height.toString() + 'px'
//                     };
//                 }
//             };

//             function setScrollVisibility(value) {
//                 vm.scrollIsVisible = value;
//             };

//             function changeSort(index) {
//                 solutionsService.changeSort(index).then(function (response) {
//                     vm.solutionsData = response.gridData;
//                 });
//             }

//             function getSortClass(id) {
//                 if (vm.solutionsData) {
//                     if (vm.solutionsData.sortCriteria.SortItem === id) {
//                         if (vm.solutionsData.sortCriteria.Order === 1) {
//                             return 'sortDescending';
//                         } else {
//                             return 'sortAscending';
//                         }
//                     }
//                 }
//                 return '';
//             }

//             function selectSolution(rowIndex) {

//                 spinner.spinnerShow(getText('designSelectedProduct'));

//                 var selectedProduct = vm.solutionsData.allProductSolutions[rowIndex];
//                 if (vm.isMemberVertical === true) {
//                     solutionsService.setSelectedVerticalSolution(selectedProduct.depth, selectedProduct.plies,
//                         selectedProduct.productId, selectedProduct.spacing, selectedProduct.isPlank).then(function() {
//                             //highlight the newly selected product.
//                             vm.solutionsData.selectedProductIndex = rowIndex;
//                             var postResponseData = allModelDataService.getPostResponseData();
//                             var verticalMemberReport = postResponseData.MemberData.ReportOutputVerticalData.VerticalMemberReportData;
//                             var miscData = verticalMemberReport.MiscellaneousDataDTO[0];
//                             vm.productName = miscData.ProductName;
//                             vm.resultText = miscData.PassFailText;
//                             vm.verticalDesignResults = verticalMemberReport.VerticalDesignResultsDTO;
//                             vm.productPasses = vm.solutionsData.allProductSolutions[rowIndex].productPasses;
//                             spinner.spinnerHide();
//                         });
//                 }
//                 else {
//                     solutionsService.setSelectedSolution(selectedProduct.depth, selectedProduct.plies,
//                         selectedProduct.productId, selectedProduct.spacing, selectedProduct.isPlank).then(function() {
//                             //highlight the newly selected product.
//                             vm.solutionsData.selectedProductIndex = rowIndex;
//                             var postResponseData = allModelDataService.getPostResponseData();
//                             var memberReport = postResponseData.MemberData.ReportOutputHorizontalData.HorizontalMemberReportData;
//                             var miscData = memberReport.MiscellaneousData[0];
//                             vm.productName = miscData.ProductName;
//                             vm.resultText = miscData.PassFailText;
//                             vm.designResults = memberReport.DesignResults;
//                             vm.productPasses = vm.solutionsData.allProductSolutions[rowIndex].productPasses;
//                             spinner.spinnerHide();
//                         });
//                 }

//                 tabNavigationService._executeTabSwitch('application.job.report', 9);
//             }

//             function _refreshAllProductSolutions() {
//                 switch (vm.solutionsData.columnCount) {
//                     case 4:
//                         vm.showSpacing = false;
//                         vm.showTJPro = false;
//                         break;
//                     case 5:
//                         vm.showSpacing = true;
//                         vm.showTJPro = false;
//                         break;
//                     default:
//                         vm.showSpacing = true;
//                         vm.showTJPro = true;
//                         break;
//                 }
//             }

//             function _initColumnWidths() {
//                 vm.colWidths = [];
//                 vm.scrollIsVisible = false;
//                 for (var i = 0; i < 7; i++) {
//                     vm.colWidths[i] = 0;
//                 }
//             }

//             function printElementPDF(elem, append, delimiter) {
//                 if (!vm.graphicDataURL) {
//                     vm.graphicDataURL = allModelDataService.getGraphicDataURL();
//                 }
//                 var prData = allModelDataService.getPostResponseData();
//                 var projectName = prData.ApplicationData.ProjectManagerData.ProjectName;
//                 var memberName = prData.ApplicationData.MemberManagerData.MemberContainers[prData.ApplicationData.MemberManagerData.SelectedContainerID].Members[prData.ApplicationData.MemberManagerData.SelectedMemberID].MemberName;
//                 var solutionsText = getText('mainform_component_solutions');
//                 var pdfFileName = projectName + '_' + memberName + '_' + solutionsText + '.pdf';

//                 var cleanedName = pdfFileName.replace(/[|&;$%@"<>()+,]/g, '');
//                 solutionsService.getSolutionsPDF(vm.graphicDataURL, allModelDataService.getGraphicOverlayLabels(), vm.solutionsData).then(function(response) {                    var fileData = new Blob([ response.data ], {type: 'application/pdf'});
//                     FileSaver.saveAs(fileData, cleanedName);

//                     spinner.spinnerHide();
//                 });
//             }
//         }
//     ]);
// }());
