export default class spansService {
    constructor(common, allModelDataService, postService) {
        'ngInject';

        this.$q = common.$q();
        this.allModelDataService = allModelDataService;
        this.postService = postService;

        this.inputQueuePromise;
        this.httpRequestQueueCounter = 0;
        this.somethingWaitingOnQueueToFinish = false;
    }

    getQueueCounter() {
        this.inputQueuePromise = this.$q.defer();
        if (this.httpRequestQueueCounter === 0)
        {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.somethingWaitingOnQueueToFinish = true;
        }
        return this.inputQueuePromise.promise;
    }

    getSpansData() {
        var data = {};
        return this._requestPostEvent('GetSpansData', data);
    }

    addSpan(spanLength) {
        var data = {
            'length': spanLength
        };

        return this._requestPostEvent('AddSpan', data);
    }

    modifyInteriorSpan(spanIndex, spanLength) {
        var data = {
            'spanIndex': spanIndex,
            'newLength': spanLength
        };

        return this._requestPostEvent('ModifyInteriorSpan', data);
    }

    modifyLeftOverhang(spanLength) {
        var data = {
            'length': spanLength
        };

        return this._requestPostEvent('ModifyLeftOverhang', data);
    }

    modifyRightOverhang(spanLength) {
        var data = {
            'length': spanLength
        };

        return this._requestPostEvent('ModifyRightOverhang', data);
    }

    saveSpansAsDefaults() {
        var currentPostResponseData = this.allModelDataService.getPostResponseData();
        return this._requestPostEvent('SaveSpansAndSupportsAsDefaults', currentPostResponseData);
    }

    _requestPostEvent(spanEvent, spanData) {
        var path = 'api/Spans/';
        return this.postService.requestPostEvent(spanEvent, spanData, path);
    }

    _incrementQueueCounter() {
        this.httpRequestQueueCounter++;
    }
    _decrementQueueCounter() {
        this.httpRequestQueueCounter--;
        if (this.somethingWaitingOnQueueToFinish && this.httpRequestQueueCounter === 0) {
            this.somethingWaitingOnQueueToFinish = false;
            this.inputQueuePromise.resolve();
        }
    }
}
// (function () {
//     'use strict';
//     var app = angular.module('app');
//     app.factory('spansService', ['$http', 'ngAuthSettings', 'common', 'allModelDataService', 'postService',
//         function ($http, ngAuthSettings, common, allModelDataService, postService) {
//             var $q = common.$q;
//             var serviceBase = ngAuthSettings.apiServiceBaseUri;
//             var httpRequestQueueCounter = 0;
//             var inputQueuePromise;
//             var somethingWaitingOnQueueToFinish = false;
//             var service = {
//                 getQueueCounter: getQueueCounter,
//                 getSpansData: getSpansData,
//                 addSpan: addSpan,
//                 modifyInteriorSpan: modifyInteriorSpan,
//                 modifyLeftOverhang: modifyLeftOverhang,
//                 modifyRightOverhang: modifyRightOverhang,
//                 saveSpansAsDefaults: saveSpansAsDefaults
//             };

//             function getQueueCounter() {
//                 inputQueuePromise = $q.defer();
//                 if (httpRequestQueueCounter === 0)
//                 {
//                     inputQueuePromise.resolve();
//                     return inputQueuePromise.promise;
//                 }
//                 else {
//                     somethingWaitingOnQueueToFinish = true;
//                 }
//                 return inputQueuePromise.promise;
//             }

//             function getSpansData() {
//                 var data = {};
//                 return _requestPostEvent('GetSpansData', data);
//             }

//             function addSpan(spanLength) {
//                 var data = {
//                     'length': spanLength
//                 };

//                 return _requestPostEvent('AddSpan', data);
//             }

//             function modifyInteriorSpan(spanIndex, spanLength) {
//                 var data = {
//                     'spanIndex': spanIndex,
//                     'newLength': spanLength
//                 };

//                 return _requestPostEvent('ModifyInteriorSpan', data);
//             }

//             function modifyLeftOverhang(spanLength) {
//                 var data = {
//                     'length': spanLength
//                 };

//                 return _requestPostEvent('ModifyLeftOverhang', data);
//             }

//             function modifyRightOverhang(spanLength) {
//                 var data = {
//                     'length': spanLength
//                 };

//                 return _requestPostEvent('ModifyRightOverhang', data);
//             }

//             function saveSpansAsDefaults() {
//                 var currentPostResponseData = allModelDataService.getPostResponseData();
//                 return _requestPostEvent('SaveSpansAndSupportsAsDefaults', currentPostResponseData);
//             }

//             function _requestPostEvent(spanEvent, spanData) {
//                 var path = 'api/Spans/';
//                 return postService.requestPostEvent(spanEvent, spanData, path);
//             }

//             function _incrementQueueCounter() {
//                 httpRequestQueueCounter++;
//             }
//             function _decrementQueueCounter() {
//                 httpRequestQueueCounter--;
//                 if (somethingWaitingOnQueueToFinish && httpRequestQueueCounter === 0) {
//                     somethingWaitingOnQueueToFinish = false;
//                     inputQueuePromise.resolve();
//                 }
//             }

//             return service;
//         }]);
// }());
