import toastr from 'toastr';

export default class locationAnalysisController {
    constructor($uibModal, $timeout, locationAnalysisService,
        notchedFlangeService, spinner, allModelDataService, dirtyCheckService, resourceMessagingService) {
        'ngInject';

        this.$uibModal = $uibModal;
        this.$timeout = $timeout;
        this.locationAnalysisService = locationAnalysisService;
        this.notchedFlangeService = notchedFlangeService;
        this.spinner = spinner;
        this.allModelDataService = allModelDataService;
        this.dirtyCheckService = dirtyCheckService;
        this.resourceMessagingService = resourceMessagingService;

        this.locationData = null;
        this.metaData = null;
        this.rowData = null;
        this.notes = null;

        this.isFloorJoist = false;
        this.isResidential = allModelDataService.isResidential();
        this.addedLocation = false;
        this.isEnglish = resourceMessagingService.isCultureEnglish();


        this.selectedColumnNumber;
        this.selectedRowNumber;

        this.stepDirection = '';

        this.notchedFlangeModelData = null;
        this.notchedFlangeMetaData = null;

        this.$locationAccordion = $('#locationAccordion');
        this.$locationAccordion.on('show.bs.collapse', '.collapse', () => {
            this.$locationAccordion.find('.collapse.in').collapse('hide');
        });

        this.activate();
    }

    activate() {
        //getLocationMetaData();
        // getLocationData();
        // getNotes();
        this.startSpinner();
        this.getAllData();
        this.getNotchedFlangeMetaData();

        this.rowData = [{
            headerText: 'Location:',
            counter: 0
        }, {
            headerText: 'Comments:',
            counter: 1
        }];
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    keyDown(isPending, $event) {
        //ignore everything accept navigation input if the fidle is "pending"
        if (isPending) {
            if (!($event.keyCode === 9 || $event.keyCode === 16)) { //tab and shift keycodes
                $event.stopPropagation();
                $event.preventDefault();
                //console.log('stopped: ' + $event.keyCode);
                return false;
            }
        }
    }

    keyPress($event) {
        if ($event.keyCode === 13) {
            $event.preventDefault();
        }
    }

    addLocation() {
        this.addedLocation = true;
        this.locationAnalysisService.addLocation().then((response) => {
            this.getLocationData();


            toastr.options = {
                'positionClass': 'toast-top-center',
            };
            toastr.success(this.getText('SuccessfullyAdded'));
            this.$locationAccordion.find('.collapse.in').collapse('hide');
        });
    }

    deleteLocation(locationIndex) {
        this.addedLocation = false;
        this.locationAnalysisService.deleteLocation(locationIndex)
            .then((response) => {
                this.getLocationData();

                toastr.options = {
                    'positionClass': 'toast-top-center',
                };
                toastr.warning(this.getText('SuccessfullyDeleted'));

                this.$locationAccordion.find('.collapse.in').removeClass('in');
            });
    }

    getLocationData() {
        this.locationData = this.locationAnalysisService.getLocationData();
    }

    getIsFloorJoist() {
        var prData = this.allModelDataService.getPostResponseData();
        var selectedContainerID = prData.ApplicationData.MemberManagerData.SelectedContainerID;
        var selectedMemberID = prData.ApplicationData.MemberManagerData.SelectedMemberID;
        var memberData = prData.ApplicationData.MemberManagerData.MemberContainers[selectedContainerID].Members[selectedMemberID];
        return memberData.StructuralSystem === 1 && memberData.MemberType === 1;
    }

    getLocationMetaData() {
        this.locationAnalysisService.getLocationMetaData().then((Response) => {
            this.metaData = Response;
        });
    }

    getNotes() {
        this.locationAnalysisService.getNotes().then((Response) => {
            this.notes = Response;
        });
    }

    getAllData() {
        this.locationAnalysisService.getAllData().then((Response) => {
            this.notes = Response.Notes;
            this.locationData = Response.Data;
            this.rowData = Response.MetaData;
            this.isFloorJoist = this.getIsFloorJoist();  // ToDo: code review
            this.spinner.spinnerHide();
            this.formLocationAnaylsis.$setPristine();

        });

        this.getNotchedFlangeDataFromPRData();
    }

    startSpinner() {
        this.spinner.spinnerShow(this.getText('loading'));
    }

    deselectAll() {
        this.selectedRowNumber = -1;
        this.selectedColumnNumber = -1;
    }

    locationChanged(event, index, newLocation) {
        if (this.dirtyCheckService.isDirty(event)) {
            this.locationData[index].pending = true;
            this.locationAnalysisService.modifyLocation(index, newLocation)
                .then((response) => {
                    this.getLocationData();
                });
            this.deselectAll();
        }
    }

    locationCommentChanged(event, index, newComment) {
        if (this.dirtyCheckService.isDirty(event)) {
            this.locationData[index].pending = true;
            this.locationAnalysisService.modifyLocationComment(index, newComment)
                .then((response) => {
                    this.getLocationData();
                });
            this.deselectAll();
        }
    }

    inputGotFocus(rowIndex, columnIndex) {
        this.selectedRowNumber = rowIndex;
        this.selectedColumnNumber = columnIndex;
    }

    selectAllTextOnFocus($event, rowIndex, columnIndex) {
        $event.target.select();
        this.inputGotFocus(rowIndex, columnIndex);
    }

    modifyLocationAnalysisComment(index, locationComment) {

        var modalInstance = this.$uibModal.open({
            animation: true,
            templateUrl: 'modifyCommentModal.html',
            controller: 'holesModifyCommentController',
            controllerAs: 'modifyCommentCtrl',
            windowClass: 'connectorDialog',
            backdrop: 'static',
            size: 'md',
            resolve: {
                items: {
                    comment: locationComment
                }
            }
        });

        modalInstance.result.then((newComment) => {
            this.locationData[index].pending = true;
            this.locationAnalysisService.modifyLocationComment(index, newComment).then(() => {
                this.getLocationData();
            });
        }, () => {
            this.getLocationData();
        }).finally(() => {
        });

        this.deselectAll();
    }

    modifyNotchedFlangeComment() {
        var modalInstance = this.$uibModal.open({
            animation: true,
            templateUrl: 'modifyCommentModal.html',
            controller: 'holesModifyCommentController',
            controllerAs: 'modifyCommentCtrl',
            windowClass: 'connectorDialog',
            backdrop: 'static',
            size: 'md',
            resolve: {
                items: {
                    comment: this.notchedFlangeModelData.Comment
                }
            }
        });

        modalInstance.result.then((newComment) => {
            this.notchedFlangeModelData.pending = true;
            this.notchedFlangeService.modifyComment(0, newComment).then((response) => {
                this.getNotchedFlangeDataFromPRData();
            });
        }, () => {
            this.notchedFlangeModelData.pending = true;
            this.getNotchedFlangeDataFromPRData();
        });

        this.deselectAll();
    }

    notchCommentChanged(event) {
        if (this.dirtyCheckService.isDirty(event)) {
            this.notchedFlangeModelData.pending = true;
            this.notchedFlangeService.modifyComment(0, this.notchedFlangeModelData.Comment).then((response) => {
                this.getNotchedFlangeDataFromPRData();
            });
        }
    }

    //Notched Flange Implementation - Start
    getNotchedFlangeDataFromPRData() {
        this.notchedFlangeData = this.allModelDataService.getPostResponseData().MemberData.NotchedFlangeData;
        if (this.notchedFlangeData.NotchList.length > 0) {
            this.notchedFlangeModelData = this.notchedFlangeData.NotchList[0];
        } else {
            this.notchedFlangeModelData = null;
        }
    }

    isNotchedFlangeEmpty() {
        if (this.notchedFlangeData.NotchList.length === 0) {
            return true;
        }
        return false;
    }

    getNotchedFlangeMetaData() {
        this.notchedFlangeService.getMetaData().then((Response) => {
            this.notchedFlangeMetaData = Response;
        });
    }

    addNotch() {
        this.notchedFlangeService.addNotch().then((response) => {
            this.getNotchedFlangeDataFromPRData();
        });
    }

    deleteNotch() {
        this.notchedFlangeModelData.pending = true;
        this.notchedFlangeService.deleteNotch(0).then((response) => {
            this.getNotchedFlangeDataFromPRData();
        });
    }

    notchInputTypeChanged(event) {
        this.notchedFlangeModelData.pending = true;
        this.notchedFlangeService.modifyInputType(0, this.notchedFlangeModelData.Type).then((response) => {
            this.getNotchedFlangeDataFromPRData();
        });
    }

    notchLocationChanged(event) {
        if (this.dirtyCheckService.isDirty(event)) {
            this.notchedFlangeModelData.pending = true;
            this.notchedFlangeService.modifyLocation(0, this.notchedFlangeModelData._locationFriendly).then((response) => {
                this.getNotchedFlangeDataFromPRData();
            });
        }
    }

    notchLengthChanged(event) {
        if (this.dirtyCheckService.isDirty(event)) {
            this.notchedFlangeModelData.pending = true;
            this.notchedFlangeService.modifyLength(0, this.notchedFlangeModelData._notchLengthFriendly).then((response) => {
                this.getNotchedFlangeDataFromPRData();
            });
        }
    }

    notchDepthChanged(event, newDepth) {
        if (this.dirtyCheckService.isDirty(event)) {
            this.notchedFlangeModelData.pending = true;
            this.notchedFlangeService.modifyDepth(0, this.notchedFlangeModelData._notchDepthFriendly).then((response) => {
                this.getNotchedFlangeDataFromPRData();
            });
        }
    }

}
