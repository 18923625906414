import { all } from '@uirouter/core';
import languageSelectorTemplate from './languageSelector.html';
import resourceMessagingDictionaries from '../resourceDict';

export default class languageSelector {
    constructor() {
        this.template = languageSelectorTemplate;
        this.scope = {

        };
    }
    controller($scope, $rootScope, resourceMessagingService, programSettingsService, forteCookieService, allModelDataService, tabNavigationService) {
        'ngInject';
        $scope.$rootScope = $rootScope;
        $scope.resourceMessagingService = resourceMessagingService;
        $scope.programSettingsService = programSettingsService;
        $scope.forteCookieService = forteCookieService;
        $scope.allModelDataService = allModelDataService;
        $scope.tabNavigationService = tabNavigationService;
    }

    link(scope, element, attrs) {
        scope.changeLanguage = changeLanguage;
        scope.getText = getText;
        scope.languageList = scope.resourceMessagingService.getLanguageOptions();
        scope.metaData = null;

        function getText(key) {
            return scope.resourceMessagingService.getText(key);
        }

        function changeLanguage(selectedCulture) {
            for (var i = 0; i < scope.languageList.length; i++) {
                if (scope.languageList[i].culture === selectedCulture) {
                    scope.languageList[i].isSelected = true;
                }
                else {
                    scope.languageList[i].isSelected = false;
                }

            }

            // language switch:
            // Set Culture code cookie for request header and
            // Set Culture for client side dictionary access
            switch (selectedCulture) {
                case 'fr-CA':
                    scope.forteCookieService.setForteWebCultureFrenchCA();
                    break;
                default:
                    scope.forteCookieService.setForteWebCultureEnglishUS();
                    break;
            }

            var authData = scope.allModelDataService.getAuthorizationData();
            if (authData) {
                var postData = {
                    NewCultureCode: selectedCulture
                };
                scope.programSettingsService.cultureChanged(postData).then((serviceResponse) => {
                    if (scope.forteCookieService.getIsGraphicVisible() || scope.tabNavigationService.isReportTabEnabled()) {
                        var prData = scope.allModelDataService.getPostResponseData();
                        if (prData !== null) {
                            if (prData.MemberData) {
                                if (prData.MemberData.GraphicOutputData) {
                                    //pause this execution because the graphic container has to be visible on
                                    //the screen for the graphic text layer to render in the proper place.
                                    setTimeout(() => {
                                        if (scope.forteCookieService.getIsGraphicVisible()) {
                                            scope.$rootScope.$broadcast('refreshGraphic_main', prData.MemberData.GraphicOutputData);
                                        }
                                        if (scope.tabNavigationService.isReportTabEnabled())
                                        {
                                            scope.$rootScope.$broadcast('refreshGraphic_report', prData.MemberData.GraphicOutputData);
                                        }
                                    }, 600);
                                }
                            }
                        }
                    }
                });
            }
        }
    }
}
// (function () {
//     'use strict';
//     angular.module('app').directive('languageSelector', function() {
//         return {
//             restrict: 'A',
//             controller: ['$rootScope','resourceMessagingService', 'programSettingsService', 'forteCookieService', 'allModelDataService',
//                 'tabNavigationService',
//                 function($rootScope, resourceMessagingService, programSettingsService, forteCookieService, allModelDataService, tabNavigationService) {
//                     var vm = this;
//                     vm.languageList = [];
//                     vm.changeLanguage = changeLanguage;
//                     vm.metaData = null;

//                     function changeLanguage(selectedCulture) {
//                         for (var i = 0; i < vm.languageList.length; i++) {
//                             if (vm.languageList[i].culture === selectedCulture) {
//                                 vm.languageList[i].isSelected = true;
//                             }
//                             else {
//                                 vm.languageList[i].isSelected = false;
//                             }

//                         }

//                         // language switch:
//                         // Set Culture code cookie for request header and
//                         // Set Culture for client side dictionary access
//                         switch (selectedCulture) {
//                             case 'fr-CA':
//                                 forteCookieService.setForteWebCultureFrenchCA();
//                                 break;
//                             default:
//                                 forteCookieService.setForteWebCultureEnglishUS();
//                                 break;
//                         }

//                         var authData = allModelDataService.getAuthorizationData();
//                         if (authData) {
//                             var postData = {
//                                 NewCultureCode: selectedCulture
//                             };
//                             programSettingsService.cultureChanged(postData).then(function (serviceResponse) {
//                                 if (forteCookieService.getIsGraphicVisible() || tabNavigationService.isReportTabEnabled()) {
//                                     var prData = allModelDataService.getPostResponseData();
//                                     if (prData !== null) {
//                                         if (prData.MemberData) {
//                                             if (prData.MemberData.GraphicOutputData) {
//                                               //pause this execution because the graphic container has to be visible on
//                                               //the screen for the graphic text layer to render in the proper place.
//                                                 setTimeout(function () {
//                                                     if (forteCookieService.getIsGraphicVisible()) {
//                                                         $rootScope.$broadcast('refreshGraphic_main', prData.MemberData.GraphicOutputData);
//                                                     }
//                                                     if (tabNavigationService.isReportTabEnabled())
//                                                     {
//                                                         $rootScope.$broadcast('refreshGraphic_report', prData.MemberData.GraphicOutputData);
//                                                     }
//                                                 }, 600);
//                                             }
//                                         }
//                                     }
//                                 }
//                             });
//                         }
//                     }

//                     function activate() {
//                         vm.languageList = resourceMessagingService.getLanguageOptions();
//                     }

//                     activate();
//                 }],
//             controllerAs: 'lsCtrl',
//             templateUrl: 'app/directives/languageSelector.html'
//         };
//     });
// }());
