export default class linkLoadService {
    constructor(allModelDataService, postService) {
        'ngInject';

        this.allModelDataService = allModelDataService;
        this.postService = postService;
    }
    getFriendlyLocation(locationString, locationSingle) {
        var prData = this.allModelDataService.getPostResponseData();
        var data = {'UOMSetting': prData.ApplicationData.ProgramSettingsData.UOM,
            'locationFriendly': locationString,
            'locationValue': locationSingle};

        this.apiRoute = 'api/UOM/ConvertReactionLocation';
        return this.postService.requestSimplePost(this.apiRoute,data);
    }

    getLinkLoadTreeData()//c_#m_# in memberinfo as id tag 4
    {
        var data = {};
        return this.postService.requestPostEvent('GetLinkLoadTreeData', data, 'api/MemberManagement/');
    }
}
// (function () {
//     'use strict';
//     var app = angular.module('app');
//     app.factory('linkLoadService', ['$http', 'ngAuthSettings', 'common', 'allModelDataService', 'postService',
//         function ($http, ngAuthSettings, common, allModelDataService, postService) {
//             var $q = common.$q;
//             var serviceBase = ngAuthSettings.apiServiceBaseUri;

//             var service = {
//                 getFriendlyLocation: getFriendlyLocation,
//                 getLinkLoadTreeData: getLinkLoadTreeData
//             };

//             function getFriendlyLocation(locationString, locationSingle) {
//                 var prData = allModelDataService.getPostResponseData();
//                 var data = {'UOMSetting': prData.ApplicationData.ProgramSettingsData.UOM,
//                     'locationFriendly': locationString,
//                     'locationValue': locationSingle};

//                 var apiRoute = 'api/UOM/ConvertReactionLocation';
//                 return postService.requestSimplePost(apiRoute,data);
//             }

//             function getLinkLoadTreeData()//c_#m_# in memberinfo as id tag 4
//             {
//                 var deferred = $q.defer();
//                 var data = {};
//                 return postService.requestPostEvent('GetLinkLoadTreeData', data, 'api/MemberManagement/');
//             }


//             return service;
//         }]);
// }());
