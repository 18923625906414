export default class windLoadingService {
    constructor ($http, common, allModelDataService, postService) {
        'ngInject';

        this.$q = common.$q();
        this.$http = $http;
        this.allModelDataService = allModelDataService;
        this.postService = postService;
        this.httpRequestQueueCounter = 0;
        this.inputQueuePromise;
        this.somethingWaitingOnQueueToFinish = false;
    }

    getQueueCounter() {
        this.inputQueuePromise = this.$q.defer();
        if (this.httpRequestQueueCounter === 0)
        {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.somethingWaitingOnQueueToFinish = true;
        }
        return this.inputQueuePromise.promise;
    }

    modifySelectedRegion(newValue) {
        var data = {
            'value': newValue
        };
        return this._requestPostEvent('ModifyNbccSelectedRegion', data);
    }

    modifySelectedLocation(newValue) {
        var data = {
            'value': newValue
        };
        return this._requestPostEvent('ModifyNbccSelectedLocation', data);
    }

    modifyWindPressureCustom(newValue) {
        var data = {
            'value': newValue
        };
        return this._requestPostEvent('ModifyWindPressureCustom', data);
    }

    modifyLoadApplicationOption(newValue) {
        var data = {
            'value': newValue
        };
        return this._requestPostEvent('ModifyLoadApplicationOption', data);
    }

    saveAsDefaults() {
        var data = {};
        return this._requestPostEvent('SaveJobWindLoadingAsDefaults', data);
    }

    getMetaData() {
        var data = {};
        return this._requestPostEvent('GetMetaData', data);
    }

    selectedWindSpeedChanged(newValue) {
        var data = {
            value: newValue
        };
        return this._requestPostEvent('ModifyBasicWindSpeed', data);
    }
    doNotApplyWindLoadChanged (newValue) {
        var data = {
            value: newValue
        };
        return this._requestPostEvent('ModifyDoNotApplyWindLoad', data);
    }
    isHurricaneProneRegionChanged(newValue) {
        var data = {
            value: newValue
        };
        return this._requestPostEvent('ModifyIsHurricaneProneRegion', data);
    }

    occupancyCategoryChanged(newValue) {
        var data = {
            value: newValue
        };
        return this._requestPostEvent('ModifyOccupancyCategory', data);
    }


    _requestPostEvent(windEvent, windData) {
        var path = 'api/JobWindLoading/';
        return this.postService.requestPostEvent(windEvent,windData,path);
    }

    _incrementQueueCounter() {
        this.httpRequestQueueCounter++;
    }

    _decrementQueueCounter() {
        this.httpRequestQueueCounter--;
        if (this.somethingWaitingOnQueueToFinish && this.httpRequestQueueCounter === 0) {
            this.somethingWaitingOnQueueToFinish = false;
            this.inputQueuePromise.resolve();
        }
    }
}
