import ngAuthSettings from '../environmentConfigs/environment.dev';
export default class jobSummaryService {
    constructor(common, postService, forteCookieService) {
        'ngInject';

        this.$q = common.$q();
        this.postService = postService;
        this.forteCookieService = forteCookieService;
        this.serviceBase = ngAuthSettings.apiServiceBaseUri;
        this.httpRequestQueueCounter = 0;
        this.inputQueuePromise;
        this.somethingWaitingOnQueueToFinish = false;
    }

    getQueueCounter() {
        this.inputQueuePromise = this.$q.defer();
        if (this.httpRequestQueueCounter === 0)
        {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.somethingWaitingOnQueueToFinish = true;
        }
        return this.inputQueuePromise.promise;
    }

    designMemberInBackground(memberIdTag, echoLogOn) {

        var data = {
            MemberIdTag: memberIdTag,
            echoLogOn: echoLogOn
        };
        var path = 'api/ReportOutput/';
        return this.postService.requestPostEvent('DesignInBackground',data,path);
    }

    printJobSummaryPDF(projectName, jobNotes, progSettingsData, memberContainers, memberIDs) {
        var data = {
            projectName: projectName,
            jobNotes: jobNotes,
            progSettingsData: progSettingsData,
            memberContainers: memberContainers,
            memberIDsBeingPrinted: memberIDs,
            showUtilizationPercentage: this.forteCookieService.getUtilizationPercentageEnabled()
        };
        return this.postService.requestPrintJobSummaryPDFPostEvent(data);
    }

    printMaterialListPDF(prData) {
        var data = {
            postResponseData: prData
        };
        return this.postService.requestPrintMaterialListPDFPostEvent(data);
    }

    printAllMembersPDF(prData, memberIDs) {
        var data = {
            postResponseData: prData,
            showFactoredLoadColumn: this.forteCookieService.getFactoredTitleEnabled(),
            memberIDsBeingPrinted: memberIDs,
            showUtilizationPercentage: this.forteCookieService.getUtilizationPercentageEnabled(),
            constructionDetailImageSetting: this.forteCookieService.getConstructionDetailImageSettingJobSummary()
        };
        return this.postService.requestPrintAllPDFPostEvent(data);
    }

    _requestPostEvent(eventName, data) {
        var path = 'api/JobSummary/';
        return this.postService.requestPostEvent(eventName,data,path);
    }

    _incrementQueueCounter() {
        this.httpRequestQueueCounter++;
    }
    _decrementQueueCounter() {
        this.httpRequestQueueCounter--;
        if (this.somethingWaitingOnQueueToFinish && this.httpRequestQueueCounter === 0) {
            this.somethingWaitingOnQueueToFinish = false;
            this.inputQueuePromise.resolve();
        }
    }
}
// (function () {
//     'use strict';
//     var app = angular.module('app');
//     var serviceId = 'jobSummaryService';
//     app.factory(serviceId, ['$http', 'ngAuthSettings', 'common','FileSaver', 'allModelDataService', 'spinner', 'postService', 'resourceMessagingService', reportService]);

//     function reportService($http, ngAuthSettings, common, FileSaver, allModelDataService, spinner, postService, resourceMessagingService) {
//         var $q = common.$q;
//         var serviceBase = ngAuthSettings.apiServiceBaseUri;
//         var httpRequestQueueCounter = 0;
//         var inputQueuePromise;
//         var somethingWaitingOnQueueToFinish = false;
//         //this is the declaration of the public methods that will be accessible
//         var service = {
//             getQueueCounter: getQueueCounter,
//             designMemberInBackground: designMemberInBackground,
//             printAllMembersPDF: printAllMembersPDF,
//             printJobSummaryPDF: printJobSummaryPDF
//         };

//         function getQueueCounter() {
//             inputQueuePromise = $q.defer();
//             if (httpRequestQueueCounter === 0)
//             {
//                 inputQueuePromise.resolve();
//                 return inputQueuePromise.promise;
//             }
//             else {
//                 somethingWaitingOnQueueToFinish = true;
//             }
//             return inputQueuePromise.promise;
//         }

//         function designMemberInBackground(memberIdTag, echoLogOn) {

//             var data = {
//                 MemberIdTag: memberIdTag,
//                 echoLogOn: echoLogOn
//             };
//             var path = 'api/ReportOutput/';
//             return postService.requestPostEvent('DesignInBackground',data,path);
//         }

//         function printJobSummaryPDF(projectName, jobNotes, progSettingsData, memberContainers, memberIDs) {
//             var data = {
//                 projectName: projectName,
//                 jobNotes: jobNotes,
//                 progSettingsData: progSettingsData,
//                 memberContainers: memberContainers,
//                 memberIDsBeingPrinted: memberIDs
//             };
//             return postService.requestPrintJobSummaryPDFPostEvent(data);
//         }

//         function printAllMembersPDF(prData, memberIDs) {
//             var data = {
//                 postResponseData: prData,
//                 memberIDsBeingPrinted: memberIDs
//             };
//             return postService.requestPrintAllPDFPostEvent(data);
//         }

//         function _requestPostEvent(eventName, data) {
//             var path = 'api/JobSummary/';
//             return postService.requestPostEvent(eventName,data,path);
//         }

//         function _incrementQueueCounter() {
//             httpRequestQueueCounter++;
//         }
//         function _decrementQueueCounter() {
//             httpRequestQueueCounter--;
//             if (somethingWaitingOnQueueToFinish && httpRequestQueueCounter === 0) {
//                 somethingWaitingOnQueueToFinish = false;
//                 inputQueuePromise.resolve();
//             }
//         }

//         return service;
//     }
// }());
