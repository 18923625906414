export default function closeOpenModals($rootScope, $uibModalStack) {
    'ngInject';
    // close the opened modal on location change.
    $rootScope.$on('$locationChangeStart', ($event, next, current) => {
        //if they are trying to navigate back to loading. dont let them.
        if (next.indexOf('forte/Loading') > -1 && current.indexOf('forte/FileManagement') > -1)
        {
            if (!!$event.preventDefault) {
                $event.preventDefault();
            }
            if (!!$event.stopPropagation) {
                $event.stopPropagation();
            }
        }
        else {
            var openedModal = $uibModalStack.getTop();
            if (openedModal && (current.indexOf('application/loading') === -1 && next.indexOf('application/job/jobsummary') === -1)) {
                if (!!$event.preventDefault) {
                    $event.preventDefault();
                }
                if (!!$event.stopPropagation) {
                    $event.stopPropagation();
                }
                $uibModalStack.dismiss(openedModal.key);
            }
        }
    });
}
