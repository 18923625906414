export default class forgotPasswordController {
    constructor ($state, $stateParams, resourceMessagingService, authService) {
        'ngInject';

        this.emailAddress;
        this.submitted = false;

        this.$state = $state;
        this.$stateParams = $stateParams;
        this.resourceMessagingService = resourceMessagingService;
        this.authService = authService;

        this.activate();
    }
    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    activate() {
        if (this.$stateParams.email !== null) {
            this.emailAddress = this.$stateParams.email;
        }
        if (this.$stateParams.message !== null) {
            this.notification = this.$stateParams.message;
        } else {
            this.notification = '';
        }
    }

    sendEmail(isValid) {
        this.submitted = true;
        if (isValid) {
            this.disableSubmitAction = true;
            this.authService.forgotPassword(this.emailAddress)
            .then((response) => {
                //console.log('confirmation email sent');
                this.$state.go('account.emailSent');
            })
            .catch((response) => {
                if (response && response.data === 'underConstruction') {
                    window.location.replace('underconstruction.html');
                } else {
                    this.emailSent = true;
                    this.message = response;
                    this.$state.go('account.emailSent');
                }
            });
        }
    }
}
