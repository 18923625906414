import importFileTemplate from '../../manage/importFile/importFile.html';
import searchTemplate from './searchFilesTemplate.html';
import moment from 'moment';
export default class fileManagementController {
    constructor($scope, $rootScope, $timeout, $uibModal, $state, common, spinner,
        resourceMessagingService, fileSystemService, smartNavService, allModelDataService, fileMoveCopyService,
        teamService, notifier, mobileNavMenu, forteCookieService, signalrService) {
        'ngInject';

        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$timeout = $timeout;
        this.$uibModal = $uibModal;
        this.$state = $state;
        this.$q = common.$q();
        this.spinner = spinner;
        this.resourceMessagingService = resourceMessagingService;
        this.fileSystemService = fileSystemService;
        this.smartNavService = smartNavService;
        this.allModelDataService = allModelDataService;
        this.fileMoveCopyService = fileMoveCopyService;
        this.teamService = teamService;
        this.notifier = notifier;
        this.mobileNavMenu = mobileNavMenu;
        this.forteCookieService = forteCookieService;
        this.signalRService = signalrService;
        this.fileSelected = false;
        this.folderSelected = false;
        this.currentSelectedNode = null;
        this.showTable = true;
        this.showDropdown = false;
        this.countSelected = 0;
        this.HMenuVisible = false;
        this.loadingFolderData = false;
        this.renamed = false;
        this.isUserAdmin = false;
        this.disableBreadCrumbs = false;
        this.languages;

        this.scrollIsVisible;
        this.colWidths;
        this.importFiles;

        this.selectedElements;
        this.selectedTreeItems;
        this.currentlyOpenedFolder;
        this.itemsToDisplay;
        this.originallyDraggedItem;
        this.dropComplete;
        this.currentlySelectedNode = null;

        this.sortTypes = ['name', 'modified'];
        this.sortReverse = false;
        this.sortType = 'text';
        this.showBackButton = true;
        this.addNewFileFormSubmitted = false;
        this.addNewFolderFormSubmitted = false;
        this.newFolderName = '';
        this.isAddFolderOpen = false;
        this.teamInfo;
        this.receivedDataDate = null;
        this.isBreadcrumbsOverflow = false;

        this.isThisRecentFiles = $state.current.name.lastIndexOf('.recent') > -1;

        // for multi selection
        this.lastClickedId = null;
        this.lastClickedIndex = null;
        this.selectedIndexes = [];
        this.itemIndexDict = {};
        this.startEditingName = false;
        this.draggedFile = null;
        this.toFolderId = null;

        this.originalName = '';
        this.openedFileId;

        this.handleMoveCopyClosedEventHandler = $rootScope.$on('moveCopyModalClosed', (event, data) => {
            this.deselectAll();
            this._refreshDataFromServer();
            this.fileSystemService.getFileSystemData(true, this.sortReverse, this.sortType);
        });
        this.handleUpdateReceivedDataDate = $rootScope.$on('updateReceivedDataDate', (event, data) => {
            this.receivedDataDate = moment.utc();
        });

        this.handleRejectionEventHandler = $scope.$on('handlePermissionRejection', (event, data) => {
            this.handlePermissionRejection(data.title, data.response);
        });

        this.handleMoveCopyCompleteEventHandler = $scope.$on('moveCopyComplete', (event, destinationFolderId) => {
            fileMoveCopyService.moveCopyComplete(destinationFolderId, this.receivedDataDate).then(
                (response) => {
                    this.receivedDataDate = moment.utc();
                    if (allModelDataService.getPostResponseData()) {
                        var fileId = allModelDataService.getPostResponseData().ApplicationData.ProjectManagerData.ProjectFileTreeID;
                        this.openedFileId = 'f' + fileId;
                    }
                    this._refreshDataFromServer();
                    this.deselectAll();
                    this.fileSystemService.getFileSystemData(true, this.sortReverse, this.sortType);
                },
                (exception) => {
                    this.handlePermissionRejection(this.getText('cantModifyItem'), exception);
                    //deferred.reject();
                }
            );
        });

        //delete dialog has been confirmed or cancelled.
        this.handleDeleteCompleteEventHandler = $scope.$on('deleteDialogComplete', (event, canDelete) => {
            //console.log('canDelete: ' + canDelete);
            if (canDelete) {
                //console.log('fileManCtrl-handleDeleteCompleteEventHandler-spinnerShow');
                this.spinner.spinnerShow(this.getText('removingItems'));
                this.$timeout(() => {
                    if (!this.openedFileId) {
                        this.openedFileId = 'f0';
                    }
                    fileSystemService.removeItems(this.selectedTreeItems, this.openedFileId, this.receivedDataDate).then(
                    (response) => {
                        if (this.selectedTreeItems[0].id === this.openedFileId) {
                            this.showBackButton = false;
                            allModelDataService.clearPostResponseData();
                            this.signalRService.unlockFile(this.openedFileId);
                        }
                        this.deselectAll();
                        this._refreshDataFromServer();
                        this.fileSystemService.getFileSystemData(true, this.sortReverse, this.sortType);
                        //console.log('fileManCtrl-handleDeleteCompleteEventHandler1-spinnerHide');
                        this.spinner.spinnerHide();
                    },
                    (response) => {
                        //console.log('fileManCtrl-handleDeleteCompleteEventHandler2-spinnerHide');
                        this.spinner.spinnerHide();
                        this.handlePermissionRejection(this.getText('cantModifyItem'), response);
                    }
                );
                }, 500, false);
            }
        });
        this.$scope.$on('$destroy', () => {
            this.handleMoveCopyClosedEventHandler();
            this.handleRejectionEventHandler();
            this.handleMoveCopyCompleteEventHandler();
            this.handleDeleteCompleteEventHandler();
            this.handleUpdateReceivedDataDate();
        });

        this.activate();
    }

    activate() {
        this.loadingFolderData = true;
        this.sortReverse = false;
        this.breadcrumbs = [];
        this.newFileName = '';
        this.openedFileHasBeenDeleted = false;
        this.showBackButton = false;
        this.allTeams = [];
        this.languages = this.resourceMessagingService.getLanguageOptions();
        this.fileSystemService.getFileSystemData(false, this.sortReverse, this.sortType);
        this._getAllTeamsForUser();

        if (this.$state.params.teamId) {
            this.allModelDataService.setTeamOwnerId(this.$state.params.teamId);
        }
        else {
            this.allModelDataService.setTeamOwnerId(null);
        }

        var openToCurrentFile = this.$state.params.openToCurrentFile;
        if (this._hasPostResponseData()) {
            var fileId = this.allModelDataService.getPostResponseData().ApplicationData.ProjectManagerData.ProjectFileTreeID;
            this.openedFileId = 'f' + fileId;
            if (openToCurrentFile) {
                if (this.isThisATeam()) {
                    this.teamId = this.$state.params.teamId;
                    this._getTeamInfo(this.teamId).then(
                        () => {
                            this._getPathToOpenFile(fileId);
                        },
                        (error) => {
                            //console.log(error);
                        }
                    );
                } else {
                    this._getPathToOpenFile(fileId);
                }
            } else {
                if (this.isThisATeam()) {
                    this.teamId = this.$state.params.teamId;
                    this._getTeamInfo(this.teamId).then(
                        () => {
                            if (this.isThisRecentFiles) {
                                this._getRecentFiles();
                            } else {
                                this._getFolderData('t' + this.teamInfo.Root.id);
                            }
                        },
                        (error) => {
                            //console.log(error);
                        }
                    );
                } else {
                    if (this.isThisRecentFiles) {
                        this._getRecentFiles();
                    } else {
                        this._getFolderData('');
                    }
                }
            }
            this.showBackButton = true;
        } else {
            if (this.isThisATeam()) {
                this.teamId = this.$state.params.teamId;
                this._getTeamInfo(this.teamId).then(
                    () => {
                        if (this.isThisRecentFiles) {
                            this._getRecentFiles();
                        } else {
                            this._getFolderData('t' + this.teamInfo.Root.id);
                        }
                    },
                    (error) => {
                        // console.log(error);
                    }
                );
            } else {
                if (this.isThisRecentFiles) {
                    this._getRecentFiles();
                } else {
                    this._getFolderData('');
                }
            }
        }
        //
        // }
        //_initColumnWidths();
        //_populateOpenFileTree(true);
    }

    openMemberJobNav() {
        this.mobileNavMenu.openMemberJobNav();
    }

    isMoveButtonAvailable() {
        if (this.$state.current.name === 'application.manage.files.recent' || this.$state.current.name === 'application.manage.team.recent') {
            return false;
        }

        if (this.isThisATeam()) {
            if (this.countSelected === 1 && this.fileSelected) {
                return true;
            } else if (this.isUserAdmin === true && this.countSelected > 0) {
                return true;
            }
        } else {
            if (this.countSelected > 0) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }
    isDeleteButtonAvailable() {
        if (this.$state.current.name === 'application.manage.files.recent' || this.$state.current.name === 'application.manage.team.recent') {
            return false;
        }

        if (this.isThisATeam()) {
            if (this.countSelected > 0 && this.fileSelected === true) {
                return true;
            } else if (this.isUserAdmin === true && this.countSelected > 0) {
                return true;
            }
        } else {
            if (this.countSelected > 0) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }

    _getAllTeamsForUser() {
        this.teamService.getAllTeamsForUser().then(
            (response) => {
                this.allTeams = response;
            },
            (exception) => {
                //console.log(exception);
            }
        );
    }
    handlePermissionRejection(titleText, response) {
        if (response.status === 403) {
            var exceptionType = response.data.substring(0, 1);
            switch (exceptionType) {
                case '1': //item modified
                    //refresh current
                    var message = this.getText('collisionRefresh');
                    this._refreshDataFromServer();
                    this.fileSystemService.getFileSystemData(true, this.sortReverse, this.sortType);
                    this.notifier.fileManagementWarning(titleText, message);
                    break;
                case '2': //item locked
                    //do nothing
                    let lockedMessage = '';
                    if (response.data.includes('UserFile')) {
                        lockedMessage = this.getText('collisionLockedUserFile');
                    }
                    else {
                        lockedMessage = this.getText('collisionLocked');
                    }
                    this.notifier.fileManagementWarning(titleText, lockedMessage);
                    break;
                case '3': //no permission
                    //log out
                    this.$state.go('account/login');
                    console.log('Unauthorized access');
                    break;
                case '4': //parent modified
                    //back to root
                    var message = this.getText('collisionReturnToRoot');
                    this._getFolderData('t' + this.teamInfo.Root.id);
                    this.notifier.fileManagementError(titleText, message);
                    this.fileSystemService.getFileSystemData(true, this.sortReverse, this.sortType);
                    break;
            }
        }
    }

    _getTeamInfo(team) {
        var deferred = this.$q.defer();
        this.teamService.getTeam(this.teamId).then(
            (result) => {
                this.teamInfo = result.Team;
                this.isThisUserAnAdmin();
                deferred.resolve();
            },
            (error) => {
                //console.log(error);
                deferred.reject();
            }
        );
        return deferred.promise;
    }

    isThisUserAnAdmin() {
        this.isUserAdmin = false;
        var username = this.allModelDataService.getUserName();
        for (var i = 0; i < this.teamInfo.Administrators.length; i++) {
            if (this.teamInfo.Administrators[i].Email === username) {
                this.isUserAdmin = true;
            }
        }
    }

    isThisATeam() {
        if (this.$state.current.name.lastIndexOf('application.manage.team') > -1) {
            return true;
        } else {
            return false;
        }
    }

    addFileDialog() {
        this.addFileDialogVisible = true;
    }

    getModifiedDateDisplayText(date) {
        var dateFormat = 'MMMM D';

        if (this.languages[1].isSelected) {
            //moment.updateLocale('fr-ca', '/bower_components/moment/locale/fr-ca.js');
            this.setFrenchText();
            dateFormat = 'LL';
        } else {
            moment.locale('en-US');
        }
        var now = moment.utc();
        var then = moment.utc(date);
        var dayDifference = now.diff(then, 'days');
        //2 d
        if (dayDifference > 2) {
            //full date e.g. May 5
            return then.format(dateFormat);
        } else {
            //relative time e.g. 1 day ago.
            return then.fromNow();
        }
    }

    setFrenchText() {
        moment.locale('fr-ca', {
            months: 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
            monthsShort: 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
            monthsParseExact: true,
            weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
            weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
            weekdaysMin: 'di_lu_ma_me_je_ve_sa'.split('_'),
            weekdaysParseExact: true,
            longDateFormat: {
                LT: 'HH:mm',
                LTS: 'HH:mm:ss',
                L: 'YYYY-MM-DD',
                LL: '[le] MMMM D', // This is custom from installed momemt.js locale
                LLY: 'D MMMM YYYY',
                LLL: 'D MMMM YYYY HH:mm',
                LLLL: 'dddd D MMMM YYYY HH:mm'
            },
            calendar: {
                sameDay: '[Aujourd’hui à] LT',
                nextDay: '[Demain à] LT',
                nextWeek: 'dddd [à] LT',
                lastDay: '[Hier à] LT',
                lastWeek: 'dddd [dernier à] LT',
                sameElse: 'L'
            },
            relativeTime: {
                future: 'dans %s',
                past: 'il y a %s',
                s: 'quelques secondes',
                ss: '%d secondes',
                m: 'une minute',
                mm: '%d minutes',
                h: 'une heure',
                hh: '%d heures',
                d: 'un jour',
                dd: '%d jours',
                M: 'un mois',
                MM: '%d mois',
                y: 'un an',
                yy: '%d ans'
            },
            dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
            ordinal: (number, period) => {
                switch (period) {
                    // Words with masculine grammatical gender: mois, trimestre, jour
                    default:
                    case 'M':
                    case 'Q':
                    case 'D':
                    case 'DDD':
                    case 'd':
                        return number + (number === 1 ? 'er' : 'e');
                        // Words with feminine grammatical gender: semaine
                    case 'w':
                    case 'W':
                        return number + (number === 1 ? 're' : 'e');
                }
            }
        });
    }

    _hasPostResponseData() {
        if (this.allModelDataService.getPostResponseData()) {
            return true;
        } else {
            return false;
        }
    }

    //show delete confirmation
    deleteSelected() {
        this.$scope.$emit('deleteSplashVisible', true);
    }

    upload() {
        // console.log('upload method fired');
        // add a null check of this.importFiles
        // because ie 11 calls the upload twice after a file is selected
        // https://github.com/danialfarid/ng-file-upload/issues/1520

        if (this.importFiles) {
            this.showImportFileModal();
        }

        this.importFiles = null;
    }

    downloadFile() {
        this.showExportFileModal();
    }

    moveSelected() {
        this.fileMoveCopyService.initialize(true, this.selectedTreeItems);
        this._toggleMoveCopySplash(true);
    }

    copySelected() {
        this.fileMoveCopyService.initialize(false, this.selectedTreeItems);
        this._toggleMoveCopySplash(true);
    }

    _toggleMoveCopySplash(isVisible) {
        this.$scope.$emit('moveCopySplashVisible', isVisible);
        if (isVisible) {
            var allRoots = [];
            if (this.isThisATeam()) {
                //only pass in the current team root.
                allRoots.push(this.breadcrumbs[0]);
            } else {
                //add the users root to all teams roots
                allRoots.push(this.breadcrumbs[0]);
                for (var i = 0; i < this.allTeams.length; i++) {
                    allRoots.push(this._getBreadCrumbFromTeamRoot(this.allTeams[i]));
                }
            }

            this.$rootScope.$broadcast('refreshMoveCopyTree', this.isThisATeam(), allRoots);
        }
    }

    _getRecentFiles() {
        this.loadingFolderData = true;
        this.itemsToDisplay = null;
        var deferred = this.$q.defer();

        this.fileSystemService.getRecentFiles(this.teamId).then(
            (response) => {
                this.fileSelected = false;
                this.folderSelected = false;
                this.currentlyOpenedFolder = response;
                this.receivedDataDate = response.receivedDataDate;
                this.itemsToDisplay = response.children;
                //there are no breadcrumbs for recent files so dont draw them
                this.loadingFolderData = false;
                this.setSortIndex('dateModified');
                deferred.resolve();
            },
            (exception) => {
                //console.log(error)
                this.handlePermissionRejection(this.getText('cantOpenFolder'), exception);
            }
        );
    }

    _getFolderData(id) {
        this.loadingFolderData = true;
        this.itemsToDisplay = null;
        var deferred = this.$q.defer();
        this.$timeout(() => {
            this.fileSystemService.getFolder(id, this.receivedDataDate).then(
                (response) => {
                    this.fileSelected = false;
                    this.folderSelected = true;
                    this.currentlyOpenedFolder = response;
                    this.receivedDataDate = response.receivedDataDate;
                    this.itemsToDisplay = this._sortChildren();
                    this.$timeout(() => {
                        this.drawBreadCrumbs(this.currentlyOpenedFolder);
                        this.loadingFolderData = false;
                        this.disableBreadCrumbs = false;
                        $('.arrows').scrollLeft(1000);
                    }, 400, true); //pass false here to prevent another digest cycle from firing.
                    deferred.resolve();
                },
                (exception) => {
                    this.disableBreadCrumbs = false;
                    this.handlePermissionRejection(this.getText('cantOpenFolder'), exception);
                    //deferred.reject();
                }
            );
        }, 500, false);

        return deferred.promise;
    }

    _getPathToOpenFile(fileId) {
        this.fileSystemService.getPathToOpenFile(fileId).then(
            (response) => {
                //build breadcrumbs for all parent folders
                //then navigate to proper folder by calling _getFolderData
                this.breadcrumbs = [];
                var currentFolder = response;
                while (currentFolder.children.length > 0) {
                    this.drawBreadCrumbs(currentFolder);
                    currentFolder = currentFolder.children[0];
                }
                this._getFolderData(currentFolder.id);
            },
            (exception) => {
                //console.log(exception);
            }
        );
    }

    _sortChildren() {
        var folders = [];
        var files = [];

        if (this.currentlyOpenedFolder && this.currentlyOpenedFolder.children) {
            for (var i = 0; i < this.currentlyOpenedFolder.children.length; i++) {
                var current = this.currentlyOpenedFolder.children[i];
                if (current.id.substring(0, 1) === 't') {
                    folders.push(current);
                } else {
                    files.push(current);
                }
            }
        }

        var foldersSorted = folders.sort(this.dynamicSort(this.sortType));
        var filesSorted = files.sort(this.dynamicSort(this.sortType));
        return foldersSorted.concat(filesSorted);
    }

    _refreshDataFromServer() {
        return this._getFolderData(this.currentlyOpenedFolder.id);
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (this.sortReverse) {
            sortOrder = -1;
        }
        return (a, b) => {
            var result;
            if (property === 'dateModified') {
                var propA = moment.utc(a[property]).valueOf();
                var probB = moment.utc(b[property]).valueOf();
                result = (propA < probB) ? -1 : (propA > probB) ? 1 : 0;
            } else {
                result = (a[property].toLowerCase() < b[property].toLowerCase()) ? -1 : (a[property].toLowerCase() > b[property].toLowerCase()) ? 1 : 0;
            }
            return result * sortOrder;
        };
    }

    drawBreadCrumbs(folder) {
        var newBreadCrumb = this._getBreadCrumbFromTreeItem(folder);
        if (this.breadcrumbs.length === 0) {
            //if this is the root element, insert My Files/Team Name instead of rootScope
            if (this.isThisATeam()) {
                newBreadCrumb.text = this.teamInfo.Name;
            } else {
                newBreadCrumb.text = this.getText('myFiles');
            }
            this.breadcrumbs.push(newBreadCrumb);
            setTimeout(() => {
                $('.arrows').scrollLeft(1000);
            }, 500);
        } else {
            var searchIndex = -1;
            for (var i = 0; i < this.breadcrumbs.length; i++) {
                if (this.breadcrumbs[i].id === newBreadCrumb.id) {
                    searchIndex = i;
                    break;
                }
            }
            if (searchIndex > -1) {
                this.breadcrumbs = this.breadcrumbs.slice(0, searchIndex + 1);
            } else {
                this.breadcrumbs.push(newBreadCrumb);
                setTimeout(() => {
                    $('.arrows').scrollLeft(1000);
                }, 500);
            }

            var hiddenBreadcrumbsLength = this.breadcrumbs.length - 4;
            if (this.breadcrumbs.length > 4) {
                this.isBreadcrumbsOverflow = true;
                this.breadcrumbsShown = [];
                this.breadcrumbsHidden = [];
                for (var i = hiddenBreadcrumbsLength; i < this.breadcrumbs.length; i++) {
                    this.breadcrumbsShown.push(this.breadcrumbs[i]);
                }
                for (var i = 0; i < hiddenBreadcrumbsLength; i++) {
                    this.breadcrumbsHidden.push(this.breadcrumbs[i]);
                }
            } else {
                this.isBreadcrumbsOverflow = false;
            }
        }
    }

    _getBreadCrumbFromTreeItem(treeItem) {
        var obj = {
            'id': treeItem.id,
            'text': treeItem.text
        };
        return obj;
    }

    _getBreadCrumbFromTeamRoot(teamRoot) {
        var obj = {
            'id': 't' + teamRoot.Root.id,
            'text': teamRoot.Name
        };
        return obj;
    }

    // show Hamburger menu
    showHMenu(value) {
        this.HMenuVisible = value;
    }
    // for scroll bar
    setScrollVisibility(value) {
        this.scrollIsVisible = value;
    };

    getHeaderMargin() {
        if (this.colWidths[0]) {
            return {
                'margin-top': '-47px'
            };
        }
    }

    _initColumnWidths() {
        this.colWidths = [];
        this.scrollIsVisible = false;
        for (var i = 0; i < 2; i++) {
            this.colWidths[i] = 0;
        }
    }

    setSortIndex(columnTag) {
        this.sortReverse = !this.sortReverse;

        this.sortType = columnTag;
        this.itemsToDisplay = this._sortChildren();
    }

    getSortClass(name) {
        if (this.sortType === name) {
            if (this.sortReverse) {
                return 'sortDescending';
            } else {
                return 'sortAscending';
            }
        }
        return '';
    }

    itemOpened(id) {
        if (this.disableBreadCrumbs === false) {
            this.deselectAll();
            if (id.substring(0, 1) === 't') {
                //it's a folder
                //clear the view while the new data is being populated
                this.currentlyOpenedFolder = undefined;
                this.disableBreadCrumbs = true;
                this._getFolderData(id);
            } else {
                this._openFile(id);
            }
        }
    }

    _openFile(id) {
        //console.log('fileManCtrl-_openFile-spinnerShow');
        this.spinner.spinnerShow(this.getText('loadingProject'));
        var fileIdToOpen;
        if (typeof id === 'string') {
            id = id.substring(1);
            fileIdToOpen = parseInt(id);
        }
        else {
            fileIdToOpen = id;
        }

        let goBackToMemberJobSetting = false;
        let previousFileIdToClose = 0;

        if (this.allModelDataService.getPostResponseData()) {
            let prFileId = this.allModelDataService.getPostResponseData().ApplicationData.ProjectManagerData.ProjectFileTreeID;
            if (prFileId === fileIdToOpen) {
                goBackToMemberJobSetting = true;
            }
            else {
                previousFileIdToClose = prFileId;
            }
        }

        if (goBackToMemberJobSetting) {
            //they clicked open on the file they already have open.  Treat it like pressing the back button
            this.backToJobSetting();
        } else {
            this.fileSystemService.openFile(fileIdToOpen, previousFileIdToClose).then(
                () => {
                    this.signalRService.notifyFileOpen(fileIdToOpen);
                    //clear out the post response data so they will no longer have an active file to return to.
                    this.allModelDataService.clearPostResponseData();
                    this.showBackButton = false;

                    this.forteCookieService.writeCurrentlyOpenedFileId(fileIdToOpen);
                    this.allModelDataService.setFileIdToOpen(fileIdToOpen);
                    this.$state.go('application.job');
                },
                (response) => {
                    this.handlePermissionRejection(this.getText('cantOpenFile'), response);
                    //console.log('fileManCtrl-openFile-spinnerHide');
                    this.spinner.spinnerHide();
                });
        }
    }

    newFileOrFolderSelected(node) {
        this.currentSelectedNode = node;
        this.selectedID = node.id;
        this.folderSelected = false;
        this.fileSelected = false;
        if (this.currentSelectedNode.id.substring(0, 1) === 't') {
            this.folderSelected = true;
        } else if (this.currentSelectedNode.id.substring(0, 1) === 'f') {
            this.fileSelected = true;
        }
    }

    getIndex(node, index) {
        this.itemIndexDict[index] = node.id;
        return true;
    }

    getIndexOfId(id) {
        var keys = Object.keys(this.itemIndexDict);

        for (var i = 0; i < keys.length; i++) {
            if (id === this.itemIndexDict[keys[i]]) {
                return keys[i];
            }
        }

        return null;
    }

    selectOne(node) {
        node.state.selected = true;
    }

    editNameButtonClicked(event, node) {
        this.deselectAll();
        this.originalName = node.text;
        node.showEdit = true;
        this.selectAllTextInTextBox();
        event.stopPropagation();
    }

    editNameTextboxClicked(event) {
        event.stopPropagation();
    }

    renameComplete(event, node) {
        var itemOriginalName = this.originalName;
        this.renameFormSubmitted = true;
        node.showEdit = false;
        var newName = node.text;
        node.dateModified = moment.utc().format();
        if ((newName) && (newName.length > 0)) {
            if (node.id.substring(0, 1) === 't') {
                var folderId = node.id.substring(1, node.id.length);
                this.fileSystemService.renameFolder(folderId, node.text, this.receivedDataDate)
                    .then(() => {
                        this.renameFormSubmitted = false;
                        this.receivedDataDate = moment.utc();
                        this.itemsToDisplay = this._sortChildren();
                    }, (response) => {
                        //rename folder failed.
                        node.text = itemOriginalName;
                        this.handlePermissionRejection(this.getText('cantModifyFolder'), response);
                    });
            } else if (node.id.substring(0, 1) === 'f') {
                //rename file
                var fileId = node.id.substring(1, node.id.length);
                this.fileSystemService.renameFileWithTreeItemId(fileId, this.getCurrentFileId(), node.text, this.receivedDataDate)
                    .then(() => {
                        this.receivedDataDate = moment.utc();
                        this.renameFormSubmitted = false;
                        this.itemsToDisplay = this._sortChildren();
                    }, (response) => {
                        //rename file failed
                        node.text = itemOriginalName;
                        this.handlePermissionRejection(this.getText('cantModifyFile'), response);
                    });
            }
            this.rename = true;
        } else {
            this.renameFormSubmitted = false;
            node.text = itemOriginalName;
        }
    }

    getCurrentFileId() {
        if (this._hasPostResponseData()) {
            // File ID open in current session
            return this.forteCookieService.getCurrentlyOpenedFileId();
        }
        else {
            return '';
        }
    }

    //eslint-disable-next-line complexity
    setSelected(event, node) {
        if (!this.lastClickedId) {
            this.lastClickedId = node.id;
        }

        this.lastClickedIndex = this.getIndexOfId(this.lastClickedId);
        this.changeNodeState(event, node);

        this.lastClickedId = node.id;
        this.selectedTreeItems = this.getSelectedTreeItems();

        this.folderSelected = false;
        this.fileSelected = false;
        if (this.selectedTreeItems.length === 1) {
            if (this.lastClickedId.substring(0, 1) === 't') {
                this.folderSelected = true;
            } else {
                this.fileSelected = true;
            }
        }
    }

    changeNodeState(event, node) {
        this.lastClickedIndex = this.getIndexOfId(this.lastClickedId);

        var srcElement = event.currentTarget;

        if (event.ctrlKey) {
            if (node.state.selected) {
                this.countSelected--;
                var indexToSplice = this.selectedElements.indexOf(srcElement);
                this.selectedElements.splice(indexToSplice, 1);
            } else {
                this.countSelected++;
                this.selectedElements.push(srcElement);
            }
            node.state.selected = !node.state.selected;
        } else if (event.shiftKey) {
            this.deselectAll();
            var start = this.lastClickedIndex;
            var end = this.getIndexOfId(node.id);
            this.countSelected = 0;
            for (var i = Math.min(start, end); i < Math.max(start, end) + 1; i++) {
                this.selectedIndexes.push(i);
            }
            var found = false;

            //push the first one.
            this.selectedElements.push(srcElement);
            var next = srcElement;
            for (var i = Math.min(start, end); i < Math.max(start, end); i++) {
                if (start > end) {
                    next = this.getNextSibling(next);
                } else {
                    next = this.getPreviousSibling(next);
                }
                this.selectedElements.push(next);
            }
            this.getSelectedItems();
        }
        // clear selected state
        else {
            this.deselectAll();
            this.countSelected = 1;
            node.state.selected = true;
            this.currentSelectedNode = node;
            this.selectedElements.push(srcElement);
        }
    }

    getSelectedItems() {
        for (var i = 0; i < this.currentlyOpenedFolder.children.length; i++) {
            var thisNode = this.currentlyOpenedFolder.children[i];
            var thisIndex = parseInt(this.getIndexOfId(thisNode.id));
            if (this.selectedIndexes.indexOf(thisIndex) > -1) {
                thisNode.state.selected = true;
                this.countSelected++;
            }
        }
    }

    addNewFile(isValid) {
        this.addNewFileFormSubmitted = true;
        if (isValid) {
            this.spinner.spinnerShow(this.getText('creatingFile'));
            var parentFolderId = this.currentlyOpenedFolder.id;
            let previousFileIdToClose = this.forteCookieService.getCurrentlyOpenedFileId();

            this.fileSystemService.addNewFile(parentFolderId, this.newFileName, this.receivedDataDate, previousFileIdToClose).then(
                (response) => {
                    //kick off process to open new file and load models.
                    this.addNewFileFormSubmitted = false;
                    this.smartNavService.addingNewFile = true;
                    this.fileSystemService.getFileSystemData(true, this.sortReverse, this.sortType);
                    //this.$rootScope.$emit('updateTheProject');
                    let newFileId = response.postResponseData.ApplicationData.ProjectManagerData.ProjectFileTreeID;

                    this.signalRService.notifyFileOpen(newFileId);

                    this.forteCookieService.writeCurrentlyOpenedFileId(newFileId);
                    this.allModelDataService.setFileIdToOpen(newFileId);
                    this.$state.go('application.job');
                    //this.spinner.spinnerHide();
                },
                (response) => {
                    //console.log('fileManCtrl-addNewFile-spinnerHide');
                    this.spinner.spinnerHide();
                    this.handlePermissionRejection(this.getText('cantAddFile'), response);
                });
        }
    }

    addFolder(valid) {
        this.addNewFolderFormSubmitted = true;
        if (valid) {
            this.spinner.spinnerShow(this.getText('creatingFolder'));
            var parentToAddToId = this.currentlyOpenedFolder.id;
            this.fileSystemService.addFolder(parentToAddToId, this.newFolderName, 0, this.receivedDataDate)
                .then((newTreeItemId) => {
                    this.addNewFolderFormSubmitted = false;
                    this.fileSystemService.getFileSystemData(true, this.sortReverse, this.sortType);
                    this._refreshDataFromServer().then(
                        () => {
                            this.deselectAll();
                            this.isAddFolderOpen = false;
                            this.newFolderName = '';
                            this.spinner.spinnerHide();
                        });
                },
                    (response) => {
                        this.spinner.spinnerHide();
                        this.handlePermissionRejection(this.getText('cantAddFolder'), response);
                    });
        }
    }

    backToJobSetting() {
        var toState = this.$state.params.fromState;
        if (toState === '') {
            this.allModelDataService.clearSelectedJobTreeNode();
            this.$rootScope.$broadcast('projectReady', {
                forgetState: false
            });
            this.smartNavService.navigateOrReload('application.job.jobSummary');
        } else {
            if (!toState) {
                this.allModelDataService.clearSelectedJobTreeNode();
                this.$rootScope.$broadcast('projectReady', {
                    forgetState: false
                });
                this.smartNavService.navigateOrReload('application.job.jobSummary');
            } else {
                this.smartNavService.navigateOrReload(toState);
            }
        }

        if (this.rename) {
            this.rename = false;
            // this.$rootScope.$broadcast('refreshJobTree', {
            //     forgetState: false
            // });
        }
    }

    searchModal() {
        var modalInstance = this.$uibModal.open({
            animation: true,
            template: searchTemplate,
            controller: 'searchFilesController',
            controllerAs: 'sfCtrl',
            backdrop: 'static',
            windowClass: 'modal-fit',
            size: 'lg',
            resolve: {}
        });

        modalInstance.result.then((result) => {
            switch (result.action.toString()) {
                case 'navigate':
                    //navigate to folder id
                    this.openToFileLocation(result.node.id);
                    break;
                case 'open':
                    this._openFile(result.node.id);
                    break;
                default:
                    //modal was closed, dont do anything
            }
        }, (error) => {});
    }

    showImportFileModal() {
        var modalInstance = this.$uibModal.open({
            animation: true,
            template: importFileTemplate,
            controller: 'importFileModalController',
            backdrop: 'static',
            keyboard: false,
            windowTopClass: 'wideModalWindow',
            size: 'lg',
            resolve: {
                isFileImportModal: true,
                filesToImport: this.importFiles,
                treeItem: this.currentlyOpenedFolder,
                receivedDataDateObj: {
                    'value': this.receivedDataDate
                }
            }
        });
        modalInstance.result.then(
            (response) => {

            },
            (response) => {
                this.fileSystemService.getFileSystemData(true, this.sortReverse, this.sortType);
                if (response.hasOwnProperty('status')) {
                    this.handlePermissionRejection(this.getText('cantImportFile'), response);
                }
            });
    }

    // openFile() {
    //     if (this.fileSelected) {
    //         this.fileSystemService.openFile(this.currentSelectedNode.id).then(
    //             () => {
    //                 //kick off process to open new file and load models.
    //                 this.forteCookieService.writeCurrentlyOpenedFileId(this.currentSelectedNode.id);
    //                 this.allModelDataService.setFileIdToOpen(this.currentSelectedNode.id);
    //                 this.$state.go('application.job');
    //             },
    //             (exception) => {
    //                 this.handlePermissionRejection(this.getText('cantOpenFile'), exception);
    //                 //deferred.reject();
    //             });
    //     }
    // }

    showExportFileModal() {
        var modalInstance = this.$uibModal.open({
            animation: true,
            template: importFileTemplate,
            controller: 'importFileModalController',
            backdrop: 'static',
            windowTopClass: 'wideModalWindow',
            resolve: {
                isFileImportModal: false,
                filesToImport: this.importFiles,
                treeItem: this.selectedTreeItems[0],
                receivedDataDateObj: {
                    'value': this.receivedDataDate
                }
            }
        });
        modalInstance.result.then(() => {}, () => {});
    }

    toggleShowTable() {
        this.showTable = !this.showTable;
    }

    allowDrag(item) {
        return item.text !== 'root';
    }

    drop(droppedItem) {
        //alert(droppedItem.text);
        var movedCount = 0;
        for (var i = 0; i < this.selectedTreeItems.length; i++) {
            //eslint-disable-next-line no-loop-func
            this.fileSystemService.moveItem(droppedItem.id, this.selectedTreeItems[i].id).then(() => {
                movedCount++;
                if (movedCount === this.selectedTreeItems.length) {
                    this._refreshDataFromServer(false);
                    this.fileSystemService.getFileSystemData(true, this.sortReverse, this.sortType);
                }
            });
        }
    }

    dragStart(event) {
        this.draggedFile = event.currentItem;
    }

    rootSelected() {
        return this.currentSelectedNode.text === 'root';
    }


    /////////////////////////////////////////////////////////////////////////
    //////////////////////HELPER FUNCTIONS///////////////////////////////////
    /////////////////////////////////////////////////////////////////////////

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    getSelectedTreeItems() {
        var selected = [];
        for (var i = 0; i < this.currentlyOpenedFolder.children.length; i++) {
            if (this.currentlyOpenedFolder.children[i].state.selected) {
                selected.push(this.currentlyOpenedFolder.children[i]);
            }
        }
        return selected;
    }

    getPreviousSibling(node) {
        return node.previousElementSibling;
    }

    getNextSibling(node) {
        return node.nextElementSibling;
    }

    selectAllTextInTextBox(divContainer) {
        //this timeout fires immediately after digest cycle completes.
        this.$timeout(() => {
            var elementToSelctText = document.getElementById('fileManagerRenameTextBox');
            elementToSelctText.select();
        }, 0, false); //pass false here to prevent another digest cycle from firing.
    }

    deselectAll() {
        this.countSelected = 0;
        this.selectedTreeItems = null;
        this.folderSelected = false;
        this.fileSelected = false;
        this.selectedIndexes = [];
        this.selectedElements = [];
        for (var i = 0; i < this.currentlyOpenedFolder.children.length; i++) {
            var thisNode = this.currentlyOpenedFolder.children[i];
            thisNode.state.selected = false;
        }
    }

    getPath(node, id) {
        if (node.id === id) {
            this.currentlyOpenedFolder = node;
            return [];
        } else if (node.children) {
            for (var i = 0; i < node.children.length; i++) {
                var ithNode = node.children[i];
                var path = this.getPath(ithNode, id);

                if (path !== null) {
                    path.unshift({
                        'id': ithNode.id,
                        'text': ithNode.text
                    });
                    return path;
                }
            }

        }
        return null;
    }

    getDisplayedItemClass(isLeaf) {
        switch (isLeaf) {
            case true:
                return 'fmFileItem';
                break;
            default:
                return 'fmFolderItem';

        }
    }

    autoFocusChildInputElement(event) {
        if (!event.target.parentElement.classList.contains('open')) {
            this.$timeout(() => {
                $(document).ready(function () {
                    if (event.target.nextElementSibling === null) {
                        event.target.parentElement.nextElementSibling.getElementsByTagName('input')[0].focus();
                    } else {
                        event.target.nextElementSibling.getElementsByTagName('input')[0].focus();
                    }
                });
            }, 300, false);
        }
    }
}
