export default class fileMoveCopyController {
    constructor($rootScope, $scope, $timeout, common, fileSystemService, fileMoveCopyService, resourceMessagingService) {
        'ngInject';

        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$q = common.$q();
        this.fileSystemService = fileSystemService;
        this.fileMoveCopyService = fileMoveCopyService;
        this.resourceMessagingService = resourceMessagingService;

        this.currentlyOpenedFolder;

        this.breadcrumbs = [];
        this.loading = false;
        this.addNewFolderFormVisible = false;
        this.addNewFolderFormSubmitted = false;
        this.addFolderButtonPressed = false;

        this.receivedDataDate = null;
        this.allRoots = [];
        this.showMultipleRoots = false;

        $scope.$on('$destroy', () => {
            this.handleRefreshMoveCopyTree();
        });
        this.handleRefreshMoveCopyTree = $rootScope.$on('refreshMoveCopyTree', (event, isPrincipalATeamItem, allRoots) => {
            this.breadcrumbs = [];
            this.allRoots = allRoots;
            if (isPrincipalATeamItem) {
                this.showMultipleRoots = false;
                this.rootBreadCrumb = allRoots[0];
                this._getFolderData(this.rootBreadCrumb.id);
            } else {
                if (allRoots.length > 1) {
                    this.breadcrumbs = [];
                    this.showMultipleRoots = true;
                } else {
                    this.rootBreadCrumb = allRoots[0];
                    this._getFolderData(this.rootBreadCrumb.id);
                }
            }
            // this.rootBreadCrumb = rootBreadCrumb;
            // _getFolderData(rootBreadCrumb.id);
        });

        this.activate();
    }

    activate() {
    }
    backToRootSelection() {
        this.showMultipleRoots = true;
    }

    selectRoot(root) {
        this.showMultipleRoots = false;
        this.rootBreadCrumb = root;
        this._getFolderData(root.id);
    }


    hidingModal() {
        this.addNewFolderFormVisible = false;
        this.$rootScope.$emit('moveCopyModalClosed');
    }

    backToRoot() {
        if (this.allRoots.length > 1) {
            this.showMultipleRoots = true;
            this.breadcrumbs = [];
            this.currentlyOpenedFolder = {};
        } else {
            this._getFolderData(this.allRoots[0].id);
        }
    }

    isMove() {
        return this.fileMoveCopyService.isMove();
    }

    itemCount() {
        return this.fileMoveCopyService.itemCount();
    }

    navigate(id) {
        this._getFolderData(id);
    }

    toggleShowAddFolder(show, event) {
        if (this.addFolderButtonPressed === false) {
            if (show === true) {
                this.addNewFolderFormVisible = true;
                this.autoFocusChildInputElement(event);
            }
            else {
                this.addNewFolderFormVisible = false;
            }
        }
    }

    addFolder(valid, event) {
        this.addNewFolderFormSubmitted = true;
        if (valid) {
            var parentToAddToId = this.currentlyOpenedFolder.id;
            this.fileSystemService.addFolder(parentToAddToId, this.newFolderName, 0, this.receivedDataDate)
            .then((newTreeItemId) => {
                this.addNewFolderFormSubmitted = false;
                this.newFolderName = '';
                this._getFolderData(newTreeItemId);
                this.addFolderButtonPressed = false;
                this.toggleShowAddFolder(false, event);
                //update the date modified
                this.$rootScope.$emit('updateReceivedDataDate');
            });
        }
        else {
            this.addFolderButtonPressed = false;
            this.autoFocusAddFolderInputElement(event);
            //autoFocusChildInputElement(event);
        }
    }

    autoFocusAddFolderInputElement(event) {
        if (!event.target.parentElement.classList.contains('open')) {
            this.$timeout(() => {
                if ((event.target.parentElement.parentElement)) {
                    var parent = event.target.parentElement.parentElement;
                    var formelement = parent.children[parent.children.length - 1].children[0];
                    formelement.getElementsByTagName('input')[0].focus();
                    //console.log('focused');
                }
            },300,false);
        }
    }

    submitAddFolder() {
        this.addFolderButtonPressed = true;
    }

    _getFolderData(id) {
        this.loading = true;
        var deferred = this.$q.defer();
        this.fileSystemService.getFolderFoldersOnly(id, this.receivedDataDate).then(
            (response) => {
                this.currentlyOpenedFolder = response;
                var children = response.children;
                this.receivedDataDate = response.receivedDataDate;
                this.displayItems = children.sort(this.dynamicSort('text'));
                this.disableFolderToMoveFromResults();
                this._drawBreadCrumbs();
                this.loading = false;
                deferred.resolve();
            },
            (exception) => {
                deferred.reject();
            }
        );
        return deferred.promise;
    }

    disableFolderToMoveFromResults() {
        if (this.isMove()) {
            var serviceItems = this.fileMoveCopyService.getItems();
            var hiddenIds = [];
            for (var itemIndex = 0; itemIndex < serviceItems.length; itemIndex++) {
                hiddenIds.push(serviceItems[itemIndex].id);
            }

            var alteredDisplayItems = [];

            for (var i = 0; i < this.displayItems.length; i++) {
                if (!(this.arrayContains(hiddenIds, this.displayItems[i].id))) {
                    alteredDisplayItems.push(this.displayItems[i]);
                }
            }

            this.displayItems = alteredDisplayItems;
        }
    }

    arrayContains(array, id) {
        for (var i = 0; i < array.length; i++) {
            if (array[i] === id) {
                return true;
            }
        }

        return false;
    }

    // function disableFolderToMoveFromResults() {
    //     if (isMove()) {
    //         var itemToMoveId = fileMoveCopyService.getItems()[0].id;
    //         for (var i = 0; i < this.displayItems.length; i++) {
    //             if (this.displayItems[i].id === itemToMoveId) {
    //                 this.displayItems.splice(this.displayItems[i], 1);
    //                 //found it, remove it and continue.
    //                 break;
    //             }
    //         }
    //     }
    // }

    dynamicSort(property) {
        var sortOrder = 1;

        return (a,b) => {
            var result;
            result = (a[property].toLowerCase() < b[property].toLowerCase()) ? -1 : (a[property].toLowerCase() > b[property].toLowerCase()) ? 1 : 0;
            return result * sortOrder;
        };
    }

    _drawBreadCrumbs() {
        var newBreadCrumb = this._getBreadCrumbFromTreeItem(this.currentlyOpenedFolder);
        if (this.breadcrumbs.length === 0)
        {
            this.breadcrumbs.push(this.rootBreadCrumb);
            setTimeout(() => {
                $('.arrows').scrollLeft(1000);
            },500);
        } else {
            var searchIndex = -1;
            for (var i = 0; i < this.breadcrumbs.length; i++) {
                if (this.breadcrumbs[i].id === newBreadCrumb.id) {
                    searchIndex = i;
                    break;
                }
            }
            if (searchIndex > -1) {
                this.breadcrumbs = this.breadcrumbs.slice(0,searchIndex + 1);
            } else {
                this.breadcrumbs.push(newBreadCrumb);
                setTimeout(() => {
                    $('.arrows').scrollLeft(1000);
                },500);
            }
        }
    }

    _getBreadCrumbFromTreeItem(treeItem) {
        var obj = {
            'id': treeItem.id,
            'text': treeItem.text
        };
        return obj;
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    autoFocusChildInputElement(event) {
        if (!event.target.parentElement.classList.contains('open')) {
            this.$timeout(() => {
                if ((event.target.parentElement.parentElement) && (event.target.parentElement.parentElement.nextElementSibling)) {
                    event.target.parentElement.parentElement.nextElementSibling.getElementsByTagName('input')[0].focus();
                    //console.log('focused');
                }
            },300,false);
        }
    }
}
// (function () {
//     'use strict';
//     var app = angular.module('app');

//     app.controller('fileMoveCopyController', ['$rootScope', '$scope', '$timeout', 'common', 'fileSystemService', 'fileMoveCopyService',
//         'resourceMessagingService',
//         function ($rootScope, $scope, $timeout, common, fileSystemService, fileMoveCopyService, resourceMessagingService) {
//             //controller code goes here.
//             /* jshint validthis: true */

//             var vm = this;
//             var $q = common.$q;

//             vm.currentlyOpenedFolder;

//             vm.breadcrumbs = [];
//             vm.loading = false;
//             vm.isMove = isMove;
//             vm.itemCount = itemCount;
//             vm.rootBreadCrumb;

//             vm.getText = getText;
//             vm.navigate = navigate;

//             vm.hidingModal = hidingModal;

//             vm.addNewFolderFormVisible = false;
//             vm.addNewFolderFormSubmitted = false;
//             vm.addFolder = addFolder;
//             vm.toggleShowAddFolder = toggleShowAddFolder;
//             vm.newFolderName;
//             vm.submitAddFolder = submitAddFolder;
//             vm.selectRoot = selectRoot;
//             vm.backToRootSelection = backToRootSelection;

//             vm.addFolderButtonPressed = false;

//             vm.backToRoot = backToRoot;
//             vm.receivedDataDate = null;
//             vm.allRoots = [];
//             vm.showMultipleRoots = false;

//             function activate() {
//             }

//             var handleRefreshMoveCopyTree = $rootScope.$on('refreshMoveCopyTree', function(event, isPrincipalATeamItem, allRoots) {
//                 vm.breadcrumbs = [];
//                 vm.allRoots = allRoots;
//                 if (isPrincipalATeamItem) {
//                     vm.showMultipleRoots = false;
//                     vm.rootBreadCrumb = allRoots[0];
//                     _getFolderData(vm.rootBreadCrumb.id);
//                 } else {
//                     if (allRoots.length > 1) {
//                         vm.breadcrumbs = [];
//                         vm.showMultipleRoots = true;
//                     } else {
//                         vm.rootBreadCrumb = allRoots[0];
//                         _getFolderData(vm.rootBreadCrumb.id);
//                     }
//                 }
//                 // vm.rootBreadCrumb = rootBreadCrumb;
//                 // _getFolderData(rootBreadCrumb.id);
//             });

//             function backToRootSelection() {
//                 vm.showMultipleRoots = true;
//             }

//             function selectRoot(root) {
//                 vm.showMultipleRoots = false;
//                 vm.rootBreadCrumb = root;
//                 _getFolderData(root.id);
//             }

//             $scope.$on('$destroy', function() {
//                 handleRefreshMoveCopyTree();
//             });

//             function hidingModal() {
//                 vm.addNewFolderFormVisible = false;
//                 $rootScope.$emit('moveCopyModalClosed');
//             }

//             function backToRoot() {
//                 if (vm.allRoots.length > 1) {
//                     vm.showMultipleRoots = true;
//                     vm.breadcrumbs = [];
//                     vm.currentlyOpenedFolder = {};
//                 } else {
//                     _getFolderData(vm.allRoots[0].id);
//                 }
//             }

//             function isMove() {
//                 return fileMoveCopyService.isMove();
//             }

//             function itemCount() {
//                 return fileMoveCopyService.itemCount();
//             }

//             function navigate(id) {
//                 _getFolderData(id);
//             }

//             function toggleShowAddFolder(show, event) {
//                 if (vm.addFolderButtonPressed === false) {
//                     if (show === true) {
//                         vm.addNewFolderFormVisible = true;
//                         autoFocusChildInputElement(event);
//                     }
//                     else {
//                         vm.addNewFolderFormVisible = false;
//                     }
//                 }
//             }

//             function addFolder(valid, event) {
//                 vm.addNewFolderFormSubmitted = true;
//                 if (valid) {
//                     var parentToAddToId = vm.currentlyOpenedFolder.id;
//                     fileSystemService.addFolder(parentToAddToId, vm.newFolderName, 0, vm.receivedDataDate)
//                     .then(function (newTreeItemId) {
//                         vm.addNewFolderFormSubmitted = false;
//                         vm.newFolderName = '';
//                         _getFolderData(newTreeItemId);
//                         vm.addFolderButtonPressed = false;
//                         toggleShowAddFolder(false, event);
//                         //update the date modified
//                         $rootScope.$emit('updateReceivedDataDate');
//                     });
//                 }
//                 else {
//                     vm.addFolderButtonPressed = false;
//                     autoFocusAddFolderInputElement(event);
//                     //autoFocusChildInputElement(event);
//                 }
//             }

//             function autoFocusAddFolderInputElement(event) {
//                 if (!event.target.parentElement.classList.contains('open')) {
//                     $timeout(function() {
//                         if ((event.target.parentElement.parentElement)) {
//                             var parent = event.target.parentElement.parentElement;
//                             var formelement = parent.children[parent.children.length - 1].children[0];
//                             formelement.getElementsByTagName('input')[0].focus();
//                             //console.log('focused');
//                         }
//                     },300,false);
//                 }
//             }

//             function submitAddFolder() {
//                 vm.addFolderButtonPressed = true;
//             }

//             function _getFolderData(id) {
//                 vm.loading = true;
//                 var deferred = $q.defer();
//                 fileSystemService.getFolderFoldersOnly(id, vm.receivedDataDate).then(
//                     function (response) {
//                         vm.currentlyOpenedFolder = response;
//                         var children = response.children;
//                         vm.receivedDataDate = response.receivedDataDate;
//                         vm.displayItems = children.sort(dynamicSort('text'));
//                         disableFolderToMoveFromResults();
//                         _drawBreadCrumbs();
//                         vm.loading = false;
//                         deferred.resolve();
//                     },
//                     function(exception) {
//                         deferred.reject();
//                     }
//                 );
//                 return deferred.promise;
//             }

//             function disableFolderToMoveFromResults() {
//                 if (isMove()) {
//                     var serviceItems = fileMoveCopyService.getItems();
//                     var hiddenIds = [];
//                     for (var itemIndex = 0; itemIndex < serviceItems.length; itemIndex++) {
//                         hiddenIds.push(serviceItems[itemIndex].id);
//                     }

//                     var alteredDisplayItems = [];

//                     for (var i = 0; i < vm.displayItems.length; i++) {
//                         if (!(arrayContains(hiddenIds, vm.displayItems[i].id))) {
//                             alteredDisplayItems.push(vm.displayItems[i]);
//                         }
//                     }

//                     vm.displayItems = alteredDisplayItems;
//                 }
//             }

//             function arrayContains(array, id) {
//                 for (var i = 0; i < array.length; i++) {
//                     if (array[i] === id) {
//                         return true;
//                     }
//                 }

//                 return false;
//             }

//             // function disableFolderToMoveFromResults() {
//             //     if (isMove()) {
//             //         var itemToMoveId = fileMoveCopyService.getItems()[0].id;
//             //         for (var i = 0; i < vm.displayItems.length; i++) {
//             //             if (vm.displayItems[i].id === itemToMoveId) {
//             //                 vm.displayItems.splice(vm.displayItems[i], 1);
//             //                 //found it, remove it and continue.
//             //                 break;
//             //             }
//             //         }
//             //     }
//             // }

//             function dynamicSort(property) {
//                 var sortOrder = 1;

//                 return function (a,b) {
//                     var result;
//                     result = (a[property].toLowerCase() < b[property].toLowerCase()) ? -1 : (a[property].toLowerCase() > b[property].toLowerCase()) ? 1 : 0;
//                     return result * sortOrder;
//                 };
//             }

//             function _drawBreadCrumbs() {
//                 var newBreadCrumb = _getBreadCrumbFromTreeItem(vm.currentlyOpenedFolder);
//                 if (vm.breadcrumbs.length === 0)
//                 {
//                     vm.breadcrumbs.push(vm.rootBreadCrumb);
//                     setTimeout(function(){
//                         $('.arrows').scrollLeft(1000);
//                     },500);
//                 } else {
//                     var searchIndex = -1;
//                     for (var i = 0; i < vm.breadcrumbs.length; i++) {
//                         if (vm.breadcrumbs[i].id === newBreadCrumb.id) {
//                             searchIndex = i;
//                             break;
//                         }
//                     }
//                     if (searchIndex > -1) {
//                         vm.breadcrumbs = vm.breadcrumbs.slice(0,searchIndex + 1);
//                     } else {
//                         vm.breadcrumbs.push(newBreadCrumb);
//                         setTimeout(function(){
//                             $('.arrows').scrollLeft(1000);
//                         },500);
//                     }
//                 }
//             }

//             function _getBreadCrumbFromTreeItem(treeItem) {
//                 var obj = {
//                     'id': treeItem.id,
//                     'text': treeItem.text
//                 };
//                 return obj;
//             }

//             function getText(key) {
//                 return resourceMessagingService.getText(key);
//             }

//             function autoFocusChildInputElement(event) {
//                 if (!event.target.parentElement.classList.contains('open')) {
//                     $timeout(function() {
//                         if ((event.target.parentElement.parentElement) && (event.target.parentElement.parentElement.nextElementSibling)) {
//                             event.target.parentElement.parentElement.nextElementSibling.getElementsByTagName('input')[0].focus();
//                             //console.log('focused');
//                         }
//                     },300,false);
//                 }
//             }

//             activate();
//         }]);
// }());
