import noneSkewImage from '../../../../images/None_Skew.png';
import connectorOptionsLeftImage from '../../../../images/ConnectorOptionsLeft.png';
import connectorOptionsRightImage from '../../../../images/ConnectorOptionsRight.png';
import seatPitchSlopedUpImage from '../../../../images/SlopedUp.png';
import seatPitchFlatImage from '../../../../images/SlopedNone.png';
import seatPitchRotatedFlatImage from '../../../../images/ConnectorOptionsRotated.png';
import seatPitchSlopedDownImage from '../../../../images/SlopedDown.png';
import beamTopImage from '../../../../images/beam_top.png';
import plateOnStellTopImage from '../../../../images/plate_on_steel_top.png';
import plateOnConcreteTopImage from '../../../../images/plate_on_concrete_top.png';
import tjiTopImage from '../../../../images/tji_top.png';
import masonryEmbededTopImage from '../../../../images/Masonry_embeded_top.png';
import studWallTopImage from '../../../../images/studwall_top.png';
import steelBeamTopImage from '../../../../images/steel_beam_top.png';
import beamFaceImage from '../../../../images/beam_face.png';
import tjiFaceImage from '../../../../images/tji_face.png';
import masonryEmbededFaceImage from '../../../../images/Masonry_embeded_face.png';
import noneImage from '../../../../images/None.png';
import offsetTopFlangeLeft from '../../../../images/OffsetTopFlangeLeft.png';
import offsetTopFlangeRight from '../../../../images/OffsetTopFlangeRight.png';
export default class connectorDialogController {
    constructor($scope, $uibModalInstance, connectorDialogService, resourceMessagingService, items) {
        'ngInject';

        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        this.connectorDialogService = connectorDialogService;
        this.resourceMessagingService = resourceMessagingService;
        this.items = items;

        var memberIsSloped = false;

        this.index = null;
        this.width = null;
        this.supportsData = null;
        this.disable = null;
        this.isLeftSupport = null;
        this.isRightSupport = null;
        this.matchPlumbDepth = null;
        this.supportType = null;
        this.supportConfiguration = null;
        this.showSupportPlies = null;
        this.supportMaterial = null;
        this.pliesArray = null;
        this.showSupportDepth = null;
        this.showPlateOptions = null;
        this.supportPlies = null;
        this.showConcealedInvertedFlanges = null;
        this.showOffsetTopFlange = null;
        this.plateArray = null;
        this.plateOption = null;
        this.flangeConcealment = null;
        this.connectorInputData = null;
        this.supportDepth = null;
        this.supportDepthString = null;
        this.skewAngle = null;
        this.offsetTopFlange = null;
        this.connectorData = null;
        this.dialogRendered = false;
        this.hangerSeatIsFlat = null;
        this.disableOK = false;
        this.okClicked = false;
        this.showSeatPitch = false;

        this.activate();
    }

    testChangeConnectorImage() {
        this.changeConnectorImage(this.supportType);
    }

    testSetupModels(data, index) {
        this.setupModels(data, index);
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    activate() {
        this.index = this.items.index + 1;
        this.supportsData = this.items.supportdata;
        this.width = this.supportsData.SupportsModelData[this.items.index].SupportModelData.Width_friendly;
        this.supportType = this.supportsData.SupportsModelData[this.items.index].SupportModelData.SupportType;
        this.supportConfiguration = this.supportsData.SupportsModelData[this.items.index].SupportModelData.Configuration;
        this.supportMaterial = this.supportsData.SupportsModelData[this.items.index].SupportModelData.Material;
        this.disable = true;
        this.matchPlumbDepth = true;

        this.plateArray = [this.getText('topFlangeOptionsSingle2X'),this.getText('topFlangeOptionsDouble2X'),this.getText('topFlangeOptions3X'),this.getText('topFlangeOptions4X')];
        this.getConnectorData();
        this.determineVisibility();
    }

/*eslint-disable complexity */
    determineVisibility() {
        if (this.supportType === 4 && this.supportMaterial === 9 || this.supportType === 0 && this.supportMaterial === 1) {
            this.showSupportPlies = true;
            if (this.supportMaterial === 9) {
                this.pliesArray = [1,2,3];
            }
            else {
                this.pliesArray = [1,2,3,4];
            }
            //this.supportPlies = this.pliesArray[0];
        }
        else {
            this.showSupportPlies = false;
        }

        if (this.supportType === 6 || this.supportType === 2 || this.supportType === 3) {
            this.showSupportDepth = false;
        }
        else {
            this.showSupportDepth = true;
        }

        if (this.supportType === 2 || this.supportType === 1) {
            this.showPlateOptions = true;
        }
        else {
            this.showPlateOptions = false;
        }
        //2 facemount // 1 topmount
        if (this.supportsData.SupportsModelData[this.items.index].SupportModelData.Configuration === 2) {
            this.showConcealedInvertedFlanges = true;
            this.showOffsetTopFlange = false;
        }
        else {
            this.showConcealedInvertedFlanges = false;
            this.showOffsetTopFlange = true;
        }

        if (this.index === 1) {
            this.isLeftSupport = true;
            this.isRightSupport = false;
        }
        else {
            this.isLeftSupport = false;
            this.isRightSupport = true;
        }

        this.showSeatPitch = this.items.isPitched;
    }

    changeConnectorImage(supportType) {
        var images = document.getElementsByName('connectorImage');
        var div = document.getElementsByName('bearingWidth');
        //top mount
        if (this.supportConfiguration === 1) {
            switch (supportType)
            {
                case 0:  case 8://beam, ledger on masonry
                    if (this.isLeftSupport) {
                        images[0].src = beamTopImage;
                        var style = div[0].style.cssText;
                        style += ' margin-left: 96px;';
                        div[0].style.cssText = style;
                    }
                    else {
                        images[1].src = beamTopImage;
                        var style = div[1].style.cssText;
                        style += ' margin-left: 74px;';
                        div[1].style.cssText = style;
                    }
                    break;
                case 1: //plate on steel
                    if (this.isLeftSupport) {
                        images[0].src = plateOnStellTopImage;
                        var style = div[0].style.cssText;
                        style += ' margin-left: 96px;';
                        div[0].style.cssText = style;
                    }
                    else {
                        images[1].src = plateOnStellTopImage;
                        var style = div[1].style.cssText;
                        style += ' margin-left: 73px;';
                        div[1].style.cssText = style;
                    }
                    break;
                case 2: //plate on concrete
                    if (this.isLeftSupport) {
                        images[0].src = plateOnConcreteTopImage;
                        var style = div[0].style.cssText;
                        style += ' margin-left: 75px;';
                        div[0].style.cssText = style;
                    }
                    else {
                        images[1].src = plateOnConcreteTopImage;
                        var style = div[1].style.cssText;
                        style += ' margin-left: 86px;';
                        div[1].style.cssText = style;
                    }
                    break;
                case 4: //tji
                    if (this.isLeftSupport) {
                        images[0].src = tjiTopImage;
                        var style = div[0].style.cssText;
                        style += ' margin-left: 92px;';
                        div[0].style.cssText = style;
                    }
                    else {
                        images[1].src = tjiTopImage;
                        var style = div[1].style.cssText;
                        style += ' margin-left: 68px;';
                        div[1].style.cssText = style;
                    }
                    break;
                case 5: //masonry embeded
                    if (this.isLeftSupport) {
                        images[0].src = masonryEmbededTopImage;
                        var style = div[0].style.cssText;
                        style += ' margin-left: 93px;';
                        div[0].style.cssText = style;
                    }
                    else {
                        images[1].src = masonryEmbededTopImage;
                        var style = div[1].style.cssText;
                        style += ' margin-left: 68px;';
                        div[1].style.cssText = style;
                    }
                    break;
                case 6: //stud wall
                    if (this.isLeftSupport) {
                        images[0].src = studWallTopImage;
                        var style = div[0].style.cssText;
                        style += ' margin-left: 67px;';
                        div[0].style.cssText = style;
                    }
                    else {
                        images[1].src = studWallTopImage;
                        var style = div[1].style.cssText;
                        style += ' margin-left: 100px;';
                        div[1].style.cssText = style;
                    }
                    break;
                case 7: //steel beam
                    if (this.isLeftSupport) {
                        images[0].src = steelBeamTopImage;
                        var style = div[0].style.cssText;
                        style += ' margin-left: 93px;';
                        div[0].style.cssText = style;
                    }
                    else {
                        images[1].src = steelBeamTopImage;
                        var style = div[1].style.cssText;
                        style += ' margin-left: 68px;';
                        div[1].style.cssText = style;
                    }
                    break;
            }

        }
        else {
            //face mount
            switch (supportType)
            {
                case 0: case 8://beam, ledger on masonry
                    if (this.isLeftSupport) {
                        images[0].src = beamFaceImage;
                        var style = div[0].style.cssText;
                        style += ' margin-left: 85px;';
                        div[0].style.cssText = style;
                    }
                    else {
                        images[1].src = beamFaceImage;
                        var style = div[1].style.cssText;
                        style += ' margin-left: 75px;';
                        div[1].style.cssText = style;
                    }
                    break;
                case 4: //tji
                    if (this.isLeftSupport) {
                        images[0].src = tjiFaceImage;
                        var style = div[0].style.cssText;
                        style += ' margin-left: 85px;';
                        div[0].style.cssText = style;
                    }
                    else {
                        images[1].src = tjiFaceImage;
                        var style = div[1].style.cssText;
                        style += ' margin-left: 75px;';
                        div[1].style.cssText = style;
                    }
                    break;
                case 5: //masonry embeded
                    if (this.isLeftSupport) {
                        images[0].src = masonryEmbededFaceImage;
                        var style = div[0].style.cssText;
                        style += ' margin-left: 85px;';
                        div[0].style.cssText = style;
                    }
                    else {
                        images[1].src = masonryEmbededFaceImage;
                        var style = div[1].style.cssText;
                        style += ' margin-left: 75px;';
                        div[1].style.cssText = style;
                    }
                    break;
            }

        }
        //flip image
        if (this.isLeftSupport === false) {
            var style = images[1].style.cssText;
            style += ' transform: scale(-1,1); -webkit-transform: scale(-1,1); -moz-transform: scale(-1,1); -o-transform: scale(-1,1);';
            images[1].style.cssText = style;
        }
    }

    skewRadioChanged(name) {
        var radios = document.getElementsByName(name);
        var value;
        for (var i = 0; i < radios.length; i++) {
            if (radios[i].type === 'radio' && radios[i].checked) {
                value = i;
                break;
            }
        }

        var inputboxes = document.getElementsByName('skewdegreebox');
        if (value !== 0) {
            this.disable = false;
            inputboxes[0].style.pointerEvents = 'all';
            inputboxes[0].style.background = 'white';
            if (this.skewAngle === 0 || inputboxes[0].value === '') {
                this.skewAngle = 45;
                inputboxes[0].value = 45;
            }
        }
        else {
            this.disable = true;
            inputboxes[0].style.pointerEvents = 'none';
            inputboxes[0].style.background = '#eee';
            inputboxes[0].value = '';
            this.skewAngle = '';
        }
        this.changeSkewImage(value);
    }

    changeSkewImage(value)
    {
        var images = document.getElementsByName('skewImage');
        switch (value)
        {
            case 0:
                images[0].src = noneSkewImage;
                break;
            case 1:
                images[0].src = connectorOptionsLeftImage;
                break;
            case 2:
                images[0].src = connectorOptionsRightImage;
                break;
        }
    }

/*eslint-enable complexity */
    offsetRadioChanged(name) {
        var radios = document.getElementsByName(name);
        var index;
        for (var i = 0; i < radios.length; i++) {
            if (radios[i].type === 'radio' && radios[i].checked) {
                this.offsetTopFlange = parseInt(radios[i].value);
                index = i;
                break;
            }
        }
        this.changeOffsetImage(index);
    }

    changeOffsetImage(value) {
        var images = document.getElementsByName('offsetImage');
        switch (value)
        {
            case 0:
                images[0].src = noneImage;
                break;
            case 1:
                images[0].src = offsetTopFlangeLeft;
                break;
            case 2:
                images[0].src = offsetTopFlangeRight;
                break;
        }
    }

    pitchRadioChanged(name) {
        var radios = document.getElementsByName(name);
        if (radios[1].checked) {
            this.hangerSeatIsFlat = true;
            this.seatPitchImage = seatPitchRotatedFlatImage;
        }
        else {
            this.hangerSeatIsFlat = false;
            if (this.isLeftSupport) {
                this.seatPitchImage = seatPitchSlopedUpImage;
            } else {
                this.seatPitchImage = seatPitchSlopedDownImage;
            }
        }
    }

    concealedFlangesRadioChanged(name) {
        var radios = document.getElementsByName(name);
        if (radios[0].checked) {
            this.flangeConcealment = true;
        }
        else {
            this.flangeConcealment = false;
        }
    }

    matchPlumbDepthChanged() {
        var checkboxes = document.getElementsByName('plumbDepthCheckBox');
        var inputboxL = document.getElementsByName('userdefineddepthL');
        var inputboxR = document.getElementsByName('userdefineddepthR');
        if (this.isLeftSupport) {
            if (checkboxes[0].checked === true) {
                inputboxL[0].style.pointerEvents = 'none';
                inputboxL[0].style.background = '#eee';
                inputboxL[0].value = '';
                this.supportDepth = 0;
                this.supportDepthString = '';
            }
            else {
                inputboxL[0].style.pointerEvents = 'all';
                inputboxL[0].style.background = 'white';
                inputboxL[0].style.setProperty('border-top-color','#b3b3b3');
                inputboxL[0].style.setProperty('border-top-style','solid;');
            }
        }
        else {
            if (checkboxes[1].checked === true) {
                inputboxR[0].style.pointerEvents = 'none';
                inputboxR[0].style.background = '#eee';
                inputboxR[0].value = '';
                this.supportDepth = 0;
                this.supportDepthString = '';
            }
            else {
                inputboxR[0].style.pointerEvents = 'all';
                inputboxR[0].style.background = 'white';
                inputboxL[0].style.setProperty('border-top-color','#b3b3b3');
                inputboxL[0].style.setProperty('border-top-style','solid;');
            }
        }
    }

    getConnectorData() {
        this.connectorDialogService.getConnectorData().then((response) => {
            this.connectorData = response;
            var index;
            if (this.isLeftSupport === true) {
                index = 0;
            }
            else {
                index = 1;
            }
            this.$uibModalInstance.rendered.then(() => {
                this.setupModels(this.connectorData, index);
                this.changeConnectorImage(this.supportType);
            });
        });
    }

    postConnectorData() {
        if (this.disableOK === true) {
            this.okClicked = true;
        }

        if (this.disableOK === false)
        {
            var index = this.index - 1;
            var nailerThicknessType = this.getNailerThicknessTypePostValue();
            var supportPlies = this.getSupportPliesPostValue();
            var skewAngle = this.setSkewAngleForPostData();

            if (this.matchPlumbDepth === true) {
                this.supportDepth = 0;
            }
            if (this.supportDepth === '' || this.supportDepth === 0) {
                this.matchPlumbDepth = true;
            }

            this.checkDepthForEmptyString();

            var data =
                {
                    'supportIndex': index,
                    'modifiedConnectorData':{
                        'NailerThicknessType': nailerThicknessType,
                        'SupportDepth': this.supportDepth,
                        'SupportDepthMatchesMemberDepth': this.matchPlumbDepth,
                        'SkewAngle': skewAngle,
                        'OffsetTopFlange': this.offsetTopFlange,
                        'ConcealedFlanges': this.flangeConcealment,
                        'SupportID': -1,
                        'SupportPlies': supportPlies,
                        'HangerSeatIsFlat': this.hangerSeatIsFlat
                    }
                };

            this.connectorDialogService.postConnectorData(data).then((response) => {
                this.cancel(); //close dialog after post is complete
            });
        }

    }

    getNailerThicknessTypePostValue() {
        if (this.showPlateOptions === true) {
            return parseInt(this.plateOption) + 1;
        }
        else {
            return 0; //None
        }
    }

    getSupportPliesPostValue() {
        if (this.showSupportPlies === true) {
            return parseInt(this.supportPlies) + 1;
        }
        else {
            return 1;
        }
    }

    checkDepthForEmptyString() {
        if (this.supportDepth === '') {
            this.supportDepth = 0;
            this.supportDepthString = '0';
        }
    }

    setSkewAngleForPostData() {
        var index;//multiply skew angle by -1 if left is checked

        var skewradios = document.getElementsByName('skewradio');
        if (skewradios[1].checked === true) {
            this.skewAngle *= -1;
        }

        if (this.skewAngle === -0) {
            this.skewAngle = 0;
        }
        if (this.skewAngle === '') {
            this.skewAngle = 0;
        }
        return this.skewAngle;
    }

    setupModels(data, index) {
        this.flangeConcealment = data[index].ConcealedFlanges;
        this.offsetTopFlange = data[index].OffsetTopFlange; //0:none 1:left 2:right
        if (data[index].SupportDepth === 0)
        {
            this.supportDepth = '';
            this.supportDepthString = '';
        }
        else {
            //uses single value from PostResposneData to set user depth and show it formatted for selected UOM
            this.supportDepthString = '';
            this.supportDepth = data[index].SupportDepth;
            this.setFriendlyDepth(this.supportDepthString,this.supportDepth);
            //userDepthUOMSetup();
        }
        this.matchPlumbDepth = data[index].SupportDepthMatchesMemberDepth;
        this.SupportPlies = data[index].SupportPlies;
        this.hangerSeatIsFlat = data[index].HangerSeatIsFlat;
        this.setupDialog(data[index],index);
    }

/*eslint-disable complexity */
    setupDialog(data,index) {
        var radios = document.getElementsByName('concealedFlange');
        if (data.ConcealedFlanges === true) {
            radios[0].checked = true;
        }
        else {
            radios[1].checked = true;
        }

        radios = document.getElementsByName('offsetTopFlange');
        for (var i = 0; i < radios.length; ++i)
            {
            if (radios[i].value === String(data.OffsetTopFlange))
            {
                radios[i].checked = true;
                this.changeOffsetImage(i);
                break;
            }
        }

        radios = document.getElementsByName('pitchradio');
        if (data.HangerSeatIsFlat === true) {
            radios[1].checked = true;
            this.seatPitchImage = seatPitchFlatImage;
        }
        else {
            radios[0].checked = true;
            if (this.isLeftSupport) {
                this.seatPitchImage = seatPitchSlopedUpImage;
            } else {
                this.seatPitchImage = seatPitchSlopedDownImage;
            }

        }


        radios = document.getElementsByName('skewradio');
        if (data.SkewAngle === 0) {
            radios[0].checked = true;
            this.skewAngle = '';
        }
        else if (data.SkewAngle < 0) {
            radios[1].checked = true;
            this.skewAngle = data.SkewAngle * -1;
        }
        else {
            radios[2].checked = true;
            this.skewAngle = data.SkewAngle;
        }
        this.setupSkewInputs('skewradio');

        radios = document.getElementsByName('plumbDepthCheckBox');
        if (data.SupportDepthMatchesMemberDepth === true) {
            radios[0].checked = true;
            radios[1].checked = true;
        }
        else {
            radios[0].checked = false;
            radios[1].checked = false;
        }

        switch (data.NailerThicknessType)
        {
            case 1://NailerType.TwoBy
                this.plateOption = '0';
                break;
            case 2://NailerType.DoubleTwoBy
                this.plateOption = '1';
                break;
            case 3://NailerType.ThreeBy
                this.plateOption = '2';
                break;
            case 4://NailerType.FourBy
                this.plateOption = '3';
                break;
            default://NailerType.None
                this.plateOption = '0';
        }

        switch (data.SupportPlies)
        {
            case 1:
                this.supportPlies = '0';
                break;
            case 2:
                this.supportPlies = '1';
                break;
            case 3:
                this.supportPlies = '2';
                break;
            case 4:
                this.supportPlies = '3';
                break;
        }

        this.matchPlumbDepthChanged();
    }
/*eslint-enable complexity */

    setupSkewInputs(name) {
        var radios = document.getElementsByName(name);
        var value;
        for (var i = 0; i < radios.length; i++) {
            if (radios[i].type === 'radio' && radios[i].checked) {
                value = i;
                break;
            }
        }

        var inputboxes = document.getElementsByName('skewdegreebox');
        if (value !== 0) {
            this.disable = false;
            inputboxes[0].style.pointerEvents = 'all';
            inputboxes[0].style.background = 'white';
        }
        else {
            this.disable = true;
            inputboxes[0].style.pointerEvents = 'none';
            inputboxes[0].style.background = '#eee';
            inputboxes[0].value = '';
        }

        this.changeSkewImage(value);
    }

    validateSkewInput() {
        if (this.skewAngle > 90) {
            this.skewAngle = 90;
        }
        else if (isNaN(this.skewAngle)) {
            this.skewAngle = 0;
        }
    }

    //Used when changing the user depth field on the connector dialog to format the string as the selected UOM
    userDepthChanged() {
        this.setFriendlyDepth(this.supportDepthString,null);
    }

    setFriendlyDepth(depthString, depthSingle)
    {
        this.disableOK = true;
        this.connectorDialogService.getFriendlyDepth(depthString, depthSingle).then((response) => {
            this.supportDepth = response.depthValue;
            this.supportDepthString = response.depthFriendly;
            this.disableOK = false;
            if (this.okClicked === true) {
                this.postConnectorData();
                this.okClicked = false;
            }
        })
        .catch((err) => {
        });
    }

    helpClicked() {
        var win = window.open('/helpmobile/Content/D_Design Concepts/connectors_horiz_members.htm', '_blank');
        win.focus();
    }

    cancel() {
        this.$uibModalInstance.dismiss('cancel');
    }
}
// (function () {
//     'use strict';
//     var app = angular.module('app');
//     app.controller('connectorDialogController',
//         ['$rootScope', '$scope', '$uibModalInstance', '$timeout', 'connectorDialogService', 'resourceMessagingService', 'items',
//             function ($rootScope, $scope, $uibModalInstance, $timeout, connectorDialogService, resourceMessagingService, items) {
//                 var vm = $scope;

//                 var memberIsSloped = false;

//                 vm.cancel = cancel;
//                 vm.getText = getText;
//                 vm.skewRadioChanged = skewRadioChanged;
//                 vm.matchPlumbDepthChanged = matchPlumbDepthChanged;
//                 vm.postConnectorData = postConnectorData;
//                 vm.getConnectorData = getConnectorData;
//                 vm.offsetRadioChanged = offsetRadioChanged;
//                 vm.concealedFlangesRadioChanged = concealedFlangesRadioChanged;
//                 vm.validateSkewInput = validateSkewInput;
//                 vm.userDepthChanged = userDepthChanged;
//                 vm.helpClicked = helpClicked;
//                 vm.pitchRadioChanged = pitchRadioChanged;

//                 vm.index = null;
//                 vm.width = null;
//                 vm.supportsData = null;
//                 vm.disable = null;
//                 vm.isLeftSupport = null;
//                 vm.isRightSupport = null;
//                 vm.matchPlumbDepth = null;
//                 vm.supportType = null;
//                 vm.supportConfiguration = null;
//                 vm.showSupportPlies = null;
//                 vm.supportMaterial = null;
//                 vm.pliesArray = null;
//                 vm.showSupportDepth = null;
//                 vm.showPlateOptions = null;
//                 vm.supportPlies = null;
//                 vm.showConcealedInvertedFlanges = null;
//                 vm.showOffsetTopFlange = null;
//                 vm.plateArray = null;
//                 vm.plateOption = null;
//                 vm.flangeConcealment = null;
//                 vm.connectorInputData = null;
//                 vm.supportDepth = null;
//                 vm.supportDepthString = null;
//                 vm.skewAngle = null;
//                 vm.offsetTopFlange = null;
//                 vm.connectorData = null;
//                 vm.dialogRendered = false;
//                 vm.hangerSeatIsFlat = null;
//                 vm.disableOK = false;
//                 vm.okClicked = false;
//                 vm.testChangeConnectorImage = testChangeConnectorImage;
//                 vm.testSetupModels = testSetupModels;
//                 vm.showSeatPitch = false;

//                 var seatPitchSlopedUpImage = '../../../../images/SlopedUp.png'
//                 var seatPitchFlatImage = '../../../../images/SlopedNone.png'
//                 var seatPitchSlopedDownImage = '../../../../images/SlopedDown.png'
//                 vm.seatPitchImage = seatPitchSlopedUpImage;
//                 activate();

//                 function testChangeConnectorImage() {
//                     changeConnectorImage(vm.supportType);
//                 }

//                 function testSetupModels(data, index) {
//                     setupModels(data, index);
//                 }

//                 function getText(key) {
//                     return resourceMessagingService.getText(key);
//                 }

//                 function activate() {
//                     vm.index = items.index + 1;
//                     vm.supportsData = items.supportdata;
//                     vm.width = vm.supportsData.SupportsModelData[items.index].SupportModelData.Width_friendly;
//                     vm.supportType = vm.supportsData.SupportsModelData[items.index].SupportModelData.SupportType;
//                     vm.supportConfiguration = vm.supportsData.SupportsModelData[items.index].SupportModelData.Configuration;
//                     vm.supportMaterial = vm.supportsData.SupportsModelData[items.index].SupportModelData.Material;
//                     vm.disable = true;
//                     vm.matchPlumbDepth = true;

//                     vm.plateArray = [getText('topFlangeOptionsSingle2X'),getText('topFlangeOptionsDouble2X'),getText('topFlangeOptions3X'),getText('topFlangeOptions4X')];
//                     getConnectorData();
//                     determineVisibility();
//                 }

// /*eslint-disable complexity */
//                 function determineVisibility() {
//                     if (vm.supportType === 4 && vm.supportMaterial === 9 || vm.supportType === 0 && vm.supportMaterial === 1) {
//                         vm.showSupportPlies = true;
//                         if (vm.supportMaterial === 9) {
//                             vm.pliesArray = [1,2,3];
//                         }
//                         else {
//                             vm.pliesArray = [1,2,3,4];
//                         }
//                         //vm.supportPlies = vm.pliesArray[0];
//                     }
//                     else {
//                         vm.showSupportPlies = false;
//                     }

//                     if (vm.supportType === 6 || vm.supportType === 2 || vm.supportType === 3) {
//                         vm.showSupportDepth = false;
//                     }
//                     else {
//                         vm.showSupportDepth = true;
//                     }

//                     if (vm.supportType === 2 || vm.supportType === 1) {
//                         vm.showPlateOptions = true;
//                     }
//                     else {
//                         vm.showPlateOptions = false;
//                     }
//                     //2 facemount // 1 topmount
//                     if (vm.supportsData.SupportsModelData[items.index].SupportModelData.Configuration === 2) {
//                         vm.showConcealedInvertedFlanges = true;
//                         vm.showOffsetTopFlange = false;
//                     }
//                     else {
//                         vm.showConcealedInvertedFlanges = false;
//                         vm.showOffsetTopFlange = true;
//                     }

//                     if (vm.index === 1) {
//                         vm.isLeftSupport = true;
//                         vm.isRightSupport = false;
//                     }
//                     else {
//                         vm.isLeftSupport = false;
//                         vm.isRightSupport = true;
//                     }

//                     vm.showSeatPitch = items.isPitched;
//                 }

//                 function changeConnectorImage(supportType) {
//                     var images = document.getElementsByName('connectorImage');
//                     var div = document.getElementsByName('bearingWidth');
//                     //top mount
//                     if (vm.supportConfiguration === 1) {
//                         switch (supportType)
//                         {
//                             case 0:  case 8://beam, ledger on masonry
//                                 if (vm.isLeftSupport) {
//                                     images[0].src = '../../../../images/beam_top.png';
//                                     var style = div[0].style.cssText;
//                                     style += ' margin-left: 96px;';
//                                     div[0].style.cssText = style;
//                                 }
//                                 else {
//                                     images[1].src = '../../../../images/beam_top.png';
//                                     var style = div[1].style.cssText;
//                                     style += ' margin-left: 74px;';
//                                     div[1].style.cssText = style;
//                                 }
//                                 break;
//                             case 1: //plate on steel
//                                 if (vm.isLeftSupport) {
//                                     images[0].src = '../../../../images/plate_on_steel_top.png';
//                                     var style = div[0].style.cssText;
//                                     style += ' margin-left: 96px;';
//                                     div[0].style.cssText = style;
//                                 }
//                                 else {
//                                     images[1].src = '../../../../images/plate_on_steel_top.png';
//                                     var style = div[1].style.cssText;
//                                     style += ' margin-left: 73px;';
//                                     div[1].style.cssText = style;
//                                 }
//                                 break;
//                             case 2: //plate on concrete
//                                 if (vm.isLeftSupport) {
//                                     images[0].src = '../../../../images/plate_on_concrete_top.png';
//                                     var style = div[0].style.cssText;
//                                     style += ' margin-left: 75px;';
//                                     div[0].style.cssText = style;
//                                 }
//                                 else {
//                                     images[1].src = '../../../../images/plate_on_concrete_top.png';
//                                     var style = div[1].style.cssText;
//                                     style += ' margin-left: 86px;';
//                                     div[1].style.cssText = style;
//                                 }
//                                 break;
//                             case 4: //tji
//                                 if (vm.isLeftSupport) {
//                                     images[0].src = '../../../../images/tji_top.png';
//                                     var style = div[0].style.cssText;
//                                     style += ' margin-left: 92px;';
//                                     div[0].style.cssText = style;
//                                 }
//                                 else {
//                                     images[1].src = '../../../../images/tji_top.png';
//                                     var style = div[1].style.cssText;
//                                     style += ' margin-left: 68px;';
//                                     div[1].style.cssText = style;
//                                 }
//                                 break;
//                             case 5: //masonry embeded
//                                 if (vm.isLeftSupport) {
//                                     images[0].src = '../../../../images/Masonry_embeded_top.png';
//                                     var style = div[0].style.cssText;
//                                     style += ' margin-left: 93px;';
//                                     div[0].style.cssText = style;
//                                 }
//                                 else {
//                                     images[1].src = '../../../../images/Masonry_embeded_top.png';
//                                     var style = div[1].style.cssText;
//                                     style += ' margin-left: 68px;';
//                                     div[1].style.cssText = style;
//                                 }
//                                 break;
//                             case 6: //stud wall
//                                 if (vm.isLeftSupport) {
//                                     images[0].src = '../../../../images/studwall_top.png';
//                                     var style = div[0].style.cssText;
//                                     style += ' margin-left: 67px;';
//                                     div[0].style.cssText = style;
//                                 }
//                                 else {
//                                     images[1].src = '../../../../images/studwall_top.png';
//                                     var style = div[1].style.cssText;
//                                     style += ' margin-left: 100px;';
//                                     div[1].style.cssText = style;
//                                 }
//                                 break;
//                             case 7: //steel beam
//                                 if (vm.isLeftSupport) {
//                                     images[0].src = '../../../../images/steel_beam_top.png';
//                                     var style = div[0].style.cssText;
//                                     style += ' margin-left: 93px;';
//                                     div[0].style.cssText = style;
//                                 }
//                                 else {
//                                     images[1].src = '../../../../images/steel_beam_top.png';
//                                     var style = div[1].style.cssText;
//                                     style += ' margin-left: 68px;';
//                                     div[1].style.cssText = style;
//                                 }
//                                 break;
//                         }

//                     }
//                     else {
//                         //face mount
//                         switch (supportType)
//                         {
//                             case 0: case 8://beam, ledger on masonry
//                                 if (vm.isLeftSupport) {
//                                     images[0].src = '../../../../images/beam_face.png';
//                                     var style = div[0].style.cssText;
//                                     style += ' margin-left: 85px;';
//                                     div[0].style.cssText = style;
//                                 }
//                                 else {
//                                     images[1].src = '../../../../images/beam_face.png';
//                                     var style = div[1].style.cssText;
//                                     style += ' margin-left: 75px;';
//                                     div[1].style.cssText = style;
//                                 }
//                                 break;
//                             case 4: //tji
//                                 if (vm.isLeftSupport) {
//                                     images[0].src = '../../../../images/tji_face.png';
//                                     var style = div[0].style.cssText;
//                                     style += ' margin-left: 85px;';
//                                     div[0].style.cssText = style;
//                                 }
//                                 else {
//                                     images[1].src = '../../../../images/tji_face.png';
//                                     var style = div[1].style.cssText;
//                                     style += ' margin-left: 75px;';
//                                     div[1].style.cssText = style;
//                                 }
//                                 break;
//                             case 5: //masonry embeded
//                                 if (vm.isLeftSupport) {
//                                     images[0].src = '../../../../images/Masonry_embeded_face.png';
//                                     var style = div[0].style.cssText;
//                                     style += ' margin-left: 85px;';
//                                     div[0].style.cssText = style;
//                                 }
//                                 else {
//                                     images[1].src = '../../../../images/Masonry_embeded_face.png';
//                                     var style = div[1].style.cssText;
//                                     style += ' margin-left: 75px;';
//                                     div[1].style.cssText = style;
//                                 }
//                                 break;
//                         }

//                     }
//                     //flip image
//                     if (vm.isLeftSupport === false) {
//                         var style = images[1].style.cssText;
//                         style += ' transform: scale(-1,1); -webkit-transform: scale(-1,1); -moz-transform: scale(-1,1); -o-transform: scale(-1,1);';
//                         images[1].style.cssText = style;
//                     }
//                 }

//                 function skewRadioChanged(name) {
//                     var radios = document.getElementsByName(name);
//                     var value;
//                     for (var i = 0; i < radios.length; i++) {
//                         if (radios[i].type === 'radio' && radios[i].checked) {
//                             value = i;
//                             break;
//                         }
//                     }

//                     var inputboxes = document.getElementsByName('skewdegreebox');
//                     if (value !== 0) {
//                         vm.disable = false;
//                         inputboxes[0].style.pointerEvents = 'all';
//                         inputboxes[0].style.background = 'white';
//                         if (vm.skewAngle === 0 || inputboxes[0].value === '') {
//                             vm.skewAngle = 45;
//                             inputboxes[0].value = 45;
//                         }
//                     }
//                     else {
//                         vm.disable = true;
//                         inputboxes[0].style.pointerEvents = 'none';
//                         inputboxes[0].style.background = '#eee';
//                         inputboxes[0].value = '';
//                         vm.skewAngle = '';
//                     }
//                     changeSkewImage(value);
//                 }

//                 function changeSkewImage(value)
//                 {
//                     var images = document.getElementsByName('skewImage');
//                     switch (value)
//                     {
//                         case 0:
//                             images[0].src = '../../../../images/None_Skew.png';
//                             break;
//                         case 1:
//                             images[0].src = '../../../../images/ConnectorOptionsLeft.png';
//                             break;
//                         case 2:
//                             images[0].src = '../../../../images/ConnectorOptionsRight.png';
//                             break;
//                     }
//                 }

// /*eslint-enable complexity */
//                 function offsetRadioChanged(name) {
//                     var radios = document.getElementsByName(name);
//                     var index;
//                     for (var i = 0; i < radios.length; i++) {
//                         if (radios[i].type === 'radio' && radios[i].checked) {
//                             vm.offsetTopFlange = parseInt(radios[i].value);
//                             index = i;
//                             break;
//                         }
//                     }
//                     changeOffsetImage(index);
//                 }

//                 function changeOffsetImage(value) {
//                     var images = document.getElementsByName('offsetImage');
//                     switch (value)
//                     {
//                         case 0:
//                             images[0].src = '../../../../images/None.png';
//                             break;
//                         case 1:
//                             images[0].src = '../../../../images/OffsetTopFlangeLeft.png';
//                             break;
//                         case 2:
//                             images[0].src = '../../../../images/OffsetTopFlangeRight.png';
//                             break;
//                     }
//                 }

//                 function pitchRadioChanged(name) {
//                     var radios = document.getElementsByName(name);
//                     if (radios[1].checked) {
//                         vm.hangerSeatIsFlat = true;
//                         vm.seatPitchImage = seatPitchFlatImage;
//                     }
//                     else {
//                         vm.hangerSeatIsFlat = false;
//                         if (vm.isLeftSupport) {
//                             vm.seatPitchImage = seatPitchSlopedUpImage;
//                         } else {
//                             vm.seatPitchImage = seatPitchSlopedDownImage;
//                         }
//                     }
//                 }

//                 function concealedFlangesRadioChanged(name) {
//                     var radios = document.getElementsByName(name);
//                     if (radios[0].checked) {
//                         vm.flangeConcealment = true;
//                     }
//                     else {
//                         vm.flangeConcealment = false;
//                     }
//                 }

//                 function matchPlumbDepthChanged() {
//                     var checkboxes = document.getElementsByName('plumbDepthCheckBox');
//                     var inputboxL = document.getElementsByName('userdefineddepthL');
//                     var inputboxR = document.getElementsByName('userdefineddepthR');
//                     if (vm.isLeftSupport) {
//                         if (checkboxes[0].checked === true) {
//                             inputboxL[0].style.pointerEvents = 'none';
//                             inputboxL[0].style.background = '#eee';
//                             inputboxL[0].value = '';
//                             vm.supportDepth = 0;
//                             vm.supportDepthString = '';
//                         }
//                         else {
//                             inputboxL[0].style.pointerEvents = 'all';
//                             inputboxL[0].style.background = 'white';
//                             inputboxL[0].style.setProperty('border-top-color','#b3b3b3');
//                             inputboxL[0].style.setProperty('border-top-style','solid;');
//                         }
//                     }
//                     else {
//                         if (checkboxes[1].checked === true) {
//                             inputboxR[0].style.pointerEvents = 'none';
//                             inputboxR[0].style.background = '#eee';
//                             inputboxR[0].value = '';
//                             vm.supportDepth = 0;
//                             vm.supportDepthString = '';
//                         }
//                         else {
//                             inputboxR[0].style.pointerEvents = 'all';
//                             inputboxR[0].style.background = 'white';
//                             inputboxL[0].style.setProperty('border-top-color','#b3b3b3');
//                             inputboxL[0].style.setProperty('border-top-style','solid;');
//                         }
//                     }
//                 }

//                 function getConnectorData() {
//                     connectorDialogService.getConnectorData().then(function (response) {
//                         vm.connectorData = response;
//                         var index;
//                         if (vm.isLeftSupport === true) {
//                             index = 0;
//                         }
//                         else {
//                             index = 1;
//                         }
//                         $uibModalInstance.rendered.then(function() {
//                             setupModels(vm.connectorData, index);
//                             changeConnectorImage(vm.supportType);
//                         });
//                     });
//                 }

//                 function postConnectorData() {
//                     if (vm.disableOK === true) {
//                         vm.okClicked = true;
//                     }

//                     if (vm.disableOK === false)
//                     {
//                         var index = vm.index - 1;
//                         var nailerThicknessType = getNailerThicknessTypePostValue();
//                         var supportPlies = getSupportPliesPostValue();
//                         var skewAngle = setSkewAngleForPostData();

//                         if (vm.matchPlumbDepth === true) {
//                             vm.supportDepth = 0;
//                         }
//                         if (vm.supportDepth === '' || vm.supportDepth === 0) {
//                             vm.matchPlumbDepth = true;
//                         }

//                         checkDepthForEmptyString();

//                         var data =
//                             {
//                                 'supportIndex': index,
//                                 'modifiedConnectorData':{
//                                     'NailerThicknessType': nailerThicknessType,
//                                     'SupportDepth': vm.supportDepth,
//                                     'SupportDepthMatchesMemberDepth': vm.matchPlumbDepth,
//                                     'SkewAngle': skewAngle,
//                                     'OffsetTopFlange': vm.offsetTopFlange,
//                                     'ConcealedFlanges': vm.flangeConcealment,
//                                     'SupportID': -1,
//                                     'SupportPlies': supportPlies,
//                                     'HangerSeatIsFlat': vm.hangerSeatIsFlat
//                                 }
//                             };

//                         connectorDialogService.postConnectorData(data).then(function (response) {
//                             cancel(); //close dialog after post is complete
//                         });
//                     }

//                 }

//                 function getNailerThicknessTypePostValue() {
//                     if (vm.showPlateOptions === true) {
//                         return parseInt(vm.plateOption) + 1;
//                     }
//                     else {
//                         return 0; //None
//                     }
//                 }

//                 function getSupportPliesPostValue() {
//                     if (vm.showSupportPlies === true) {
//                         return parseInt(vm.supportPlies) + 1;
//                     }
//                     else {
//                         return 1;
//                     }
//                 }

//                 function checkDepthForEmptyString() {
//                     if (vm.supportDepth === '') {
//                         vm.supportDepth = 0;
//                         vm.supportDepthString = '0';
//                     }
//                 }

//                 function setSkewAngleForPostData() {
//                     var index;//multiply skew angle by -1 if left is checked

//                     var skewradios = document.getElementsByName('skewradio');
//                     if (skewradios[1].checked === true) {
//                         vm.skewAngle *= -1;
//                     }

//                     if (vm.skewAngle === -0) {
//                         vm.skewAngle = 0;
//                     }
//                     if (vm.skewAngle === '') {
//                         vm.skewAngle = 0;
//                     }
//                     return vm.skewAngle;
//                 }

//                 function setupModels(data, index) {
//                     vm.flangeConcealment = data[index].ConcealedFlanges;
//                     vm.offsetTopFlange = data[index].OffsetTopFlange; //0:none 1:left 2:right
//                     if (data[index].SupportDepth === 0)
//                     {
//                         vm.supportDepth = '';
//                         vm.supportDepthString = '';
//                     }
//                     else {
//                         //uses single value from PostResposneData to set user depth and show it formatted for selected UOM
//                         vm.supportDepthString = '';
//                         vm.supportDepth = data[index].SupportDepth;
//                         setFriendlyDepth(vm.supportDepthString,vm.supportDepth);
//                         //userDepthUOMSetup();
//                     }
//                     vm.matchPlumbDepth = data[index].SupportDepthMatchesMemberDepth;
//                     vm.SupportPlies = data[index].SupportPlies;
//                     vm.hangerSeatIsFlat = data[index].HangerSeatIsFlat;
//                     setupDialog(data[index],index);
//                 }

// /*eslint-disable complexity */
//                 function setupDialog(data,index) {
//                     var radios = document.getElementsByName('concealedFlange');
//                     if (data.ConcealedFlanges === true) {
//                         radios[0].checked = true;
//                     }
//                     else {
//                         radios[1].checked = true;
//                     }

//                     radios = document.getElementsByName('offsetTopFlange');
//                     for (var i = 0; i < radios.length; ++i)
//                         {
//                         if (radios[i].value === String(data.OffsetTopFlange))
//                         {
//                             radios[i].checked = true;
//                             changeOffsetImage(i);
//                             break;
//                         }
//                     }

//                     radios = document.getElementsByName('pitchradio');
//                     if (data.HangerSeatIsFlat === true) {
//                         radios[1].checked = true;
//                         vm.seatPitchImage = seatPitchFlatImage;
//                     }
//                     else {
//                         radios[0].checked = true;
//                         if (vm.isLeftSupport) {
//                             vm.seatPitchImage = seatPitchSlopedUpImage;
//                         } else {
//                             vm.seatPitchImage = seatPitchSlopedDownImage;
//                         }

//                     }


//                     radios = document.getElementsByName('skewradio');
//                     if (data.SkewAngle === 0) {
//                         radios[0].checked = true;
//                         vm.skewAngle = '';
//                     }
//                     else if (data.SkewAngle < 0) {
//                         radios[1].checked = true;
//                         vm.skewAngle = data.SkewAngle * -1;
//                     }
//                     else {
//                         radios[2].checked = true;
//                         vm.skewAngle = data.SkewAngle;
//                     }
//                     setupSkewInputs('skewradio');

//                     radios = document.getElementsByName('plumbDepthCheckBox');
//                     if (data.SupportDepthMatchesMemberDepth === true) {
//                         radios[0].checked = true;
//                         radios[1].checked = true;
//                     }
//                     else {
//                         radios[0].checked = false;
//                         radios[1].checked = false;
//                     }

//                     switch (data.NailerThicknessType)
//                     {
//                         case 1://NailerType.TwoBy
//                             vm.plateOption = '0';
//                             break;
//                         case 2://NailerType.DoubleTwoBy
//                             vm.plateOption = '1';
//                             break;
//                         case 3://NailerType.ThreeBy
//                             vm.plateOption = '2';
//                             break;
//                         case 4://NailerType.FourBy
//                             vm.plateOption = '3';
//                             break;
//                         default://NailerType.None
//                             vm.plateOption = '0';
//                     }

//                     switch (data.SupportPlies)
//                     {
//                         case 1:
//                             vm.supportPlies = '0';
//                             break;
//                         case 2:
//                             vm.supportPlies = '1';
//                             break;
//                         case 3:
//                             vm.supportPlies = '2';
//                             break;
//                         case 4:
//                             vm.supportPlies = '3';
//                             break;
//                     }

//                     matchPlumbDepthChanged();
//                 }
// /*eslint-enable complexity */

//                 function setupSkewInputs(name) {
//                     var radios = document.getElementsByName(name);
//                     var value;
//                     for (var i = 0; i < radios.length; i++) {
//                         if (radios[i].type === 'radio' && radios[i].checked) {
//                             value = i;
//                             break;
//                         }
//                     }

//                     var inputboxes = document.getElementsByName('skewdegreebox');
//                     if (value !== 0) {
//                         vm.disable = false;
//                         inputboxes[0].style.pointerEvents = 'all';
//                         inputboxes[0].style.background = 'white';
//                     }
//                     else {
//                         vm.disable = true;
//                         inputboxes[0].style.pointerEvents = 'none';
//                         inputboxes[0].style.background = '#eee';
//                         inputboxes[0].value = '';
//                     }

//                     changeSkewImage(value);
//                 }

//                 function validateSkewInput() {
//                     if (vm.skewAngle > 90) {
//                         vm.skewAngle = 90;
//                     }
//                     else if (isNaN(vm.skewAngle)) {
//                         vm.skewAngle = 0;
//                     }
//                 }

//                 //uses the single that is in the file to set up the user depth and show it formatted for selected UOM
//                 // function userDepthUOMSetup() {
//                 //     var data = {'stringDepth': null,
//                 //         'singleDepth': null};
//                 //
//                 //     data.stringDepth = vm.supportDepth;
//                 //     connectorDialogService.userDepthUOM(data).then(function (response) {
//                 //         vm.supportDepth = response.singleDepth;
//                 //         vm.supportDepthString = response.stringDepth;
//                 //     });
//                 // }

//                 //Used when changing the user depth field on the connector dialog to format the string as the selected UOM
//                 function userDepthChanged() {
//                     // var data = {'stringDepth': null,
//                     //     'singleDepth': null};
//                     //
//                     // data.stringDepth = vm.supportDepthString;
//                     // connectorDialogService.userDepthUOM(data).then(function (response) {
//                     //     vm.supportDepth = response.singleDepth;
//                     //     vm.supportDepthString = response.stringDepth;
//                     // });
//                     setFriendlyDepth(vm.supportDepthString,null);
//                 }

//                 function setFriendlyDepth(depthString, depthSingle)
//                 {
//                     vm.disableOK = true;
//                     connectorDialogService.getFriendlyDepth(depthString, depthSingle).then(function (response) {
//                         vm.supportDepth = response.depthValue;
//                         vm.supportDepthString = response.depthFriendly;
//                         vm.disableOK = false;
//                         if (vm.okClicked === true) {
//                             postConnectorData();
//                             vm.okClicked = false;
//                         }
//                     })
//                     .catch(function (err) {
//                     });
//                 }

//                 function helpClicked() {
//                     var win = window.open('/helpmobile/Content/D_Design Concepts/connectors_horiz_members.htm', '_blank');
//                     win.focus();
//                 }

//                 function cancel() {
//                     $uibModalInstance.dismiss('cancel');
//                 }

//             }]);
// }());
