import linkedReactionTemplate from '../../modals/linkedReaction/linkedReaction.html';
export default class loadsVertController {
    constructor ($rootScope, $scope, $uibModal, $templateCache, loadsVertYService, loadsVertZService, dirtyCheckService, resourceMessagingService,
        common,  allModelDataService, smartNavService, heightAndSupportsService, notifier) {
        //controller code goes here.
        'ngInject';
        this.$q = common.$q();

        this.memberData;
        this.moreOptionsListVisible;

        this.gridData = [];
        this.ldfData = [];
        this.loadTypeChanged;
        this.wallHeightFriendly;
        this.memberHeightFriendly;
        this.subMenuHeight = 100;
        this.metadata = null;
        this.isEnglish = true;
        this.commentdialogcalled = false;
        this.loadsTabStepContent = 'loadsTabStepContent';
        this.linkedLoads;
        this.linkedLoadsViewPositions;
        this.quickLoadsList = null;
        this.memberSDOData;
        this.enableAddLateralLoad = true;
        this.addedLoad = false;
        this.verticalTributaryWidth;
        this.isVerticalTributaryWidthDisabled = false;

        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$uibModal = $uibModal;
        this.$templateCache = $templateCache;
        this.loadsVertYService = loadsVertYService;
        this.loadsVertZService = loadsVertZService;
        this.notifier = notifier;

        this.dirtyCheckService = dirtyCheckService;
        this.resourceMessagingService = resourceMessagingService;
        this.allModelDataService = allModelDataService;
        this.smartNavService = smartNavService;
        this.heightAndSupportsService = heightAndSupportsService;

        // lateral load functionality
        this.gridDataLateral = [];
        this.metaDataLateral = null;

        this.activate();
    }

    activate() {
        //show the graphic toggle
        this.$scope.$emit('showGraphicToggle', true);
        this.moreOptionsListVisible = false;
        this.isGraphicVisible = false;
        this.linkedLoadsViewPositions = [];

        this._refreshGraphic();
        this._getMemberSDOData();
        this.getMetaData();
        this.getLateralMetadata();
        this.getAllLoadData();
        this.getLateralLoadData();
        this.getJobSettingsData();
        this.getWallHeightFriendly();
        this.isEnglish = this.resourceMessagingService.isCultureEnglish();
        this.resetIsLargeMobileDevice();
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    _getMemberSDOData() {
        var prData = this.allModelDataService.getPostResponseData();
        this.memberManagerData = prData.ApplicationData.MemberManagerData;

        var selectedContainerID = this.memberManagerData.SelectedContainerID;
        var selectedMemberID = this.memberManagerData.SelectedMemberID;
        this.memberSDOData = this.memberManagerData.MemberContainers[selectedContainerID].Members[selectedMemberID];
    }

    _refreshGraphic() {
        var prData = this.allModelDataService.getPostResponseData();
        this.$rootScope.$broadcast('refreshVerticalGraphic', prData.MemberData.GraphicOutputData);
    }

    getWallHeightFriendly() {
        this.heightAndSupportsService.getData().then((response) => {
            this.wallHeightFriendly = response.WallHeightFriendly;
            this.memberHeightFriendly = response.MemberHeightFriendly;
        });
        // var prData = allModelDataService.getPostResponseData();
        // memberData = prData.MemberData;
        // vm.wallHeightFriendly = memberData.HeightAndSupportsData.WallHeightFriendly;
    }

    getMetaData() {
        this.loadsVertYService.getMetaData().then((response) => {
            this.metaData = response;
            this._refreshGraphic();
        });
    }

    getLateralMetadata() {
        this.loadsVertZService.getMetadata().then((response) => {
            this.metaDataLateral = response;
            this._refreshGraphic();
        });
    }

    toggleGraphicZoom() {
        this.isLandscapModal = true;
        this.isNormalModeChart = false;

        this._showGraphModal();
        //need to wait until the modal is shown because this zoomed graphic doesnt exist in the dom until the modal is shown.
        // $timeout(function() {
        //     _broadcastRefreshGraphic('refreshGraphic_zoomed');
        // },500,true);
    }

    _showGraphModal() {
        this.resetIsLargeMobileDevice();
        $('#vertGraphicToggleZoomModal').modal();
    }

    resetIsLargeMobileDevice() {
        this.isLargeMobile = false;
        if (document.documentElement.clientHeight > 811 && document.documentElement.clientHeight < 813) {
            this.isLargeMobile = true;
        }
    }

    getAllLoadData() {
        this.getVertYLoadData();
        this.getAllSupplementalVPData();
    }

    getAllSupplementalVPData() {
        //this converts from JSON object to js array of JSON objects
        var loadGroups = Object.keys(this.vertYLoadData.LoadGroups).map((k) => { return this.vertYLoadData.LoadGroups[k];});
        this.gridData = [];
        var allViewPositions = this.getAllViewPositionValues(loadGroups);

        for (var i = 0; i < allViewPositions.length; i++) {
            var currentVP = allViewPositions[i];
            this.getSupplementalVPData(currentVP,i);
        }
        if (allViewPositions.length === 0) {
            this.verticalTributaryWidth = ' ';
            this.isVerticalTributaryWidthDisabled = true;
        }
    }

    getVertYLoadData() {
        this.vertYLoadData = this.loadsVertYService.getSessionLoadData();
    }

    getLateralLoadData() {
        this.getVertZLoadData();
        // convert monolithic vert z load group data to array of load groups
        var latLoadGroups = Object.keys(this.vertZLoadData.LoadGroups).map((lg) => {
            return this.vertZLoadData.LoadGroups[lg];
        });
        var latViewPositions = this.getAllViewPositionValues(latLoadGroups);

        this.gridDataLateral = [];

        for (var vp = 0; vp < latViewPositions.length; vp++) {
            var currentViewPosition = latViewPositions[vp];
            this.getSupplementalLateralVPData(currentViewPosition, vp);
        }
    }

    getVertZLoadData() {
        this.vertZLoadData = this.loadsVertZService.getSessionLoadData();
    }

    applyRecommendedMinimumEccentricityChanged() {
        this.loadsVertYService.modifyApplyEccentricity(this.vertYLoadData.VerticalEccentricityType).then(
            (response) => {
                this.getVertYLoadData();
            },
            (error) => {

            }
        );
    }

    getAllViewPositionValues(loadGroups) {
        var viewPositionArray = [];
        for (var i = 0; i < loadGroups[0].LoadDefs.length; i++) {
            var currentVPValue = loadGroups[0].LoadDefs[i].ViewPositionValue;
            if (viewPositionArray.indexOf(currentVPValue) > -1) {
                //dont add it
            } else {
                viewPositionArray.push(currentVPValue);
            }
        }
        return viewPositionArray;
    }

    testGetAllViewPositonValues(loadGroups) {
        return this.getAllViewPositionValues(loadGroups);
    }

    getSupplementalVPData(viewPosition,i) {
        this.$rootScope.$broadcast('disableSaveDefaults', false);
        this.loadsVertYService.getSupplementalVPData(viewPosition).then((response) => {
            this.gridData[i] = response;
            if (this.gridData[0] !== null) {
                this.verticalTributaryWidth = this.gridData[0].verticalTributaryWidth;
                this.isVerticalTributaryWidthDisabled = false;
            } else  {
                this.verticalTributaryWidth = ' ';
                this.isVerticalTributaryWidthDisabled = true;
            }
            this.disableSaveAsDefaultButton();
            this.gridData[i].pending = false;
            this.gridDataLateral[0].pending = false;
            this.formLoads.$setPristine();
        });
    }

    getSupplementalLateralVPData(viewPosition, i) {
        this.$rootScope.$broadcast('disableSaveDefaults', false);
        this.loadsVertZService.getSupplementalVPData(viewPosition).then((response) => {
            this.gridDataLateral[i] = response;
            this.disableSaveAsDefaultButton();
            this.gridDataLateral[i].pending = false;
            this.formLateralLoads.$setPristine();
        });
    }

    disableSaveAsDefaultButton()
    {
        for (var i = 0; i < this.gridData.length; ++i)
        {
            if (this.gridData[i].linkedReaction) {
                this.$rootScope.$broadcast('disableSaveDefaults', true);
            }
        }
    }

    showLinkedReactionDialog(loadToUpdate) {
        var modalInstance = this.$uibModal.open({
            animation: true,
            template: linkedReactionTemplate,
            controller: 'linkedReactionController',
            controllerAs: 'lrCtrl',
            backdrop: 'static',
            resolve: {
                loadToUpdate: loadToUpdate
            }
        });
        modalInstance.result.then(function closed(result) {
            switch (result.toString()) {
                case 'success':
                    //refresh the view
                    this.getAllLoadData();
                    break;
                default:
                    //dont refresh.
            }
        })
        .finally(() => {
            //tour stuff
        });
    }

    showUpdateLinkedReactionDialog(load) {
        this.showLinkedReactionDialog(load);
    }
    addLoad(viewPosition) {
        this.addedLoad = true;
        this.loadsVertYService.addLoad(viewPosition).then((response) => {
            this.getAllLoadData();
        });
    }
    copyLoad(viewPosition) {
        this.addedLoad = true;
        this.loadsVertYService.copyLoad(viewPosition).then((response) => {
            this.getAllLoadData();
        });
    }
    deleteLoad(viewPosition) {
        this.addedLoad = false;
        var gridIndex = this.findGridIndex(viewPosition);
        this.gridData[gridIndex].pending = true;
        this.loadsVertYService.deleteLoad(viewPosition).then((response) => {
            this.getAllLoadData();
        },
        (exception) => {
            this.getAllLoadData();
            if (exception.status === 403) {
                var message = this.getText(exception.data.Message);
                this.notifier.fileManagementError(this.getText('error'), message);
            }
        });
    }
    loadTypeChanged(viewPosition, newType) {
        var gridIndex = this.findGridIndex(viewPosition);
        this.gridData[gridIndex].pending = true;
        this.loadsVertYService.modifyLoadType(viewPosition, newType).then((response) => {
            this.getSupplementalVPData(viewPosition, gridIndex);
        });
    }
    _roundString(value, decimals) {
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
    }

    isPost() {
        if (this.memberSDOData.StructuralSystemStringTag === 'floor' && this.memberSDOData.MemberTypeStringTag === 'freestandingpost') {
            return true;
        }
        else {
            return false;
        }
    }

    isWallColumn() {
        if (this.memberSDOData.StructuralSystemStringTag === 'wall' && this.memberSDOData.MemberTypeStringTag === 'column') {
            return true;
        }
        else {
            return false;
        }
    }

    selectAllTextOnFocus($event) {
        $event.target.select();
    }

    modifyTributaryWidth () {
        if (this.gridDataLateral.length > 0) {
            this.gridDataLateral[0].pending = true;
        }
        this.loadsVertYService.modifyTributaryWidth(this.verticalTributaryWidth).then(
            (response) => {
                this.getSupplementalVPData(0,0);
            },
            (error) => {

            }
        );
    }

    loadCommentChanged(event, viewPosition, newValue) {
        if (this.dirtyCheckService.isDirty(event) && this.commentdialogcalled === false) {
            var gridIndex = this.findGridIndex(viewPosition);
            this.gridData[gridIndex].pending = true;
            this.loadsVertYService.modifyLoadComment(viewPosition, newValue)
            .then(() => {
                //var gridIndex = findGridIndex(viewPosition);
                this.getSupplementalVPData(viewPosition, gridIndex);
            });
            //deselectAll();
        }
    }
    modifyLoadComment(viewPosition, loadComment) {
        this.commentdialogcalled = true;
        var modalInstance = this.$uibModal.open({
            animation: true,
            templateUrl: 'modifyCommentModal.html',
            controller: 'holesModifyCommentController',
            controllerAs: 'modifyCommentCtrl',
            windowClass: 'connectorDialog',
            backdrop: 'static',
            size: 'md',
            resolve: {
                items: {
                    comment: loadComment
                }
            }
        });

        var gridIndex = this.findGridIndex(viewPosition);
        modalInstance.result.then((newComment) => {
            this.gridData[gridIndex].pending = true;
            this.loadsVertYService.modifyLoadComment(viewPosition, newComment).then(() => {
                this.getSupplementalVPData(viewPosition, gridIndex);
                this.commentdialogcalled = false;
            });
        }, () => {
            this.gridData[gridIndex].pending = true;
            this.getSupplementalVPData(viewPosition, gridIndex);
            this.commentdialogcalled = false;
        })
        .finally(() => {
            //tour stuff
        });

    }
    modifyLoadCommentLateral(viewPosition, loadComment) {
        this.commentdialogcalled = true;
        var modalInstance = this.$uibModal.open({
            animation: true,
            templateUrl: 'modifyCommentModal.html',
            controller: 'holesModifyCommentController',
            controllerAs: 'modifyCommentCtrl',
            windowClass: 'connectorDialog',
            backdrop: 'static',
            size: 'md',
            resolve: {
                items: {
                    comment: loadComment
                }
            }
        });

        var gridIndex = this.findLateralGridIndex(viewPosition);
        modalInstance.result.then((newComment) => {
            this.gridDataLateral[gridIndex].pending = true;
            this.loadsVertZService.modifyLateralLoadComment(viewPosition, newComment).then(() => {
                this.getSupplementalLateralVPData(viewPosition, gridIndex);
                this.commentdialogcalled = false;
            });
        }, () => {
            this.gridDataLateral[gridIndex].pending = true;
            this.getSupplementalLateralVPData(viewPosition, gridIndex);
            this.commentdialogcalled = false;
        })
        .finally(() => {
            // the other one does it
        });

    }
    modifyDeadLoadMagnitude(event, viewPosition, newValue) {
        if (this.dirtyCheckService.isDirty(event)) {
            var gridIndex = this.findGridIndex(viewPosition);
            this.gridData[gridIndex].pending = true;
            this.loadsVertYService.modifyDeadLoadMagnitude(viewPosition, newValue).then((response) => {
                this.getSupplementalVPData(viewPosition, gridIndex);
            });
        }
    }
    modifyFloorLoadMagnitude(event, viewPosition, newValue) {
        if (this.dirtyCheckService.isDirty(event)) {
            var gridIndex = this.findGridIndex(viewPosition);
            this.gridData[gridIndex].pending = true;
            this.loadsVertYService.modifyFloorLoadMagnitude(viewPosition, newValue).then((response) => {
                this.getSupplementalVPData(viewPosition, gridIndex);
            });
        }
    }
    modifyRoofLoadMagnitude(event, viewPosition, newValue) {
        if (this.dirtyCheckService.isDirty(event)) {
            var gridIndex = this.findGridIndex(viewPosition);
            this.gridData[gridIndex].pending = true;
            this.loadsVertYService.modifyRoofLoadMagnitude(viewPosition, newValue).then((response) => {
                this.getSupplementalVPData(viewPosition, gridIndex);
            });
        }
    }
    modifySnowLoadMagnitude(event, viewPosition, newValue) {
        if (this.dirtyCheckService.isDirty(event)) {
            var gridIndex = this.findGridIndex(viewPosition);
            this.gridData[gridIndex].pending = true;
            this.loadsVertYService.modifySnowLoadMagnitude(viewPosition, newValue).then((response) => {
                this.getSupplementalVPData(viewPosition, gridIndex);
            });
        }
    }
    modifyWindLoadMagnitude(event, viewPosition, newValue) {
        if (this.dirtyCheckService.isDirty(event)) {
            var gridIndex = this.findGridIndex(viewPosition);
            this.gridData[gridIndex].pending = true;
            this.loadsVertYService.modifyWindLoadMagnitude(viewPosition, newValue).then((response) => {
                this.getSupplementalVPData(viewPosition, gridIndex);
            });
        }
    }
    modifySeismicLoadMagnitude(event, viewPosition, newValue) {
        if (this.dirtyCheckService.isDirty(event)) {
            var gridIndex = this.findGridIndex(viewPosition);
            this.gridData[gridIndex].pending = true;
            this.loadsVertYService.modifySeismicLoadMagnitude(viewPosition, newValue).then((response) => {
                this.getSupplementalVPData(viewPosition, gridIndex);
            });
        }
    }

    displayLoadsDropDown(event) {
        this.isEnglish = this.resourceMessagingService.isCultureEnglish();
        if (this.moreOptionsListVisible === true) {
            this.hideLoadsDropDown(event);
        }
        else {
            this.moreOptionsListVisible = true;
            var dropdownElement = angular.element(document.getElementById('firstDropdown'));
            dropdownElement[0].style.display = 'block';
            var isHidden = dropdownElement[0].hidden;
            if (event) {
                event.stopPropagation();
            }
        }
    }

    hideLoadsDropDown(event) {
        this.moreOptionsListVisible = false;
        var dropdownElement = angular.element(document.getElementById('firstDropdown'));
        if (dropdownElement[0]) {
            dropdownElement[0].style.display = 'none';
        }
        if (event) {
            event.stopPropagation();
        }
    }

    keyDown(isPending, $event) {
        //ignore everything accept navigation input if the fidle is "pending"
        if (isPending) {
            if (!($event.keyCode === 9 || $event.keyCode === 16)) { //tab and shift keycodes
                $event.stopPropagation();
                $event.preventDefault();
                console.log('stopped: ' + $event.keyCode);
                return false;
            }
        }
    }

    inputGotFocus(rowIndex, columnIndex) {
        this.selectedRowNumber = rowIndex;
        this.selectedColumnNumber = columnIndex;
    }

    selectAllTextOnFocus($event, rowIndex, columnIndex) {
        $event.target.select();
        this.inputGotFocus(rowIndex, columnIndex);
    }

    getLDFValue(key) {
        var data = this.loadsVertYService.getJobSettingsData();
        var string;
        if (data.BuildingCode === 18 || data.BuildingCode === 23 || data.BuildingCode === 24) {
            string = '';
        }
        else {
            switch (key) {
                case 'dead':
                    string = this.ldfData[0];
                    break;
                case 'floorLive':
                    string = this.ldfData[1];
                    break;
                case 'roofLive':
                    string = this.ldfData[2];
                    break;
                case 'seismic':
                    string = this.ldfData[3];
                    break;
                case 'snow':
                    string = this.ldfData[4];
                    break;
                case 'wind':
                    string = this.ldfData[5];
                    break;
                default:
                    string = '';
            }
        }
        return string;
    }

    getJobSettingsData() {
        var data = this.loadsVertYService.getJobSettingsData();
        var digits = 0;
        var string = data.LDFDead.toString();
        this.ldfData[0] = data.LDFDead.toString();
        digits = this.sigfig(digits, this.ldfData[0].length);
        this.ldfData[1] = data.LDFFloor.toString();
        digits = this.sigfig(digits, this.ldfData[1].length);
        this.ldfData[2] = data.LDFRoof.toString();
        digits = this.sigfig(digits, this.ldfData[2].length);
        this.ldfData[3] = data.LDFSeismic.toString();
        digits = this.sigfig(digits, this.ldfData[3].length);
        this.ldfData[4] = data.LDFSnow.toString();
        digits = this.sigfig(digits, this.ldfData[4].length);
        this.ldfData[5] = data.LDFWind.toString();
        digits = this.sigfig(digits, this.ldfData[5].length);

        for (var i = 0; i < this.ldfData.length; ++i) {
            if (this.ldfData[i].length === 1)
            {
                this.ldfData[i] = this.ldfData[i] + '.';
            }
            if (this.ldfData[i].length < digits)
            {
                var difference =  digits - this.ldfData[i].length;
                for (var d = 0; d < difference; ++d) {
                    this.ldfData[i] = this.ldfData[i] + '0';
                }
            }
            this.ldfData[i] = '(' + this.ldfData[i] + ')';
        }
    }

    sigfig(curentdigits, newvalue) {
        if (newvalue > curentdigits)
        {
            curentdigits = newvalue;
        }
        return curentdigits;
    }

    //original array was indexed based on view position
    //this doesnt work once we start deleting loads from the grid.
    //so we reindex and then search for the specified VP to find the array index of the matching load.
    findGridIndex(viewPosition)
    {
        for (var i = 0; i < this.gridData.length; i++)
        {
            if (this.gridData[i].viewPosition === viewPosition)
            {
                return i;
            }
        }
    }

    // just as above but for the lateral grid
    findLateralGridIndex(viewPosition) {
        for (var latIdx = 0; latIdx < this.gridDataLateral.length; latIdx++) {
            if (this.gridDataLateral[latIdx].viewPosition === viewPosition) {
                return latIdx;
            }
        }
    }

    // lateral load functionality
    addLateralLoad() {
        this.addedLoad = true;
        if (this.gridDataLateral.length < 1 && this.enableAddLateralLoad) {
            this.enableAddLateralLoad = false;
            this.loadsVertZService.addLateralLoad().then((response) => {
                this.getLateralLoadData();
            });
        }
    }

    deleteLateralLoad(viewPosition) {
        this.addedLoad = false;
        this.enableAddLateralLoad = true;
        this.loadsVertZService.deleteLateralLoad(viewPosition).then((response) => {
            this.getLateralLoadData();
        });
    }

    modifyLateralLoadType(viewPosition, value) {
        var lateralGridIndex = this.findLateralGridIndex(viewPosition);
        this.gridDataLateral[lateralGridIndex].pending = true;
        this.loadsVertZService.modifyLateralLoadType(viewPosition, value).then((response) => {
            this.getSupplementalLateralVPData(viewPosition, lateralGridIndex);
        });
    }

    modifyLateralLoadMagnitude(event, viewPosition, value) {
        if (this.dirtyCheckService.isDirty(event)) {
            var lateralGridIndex = this.findLateralGridIndex(viewPosition);
            this.gridDataLateral[lateralGridIndex].pending = true;
            this.loadsVertZService.modifyLateralLoadMagnitude(viewPosition, value).then((response) => {
                this.getSupplementalLateralVPData(viewPosition, lateralGridIndex);
            });
        }
    }

    modifyLateralTribWidth(event, viewPosition, value) {
        if (this.dirtyCheckService.isDirty(event)) {
            var lateralGridIndex = this.findLateralGridIndex(viewPosition);
            this.gridDataLateral[lateralGridIndex].pending = true;
            this.loadsVertZService.modifyLateralLoadTribWidth(viewPosition, value).then((response) => {
                this.getSupplementalLateralVPData(viewPosition, lateralGridIndex);
            });
        }
    }

    modifyLateralLoadComment(event, viewPosition, value) {
        if (this.dirtyCheckService.isDirty(event)) {
            var lateralGridIndex = this.findLateralGridIndex(viewPosition);
            this.gridDataLateral[lateralGridIndex].pending = true;
            this.loadsVertZService.modifyLateralLoadComment(viewPosition, value).then((response) => {
                this.getSupplementalLateralVPData(viewPosition, lateralGridIndex);
            });
        }
    }

    loadCommentChangedLateral(event, viewPosition, value) {
        if (this.dirtyCheckService.isDirty(event)) {
            var lateralGridIndex = this.findLateralGridIndex(viewPosition);
            this.gridDataLateral[lateralGridIndex].pending = true;
            this.loadsVertZService.modifyLateralLoadComment(viewPosition, value).then((response) => {
                this.getSupplementalLateralVPData(viewPosition, lateralGridIndex);
            });
        }
    }
}
