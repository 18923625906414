import connectorDialog from './connectorDialog.html';
import toastr from 'toastr';
export default class spansAndSupportsController {
    constructor($scope, $timeout, $uibModal, $state, $anchorScroll, $location, spansService, allModelDataService,
        supportsService, dirtyCheckService, memberManagementService, resourceMessagingService, notifier) {
        'ngInject';

        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$uibModal = $uibModal;
        this.$state = $state;
        this.$anchorScroll = $anchorScroll;
        this.$location = $location;
        this.spansService = spansService;
        this.supportsService = supportsService;
        this.dirtyCheckService = dirtyCheckService;
        this.memberManagementService = memberManagementService;
        this.resourceMessagingService = resourceMessagingService;
        this.allModelDataService = allModelDataService;
        this.notifier = notifier;

        this.spansData = null;
        this.spansMetadata = null;

        this.supportsData = null;
        this.supportsMetadata = null;
        this.memberInfo = null;
        this.disableSupportsInput = false;
        this.spanInputSelected = false;
        this.supportInputSelected = false;

        this.readyToShowDialog = true;

        this.supportsStepTitle = 'supports';
        this.supportsStepContent = 'spansAndSupports_supportsContent';
        this.supportsStepAction = 'chooseNext';

        this.newSpanLength;
        this.selectedDimensionLocation = 2; // select custom dimesion location as default selection
        this.addedSupport = false;
        this.spanLength = 0;

        this.$supportaccordion = $('#supportaccordion');
        this.$supportaccordion.on('show.bs.collapse', '.collapse', () => {
            this.$supportaccordion.find('.collapse.in').removeClass('in');
            this.$supportaccordion.find('.accordion-toggle').addClass('collapsed');
        });

        // this.$bracingaccordion = $('#bracingaccordion');
        // this.$bracingaccordion.on('show.bs.collapse', '.collapse', () => {
        //     this.$bracingaccordion.find('.collapse.in').removeClass('in');
        //     this.$bracingaccordion.find('.accordion-toggle').addClass('collapsed');
        // });

        this.edgeBracingTypes_CUSTOM = 4;
        this.topBracingLengthModel = '';
        this.bottomBracingLengthModel = '';

        this.activate();
    }

    activate() {
        this.$scope.$emit('showGraphicToggle', true);

        this.getSpansData();

        this.getSupportsData();
        this.getSupportsMetadata();
        this.dimensionLocationSelected(2);
        this.getBracingData();
    }

    bracingTypeModified(edgeIndex, newBracingType) {
        this.disableBracingInput = true;
        this.supportsService.bracingTypeModified(edgeIndex, newBracingType).then(() => {
            this.getBracingData();
            this.disableBracingInput = false;
        });
    }

    getBracingLengthDisplay(edgeIndex) {
        let bracingLengthDisplay = '';
        if (edgeIndex === 0) {
            if (this.topBracing.BracingType === this.edgeBracingTypes_CUSTOM) {
                bracingLengthDisplay = this.topBracing.MaxUnbracedLengthFriendly;
            } else {
                bracingLengthDisplay = this.getText(this.topBracing.MaxUnbracedLengthFriendly);
            }
        } else {
            if (this.bottomBracing.BracingType === this.edgeBracingTypes_CUSTOM) {
                bracingLengthDisplay = this.bottomBracing.MaxUnbracedLengthFriendly;
            } else {
                bracingLengthDisplay = this.getText(this.bottomBracing.MaxUnbracedLengthFriendly);
            }
        }

        return bracingLengthDisplay;
    }

    isBracingLengthInputDisabled(edgeIndex) {
        let bracingLengthInputDisabled = true;
        if (edgeIndex === 0) {
            if (this.topBracing.BracingType === this.edgeBracingTypes_CUSTOM) {
                bracingLengthInputDisabled = false;
            }
        } else {
            if (this.bottomBracing.BracingType === this.edgeBracingTypes_CUSTOM) {
                bracingLengthInputDisabled = false;
            }
        }

        return bracingLengthInputDisabled;
    }

    bracingLengthModified(edgeIndex, newMaxUnbracedLength) {
        this.disableBracingInput = true;
        this.supportsService.bracingLengthModified(edgeIndex, newMaxUnbracedLength).then(() => {
            this.getBracingData();
            this.disableBracingInput = false;
        });
    }

    bracingCommentModified(edgeIndex, newBracingComment) {
        this.disableBracingInput = true;
        this.supportsService.bracingCommentModified(edgeIndex, newBracingComment).then(() => {
            this.getBracingData();
            this.disableBracingInput = false;
        });
    }

    modifyBracingComment(edgeIndex, comment) {
        this.commentdialogcalled = true;
        var modalInstance = this.$uibModal.open({
            animation: true,
            templateUrl: 'modifyCommentModal.html',
            controller: 'holesModifyCommentController',
            controllerAs: 'modifyCommentCtrl',
            windowClass: 'connectorDialog',
            backdrop: 'static',
            size: 'md',
            resolve: {
                items: {
                    comment: comment
                }
            }
        });

        modalInstance.result.then((newComment) => {
            this.bracingCommentModified(edgeIndex, newComment);
        }, () => {
            //dont do anything on cancel
        })
        .finally(() => {});

    }

    bracingKeyDown($event) {
        //ignore everything accept navigation input if the fidle is "pending"
        if (this.disableBracingInput === true) {
            if (!($event.keyCode === 9 || $event.keyCode === 16)) {
                //tab and shift keycodes
                $event.stopPropagation();
                $event.preventDefault();
                //console.log('stopped: ' + $event.keyCode);
                return false;
            }
        }
    }

    bracingValueKeyDown($event) {
        if ($event.keyCode === 13 || $event.keyCode === 189) {
            // enter, minus
            $event.preventDefault();
        }
        if (this.disableBracingInput === true) {
            if (!($event.keyCode === 9 || $event.keyCode === 16)) {
                //tab and shift keycodes
                $event.stopPropagation();
                $event.preventDefault();
                //console.log('stopped: ' + $event.keyCode);
                return false;
            }
        }
    }

    getBracingData() {
        var prData = this.allModelDataService.getPostResponseData();
        var memberData = prData.MemberData;
        this.topBracing = memberData.SupportsData.TopEdgeBracing;
        this.bottomBracing = memberData.SupportsData.BottomEdgeBracing;
        if (this.topBracing !== null) {
            this.topBracingLengthModel = this.getBracingLengthDisplay(0);
            this.bottomBracingLengthModel = this.getBracingLengthDisplay(1);
        }
    }

    scrollToElement(elementId) {
        this.$location.hash(elementId);
        this.$anchorScroll();
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    spanInputGotFocus($event, rowIndex, columnIndex) {
        $event.target.select();
        this.spanInputSelected = true;
        this.supportInputSelected = false;
        this.selectedSpanRowNumber = rowIndex;
        this.selectedSpanColumnNumber = columnIndex;
    }

    supportInputGotFocus(rowIndex, columnIndex) {
        this.spanInputSelected = false;
        this.supportInputSelected = true;
        this.selectedSupportRowNumber = rowIndex;
        this.selectedSupportColumnNumber = columnIndex;
    }

    bearingLengthGotFocus($event, rowIndex, columnIndex) {
        $event.target.select();
        this.supportInputGotFocus(rowIndex, columnIndex);
    }

    keyDown(isPending, $event) {
        //ignore everything accept navigation input if the fidle is "pending"
        if (isPending || this.disableSupportsInput) {
            if (!($event.keyCode === 9 || $event.keyCode === 16)) {
                //tab and shift keycodes
                $event.stopPropagation();
                $event.preventDefault();
                //console.log('stopped: ' + $event.keyCode);
                return false;
            }
        }
    }

    keyPress($event) {
        if ($event.keyCode === 13) {
            $event.preventDefault();
        }
    }

    isMaterialExist(index) {
        var allMaterials = this.supportsData.SupportsModelData[index].AvailableSupportOptionsData._materialOptions;
        var material = this.supportsData.SupportsModelData[index].SupportModelData.Material;
        var isExists = false;
        for (var i = 0; i < allMaterials.length; i++) {
            if (allMaterials[i].Key === material) {
                isExists = true;
            }
        }

        return isExists;
    }

    lateralConnectionChanged(supportIndex, newValue) {
        this.supportsService.modifyLateralConnection(supportIndex, newValue).then(
            () => {
                this.getSupportsData();
            }
        );
    }

    bearingLengthChanged(event, supportIndex, newLength) {
        if (this.dirtyCheckService.isDirty(event)) {
            this.readyToShowDialog = false;
            this.supportsService.modifyBearingLength(supportIndex, newLength).then(() => {
                this.updateSupportData(supportIndex);
                this.readyToShowDialog = true;
                if (this.supportsData.SupportsModelData[supportIndex].SupportModelData.Width < parseFloat(newLength)) {
                    const message = this.getText('longSupportWithLinkedReactionWarning');
                    this.notifier.fileManagementWarning(
                        this.getText('supportWidthAdjusted'),
                        message
                    );
                }
            });
        }
    };

    justificationChanged(supportIndex, newJustification) {
        this.supportsService.modifyJustification(supportIndex, newJustification).then(() => {
            this.getSupportsData();
        });
    };

    configurationChanged(supportIndex, newConfiguration) {
        var targetDiv = 'support' + supportIndex;
        this.supportsData.SupportsModelData[supportIndex].pending = true;
        this.supportsService.modifyConfiguration(supportIndex, newConfiguration).then(() => {
            this.updateSupportData(supportIndex);
        });
    };

    typeChanged(supportIndex, newType) {
        this.supportsService.modifyType(supportIndex, newType).then(() => {
            this.getSupportsData();
        });
    };

    materialChanged(supportIndex, newMaterial) {
        this.supportsService.modifyMaterial(supportIndex, newMaterial).then(() => {
            this.getSupportsData();
        });
    };

    accessoryChanged(supportIndex, newAccessory) {
        this.supportsService.modifyAccessory(supportIndex, newAccessory).then(() => {
            this.getSupportsData();
        });
    };

    checkDimensionTemplateSelection(checkVal) {
        return this.supportsData._dimensionTemplate === checkVal;
    };

    dimensionLocationSelected(newDimLoc) {
        this.disableSupportsInput = true;
        this.supportsService.modifyDimensionLocation(newDimLoc).then(() => {
            this.getSupportsData();
            this.getSupportsMetadata();
        });
    };

    leftOverhangChanged(event, newLength) {
        if (this.dirtyCheckService.isDirty(event)) {
            //this.disableSupportsInput = true;
            if (newLength.length === 0) {
                newLength = 0;
            }
            this.supportsData.SupportsModelData[0].pending = true;
            this.spansService.modifyLeftOverhang(newLength).then(() => {
                this.getSpansData();
                this.updateSupportData(0);
            });
        }
    };

    rightOverhangChanged(event, newLength) {
        if (this.dirtyCheckService.isDirty(event)) {
            //this.disableSupportsInput = true;
            if (newLength.length === 0) {
                newLength = 0;
            }
            this.supportsData.SupportsModelData[this.supportsData.SupportsModelData.length - 1].pending = true;
            this.spansService.modifyRightOverhang(newLength).then(() => {
                this.getSpansData();
                this.updateSupportData(this.supportsData.SupportsModelData.length - 1);
            });
        }
    };

    spanLengthChanged(event, spanIndex, newLength) {
        if (this.dirtyCheckService.isDirty(event)) {
            //console.log('span changed event');
            if (newLength.length === 0) {
                newLength = 0;
                this.disableSupportsInput = true;
            }
            this.spansService.modifyInteriorSpan(spanIndex, newLength).then(() => {
                this.addedSupport = false;
                this.getSpansData();
                this.getSupportsData();
                if (newLength) {
                    this.spanLength = this.spansData._interiorSpans.length;

                    this.$supportaccordion.find('.collapse.in').removeClass('in');
                    this.$supportaccordion.find('.accordion-toggle').addClass('collapsed');

                }
            },
            (exception) => {
                if (exception.status === 403) {
                    this.getSpansData();
                    var message = this.getText(exception.data);
                    this.notifier.fileManagementError(this.getText('error'), message);
                }
            });
        }
    };

    deleteSpan(spanIndex) {
        this.addedSupport = false;
        this.spansService.modifyInteriorSpan(spanIndex, 0).then(() => {
            this.getSpansData();
            this.getSupportsData();
            this.spanLength = this.spansData._interiorSpans.length;
            this.$supportaccordion.find('.collapse.in').removeClass('in');
            this.$supportaccordion.find('.accordion-toggle').addClass('collapsed');
        },
        (exception) => {
            if (exception.status === 403) {
                var message = this.getText(exception.data);
                this.notifier.fileManagementError(this.getText('error'), message);
            }
        });
    };

    spanAdded(newLength, rowIndex, columnIndex) {
        if (newLength) {
            this.disableSupportsInput = true;
            this.spansService.addSpan(newLength).then(() => {
                this.newSpanLength = '';
                this.getSpansData();
                this.getSupportsData();
                this.spanLength = this.spansData._interiorSpans.length;
                //ZS: https://blogs.microsoft.co.il/choroshin/2014/04/08/angularjs-postdigest-vs-timeout-when-dom-update-is-needed/
                //forces the to run after the digest cycle completes.  the final false parameter prevents another digest
                //cycle from taking place.
                this.$timeout(() => {
                    var inputToFocus = document.getElementById('inputNewSpan');
                    inputToFocus.focus();
                    this.selectedSpanColumnNumber = columnIndex + 1;
                }, 0, false);
                this.$supportaccordion.find('.collapse.in').removeClass('in');
                this.$supportaccordion.find('.accordion-toggle').addClass('collapsed');
                this.addedSupport = true;
            });
        }
    };

    isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    isWallHeader() {
        if (this.memberInfo.MemberTypeStringTag === 'header') {
            return true;
        } else {
            return false;
        }
    }

    isWallWindSill() {
        if (this.memberInfo.MemberTypeStringTag === 'windsill') {
            return true;
        } else {
            return false;
        }
    }

    isRoofHipValley() {
        if (this.memberInfo.MemberTypeStringTag === 'hipbeam' && this.memberInfo.StructuralSystemStringTag === 'roof') {
            return true;
        } else {
            return false;
        }
    }

    getSpansData() {
        this.spansService.getSpansData().then((response) => {
            this.spansData = response;
            if (this.formSpans) {
                this.formSpans.$setPristine();
                this.addedSupport = false;
                if (this.spanLength !== 0 && this.spanLength < this.spansData._interiorSpans.length) {
                    toastr.options = {
                        'positionClass': 'toast-top-center',
                    };
                    toastr.success(this.getText('SuccessfullyAdded'));
                    this.addedSupport = true;
                } else if (this.spanLength !== 0 && this.spanLength > this.spansData._interiorSpans.length) {
                    toastr.options = {
                        'positionClass': 'toast-top-center',
                    };
                    toastr.warning(this.getText('SuccessfullyDeleted'));
                }
            }
        });
    }

    getSupportsData() {
        this.supportsService.getSupportsData().then((response) => {
            this.supportsData = response;
            this.disableSupportsInput = false;
            if (this.formSupports) {
                this.formSupports.$setPristine();
            }
        });

    }

    updateSupportData(supportIndex) {
        if (supportIndex >= 0 && supportIndex < this.supportsData.SupportsModelData.length) {
            this.supportsService.getSupportData(supportIndex).then((response) => {
                this.supportsData.SupportsModelData.splice(supportIndex, 1, response);
                // NProgress.done();
                this.supportsData.SupportsModelData[supportIndex].pending = false;
                // var targetDiv = 'support' + supportIndex;
                // enableForteGridColumn(targetDiv);
            });
        }
    }

    getSupportsMetadata() {
        this.supportsService.getSupportsMetadata().then((response) => {
            this.supportsMetadata = response;
            this.setSelectedDimensionLocation();
        });

        this.memberInfo = this.memberManagementService.getCurrentlySelectedMemberSDO();
    }

    setSelectedDimensionLocation() {
        for (var dlIndex = 0; dlIndex < this.supportsMetadata._dimensionLocationOptions.length; dlIndex++) {
            if (this.supportsMetadata._dimensionLocationOptions[dlIndex]._enabled) {
                this.selectedDimensionLocation = dlIndex;
            }
        }
    }

    showConnectorDialog($index) {
        var isPitched = this.memberInfo.MemberPitch > 0.25;

        if (this.readyToShowDialog === true) {
            var modalInstance = this.$uibModal.open({
                animation: true,
                template: connectorDialog,
                controller: 'connectorDialogController',
                controllerAs: 'cdCtrl',
                backdrop: 'static',
                windowClass: 'connectorDialog',
                resolve: {
                    items: {
                        index: $index,
                        //width: this.supportsData.SupportsModelData[0].SupportModelData.Width_friendly,
                        supportdata: this.supportsData,
                        isPitched: isPitched
                    }
                }
            });

            modalInstance.result.then(() => {}, () => {})
                .finally(() => {});
        }
    }
}
