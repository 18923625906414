import ngAuthSettings from '../../../../environment';
import importFileTemplate from '../../../manage/importFile/importFile.html';
import settingsTemplate from '../../../modals/settings/settings.html';
import accountTemplate from '../../../modals/userProfile/userProfile.html';
import reportDamageTemplate from '../../../modals/reportDamage/reportDamage.html';
import welcomeTourTemplate from '../welcomeTemplate.html';

export default class headerController {
    constructor($scope, $rootScope, $state, $location, $uibModal, $sce, $templateCache, $compile, $window, $timeout,
        authService, datacontext, resourceMessagingService,
        smartNavService, postService, allModelDataService, fileSystemService) {
        'ngInject';

        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.$location = $location;
        this.$uibModal = $uibModal;
        this.$sce = $sce;
        //this.$templateCache = $templateCache;
        this.$compile = $compile;
        this.$window = $window;
        this.$timeout = $timeout;
        this.authService = authService;
        this.ngAuthSettings = ngAuthSettings;
        this.datacontext = datacontext;
        this.resourceMessagingService = resourceMessagingService;
        this.smartNavService = smartNavService;
        this.postService = postService;
        this.allModelDataService = allModelDataService;
        this.fileSystemService = fileSystemService;

        this.message = '';
        this.showFileAnimation = false;
        this.helpTitle = 'mainform_menu_help';
        this.helpContent = this.getText('basic_helpContent') + '<ul><li>' + this.getText('chooseNext') + '</li></ul>';

        this.handleShowNoFileSelectedAnimations = $rootScope.$on('ShowNoFileSelectedAnimations',
            (event, data) => {
                this.showFileAnimation = true;
            }
        );
        this.$scope.$on('$destroy', () => {
            this.handleShowNoFileSelectedAnimations();
        });

        this.activate();
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    isCultureEnglish() {
        var languages = this.resourceMessagingService.getLanguageOptions();
        var i = 0;
        //var retMsg = '';

        for (i; i < languages.length; ++i) {
            if (languages[i].isSelected === true) {
                break;
            }
        }
        if (languages[i].culture === 'fr-CA') {return false;}
        else {return true;}
    }

    termOfUse() {
        var url = '';
        if (this.isCultureEnglish()) {
            url = 'http://eula.weyerhaeusersoftware.com';
        }
        else {
            url = 'http://eula.weyerhaeusersoftware.com/licensefrench';
        }
        var win = window.open(url,'_blank');
        // win.focus();
    }

    isReportDamageToolAvailable() {
        if (this.$state.$current.name.lastIndexOf('application.job') > -1) {
            return true;
        } else {
            return false;
        }
    }

    liveChat() {
        window.open('https://lc.chat/now/1549451/');
    }

    gotAnIdea() {
        window.open('https://ideas.forteweb.com');
    }

    mobileTour() {
        var data = {
            videoURL:  '_cYratqY4jc'
        };
        this.$rootScope.$broadcast('showTourDialog', data);
    }

    getNewFileAnimationClasses() {
        if (this.showNewFileAnimation === true) {
            return 'headerFlashAnimation';
        }
        else {
            return '';
        }
    }

    navigateFileManager() {
        if (this.$state.$current.name !== 'application.manage.files') {
            this.postService.allowChangeEvent().then(() => {
                var currentFileTeamOwner = this.allModelDataService.getTeamOwnerId();
                if (currentFileTeamOwner === undefined || currentFileTeamOwner === null || currentFileTeamOwner === '') {
                    this.$state.go('application.manage.files', {fromState: this.$state.$current.name, openToCurrentFile: true});
                } else {
                    this.$state.go('application.manage.team', {teamId: currentFileTeamOwner, openToCurrentFile: true});
                }
            });
        }
    }

    navigateMaterialsManager() {
        if (this.$state.$current.name !== 'application.materials') {
            this.postService.allowChangeEvent()
            .then(() => {
                this.$state.go('application.materials', {fromState: this.$state.$current.name, currentTeamId: this.$state.params.teamId});
            });
        }
    }

    showUserProfileModal() {
        this.postService.requestSimplePost('api/Account/GetUserProfile','')
        .then((response) => {
            var modalInstance = this.$uibModal.open({
                animation: true,
                template: accountTemplate,
                controller: 'userProfileController',
                controllerAs: 'upCtrl',
                keyboard: false,
                backdrop: 'static',
                windowTopClass :'wideModalWindow',
                size: 'lg',
                resolve: {profileParams: () => { return {'userProfileData': response, 'calledFrom': 'header'};}}
            });

            modalInstance.result.then((result) => {
            }, (error) => {
                if (error === 'save') {
                    this.smartNavService.reloadCurrent();
                }
            });
        })
        .catch((err) => {
        });
    }

    disableFileAnimation() {
        if (this.showFileAnimation === true)
        {
            this.showNewFileAnimation = true;
            this.showFileAnimation = false;
        }
        else {
            this.showNewFileAnimation = false;
        }

    }
    disableNewFileAnimation() {
        this.showNewFileAnimation = false;
    }

    showSettingsModal() {
        var modalInstance = this.$uibModal.open({
            animation: true,
            template: settingsTemplate,
            controller: 'settingsModalController',
            controllerAs: 'settingsCtrl',
            backdrop: 'static',
            windowTopClass :'wideModalWindow',
            size: 'lg'
        });

        modalInstance.result.then((result) => {
        }, (error) => {
            if (error === 'save') {
                this.smartNavService.reloadCurrent();
            }
        });
    }

    showReportDamageModal() {
        var modalInstance = this.$uibModal.open({
            animation: true,
            template: reportDamageTemplate,
            controller: 'reportDamageController',
            controllerAs: 'reportDamageCtrl',
            windowTopClass: 'reportDamageContainer',
            backdrop: 'static',
            size: 'md'
        });

        modalInstance.result.then((result) => {
            //send the email
            var currentFileId = this.allModelDataService.getCurrentFileId();
            this.fileSystemService.sendDamageEmail(currentFileId, result.details, result.damageInfo,
                result.numDamaged, result.customerNotes).then(
                (response) => {
                    //show success toast
                    this.notifier.showSuccessToast(this.getText('success'), this.getText('reportDamageSuccess'));
                }, () => {
                    //show error toast.
                    this.notifier.showErrorToast(this.getText('failed'), this.getText('reportDamageFailure'));
                }
            );
        }, () => {
            // nothing, the modal was cancelled
        })
        .finally(() => {
            //nothing to do here
        });
    }

    showImportFileModal() {
        var modalInstance = this.$uibModal.open({
            animation: true,
            template: importFileTemplate,
            controller: 'importFileModalController',
            controllerAs: 'importFileModalCtrl',
            backdrop: 'static',
            windowTopClass :'wideModalWindow',
            resolve: {
                isFileImportModal: true,
            }
        });
        modalInstance.result.then(() => {
        }, () => {
        });
    }

    showExportFileModal() {
        var modalInstance = this.$uibModal.open({
            animation: true,
            template: importFileTemplate,
            controller: 'importFileModalController',
            controllerAs: 'importFileModalCtrl',
            backdrop: 'static',
            windowTopClass :'wideModalWindow',
            size: 'lg',
            resolve: {
                isFileImportModal: false,
                filesToImport: this.importFiles,
                parentFolder: this.currentlyOpenedFolder
            }
        });
        modalInstance.result.then(() => {
        }, () => {
        });
    }

    /*eslint-disable complexity */
    contextHelp() {
        switch (this.$state.$current.name) {
            case 'application.manage.files':
                var win = window.open('/helpmobile/Content/B_Getting Started/03_file_manager.htm', '_blank');
                win.focus();
                break;
            case 'application.manage.team.recent':
                var win = window.open('/helpmobile/Content/B_Getting Started/03_file_manager.htm', '_blank');
                win.focus();
                break;
            case 'application.manage.teamInfo':
                var win = window.open('/helpmobile/Content/B_Getting Started/03_file_manager.htm', '_blank');
                win.focus();
                break;
            case 'application.manage.team':
                var win = window.open('/helpmobile/Content/B_Getting Started/03_file_manager.htm', '_blank');
                win.focus();
                break;
            case 'application.job.jobSettings':
                var win = window.open('/helpmobile/Content/C_Tabs/01_job_settings.htm', '_blank');
                win.focus();
                break;
            case 'application.job.jobSummary':
                var win = window.open('/helpmobile/Content/C_Tabs/01a_Job_summary.htm', '_blank');
                win.focus();
                break;
            case 'application.job.windLoading':
                var win = window.open('/helpmobile/Content/C_Tabs/01b_wind_loading.htm', '_blank');
                win.focus();
                break;
            case 'application.job.seismicLoading':
                var win = window.open('/helpmobile/Content/C_Tabs/01c_seismic_loading.htm', '_blank');
                win.focus();
                break;
            case 'application.job.level':
                var win = window.open('/helpmobile/Content/C_Tabs/02_level_properties.htm', '_blank');
                win.focus();
                break;
            case 'application.job.memberInfo':
                var win = window.open('/helpmobile/Content/C_Tabs/03_member_info.htm', '_blank');
                win.focus();
                break;
            case 'application.job.roofGeometry':
                var win = window.open('/helpmobile/Content/C_Tabs/04_roof_geometry.htm', '_blank');
                win.focus();
                break;
            case 'application.job.spansAndSupports':
                var win = window.open('/helpmobile/Content/C_Tabs/04a_spans_and_supports.htm', '_blank');
                win.focus();
                break;
            case 'application.job.heightAndSupports':
                var win = window.open('/helpmobile/Content/C_Tabs/04b_height_and_supports.htm', '_blank');
                win.focus();
                break;
            case 'application.job.loadsHorizontalMember':
                var win = window.open('/helpmobile/Content/C_Tabs/05_loads_tab.htm', '_blank');
                win.focus();
                break;
            case 'application.job.verticalLoads':
                var win = window.open('/helpmobile/Content/C_Tabs/05_loads_tab.htm', '_blank');
                win.focus();
                break;
            case 'application.job.holes':
                var win = window.open('/helpmobile/Content/C_Tabs/06_holes_tab.htm', '_blank');
                win.focus();
                break;
            case 'application.job.locationAnalysis':
                var win = window.open('/helpmobile/Content/C_Tabs/07_location_analysis.htm', '_blank');
                win.focus();
                break;
            case 'application.job.floorPerformance':
                var win = window.open('/helpmobile/Content/C_Tabs/08_floor_performance.htm', '_blank');
                win.focus();
                break;
            case 'application.job.productSelection':
                var win = window.open('/helpmobile/Content/C_Tabs/09_product_selection.htm', '_blank');
                win.focus();
                break;
            case 'application.job.taperedEnds':
                var win = window.open('/helpmobile/Content/C_Tabs/07a_tapered_end_cuts.htm', '_blank');
                win.focus();
                break;
            case 'application.job.solutions':
                var win = window.open('/helpmobile/Content/C_Tabs/10_solutions.htm', '_blank');
                win.focus();
                break;
            case 'application.job.report':
                var win = window.open('/helpmobile/Content/C_Tabs/11_report_tab.htm', '_blank');
                win.focus();
                break;
            case 'application.materials':
                var win = window.open('/helpmobile/Content/B_Getting%20Started/04b_materials_manager.htm', '_blank');
                win.focus();
                //use this if we are asked to implement scrolling to specific parts of a help page.
                //win.addEventListener('load', function() {win.scrollTo(0,1015);}, false);//does not work in IE
                //win.attachEvent works in IE but causes problems in all other browsers
                //setTimeout(function() { win.scrollTo(0,1015);}, 1000); uses the header file value from program that makes the help pages
                break;
            default:
                var win = window.open('/helpmobile/Content/Home.htm', '_blank');
                win.focus();
        }
    }

    /*eslint-enable complexity */

    activate() {
        this.showVersionNumber = false;
        if (window.location.href.indexOf('-test') > -1) {
            this.authService.getWebApiVersion()
                .then((response)=> {
                    this.showVersionNumber = true;
                    this.apiVersion = response.data;
                }, (err, status)=> {
                    this.apiVersion = 'ERROR';
                });
        }
    }

    logout() {
        this.authService.logOut();
    };
}
