import ngAuthSettings from '../environmentConfigs/environment.dev';
export default class memberPropertiesService {
    constructor(common, allModelDataService, postService) {
        'ngInject';

        this.$q = common.$q();
        this.allModelDataService = allModelDataService;
        this.postService = postService;
        this.serviceBase = ngAuthSettings.apiServiceBaseUri;
        this.httpRequestQueueCounter = 0;
        this.inputQueuePromise = null;
        this.somethingWaitingOnQueueToFinish = false;
    }

    getMemberPropertiesData() {
        var prData = this.allModelDataService.getPostResponseData();
        return prData;
    }

    modifyLOverIBC(newValue) {
        var data = {
            'value': newValue
        };
        return this._requestPostEvent('ModifyLOverValueIBC', data);
    }

    modifyLOverNBCC(newValue) {
        var data = {
            'value': newValue
        };
        return this._requestPostEvent('ModifyLOverValueNBCC', data);
    }

    getMemberPropertiesMetaData() {
        var data = {};
        return this._requestPostEvent('GetMemberPropertiesMetaData', data);
    }

    modifyDeflectionCriteria(data) {
        return this._requestPostEvent('ModifyDeflectionCriteria', data);
    }

    modifyMemberName(data) {
        return this._requestPostEvent('ModifyMemberName', data);
    }

    modifyMemberNotes(data) {
        return this._requestPostEvent('ModifyMemberNotes', data);
    }

    modifyMemberPitch(data) {
        return this._requestPostEvent('ModifyMemberPitch', data);
    }

    modifyLoadBearingLength(data) {
        return this._requestPostEvent('ModifyLoadBearingLength', data);
    }

    modifyLiveDeflectionRatio(data) {
        return this._requestPostEvent('ModifyLiveDeflectionRatio', data);
    }

    modifyTotalDeflectionRatio(data) {
        return this._requestPostEvent('ModifyTotalDeflectionRatio', data);
    }

    modifyRepetetiveMemberIncrease(data) {
        return this._requestPostEvent('ModifyRepetetiveMemberIncrease', data);
    }

    modifyNegativeSupportRemoval(data) {
        return this._requestPostEvent('ModifyNegativeSupportRemoval', data);
    }

    modifyAllowUpwardDeflections(data) {
        return this._requestPostEvent('ModifyAllowUpwardDeflections', data);
    }

    modifyRemoveUpliftLimit(data) {
        return this._requestPostEvent('ModifyRemoveUpliftLimit', data);
    }

    modifyApplySeismicOverstrength(data) {
        return this._requestPostEvent('ModifyApplySeismicOverstrength', data);
    }

    modifyWebStiffenersAtEndSupports(data) {
        return this._requestPostEvent('ModifyWebStiffenersAtEndSupports', data);
    }

    modifyWebStiffenersAtIntSupports(data) {
        return this._requestPostEvent('ModifyWebStiffenersAtIntSupports', data);
    }

    modifyCantileverReinforced(data) {
        return this._requestPostEvent('ModifyCantileverReinforced', data);
    }

    modifyMultiPlyDisplay(data) {
        return this._requestPostEvent('ModifyMultiPlyDisplay', data);
    }

    moveMember(data) {
        return this._requestPostEvent('MoveMember', data);
    }

    saveMemberPropertiesAsDefaults() {
        var currentPostResponseData = this.allModelDataService.getPostResponseData();
        return this._requestPostEvent('SaveMemberPropertiesAsDefaults', currentPostResponseData);
    }

    _requestPostEvent(LevelPropertiesEvent, LevelPropertiesData) {
        var path = 'api/MemberProperties/';
        return this.postService.requestPostEvent(LevelPropertiesEvent, LevelPropertiesData, path);
    }

    _incrementQueueCounter() {
        this.httpRequestQueueCounter++;
    }

    _decrementQueueCounter() {
        this.httpRequestQueueCounter--;
        if (this.somethingWaitingOnQueueToFinish && this.httpRequestQueueCounter === 0) {
            this.somethingWaitingOnQueueToFinish = false;
            this.inputQueuePromise.resolve();
        }
    }


    getQueueCounter() {
        this.inputQueuePromise = this.$q.defer();
        if (this.httpRequestQueueCounter === 0) {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.somethingWaitingOnQueueToFinish = true;
        }
        return this.inputQueuePromise.promise;
    }
}
