export default class mobileNavMenu {
    constructor() {
        'ngInject';
        /* close nav automatic when click outside */
        var closeNav = () => {
            var opened = $('.navMenu').hasClass('collapse in');
            if (opened === true) {
                $('.navMenu').collapse('hide');
            }
        };

        $('body').scroll(() => {
            closeNav();
        });

        $(document).on('click', () => {
            closeNav();
        });

        $(document).on({
            'touchstart': (event) => {
                if (!$(event.target).closest('.closeOnOutSideClick').length) {
                    var elem = $('#jobsidenav');
                    if (!this.checkForIgnoreClose(elem)) {
                        elem.css('width', '0');
                    }
                }

                if (!$(event.target).closest('.closeOnOutSideClick').length) {
                    var elem = $('#membersidenav');
                    if (!this.checkForIgnoreClose(elem)) {
                        elem.css('width', '0');
                    }
                }

                // Top right Menu
                if (!$(event.target).closest('.navMenu').length) {
                    closeNav();
                }
            },
            'mouseup': (event) => {
                if (!$(event.target).closest('.closeOnOutSideClick').length) {
                    var elem = $('#jobsidenav');
                    if (!this.checkForIgnoreClose(elem)) {
                        elem.css('width', '0');
                    }
                }

                if (!$(event.target).closest('.closeOnOutSideClick').length) {
                    var elem = $('#membersidenav');
                    if (!this.checkForIgnoreClose(elem)) {
                        elem.css('width', '0');
                    }
                }

                // Top right Menu
                if (!$(event.target).closest('.navMenu').length) {
                    closeNav();
                }
            }
        });

        $(document).ready(() => {
            this.scrollToEnd();
        });

        // function stopYoutube()
        // {
        //     $('#youtubeModal iframe').attr('src', $('#youtubeModal iframe').attr('src'));
        // }

        $(window).resize(() => {
            this.scrollToEnd();
        });
    }

    checkForIgnoreClose(elem) {
        if (!elem || !elem.attr('class')) {
            return false;
        }
        var classes = elem.attr('class').split(' ');
        for (var classIndex = 0; classIndex < classes.length; classIndex++) {
            if (classes[classIndex] === 'ignoreClose') {
                return true;
            }
        }

        return false;
    }

    openSideJobNav() {
        $('#jobsidenav').css('width', '80%');
    }

    openMemberJobNav() {
        $('#membersidenav').css('width', '80%');
    }

    scrollToEnd() {
        var element = $('.arrows');
        element.scrollLeft(1000);
    }

}
// /* close nav automatic when click outside */
// var closeNav = function () {
//     var opened = $('.navMenu').hasClass('collapse in');
//     if (opened === true) {
//         $('.navMenu').collapse('hide');
//     }
// }

// $("body").scroll(function () {
//     closeNav();
// });

// $(document).on('click', function () {
//     closeNav();
// });

// function openSideJobNav() {
//     $("#jobsidenav").css('width', '80%');
// }

// function openMemberJobNav() {
//     $("#membersidenav").css('width', '80%');
// }

// $(document).on({
//     "touchstart": function (event) {
//         if (!$(event.target).closest('.closeOnOutSideClick').length) {
//             $("#jobsidenav").css('width', '0');
//         }

//         if (!$(event.target).closest('.closeOnOutSideClick').length) {
//             $("#membersidenav").css('width', '0');
//         }

//         // Top right Menu
//         if (!$(event.target).closest('.navMenu').length) {
//             closeNav();
//         }
//     },
//     "mouseup": function (event) {
//         if (!$(event.target).closest('.closeOnOutSideClick').length) {
//             $("#jobsidenav").css('width', '0');
//         }

//         if (!$(event.target).closest('.closeOnOutSideClick').length) {
//             $("#membersidenav").css('width', '0');
//         }

//         // Top right Menu
//         if (!$(event.target).closest('.navMenu').length) {
//             closeNav();
//         }
//     }
// });

// function scrollToEnd()
// {
//     var element = $(".arrows");
//     element.scrollLeft(1000);
// }
// $(document).ready(function () {
//     scrollToEnd();
// });

// // function stopYoutube()
// // {
// //     $("#youtubeModal iframe").attr("src", $("#youtubeModal iframe").attr("src"));
// // }

// $(window).resize(function(){
//     scrollToEnd();
// });
