export default class supportsService {
    constructor(common, allModelDataService, postService) {
        'ngInject';

        this.$q = common.$q();
        this.allModelDataService = allModelDataService;
        this.postService = postService;

        this.inputQueuePromise;
        this.httpRequestQueueCounter = 0;
        this.somethingWaitingOnQueueToFinish = false;
    }

    getQueueCounter() {
        this.inputQueuePromise = this.$q.defer();
        if (this.httpRequestQueueCounter === 0)
        {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.somethingWaitingOnQueueToFinish = true;
        }
        return this.inputQueuePromise.promise;
    }

    getSupportsData() {
        var data = {};
        return this._requestPostEvent('GetSupportsData', data);
    }

    getSupportData(supportIndex) {
        var data = {
            'dimensionLocation': supportIndex
        };

        return this._requestPostEvent('GetSupportData', data);
    }

    getSupportsMetadata() {
        var data = {};
        return this._requestPostEvent('GetSupportsMetadata', data);
    }

    modifyBearingLength(supportIndex, newLength) {
        var data = {
            'index': supportIndex,
            'newValue': newLength
        };

        return this._requestPostEvent('ModifySupportBearingLength', data);
    }

    modifyLateralConnection(supportIndex, newValue) {
        var data = {
            'index': supportIndex,
            'newValue': newValue
        };
        return this._requestPostEvent('ModifySupportLateralConnection', data);
    }

    modifyJustification(supportIndex, newJustification) {
        var data = {
            'index': supportIndex,
            'newValue': newJustification
        };

        return this._requestPostEvent('ModifySupportJustification', data);
    }

    modifyConfiguration(supportIndex, newConfiguration) {
        var data = {
            'index': supportIndex,
            'newValue': newConfiguration
        };

        return this._requestPostEvent('ModifySupportConfiguration', data);
    }

    modifyType(supportIndex, newType) {
        var data = {
            'index': supportIndex,
            'newValue': newType
        };

        return this._requestPostEvent('ModifySupportType', data);
    }

    modifyMaterial(supportIndex, newMaterial) {
        var data = {
            'index': supportIndex,
            'newValue': newMaterial
        };

        return this._requestPostEvent('ModifySupportMaterial', data);
    }

    modifyAccessory(supportIndex, newAccessory) {
        var data = {
            'index': supportIndex,
            'newValue': newAccessory
        };

        return this._requestPostEvent('ModifySupportAccessory', data);
    }

    modifyDimensionLocation(newDimensionLocation) {
        var data = {
            'dimensionLocation': newDimensionLocation
        };

        return this._requestPostEvent('ModifyDimensionLocation', data);
    }

    bracingTypeModified(edgeIndex, newBracingType) {
        var data = {
            'edgeIndex': edgeIndex,
            'newValue': newBracingType
        };

        return this._requestPostEvent('ModifyEdgeBracingType', data);
    }

    bracingLengthModified(edgeIndex, newMaxUnbracedLength) {
        var data = {
            'edgeIndex': edgeIndex,
            'newValue': newMaxUnbracedLength
        };

        return this._requestPostEvent('ModifyEdgeMaxUnbracedLength', data);
    }

    bracingCommentModified(edgeIndex, newBracingComment) {
        var data = {
            'edgeIndex': edgeIndex,
            'newValue': newBracingComment
        };

        return this._requestPostEvent('ModifyEdgeBracingComments', data);
    }

    saveSupportsAsDefaults() {
        var currentPostResponseData = this.allModelDataService.getPostResponseData();
        return this._requestPostEvent('SaveSpansAndSupportsAsDefaults', currentPostResponseData);
    }

    _requestPostEvent(supportEvent, supportData) {
        var path = 'api/Supports/';
        return this.postService.requestPostEvent(supportEvent, supportData, path);
    }

    _incrementQueueCounter() {
        this.httpRequestQueueCounter++;
    }
    _decrementQueueCounter() {
        this.httpRequestQueueCounter--;
        if (this.somethingWaitingOnQueueToFinish && this.httpRequestQueueCounter === 0) {
            this.inputQueuePromise.resolve();
        }
    }
}
// (function () {
//     'use strict';
//     var app = angular.module('app');
//     app.factory('supportsService', ['$http', 'ngAuthSettings', 'common', 'allModelDataService', 'postService',
//         function ($http, ngAuthSettings, common, allModelDataService, postService) {
//             var $q = common.$q;
//             var serviceBase = ngAuthSettings.apiServiceBaseUri;
//             var inputQueuePromise;
//             var httpRequestQueueCounter = 0;
//             var somethingWaitingOnQueueToFinish = false;

//             var service = {
//                 getQueueCounter: getQueueCounter,
//                 getSupportsData: getSupportsData,
//                 getSupportData: getSupportData,
//                 getSupportsMetadata: getSupportsMetadata,
//                 modifyDimensionLocation: modifyDimensionLocation,
//                 modifyBearingLength: modifyBearingLength,
//                 modifyJustification: modifyJustification,
//                 modifyConfiguration: modifyConfiguration,
//                 modifyType: modifyType,
//                 modifyMaterial: modifyMaterial,
//                 modifyAccessory: modifyAccessory,
//                 modifyLateralConnection: modifyLateralConnection,
//                 saveSupportsAsDefaults: saveSupportsAsDefaults
//             };

//             function getQueueCounter() {
//                 inputQueuePromise = $q.defer();
//                 if (httpRequestQueueCounter === 0)
//                 {
//                     inputQueuePromise.resolve();
//                     return inputQueuePromise.promise;
//                 }
//                 else {
//                     somethingWaitingOnQueueToFinish = true;
//                 }
//                 return inputQueuePromise.promise;
//             }

//             function getSupportsData() {
//                 var data = {};
//                 return _requestPostEvent('GetSupportsData', data);
//             }

//             function getSupportData(supportIndex) {
//                 var data = {
//                     'dimensionLocation': supportIndex
//                 };

//                 return _requestPostEvent('GetSupportData', data);
//             }

//             function modifyLateralConnection(supportIndex, newValue) {
//                 var data = {
//                     'index': supportIndex,
//                     'newValue': newValue
//                 };
//                 return _requestPostEvent('ModifySupportLateralConnection', data);
//             }

//             function getSupportsMetadata() {
//                 var data = {};
//                 return _requestPostEvent('GetSupportsMetadata', data);
//             }

//             function modifyBearingLength(supportIndex, newLength) {
//                 var data = {
//                     'index': supportIndex,
//                     'newValue': newLength
//                 };

//                 return _requestPostEvent('ModifySupportBearingLength', data);
//             }

//             function modifyJustification(supportIndex, newJustification) {
//                 var data = {
//                     'index': supportIndex,
//                     'newValue': newJustification
//                 };

//                 return _requestPostEvent('ModifySupportJustification', data);
//             }

//             function modifyConfiguration(supportIndex, newConfiguration) {
//                 var data = {
//                     'index': supportIndex,
//                     'newValue': newConfiguration
//                 };

//                 return _requestPostEvent('ModifySupportConfiguration', data);
//             }

//             function modifyType(supportIndex, newType) {
//                 var data = {
//                     'index': supportIndex,
//                     'newValue': newType
//                 };

//                 return _requestPostEvent('ModifySupportType', data);
//             }

//             function modifyMaterial(supportIndex, newMaterial) {
//                 var data = {
//                     'index': supportIndex,
//                     'newValue': newMaterial
//                 };

//                 return _requestPostEvent('ModifySupportMaterial', data);
//             }

//             function modifyAccessory(supportIndex, newAccessory) {
//                 var data = {
//                     'index': supportIndex,
//                     'newValue': newAccessory
//                 };

//                 return _requestPostEvent('ModifySupportAccessory', data);
//             }

//             function modifyDimensionLocation(newDimensionLocation) {
//                 var data = {
//                     'dimensionLocation': newDimensionLocation
//                 };

//                 return _requestPostEvent('ModifyDimensionLocation', data);
//             }

//             function saveSupportsAsDefaults() {
//                 var currentPostResponseData = allModelDataService.getPostResponseData();
//                 return _requestPostEvent('SaveSpansAndSupportsAsDefaults', currentPostResponseData);
//             }

//             function _requestPostEvent(supportEvent, supportData) {
//                 var path = 'api/Supports/';
//                 return postService.requestPostEvent(supportEvent, supportData, path);
//             }

//             function _incrementQueueCounter() {
//                 httpRequestQueueCounter++;
//             }
//             function _decrementQueueCounter() {
//                 httpRequestQueueCounter--;
//                 if (somethingWaitingOnQueueToFinish && httpRequestQueueCounter === 0) {
//                     inputQueuePromise.resolve();
//                 }
//             }

//             return service;
//         }]);
// }());
