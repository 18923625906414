import whatsnewtemplate from './welcomeTemplate.html';
import warningdialogtemplate from './warning-dialog.html';
import timedoutdialogtemplate from './timedout-dialog.html';
import modifycommenttemplate from './modifyCommentModal.html';
import userprofiletemplate from '../../modals/userProfile/userProfile.html';

import moment from 'moment';

export default class shellController {
    //eslint-disable-next-line max-params
    constructor(
        $scope,
        $rootScope,
        $location,
        $state,
        $uibModal,
        $timeout,
        $interval,
        common,
        config,
        datacontext,
        authService,
        projectManagementService,
        spinner,
        Idle,
        Title,
        allModelDataService,
        forteCookieService,
        fileSystemService,
        $templateCache,
        smartNavService,
        postService,
        resourceMessagingService,
        notifier,
        signalrService
    ) {
        'ngInject';

        this.controllerId = 'shellController';
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$location = $location;
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.$timeout = $timeout;
        this.$interval = $interval;
        this.common = common;
        this.config = config;
        this.datacontext = datacontext;
        this.authService = authService;
        this.projectManagementService = projectManagementService;
        this.spinner = spinner;
        this.Idle = Idle;
        this.Title = Title;
        //this.drawingService = drawingService;
        this.allModelDataService = allModelDataService;
        this.forteCookieService = forteCookieService;
        this.fileSystemService = fileSystemService;
        this.$templateCache = $templateCache;
        this.smartNavService = smartNavService;
        this.postService = postService;
        this.resourceMessagingService = resourceMessagingService;
        this.notifier = notifier;
        this.signalRService = signalrService;
        this.$q = common.$q();
        this.videoTourVersion = 1.0;

        let graphicCookieID = 'graphicCookie';
        let lockUpdatePromise;
        let authLogoutForMaintenancePromise;

        this.$scope.started = false;

        let events = this.config.events;

        this.graphicId = 'main';
        this.showSplash = false;
        this.currentProject = null;
        this.hideJobTree = false;
        this.hideTabNav = false;
        this.showGraphicToggle = false;
        this.isGraphicVisible = false;
        this.applyAnimateOutGraphicClass = false;
        this.showInitialTour = false;
        this.isAuthenticated = this.authService.authentication;

        this.handleShowWaitingSplash = this.$scope.$on(
            'showWaitingSplash',
            (event, data) => {
                $scope.showWaitingSplash = data;
            }
        );
        this.handleSpinnerToggle = this.$rootScope.$on(
            'spinner.toggle',
            (event, data) => {
                this.splashMessage = data.message;
                this.toggleSplash(data.show);
            }
        );

        // this.handleJustLoggedInGetProject = this.$rootScope.$on('justLoggedInGetProject', (data) => {
        //     this._getProject();
        // });

        // this.handleJustLoggedInOpenSelectedFile = this.$rootScope.$on('openSelectedFile', (event, data) => {
        //     this._openFile(data.fileId, data.fromLink);
        // });

        this.handleLaunchNecessaryPopups = this.$rootScope.$on(
            'launchNecessaryModalPopups',
            (event, data) => {
                this._launchNecessaryPopups(data);
            }
        );
        this.handleShowTourDialog = this.$rootScope.$on(
            'showTourDialog',
            (event, data) => {
                this.startVideoDialog(
                    this.getText('forteWebTour'),
                    false,
                    data.videoURL
                );
            }
        );

        this.handleIdleStart = this.$scope.$on('IdleStart', () => {
            this.Title.store(false);
            this.closeModals();

            this.$scope.warning = this.$uibModal.open({
                template: warningdialogtemplate,
                controller: 'timeoutWarningController',
                controllerAs: 'twCtrl',
                windowClass: 'modal-danger',
                backdrop: 'static',
            });
        });

        this.handleIdleEnd = this.$scope.$on('IdleEnd', () => {
            this.closeModals();
            Title.restore();
        });

        this.handleIdleTimeout = $scope.$on('IdleTimeout', () => {
            this._forceSaveAndLogout();
        });

        this.activate();
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    toggleSplash(value) {
        this.showSplash = value;
    }

    activate() {
        this.signalRService.connectToSignalR();
        this._launchNecessaryPopups();
        this.showWaitingSplash = false;
        this.showGraphicToggle = false;
        this.applyAnimateOutGraphicClass = false;

        this.setupAutoLogoutForMaintenance();

        this.startIdleTimer();
        this.startUpdateLockTimer();
        //this.common.activateController([], this.controllerId);

        // var state = this.$state.$current.name;
        // if (state === 'application.manage.files') {
        //     this._getProject(); // added this in activate to get back the user profile if user refreshes the page after login.
        // }

        this.$scope.stop = () => {
            this.closeModals();
            this.Idle.unwatch();
            this.$scope.started = false;
        };

        this.$scope.$on('$destroy', () => {
            this.handleIdleTimeout();
            this.handleIdleEnd();
            this.handleIdleStart();
            // this.handleJustLoggedInGetProject();
            // this.handleJustLoggedInOpenSelectedFile();
            this.handleLaunchNecessaryPopups();
            this.handleShowTourDialog();
            this.handleShowWaitingSplash();
            this.handleSpinnerToggle();
            this.$interval.cancel(this.lockUpdatePromise);
            this.$interval.cancel(this.autoLogoutForMaintenancePromise);
        });
        //this code handles if the user reloads the page before finishing the user profile data.
        this._checkUserProfileCompleteStatusOnReload();
    }

    _launchNecessaryPopups() {
        if (this.$state.current.name !== 'application.loading') {
            let isProfileIncomplete =
                this.allModelDataService.getUserProfileIsIncomplete();
            if (isProfileIncomplete !== null) {
                this.launchUserProfileWhenIncomplete(isProfileIncomplete).then(
                    () => {
                        this.$timeout(
                            () => {
                                //check if tour needs to be shown.
                                // if (this.allModelDataService.getLaunchWhatsNewTour() === true) {
                                if (
                                    this.videoTourVersion >
                                    this.forteCookieService.getVideoTourVersion()
                                ) {
                                    this.startVideoDialog(
                                        this.getText('basic_welcomeTitle'),
                                        false,
                                        '_cYratqY4jc'
                                    ); //mobile version tour
                                    this.forteCookieService.writeVideoTourVersion(
                                        this.videoTourVersion
                                    );
                                }
                                // }
                            },
                            0,
                            false
                        );
                    }
                );
            }
        }
    }
    _checkUserProfileCompleteStatusOnReload() {
        let isProfileIncomplete =
            this.allModelDataService.getUserProfileIsIncomplete();
        this.launchUserProfileWhenIncomplete(isProfileIncomplete);
    }

    toggleSpinner(value) {
        this.isBusy = value;
    }

    _openFile(id, fromLink) {
        //console.log('shellJS-_openFile-spinnerShowIfNotShownAlready');
        this.spinner.spinnerShowIfNotShownAlready(
            this.getText('loadingProject')
        );
        this.projectManagementService.getProject(id).then((response) => {
            //console.log('shellJS-_openFile-spinnerHide');
            this.spinner.spinnerHide();
            //if the api returned nothing, then there was a permissions error opening that file
            //redirect to file management
            if (response.postResponseData === null) {
                if (fromLink === true) {
                    this.notifier.fileManagementError(
                        this.getText('error'),
                        this.getText('fileUnavailableMessage')
                    );
                }
                this.forteCookieService.writeCurrentlyOpenedFileId(null);
                this.$state.go('application.manage.files');
            } else {
                this.forteCookieService.writeCurrentlyOpenedFileId(id);
                this.$state.go('application.job.jobSummary');
                this.$timeout(
                    () => {
                        this.$rootScope.$broadcast('projectReady', {
                            forgetState: false,
                        });
                    },
                    0,
                    false
                );
                //this.allModelDataService.setTeamOwnerId(response.data.teamOwner);
            }
        });
    }

    handlePermissionRejection(titleText, response) {
        if (response.status === 403) {
            this.$state.go('account/login');
            console.log('Unauthorized access');
        }
    }
    _getProject() {
        //console.log('shellJS-_getProject-spinnerShow');
        this.spinner.spinnerShow(this.getText('authenticating'));
        this.projectManagementService
            .getProject()
            .then((response) => {
                let launchWhatsNewTour = response.data.launchWhatsNewTour;
                this.allModelDataService.setLaunchWhatsNewTour(
                    launchWhatsNewTour
                );
                //console.log('shellJS-_getProject-spinnerHide');
                this.spinner.spinnerHide();

                this.launchUserProfileWhenIncomplete(
                    response.data.isUserProfileDataIncomplete
                ).then(() => {
                    if (response.data.postResponseData === null) {
                        this.$state.go('application.manage.files');
                    } else {
                        this.$state.go('application.job.jobSummary');
                    }
                    this.$timeout(
                        () => {
                            if (this.showInitialTour === true) {
                                this.startVideoDialog(
                                    this.getText('basic_welcomeTitle'),
                                    false,
                                    'imLK4EtcNJg'
                                ); //new user video
                            } else if (
                                this.allModelDataService.getLaunchWhatsNewTour() ===
                                true
                            ) {
                                this.startVideoDialog(
                                    this.getText('whatsNewTitle'),
                                    true,
                                    'gn_XfeRA7io'
                                );
                            }
                        },
                        0,
                        false
                    );
                });
            })
            .finally(() => {
                // update lastLoggedInVersion
                if (this.allModelDataService.getLaunchWhatsNewTour() === true) {
                    this.postService
                        .requestSimplePost(
                            'api/Account/UpdateLastLoggedInVersion'
                        )
                        .then(() => {});
                }
            });
    }

    launchUserProfileWhenIncomplete(isUserProfileDataIncomplete) {
        var deferred = this.common.$q().defer();
        if (isUserProfileDataIncomplete === true) {
            this.showUserProfileModal().then(() => {
                deferred.resolve();
            });
        } else {
            deferred.resolve();
        }

        return deferred.promise;
    }

    startVideoDialog(title, showSubtext, videoURL) {
        let obj = {
            title: title,
            showSubtext: showSubtext,
            videoURL: videoURL,
        };
        var modalInstance = this.$uibModal.open({
            animation: true,
            template: whatsnewtemplate,
            controller: 'welcomeDialogController',
            controllerAs: 'wdCtrl',
            backdrop: 'static',
            size: 'md',
            resolve: {
                tourData: obj,
            },
        });

        modalInstance.result.then(
            (result) => {},
            (error) => {}
        );
    }

    // functions for Idle Timeout Mangement

    closeModals() {
        if (this.$scope.warning) {
            this.$scope.warning.close();
            this.$scope.warning = null;
        }

        if (this.$scope.timedout) {
            this.$scope.timedout.close();
            this.$scope.timedout = null;
        }
    }

    setupAutoLogoutForMaintenance() {
        this.nextMaintenanceData;
        this.authService
            .getNextMaintenanceDate()
            .then(
                (response) => {
                    this.nextMaintenanceData = moment.utc(response.data);

                    this.$interval.cancel(this.autoLogoutForMaintenancePromise);
                    let delay = 60 * 1 * 1000; //every minute
                    this.autoLogoutForMaintenancePromise = this.$interval(
                        () => this._checkForMaintenanceLogoug(),
                        delay,
                        0,
                        false
                    );
                },
                (err, status) => {
                    this.nextMaintenanceData = moment.utc('1900-01-01 00:00');
                }
            )
            .finally(() => {});
    }

    _forceSaveAndLogout() {
        const openedFileId = this.allModelDataService.getCurrentFileId();
        this.closeModals();
        this.fileSystemService.saveCurrentFile();
        if (openedFileId) {
            this.signalRService.notifyFileClosed().then(() => {
                this.signalRService.notifyClientIdledOut().then(() => {
                    this.signalRService.disconnect();
                    this.authService.logOut();
                    this.$state.go('account.login');
                });
            });
        } else {
            this.signalRService.notifyClientIdledOut().then(() => {
                this.signalRService.disconnect();
                this.authService.logOut();
                this.$state.go('account.login');
            });
        }
    }

    startIdleTimer() {
        this.closeModals();
        this.Idle.watch();
        this.$scope.started = true;
    }

    startUpdateLockTimer() {
        this.$interval.cancel(this.lockUpdatePromise);
        let delay = 60 * 5 * 1000; //every 5 minutes
        this.lockUpdatePromise = this.$interval(
            () => this._updateLock(),
            delay,
            0,
            false
        );
    }

    _checkForMaintenanceLogoug() {
        let now = moment.utc();
        let minuteDifference = this.nextMaintenanceData.diff(now, 'minutes');
        //if the time is less than 5 minutes away. log them out
        //if the time is less than 10 minutes away, give them a 5 minute warning
        //if the time is less than 15 minutes away, give them a 10 minute warning.
        if (minuteDifference >= 0) {
            if (minuteDifference <= 10) {
                // prompt them and log them out
                this._autoLogout().then((response) => {
                    //log them out
                    this._forceSaveAndLogout();
                    this.$interval.cancel(this.autoLogoutForMaintenancePromise);
                });
            }
            if (minuteDifference === 15) {
                //warn them that there is 5 minutes until they are auto logged out
                var deferred = this.$q.defer();
                this.notifier.showAutoLogoutToast(
                    deferred,
                    'Maintenance Alert',
                    'You will be logged out in 5 minutes.'
                );
                return;
            }
            if (minuteDifference === 20) {
                //warn them that there is 10 minutes until they are auto logged out
                this.notifier.showAutoLogoutToast(
                    deferred,
                    'Maintenance Alert',
                    'You will be logged out in 10 minutes.'
                );
                return;
            }
        }
        return;
    }

    _autoLogout() {
        let deferred = this.$q.defer();
        this.notifier.showAutoLogoutToast(
            deferred,
            'Maintenance Alert',
            'Your work will be saved, and you will now be logged out.”'
        );
        return deferred.promise;
    }

    _updateLock() {
        let currentFileId = this.allModelDataService.getCurrentFileId();
        var data = {
            forteFileTreeItemId: currentFileId,
            receivedDataDate: null,
        };
        this.postService.requestSimplePost(
            'api/keepalive/UpdateFileLock',
            data
        );
    }

    // end functions for Idle Timeout Mangement

    showUserProfileModal() {
        let deferred = this.$q.defer();

        this.postService
            .requestSimplePost('api/Account/GetUserProfile', '')
            .then((response) => {
                this.displayProfileModal(response).then((result) => {
                    deferred.resolve();
                });
            })
            .catch((err) => {});

        return deferred.promise;
    }

    displayProfileModal(profileData) {
        var deferred = this.$q.defer();
        var modalInstance = this.$uibModal.open({
            animation: true,
            template: userprofiletemplate,
            controller: 'userProfileController',
            controllerAs: 'upCtrl',
            keyboard: false,
            backdrop: 'static',
            windowTopClass: 'wideModalWindow',
            size: 'lg',
            resolve: {
                profileParams: () => {
                    return {
                        userProfileData: profileData,
                        calledFrom: 'login',
                    };
                },
            },
        });

        modalInstance.result.then(
            (result) => {
                this.showInitialTour = result;
                deferred.resolve();
            },
            (error) => {
                if (error === true) {
                    this.showInitialTour = true;
                } else {
                    this.showInitialTour = false;
                }

                if (error === 'save') {
                    this.smartNavService.reloadCurrent();
                }
                deferred.resolve();
            }
        );

        return deferred.promise;
    }
}
