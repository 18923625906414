export default class memberInfoController {
    constructor(
        $scope,
        $rootScope,
        $location,
        $timeout,
        $anchorScroll,
        memberPropertiesService,
        resourceMessagingService,
        smartNavService,
        tabNavigationService,
        allModelDataService,
        notifier
    ) {
        'ngInject';

        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$location = $location;
        this.$timeout = $timeout;
        this.$anchorScroll = $anchorScroll;
        this.memberPropertiesService = memberPropertiesService;
        this.resourceMessagingService = resourceMessagingService;
        this.smartNavService = smartNavService;
        this.tabNavigationService = tabNavigationService;
        this.allModelDataService = allModelDataService;
        this.notifier = notifier;

        this.jobTreeData = null;
        this.levelDic = [];
        this.metaData = null;
        this.deflectionCriteria = null;
        this.LiveDeflectionRatio = 0;

        this.isMemberVertical = false;
        this.isResidential = false;

        this.connectionsOptions = [{ Key: 0, Value: 'none' }];
        this.connectionSelected = 0;
        this.memberNotes = null;
        this.missingMultiPlyTitle = 'MultiPlyAttachments';
        this.missingMultiPlyContent = 'memberInfo_connectionsContent';
        this.missingMultiPlyAction = 'chooseNext';

        this.multiPlyTitle = 'MultiPlyAttachments';
        this.multiPlyContent = 'memberInfo_connectionsContent';
        this.multiPlyAction = 'chooseNext';
        this.showLoadBearingLengthInput = false;
        this.allowUpwardDeflectionsDisabled = true;

        this.activate();
    }

    activate() {
        //show the graphic toggle
        console.log('memberinfoactivate');
        this.$scope.$emit('showGraphicToggle', true);
        this.getMemberPropertiesData();
        this.getMemberPropertiesMetaData();
        this.isResidential = this.allModelDataService.isResidential();
        this.isMemberVertical = this.allModelDataService.getIsMemberVertical();
    }

    isIbc() {
        return this.allModelDataService.isBuildingCodeIBC();
    }

    changedDeflectionWallCriteria(ibc, newValue) {
        if (ibc) {
            this.memberPropertiesService
                .modifyLOverIBC(newValue)
                .then((response) => {
                    this.getMemberPropertiesData;
                });
        } else {
            this.memberPropertiesService
                .modifyLOverNBCC(newValue)
                .then((response) => {
                    this.getMemberPropertiesData;
                });
        }
    }

    preventInputsToOnlyNumbers(keyPressEvent) {
        if (keyPressEvent.which === 8 || keyPressEvent.which === 0) {
            // backspace, null
            return;
        }
        if (keyPressEvent.which < 48 || keyPressEvent.which > 57) {
            // numbers 0-9 OK
            keyPressEvent.preventDefault();
        }
    }
    preventInputsInMemberPitch(keyPressEvent) {
        if (keyPressEvent.which === 8 || keyPressEvent.which === 0) {
            // backspace, null
            return;
        }
        if (
            (keyPressEvent.which < 48 || keyPressEvent.which > 57) &&
            keyPressEvent.which !== 46
        ) {
            // numbers 0-9 and decimal point '.' OK
            keyPressEvent.preventDefault();
        }
    }

    setStepDirection(direction) {
        this.stepDirection = direction;
    }

    selectAllTextOnFocus($event) {
        $event.target.select();
    }

    scrollToElement(elementId) {
        this.$location.hash(elementId);
        this.$anchorScroll();
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    changeMemberToLevel() {
        //pass level id instead of leve name.
        //you can bind this to the drop down in the same fashion as the other drop downs in the app.
        var data = {
            value: this.levelName,
        };

        this.memberPropertiesService
            .moveMember(data)
            .then((serviceResponse) => {
                this.getMemberPropertiesData();
                //broadcast to a new event and pass the id of the level you are moving to.
                //then follow login of selectNewlyAddedChildNode to select the proper element in the tree
                //call refresh tree after you set up the proper state for selectNewlyAddedChildNode.
                this.$rootScope.$broadcast('moveMember', data);
            });
    }

    getMemberPropertiesData() {
        var prData = this.memberPropertiesService.getMemberPropertiesData();
        this.memberManagerData = prData.ApplicationData.MemberManagerData;
        this.multiPlyDisplayType = 0;
        if (prData.MemberData.MultiPlyData) {
            this.multiPlyDisplayType =
                prData.MemberData.MultiPlyData.DisplayType;
        }
        var selectedContainerID = this.memberManagerData.SelectedContainerID;
        var selectedMemberID = this.memberManagerData.SelectedMemberID;
        this.memberData =
            this.memberManagerData.MemberContainers[
                selectedContainerID
            ].Members[selectedMemberID];
        this.levelName =
            this.memberManagerData.MemberContainers[
                selectedContainerID
            ].ContainerID;
        if (this.memberData.LiveDeflectionRatio === 0) {
            this.memberData.LiveDeflectionRatio = this.LiveDeflectionRatio;
        }
        if (this.memberData.AllowNegativeSupportRemoval === true) {
            this.allowUpwardDeflectionsDisabled = false;
        } else {
            this.allowUpwardDeflectionsDisabled = true;
        }

        if (this.metaData !== null) {
            for (
                var i = 0;
                i < this.metaData.deflectionCriteriaList.length;
                i++
            ) {
                if (
                    this.metaData.deflectionCriteriaList[i].Key ===
                    this.memberData.DeflectionCriteriaSetting
                ) {
                    this.deflectionOption =
                        this.metaData.deflectionCriteriaList[i].Value;
                }
            }
        }

        this.levelDic = [];
        for (
            var i = 0;
            i < this.memberManagerData.MemberContainers.length;
            i++
        ) {
            var name =
                this.memberManagerData.MemberContainers[i].LevelSettingsData
                    .ContainerName;
            this.levelDic.push({
                key: i,
                value: name,
            });
        }
    }

    getMemberPropertiesMetaData() {
        this.memberPropertiesService
            .getMemberPropertiesMetaData()
            .then((response) => {
                this.metaData = response;
                if (this.metaData !== null) {
                    for (
                        var i = 0;
                        i < this.metaData.deflectionCriteriaList.length;
                        i++
                    ) {
                        if (
                            this.metaData.deflectionCriteriaList[i].Key ===
                            this.memberData.DeflectionCriteriaSetting
                        ) {
                            this.deflectionOption =
                                this.metaData.deflectionCriteriaList[i].Value;
                        }
                    }

                    if (this.memberData.StructuralSystem === 2 || this.memberData.StructuralSystem === 4) {
                        this.metaData.deflectionCriteriaList.splice(0, 1);
                    }
                    if (this.metaData.multiPlyDisplayTypeList !== null) {
                        if (
                            this.multiPlyDisplayType >=
                            this.metaData.multiPlyDisplayTypeList.length
                        ) {
                            this.multiPlyDisplayType = 1;
                        }
                    }
                    this.setShowBearingLength();
                }
            });
    }

    setShowBearingLength() {
        if (this.isIbc()) {
            this.showLoadBearingLengthInput = false;
        } else {
            // NBCC
            switch (this.memberData.MemberType) {
                case 30: //stud
                case 8:  //column
                case 40: //freeStandingPost
                case 32: //windSill
                    this.showLoadBearingLengthInput = false;
                    break;
                default:
                    this.showLoadBearingLengthInput = true;
                    this.modifyLoadBearingLength();
                    break;
            }
        }
    }

    changedDeflectionCriteria() {
        var prData = this.memberPropertiesService.getMemberPropertiesData();
        var selectedContainerID = this.memberManagerData.SelectedContainerID;
        var selectedMemberID = this.memberManagerData.SelectedMemberID;
        this.memberData =
            this.memberManagerData.MemberContainers[
                selectedContainerID
            ].Members[selectedMemberID];
        this.LiveDeflectionRatio = this.memberData.LiveDeflectionRatio;
        var data = {
            value: this.memberData.DeflectionCriteriaSetting,
        };
        this.memberPropertiesService
            .modifyDeflectionCriteria(data)
            .then((serviceResponse) => {
                this.getMemberPropertiesData();
            });
    }

    modifyMemberNotes() {
        var data = {
            value: this.memberData.Notes,
        };

        this.memberPropertiesService
            .modifyMemberNotes(data)
            .then((serviceResponse) => {
                this.getMemberPropertiesData();
            });
    }

    modifyMemberPitch() {
        var data = {
            value: this.memberData.MemberPitch,
        };
        this.memberPropertiesService
            .modifyMemberPitch(data)
            .then((serviceResponse) => {
                this.tabNavigationService.getVisibleTabsForMember();
                this.tabNavigationService.tabSwitch(
                    'application.job.memberInfo',
                    0
                );
                this.$scope.$parent.$broadcast('updateVisibleTabs', 'member');
                this.getMemberPropertiesData();
            });
    }

    modifyMaterialQuantity() {
        if (this.memberData.MaterialQuantity < 1) {this.memberData.MaterialQuantity = 1;}
        var prData = this.allModelDataService.getPostResponseData();
        var selectedContainerID = this.memberManagerData.SelectedContainerID;
        var selectedMemberID = this.memberManagerData.SelectedMemberID;
        prData.ApplicationData.MemberManagerData.MemberContainers[
            selectedContainerID].Members[selectedMemberID].MaterialQuantity = this.memberData.MaterialQuantity;
        this.allModelDataService.setPostResponseData(prData);
    }

    modifyLoadBearingLength() {
        var data = {
            value: this.memberData.LoadBearingLengthFriendly,
        };
        this.memberPropertiesService
            .modifyLoadBearingLength(data)
            .then((serviceResponse) => {
                this.getMemberPropertiesData();
            });
    }

    modifyMemberName() {
        if (this.memberData.MemberName.length !== 0) {
            var data = {
                memberId: this.memberData.MemberID,
                containerId: this.memberData.ContainerID,
                newName: this.memberData.MemberName,
            };

            this.memberPropertiesService
                .modifyMemberName(data)
                .then((serviceResponse) => {
                    this.getMemberPropertiesData();
                    var containerId = this.memberData.ContainerID;
                    var memberId = this.memberData.MemberID;
                    var nodeID = 'c_' + containerId + 'm_' + memberId;
                    this.$rootScope.$broadcast(
                        'updateNodeName',
                        this.memberData.MemberName,
                        nodeID
                    );
                });
        } else {
            this.smartNavService.reloadCurrent();
        }
    }

    modifyLiveDeflectionRatio() {
        var data = {
            value: this.memberData.LiveDeflectionRatio
        };

        this.memberPropertiesService
            .modifyLiveDeflectionRatio(data)
            .then((serviceResponse) => {
                this.getMemberPropertiesData();
            });
    }

    modifyTotalDeflectionRatio() {
        var data = {
            value: this.memberData.TotalDeflectionRatio
        };

        this.memberPropertiesService
            .modifyTotalDeflectionRatio(data)
            .then((serviceResponse) => {
                this.getMemberPropertiesData();
            });
    }

    modifyRepetetiveMemberIncrease() {
        var data = {
            value: this.memberData.RepetitiveMemberIncrease,
        };

        this.memberPropertiesService
            .modifyRepetetiveMemberIncrease(data)
            .then((serviceResponse) => {
                this.getMemberPropertiesData();
            });
    }

    modifyNegativeSupportRemoval() {
        var data = {
            value: this.memberData.AllowNegativeSupportRemoval,
        };

        this.memberPropertiesService
            .modifyNegativeSupportRemoval(data)
            .then((serviceResponse) => {
                this.getMemberPropertiesData();
            });
    }

    modifyAllowUpwardDeflections() {
        var data = {
            value: this.memberData.AllowUpwardDeflections,
        };

        this.memberPropertiesService
            .modifyAllowUpwardDeflections(data)
            .then((serviceResponse) => {
                this.getMemberPropertiesData();
            });
    }

    modifyRemoveUpliftLimit() {
        var data = {
            value: this.memberData.RemoveUpliftLimit,
        };

        this.memberPropertiesService
            .modifyRemoveUpliftLimit(data)
            .then((serviceResponse) => {
                this.getMemberPropertiesData();
            });
    }

    modifyApplySeismicOverstrength() {
        var prData = this.allModelDataService.getPostResponseData();
        var currentContainerId = prData.ApplicationData.MemberManagerData.SelectedContainerID;
        var currentMemberId = prData.ApplicationData.MemberManagerData.SelectedMemberID;
        var memberSDO = prData.ApplicationData.MemberManagerData.MemberContainers[currentContainerId].Members[currentMemberId];
        var linkedReactions = prData.ApplicationData.MemberManagerData.MemberTagToLinkedReactionDictionary;
        if (linkedReactions[memberSDO.IDTag].ToReactions.length > 0 || linkedReactions[memberSDO.IDTag].FromReactions.length > 0) {
            this.memberData.ApplySeismicOverstrength = false;
            const message = this.getText('seismicOverstrengthError');
            this.notifier.fileManagementWarning(
          this.getText('cantModifyItem'),
          message
        );
        } else  {
            var data = {
                value: this.memberData.ApplySeismicOverstrength,
            };
            this.memberPropertiesService
                .modifyApplySeismicOverstrength(data)
                .then((serviceResponse) => {
                    this.getMemberPropertiesData();
                });
        }
    }

    modifyWebStiffenersAtEndSupports() {
        var data = {
            value: this.memberData.AllowWebStiffenersEndSupports,
        };

        this.memberPropertiesService
            .modifyWebStiffenersAtEndSupports(data)
            .then((serviceResponse) => {
                this.getMemberPropertiesData();
            });
    }

    modifyWebStiffenersAtIntSupports() {
        var data = {
            value: this.memberData.AllowWebStiffenersIntermediateSupports,
        };

        this.memberPropertiesService
            .modifyWebStiffenersAtIntSupports(data)
            .then((serviceResponse) => {
                this.getMemberPropertiesData();
            });
    }

    modifyCantileverReinforced() {
        var data = {
            value: this.memberData.AllowReinforcedCantilevers,
        };

        this.memberPropertiesService
            .modifyCantileverReinforced(data)
            .then((serviceResponse) => {
                this.getMemberPropertiesData();
            });
    }

    modifyMultiPlyDisplay(modelvalue) {
        var data = {
            value: modelvalue, //this.multiPlyDisplayType
        };

        this.memberPropertiesService
            .modifyMultiPlyDisplay(data)
            .then((serviceResponse) => {
                this.getMemberPropertiesData();
            });
    }

    isFloorDBOrWallH() {
        if (
            (this.memberData.StructuralSystemStringTag === 'floor' &&
                this.memberData.MemberTypeStringTag === 'dropbeam') ||
            (this.memberData.StructuralSystemStringTag === 'wall' &&
                this.memberData.MemberTypeStringTag === 'header')
        ) {
            return true;
        } else {
            return false;
        }
    }

    memberPitchDisabled() {
        if (
            this.memberData.StructuralSystemStringTag === 'roof' &&
            this.memberData.MemberTypeStringTag === 'hipbeam'
        ) {
            return true;
        } else if (this.memberData.StructuralSystemStringTag === 'ceiling') {
            return true;
        } else {
            return false;
        }
    }

    isFloorJoist() {
        if (
            this.memberData.StructuralSystemStringTag === 'floor' &&
            this.memberData.MemberTypeStringTag === 'joist'
        ) {
            return true;
        } else {
            return false;
        }
    }

    isHipValley() {
        if (
            this.memberData.StructuralSystemStringTag === 'roof' &&
            this.memberData.MemberTypeStringTag === 'hipbeam'
        ) {
            return true;
        }
        return false;
    }

    isFloorFlushBeam() {
        if (
            this.memberData.StructuralSystemStringTag === 'floor' &&
            this.memberData.MemberTypeStringTag === 'flushbeam'
        ) {
            return true;
        } else {
            return false;
        }
    }

    isFloorDropBeam() {
        if (
            this.memberData.StructuralSystemStringTag === 'floor' &&
            this.memberData.MemberTypeStringTag === 'dropbeam'
        ) {
            return true;
        } else {
            return false;
        }
    }

    isWallHeader() {
        if (
            this.memberData.StructuralSystemStringTag === 'wall' &&
            this.memberData.MemberTypeStringTag === 'header'
        ) {
            return true;
        } else {
            return false;
        }
    }

    isWallWindSill() {
        if (
            this.memberData.StructuralSystemStringTag === 'wall' &&
            this.memberData.MemberTypeStringTag === 'windsill'
        ) {
            return true;
        } else {
            return false;
        }
    }

    isWallStud() {
        if (
            this.memberData.StructuralSystemStringTag === 'wall' &&
            this.memberData.MemberTypeStringTag === 'stud'
        ) {
            return true;
        } else {
            return false;
        }
    }

    isWallColumn() {
        if (
            this.memberData.StructuralSystemStringTag === 'wall' &&
            this.memberData.MemberTypeStringTag === 'column'
        ) {
            return true;
        } else {
            return false;
        }
    }

    isPost() {
        if (
            this.memberData.StructuralSystemStringTag === 'floor' &&
            this.memberData.MemberTypeStringTag === 'freestandingpost'
        ) {
            return true;
        } else {
            return false;
        }
    }

    isCeilingJoist() {
        if (
            this.memberData.StructuralSystemStringTag === 'ceiling' &&
            this.memberData.MemberTypeStringTag === 'joist'
        ) {
            return true;
        } else {
            return false;
        }
    }

    isRoofJoist() {
        if (
            this.memberData.StructuralSystemStringTag === 'roof' &&
            this.memberData.MemberTypeStringTag === 'joist'
        ) {
            return true;
        } else {
            return false;
        }
    }

    isRoofFlushBeam() {
        if (
            this.memberData.StructuralSystemStringTag === 'roof' &&
            this.memberData.MemberTypeStringTag === 'flushbeam'
        ) {
            return true;
        } else {
            return false;
        }
    }

    isRoofDropBeam() {
        if (
            this.memberData.StructuralSystemStringTag === 'roof' &&
            this.memberData.MemberTypeStringTag === 'dropbeam'
        ) {
            return true;
        } else {
            return false;
        }
    }

    showMemberPitch() {
        if (this.memberData.StructuralSystemStringTag === 'roof' || this.memberData.StructuralSystemStringTag === 'ceiling') {
            return true;
        } else {
            return false;
        }
    }

    isMemberFlatRoof() {
        if (
            this.memberData.StructuralSystemStringTag === 'roof' &&
            this.memberData.MemberPitch <= 0.25
        ) {
            return true;
        } else {
            return false;
        }
    }

    isFloorDBOrFB() {
        if (
            (this.memberData.StructuralSystemStringTag === 'floor' &&
                this.memberData.MemberTypeStringTag === 'dropbeam') ||
            (this.memberData.StructuralSystemStringTag === 'floor' &&
                this.memberData.MemberTypeStringTag === 'flushbeam')
        ) {
            return true;
        }
        return false;
    }

    isFloorOrRoofDBOrFB() {
        if (
            (this.memberData.StructuralSystemStringTag === 'floor' &&
                this.memberData.MemberTypeStringTag === 'dropbeam') ||
            (this.memberData.StructuralSystemStringTag === 'floor' &&
                this.memberData.MemberTypeStringTag === 'flushbeam')
        ) {
            return true;
        }
        if (
            (this.memberData.StructuralSystemStringTag === 'roof' &&
                this.memberData.MemberTypeStringTag === 'dropbeam') ||
            (this.memberData.StructuralSystemStringTag === 'roof' &&
                this.memberData.MemberTypeStringTag === 'flushbeam')
        ) {
            return true;
        } else {
            return false;
        }
    }

    iswindDeflectionVisible() {
        if (
            this.memberData.MemberTypeStringTag === 'header' ||
            this.memberData.MemberTypeStringTag === 'column' ||
            this.memberData.MemberTypeStringTag === 'stud'
        ) {
            return true;
        } else {
            return false;
        }
    }

    isMemberTypeSettingsAvailable() {
        //if ((this.memberData.StructuralSystemStringTag === 'floor' || this.memberData.StructuralSystemStringTag === 'roof') &&
        //    (this.memberData.MemberTypeStringTag === 'joist' || this.memberData.MemberTypeStringTag === 'flushbeam')) {
        if (
            this.isFloorJoist() ||
            this.isFloorFlushBeam() ||
            this.isFloorDropBeam() ||
            this.isRoofJoist() ||
            this.isRoofFlushBeam() ||
            this.isCeilingJoist()
        ) {
            return true;
        } else {
            if (this.isApplySeismicOverstrengthAvailable()) {
                return true;
            } else {
                return false;
            }
        }
    }

    isRepetitiveMemberIncreaseAvailable() {
        return this.isFloorJoist() || this.isRoofJoist() || this.isCeilingJoist();
    }

    isAllowRemovalsAvailable() {
        return (
            this.isFloorJoist() ||
            this.isFloorFlushBeam() ||
            this.isFloorDropBeam() ||
            this.isCeilingJoist()
        );
    }

    isApplySeismicOverstrengthAvailable() {
        var seismicOverstrengthAvailable = false;
        if (this.allModelDataService.isBuildingCodeIBC2021IBC2018IBC2024()) {
            if (this.memberData.MemberType === 2 || this.memberData.MemberType === 4) {
                //flushBeam = 2, dropBeam = 4 (Floor or Roof)
                seismicOverstrengthAvailable = true;
            }
        }
        return seismicOverstrengthAvailable;
    }

    isAllowReinforcedCantileversAvailable() {
        return this.isFloorJoist() || this.isFloorFlushBeam();
    }

    isAllowWebStiffenersEndSupportsAvailable() {
        return (
            this.isFloorJoist() ||
            this.isFloorFlushBeam() ||
            this.isRoofJoist() ||
            this.isRoofFlushBeam()
        );
    }

    isAllowWebStiffenersIntermediateSupportsAvailable() {
        return (
            this.isFloorJoist() ||
            this.isFloorFlushBeam() ||
            this.isRoofJoist() ||
            this.isRoofFlushBeam()
        );
    }

    isCustom() {
        if (this.memberData.DeflectionCriteriaSetting === 3) {
            return true;
        } else {
            return false;
        }
    }
}
