import reportConnectorInformationTemplate from './reportConnectorInformation.html';
import moment from 'moment';
export default class reportController {
    constructor(
        $scope,
        $rootScope,
        $location,
        $timeout,
        $uibModal,
        $anchorScroll,
        $sce,
        $state,
        common,
        spinner,
        allModelDataService,
        reportService,
        forteCookieService,
        resourceMessagingService,
        FileSaver,
        materialAvailabilityService,
        materialAvailabilityStatus
    ) {
        'ngInject';
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$location = $location;
        this.$timeout = $timeout;
        this.$uibModal = $uibModal;
        this.$anchorScroll = $anchorScroll;
        this.$sce = $sce;
        this.$state = $state;
        this.$q = common.$q();
        this.spinner = spinner;
        this.allModelDataService = allModelDataService;
        this.reportService = reportService;
        this.resourceMessagingService = resourceMessagingService;
        this.FileSaver = FileSaver;
        this.materialAvailabilityService = materialAvailabilityService;
        this.materialAvailabilityStatusEnums = materialAvailabilityStatus;
        this.forteCookieService = forteCookieService;

        this.memberReportDataDTO;
        this.prData;
        this.mpcemsg = null;

        this.graphicIdReport = 'report';
        this.memberReportDataDTO = null;
        this.prData = null;
        this.graphicDataURL = null;
        this.graphicOverlayDataURL = null;
        this.hasSelfWeight = false;
        //this.hideLateralConnectionGrid = false;
        this.showLateralConnectionGridVertical = false;
        this.showLateralConnectionGridHorizontal = false;

        this.reportTabStepContent = 'reportTabStepContent';
        this.connectorsStepContent = 'connectorsStepContent';
        this.multipleMemberConnectorsStepContent =
            'multipleMemberConnectorsStepContent';
        this.printToPDFStepContent = 'printToPDFStepContent';
        this.reportNotesTitle = 'mainform_component_output';
        this.reportNotesContent = 'report_pdfStepContent';
        this.reportNotesOrder = 50;
        this.connectorsTableStepEnabled = false;
        this.isMpceVisible = false;
        this.isMemberVertical = false;
        this.isMemberWallStud = false;
        this.isMemberCeilingJoist = false;
        this.isResidential = false;
        this.isMemberHeaderOrWindSill = false;
        this.showLocationSide = true;
        this.showFactoredLoadColumn;
        this.showTotalLoadColumn;
        this.seismicLoadHeadingTag;

        this.displayMpceDetailedData;
        this.isJoist;
        this.hasMpceError;
        this.mpcemsg = null;
        this.mpceErrorMsg;
        this.mpceSelectedFullLengthDataArray;
        this.mpceSpansArray;
        this.mpceMessageColorArray;
        this.mpceDetailedDataArray;
        this.hasSelfWeight = false;
        this.designedDate;
        this.versionNumbersText;
        this.fileNameText;
        this.operatorName;
        this.operatorCompany;
        this.operatorPhone;
        this.operatorEmail;
        this.jobNotes;
        this.jobNotesTrustedHtml;
        this.memberNotesTrustedHtml;
        this.gridModificationAllowed = false;
        this.multiplyMessageStyles = {
            1: 'mpceNormalGridStyle',
            2: 'mpceNoSolutionGridStyle',
        };
        this.allDesignNotes = [];
        this.fullDetailUrl = 'http://' + $location.host() + '/fulldetailreport';

        this.handleReload = $scope.$on('reload', (event, args) => {
            if (args && !args.ignoreReport) {
                this.activate();
            }
        });

        $scope.$on('$destroy', () => {
            this.handleReload();
        });

        ////Methods from directive:
        this.$scope.$watch('memberReportDataDTO', (value) => {
            //I change here
            var val = value || null;
            if (val) {
                this.activate();
            }
        });

        this.$scope.$watch('multiPlyData', (value) => {
            //I change here
            var val = value || null;
            if (val) {
                this.refreshMPCEdataFromPRData();
            }
        });

        this.activate();
    }

    activate() {
        //hide the graphic toggle
        console.log('reportActivateTwice');
        this.$scope.$emit('showGraphicToggle', false);

        this.materialAvailabilityEnabled =
            this.forteCookieService.getMaterialAvailabilityEnabled();

        this.isMemberVertical = this.allModelDataService.getIsMemberVertical();
        this.isMemberWindSill = this.allModelDataService.getIsMemberWindSill();
        this.isResidential = this.allModelDataService.isResidential();
        this.isMemberHeaderOrWindSill = this.allModelDataService.getIsMemberHeaderOrWindSill();

        this._getReportDTO();
    }

    assembleMessage(messageComponent) {
        if (messageComponent === null) {
            return '';
        } else {
            return this.reportService.assembleMessage(messageComponent);
        }
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    getConstructionDetails(constructionDetails) {
        var details = '';
        for (var i = 0; i < constructionDetails.length; i++) {
            if (constructionDetails[i].DetailHidden === false) {
                details = details + constructionDetails[i].DetailText + ', ';
            }
        }
        details = details.substring(0, details.length - 2);
        return details;
    }

    getNBCCProvinceCodeText(provinceCode) {
        if (provinceCode.startsWith('nbcc') || provinceCode.startsWith('obc')) {
            return this.resourceMessagingService.getText(provinceCode);
        } else {
            // Actual Province Codes NOT translated
            return provinceCode;
        }
    }

    getNAForLocationGrid(key) {
        var returnStrings = '';
        if (key.indexOf('na') > -1) {
            var partials = key.split(' / ');
            for (var i = 0; i < partials.length; i++) {
                returnStrings = returnStrings + this.getText(partials[i]);
                if (i !== partials.length - 1) {
                    returnStrings += ' / ';
                }
            }
        } else {
            returnStrings = key;
        }

        return returnStrings;
    }

    connectorModelConvert(str) {
        if (str === 'NoSolution' || str === 'connectorNotFound') {
            return this.getText(str);
        } else {
            return str;
        }
    }

    getTextFastener(key) {
        if (key) {
            if (key.lastIndexOf('[') > -1) {
                var index = key.indexOf('[');
                var appendText = key.substring(index - 1, key.length);
                key = this.getText(key.substring(0, index - 1)).concat(
                    appendText
                );
                return key;
            } else {
                return this.getText(key);
            }
        }
    }

    modifyLateralConnectionMethod(supportIndex, newMethod) {
        this.reportService
            .modifyLateralSelectedMethod(supportIndex, newMethod)
            .then(
                (response) => {
                    this._getReportDTO();
                },
                (error) => {}
            );
    }

    showConnectorManufacturerName() {
        var showHorizontalName = false;
        var showVerticalName = false;

        if (this.memberReportDataDTO) {
            showHorizontalName = this.supportsDtoHasNailingSelected(
                this.memberReportDataDTO.VerticalSupports
            );
        }

        if (this.verticalMemberReportDTO) {
            showVerticalName = this.supportsDtoHasNailingSelected(
                this.verticalMemberReportDTO.VerticalSupportsDTO
            );
        }

        return showHorizontalName || showVerticalName;
    }

    supportsDtoHasNailingSelected(supportsDTO) {
        if (supportsDTO && supportsDTO.length > 0) {
            for (var supIdx = 0; supIdx < supportsDTO.length; supIdx++) {
                if (
                    supportsDTO[supIdx].LateralConnectionMethod &&
                    supportsDTO[supIdx].LateralConnectionMethod === '1'
                ) {
                    return true;
                }
            }
        }
        return false;
    }

    modifyLateralSelectedConnector(supportIndex, newConnector) {
        this.reportService
            .modifyLateralSelectedConnector(supportIndex, newConnector)
            .then(
                (response) => {
                    this._getReportDTO();
                },
                (error) => {}
            );
    }

    //force the control to call these functions right after element is drawn on the screen.

    _getFileName() {
        var miscData = this.memberReportDataDTO.MiscellaneousData[0];
        var textToReturn = this.getText('fileName') + ': ' + miscData.FileName;
        this.fileNameText = textToReturn;
    }

    _getVersionNumbersText() {
        //var prData = allModelDataService.getPostResponseData();
        var textToReturn = this.memberReportDataDTO.AppVersion;
        textToReturn =
            textToReturn + ', ' + this.memberReportDataDTO.EngineVersion;
        this.versionNumbersText = textToReturn;
    }
    /* eslint-disable no-undef */
    _getDesignedDate() {
        this.designedDate;
        var timeString = this.memberReportDataDTO.DateTime;
        var then = moment.utc(timeString);
        this.designedDate = then.format('lll');
    }
    /* eslint-enable no-undef */
    getSupportReactionColSpan() {
        //eslint-disable-line complexity
        var toReturn = 1;
        if (this.memberReportDataDTO) {
            if (this.memberReportDataDTO.SupportReactionColumnVisibility) {
                if (
                    this.memberReportDataDTO.SupportReactionColumnVisibility[0]
                        .FloorLiveVisible === true
                ) {
                    toReturn += 1;
                }
                if (
                    this.memberReportDataDTO.SupportReactionColumnVisibility[0]
                        .RoofLiveVisible === true
                ) {
                    toReturn += 1;
                }
                if (
                    this.memberReportDataDTO.SupportReactionColumnVisibility[0]
                        .SnowVisible === true
                ) {
                    toReturn += 1;
                }
                if (
                    this.memberReportDataDTO.SupportReactionColumnVisibility[0]
                        .WindVisible === true
                ) {
                    toReturn += 1;
                }
                if (
                    this.memberReportDataDTO.SupportReactionColumnVisibility[0]
                        .SeismicVisible === true
                ) {
                    toReturn += 1;
                }
                if (
                    this.memberReportDataDTO.MiscellaneousData[0]
                        .BuildingCodeIsNbcc === false
                ) {
                    toReturn += 1;
                }
            }
        }
        return toReturn;
    }

    checkForNumberGetText(key) {
        var test = key.substring(0, 1);
        if (isNaN(test) && test !== '-') {
            return this.getText(key);
        } else {
            return key;
        }
    }

    requiredBearingFailed(requiredBearing) {
        if (requiredBearing === 'failed') {
            return true;
        } else {
            return false;
        }
    }

    _getSoftwareOperatorText() {
        if (this.softwareOperator === '') {
            this.softwareOperatorText = this.getText('softwareOperatorDefault');
        } else {
            this.softwareOperatorText = this.softwareOperator;
        }
    }

    buildEdgeBracingLabel(index) {
        if (index === 0) {
            return this.getText('topEdgeLengthMobile');
        } else {
            return this.getText('bottomEdgeLengthMobile');
        }
    }

    buildSupportNameString(index, name) {
        var msg = '';
        var namesegment;
        var segmentcheck;
        msg = index + 1 + ' - ';
        namesegment = name.split(' ');
        for (var i = 0; i < namesegment.length; ++i) {
            //Dim suptName As String = "hangerOn"
            segmentcheck = namesegment[i];
            segmentcheck = segmentcheck.substring(0, 1);
            //check for PSL etc., depth, and other exceptions
            if (
                segmentcheck === segmentcheck.toUpperCase() ||
                namesegment[i] === 'concrete' ||
                namesegment[i] === 'masonry' ||
                namesegment[i] === 'steel'
            ) {
                msg += namesegment[i] + ' ';
            } else {
                //beam plate and studwall are lowercase if they are not first in namesegment
                if (
                    i >= 1 &&
                    (namesegment[i] === 'beam' ||
                        namesegment[i] === 'ledger' ||
                        namesegment[i] === 'plate' ||
                        namesegment[i] === 'studWall')
                ) {
                    msg += this.getText(namesegment[i]).toLowerCase() + ' ';
                } else {
                    msg += this.getText(namesegment[i]) + ' ';
                }
            }
        }
        return msg;
    }

    buildConnectorSupportNameString(name) {
        var namesegment = name.split('-');
        return namesegment[0] + ' - ' + this.getText(namesegment[1]);
    }

    loadCombinationString(message) {
        var languages = this.resourceMessagingService.getLanguageOptions();
        var i = 0;
        for (i; i < languages.length; ++i) {
            if (languages[i].isSelected === true) {
                break;
            }
        }
        if (message.MessageParameters.length > 0) {
            if (message.MessageParameters[0].trim() === 'na') {
                return this.getText('na');
            }
            if (languages[i].culture === 'fr-CA') {
                //replace . with ,
                message.MessageParameters[0] =
                    message.MessageParameters[0].replace('.', ',');
            } else {
                //replace , with .
                message.MessageParameters[0] =
                    message.MessageParameters[0].replace(',', '.');
            }
        }
        return this.reportService.assembleMessage(message);
    }

    buildHoleNameString(holeName) {
        var newHoleName = holeName;

        if (newHoleName.includes('circular')) {
            newHoleName = newHoleName.replace('circular', this.getText('circular'));
        } else if (newHoleName.includes('rectangular')) {
            newHoleName = newHoleName.replace('rectangular', this.getText('rectangular'));
        } else if (newHoleName.includes('slotted')) {
            newHoleName = newHoleName.replace('slotted', this.getText('slotted'));
        } else if (newHoleName.includes('square')) {
            newHoleName = newHoleName.replace('square', this.getText('square'));
        }

        if (newHoleName.includes('vertical')) {
            newHoleName = newHoleName.replace('vertical', this.getText('vertical'));
        }

        return newHoleName;

        // var msg = index + 1 + ' - ';
        // var namesegment = name.split(' ');
        // for (var i = 0; i < namesegment.length; ++i) {
        //     if (
        //         i > 1 &&
        //         (namesegment[i] === 'circular' ||
        //             namesegment[i] === 'rectangular' ||
        //             namesegment[i] === 'square' ||
        //             namesegment[i] === 'slotted')
        //     ) {
        //         msg += this.getText(namesegment[i]) + ' ';
        //     } else if (namesegment[i].length > 2) {
        //         if (i === namesegment.length - 1) {
        //             msg += namesegment[i];
        //         } else {
        //             msg += namesegment[i] + ' ';
        //         }
        //     }
        // }
        // return msg;
    }

    naStringOver12(msg) {
        if (msg === 'na') {
            return this.getText('na');
        } else {
            return msg + this.getText('slash12');
        }
    }

    naString(msg) {
        if (msg === 'na') {
            return this.getText('na');
        } else if (this._contains(msg, 'na')) {
            var replacer = this.getText('na');
            msg = msg.replace(/na/g, replacer);
            return msg;
        } else if (this._contains(msg, 'MidSpan')) {
            var replacer = this.getText('MidSpan');
            msg = msg.replace(/MidSpan/g, replacer);
            return msg;
        } else if (msg === 'no' || msg === 'yes') {
            return this.getText(msg);
        } else {
            return msg;
        }
    }

    _contains(string, searchfor) {
        return (string || '').indexOf(searchfor) > -1;
    }

    buildLoadsLocation(str) {
        if (str === 'reportConcentratedLiveLoadLocation' || str === 'reportConcentratedLiveLoadLocationTop') {
            return this.getText(str);
        }
        var locationsParts = str.split(' ');
        var convertedStr = '';
        for (var i = 0; i < locationsParts.length; i++) {
            if (i === locationsParts.length - 1) {
                if (
                    locationsParts[i] === 'loadPlacementTop' ||
                    locationsParts[i] === 'loadPlacementFront' ||
                    locationsParts[i] === 'loadPlacementBack' ||
                    locationsParts[i] === ''
                ) {
                    convertedStr +=
                        ' (' + this.getText(locationsParts[i]) + ')';
                } else {
                    convertedStr += locationsParts[i];
                }
            } else {
                convertedStr += locationsParts[i] + ' ';
            }
        }
        return convertedStr;
    }

    buildString(index, name) {
        var msg;
        var selfweightstring = name.slice(0, 4);
        if (selfweightstring === 'self') {
            this.hasSelfWeight = true;
            msg = 0 + ' - ' + this.getText(name);
        }

        if (this.hasSelfWeight) {
            msg = index + ' - ' + this.getText(name);
        } else {
            msg = index + 1 + ' - ' + this.getText(name);
        }

        return msg;
    }

    /*eslint-disable max-depth */
    refreshMPCEdataFromPRData() {
        this.multiPlyData = this.prData.MemberData.MultiPlyData;
        if (this.multiPlyData) {
            this.displayMpceDetailedData = this.multiPlyData.DisplayType === 3;

            if (
                !this.multiPlyData.MpceResults ||
                !this.memberReportDataDTO.MiscellaneousData[0].DesignPassed ||
                this.multiPlyData.DisplayType === 0
            ) {
                //no mpce run, skip the rest, dont show the grid.
                this.isMpceVisible = false;
            } else {
                this.isMpceVisible = true;
                this.hasMpceError =
                    this.multiPlyData.MpceResults.ErrorStatus.HasError;
                //this.mpcemsg = this.multiPlyData.MpceResults.ParallamMessageTag;
                //this.mpceErrorMsg = '';

                //if (this.hasMpceError) {
                //    this.mpceErrorMsg = this.multiPlyData.MpceResults.ErrorStatus.ErrorMessageKey;
                //} else {
                //populate the data for the fullLength/bySpan grid.
                if (!this.displayMpceDetailedData) {
                    this.mpceSelectedFullLengthDataArray =
                        this.memberReportDataDTO.MpceFastenerInfo;
                    //this.mpceReportNote = this.memberReportDataDTO.MpceMessages[0].Message;

                    //build the options for the fasteners
                    this.mpceSpansArray = [];
                    this.mpceMessageColorArray = [];
                    for (
                        var fullLengthIndex = 0;
                        fullLengthIndex <
                        this.multiPlyData.FullLengthGridData.length;
                        fullLengthIndex++
                    ) {
                        if (
                            this.multiPlyData.FullLengthGridData[
                                fullLengthIndex
                            ].Fastener[0] === 'NoSolution'
                        ) {
                            //set color to red
                            this.mpceMessageColorArray.push(2);
                        } else {
                            //set color to black
                            this.mpceMessageColorArray.push(1);
                        }
                        var currentItem =
                            this.multiPlyData.FullLengthGridData[
                                fullLengthIndex
                            ];
                        var currentSpanFasteners = [];
                        for (
                            var fastenerIndex = 0;
                            fastenerIndex < currentItem.Fastener.length;
                            fastenerIndex++
                        ) {
                            var fastenerToAdd = {};
                            fastenerToAdd.Name =
                                currentItem.Fastener[fastenerIndex];
                            fastenerToAdd.Rows =
                                currentItem.FastenerInfo[fastenerIndex].Rows;
                            fastenerToAdd.OCSpacing =
                                currentItem.FastenerInfo[
                                    fastenerIndex
                                ].OCSpacing;
                            fastenerToAdd.FastenerQty =
                                currentItem.FastenerInfo[
                                    fastenerIndex
                                ].FastenerQty;
                            fastenerToAdd.Notes =
                                currentItem.FastenerInfo[fastenerIndex].Notes;
                            fastenerToAdd.Placement =
                                currentItem.FastenerInfo[
                                    fastenerIndex
                                ].Placement;
                            fastenerToAdd.ReportNote =
                                currentItem.FastenerInfo[
                                    fastenerIndex
                                ].ReportNote;
                            currentSpanFasteners.push(fastenerToAdd);
                        }
                        this.mpceSpansArray.push(currentSpanFasteners);
                    }
                } else {
                    var detailedData = {};
                    this.mpceDetailedDataArray =
                        this.memberReportDataDTO.MpceDetailed;
                    for (
                        var detailedIndex = 0;
                        detailedIndex <
                        this.memberReportDataDTO.MpceDetailed.length;
                        detailedIndex++
                    ) {
                        detailedData.type =
                            this.memberReportDataDTO.MpceDetailed[
                                detailedIndex
                            ].Type;
                        detailedData.location =
                            this.memberReportDataDTO.MpceDetailed[
                                detailedIndex
                            ].Location;
                        detailedData.loads =
                            this.memberReportDataDTO.MpceDetailed[
                                detailedIndex
                            ].Load;
                        detailedData.ldf =
                            this.memberReportDataDTO.MpceDetailed[
                                detailedIndex
                            ].LDF;
                    }
                }
                //}
            }
        }
    }
    /*eslint-enable max-depth */

    multiPlyMessageFatal(messageItem) {
        let messageFatal;
        if (messageItem) {
            switch (messageItem.MessageKey) {
                case 'MultiPlyParallamMessage':
                    messageFatal = 'false';
                    break;
                case 'MultiPlyError110':
                    messageFatal = 'false';
                    break;
                default:
                    messageFatal = 'true';
                    break;
            }
        } else {
            messageFatal = 'false';
        }

        return messageFatal;
    }

    buildSupportAccessoryString(name) {
        var msg = '';
        var namesegment;
        namesegment = name.split(', ');
        for (var i = 0; i < namesegment.length; ++i) {
            if (i === namesegment.length - 1) {
                msg += this.getText(namesegment[i]);
            } else {
                msg += this.getText(namesegment[i]) + ', ';
            }
        }
        return msg;
    }

    buildAccessoryString(name) {
        var msg = '';
        if (name.length > 0) {
            var namesegment = name.split(', ');
            for (var i = 0; i < namesegment.length; ++i) {
                if (i === namesegment.length - 1) {
                    msg += this.getText(namesegment[i]);
                } else {
                    msg += this.getText(namesegment[i]) + ', ';
                }
            }
        }
        return msg;
    }

    getNotchFlangeColumnHeadersWithProperUOM(resultType) {
        var headerText = '';
        //vm.prData.ApplicationData.ProgramSettingsData.UOM === 3
        if (this.allModelDataService.isUOMMetric()) {
            switch (resultType) {
                case 'tension':
                    headerText = this.getText('tensionMomentHeaderMetric');
                    break;
                case 'compression':
                    headerText = this.getText('compressionMomentHeaderMetric');
                    break;
            }
        } else {
            switch (resultType) {
                case 'tension':
                    headerText = this.getText('tensionMomentHeader');
                    break;
                case 'compression':
                    headerText = this.getText('compressionMomentHeader');
                    break;
            }
        }
        return headerText;
    }

    /*eslint-disable complexity */
    getColumnHeadersWithProperUOM(key) {
        //keys for metric and imperial are not consistent in dictionary(shear and deflection).
        var letter = key.slice(0, 2);
        var string = null;

        //vm.prData.ApplicationData.ProgramSettingsData.UOM === 3
        if (this.allModelDataService.isUOMMetric()) {
            switch (letter) {
                case 'De':
                    string = this.getText('deflectionINMetric');
                    break;
                case 'su':
                    string = this.getText('supportReactionsLBSMetric');
                    break;
                case 'sh':
                    string = this.getText('ShearLBSMetric');
                    break;
                case 'mo':
                    string = this.getText('momentFTLBSMetric');
                    break;
            }
        } else {
            switch (letter) {
                case 'De':
                    string = this.getText('DeflectionIN');
                    break;
                case 'su':
                    string = this.getText('supportReactionsLBS');
                    break;
                case 'sh':
                    string = this.getText('shearLBS');
                    break;
                case 'mo':
                    string = this.getText('momentFTLBS');
                    break;
            }
        }
        return string;
    }
    /*eslint-enable complexity */

    refreshDesignNotes() {
        if (!this.memberReportDataDTO) {
            return;
        }
        if (!this.memberReportDataDTO.DesignMessageComponents) {
            return;
        }

        for (
            var msgCmpIndex = 0;
            msgCmpIndex <
            this.memberReportDataDTO.DesignMessageComponents.length;
            msgCmpIndex++
        ) {
            this.allDesignNotes.push({
                Message: this.reportService.assembleMessage(
                    this.memberReportDataDTO.DesignMessageComponents[
                        msgCmpIndex
                    ]
                ),
            });
        }
    }

    showPrintDialog() {
        this.spinner.spinnerShow(this.getText('mainform_menu_print'));
        this.reportService
            .updateAnyJobLevelInfoThatMightHaveChanged()
            .then(() => {
                this.prData = this.allModelDataService.getPostResponseData();
                this.prData.ApplicationData.JobSettingsData.JobNotes =
                    this.prData.ApplicationData.JobSettingsData.JobNotes.replace(
                        /\n/g,
                        '<br/>'
                    );
                this.$scope.JobNotesTrustedHtml = this.$sce.trustAsHtml(
                    this.prData.ApplicationData.JobSettingsData.JobNotes
                );
                // print after digest cycle
                this.$timeout(
                    () => {
                        //printElement(document.getElementById('reportDocument'), true);
                        this.printElementPDF(
                            document.getElementById('reportDocument'),
                            true
                        );
                    },
                    0,
                    false
                );
            });
    }

    getLoadCombinationHeader() {
        var loadCombinationHeader = this.getText('loadCombination');

        for (
            var resultIndex = 0;
            resultIndex <
            this.verticalMemberReportDTO.VerticalDesignResultsDTO.length;
            resultIndex++
        ) {
            if (
                this.verticalMemberReportDTO.VerticalDesignResultsDTO[
                    resultIndex
                ].LoadCombination.indexOf('[') !== -1
            ) {
                loadCombinationHeader =
                    loadCombinationHeader +
                    ' [' +
                    this.getText('loadGroup') +
                    ']';
                break;
            }
        }

        return loadCombinationHeader;
    }

    getVerticalLoadsHeader() {
        if (this.verticalMemberReportDTO) {
            if (
                this.verticalMemberReportDTO.VerticalLoadsDTO &&
                this.verticalMemberReportDTO.VerticalLoadsDTO.length > 1
            ) {
                return this.getText('LoadGridHeaderVerticalLoads');
            }
        } else {
            if (
                this.memberReportDataDTO.Loads &&
                this.memberReportDataDTO.Loads.length > 1
            ) {
                return this.getText('LoadGridHeaderVerticalLoads');
            }
        }

        return this.getText('verticalLoad');
    }

    _getReportDTO() {
        if (!this.allModelDataService.isCurrentMemberDesignable()) {
            this.$state.go('application.manage.files');
            return;
        }

        if (this.isMemberVertical === true) {
            this.reportService.getVertReportData().then(
                (response) => {
                    this.prData =
                        this.allModelDataService.getPostResponseData();
                    this.memberManagerData =
                        this.prData.ApplicationData.MemberManagerData;
                    var selectedContainerID =
                        this.memberManagerData.SelectedContainerID;
                    var selectedMemberID =
                        this.memberManagerData.SelectedMemberID;
                    this.memberData =
                        this.memberManagerData.MemberContainers[
                            selectedContainerID
                        ].Members[selectedMemberID];
                    this.verticalMemberReportDTO =
                        this.prData.MemberData.ReportOutputVerticalData.VerticalMemberReportData;

                    this.materialAvailabilityStatus =
                        this.materialAvailabilityService.getSeriesAvailabilityStatus(
                            this.memberData.SeriesID, this.memberData.ProductID
                        );
                    this.currentZipPostal =
                        this.materialAvailabilityService.getCurrentPostalCode();

                    if (this.memberData.MemberTypeStringTag === 'stud') {
                        this.isMemberWallStud = true;
                    }

                    // if (
                    //     this.prData.MemberData.VerticalZAxisLoadData
                    //         .LoadGroups[0].LoadDefs.length === 0
                    // ) {
                    //     this.hideLateralConnectionGrid = true;
                    // }
                    this.showLateralConnectionGridVertical = false;
                    if (this.memberData.MemberType === 8 || this.memberData.MemberType === 30) {
                        // Column or Stud
                        if (this.verticalMemberReportDTO.VerticalSupportsDTO.length === 0) {
                            if (this.prData.MemberData.ProductSelectionData.SelectedPreservativeTreatment > 0) {
                                this.showLateralConnectionGridVertical = true;
                            } else {
                                this.showLateralConnectionGridVertical = false;
                            }
                        } else if (this.prData.MemberData.VerticalZAxisLoadData.LoadGroups[0].LoadDefs.length === 0) {
                            this.showLateralConnectionGridVertical = false;
                        } else {
                            this.showLateralConnectionGridVertical = true;
                        }
                    }

                    this.verticalMemberReportDTO =
                        this.prData.MemberData.ReportOutputVerticalData.VerticalMemberReportData;
                    this.isMemberFreeStandingPost =
                        this.allModelDataService.getIsMemberFreeStandingPost();
                    this.refreshGraphic(
                        this.prData.MemberData.GraphicOutputData
                    );
                    this.setProcessingData();
                    this.gridModificationAllowed = false;
                    this.spinner.spinnerHide();
                },
                (error) => {}
            );
        } else {
            this.reportService.getHorizReportData().then(
                //promise success
                (response) => {
                    this.memberReportDataDTO = angular.fromJson(response);
                    var prData = this.allModelDataService.getPostResponseData();
                    this.prData = prData;
                    this.refreshGraphic(
                        this.prData.MemberData.GraphicOutputData
                    );

                    this.memberManagerData =
                        this.prData.ApplicationData.MemberManagerData;
                    var selectedContainerID =
                        this.memberManagerData.SelectedContainerID;
                    var selectedMemberID =
                        this.memberManagerData.SelectedMemberID;
                    this.memberData =
                        this.memberManagerData.MemberContainers[
                            selectedContainerID
                        ].Members[selectedMemberID];
                    if (this.memberData.StructuralSystem === 4 && this.memberData.MemberType === 1) {
                        this.isMemberCeilingJoist = true;
                    }

                    this.materialAvailabilityStatus =
                        this.materialAvailabilityService.getSeriesAvailabilityStatus(
                            this.memberData.SeriesID, this.memberData.ProductID
                        );
                    this.currentZipPostal =
                        this.materialAvailabilityService.getCurrentPostalCode();

                    this.setSeismicLoadHeadingTag();
                    this.setShowFactoredOrTotal();
                    this.refreshDesignNotes();

                    this.refreshMPCEdataFromPRData();
                    this._getDesignedDate();
                    this._getVersionNumbersText();
                    this._getFileName();
                    this._getSoftwareOperatorText();
                    this.gridModificationAllowed = true;

                    this.showLateralConnectionGridHorizontal = false;
                    if (this.memberData.MemberType === 31 ||  this.memberData.MemberType === 32) {
                        // Headers / WindSill
                        if (this.memberReportDataDTO.VerticalSupports) {
                            if (this.memberReportDataDTO.VerticalSupports.length > 0) {
                                this.showLateralConnectionGridHorizontal = true;
                            } else {
                                if (this.prData.MemberData.ProductSelectionData.SelectedPreservativeTreatment > 0) {
                                    this.showLateralConnectionGridHorizontal = true;
                                }
                            }
                        }
                    }

                    if (
                        this.memberReportDataDTO.MemberInformation.MemberType.toLowerCase() ===
                        'joist'
                    ) {
                        this.isJoist = true;
                    } else {
                        this.isJoist = false;
                    }
                    this.setShowLocationSideHorizontal();

                    this.memberNotesTrustedHtml = this.$sce.trustAsHtml(
                        this.memberReportDataDTO.MiscellaneousData[0].MemberNotes.replace(
                            /\n/g,
                            '<br/>'
                        )
                    );

                    this.spinner.spinnerHideAll();
                },
                //promise failure
                (response) => {
                    //log the error
                }
            );
        }
    }

    setSeismicLoadHeadingTag() {
        if (this.memberData.ApplySeismicOverstrength === true) {
            this.seismicLoadHeadingTag = 'seismicQE';
        } else {
            this.seismicLoadHeadingTag = 'seismic';
        }
    }

    setShowFactoredOrTotal() {
        if (this.memberReportDataDTO.MiscellaneousData[0].BuildingCodeIsNbcc === false) {
            if (this.forteCookieService.getFactoredTitleEnabled()) {
                this.showFactoredLoadColumn = true;
                this.showTotalLoadColumn = false;
            } else {
                this.showFactoredLoadColumn = false;
                this.showTotalLoadColumn = true;
            }
        } else {
            this.showFactoredLoadColumn = false;
            this.showTotalLoadColumn = false;
        }
    }

    setShowLocationSideHorizontal() {
        // if (
        //     this.allModelDataService.getIsMemberHeaderOrWindSill() ||
        //     this.memberReportDataDTO.MiscellaneousData[0].ProductIsTJI ||
        //     this.memberReportDataDTO.MiscellaneousData[0].ProductIsSteel
        // ) {
        //     this.showLocationSide = false;
        // } else {
        //     this.showLocationSide = true;
        // }
        this.showLocationSide = false;
        if (this.memberData.MemberType === 2 || this.memberData.MemberType === 4) {
            // Flush Beam or Drop Beam
            if (this.memberData.StructuralSystem === 1) {
                // Floor
                this.showLocationSide = true;
            } else if (this.memberData.StructuralSystem === 2) {
                // Roof
                if (this.memberData.MemberPitch <= 0.25) {
                    // Flat Roof Member
                    this.showLocationSide = true;
                }
            }
        }
    }

    getLocationLabel() {
        if (this.showLocationSide === true) {
            return this.getText('locationside');
        } else {
            return this.getText('locationText');
        }
    }

    setProcessingData() {
        if (this.isMemberVertical === true) {
            this.isJoist = false;

            this.setMemberNote(
                this.verticalMemberReportDTO.MiscellaneousDataDTO[0].MemberNotes
            );
            this.reportTabVersionInfo =
                this.verticalMemberReportDTO.AppVersion +
                ', ' +
                this.getText('designEngineVersionMemberReport') +
                this.verticalMemberReportDTO.EngineVersion +
                ', ' +
                this.getText('designEngineDataVersion') +
                this.verticalMemberReportDTO.DataVersion;
        } else {
            this.refreshMPCEdataFromPRData();
            //_getDesignedDate();
            //_getVersionNumbersText();
            //_getFileName();
            //_getSoftwareOperatorText();

            if (
                this.memberReportDataDTO.MemberInformation.MemberType.toLowerCase() ===
                'joist'
            ) {
                this.isJoist = true;
            } else {
                this.isJoist = false;
            }

            this.setMemberNote(
                this.memberReportDataDTO.MiscellaneousData[0].MemberNotes
            );
            this.reportTabVersionInfo =
                this.memberReportDataDTO.AppVersion +
                ', ' +
                this.getText('designEngineVersionMemberReport') +
                this.memberReportDataDTO.EngineVersion +
                ', ' +
                this.getText('designEngineDataVersion') +
                this.memberReportDataDTO.DataVersion;
        }

        this.operatorName =
            this.prData.ApplicationData.ProgramSettingsData.OperatorName;
        this.operatorCompany =
            this.prData.ApplicationData.ProgramSettingsData.OperatorCompany;
        this.operatorPhone =
            this.prData.ApplicationData.ProgramSettingsData.OperatorPhone;
        this.operatorEmail =
            this.prData.ApplicationData.ProgramSettingsData.OperatorEmail;
        this.jobNotesTrustedHtml = this.$sce.trustAsHtml(
            this.prData.ApplicationData.JobSettingsData.JobNotes.replace(
                /\n/g,
                '<br/>'
            )
        );
    }

    setMemberNote(memberNoteData) {
        if (memberNoteData.length === 0) {
            this.displayMemberNotes = false;
        } else {
            this.displayMemberNotes = true;
            this.memberNotesTrustedHtml = this.$sce.trustAsHtml(
                memberNoteData.replace(/\n/g, '<br/>')
            );
        }
    }

    ///////////////////////////////////helper for printing
    refreshGraphic(graphicData) {
        this.graphicDataURL =
            'data:image/png;base64,' + graphicData.imageDataURL;
        this.allModelDataService.setGraphicDataURL(this.graphicDataURL);
    }

    printElementPDF(elem, append, delimiter) {
        if (!this.graphicDataURL) {
            this.graphicDataURL = this.allModelDataService.getGraphicDataURL();
        }
        var prData = this.allModelDataService.getPostResponseData();
        var projectName = prData.ApplicationData.ProjectManagerData.ProjectName;
        var memberName =
            prData.ApplicationData.MemberManagerData.MemberContainers[
                prData.ApplicationData.MemberManagerData.SelectedContainerID
            ].Members[prData.ApplicationData.MemberManagerData.SelectedMemberID]
                .MemberName;
        var pdfFileName = projectName + '_' + memberName + '.pdf';

        var cleanedName = pdfFileName.replace(/[|&;$%@"<>()+,]/g, '');
        this.reportService
            .getHorizPDF(
                this.graphicDataURL,
                this.allModelDataService.getGraphicOverlayLabels()
            )
            .then((response) => {
                var fileData = new Blob([response.data], {
                    type: 'application/pdf',
                });
                this.FileSaver.saveAs(fileData, cleanedName);

                this.spinner.spinnerHide();
            });
    }

    getConnectorTable(prData) {
        var connectorData;
        this.reportService
            .getConnectorTableData('GetConnectorTableData', prData)
            .then((response) => {
                connectorData = angular.fromJson(response);
                this.showMoreInformationTable(connectorData);
            });
    }

    showMoreInformationTable() {
        var modalInstance = this.$uibModal.open({
            animation: true,
            template: reportConnectorInformationTemplate,
            controller: 'reportConnectorInformationController',
            controllerAs: 'rciCtrl',
            backdrop: 'static',
            resolve: {
                items: {
                    item: this.memberReportDataDTO.ConnectorTableData,
                },
            },
        });

        modalInstance.result.then(
            () => {},
            () => {}
        );
    }

    modifyConnectorData(index) {
        var data = {
            supportIndex: null,
            selectedConnectorName: null,
        }; //this.memberReportDataDTO.Connectors[index];
        data.supportIndex = index;
        data.selectedConnectorName =
            this.memberReportDataDTO.Connectors[index].Model;
        //var data = this.memberReportDataDTO.Connectors[index];
        //postService.requestPostEvent('ModifySelectedConnector', data, 'api/ReportOutput/');//holeEvent,holeData,path);
        this.reportService
            .modifySelectedConnector('ModifySelectedConnector', data)
            .then(() => {
                //this.memberReportDataDTO = angular.fromJson(response);
                var prData = this.allModelDataService.getPostResponseData();
                this.memberReportDataDTO =
                    prData.MemberData.ReportOutputHorizontalData.HorizontalMemberReportData;
                this.prData = prData;
                this.$rootScope.$broadcast(
                    'refreshGraphic_report',
                    prData.MemberData.GraphicOutputData
                );
            });
    }

    selectedFastenerChanged(index, fastener, spansArray) {
        var data = {
            gridRowIndex: null,
            selectedFastenerIndex: null,
            selectedFastenerName: null,
        };
        data.gridRowIndex = index;
        data.selectedFastenerIndex = this.getIndexFromFastenerList(
            index,
            fastener,
            spansArray
        );
        data.selectedFastenerName = fastener;
        //data.selectedFastenerName = this.memberReportDataDTO.MpceFastenerInfo[index].Fastener;
        this.reportService
            .modifySelectedFastener('ModifySelectedFastener', data)
            .then(() => {
                var prData = this.allModelDataService.getPostResponseData();
                this.memberReportDataDTO =
                    prData.MemberData.ReportOutputHorizontalData.HorizontalMemberReportData;
                this.prData = prData;
                this.multiPlyData = this.prData.MemberData.MultiPlyData;
                this.refreshMPCEdataFromPRData();
            });
    }

    getIndexFromFastenerList(mpceSpansIndex, fastenerName, spansArray) {
        // for each element in mpceSpansArray[index]: .Name
        // return index of match

        for (
            var fastIndex = 0;
            fastIndex < spansArray[mpceSpansIndex].length;
            fastIndex++
        ) {
            if (spansArray[mpceSpansIndex][fastIndex].Name === fastenerName) {
                return fastIndex;
            }
        }

        return 0;
    }

    scrollToElement(elementId) {
        if (
            (this.memberReportDataDTO &&
                this.memberReportDataDTO.Connectors.length !== 0 &&
                elementId === 'connectorsTable') ||
            (this.isMpceVisible === true && elementId === 'fastenersTable')
        ) {
            this.$location.hash(elementId);
            this.$anchorScroll();
        } else if (elementId === 'top') {
            this.$location.hash('btnPrintMemberReport');
            this.$anchorScroll();
        }
    }
}
