export default class dataMiningService {
    constructor(common, postService) {
        'ngInject';
        this.$q = common.$q;
        this.postService = postService;
    }

    sendLoginData() {
        //userAgent
        var data = {
            userAgent: navigator.userAgent,
            cookiesEnabled: navigator.cookieEnabled,
            windowHeight: this._getWindowHeight(),
            windowWidth: this._getWindowWidth(),
            screenHeight: screen.height,
            screenWidth: screen.width
        };
        return this._requestPostEvent('CollectLoginData', data);
    }

    sendTourEndData(isExistingUser, finalStepCompleted) {
        var data = {
            finalStepCompleted: finalStepCompleted,
            isExistingUser: isExistingUser
        };
        return this._requestPostEvent('CollectTourEndData', data);
    }

    _getWindowWidth() {
        return Math.max(
            document.body.scrollWidth,
            document.documentElement.scrollWidth,
            document.body.offsetWidth,
            document.documentElement.offsetWidth,
            document.documentElement.clientWidth
        );
    }

    _getWindowHeight() {
        return Math.max(
            document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.documentElement.clientHeight
          );
    }

    _requestPostEvent(event, data) {
        var path = 'api/DataMining/';
        return this.postService.requestSimplePost(path + event, data);
    }
}
// (function () {
//     'use strict';
//     var app = angular.module('app');
//     var serviceId = 'dataMiningService';
//     app.factory(serviceId, ['$http', 'common','postService', dataMiningService]);

//     function dataMiningService($http, common, postService) {
//         var $q = common.$q;

//         //this is the declaration of the public methods that will be accessible
//         var service = {
//             sendLoginData: sendLoginData,
//             sendTourEndData: sendTourEndData
//         };

//         function sendLoginData() {
//             //userAgent
//             var data = {
//                 userAgent: navigator.userAgent,
//                 cookiesEnabled: navigator.cookieEnabled,
//                 windowHeight: getWindowHeight(),
//                 windowWidth: getWindowWidth(),
//                 screenHeight: screen.height,
//                 screenWidth: screen.width
//             };
//             return _requestPostEvent('CollectLoginData', data);
//         }

//         function sendTourEndData(isExistingUser, finalStepCompleted) {
//             var data = {
//                 finalStepCompleted: finalStepCompleted,
//                 isExistingUser: isExistingUser
//             };
//             return _requestPostEvent('CollectTourEndData', data);
//         }

//         function getWindowWidth() {
//             return Math.max(
//                 document.body.scrollWidth,
//                 document.documentElement.scrollWidth,
//                 document.body.offsetWidth,
//                 document.documentElement.offsetWidth,
//                 document.documentElement.clientWidth
//             );
//         }

//         function getWindowHeight() {
//             return Math.max(
//                 document.body.scrollHeight,
//                 document.documentElement.scrollHeight,
//                 document.body.offsetHeight,
//                 document.documentElement.offsetHeight,
//                 document.documentElement.clientHeight
//               );
//         }

//         function _requestPostEvent(event, data) {
//             var path = 'api/DataMining/';
//             return postService.requestSimplePost(path + event, data);
//         }


//         return service;
//     }
// }());
