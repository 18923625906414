export default class dirtyCheckService {
    constructor() {

    }
    isDirty(event) {
        return event.target.classList.contains('ng-dirty');
    }
}
// (function () {
//     'use strict';
//     var app = angular.module('app');

//     app.factory('dirtyCheckService', [
//         function () {

//             var dirtyCheckServiceFactory = {
//                 isDirty: isDirty
//             };

//             // dirtyCheckService only works for form with name
//             function isDirty(event) {
//                 return (event.target.classList.contains('ng-dirty'));
//             }

//             return dirtyCheckServiceFactory;
//         } ]);
// }());
