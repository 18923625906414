export default class saveAsDefaultButtonController {
    constructor($rootScope, $location, $timeout, $state, $anchorScroll, jobSettingsService,
        levelPropertiesService, memberPropertiesService, floorPerformanceService, productSelectionService,
        spansService, resourceMessagingService, loadsHorizYService, roofGeometryService,
        windLoadingService, seismicLoadingService, heightAndSupportsService, loadsVertYService) {
        'ngInject';

        this.$rootScope = $rootScope;
        this.$location = $location;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$anchorScroll = $anchorScroll;
        this.jobSettingsService = jobSettingsService;
        this.levelPropertiesService = levelPropertiesService;
        this.memberPropertiesService = memberPropertiesService;
        this.floorPerformanceService = floorPerformanceService;
        this.productSelectionService = productSelectionService;
        this.spansService = spansService;
        this.resourceMessagingService = resourceMessagingService;
        this.loadsHorizYService = loadsHorizYService;
        this.roofGeometryService = roofGeometryService;
        this.windLoadingService = windLoadingService;
        this.seismicLoadingService = seismicLoadingService;
        this.heightAndSupportsService = heightAndSupportsService;
        this.loadsVertYService = loadsVertYService;

        this.isEnglish = true;
        this.isFrench = false;

        this.disabled = false;

        this.spinnerIn = false; //slide in
        this.spinnerOut = false; //slide out
        this.spinnerLoad = false; //rotate
        this.spinnerHide = true;
        this.spinnerComplete = false; //checkmark and solid circle

        this.spinnerInFr = false; //slide in
        this.spinnerOutFr = false; //slide out
        this.spinnerLoadFr = false; //rotate
        this.spinnerHideFr = true;
        this.spinnerCompleteFr = false; //checkmark and solid circle

        this.textIn = false;
        this.textOut = false;

        this.textInFr = false;
        this.textOutFr = false;

        this.isStepEnabled = false;
        this.stepContent = '';

        this.checkMarkTimer = 5000;
        this.hideTimer = 6000; //this should always be checkMarkTimer + spinner out animation duaration/currently(1s)
        this.loadinanimcomplete = false;
        this.disableButton;

        this.disableButton = $rootScope.$on('disableSaveDefaults', (event, data) => {
            this.disabled = data;
        });
    }

    activate() {
        this.isEnglish = this.isCultureEnglish();
        this.isFrench = !this.isCultureEnglish();
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    isCultureEnglish() {
        var languages = this.resourceMessagingService.getLanguageOptions();
        var i = 0;
        //var retMsg = '';

        for (i; i < languages.length; ++i) {
            if (languages[i].isSelected === true) {
                break;
            }
        }
        if (languages[i].culture === 'fr-CA') {
            return false;
        } else {
            return true;
        }
    }

    saveAsDefaultsClicked() {
        //find out if the french animation is used or the english one
        this.isEnglish = this.isCultureEnglish();
        this.isFrench = !this.isCultureEnglish();
        if (this.isCultureEnglish()) {
            this.saveAsDefaultsEnglishAnimation();
        } else {
            this.saveAsDefaultsFrenchAnimation();
        }
    }

    saveAsDefaultsEnglishAnimation() {
        //call saveasdefaults with correct path
        var path = this.$state.$current.name;
        //text animation flags
        this.textOut = true;
        this.textIn = false;
        //spinner and checkmark flags
        this.spinnerHide = false;
        this.spinnerOut = false;
        this.spinnerIn = true;
        this.spinnerComplete = false;

        this.loadinanimcomplete = false;
        //spinner in done. go to loading animation.
        this.$timeout(() => {
            this.spinnerIn = false;
            this.spinnerLoad = true;
            this.loadinanimcomplete = true;
        }, 1000); //the duration of the animation is 1s.

        switch (path) {
            case 'application.job.jobSettings':
                this.jobSettingsService.saveJobSettingsUserDefaults()
                    .then(() => {
                        this.toggleClasses();
                    });
                break;
            case 'application.job.level':
                this.levelPropertiesService.saveLevelPropertiesAsDefaults()
                    .then(() => {
                        this.toggleClasses();
                    });
                break;
            case 'application.job.memberInfo':
                this.memberPropertiesService.saveMemberPropertiesAsDefaults()
                    .then(() => {
                        this.toggleClasses();
                    });
                break;
            case 'application.job.spansAndSupports':
                this.spansService.saveSpansAsDefaults()
                    .then(() => {
                        this.toggleClasses();
                    });
                break;
            case 'application.job.heightAndSupports':
                this.heightAndSupportsService.saveUserDefaults()
                .then(() => {
                    this.toggleClasses();
                });
                break;
            case 'application.job.floorPerformance':
                this.floorPerformanceService.saveFloorPerformanceAsDefaults()
                    .then(() => {
                        this.toggleClasses();
                    });
                break;
            case 'application.job.productSelection':
                this.productSelectionService.saveProductSelectionAsDefaults()
                    .then(() => {
                        this.toggleClasses();
                    });
                break;
            case 'application.job.loadsHorizontalMember':
                this.loadsHorizYService.saveLoadAsDefault()
                    .then(() => {
                        this.toggleClasses();
                    });
                break;
            case 'application.job.verticalLoads':
                this.loadsVertYService.saveLoadAsDefault()
                    .then(() => {
                        this.toggleClasses();
                    });
                break;
            case 'application.job.roofGeometry':
                this.roofGeometryService.saveRoofGeometryAsDefaults()
                    .then(() => {
                        this.toggleClasses();
                    });
                break;
            case 'application.job.windLoading':
                this.windLoadingService.saveAsDefaults()
                    .then(() => {
                        this.toggleClasses();
                    });
                break;
            case 'application.job.seismicLoading':
                this.seismicLoadingService.saveAsDefaults()
                    .then(() => {
                        this.toggleClasses();
                    });
                break;
            default:
                break;
        }
    }

    saveAsDefaultsFrenchAnimation() {
        //call saveasdefaults with correct path
        var path = this.$state.$current.name;
        //text animation flags
        this.textOutFr = true;
        this.textInFr = false;
        //spinner and checkmark flags
        this.spinnerHideFr = false;
        this.spinnerOutFr = false;
        this.spinnerInFr = true;
        this.spinnerCompleteFr = false;

        this.loadinanimcomplete = false;
        //spinner in done. go to loading animation.
        this.$timeout(() => {
            this.spinnerInFr = false;
            this.spinnerLoadFr = true;
            this.loadinanimcomplete = true;
        }, 1000); //the duration of the animation is 1s.

        switch (path) {
            case 'application.job.jobSettings':
                this.jobSettingsService.saveJobSettingsUserDefaults()
                    .then(() => {
                        this.toggleClassesFr();
                    });
                break;
            case 'application.job.level':
                this.levelPropertiesService.saveLevelPropertiesAsDefaults()
                    .then(() => {
                        this.toggleClassesFr();
                    });
                break;
            case 'application.job.memberInfo':
                this.memberPropertiesService.saveMemberPropertiesAsDefaults()
                    .then(() => {
                        this.toggleClassesFr();
                    });
                break;
            case 'application.job.spansAndSupports':
                this.spansService.saveSpansAsDefaults()
                    .then(() => {
                        this.toggleClassesFr();
                    });
                break;
            case 'application.job.heightAndSupports':
                this.heightAndSupportsService.saveUserDefaults()
                .then(() => {
                    this.toggleClassesFr();
                });
                break;
            case 'application.job.floorPerformance':
                this.floorPerformanceService.saveFloorPerformanceAsDefaults()
                    .then(() => {
                        this.toggleClassesFr();
                    });
                break;
            case 'application.job.productSelection':
                this.productSelectionService.saveProductSelectionAsDefaults()
                    .then(() => {
                        this.toggleClassesFr();
                    });
                break;
            case 'application.job.loadsHorizontalMember':
                this.loadsHorizYService.saveLoadAsDefault()
                    .then(() => {
                        this.toggleClassesFr();
                    });
                break;
            case 'application.job.verticalLoads':
                this.loadsVertYService.saveLoadAsDefault()
                    .then(() => {
                        this.toggleClassesFr();
                    });
                break;
            case 'application.job.roofGeometry':
                this.roofGeometryService.saveRoofGeometryAsDefaults()
                    .then(() => {
                        this.toggleClassesFr();
                    });
                break;
            case 'application.job.windLoading':
                this.windLoadingService.saveAsDefaults()
                    .then(() => {
                        this.toggleClassesFr();
                    });
                break;
            case 'application.job.seismicLoading':
                this.seismicLoadingService.saveAsDefaults()
                    .then(() => {
                        this.toggleClassesFr();
                    });
                break;
            default:
                break;
        }
    }

    toggleClasses() {
        //this if check is to make sure the load spinner in animation is complete.
        //slow connections it will be, but on a fast connection it will finish loading first.
        //if this is the case do the else and time out for duration of load in animation.
        if (this.loadinanimcomplete) {
            this.spinnerComplete = true;
            //leave check mark up for an amount of time(5s right now) and reset classes.
            this.checkMarkTimeout();

            this.$timeout(() => {
                //hide the spinner after the checkmark timer expires
                this.spinnerHide = true;
            }, this.hideTimer); //checkmark timer + animation duration of the spinner out animation and text in animation
        } else {
            this.$timeout(() => {
                this.spinnerComplete = true;
                //leave check mark up for an amount of time(5s right now) and reset classes.
                this.checkMarkTimeout();

                this.$timeout(() => {
                    //hide the spinner after the checkmark timer expires
                    this.spinnerHide = true;
                }, this.hideTimer); //checkmark timer + animation duration of the spinner out animation and text in animation
            }, 1000);
        }

    }

    toggleClassesFr() {
        //this if check is to make sure the load spinner in animation is complete.
        //slow connections it will be, but on a fast connection it will finish loading first.
        //if this is the case do the else and time out for duration of load in animation.
        if (this.loadinanimcomplete) {
            this.spinnerCompleteFr = true;
            //leave check mark up for an amount of time(5s right now) and reset classes.
            this.checkMarkTimeoutFr();

            this.$timeout(() => {
                //hide the spinner after the checkmark timer expires
                this.spinnerHideFr = true;
            }, this.hideTimer); //checkmark timer + animation duration of the spinner out animation and text in animation
        } else {
            this.$timeout(() => {
                this.spinnerCompleteFr = true;
                //leave check mark up for an amount of time(5s right now) and reset classes.
                this.checkMarkTimeoutFr();

                this.$timeout(() => {
                    //hide the spinner after the checkmark timer expires
                    this.spinnerHideFr = true;
                }, this.hideTimer); //checkmark timer + animation duration of the spinner out animation and text in animation
            }, 1000);
        }

    }

    checkMarkTimeout() {
        this.$timeout(() => {
            //make text come back in.
            this.textOut = false;
            this.textIn = true;
            //make spinner go out
            this.spinnerIn = false;
            this.spinnerOut = true;
            this.spinnerComplete = false;
        }, this.checkMarkTimer);
    }

    checkMarkTimeoutFr() {
        this.$timeout(() => {
            //make text come back in.
            this.textOutFr = false;
            this.textInFr = true;
            //make spinner go out
            this.spinnerInFr = false;
            this.spinnerOutFr = true;
            this.spinnerCompleteFr = false;
        }, this.checkMarkTimer);
    }
}
