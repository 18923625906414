export default class createTeamController {
    constructor($scope, $uibModalInstance, common, resourceMessagingService, teamService) {
    // constructor($uibModalInstance, common, teamService) {
        'ngInject';

        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        this.common = common;
        this.resourceMessagingService = resourceMessagingService;
        this.teamService = teamService;
        this.submitted = false;
        this.teamName = '';

        this.$q = common.$q();

        // this.activate();
    }

    // activate() {
    // }

    createTeam(isValid) {
        //vm.submitted = true;
        if (isValid && this.submitted === false) {
            this.submitted = true;
            this.teamService.createTeam(this.teamName).then(
                (response) => {
                    this.$uibModalInstance.close(response);
                },
                (error) => {
                    this.$uibModalInstance.dismiss('');                            }
            );
        }
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    cancelClicked() {
        this.$uibModalInstance.dismiss('cancel');
    }
}
// var test;
// (function () {
//     'use strict';
//     var app = angular.module('app');

//     app.controller('createTeamController',
//         ['$rootScope', '$scope', '$uibModalInstance', '$uibModal', 'common',
//             'resourceMessagingService','teamService',
//             function ($rootScope, $scope, $uibModalInstance, $uibModal, common,
//                 resourceMessagingService, teamService) {
//                 ///NOTE: this does not follow convention.  normally we would write
//                 //var vm = this;
//                 //but for some reason, this modal controller will not work this way.
//                 //it could be because of the way it is injected in the modal.open method.
//                 var vm = $scope;

//                 var $q = common.$q;
//                 vm.getText = resourceMessagingService.getText;
//                 vm.createTeam = createTeam;
//                 vm.cancelClicked = cancelClicked;
//                 vm.submitted = false;
//                 vm.teamName = '';

//                 function activate() {

//                 }

//                 function createTeam(isValid) {
//                     //vm.submitted = true;
//                     if (isValid && vm.submitted === false) {
//                         vm.submitted = true;
//                         teamService.createTeam(vm.teamName).then(
//                             function success(response) {
//                                 $uibModalInstance.close(response);
//                             },
//                             function failure(error) {
//                                 $uibModalInstance.dismiss('');                            }
//                         );
//                     }
//                 }
//                 function cancelClicked() {
//                     $uibModalInstance.dismiss('cancel');
//                 }

//                 activate();

//             }]);
// }());
