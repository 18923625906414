export default class reportConnectorInformationController {
    constructor($scope, $uibModalInstance, resourceMessagingService, items) {
        'ngInject';
        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        this.resourceMessagingService = resourceMessagingService;
        this.items = items;

        this.rows = null;
        this.tableinfo = null;
        this.hideCell = null;
        this.supportIndexTitle = [];
        this.productSeries = [];
        this.minimumReinforcedBearingLength = [];
        this.supportType = [];
        this.slopeSkew = [];
        this.offsetReverseFlange = [];

        this.activate();
    }
    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    activate() {
        this.tableinfo = this.items.item;
        this.connectorGridTranslations();
        if (this.items.item.length === 1) {
            this.hideCell = true;
        }
        else {
            this.hideCell = false;
        }
    }

    cancel() {
        this.$uibModalInstance.dismiss('cancel');
    }

    helpClicked() {
        var win = window.open('/helpmobile/Content/D_Design Concepts/connectors_horiz_members.htm', '_blank');
        win.focus();
    }

    connectorGridTranslations() {
        for (var itemsCount = 0; itemsCount < this.items.item.length; itemsCount++) {
            var temporaryArrayofString = this.tableinfo[itemsCount].SupportIndexTitle.split(' ');
            this.supportIndexTitle[itemsCount] = this.getText(temporaryArrayofString[0]) + ' ' + temporaryArrayofString[1] + ' ' + this.getText(temporaryArrayofString[2]);

            if (this.tableinfo[itemsCount].ProductSeries.includes('na')) {
                var temporaryArrayofString = this.tableinfo[itemsCount].ProductSeries.split(' ');
                this.productSeries[itemsCount] = temporaryArrayofString[0] + ' ' + temporaryArrayofString[1] + ' ' + temporaryArrayofString[2] + ' ' + this.getText(temporaryArrayofString[3]);
            } else {
                this.productSeries[itemsCount] = this.tableinfo[itemsCount].ProductSeries;
            }

            temporaryArrayofString = this.tableinfo[itemsCount].MinimumReinforcedBearingLength.split(' ');
            if (temporaryArrayofString[0] === 'na') {
                temporaryArrayofString[0] = this.getText(temporaryArrayofString[0]);
            }
            if (temporaryArrayofString[2] === 'na') {
                temporaryArrayofString[2] = this.getText(temporaryArrayofString[2]);
            }
            this.minimumReinforcedBearingLength[itemsCount] = temporaryArrayofString[0] + ' ' + temporaryArrayofString[1] + ' ' + temporaryArrayofString[2];

            temporaryArrayofString = this.tableinfo[itemsCount].SupportType.split(' ');
            this.supportType[itemsCount] = this.getText(temporaryArrayofString[0]) + ' ' + temporaryArrayofString[1] + ' ' + this.getText(temporaryArrayofString[2]);

            temporaryArrayofString = this.tableinfo[itemsCount].SlopeSkew.split(' ');
            if (temporaryArrayofString[0] !== '') {
                temporaryArrayofString[0] = this.getText(temporaryArrayofString[0]);
            }
            if (temporaryArrayofString.length > 6) {
                this.slopeSkew[itemsCount] = temporaryArrayofString[0] + ' ' + temporaryArrayofString[1] + ' ' + (this.getText(temporaryArrayofString[2])).toLowerCase() + ' ' + temporaryArrayofString[3] + ' ' + this.getText(temporaryArrayofString[4]) + ' ' + temporaryArrayofString[5] + ' ' + (this.getText(temporaryArrayofString[6]).toLowerCase());
            }
            else {
                this.slopeSkew[itemsCount] = temporaryArrayofString[0] + ' ' + temporaryArrayofString[1] + ' ' + (this.getText(temporaryArrayofString[2])).toLowerCase() + ' ' + temporaryArrayofString[3] + ' ' + temporaryArrayofString[4] + ' ' + (this.getText(temporaryArrayofString[5]).toLowerCase());
            }
            temporaryArrayofString = this.tableinfo[itemsCount].OffsetReverseFlange.split(' ');
            this.offsetReverseFlange[itemsCount] = this.getText(temporaryArrayofString[0]) + ' ' + temporaryArrayofString[1] + ' ' + this.getText(temporaryArrayofString[2]);
        }
    }
}
// (function () {
//     'use strict';
//     var app = angular.module('app');
//     app.controller('reportConnectorInformationController',
//         ['$rootScope', '$scope', '$uibModalInstance', 'resourceMessagingService', 'items',
//             function ($rootScope, $scope, $uibModalInstance, resourceMessagingService, items) {
//                 var vm = $scope;

//                 vm.cancel = cancel;
//                 vm.helpClicked = helpClicked;
//                 vm.getText = getText;
//                 vm.rows = null;
//                 vm.tableinfo = null;
//                 vm.hideCell = null;
//                 //vm.test = null;

//                 activate();

//                 function getText(key) {
//                     return resourceMessagingService.getText(key);
//                 }

//                 function activate() {
//                     vm.tableinfo = items.item;
//                     if (items.item.length === 1) {
//                         vm.hideCell = true;
//                     }
//                     else {
//                         vm.hideCell = false;
//                     }
//                 }

//                 function cancel() {
//                     $uibModalInstance.dismiss('cancel');
//                 }

//                 function helpClicked() {
//                     var win = window.open('/helpmobile/Content/D_Design Concepts/connectors_horiz_members.htm', '_blank');
//                     win.focus();
//                 }

//             }]);
// }());
