export default class importFileModalController {
    constructor(
        $rootScope,
        $scope,
        $uibModalInstance,
        common,
        fileSystemService,
        spinner,
        resourceMessagingService,
        allModelDataService,
        FileSaver,
        Blob,
        isFileImportModal,
        filesToImport,
        treeItem,
        receivedDataDateObj,
        smartNavService
    ) {
        'ngInject';

        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        this.common = common;
        this.fileSystemService = fileSystemService;
        this.spinner = spinner;
        this.resourceMessagingService = resourceMessagingService;
        this.allModelDataService = allModelDataService;
        this.FileSaver = FileSaver;
        this.Blob = Blob;
        this.isFileImportModal = isFileImportModal;
        this.filesToImport = filesToImport;
        this.treeItem = treeItem;
        this.receivedDataDateObj = receivedDataDateObj;
        this.smartNavService = smartNavService;

        var $q = common.$q;

        // this.getText = getText();
        // this.importFileCancelClicked = importFileCancelClicked;
        // this.importFileOpenClicked = importFileOpenClicked;
        // this.drop = drop;
        // this.upload = upload;
        // this.calcDragOverClass = calcDragOverClass;
        this.headerText = null;
        //vm.uploadVisible = null;
        this.messageVisible = null;
        this.messageText = null;
        this.resultsVisible = null;
        this.openVisible = null;
        this.cancelVisible = null;
        this.importFileID = null;
        this.resultMessages = null;
        this.isFileImportModal = null;
        this.parentFolderId = null;

        // eslint-disable-next-line quotes
        this.accept = "'.4te'";
        // eslint-disable-next-line quotes
        this.pattern = "'.4te'";

        //NOTE: dictionary keys must match API Enum ProcessMessageType
        this.messageStyle = {
            1: 'headerMessage',
            2: 'infoMessage',
            3: 'execErrorMessage',
            4: 'successMessage',
            5: 'failedMessage',
        };

        this.activate();
    }

    activate() {
        this.files = this.filesToImport;
        this.receivedDataDate = this.receivedDataDateObj.value;
        if (this.isFileImportModal) {
            this.parentFolderId = this.treeItem.id;
            //vm.uploadVisible = true;
            this.messageVisible = false;
            this.resultsVisible = false;
            this.openVisible = false;
            this.cancelVisible = true;

            this.headerText = this.getText('importMenuItem');
            this.messageText = this.getText('importingForteFile');
            if (this.files.type === '' || this.files.type === 'text/xml') {
                this.upload();
            } else {
                // invalid file type - notify the user
                // logger.logWarning('Not a Forte File (.4te)', {}, null, true);
                this.headerText = this.getText('importResultMessages');
                this.messageVisible = false;
                this.resultsVisible = true;
                this.openVisible = false;
                //vm.uploadVisible = false;
                this.cancelVisible = !this.openVisible;
                var resultErrorMessage = this.getText('invalidForteFile');
                this.resultMessages = [
                    {
                        MessageType: 5,
                        MessageText: resultErrorMessage,
                    },
                ];
            }
        } else {
            var fileId = this.treeItem.id.substring(1, this.treeItem.id.length);
            this.fileIdToDownload = fileId;
            //vm.uploadVisible = false;
            this.messageVisible = true;
            this.resultsVisible = false;
            this.openVisible = false;
            this.cancelVisible = false;

            this.headerText = this.getText('downloadMenuItem');
            this.messageText = this.getText('downloadForteFile');
        }

        angular.element(document).ready(() => {
            if (!this.isFileImportModal) {
                this.downloadFileAndSave();
            }
        });
    }

    // angular.element(document).ready(()  => {
    //     if (!this.isFileImportModal) {
    //         downloadFileAndSave();
    //     }
    // });

    // $scope.$on('$viewContentLoaded', function() {
    //     downloadFileAndSave();
    // });

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    calcDragOverClass(e) {
        //console.log(e.dataTransfer.files[0]);
    }

    drop(e) {
        if (!this.files) {
            // invalid file type - notify the user
            // logger.logWarning('Not a Forte File (.4te)', {}, null, true);
            this.headerText = this.getText('importResultMessages');
            this.messageVisible = false;
            this.resultsVisible = true;
            this.openVisible = false;
            //vm.uploadVisible = false;
            this.cancelVisible = !this.openVisible;
            this.resultMessages = [
                {
                    MessageType: 5,
                    MessageText: 'This is not a 4te file!',
                },
            ];
        } else {
            this.upload();
        }
    }

    upload() {
        this.headerText = '';
        //vm.uploadVisible = false;
        this.messageVisible = true;
        this.cancelVisible = false;

        this.uploadFileToImport().then(
            (response) => {
                //if there is a echo file, try to convert it to an html and open a new window with it
                if (response.HtmlResultsLog) {
                    //this will probably get blocked by a popup blocker, turn off blocker for this url.
                    var wnd = window.open('about:blank', '');
                    wnd.document.write(response.HtmlResultsLog);
                    wnd.document.close();
                }

                this.headerText = this.getText('importResultMessages');
                this.messageVisible = false;
                this.resultsVisible = true;
                this.openVisible = response.ImportFileOK;
                this.cancelVisible = !this.openVisible;
                this.importFileID = response.ImportedNewFileTreeItemId;
                this.resultMessages = response.ImportResultsLog;
            },
            //promise failure
            (response) => {
                //handle permission rejection.
                if (response.hasOwnProperty('status')) {
                    if (response.status === 403) {
                        this.$uibModalInstance.dismiss(response);
                    }
                } else {
                    //log the error
                    this.headerText = this.getText('importResultMessages');
                    this.messageVisible = false;
                    this.resultsVisible = true;
                    this.openVisible = false;
                    this.cancelVisible = !this.openVisible;
                    this.resultMessages = response;
                }
            }
        );
    }

    uploadFileToImport() {
        var deferred = this.$q.defer();
        this.fileSystemService
            .importFile(this.files, this.parentFolderId, this.receivedDataDate)
            .then(
                (response) => {
                    deferred.resolve(response);
                },
                (response) => {
                    //broadcast response and the permission rejection will handle it if it failed permission check
                    if (response.status === 403) {
                        deferred.reject(response);
                    } else {
                        var data = {
                            response: response,
                            title: 'cAN nOT uPLOAD fILE',
                        };
                        this.$scope.$broadcast(
                            'handlePermissionRejection',
                            data
                        );
                        var rejectMessage = [
                            {
                                MessageType: 5,
                                MessageText: this.files.name + ' is Locked',
                            },
                        ];
                        deferred.reject(rejectMessage);
                    }
                }
            );
        return deferred.promise;
    }

    downloadFileAndSave() {
        var prData = this.allModelDataService.getPostResponseData();
        var requestData = {
            forteFileId: this.fileIdToDownload,
        };

        this.fileSystemService.downloadFileContent(requestData).then(
            (responseData) => {
                this.messageVisible = false;
                this.resultsVisible = true;
                this.resultMessages = responseData.downloadResultsLog;

                if (responseData.downloadOK) {
                    var fileData = new Blob([responseData.downloadContent], {
                        type: 'text/plain;charset=utf-8',
                    });
                    this.$uibModalInstance.dismiss('cancel');
                    this.FileSaver.saveAs(
                        fileData,
                        responseData.downloadFileName
                    );
                } else {
                    this.cancelVisible = true;
                }
            },
            (exception) => {
                var data = {
                    response: exception,
                    title: this.getText('downloadExportError'),
                };
                this.$rootScope.$broadcast('handlePermissionRejection', data);
                this.$uibModalInstance.dismiss('cancel');
            },
            (response) => {
                //broadcast response and the permission rejection will handle it if it failed permission check
                var data = {
                    response: response,
                    title: 'cAN nOT dOWNLOAD fILE',
                };
                this.$scope.$broadcast('handlePermissionRejection', data);
            }
        );
    }

    importFileCancelClicked() {
        this.$uibModalInstance.dismiss('cancel');
    }

    importFileOpenClicked() {
        this.$uibModalInstance.dismiss('open');
        this.spinner.spinnerShow(this.getText('openingFile'));
        var fileSystemTreeId = 'f' + this.importFileID;

        let previousFileIdToClose =
            this.forteCookieService.getCurrentlyOpenedFileId();
        if (this.allModelDataService.getPostResponseData()) {
            let prFileId =
                this.allModelDataService.getPostResponseData().ApplicationData
                    .ProjectManagerData.ProjectFileTreeID;
            if (prFileId !== previousFileIdToClose) {
                previousFileIdToClose = 0;
            }
        } else {
            // no PostResponseData, previous file NOT open, do not pass previous ID to API
            previousFileIdToClose = 0;
        }

        this.fileSystemService
            .openFile(fileSystemTreeId, previousFileIdToClose)
            .then(
                () => {
                    //kick off process to open new file and load models.
                    this.$rootScope.$emit('updateTheProject');
                    this.smartNavService.navigateOrReload(
                        'application.job.jobSettings'
                    );
                    this.spinner.spinnerHide();
                },
                (response) => {
                    //broadcast response and the permission rejection will handle it if it failed permission check
                    var data = {
                        response: response,
                        title: 'cAN nOT oPEN fILE',
                    };
                    this.$scope.$broadcast('handlePermissionRejection', data);
                }
            );
    }
}
// var test;
// (function () {
//     'use strict';
//     var app = angular.module('app');
//     app.controller('importFileModalController',
//         ['$rootScope', '$scope', '$uibModalInstance', '$uibModal', 'common', 'fileSystemService', 'logger', 'Upload', 'spinner', 'resourceMessagingService',
//             'allModelDataService', 'FileSaver', 'Blob', 'isFileImportModal', 'filesToImport', 'treeItem', 'receivedDataDateObj', 'smartNavService',
//             function ($rootScope, $scope, $uibModalInstance, $uibModal, common, fileSystemService, logger, Upload, spinner, resourceMessagingService,
//               allModelDataService,  FileSaver, Blob, isFileImportModal, filesToImport, treeItem, receivedDataDateObj, smartNavService) {
//                 ///NOTE: this does not follow convention.  normally we would write
//                 //var vm = this;
//                 //but for some reason, this modal controller will not work this way.
//                 //it could be because of the way it is injected in the modal.open method.
//                 var vm = $scope;
//                 var $q = common.$q;

//                 vm.getText = getText;
//                 vm.importFileCancelClicked = importFileCancelClicked;
//                 vm.importFileOpenClicked = importFileOpenClicked;
//                 vm.drop = drop;
//                 vm.upload = upload;
//                 vm.calcDragOverClass = calcDragOverClass;
//                 vm.headerText = null;
//                 //vm.uploadVisible = null;
//                 vm.messageVisible = null;
//                 vm.messageText = null;
//                 vm.resultsVisible = null;
//                 vm.openVisible = null;
//                 vm.cancelVisible = null;
//                 vm.importFileID = null;
//                 vm.resultMessages = null;
//                 vm.isFileImportModal = null;
//                 vm.parentFolderId = null;

//                 vm.accept = '\'.4te\'';
//                 vm.pattern = '\'.4te\'';

//                 //NOTE: dictionary keys must match API Enum ProcessMessageType
//                 vm.messageStyle = {
//                     1: 'headerMessage',
//                     2: 'infoMessage',
//                     3: 'execErrorMessage',
//                     4: 'successMessage',
//                     5: 'failedMessage'
//                 };

//                 activate();

//                 function activate() {
//                     vm.isFileImportModal = isFileImportModal;
//                     vm.files = filesToImport;
//                     vm.receivedDataDate = receivedDataDateObj.value;
//                     if (vm.isFileImportModal) {
//                         vm.parentFolderId = treeItem.id;
//                         //vm.uploadVisible = true;
//                         vm.messageVisible = false;
//                         vm.resultsVisible = false;
//                         vm.openVisible = false;
//                         vm.cancelVisible = true;

//                         vm.headerText = getText('importMenuItem');
//                         vm.messageText = getText('importingForteFile');
//                         if (vm.files.type === '' || vm.files.type === 'text/xml') {
//                             upload();
//                         } else {
//                             // invalid file type - notify the user
//                             // logger.logWarning('Not a Forte File (.4te)', {}, null, true);
//                             vm.headerText = getText('importResultMessages');
//                             vm.messageVisible = false;
//                             vm.resultsVisible = true;
//                             vm.openVisible = false;
//                             //vm.uploadVisible = false;
//                             vm.cancelVisible = !vm.openVisible;
//                             var resultErrorMessage = getText('invalidForteFile');
//                             vm.resultMessages = [ {
//                                 MessageType: 5,
//                                 MessageText: resultErrorMessage
//                             } ];
//                         }
//                     }
//                     else {
//                         var fileId = treeItem.id.substring(1, treeItem.id.length);
//                         vm.fileIdToDownload = fileId;
//                         //vm.uploadVisible = false;
//                         vm.messageVisible = true;
//                         vm.resultsVisible = false;
//                         vm.openVisible = false;
//                         vm.cancelVisible = false;

//                         vm.headerText = getText('downloadMenuItem');
//                         vm.messageText = getText('downloadForteFile');
//                     }
//                 }

//                 angular.element(document).ready(function () {
//                     if (!vm.isFileImportModal) {
//                         downloadFileAndSave();
//                     }
//                 });

//                 // $scope.$on('$viewContentLoaded', function() {
//                 //     downloadFileAndSave();
//                 // });

//                 function getText(key) {
//                     return resourceMessagingService.getText(key);
//                 }

//                 function calcDragOverClass(e) {
//                     //console.log(e.dataTransfer.files[0]);
//                 }

//                 function drop(e) {
//                     if (!vm.files) {
//                         // invalid file type - notify the user
//                         // logger.logWarning('Not a Forte File (.4te)', {}, null, true);
//                         vm.headerText = getText('importResultMessages');
//                         vm.messageVisible = false;
//                         vm.resultsVisible = true;
//                         vm.openVisible = false;
//                         //vm.uploadVisible = false;
//                         vm.cancelVisible = !vm.openVisible;
//                         vm.resultMessages = [ {
//                             MessageType: 5,
//                             MessageText: 'This is not a 4te file!'
//                         } ];
//                     }
//                     else {
//                         upload();
//                     }
//                 }

//                 function upload () {
//                     vm.headerText = '';
//                     //vm.uploadVisible = false;
//                     vm.messageVisible = true;
//                     vm.cancelVisible = false;

//                     uploadFileToImport().then(function (response) {
//                          //if there is a echo file, try to convert it to an html and open a new window with it
//                         if (response.HtmlResultsLog) {
//                             //this will probably get blocked by a popup blocker, turn off blocker for this url.
//                             var wnd = window.open('about:blank', '');
//                             wnd.document.write(response.HtmlResultsLog);
//                             wnd.document.close();
//                         }

//                         vm.headerText = getText('importResultMessages');
//                         vm.messageVisible = false;
//                         vm.resultsVisible = true;
//                         vm.openVisible = response.ImportFileOK;
//                         vm.cancelVisible = !vm.openVisible;
//                         vm.importFileID = response.ImportedNewFileTreeItemId;
//                         vm.resultMessages = response.ImportResultsLog;
//                     },
//                     //promise failure
//                     function (response) {
//                         //handle permission rejection.
//                         if (response.hasOwnProperty('status')) {
//                             if (response.status === 403) {
//                                 $uibModalInstance.dismiss(response);
//                             }
//                         } else {
//                             //log the error
//                             vm.headerText = getText('importResultMessages');
//                             vm.messageVisible = false;
//                             vm.resultsVisible = true;
//                             vm.openVisible = false;
//                             vm.cancelVisible = !vm.openVisible;
//                             vm.resultMessages = response;
//                         }
//                     });
//                 }

//                 function uploadFileToImport() {
//                     var deferred = $q.defer();
//                     fileSystemService.importFile(vm.files, vm.parentFolderId, vm.receivedDataDate).then(
//                        function (response) {
//                            deferred.resolve(response);
//                        },
//                        function (response) {
//                            //broadcast response and the permission rejection will handle it if it failed permission check
//                            if (response.status === 403) {
//                                deferred.reject(response);
//                            } else {
//                                var data = {
//                                    response: response,
//                                    title: 'cAN nOT uPLOAD fILE'
//                                };
//                                $scope.$broadcast('handlePermissionRejection', data);
//                                var rejectMessage = [ {
//                                    MessageType: 5,
//                                    MessageText: vm.files.name + ' is Locked'
//                                } ];
//                                deferred.reject(rejectMessage);
//                            }
//                        }
//                     );
//                     return deferred.promise;
//                 }

//                 function downloadFileAndSave () {
//                     var prData = allModelDataService.getPostResponseData();
//                     var requestData = {
//                         'forteFileId': vm.fileIdToDownload,
//                     };

//                     fileSystemService.downloadFileContent(requestData).then(
//                         function (responseData) {
//                             vm.messageVisible = false;
//                             vm.resultsVisible = true;
//                             vm.resultMessages = responseData.downloadResultsLog;

//                             if (responseData.downloadOK) {
//                                 var fileData = new Blob ([ responseData.downloadContent ], {type: 'text/plain;charset=utf-8'});
//                                 $uibModalInstance.dismiss('cancel');
//                                 FileSaver.saveAs(fileData, responseData.downloadFileName);
//                             }
//                             else {
//                                 vm.cancelVisible = true;
//                             }
//                         },
//                         function(exception) {
//                             var data = {
//                                 response: exception,
//                                 title: getText('downloadExportError')
//                             };
//                             $rootScope.$broadcast('handlePermissionRejection', data);
//                             $uibModalInstance.dismiss('cancel');
//                         },
//                         function error(response) {
//                             //broadcast response and the permission rejection will handle it if it failed permission check
//                             var data = {
//                                 response: response,
//                                 title: 'cAN nOT dOWNLOAD fILE'
//                             };
//                             $scope.$broadcast('handlePermissionRejection', data);
//                         });
//                 }

//                 function importFileCancelClicked() {
//                     $uibModalInstance.dismiss('cancel');
//                 }

//                 function importFileOpenClicked() {
//                     $uibModalInstance.dismiss('open');
//                     spinner.spinnerShow(getText('openingFile'));
//                     var fileSystemTreeId = 'f' + vm.importFileID;
//                     fileSystemService.openFile(fileSystemTreeId).then(
//                         function () {
//                             //kick off process to open new file and load models.
//                             $rootScope.$emit('updateTheProject');
//                             smartNavService.navigateOrReload('application.job.jobSettings');
//                             spinner.spinnerHide();
//                         },
//                         function error(response) {
//                             //broadcast response and the permission rejection will handle it if it failed permission check
//                             var data = {
//                                 response: response,
//                                 title: 'cAN nOT oPEN fILE'
//                             };
//                             $scope.$broadcast('handlePermissionRejection', data);
//                         });
//                 }
//             }]);
// }());
