import linkedReactionTemplate from '../../modals/linkedReaction/linkedReaction.html';
import toastr from 'toastr';
export default class loadsHorizController {
    /* eslint-disable max-params*/
    constructor($rootScope, $scope, $location, $uibModal, $timeout, $anchorScroll, $templateCache,
        loadsHorizYService, loadsHorizZService, quickLoadsService, dirtyCheckService, resourceMessagingService,
        $window, $document, common, notifier, allModelDataService, smartNavService, memberManagementService, loadsCllService) {
        'ngInject';
        //controller code goes here.
        this.$q = common.$q();
        this.memberInfo;

        this.gridData = [];
        this.ldfData = [];
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.subMenuHeight = 100;
        this.isEnglish = true;
        this.commentdialogcalled = false;
        this.loadsTabStepContent = 'loadsTabStepContent';
        this.linkedLoads;
        this.linkedLoadsViewPositions;
        this.quickLoadsList = null;
        this.metadata = null;
        this.roofSlope = null;

        this.$location = $location;
        this.$uibModal = $uibModal;
        this.$timeout = $timeout;
        this.$anchorScroll = $anchorScroll;
        this.$templateCache = $templateCache;
        this.loadsHorizYService = loadsHorizYService;
        this.loadsHorizZService = loadsHorizZService;
        this.quickLoadsService = quickLoadsService;
        this.dirtyCheckService = dirtyCheckService;
        this.resourceMessagingService = resourceMessagingService;
        this.$window = $window;
        this.$document = $document;
        this.notifier = notifier;
        this.allModelDataService = allModelDataService;
        this.smartNavService = smartNavService;
        this.memberManagementService = memberManagementService;
        this.loadsCllService = loadsCllService;
        this.subMenuHeight = 100;

        this.isEnglish = true;

        this.moreOptionsListVisible;
        this.addedLoad = false;

        this.$loadsAccordion = $('#loadsAccordion');
        this.$loadsAccordion.on('show.bs.collapse', '.collapse', () => {
            this.$loadsAccordion.find('.collapse.in').collapse('hide');
        });

        this.activate();
    }


    activate() {
        //show the graphic toggle
        this.$scope.$emit('showGraphicToggle', true);
        this.moreOptionsListVisible = false;
        //var data = loadsHorizYService.getLoadData();
        this.linkedLoadsViewPositions = [];
        this.memberInfo = this.memberManagementService.getCurrentlySelectedMemberSDO();

        this.getMetaData();
        this.setQuickLoadsList();
        this.getAllLoadData();
        this.getAllLateralLoadData();
        this.getJobSettingsData();
        this.getCllData();

        this.isEnglish = this.resourceMessagingService.isCultureEnglish();
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    getCllData() {
        this.loadsCllService.getSupplementalCLLData().then(
            (response) => {
                this.cllData = response;
                this.cllPending = false;
                this.formCll.$setPristine();
            }
        );
    }

    isCllMagnitudeZero() {
        if (this.cllData.floorMagnitudeFriendly !== '') {
            return parseInt(this.cllData.floorMagnitudeFriendly) === 0;
        } else {
            return false;
        }
    }

    deleteCll() {
        this.cllData.CllMag = 0;
        this.loadsCllService.modifyCllMag(0).then(
            (response) => {
                this.loadsCllService.modifyCllComment('').then(
                    (response2) => {
                        this.getCllData();
                    }
                );
            }
        );
    }

    isCombineRowEnabled() {
        if (this.metaData.cllEnabled === true && this.cllData.CllMag > 0) {
            return true;
        }
        return false;
    }

    getCllLocationMessage() {
        var originalText = this.getText('cllDefaultLocation');
        if (this.allModelDataService.isUOMMetric()) {
            return originalText.replace('~1', '305mm');
        } else {
            return originalText.replace('~1', '1\'');
        }
    }

    modifyCllMag(event, newValue) {
        if (this.dirtyCheckService.isDirty(event)) {
            this.cllPending = true;
            if (typeof newValue === 'undefined') {
                newValue = '0';
            }
            this.loadsCllService.modifyCllMag(newValue).then(
                (response) => {
                    this.getAllLoadData();
                }
            );
        }
    }

    modifyCllTrib(event, newValue) {
        if (this.dirtyCheckService.isDirty(event)) {
            this.cllPending = true;
            this.loadsCllService.modifyCllTrib(newValue).then(
                (response) => {
                    this.getCllData();
                }
            );
        }
    }

    modifyCllComment(event, newValue) {
        if (this.dirtyCheckService.isDirty(event)) {
            this.cllPending = true;
            this.loadsCllService.modifyCllComment(newValue).then(
                (response) => {
                    this.getCllData();
                }
            );
        }
    }

    modifyCllShared(newValue) {
        this.cllPending = true;
        this.loadsCllService.modifyCllShared(newValue).then(
            (response) => {
                this.getCllData();
            }
        );
    }

    modifyLoadCommentCll(loadComment) {
        this.commentdialogcalled = true;
        var modalInstance = this.$uibModal.open({
            animation: true,
            templateUrl: 'modifyCommentModal.html',
            controller: 'holesModifyCommentController',
            controllerAs: 'modifyCommentCtrl',
            windowClass: 'connectorDialog',
            backdrop: 'static',
            size: 'md',
            resolve: {
                items: {
                    comment: loadComment
                }
            }
        });

        modalInstance.result.then((newComment) => {
            this.cllPending = true;
            this.loadsCllService.modifyCllComment(newComment).then(
                (response) => {
                    this.getCllData();
                }
            );
        }, () => {
            this.cllPending = true;
            this.getCllData();
            this.commentdialogcalled = false;
        })
        .finally(() => {});

    }

    isCllSharingAvailable() {
        //if membertype is flush beam & cllmag is greater than 0
        if (this.memberInfo.MemberTypeStringTag === 'flushbeam' && !this.isCllMagnitudeZero()) {
            return true;
        }
        return false;
    }

    enableLoadEditing() {
        this._showWarning().then(
            (reason) => {
                this.loadsHorizYService.enableLoadEditing().then(() => {
                    // refresh whole page
                    //this.$window.location.reload();
                    this.smartNavService.navigateOrReload('application.job.loadsHorizontalMember');
                });
            },
            (reason) => {

            });
    }

    _showWarning() {
        var deferred = this.$q.defer();
        var buttonText = {
            ok: this.getText('ok'),
            cancel: this.getText('cancel')
        };
        this.notifier.showEnableLoadEditingWarning(this.getText('warning'), this.getText('enableLoadWarning'), buttonText, deferred);
        return deferred.promise;
    }

    isWallHeader() {
        if (this.memberInfo.MemberTypeStringTag === 'header') {
            return true;
        } else {
            return false;
        }
    }

    isWallWindSill() {
        if (this.memberInfo.MemberTypeStringTag === 'windsill') {
            return true;
        } else {
            return false;
        }
    }

    isFlushBeam() {
        if (this.memberInfo && this.memberInfo.MemberTypeStringTag === 'flushbeam') {
            return true;
        } else {
            return false;
        }
    }

    isDropBeam() {
        if (this.memberInfo && this.memberInfo.MemberTypeStringTag === 'dropbeam') {
            return true;
        } else {
            return false;
        }
    }

    isHipValleyBeam() {
        if (this.memberInfo && this.memberInfo.MemberTypeStringTag === 'hipbeam' && this.memberInfo.StructuralSystemStringTag === 'roof') {
            return true;
        } else {
            return false;
        }
    }

    isCeilingJoist() {
        if (this.memberInfo && this.memberInfo.MemberTypeStringTag === 'joist' && this.memberInfo.StructuralSystemStringTag === 'ceiling') {
            return true;
        } else {
            return false;
        }
    }

    getMetaData() {
        this.loadsHorizYService.getMetaData().then((response) => {
            this.metaData = response;
            var headerColumns = this.metaData.rowHeaders;
            var headerColumnsName = headerColumns.filter((object) => {
                return object.enabled === true;
            });
            if (this.isCeilingJoist()) {
                this.metaData.loadNotes.push('ceilingJoistLoads');
            }
            if (this.isFlushBeam() || this.isDropBeam() || this.isHipValleyBeam()) {
                this.metaData.loadNotes.push('crossGrainTensionLoadNotes');
            }
            this.headerColumnsName = headerColumnsName;
        });

        this.loadsHorizZService.getMetaData().then((response) => {
            this.metaDataLateral = response;
        });
    }

    isMemberLoadValidForQuickload(viewPosition) {
        return this.quickLoadsService.isMemberLoadValidForQuickload(viewPosition);
    }

    addQuickLoadFromMemberLoad(viewPosition) {
        this._getQLName(viewPosition).then(
            (response) => {
                if (!response || response.trim() === '') {
                    response = this.getText('QuickLoadsDescriptionDefault');
                }
                var qlData = this.quickLoadsService.getQuickLoadsModelData();
                this.quickLoadsService.addQuickLoadFromMemberLoad(viewPosition, response, qlData).then(
                    (newQlData) => {
                        this.allModelDataService.setQuickLoadsModelData(newQlData);
                        this.smartNavService.navigateOrReload('application.job.loadsHorizontalMember');
                    },
                    (error) => {
                        //console.log(error);
                    }
                );
            },
            (error) => {
                //console.log('cancelled');
            }
        );
    }
    _getQLName(viewPosition) {
        var deferred = this.$q.defer();
        this.notifier.showSaveAsQuickLoadPrompt(deferred, this.getText('newQuickLoadName'), this.getText('mainform_menu_save'), this.getText('trCancel'));
        return deferred.promise;
    }

    setQuickLoadsList() {
        this.quickLoadsService.setQuickLoadsModelData().then(() => {
            this.quickLoadsList = this.quickLoadsService.getMemberQuickLoadsList();
        });
    }

    addMemberLoadFromQuickLoad(quickLoadListItem) {
        this.addedLoad = true;
        this.quickLoadsService.addMemberLoadFromQuickLoad(quickLoadListItem).then(() => {
            this.getAllLoadData();
            toastr.options = {
                'positionClass': 'toast-top-center',
            };
            toastr.success(this.getText('SuccessfullyAdded'));
        });
    }

    getAllLoadData() {
        var sessionData = this.loadsHorizYService.getSessionLoadData();
        //this converts from JSON object to js array of JSON objects
        var loadGroups = Object.keys(sessionData.LoadGroups).map((k) => {
            return sessionData.LoadGroups[k];
        });
        this.gridData = [];
        var allViewPositions = this.getAllViewPositonValues(loadGroups);

        for (var i = 0; i < allViewPositions.length; i++) {
            var currentVP = allViewPositions[i];
            this.getSupplementalVPData(currentVP, i);

        }

        this.getCllData();
    }

    getAllLateralLoadData() {
        var lateralLoadData = this.allModelDataService.getPostResponseData().MemberData.HorizontalZAxisLoadData;
        var loadGroups = Object.keys(lateralLoadData.LoadGroups).map((k) => {
            return lateralLoadData.LoadGroups[k];
        });

        this.gridDataLateral = [];
        var allViewPositions = this.getAllViewPositonValues(loadGroups);

        for (var i = 0; i < allViewPositions.length; i++) {
            var currentVP = allViewPositions[i];
            this.getSupplementalVPDataLateral(currentVP, i);
        }
    }

    getAllViewPositonValues(loadGroups) {
        var viewPositionArray = [];
        for (var i = 0; i < loadGroups[0].LoadDefs.length; i++) {
            var currentVPValue = loadGroups[0].LoadDefs[i].ViewPositionValue;
            if (viewPositionArray.indexOf(currentVPValue) > -1) {
                //dont add it
            } else {
                viewPositionArray.push(currentVPValue);
            }
        }
        return viewPositionArray;
    }

    testGetAllViewPositonValues(loadGroups) {
        return this.getAllViewPositonValues(loadGroups);
    }

    getSupplementalVPData(viewPosition, i) {
        this.$rootScope.$broadcast('disableSaveDefaults', false);
        this.loadsHorizYService.getSupplementalVPData(viewPosition).then((response) => {
            this.gridData[i] = response;
            if (this.gridData[i].roofSlopeVisible === true && this.gridData[i].roofSlopeEnabled === false && this.gridData[i].roofSlope !== '0/12') {
                this.loadsHorizYService.modifyRoofSlope(viewPosition, 0).then((response1) => {
                    this.getSupplementalVPData(viewPosition, i);
                });
            }

            this.disableSaveAsDefaultButton();
            this.gridData[i].pending = false;
            this.formLoads.$setPristine();
        });
    }

    getSupplementalVPDataLateral(viewPosition, i) {
        this.$rootScope.$broadcast('disableSaveDefaults', false);
        this.loadsHorizZService.getSupplementalVPData(viewPosition).then((response) => {
            if (response.loadType === 2) {
                response.tribWidthStart = this.getText('na');
            }
            this.gridDataLateral[i] = response;
            this.disableSaveAsDefaultButton();
            this.gridDataLateral[i].pending = false;
            this.formLateralLoads.$setPristine();
        });
    }

    disableSaveAsDefaultButton() {
        for (var i = 0; i < this.gridData.length; ++i) {
            if (this.gridData[i].linkedReaction) {
                this.$rootScope.$broadcast('disableSaveDefaults', true);
            }
        }
    }

    showLinkedReactionDialog(loadToUpdate) {

        var modalInstance = this.$uibModal.open({
            animation: true,
            template: linkedReactionTemplate,
            controller: 'linkedReactionController',
            controllerAs: 'lrCtrl',
            backdrop: 'static',
            resolve: {
                loadToUpdate: loadToUpdate
            }
        });
        modalInstance.result.then((result) => {
            switch (result.toString()) {
                case 'success':
                    //refresh the view
                    this.getAllLoadData();
                    break;
                default:
                    //dont refresh.
            }
        })
        .finally(() => {});
    }

    showUpdateLinkedReactionDialog(load) {
        this.showLinkedReactionDialog(load);
    }

    addLoad(viewPosition) {
        this.addedLoad = true;
        this.loadsHorizYService.addLoad(viewPosition).then((response) => {
            this.getAllLoadData();
            toastr.options = {
                'positionClass': 'toast-top-center',
            };
            toastr.success(this.getText('SuccessfullyAdded'));
            this.$loadsAccordion.find('.collapse.in').collapse('hide');
        });
    }

    addLateralLoad(viewPosition) {
        this.addedLoad = true;
        if (this.gridDataLateral.length < 1) {
            this.loadsHorizZService.addLateralLoad(viewPosition).then((response) => {
                this.getAllLateralLoadData();
                toastr.options = {
                    'positionClass': 'toast-top-center',
                };
                toastr.success(this.getText('SuccessfullyAdded'));
                this.$loadsAccordion.find('.collapse.in').collapse('hide');
            });
        }

    }

    copyLoad(viewPosition) {
        this.addedLoad = true;
        this.loadsHorizYService.copyLoad(viewPosition).then((response) => {
            this.getAllLoadData();
            toastr.options = {
                'positionClass': 'toast-top-center',
            };
            toastr.info(this.getText('SuccessfullyCopied'));
        });
    }

    deleteLoad(viewPosition) {
        this.addedLoad = false;
        var gridIndex = this.findGridIndex(viewPosition);
        this.gridData[gridIndex].pending = true;
        this.loadsHorizYService.deleteLoad(viewPosition).then((response) => {
            this.getAllLoadData();
            toastr.options = {
                'positionClass': 'toast-top-center',
            };
            toastr.warning(this.getText('SuccessfullyDeleted'));
            if (this.gridData.length > 2) {
                this.$loadsAccordion.find('.collapse.in').collapse('hide');
            }
        })
        .finally(() => {

        });
    }

    deleteLateralLoad(viewPosition) {
        this.addedLoad = false;
        var gridIndex = this.findGridIndexLateral(viewPosition);
        this.gridDataLateral[gridIndex].pending = true;
        this.loadsHorizZService.deleteLoad(viewPosition).then((response) => {
            this.getAllLateralLoadData();
            toastr.options = {
                'positionClass': 'toast-top-center',
            };
            toastr.warning(this.getText('SuccessfullyDeleted'));
            if (this.gridDataLateral.length > 2) {
                this.$loadsAccordion.find('.collapse.in').collapse('hide');
            }
        })
        .finally(() => {

        });
    }

    loadPlacementChanged(viewPosition, newPlacement) {
        var gridIndex = this.findGridIndex(viewPosition);
        this.gridData[gridIndex].pending = true;
        this.loadsHorizYService.modifyLoadPlacement(viewPosition, newPlacement).then((response) => {
            this.getSupplementalVPData(viewPosition, gridIndex);
        });
    }
    loadTypeChanged(viewPosition, newType) {
        var gridIndex = this.findGridIndex(viewPosition);
        this.gridData[gridIndex].pending = true;
        this.loadsHorizYService.modifyLoadType(viewPosition, newType).then((response) => {
            this.getSupplementalVPData(viewPosition, gridIndex);
        });
    }

    modifyLoadTypeLateral(viewPosition, newType) {
        var gridIndex = this.findGridIndexLateral(viewPosition);
        this.gridDataLateral[gridIndex].pending = true;
        this.loadsHorizZService.modifyLoadType(viewPosition, newType).then((response) => {
            this.getSupplementalVPDataLateral(viewPosition, gridIndex);
        });
    }

    modifyLoadIsFullLength(viewPosition, isFullLength) {
        var gridIndex = this.findGridIndex(viewPosition);
        this.gridData[gridIndex].pending = true;
        this.loadsHorizYService.modifyLoadIsFullLength(viewPosition, isFullLength).then((response) => {
            this.getSupplementalVPData(viewPosition, gridIndex);
        });
    }

    modifyLoadStartLocation(event, viewPosition, newValue) {
        if (this.dirtyCheckService.isDirty(event)) {
            var gridIndex = this.findGridIndex(viewPosition);
            this.gridData[gridIndex].pending = true;
            this.loadsHorizYService.modifyLoadStartLocation(viewPosition, newValue).then((response) => {
                this.getSupplementalVPData(viewPosition, gridIndex);
            });
        }
    }
    modifyLoadEndLocation(event, viewPosition, newValue) {
        if (this.dirtyCheckService.isDirty(event)) {
            var gridIndex = this.findGridIndex(viewPosition);
            this.gridData[gridIndex].pending = true;
            this.loadsHorizYService.modifyLoadEndLocation(viewPosition, newValue).then((response) => {
                this.getSupplementalVPData(viewPosition, gridIndex);
            });
        }
    }
    modifyTribWidthStart(event, viewPosition, newValue) {
        if (this.dirtyCheckService.isDirty(event)) {
            var gridIndex = this.findGridIndex(viewPosition);
            this.gridData[gridIndex].pending = true;
            this.loadsHorizYService.modifyTribWidthStart(viewPosition, newValue).then((response) => {
                this.getSupplementalVPData(viewPosition, gridIndex);
            });
        }
    }
    modifyTribWidthEnd(event, viewPosition, newValue) {
        if (this.dirtyCheckService.isDirty(event)) {
            var gridIndex = this.findGridIndex(viewPosition);
            this.gridData[gridIndex].pending = true;
            this.loadsHorizYService.modifyTribWidthEnd(viewPosition, newValue).then((response) => {
                this.getSupplementalVPData(viewPosition, gridIndex);
            });
        }
    }

    modifyTribWidthLateral(event, viewPosition, newValue) {
        if (this.dirtyCheckService.isDirty(event)) {
            var gridIndex = this.findGridIndexLateral(viewPosition);
            this.gridDataLateral[gridIndex].pending = true;
            this.loadsHorizZService.modifyTribWidth(viewPosition, newValue).then((response) => {
                this.getSupplementalVPDataLateral(viewPosition, gridIndex);
            });
        }
    }

    _roundString(value, decimals) {
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
    }

    modifyRoofSlope(event, viewPosition, newValue) {
        if (this.dirtyCheckService.isDirty(event)) {
            var gridIndex = this.findGridIndex(viewPosition);
            this.gridData[gridIndex].pending = true;

            if (newValue.indexOf('/12') > -1) {
                newValue = newValue.substring(0, newValue.length - 3);
            }
            this.loadsHorizYService.modifyRoofSlope(viewPosition, this._roundString(newValue, 2)).then((response) => {
                this.getSupplementalVPData(viewPosition, gridIndex);
            });
        }
    }
    loadCommentChanged(event, viewPosition, newValue) {
        if (this.dirtyCheckService.isDirty(event) && this.commentdialogcalled === false) {
            var gridIndex = this.findGridIndex(viewPosition);
            this.gridData[gridIndex].pending = true;
            this.loadsHorizYService.modifyLoadComment(viewPosition, newValue)
                .then(() => {
                    //var gridIndex = findGridIndex(viewPosition);
                    this.getSupplementalVPData(viewPosition, gridIndex);
                });
            //deselectAll();
        }
    }

    loadCommentChangedLateral(event, viewPosition, newValue) {
        if (this.dirtyCheckService.isDirty(event) && this.commentdialogcalled === false) {
            var gridIndex = this.findGridIndexLateral(viewPosition);
            this.gridDataLateral[gridIndex].pending = true;
            this.loadsHorizZService.modifyLoadComment(viewPosition, newValue)
                .then(() => {
                    //var gridIndex = findGridIndex(viewPosition);
                    this.getSupplementalVPDataLateral(viewPosition, gridIndex);
                });
            //deselectAll();
        }
    }

    modifyLoadComment(viewPosition, loadComment) {
        this.commentdialogcalled = true;
        var modalInstance = this.$uibModal.open({
            animation: true,
            templateUrl: 'modifyCommentModal.html',
            controller: 'holesModifyCommentController',
            controllerAs: 'modifyCommentCtrl',
            windowClass: 'connectorDialog',
            backdrop: 'static',
            size: 'md',
            resolve: {
                items: {
                    comment: loadComment
                }
            }
        });

        var gridIndex = this.findGridIndex(viewPosition);
        modalInstance.result.then((newComment) => {
            this.gridData[gridIndex].pending = true;
            this.loadsHorizYService.modifyLoadComment(viewPosition, newComment).then(() => {
                this.getSupplementalVPData(viewPosition, gridIndex);
                this.commentdialogcalled = false;
            });
        }, () => {
            this.gridData[gridIndex].pending = true;
            this.getSupplementalVPData(viewPosition, gridIndex);
            this.commentdialogcalled = false;
        })
        .finally(() => {});

    }

    modifyLoadCommentLateral(viewPosition, loadComment) {
        this.commentdialogcalled = true;
        var modalInstance = this.$uibModal.open({
            animation: true,
            templateUrl: 'modifyCommentModal.html',
            controller: 'holesModifyCommentController',
            controllerAs: 'modifyCommentCtrl',
            windowClass: 'connectorDialog',
            backdrop: 'static',
            size: 'md',
            resolve: {
                items: {
                    comment: loadComment
                }
            }
        });

        var gridIndex = this.findGridIndexLateral(viewPosition);
        modalInstance.result.then((newComment) => {
            this.gridDataLateral[gridIndex].pending = true;
            this.loadsHorizZService.modifyLoadComment(viewPosition, newComment).then(() => {
                this.getSupplementalVPDataLateral(viewPosition, gridIndex);
                this.commentdialogcalled = false;
            });
        }, () => {
            this.gridDataLateral[gridIndex].pending = true;
            this.getSupplementalVPDataLateral(viewPosition, gridIndex);
            this.commentdialogcalled = false;
        })
        .finally(() => {});

    }

    modifyDeadLoadStartMagnitude(event, viewPosition, newValue) {
        if (this.dirtyCheckService.isDirty(event)) {
            var gridIndex = this.findGridIndex(viewPosition);
            this.gridData[gridIndex].pending = true;
            this.loadsHorizYService.modifyDeadLoadStartMagnitude(viewPosition, newValue).then((response) => {
                this.getSupplementalVPData(viewPosition, gridIndex);
            });
        }
    }
    modifyDeadLoadEndMagnitude(event, viewPosition, newValue) {
        if (this.dirtyCheckService.isDirty(event)) {
            var gridIndex = this.findGridIndex(viewPosition);
            this.gridData[gridIndex].pending = true;
            this.loadsHorizYService.modifyDeadLoadEndMagnitude(viewPosition, newValue).then((response) => {
                this.getSupplementalVPData(viewPosition, gridIndex);
            });
        }
    }
    modifyFloorLoadStartMagnitude(event, viewPosition, newValue) {
        if (this.dirtyCheckService.isDirty(event)) {
            var gridIndex = this.findGridIndex(viewPosition);
            this.gridData[gridIndex].pending = true;
            this.loadsHorizYService.modifyFloorLoadStartMagnitude(viewPosition, newValue).then((response) => {
                this.getSupplementalVPData(viewPosition, gridIndex);
            });
        }
    }
    modifyFloorLoadEndMagnitude(event, viewPosition, newValue) {
        if (this.dirtyCheckService.isDirty(event)) {
            var gridIndex = this.findGridIndex(viewPosition);
            this.gridData[gridIndex].pending = true;
            this.loadsHorizYService.modifyFloorLoadEndMagnitude(viewPosition, newValue).then((response) => {
                this.getSupplementalVPData(viewPosition, gridIndex);
            });
        }
    }
    modifyRoofLoadStartMagnitude(event, viewPosition, newValue) {
        if (this.dirtyCheckService.isDirty(event)) {
            var gridIndex = this.findGridIndex(viewPosition);
            this.gridData[gridIndex].pending = true;
            this.loadsHorizYService.modifyRoofLoadStartMagnitude(viewPosition, newValue).then((response) => {
                this.getSupplementalVPData(viewPosition, gridIndex);
            });
        }
    }

    modifyRoofLoadEndMagnitude(event, viewPosition, newValue) {
        if (this.dirtyCheckService.isDirty(event)) {
            var gridIndex = this.findGridIndex(viewPosition);
            this.gridData[gridIndex].pending = true;
            this.loadsHorizYService.modifyRoofLoadEndMagnitude(viewPosition, newValue).then((response) => {
                this.getSupplementalVPData(viewPosition, gridIndex);
            });
        }
    }
    modifySnowLoadStartMagnitude(event, viewPosition, newValue) {
        if (this.dirtyCheckService.isDirty(event)) {
            var gridIndex = this.findGridIndex(viewPosition);
            this.gridData[gridIndex].pending = true;
            this.loadsHorizYService.modifySnowLoadStartMagnitude(viewPosition, newValue).then((response) => {
                this.getSupplementalVPData(viewPosition, gridIndex);
            });
        }
    }
    modifySnowLoadEndMagnitude(event, viewPosition, newValue) {
        if (this.dirtyCheckService.isDirty(event)) {
            var gridIndex = this.findGridIndex(viewPosition);
            this.gridData[gridIndex].pending = true;
            this.loadsHorizYService.modifySnowLoadEndMagnitude(viewPosition, newValue).then((response) => {
                this.getSupplementalVPData(viewPosition, gridIndex);
            });
        }
    }
    modifyWindLoadStartMagnitude(event, viewPosition, newValue) {
        if (this.dirtyCheckService.isDirty(event)) {
            var gridIndex = this.findGridIndex(viewPosition);
            this.gridData[gridIndex].pending = true;
            this.loadsHorizYService.modifyWindLoadStartMagnitude(viewPosition, newValue).then((response) => {
                this.getSupplementalVPData(viewPosition, gridIndex);
            });
        }
    }
    modifyWindLoadEndMagnitude(event, viewPosition, newValue) {
        if (this.dirtyCheckService.isDirty(event)) {
            var gridIndex = this.findGridIndex(viewPosition);
            this.gridData[gridIndex].pending = true;
            this.loadsHorizYService.modifyWindLoadEndMagnitude(viewPosition, newValue).then((response) => {
                this.getSupplementalVPData(viewPosition, gridIndex);
            });
        }
    }

    modifyWindLoadMagnitudeLateral(event, viewPosition, newValue) {
        if (this.dirtyCheckService.isDirty(event)) {
            var gridIndex = this.findGridIndexLateral(viewPosition);
            this.gridDataLateral[gridIndex].pending = true;
            this.loadsHorizZService.modifyWindLoadMagnitude(viewPosition, newValue).then((response) => {
                this.getSupplementalVPDataLateral(viewPosition, gridIndex);
            });
        }
    }

    modifySeismicLoadStartMagnitude(event, viewPosition, newValue) {
        if (this.dirtyCheckService.isDirty(event)) {
            var gridIndex = this.findGridIndex(viewPosition);
            this.gridData[gridIndex].pending = true;
            this.loadsHorizYService.modifySeismicLoadStartMagnitude(viewPosition, newValue).then((response) => {
                this.getSupplementalVPData(viewPosition, gridIndex);
            });
        }
    }
    modifySeismicLoadEndMagnitude(event, viewPosition, newValue) {
        if (this.dirtyCheckService.isDirty(event)) {
            var gridIndex = this.findGridIndex(viewPosition);
            this.gridData[gridIndex].pending = true;
            this.loadsHorizYService.modifySeismicLoadEndMagnitude(viewPosition, newValue).then((response) => {
                this.getSupplementalVPData(viewPosition, gridIndex);
            });
        }
    }

    modifyLoadCombine(viewPosition, newValue) {
        var gridIndex = this.findGridIndex(viewPosition);
        this.gridData[gridIndex].pending = true;
        this.loadsHorizYService.modifyCombine(viewPosition, newValue).then((response) => {
            this.getSupplementalVPData(viewPosition, gridIndex);
        });
    }

    hideLoadsDropDown(event) {
        this.moreOptionsListVisible = false;
        if (event) {
            event.stopPropagation();
        }
    }

    _getOffset(element) {
        var boundingClientRect = element[0].getBoundingClientRect();

        return {
            width: element.prop('offsetWidth'),
            height: element.prop('offsetHeight'),
            top: boundingClientRect.top + (this.$window.pageYOffset || this.$document[0].body.scrollTop || this.$document[0].documentElement.scrollTop),
            left: boundingClientRect.left + (this.$window.pageXOffset || this.$document[0].body.scrollLeft || this.$document[0].documentElement.scrollLeft)
        };
    }

    keyDown(isPending, $event) {
        //ignore everything accept navigation input if the fidle is "pending"
        if (isPending) {
            if (!($event.keyCode === 9 || $event.keyCode === 16)) { //tab and shift keycodes
                $event.stopPropagation();
                $event.preventDefault();
                console.log('stopped: ' + $event.keyCode);
                return false;
            }
        }
    }

    inputGotFocus(rowIndex, columnIndex) {
        this.selectedRowNumber = rowIndex;
        this.selectedColumnNumber = columnIndex;
    }

    selectAllTextOnFocus($event, rowIndex, columnIndex) {
        $event.target.select();
        this.inputGotFocus(rowIndex, columnIndex);
    }

    getLDFValue(key) {
        var string;
        var data = this.loadsHorizYService.getJobSettingsData();
        if (data.BuildingCode === 18 || data.BuildingCode === 23 || data.BuildingCode === 24) {
            string = '';
        }
        else {
            switch (key) {
                case 'dead':
                    string = this.ldfData[0];
                    break;
                case 'floorLive':
                case 'floorCeilingLive':
                    string = this.ldfData[1];
                    break;
                case 'roofLive':
                    string = this.ldfData[2];
                    break;
                case 'seismic':
                    string = this.ldfData[3];
                    break;
                case 'snow':
                    string = this.ldfData[4];
                    break;
                case 'wind':
                    string = this.ldfData[5];
                    break;
                default:
                    string = '';
            }
        }

        return string;
    }

    getJobSettingsData() {
        var data = this.loadsHorizYService.getJobSettingsData();
        var digits = 0;
        var string = data.LDFDead.toString();
        this.ldfData[0] = data.LDFDead.toString();
        digits = this.sigfig(digits, this.ldfData[0].length);
        this.ldfData[1] = data.LDFFloor.toString();
        digits = this.sigfig(digits, this.ldfData[1].length);
        this.ldfData[2] = data.LDFRoof.toString();
        digits = this.sigfig(digits, this.ldfData[2].length);
        this.ldfData[3] = data.LDFSeismic.toString();
        digits = this.sigfig(digits, this.ldfData[3].length);
        this.ldfData[4] = data.LDFSnow.toString();
        digits = this.sigfig(digits, this.ldfData[4].length);
        this.ldfData[5] = data.LDFWind.toString();
        digits = this.sigfig(digits, this.ldfData[5].length);

        for (var i = 0; i < this.ldfData.length; ++i) {
            if (this.ldfData[i].length === 1) {
                this.ldfData[i] = this.ldfData[i] + '.';
            }
            if (this.ldfData[i].length < digits) {
                var difference = digits - this.ldfData[i].length;
                for (var d = 0; d < difference; ++d) {
                    this.ldfData[i] = this.ldfData[i] + '0';
                }
            }
            this.ldfData[i] = '(' + this.ldfData[i] + ')';
        }
    }

    sigfig(curentdigits, newvalue) {
        if (newvalue > curentdigits) {
            curentdigits = newvalue;
        }
        return curentdigits;
    }

    //original array was indexed based on view position
    //this doesnt work once we start deleting loads from the grid.
    //so we reindex and then search for the specified VP to find the array index of the matching load.
    findGridIndex(viewPosition) {
        for (var i = 0; i < this.gridData.length; i++) {
            if (this.gridData[i].viewPosition === viewPosition) {
                return i;
            }
        }
    }

    findGridIndexLateral(viewPosition) {
        for (var i = 0; i < this.gridDataLateral.length; i++) {
            if (this.gridDataLateral[i].viewPosition === viewPosition) {
                return i;
            }
        }
    }

    scrollToElement(elementId) {
        this.$location.hash(elementId);
        this.$anchorScroll();
    }
}
