import ngAuthSettings from '../environment';
export default class teamService {
    constructor(common, postService) {
        'ngInject';

        this.$q = common.$q();
        this.postService = postService;

    }
    removeTeamMember(teamId, userId) {
        var data = {
            teamId: teamId,
            userId: userId
        };
        return this._requestSimplePostEvent('RemoveTeamMember', data);
    }

    setUserIsAdmin(teamId, userId, value) {
        var data = {
            teamId: teamId,
            userId: userId,
            value: value
        };
        return this._requestSimplePostEvent('setIsAdministrator', data);
    }

    createTeam(teamName) {
        var data = {
            teamName: teamName
        };
        return this._requestSimplePostEvent('CreateTeam', data);
    }

    inviteMemberToTeam(teamId, teamName, email) {
        var data = {
            teamId: teamId,
            teamName: teamName.Name,
            email: email
        };
        return this._requestSimplePostEvent('JoinTeam', data);
    }

    getAllTeamsForUser() {
        return this.postService.requestGetEvent('getAllTeamsForUser', 'api/Team/');
    }
    getTeam(teamId) {
        var data = {
            teamId: teamId
        };
        return this._requestSimplePostEvent('GetTeam', data);
    }

    renameTeam(teamId, newName) {
        var data = {
            teamId: teamId,
            newTeamName: newName
        };
        return this._requestSimplePostEvent('RenameTeam', data);
    }

    _requestSimplePostEvent(event, data) {
        var path = 'api/Team/' + event;
        return this.postService.requestSimplePost(path,data);
    }
}
// (function () {
//     'use strict';
//     var app = angular.module('app');
//     var serviceId = 'teamService';
//     app.factory(serviceId, ['$http', 'ngAuthSettings', 'common', 'allModelDataService', 'postService', teamService]);

//     function teamService($http, ngAuthSettings, common, allModelDataService, postService) {
//         var $q = common.$q;
//         var serviceBase = ngAuthSettings.apiServiceBaseUri;
//         //this is the declaration of the public methods that will be accessible
//         var service = {
//             createTeam: createTeam,
//             getTeam: getTeam,
//             renameTeam: renameTeam,
//             getAllTeamsForUser: getAllTeamsForUser,
//             inviteMemberToTeam: inviteMemberToTeam,
//             setUserIsAdmin: setUserIsAdmin,
//             removeTeamMember: removeTeamMember
//         };

//         function removeTeamMember(teamId, userId) {
//             var data = {
//                 teamId: teamId,
//                 userId: userId
//             };
//             return _requestSimplePostEvent('RemoveTeamMember', data);
//         }

//         function setUserIsAdmin(teamId, userId, value) {
//             var data = {
//                 teamId: teamId,
//                 userId: userId,
//                 value: value
//             };
//             return _requestSimplePostEvent('setIsAdministrator', data);
//         }

//         function createTeam(teamName) {
//             var data = {
//                 teamName: teamName
//             };
//             return _requestSimplePostEvent('CreateTeam', data);
//         }

//         function inviteMemberToTeam(teamId, teamName, email) {
//             var data = {
//                 teamId: teamId,
//                 teamName: teamName.Name,
//                 email: email
//             };
//             return _requestSimplePostEvent('JoinTeam', data);
//         }

//         function getAllTeamsForUser() {
//             return postService.requestGetEvent('getAllTeamsForUser', 'api/Team/');
//         }
//         function getTeam(teamId) {
//             var data = {
//                 teamId: teamId
//             };
//             return _requestSimplePostEvent('GetTeam', data);
//         }

//         function renameTeam(teamId, newName) {
//             var data = {
//                 teamId: teamId,
//                 newTeamName: newName
//             };
//             return _requestSimplePostEvent('RenameTeam', data);
//         }

//         function _requestSimplePostEvent(event, data) {
//             var path = 'api/Team/' + event;
//             return postService.requestSimplePost(path,data);
//         }

//         return service;
//     }
// }());
