import moment from 'moment';

export default class forteCookieService {
    constructor($http, $cookies, resourceMessagingService) {
        'ngInject';

        this.$http = $http;
        this.$cookies = $cookies;
        this.resourceMessagingService = resourceMessagingService;

        this.isGraphicVisibleCookieID = 'isGraphicVisibleCookie';

        this.enumEnglishUS = '0';
        this.enumFrenchCanadian = '1';
        this.materialAvailabilityEnabled = 'materialAvailabilityEnabled';
        this.suggestedSolutionsEnabled = 'suggestedSolutionsEnabled';
        this.factoredTitleEnabledTag = 'factoredTitleEnabled';
        this.constructionDetailImageSettingMemberReportTag =
        'constructionDetailImageSettingMemberReport';
        this.constructionDetailImageSettingJobSummaryTag = 'constructionDetailImageSettingJobSummary';
        this.utilizationPercentageEnabledTag = 'utilizationPercentageEnabled';
        this.forteWebCultureID = 'forteWebCultureCode';
        this.currentlyOpenedFileId = 'currentlyOpenedFileId';
        this.videoTourVersion = 'mobileVideoTourVersion';
        this.openToFileManagerOnLogin = 'openToFileManagerOnLogin';

        let tempDate = new Date();
        this.cookieExpirationDate = moment(tempDate).add(1, 'year').toDate();
    }

    getSuggestedSolutionsEnabled() {
        let cookie = this.$cookies.getObject(this.suggestedSolutionsEnabled);
        if (cookie === undefined || cookie === null) {
            cookie = true;
            this.writeSuggestedSolutionsEnabled(cookie);
        }
        return cookie;
    }
    writeSuggestedSolutionsEnabled(value) {
        this.$cookies.putObject(this.suggestedSolutionsEnabled, value, {'expires': this.cookieExpirationDate, 'samesite': 'strict'});
    }

    getMaterialAvailabilityEnabled() {
        let cookieVersion = this.$cookies.getObject(this.materialAvailabilityEnabled);
        if (cookieVersion === undefined || cookieVersion === null) {
            cookieVersion = true;
            this.writeMaterialAvailabilityEnabled(cookieVersion);
        }
        return cookieVersion;
    }
    writeMaterialAvailabilityEnabled(value) {
        this.$cookies.putObject(this.materialAvailabilityEnabled, value, {'expires': this.cookieExpirationDate, 'samesite': 'strict'});
    }

    getFactoredTitleEnabled() {
        let cookieVersion = this.$cookies.getObject(this.factoredTitleEnabledTag);
        if (cookieVersion === undefined || cookieVersion === null) {
            cookieVersion = true;
            this.writeFactoredTitleEnabled(cookieVersion);
        }
        return cookieVersion;
    }
    writeFactoredTitleEnabled(value) {
        this.$cookies.putObject(this.factoredTitleEnabledTag, value, {'expires': this.cookieExpirationDate, 'samesite': 'strict'});
    }

    getConstructionDetailImageSettingMemberReport() {
        let cookieVersion = this.$cookies.getObject(this.constructionDetailImageSettingMemberReportTag);
        if (cookieVersion === undefined || cookieVersion === null) {
            cookieVersion = 3; //ConstructionDetailImageSettingType.afterEachReport
            this.writeConstructionDetailImageSettingMemberReport(cookieVersion);
        }
        return cookieVersion;
    }
    writeConstructionDetailImageSettingMemberReport(value) {
        this.$cookies.putObject(this.constructionDetailImageSettingMemberReportTag, value, {'expires': this.cookieExpirationDate, 'samesite': 'strict'});
    }

    getConstructionDetailImageSettingJobSummary() {
        let cookieVersion = this.$cookies.getObject(this.constructionDetailImageSettingJobSummaryTag);
        if (cookieVersion === undefined || cookieVersion === null) {
            cookieVersion = 1; //ConstructionDetailImageSettingType.summaryCoverPage
            this.writeConstructionDetailImageSettingJobSummary(cookieVersion);
        }
        return cookieVersion;
    }
    writeConstructionDetailImageSettingJobSummary(value) {
        this.$cookies.putObject(this.constructionDetailImageSettingJobSummaryTag, value, {'expires': this.cookieExpirationDate, 'samesite': 'strict'});
    }

    getUtilizationPercentageEnabled() {
        let cookieVersion = this.$cookies.getObject(this.utilizationPercentageEnabledTag);
        if (cookieVersion === undefined || cookieVersion === null) {
            cookieVersion = true;
            this.writeUtilizationPercentageEnabled(cookieVersion);
        }
        return cookieVersion;
    }
    writeUtilizationPercentageEnabled(value) {
        this.$cookies.putObject(this.utilizationPercentageEnabledTag, value, {'expires': this.cookieExpirationDate, 'samesite': 'strict'});
    }

    getOpenToFileManagerOnLogin() {
        let cookieOpenToFileManagerOnLogin = this.$cookies.getObject(this.openToFileManagerOnLogin);
        if (cookieOpenToFileManagerOnLogin === undefined || cookieOpenToFileManagerOnLogin === null) {
            cookieOpenToFileManagerOnLogin = false;
            this.writeVideoTourVersion(cookieOpenToFileManagerOnLogin);
        }
        return cookieOpenToFileManagerOnLogin;
    }
    writeOpenToFileManagerOnLogin(value) {
        this.$cookies.putObject(this.openToFileManagerOnLogin, value, {'expires': this.cookieExpirationDate, 'samesite': 'strict'});
    }

    getVideoTourVersion() {
        let cookieVersion = this.$cookies.getObject(this.videoTourVersion);
        if (cookieVersion === undefined || cookieVersion === null) {
            cookieVersion = 0;
            this.writeVideoTourVersion(cookieVersion);
        }
        return cookieVersion;
    }
    writeVideoTourVersion(value) {
        this.$cookies.putObject(this.videoTourVersion, value, {'expires': this.cookieExpirationDate, 'samesite': 'strict'});
    }
    getCurrentlyOpenedFileId() {
        return this.$cookies.getObject(this.currentlyOpenedFileId);
    }
    writeCurrentlyOpenedFileId(value) {
        this.$cookies.putObject(this.currentlyOpenedFileId, value, {'expires': this.cookieExpirationDate, 'samesite': 'strict'});
    }

    getIsGraphicVisible() {
        return this.$cookies.getObject(this.isGraphicVisibleCookieID);
    }

    writeIsGraphicVisible(value) {
        this.$cookies.putObject(this.isGraphicVisibleCookieID, value, {'expires': this.cookieExpirationDate, 'samesite': 'strict'});
    }

    setCultureFromCookie() {
        //Set Culture for Client and API processing
        let forteWebCultureCookie = this.$cookies.getObject(this.forteWebCultureID);
        if (!forteWebCultureCookie) {
            // Cookie does not exist - set to English
            // (FOR NOW: Read default culture from API ??? or Browser ???)
            this.setForteWebCultureEnglishUS();
        }
        else {
            this.setCookieAndHeader(forteWebCultureCookie);
        }
    }

    setForteWebCultureEnglishUS() {
        this.setCookieAndHeader(this.enumEnglishUS);
    }

    setForteWebCultureFrenchCA() {
        this.setCookieAndHeader(this.enumFrenchCanadian);
    }

    setCookieAndHeader(forteWebCultureCookie) {
        this.$cookies.putObject(this.forteWebCultureID, forteWebCultureCookie, {'expires': this.cookieExpirationDate, 'samesite': 'strict'});
        this.$http.defaults.headers.common['Accept-Language'] = forteWebCultureCookie;
        this.resourceMessagingService.setCultureFromCookie(forteWebCultureCookie);
    }
}
