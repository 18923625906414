import addMemberTemplate from '../../modals/addTeamMember/addTeamMember.html';
import preventSelfModification from '../../modals/removeAdministrator/preventSelfModification.html';
import removeUserConfirmTemplate from '../../modals/removeAdministrator/removeUser.html';
export default class teamManagementController {
    constructor($scope, $uibModal, $templateCache, $state, $stateParams, $timeout, resourceMessagingService, teamService, mobileNavMenu) {
        'ngInject';

        this.$scope = $scope;
        this.$uibModal = $uibModal;
        this.$templateCache = $templateCache;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$timeout = $timeout;
        this.resourceMessagingService = resourceMessagingService;
        this.teamService = teamService;
        this.mobileNavMenu = mobileNavMenu;

        // this.showAddMemberModal = this.showAddMemberModal();
        // this.removeUserFromTeam = this.removeUserFromTeam();
        // this.setUserIsAdmin = this.setUserIsAdmin();
        // this.editTeamNameClicked = this.editTeamNameClicked();
        // this.renameTeamTextboxClicked = this.renameTeamTextboxClicked();
        // this.renameTeam = this.renameTeam();

        this.editingTeamName = '';
        this.originalTeamName = '';
        this.tempTeamName = '';

        this.userIsAdmin;
        this.userCanLeave;

        this.activate();
    }

    activate() {
        this.teamId = this.$stateParams.teamId;
        this.editingTeamName = false;
        this._refreshView();
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    openMemberJobNav() {
        this.mobileNavMenu.openMemberJobNav();
    }

    _refreshView() {
        this.userIsAdmin = false;
        this.userCanLeave = false;
        this.getTeam();
    }

    editTeamNameClicked() {
        this.editingTeamName = true;
        this.originalTeamName = this.team.Name;
        this.tempTeamName = this.team.Name;

        this.$timeout(() => {
            var elementToSelectText = document.getElementById('teamRenameTextBox');
            elementToSelectText.select();
        },0,false);
    }

    removeUserFromTeam(userId) {
        //console.log(user);
        //showRemoveLastAdminModal();
        this.showRemoveUserConfirmation(userId);
    }

    setUserIsAdmin(userId, isAdmin) {
        this.teamService.setUserIsAdmin(this.team.TeamId, userId, isAdmin).then(
            (response) => {
                //console.log('success');
                this._refreshView();
            },
            (error) => {
                //console.log(error);
            }
        );
    }

    getTeam() {
        this.teamService.getTeam(this.teamId).then(
            (response) => {
                //console.log('get team worked');
                this.team = response.Team;
                this.thisTeamUser = response.TeamUser;
                this.isCurrentUserAdmin();
            },
            (error) => {
                //console.log('get team failed:' + error')
            }
        );
    }

    renameTeam() {
        this.editingTeamName = false;
        this.team.Name = this.tempTeamName;
        this.teamService.renameTeam(this.teamId, this.tempTeamName).then((response) => {
            this.$scope.$emit('teamsUpdated');
            this._refreshView();
        },
            (error) => {
                this.team.Name = this.originalTeamName;
                // console.log('rename team failed:' + error);
            }
        );
    }

    renameTeamTextboxClicked(event) {
        event.stopPropagation();
    }

    isCurrentUserAdmin() {
        for (var i = 0; i < this.team.Administrators.length; i++) {
            var current = this.team.Administrators[i];
            if (current.Id === this.thisTeamUser.AppUserId) {
                this.userIsAdmin = true;
                if (this.team.Administrators.length > 1) {
                    this.userCanLeave = true;
                }
            } else {
                this.userCanLeave = true;
            }
        }
    }

    showAddMemberModal() {
        var modalInstance = this.$uibModal.open({
            animation: true,
            template: addMemberTemplate,
            controller: 'addTeamMemberController',
            controllerAs: 'addTMCtrl',
            backdrop: 'static'
        });

        modalInstance.result.then((result) => {
            //refresh the team list
            this.teamService.inviteMemberToTeam(this.teamId, this.team, result).then(
                (response) => {
                    this.getTeam();
                },
                (error) => {
                    //console.log(error);
                }
            );
        }, (error) => {
            //console.log(error);
        });
    }

    showRemoveUserConfirmation(userId) {
        var modalInstance = this.$uibModal.open({
            animation: true,
            template: removeUserConfirmTemplate,
            controller: 'simpleGetTextController',
            controllerAs: 'simpleGTCtrl',
            backdrop: 'static'
        });

        modalInstance.result.then((result) => {
            //refresh the team list
            if (result === true) {
                this.teamService.removeTeamMember(this.teamId, userId).then(
                    (response) => {
                        if (userId === this.thisTeamUser.AppUserId)
                        {
                            //redirect to my files.
                            this.$state.go('application.manage.files', {}, {reload: true});
                        }
                        else {
                            this._refreshView();
                        }
                    },
                    (error) => {
                        //console.log(error);
                    }
                );
            }
        }, (error) => {
            //console.log(error);
        });
    }

    showNoSelfModificationModal() {
        var modalInstance = this.$uibModal.open({
            animation: true,
            template: preventSelfModification,
            controller: 'simpleGetTextController',
            controllerAs: 'simpleGTCtrl',
            backdrop: 'static'
        });

        modalInstance.result.then((result) => {
            //refresh the team list
            console.log(result);
        }, (error) => {
            //console.log(error);
        });
    }
}
// (function () {
//     'use strict';

//     var controllerId = 'teamManagementController';
//     angular.module('app').controller(controllerId,
//         ['$scope', '$rootScope', '$uibModal', '$templateCache', '$state', '$stateParams', '$timeout', 'common',
//             'resourceMessagingService', 'teamService',  teamManagementController]);
//     //eslint-disable-next-line max-params
//     function teamManagementController($scope, $rootScope, $uibModal, $templateCache, $state,
//             $stateParams, $timeout, common,resourceMessagingService, teamService) {
//         /* jshint validthis: true */
//         var vm = this;

//         var addMemberTemplate = $templateCache.get('app/loggedIn/modals/addTeamMember/addTeamMember.html');
//         var preventSelfModification = $templateCache.get('app/loggedIn/modals/removeAdministrator/preventSelfModification.html');
//         var removeUserConfirmTemplate = $templateCache.get('app/loggedIn/modals/removeAdministrator/removeUser.html');

//         vm.showAddMemberModal = showAddMemberModal;
//         vm.getText = resourceMessagingService.getText;
//         vm.removeUserFromTeam = removeUserFromTeam;
//         vm.setUserIsAdmin = setUserIsAdmin;

//         vm.editTeamNameClicked = editTeamNameClicked;
//         vm.renameTeamTextboxClicked = renameTeamTextboxClicked;
//         vm.renameTeam = renameTeam;
//         vm.editingTeamName;
//         vm.originalTeamName;
//         vm.tempTeamName;

//         vm.userIsAdmin;
//         vm.userCanLeave;
//         function activate() {
//             vm.teamId = $stateParams.teamId;
//             vm.editingTeamName = false;
//             _refreshView();
//         }

//         function _refreshView() {
//             vm.userIsAdmin = false;
//             vm.userCanLeave = false;
//             getTeam();
//         }

//         function editTeamNameClicked() {
//             vm.editingTeamName = true;
//             vm.originalTeamName = vm.team.Name;
//             vm.tempTeamName = vm.team.Name;

//             $timeout(function() {
//                 var elementToSelectText = document.getElementById('teamRenameTextBox');
//                 elementToSelectText.select();
//             },0,false);
//         }

//         function removeUserFromTeam(userId) {
//             //console.log(user);
//             //showRemoveLastAdminModal();
//             showRemoveUserConfirmation(userId);
//         }

//         function setUserIsAdmin(userId, isAdmin) {
//             teamService.setUserIsAdmin(vm.team.TeamId, userId, isAdmin).then(
//                 function success(response) {
//                     //console.log('success');
//                     _refreshView();
//                 },
//                 function failure(error) {
//                     //console.log(error);
//                 }
//             );
//         }

//         function getTeam() {
//             teamService.getTeam(vm.teamId).then(
//                 function success(response) {
//                     //console.log('get team worked');
//                     vm.team = response.Team;
//                     vm.thisTeamUser = response.TeamUser;
//                     isCurrentUserAdmin();
//                 },
//                 function failure(error) {
//                     //console.log('get team failed:' + error')
//                 }
//             );
//         }

//         function renameTeam() {
//             vm.editingTeamName = false;
//             vm.team.Name = vm.tempTeamName;
//             teamService.renameTeam(vm.teamId, vm.tempTeamName).then(
//                 function success (response) {
//                     $scope.$emit('teamsUpdated');
//                     _refreshView();
//                 },
//                 function failure (error) {
//                     vm.team.Name = vm.originalTeamName;
//                     // console.log('rename team failed:' + error);
//                 }
//             );
//         }

//         function renameTeamTextboxClicked(event) {
//             event.stopPropagation();
//         }

//         function isCurrentUserAdmin() {
//             for (var i = 0; i < vm.team.Administrators.length; i++) {
//                 var current = vm.team.Administrators[i];
//                 if (current.Id === vm.thisTeamUser.AppUserId) {
//                     vm.userIsAdmin = true;
//                     if (vm.team.Administrators.length > 1) {
//                         vm.userCanLeave = true;
//                     }
//                 } else {
//                     vm.userCanLeave = true;
//                 }
//             }
//         }

//         function showAddMemberModal() {
//             var modalInstance = $uibModal.open({
//                 animation: true,
//                 template: addMemberTemplate,
//                 controller: 'addTeamMemberController',
//                 backdrop: 'static'
//             });

//             modalInstance.result.then(function (result) {
//                 //refresh the team list
//                 teamService.inviteMemberToTeam(vm.teamId, vm.team, result).then(
//                     function success(response) {
//                         getTeam();
//                     },
//                     function failure(error) {
//                         //console.log(error);
//                     }
//                 );
//             }, function (error) {
//                 //console.log(error);
//             });
//         }

//         function showRemoveUserConfirmation(userId) {
//             var modalInstance = $uibModal.open({
//                 animation: true,
//                 template: removeUserConfirmTemplate,
//                 controller: 'simpleGetTextController',
//                 backdrop: 'static'
//             });

//             modalInstance.result.then(function (result) {
//                 //refresh the team list
//                 if (result === true) {
//                     teamService.removeTeamMember(vm.teamId, userId).then(
//                         function success(response) {
//                             if (userId === vm.thisTeamUser.AppUserId)
//                             {
//                                 //redirect to my files.
//                                 $state.go('application.manage.files', {}, {reload: true});
//                             }
//                             else {
//                                 _refreshView();
//                             }
//                         },
//                         function failure(error) {
//                             //console.log(error);
//                         }
//                     );
//                 }
//             }, function (error) {
//                 //console.log(error);
//             });
//         }

//         function showNoSelfModificationModal() {
//             var modalInstance = $uibModal.open({
//                 animation: true,
//                 template: preventSelfModification,
//                 controller: 'simpleGetTextController',
//                 backdrop: 'static'
//             });

//             modalInstance.result.then(function (result) {
//                 //refresh the team list
//                 console.log(result);
//             }, function (error) {
//                 //console.log(error);
//             });
//         }

//         activate();
//     };
// }());
