export default class heightAndSupportsService {

    constructor ($http, common, allModelDataService, postService) {
        'ngInject';

        this.$q = common.$q();
        this.$http = $http;
        this.allModelDataService = allModelDataService;
        this.postService = postService;
    }

    getMetaData() {
        var data = {};
        return this._requestPostEvent('GetHeightAndSupportsMetaData', data);
    }
    getData() {
        var data = {};
        return this._requestPostEvent('GetHeightAndSupportsData', data);
    }

    saveUserDefaults() {
        var currentPostResponseData = this.allModelDataService.getPostResponseData();
        return this._requestPostEvent('SaveHeightsAndSupportsAsDefaults', currentPostResponseData);
    }
    modifyWallHeight(newValue) {
        var data = {
            'value': newValue
        };
        return this._requestPostEvent('ModifyWallHeight', data);
    }

    modifyMaxULLength(newValue) {
        var data = {
            'value': newValue
        };
        return this._requestPostEvent('ModifyUnbracedLength', data);
    }

    modifyPlateSize(isTop, newSize) {
        var data = {
            'isTop': isTop,
            'propertyType': newSize
        };
        return this._requestPostEvent('ModifyPlateSize', data);
    }

    modifyBracing(newValue) {
        var data = {
            'propertyType': newValue
        };
        return this._requestPostEvent('ModifyBracing', data);
    }

    modifyComment(newValue) {
        var data = {
            'value': newValue
        };
        return this._requestPostEvent('ModifyULAxisComment', data);
    }

    modifyPlateMaterial(isTop, newMaterial) {
        var data = {
            'isTop': isTop,
            'propertyType': newMaterial
        };
        return this._requestPostEvent('ModifyPlateMaterial', data);
    }

    modifyPlateLateralConnector(isTop, newConnector) {
        var data = {
            'isTop': isTop,
            'propertyType': newConnector
        };
        return this._requestPostEvent('ModifyPlateConnectorType', data);
    }

    _requestPostEvent(event, data) {
        var path = 'api/HeightAndSupports/';
        return this.postService.requestPostEvent(event,data,path);
    }
}
// (function () {
//     'use strict';
//     var app = angular.module('app');
//     var serviceId = 'heightAndSupportsService';
//     app.factory(serviceId, ['$http', 'ngAuthSettings', 'common', 'allModelDataService', 'postService', heightAndSupportsService]);

//     function heightAndSupportsService($http, ngAuthSettings, common, allModelDataService, postService) {
//         var $q = common.$q;
//         //this is the declaration of the public methods that will be accessible
//         var service = {
//             getMetaData: getMetaData,
//             modifyPlateSize: modifyPlateSize,
//             modifyPlateMaterial: modifyPlateMaterial,
//             getData: getData,
//             modifyWallHeight: modifyWallHeight,
//             modifyMaxULLength: modifyMaxULLength,
//             modifyBracing: modifyBracing,
//             modifyComment: modifyComment,
//             saveUserDefaults: saveUserDefaults
//         };

//         function getMetaData() {
//             var data = {};
//             return _requestPostEvent('GetHeightAndSupportsMetaData', data);
//         }
//         function saveUserDefaults() {
//             var currentPostResponseData = allModelDataService.getPostResponseData();
//             return _requestPostEvent('SaveHeightsAndSupportsAsDefaults', currentPostResponseData);
//         }
//         function modifyWallHeight(newValue) {
//             var data = {
//                 'value': newValue
//             };
//             return _requestPostEvent('ModifyWallHeight', data);
//         }

//         function modifyMaxULLength(newValue) {
//             var data = {
//                 'value': newValue
//             };
//             return _requestPostEvent('ModifyUnbracedLength', data);
//         }

//         function modifyBracing(newValue) {
//             var data = {
//                 'propertyType': newValue
//             };
//             return _requestPostEvent('ModifyBracing', data);
//         }

//         function modifyComment(newValue) {
//             var data = {
//                 'value': newValue
//             };
//             return _requestPostEvent('ModifyULAxisComment', data);
//         }

//         function getData() {
//             var data = {};
//             return _requestPostEvent('GetHeightAndSupportsData', data);
//         }

//         function modifyPlateSize(isTop, newSize) {
//             var data = {
//                 'isTop': isTop,
//                 'propertyType': newSize
//             };
//             return _requestPostEvent('ModifyPlateSize', data);
//         }

//         function modifyPlateMaterial(isTop, newMaterial) {
//             var data = {
//                 'isTop': isTop,
//                 'propertyType': newMaterial
//             };
//             return _requestPostEvent('ModifyPlateMaterial', data);
//         }

//         function _requestPostEvent(event, data) {
//             var path = 'api/HeightAndSupports/';
//             return postService.requestPostEvent(event,data,path);
//         }

//         return service;
//     }
// }());
