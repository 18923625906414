export default class jobController {
    //eslint-disable-next-line max-params
    constructor($scope, $rootScope, $location, $state, $uibModal, $timeout, common, config,
        datacontext, authService, projectManagementService, spinner, Idle, Title, allModelDataService, forteCookieService,
        fileSystemService, smartNavService, postService, resourceMessagingService, notifier) {
        'ngInject';
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$location = $location;
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.$timeout = $timeout;
        this.common = common;
        this.config = config;
        this.datacontext = datacontext;
        this.authService = authService;
        this.projectManagementService = projectManagementService;
        this.spinner = spinner;
        this.Idle = Idle;
        this.Title = Title;
        this.allModelDataService = allModelDataService;
        this.forteCookieService = forteCookieService;
        this.fileSystemService = fileSystemService;
        this.smartNavService = smartNavService;
        this.postService = postService;
        this.resourceMessagingService = resourceMessagingService;
        this.notifier = notifier;

        this.controllerId = 'jobController';
        this.graphicCookieID = 'graphicCookie';

        this.$scope.started = false;
        this.checkAuthentication = () => {
            this.datacontext.checkAuthentication();
        };

        this.events = config.events;

        this.graphicId = 'main';
        this.zoomedGraphicId = 'zoomed';
        this.loadingComplete = false;
        this.isLandscapModal = false;
        this.isLargeMobile = false;
        this.isNormalModeChart = true;
        this.isGraphicVisibleInReport = false;
        this.showSplash = false;
        this.currentProject = null;
        this.showGraphicToggle = false;
        this.isGraphicVisible = false;
        this.showText = '';
        this.applyAnimateOutGraphicClass = false;
        this.graphicStepContent = 'spansAndSupports_graphicContent';

        this.memberTypeContent = this.getText('jobSettings_memberTypeContent') + '<ul><li>' + this.getText('jobSettings_memberTypeAction') + '</li></ul>';

        this.showAddMemberViewListener = $scope.$on('toggleAddMemberView', (event, data) => {
            this.toggleAddMemberView();
        });

        this.showSplashListener = $scope.$on('showSplashForDeleteJobNode', (event, data) => {
            this.showSplash = true;
        });

        this.hideSplashListener = $scope.$on('hideSplashForDeleteJobNode', (event, data) => {
            this.showSplash = false;
        });

        this.handleStateChangeStart = $scope.$on('stateChangeStart',
            (event, toState) => {
                if (!toState.data) {
                    this.showGraphicToggle = false;
                    //set the graphic visibility in the drawing service.
                    // this.drawingService.setIsGraphicVisible(forteCookieService.getIsGraphicVisible());
                } else {
                    this.showGraphicToggle = toState.data.showGraphicToggle;
                }
                if (toState.data) {
                    if (this.isGraphicVisible && !toState.data.showGraphicToggle) {
                        this.isGraphicVisible = false;
                        // this.drawingService.setIsGraphicVisible(this.isGraphicVisible);
                    }
                    if (!this.isGraphicVisible && toState.data.showGraphicToggle && this.forteCookieService.getIsGraphicVisible()) {
                        this.isGraphicVisible = true;
                        // this.drawingService.setIsGraphicVisible(this.isGraphicVisible);
                        this._broadcastRefreshGraphic('refreshGraphic_main');
                    }
                }
            }
        );

        this.$scope.$on('$destroy', () => {
            //this.updateEventListener();
            //this.loadNewEventListener();
            this.handleStateChangeStart();
            this.showAddMemberViewListener();
            this.showSplashListener();
            this.hideSplashListener();
        });

        $(window).resize(() => {
            var currentTab = this.allModelDataService.tryGetCurrentTab();
            if (currentTab === 9) //report
            {
                this.isGraphicVisibleInReport = true;
                // this.drawingService.setIsGraphicVisibleInReport(this.isGraphicVisibleInReport); //vm.isGraphicVisible);

            } else {
                this.isGraphicVisibleInReport = false;
                // this.drawingService.setIsGraphicVisibleInReport(this.isGraphicVisibleInReport); //vm.isGraphicVisible);

            }
            if (this.isNormalModeChart || this.isGraphicVisibleInReport) {
                //toggleGraphicResize();
            }
        });

        this.activate();
    }

    activate() {
        let fileIdToOpen = this.allModelDataService.getFileIdToOpen();
        if (fileIdToOpen !== null) {
            //open the file with the given id
            this._getProject(false, fileIdToOpen, true); //TODO: display a message if the user doesnt have permissions to this file.
        } else {
            let currentlyOpenedFileId = this.forteCookieService.getCurrentlyOpenedFileId();
            let openToFileManagementOnLogin = this.forteCookieService.getOpenToFileManagerOnLogin();
            if (openToFileManagementOnLogin === false && currentlyOpenedFileId !== null && currentlyOpenedFileId !== undefined) {
                //open the currently opened file id.
                this.allModelDataService.setFileIdToOpen(currentlyOpenedFileId);
                this._getProject(false, currentlyOpenedFileId, false); //TODO: return to file management if the user doesnt have permissions.
            } else {
                this.$state.go('application.manage.files');
            }
        }

        this.addMemberViewVisible = false;
        this.showWaitingSplash = false;
        this.showGraphicToggle = false;
        this.applyAnimateOutGraphicClass = false;

        // common.activateController([], controllerId);

        if (!this.forteCookieService.getIsGraphicVisible()) {
            this.isGraphicVisible = false;
            this.forteCookieService.writeIsGraphicVisible(false);
        } else {
            this.isGraphicVisible = true;
            this.forteCookieService.writeIsGraphicVisible(true);
        }
        this.forteCookieService.setCultureFromCookie();

        this.resetIsLargeMobileDevice();
    }

    resetIsLargeMobileDevice() {
        this.isLargeMobile = false;
        if (document.documentElement.clientHeight > 811 && document.documentElement.clientHeight < 813) {
            this.isLargeMobile = true;
        }
    }

    addMember(memberTypeName) {
        this.$scope.$broadcast('handleAddMember', memberTypeName);
    }

    toggleAddMemberView() {
        this.addMemberViewVisible = !this.addMemberViewVisible;
        if (this.addMemberViewVisible) {
            this.memberTypeContent = this.getText('jobSettings_memberTypeSContent') + '<ul><li>' + this.getText('jobSettings_memberTypeSAction') + '</li></ul>';
        }
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    // isBuildingCodeNBCC() {
    //     var prData = this.allModelDataService.getPostResponseData();
    //     var buildingCodeEnum = prData.ApplicationData.JobSettingsData.BuildingCodeForteWEB;
    //     if (buildingCodeEnum === 18 || buildingCodeEnum === 23 || buildingCodeEnum === 24) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }
    _getProject(forgetState, fildId, fromLink) {
        //console.log('jobJS-_getProject-spinnerShowIfNotShownAlready');
        this.spinner.spinnerShowIfNotShownAlready(this.getText('loadingProject'));
        this.projectManagementService.getProject(fildId).then((response) => {
            console.log('getproject api request complete');
            if (response.postResponseData === null) {
                if (fromLink === true) {
                    this.notifier.fileManagementError(this.getText('error'), this.getText('fileUnavailableMessage'));
                }
                this.allModelDataService.setFileIdToOpen(null);
                this.forteCookieService.writeCurrentlyOpenedFileId(null);
                this.$state.go('application.manage.files');
            } else {
                this.allModelDataService.setBuildingCodeListTypeOnLoad();
                this.loadingComplete = true;
                //this.currentProject = response.data.postResponseData;
                //this.allModelDataService.setTeamOwnerId(response.data.teamOwner);
                //load the file tree.
                this.$timeout(() => {
                    this.$state.go('application.job.jobSummary');
                    this.$scope.$broadcast('projectReady', {forgetState: forgetState});
                }, 0, true);
            }
        }, (error) => {
            if (fromLink === true) {
                this.notifier.fileManagementError(this.getText('fileUnavailable'), this.getText('fileUnavailableMessage'));
            }
            this.allModelDataService.setFileIdToOpen(null);
            this.forteCookieService.writeCurrentlyOpenedFileId(null);
            this.$state.go('application.manage.files');
        })
        .finally(() => {
            //console.log('jobJS-_getProject-spinnerHide');
            this.spinner.spinnerHide();
        });
    }

    getGraphicToggleCSSClass() {
        // if (vm.showGraphicToggle) {
        //     return 'graphic-show';
        // }
        // else {
        return 'graphic-hide';
        // }
    }

    toggleGraphic() {
        this.isGraphicVisible = !this.isGraphicVisible;
        this.isLandscapModal = false;
        this.forteCookieService.writeIsGraphicVisible(this.isGraphicVisible);
        // this.drawingService.setIsGraphicVisible(this.isGraphicVisible); //vm.isGraphicVisible);
        if (this.isGraphicVisible === true || this.isGraphicVisibleInReport === true) {
            this._broadcastRefreshGraphic('refreshGraphic_main');
        }
    }

    toggleGraphicZoom() {
        this.isLandscapModal = true;
        this.isNormalModeChart = false;

        this._showGraphModal();
        //need to wait until the modal is shown because this zoomed graphic doesnt exist in the dom until the modal is shown.
        this.$timeout(() => {
            this._broadcastRefreshGraphic('refreshGraphic_zoomed');
        }, 500, true);
    }

    _broadcastRefreshGraphic(graphicName) {
        var prData = this.allModelDataService.getPostResponseData();
        if (prData.MemberData) {
            if (prData.MemberData.GraphicOutputData) {
                //pause this execution because the graphic container has to be visible on
                //the screen for the graphic text layer to render in the proper place.
                this.$rootScope.$broadcast(graphicName, prData.MemberData.GraphicOutputData);

                var currentTab = this.allModelDataService.tryGetCurrentTab();
                if (currentTab === 9) //report
                {
                    this.$rootScope.$broadcast('refreshGraphic_report', prData.MemberData.GraphicOutputData);
                }
            }
        }
    }
    _showGraphModal() {
        this.resetIsLargeMobileDevice();
        $('#graphicToggleZoomModal').modal();
    }

    cancelModal() {
        this.isLandscapModal = false;
        this.isNormalModeChart = true;
        $('#graphicToggleZoomModal').modal('hide');
    }

    openSideJobNav() {

    }
}
