import moment from 'moment-timezone';

export default class loginController {
    constructor($state, authService, resourceMessagingService, Title,  notifier, userNameValue, signalrService) {
        'ngInject';

        this.$state = $state;
        this.authService = authService;
        this.resourceMessagingService = resourceMessagingService;
        this.Title = Title;
        this.userNameValue = userNameValue;
        this.notifier = notifier;
        this.signalRService = signalrService;

        this.loginData = {
            userName: '',
            password: '',
            useRefreshTokens: false
        };
        this.message = '';
        this.submitted = false;
        this.disableLoginAction = false;
        this.showMaintanenceMessage = false;

        this.activate();
    }

    activate() {
        this.signalRService.disconnect();
        //store the original document title (this is for reseting the title ng-idle puts in place when the user is auto-logged out)
        this.message = '';
        this.Title.original('ForteWEB');
        this.authService.clearLocalStorageData();
        //this.authService.getPaeVersion();
        this.getLoginPageData();
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    isUserNameEntered() {
        this.userNameValue.user = this.loginData.userName;
    }

    getLoginPageData() {
        var nextDateString;
        this.authService.getLoginPageData()
            .then((response)=> {
                nextDateString = response.data.nextMaintenanceDate;
            }, (err, status)=> {
                nextDateString = '1900-01-01 00:00';
            })
            .finally(() => {
                this.determineMaintanenceMessageVisibility(nextDateString);
            });
    }

    determineMaintanenceMessageVisibility(nextDateString) {
        this.nextMaintenanceDate = moment.utc(nextDateString);
        var now = moment.utc();
        var dayDifference = this.nextMaintenanceDate.diff(now,'days');
        if (dayDifference >= 0) {
            if (dayDifference < 8) {
                var eastCoast = this.nextMaintenanceDate.tz('America/New_York');
                this.maintainenceDateText = eastCoast.format('MMMM Do');
                this.maintainenceTimeText = eastCoast.format('ha z');
                this.showMaintanenceMessage = true;
            }
        }
    }

    login(isValid) {

        var now = moment.utc();
        var minuteDifference = this.nextMaintenanceDate.diff(now,'minutes');

        if (minuteDifference < 20 && this.nextMaintenanceDate.toJSON() !== moment.utc('1900-01-01 00:00').toJSON()) {
            //dont let them login
            this.notifier.showLoginPreventedToast('Maintenance', 'The site is currently down for maintenance, please check back later');
            return;
        }

        this.submitted = true;
        this.Title.restore();
        if (isValid) {
            this.disableLoginAction = true;
            this.authService.login(this.loginData)
                .then((response) => {
                    this.message = '';
                    this.$state.go('application.loading');
                })
                .catch((err) => {
                    if (err) {
                        if (err.data.error === 'EmailNotConfirmed') {
                            this.$state.go('account.resendEmail', {
                                'message':this.getText('confirmEmailAddress'),
                                'email': this.loginData.userName});
                        }
                        else if (err.data.error === 'underConstruction') {
                            window.location.replace('underconstruction.html');
                        }
                        else {
                            this.message = err.data.error_description;
                        }
                    } else {
                        this.message = this.getText('sometingWentWrong');
                    }
                    this.disableLoginAction = false;
                });
        } else {
            this.message = '';
        }
    };

}
