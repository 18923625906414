import horizontalMemberReportTemplate from './horizontalMemberReport.html';
import moment from 'moment';
export default class horizontalMemberReport {
    constructor() {
        this.restrict = 'A';
        this.scope = {
            memberReportDataDTO: '=memberreportdatadto',
            multiPlyData: '=multiplydata',
            operatorInfo: '=operatorinfo',
            softwareOperator: '=softwareoperator',
            graphicIdReport: '=graphicidreport',
            uom: '=uom',
            jobNotes: '=jobnotes'
        };
        this.template = horizontalMemberReportTemplate;
    }
    controller($scope, resourceMessagingService, reportService, $sce) {
        'ngInject';

        $scope.resourceMessagingService = resourceMessagingService;
        $scope.reportService = reportService;
        $scope.$sce = $sce;
    }
    link(scope, element, attrs) {
        scope.reportCtrl = scope.$parent.reportCtrl;
        scope.displayMpceDetailedData;
        scope.isJoist;
        scope.isMpceVisible;
        scope.hasMpceError;
        scope.mpcemsg = null;
        scope.mpceErrorMsg;
        scope.mpceSelectedFullLengthDataArray;
        scope.mpceSpansArray;
        scope.mpceMessageColorArray;
        scope.mpceDetailedDataArray;
        scope.hasSelfWeight = false;
        scope.designedDate;
        scope.versionNumbersText;
        scope.fileNameText;
        scope.operatorName;
        scope.operatorCompany;
        scope.operatorPhone;
        scope.operatorEmail;
        scope.jobNotes;
        scope.jobNotesTrustedHtml;
        scope.memberNotesTrustedHtml;
        scope.gridModificationAllowed = false;
        scope.multiplyMessageStyles = {
            1: 'mpceNormalGridStyle',
            2: 'mpceNoSolutionGridStyle'
        };

        scope.getText = getText;
        scope.assembleMessage = assembleMessage;
        scope.buildEdgeBracingLabels = buildEdgeBracingLabel;
        scope.buildSupportNameString = buildSupportNameString;
        scope.checkForNumberGetText = checkForNumberGetText;
        scope.naString = naString;
        scope.buildSupportAccessoryString = buildSupportAccessoryString;
        scope.loadCombinationString = loadCombinationString;
        scope.buildConnectorSupportNameString = buildConnectorSupportNameString;
        scope.connectorModelConvert = connectorModelConvert;
        scope.buildAccessoryString = buildAccessoryString;
        scope.getTextFastener = getTextFastener;
        scope.buildString = buildString;
        scope.buildLoadsLocation = buildLoadsLocation;
        scope.getColumnHeadersWithProperUOM = getColumnHeadersWithProperUOM;
        scope.buildHoleNameString = buildHoleNameString;
        scope.getNAForLocationGrid = getNAForLocationGrid;
        scope.getNotchFlangeColumnHeadersWithProperUOM = getNotchFlangeColumnHeadersWithProperUOM;

        scope.$watch('memberReportDataDTO', (value) => { //I change here
            var val = value || null;
            if (val) {
                activate();
            }
        });
        scope.$watch('multiPlyData', (value) => { //I change here
            var val = value || null;
            if (val) {
                refreshMPCEdataFromPRData();
            }
        });

        function assembleMessage(value) {
            return scope.reportService.assembleMessage(value);
        }
        function getText(key) {
            return scope.resourceMessagingService.getText(key);
        }

        function getNAForLocationGrid(key) {
            var returnStrings = '';
            if (key.indexOf('na') > -1) {
                var partials = key.split(' / ');
                for (var i = 0; i < partials.length; i++) {
                    returnStrings = returnStrings + scope.resourceMessagingService.getText(partials[i]);
                    if (i !== partials.length - 1) {
                        returnStrings += ' / ';
                    }
                }
            } else {
                returnStrings = key;
            }

            return returnStrings;
        }

        function connectorModelConvert(str) {
            if (str === 'NoSolution' || str === 'connectorNotFound') {
                return scope.resourceMessagingService.getText(str);
            } else {
                return str;
            }
            return scope.resourceMessagingService.getText(str);
        }

        function getTextFastener(key) {
            if (key) {
                if (key.lastIndexOf('[') > -1) {
                    var index = key.indexOf('[');
                    var appendText = key.substring(index - 1, key.length);
                    key = scope.getText(key.substring(0, index - 1)).concat(appendText);
                    return key;
                } else {
                    return scope.resourceMessagingService.getText(key);
                }
            }
        }

        //force the control to call these functions right after element is drawn on the screen.
        function activate() {
            refreshMPCEdataFromPRData();
            _getDesignedDate();
            _getVersionNumbersText();
            _getFileName();
            _getSoftwareOperatorText();

            if (scope.reportCtrl) {
                scope.gridModificationAllowed = true;
            }

            if (scope.memberReportDataDTO.MemberInformation.MemberType.toLowerCase() === 'joist') {
                scope.isJoist = true;
            } else {
                scope.isJoist = false;
            }
            scope.operatorName = scope.operatorInfo.OperatorName;
            scope.operatorCompany = scope.operatorInfo.OperatorCompany;
            scope.operatorPhone = scope.operatorInfo.OperatorPhone;
            scope.operatorEmail = scope.operatorInfo.OperatorEmail;

            scope.jobNotesTrustedHtml = scope.$sce.trustAsHtml(scope.jobNotes.replace(/\n/g, '<br/>'));
            scope.memberNotesTrustedHtml = scope.$sce.trustAsHtml(scope.memberReportDataDTO.MiscellaneousData[0].MemberNotes.replace(/\n/g, '<br/>'));
        }

        function _getFileName() {
            var miscData = scope.memberReportDataDTO.MiscellaneousData[0];
            var textToReturn = scope.getText('fileName') + ': ' + miscData.FileName;
            scope.fileNameText = textToReturn;
        }

        function _getVersionNumbersText() {
            //var prData = allModelDataService.getPostResponseData();
            var textToReturn = scope.memberReportDataDTO.AppVersion;
            textToReturn = textToReturn + ', ' + scope.memberReportDataDTO.EngineVersion;
            scope.versionNumbersText = textToReturn;
        }

        function _getDesignedDate() {
            scope.designedDate;
            var timeString = scope.memberReportDataDTO.DateTime;
            var then = moment.utc(timeString);
            scope.designedDate = then.format('lll');
        }

        function getSupportReactionColSpan() { //eslint-disable-line complexity
            var toReturn = 1;
            if (scope.memberReportDataDTO) {
                if (scope.memberReportDataDTO.SupportReactionColumnVisibility) {
                    if (scope.memberReportDataDTO.SupportReactionColumnVisibility[0].FloorLiveVisible === true) {
                        toReturn += 1;
                    }
                    if (scope.memberReportDataDTO.SupportReactionColumnVisibility[0].RoofLiveVisible === true) {
                        toReturn += 1;
                    }
                    if (scope.memberReportDataDTO.SupportReactionColumnVisibility[0].SnowVisible === true) {
                        toReturn += 1;
                    }
                    if (scope.memberReportDataDTO.SupportReactionColumnVisibility[0].WindVisible === true) {
                        toReturn += 1;
                    }
                    if (scope.memberReportDataDTO.SupportReactionColumnVisibility[0].SeismicVisible === true) {
                        toReturn += 1;
                    }
                    if (scope.memberReportDataDTO.MiscellaneousData[0].BuildingCodeIsNbcc === false) {
                        toReturn += 1;
                    }
                }
            }
            return toReturn;
        }

        function checkForNumberGetText(key) {
            var test = key.substring(0, 1);
            if (isNaN(test)) {
                return scope.getText(key);
            } else {
                return key;
            }
        }

        function _getSoftwareOperatorText() {

            if (scope.softwareOperator === '') {
                scope.softwareOperatorText = scope.getText('softwareOperatorDefault');
            } else {
                scope.softwareOperatorText = scope.softwareOperator;
            }
        }

        function buildEdgeBracingLabel(index) {
            if (index === 0) {
                return scope.getText('topEdgeLengthMobile');
            }
            else {
                return scope.getText('bottomEdgeLengthMobile');
            }
        }

        function buildSupportNameString(index, name) {
            var msg = '';
            var namesegment;
            var segmentcheck;
            msg = index + 1 + ' - ';
            namesegment = name.split(' ');
            for (var i = 0; i < namesegment.length; ++i) { //Dim suptName As String = "hangerOn"
                segmentcheck = namesegment[i];
                segmentcheck = segmentcheck.substring(0, 1);
                //check for PSL etc., depth, and other exceptions
                if (segmentcheck === segmentcheck.toUpperCase() || namesegment[i] === 'concrete' || namesegment[i] === 'masonry' || namesegment[i] === 'steel') {
                    msg += namesegment[i] + ' ';
                } else { //beam plate and studwall are lowercase if they are not first in namesegment
                    if (i >= 1 && (namesegment[i] === 'beam' || namesegment[i] === 'ledger' || namesegment[i] === 'plate' || namesegment[i] === 'studWall')) {
                        msg += scope.getText(namesegment[i]).toLowerCase() + ' ';
                    } else {
                        msg += scope.getText(namesegment[i]) + ' ';
                    }
                }
            }
            return msg;
        }

        function buildConnectorSupportNameString(name) {
            var namesegment = name.split('-');
            return namesegment[0] + ' - ' + scope.getText(namesegment[1]);
        }

        function loadCombinationString(message) {
            var languages = scope.resourceMessagingService.getLanguageOptions();
            var i = 0;
            for (i; i < languages.length; ++i) {
                if (languages[i].isSelected === true) {
                    break;
                }
            }
            if (message.MessageParameters.length > 0) {
                if (languages[i].culture === 'fr-CA') { //replace . with ,
                    message.MessageParameters[0] = message.MessageParameters[0].replace('.', ',');
                } else { //replace , with .
                    message.MessageParameters[0] = message.MessageParameters[0].replace(',', '.');
                }
            }
            return scope.reportService.assembleMessage(message);
        }

        function buildHoleNameString(holeName) {
            var newHoleName = holeName;

            if (newHoleName.includes('circular')) {
                newHoleName = newHoleName.replace('circular', scope.getText('circular'));
            } else if (newHoleName.includes('rectangular')) {
                newHoleName = newHoleName.replace('rectangular', scope.getText('rectangular'));
            } else if (newHoleName.includes('slotted')) {
                newHoleName = newHoleName.replace('slotted', scope.getText('slotted'));
            } else if (newHoleName.includes('square')) {
                newHoleName = newHoleName.replace('square', scope.getText('square'));
            }

            if (newHoleName.includes('vertical')) {
                newHoleName = newHoleName.replace('vertical', scope.getText('vertical'));
            }

            return newHoleName;

            // var msg = '';
            // var namesegment;
            // msg = index + 1 + ' - ';
            // namesegment = name.split(' ');
            // for (var i = 0; i < namesegment.length; ++i) {
            //     if (i > 1 && (namesegment[i] === 'circular' || namesegment[i] === 'rectangular' || namesegment[i] === 'square' || namesegment[i] === 'slotted')) {
            //         msg += scope.getText(namesegment[i]) + ' ';
            //     } else if (namesegment[i].length > 2) {
            //         if (i === namesegment.length - 1) {
            //             msg += namesegment[i];
            //         } else {
            //             msg += namesegment[i] + ' ';
            //         }
            //     }
            // }
            // return msg;
        }

        function naStringOver12(msg) {
            if (msg === 'na') {
                return scope.getText('na');
            } else {
                return msg + scope.getText('slash12');
            }
        }

        function naString(msg) {
            if (msg === 'na') {
                return scope.getText('na');
            } else if (_contains(msg, 'na')) {
                var replacer = scope.getText('na');
                msg = msg.replace(/na/g, replacer);
                return msg;
            } else {
                return msg;
            }
        }

        function _contains(string, searchfor) {
            return (string || '').indexOf(searchfor) > -1;
        }

        function buildLoadsLocation(str) {
            var locationsParts = str.split(' ');
            var convertedStr = '';
            for (var i = 0; i < locationsParts.length; i++) {
                if (i === locationsParts.length - 1) {
                    if (locationsParts[i] === 'loadPlacementTop' || locationsParts[i] === 'loadPlacementFront' || locationsParts[i] === 'loadPlacementBack' || locationsParts[i] === '') {
                        convertedStr += ' (' + scope.getText(locationsParts[i]) + ')';
                    } else {
                        convertedStr += locationsParts[i];
                    }
                } else {
                    convertedStr += locationsParts[i] + ' ';
                }
            }
            return convertedStr;
        }

        function buildString(index, name) {
            var msg;
            var selfweightstring = name.slice(0, 4);
            if (selfweightstring === 'self') {
                scope.hasSelfWeight = true;
                msg = 0 + ' - ' + scope.getText(name);
            }

            if (scope.hasSelfWeight) {
                msg = index + ' - ' + scope.getText(name);
            } else {
                msg = (index + 1) + ' - ' + scope.getText(name);
            }

            return msg;
        }

        /*eslint-disable max-depth */
        function refreshMPCEdataFromPRData() {
            if (scope.multiPlyData) {
                scope.displayMpceDetailedData = scope.multiPlyData.DisplayType === 3;

                if (!scope.multiPlyData.MpceResults ||
                    !scope.memberReportDataDTO.MiscellaneousData[0].DesignPassed ||
                    scope.multiPlyData.DisplayType === 0) {
                    //no mpce run, skip the rest, dont show the grid.
                    scope.isMpceVisible = false;
                } else {
                    scope.isMpceVisible = true;
                    scope.hasMpceError = scope.multiPlyData.MpceResults.ErrorStatus.HasError;
                    scope.mpcemsg = scope.multiPlyData.MpceResults.ParallamMessageTag;
                    scope.mpceErrorMsg = '';

                    if (scope.hasMpceError) {
                        scope.mpceErrorMsg = scope.multiPlyData.MpceResults.ErrorStatus.ErrorMessageKey;
                    } else {
                        //populate the data for the fullLength/bySpan grid.
                        if (!scope.displayMpceDetailedData) {
                            scope.mpceSelectedFullLengthDataArray = scope.memberReportDataDTO.MpceFastenerInfo;
                            //build the options for the fasteners
                            scope.mpceSpansArray = [];
                            scope.mpceMessageColorArray = [];
                            for (var fullLengthIndex = 0; fullLengthIndex < scope.multiPlyData.FullLengthGridData.length; fullLengthIndex++) {
                                if (scope.multiPlyData.FullLengthGridData[fullLengthIndex].Fastener[0] === 'NoSolution') {
                                    //set color to red
                                    scope.mpceMessageColorArray.push(2);
                                } else {
                                    //set color to black
                                    scope.mpceMessageColorArray.push(1);
                                }
                                var currentItem = scope.multiPlyData.FullLengthGridData[fullLengthIndex];
                                var currentSpanFasteners = [];
                                for (var fastenerIndex = 0; fastenerIndex < currentItem.Fastener.length; fastenerIndex++) {
                                    var fastenerToAdd = {};
                                    fastenerToAdd.Name = currentItem.Fastener[fastenerIndex];
                                    fastenerToAdd.Rows = currentItem.FastenerInfo[fastenerIndex].Rows;
                                    fastenerToAdd.OCSpacing = currentItem.FastenerInfo[fastenerIndex].OCSpacing;
                                    fastenerToAdd.FastenerQty = currentItem.FastenerInfo[fastenerIndex].FastenerQty;
                                    fastenerToAdd.Notes = currentItem.FastenerInfo[fastenerIndex].Notes;
                                    fastenerToAdd.Placement = currentItem.FastenerInfo[fastenerIndex].Placement;
                                    fastenerToAdd.ReportNote = currentItem.FastenerInfo[fastenerIndex].ReportNote;
                                    currentSpanFasteners.push(fastenerToAdd);
                                }
                                scope.mpceSpansArray.push(currentSpanFasteners);
                            }
                        } else {
                            var detailedData = {};
                            scope.mpceDetailedDataArray = scope.memberReportDataDTO.MpceDetailed;
                            for (var detailedIndex = 0; detailedIndex < scope.memberReportDataDTO.MpceDetailed.length; detailedIndex++) {
                                detailedData.type = scope.memberReportDataDTO.MpceDetailed[detailedIndex].Type;
                                detailedData.location = scope.memberReportDataDTO.MpceDetailed[detailedIndex].Location;
                                detailedData.loads = scope.memberReportDataDTO.MpceDetailed[detailedIndex].Load;
                                detailedData.ldf = scope.memberReportDataDTO.MpceDetailed[detailedIndex].LDF;
                            }
                        }
                    }
                }
            }
        }
        /*eslint-enable max-depth */
        function buildSupportAccessoryString(name) {
            var msg = '';
            var namesegment;
            namesegment = name.split(', ');
            for (var i = 0; i < namesegment.length; ++i) {
                if (i === namesegment.length - 1) {
                    msg += scope.getText(namesegment[i]);
                } else {
                    msg += scope.getText(namesegment[i]) + ', ';
                }
            }
            return msg;
        }

        function buildAccessoryString(name) {
            var msg = '';
            if (name.length > 0) {
                var namesegment = name.split(', ');
                for (var i = 0; i < namesegment.length; ++i) {
                    if (i === namesegment.length - 1) {
                        msg += scope.getText(namesegment[i]);
                    } else {
                        msg += scope.getText(namesegment[i]) + ', ';
                    }
                }
            }
            return msg;
        }

        function getNotchFlangeColumnHeadersWithProperUOM(resultType) {
            var headerText = null;
            if (scope.uom === 3) {
                switch (resultType) {
                    case 'tension':
                        headerText = scope.getText('tensionMomentHeaderMetric');
                        break;
                    case 'compression':
                        headerText = scope.getText('compressionMomentHeaderMetric');
                        break;
                }
            } else {
                switch (resultType) {
                    case 'tension':
                        headerText = scope.getText('tensionMomentHeader');
                        break;
                    case 'compression':
                        headerText = scope.getText('compressionMomentHeader');
                        break;
                }
            }
            return headerText;
        }

        /*eslint-disable complexity */
        function getColumnHeadersWithProperUOM(key) {
            //keys for metric and imperial are not consistent in dictionary(shear and deflection).
            var letter = key.slice(0, 2);
            var string = null;

            if (scope.uom === 3) {
                switch (letter) {
                    case 'De':
                        string = scope.getText('deflectionINMetric');
                        break;
                    case 'su':
                        string = scope.getText('supportReactionsLBSMetric');
                        break;
                    case 'sh':
                        string = scope.getText('ShearLBSMetric');
                        break;
                    case 'mo':
                        string = scope.getText('momentFTLBSMetric');
                        break;
                }
            } else {
                switch (letter) {
                    case 'De':
                        string = scope.getText('DeflectionIN');
                        break;
                    case 'su':
                        string = scope.getText('supportReactionsLBS');
                        break;
                    case 'sh':
                        string = scope.getText('shearLBS');
                        break;
                    case 'mo':
                        string = scope.getText('momentFTLBS');
                        break;
                }
            }
            /*eslint-enable complexity */
            return string;
        }
    }
}
