export default class levelController {
    constructor($rootScope, levelPropertiesService, resourceMessagingService, allModelDataService, roofGeometryService) {
        'ngInject';

        this.$rootScope = $rootScope;
        this.levelPropertiesService = levelPropertiesService;
        this.resourceMessagingService = resourceMessagingService;
        this.allModelDataService = allModelDataService;
        this.roofGeometryService = roofGeometryService;

        this.metaData = null;
        this.memberManagerData = null;
        this.levelName = null;
        this.selectedBuildingClass = null;
        this.isIBC = false;
        this.exposureCategoryOptions = null;
        this.meanRoofHeightFriendly = null;
        this.ridgeHeightFriendly = null;

        this.activate();
    }


    activate() {
        this.getLevelPropertiesMetaData();
        this.getLevelPropertiesData();
        this._populateExposureCategories();
        this.isIBC = this.allModelDataService.isBuildingCodeIBC();
    }

    exposureCategoryChanged(newValue) {
        this.levelPropertiesService.modifyExposureCategory(newValue).then(
            (response) => {
                this.getLevelPropertiesData();
            }
        );
    }

    exposureCategoryChangedNBCC(newValue) {
        this.levelPropertiesService.modifyExposureCategoryNBCC(newValue).then(
            (response) => {
                this.getLevelPropertiesData();
            }
        );
    }

    modifyRidgeHeight(newValue) {
        this.levelPropertiesService.modifyRidgeHeight(newValue).then(
            (response) => {
                this.getLevelPropertiesData();
            }
        );
    }

    modifyEaveHeight(newValue) {
        this.levelPropertiesService.modifyEaveHeight(newValue).then(
            (response) => {
                this.getLevelPropertiesData();
            }
        );
    }

    modifyMeanRoofHeight(newValue) {
        this.levelPropertiesService.modifyMeanRoofHeight(newValue).then(
            (response) => {
                this.getLevelPropertiesData();
            }
        );
    }

    modifyDistanceChange(newValue) {
        this.levelPropertiesService.modifyDistanceChange(newValue).then(
            (response) => {
                this.getLevelPropertiesData();
            }
        );
    }

    _populateExposureCategories() {
        this.exposureCategoryOptionsIBC = [
            {
                'Key':0,
                'title': this.getText('ExposureBLabel'),
                'text': this.getText('ExposureBText')
            },
            {
                'Key':1,
                'title': this.getText('ExposureCLabel'),
                'text': this.getText('ExposureCText')
            },
            {
                'Key':2,
                'title': this.getText('ExposureDLabel'),
                'text': this.getText('ExposureDText')
            }
        ];

        this.exposureCategoryOptionsNBCC = [
            {
                'Key':0,
                'title': this.getText('exposureOpenLabel'),
                'text': this.getText('exposureOpenText')
            },
            {
                'Key':1,
                'title': this.getText('exposureRoughLabel'),
                'text': this.getText('exposureRoughText')
            },
            {
                'Key':2,
                'title': this.getText('exposureIntermediateLabel'),
                'text': this.getText('exposureIntermediateText')
            }
        ];


    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    getLevelPropertiesMetaData() {
        this.levelPropertiesService.getLevelPropertiesMetaData().then((response) => {
            this.metaData = response;
        });
    }

    getLevelPropertiesData() {
        this.memberManagerData = this.levelPropertiesService.getLevelPropertiesData();
        var selectedContainer = this.memberManagerData.SelectedContainerID;
        this.levelName = this.memberManagerData.MemberContainers[selectedContainer].LevelSettingsData.ContainerName;
        this.selectedBuildingClass = this.memberManagerData.MemberContainers[selectedContainer].LevelSettingsData.BuildingClass;
        this.levelSettingsData = this.memberManagerData.MemberContainers[selectedContainer].LevelSettingsData;
        this.levelHasMembers = this.memberManagerData.MemberContainers[selectedContainer].Members.length > 0;
        //recalc the friendly values on every reload just in case they changed uom's since the values were written to the model.
        var data = {
            'UOMSetting': this.allModelDataService.getPostResponseData().ApplicationData.ProgramSettingsData.UOM,
            'lengthsList': [
                {'lengthFriendly': '', 'lengthValue': this.levelSettingsData.MeanRoofHeight === 'NaN' ? 0 : this.levelSettingsData.MeanRoofHeight},
                {'lengthFriendly': '', 'lengthValue': this.levelSettingsData.RidgeHeight === 'NaN' ? 0 : this.levelSettingsData.RidgeHeight},
                {'lengthFriendly': '', 'lengthValue': this.levelSettingsData.EaveHeight === 'NaN' ? 0 : this.levelSettingsData.EaveHeight},
                {'lengthFriendly': '', 'lengthValue': this.levelSettingsData.DistanceChange === 'NaN' ? 0 : this.levelSettingsData.DistanceChange}
            ]
        };
        this.roofGeometryService.convertLengthsListLargeUnits(data).then((response) => {
            this.meanRoofHeightFriendly = response.lengthsList[0].lengthValue === 0 ? '' : response.lengthsList[0].lengthFriendly;
            this.ridgeHeightFriendly = response.lengthsList[1].lengthValue === 0 ? '' : response.lengthsList[1].lengthFriendly;
            this.eaveHeightFriendly = response.lengthsList[2].lengthValue === 0 ? '' : response.lengthsList[2].lengthFriendly;
            this.distanceChangeFriendly = response.lengthsList[3].lengthValue === 0 ? '' : response.lengthsList[3].lengthFriendly;
        });
    }

    modifyLevelName() {
        var data = {
            'containerId': this.memberManagerData.SelectedContainerID,
            'newName': this.levelName
        };

        this.levelPropertiesService.modifyLevelName(data)
            .then((serviceResponse) => {
                this.getLevelPropertiesData();
                var nodeId = 'c_' + this.memberManagerData.SelectedContainerID;
                this.$rootScope.$broadcast('updateNodeName', this.levelName, nodeId);
            });
    }

    modifyBuildingClass() {
        var data = {
            'value': this.selectedBuildingClass
        };

        this.levelPropertiesService.modifyBuildingClass(data)
            .then((serviceResponse) => {
                this.getLevelPropertiesMetaData();
            });
    }
}
// (function () {
//     'use strict';
//     var app = angular.module('app');

//     app.controller('levelController', ['$scope', '$rootScope', '$location', '$timeout',
//         'uiTourService', 'levelPropertiesService', 'resourceMessagingService',
//         'allModelDataService', 'roofGeometryService',
//         function ($scope, $rootScope, $location, $timeout, uiTourService,
//             levelPropertiesService, resourceMessagingService, allModelDataService, roofGeometryService) {
//             //controller code goes here.
//             /* jshint validthis: true */
//             var vm = this;
//             /* jshint validthis: true */

//             vm.metaData = null;
//             vm.memberManagerData = null;
//             vm.levelName = null;
//             vm.selectedBuildingClass = null;
//             vm.isIBC = false;

//             vm.modifyBuildingClass = modifyBuildingClass;
//             vm.exposureCategoryChanged = exposureCategoryChanged;
//             vm.exposureCategoryChangedNBCC = exposureCategoryChangedNBCC;
//             vm.modifyLevelName = modifyLevelName;
//             vm.getText = getText;
//             vm.modifyRidgeHeight = modifyRidgeHeight;
//             vm.modifyEaveHeight = modifyEaveHeight;
//             vm.modifyMeanRoofHeight = modifyMeanRoofHeight;
//             vm.modifyDistanceChange = modifyDistanceChange;
//             vm.setActionBtn = setActionBtn;

//             activate();

//             function activate() {
//                 getLevelPropertiesMetaData();
//                 getLevelPropertiesData();

//                 _populateExposureCategories();

//                 vm.isIBC = allModelDataService.isBuildingCodeIBC();
//             }

//             function _populateExposureCategories() {
//                 //vm.selectedExposureCategory = 0; //hardcoded for now b/c select needs a ng-model
//                 vm.exposureCategoryOptionsIBC = [
//                     {
//                         'Key':0,
//                         'title': getText('ExposureBLabel'),
//                         'text': getText('ExposureBText')
//                     },
//                     {
//                         'Key':1,
//                         'title': getText('ExposureCLabel'),
//                         'text': getText('ExposureCText')
//                     },
//                     {
//                         'Key':2,
//                         'title': getText('ExposureDLabel'),
//                         'text': getText('ExposureDText')
//                     }
//                 ];

//                 vm.exposureCategoryOptionsNBCC = [
//                     {
//                         'Key':0,
//                         'title': getText('exposureOpenLabel'),
//                         'text': getText('exposureOpenText')
//                     },
//                     {
//                         'Key':1,
//                         'title': getText('exposureRoughLabel'),
//                         'text': getText('exposureRoughText')
//                     },
//                     {
//                         'Key':2,
//                         'title': getText('exposureIntermediateLabel'),
//                         'text': getText('exposureIntermediateText')
//                     }
//                 ];
//             }


//             function setActionBtn(prev, next) {
//                 var levelTour = uiTourService.getTourByName('levelSettingsTour');
//                 levelTour.prevDisabled = prev;
//                 levelTour.nextDisabled = next;
//             }

//             function getText(key) {
//                 return resourceMessagingService.getText(key);
//             }

//             function getLevelPropertiesMetaData() {
//                 levelPropertiesService.getLevelPropertiesMetaData().then(function (response) {
//                     vm.metaData = response;
//                 });
//             }
//             function modifyRidgeHeight(newValue) {
//                 levelPropertiesService.modifyRidgeHeight(newValue).then(
//                     function(response) {
//                         getLevelPropertiesData();
//                     }
//                 );
//             }

//             function modifyEaveHeight(newValue) {
//                 levelPropertiesService.modifyEaveHeight(newValue).then(
//                     function(response) {
//                         getLevelPropertiesData();
//                     }
//                 );
//             }

//             function modifyMeanRoofHeight(newValue) {
//                 levelPropertiesService.modifyMeanRoofHeight(newValue).then(
//                     function(response) {
//                         getLevelPropertiesData();
//                     }
//                 );
//             }

//             function modifyDistanceChange(newValue) {
//                 levelPropertiesService.modifyDistanceChange(newValue).then(
//                     function(response) {
//                         getLevelPropertiesData();
//                     }
//                 );
//             }

//             function exposureCategoryChanged(newValue) {
//                 levelPropertiesService.modifyExposureCategory(newValue).then(
//                     function(response) {
//                         getLevelPropertiesData();
//                     }
//                 );
//             }

//             function exposureCategoryChangedNBCC(newValue) {
//                 levelPropertiesService.modifyExposureCategoryNBCC(newValue).then(
//                     function(response) {
//                         getLevelPropertiesData();
//                     }
//                 );
//             }

//             function getLevelPropertiesData() {
//                 vm.memberManagerData = levelPropertiesService.getLevelPropertiesData();
//                 var selectedContainer = vm.memberManagerData.SelectedContainerID;
//                 vm.levelName = vm.memberManagerData.MemberContainers[selectedContainer].LevelSettingsData.ContainerName;
//                 vm.selectedBuildingClass = vm.memberManagerData.MemberContainers[selectedContainer].LevelSettingsData.BuildingClass;
//                 vm.levelSettingsData = vm.memberManagerData.MemberContainers[selectedContainer].LevelSettingsData;
//                 vm.levelHasMembers = vm.memberManagerData.MemberContainers[selectedContainer].Members.length > 0;
//                 //recalc the friendly values on every reload just in case they changed uom's since the values were written to the model.
//                 var data = {
//                     'UOMSetting': allModelDataService.getPostResponseData().ApplicationData.ProgramSettingsData.UOM,
//                     'lengthsList': [
//                         {'lengthFriendly': '', 'lengthValue': vm.levelSettingsData.MeanRoofHeight === 'NaN' ? 0 : vm.levelSettingsData.MeanRoofHeight},
//                         {'lengthFriendly': '', 'lengthValue': vm.levelSettingsData.RidgeHeight === 'NaN' ? 0 : vm.levelSettingsData.RidgeHeight},
//                         {'lengthFriendly': '', 'lengthValue': vm.levelSettingsData.EaveHeight === 'NaN' ? 0 : vm.levelSettingsData.EaveHeight},
//                         {'lengthFriendly': '', 'lengthValue': vm.levelSettingsData.DistanceChange === 'NaN' ? 0 : vm.levelSettingsData.DistanceChange}
//                     ]
//                 };
//                 roofGeometryService.convertLengthsListLargeUnits(data).then(function(response) {
//                     vm.meanRoofHeightFriendly = response.lengthsList[0].lengthValue === 0 ? '' : response.lengthsList[0].lengthFriendly;
//                     vm.ridgeHeightFriendly = response.lengthsList[1].lengthValue === 0 ? '' : response.lengthsList[1].lengthFriendly;
//                     vm.eaveHeightFriendly = response.lengthsList[2].lengthValue === 0 ? '' : response.lengthsList[2].lengthFriendly;
//                     vm.distanceChangeFriendly = response.lengthsList[3].lengthValue === 0 ? '' : response.lengthsList[3].lengthFriendly;
//                 });

//             }

//             function modifyLevelName() {
//                 var data = {
//                     'containerId': vm.memberManagerData.SelectedContainerID,
//                     'newName': vm.levelName
//                 };

//                 levelPropertiesService.modifyLevelName(data)
//                     .then(function (serviceResponse) {
//                         getLevelPropertiesData();
//                         var nodeId = 'c_' + vm.memberManagerData.SelectedContainerID;
//                         $rootScope.$broadcast('updateNodeName', vm.levelName, nodeId);
//                     });
//             }

//             function modifyBuildingClass() {
//                 var data = {
//                     'value': vm.selectedBuildingClass
//                 };

//                 levelPropertiesService.modifyBuildingClass(data)
//                     .then(function (serviceResponse) {
//                         getLevelPropertiesMetaData();
//                     });
//             }

//         }]);
// }());
