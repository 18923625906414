export default class productSelectionService {
    constructor(common, allModelDataService, postService) {
        'ngInject';
        this.$q = common.$q();
        this.allModelDataService = allModelDataService;
        this.postService = postService;

        this.httpRequestQueueCounter = 0;
        this.promiseQueue = [];
        this.waiting = false;
    }


    // Get Data functions
    getProductData() {
        var data = {};
        return this._requestPostEvent('GetProductData', data);
    }
    handleSelectedProductGroupChanged(productGroups) {
        var data = {
            productGroups: productGroups
        };
        return this._requestPostEvent('SelectedProductGroupChanged', data);
    }
    handleSelectedSeriesChanged(_selected) {
        var data = {
            selected: _selected
        };
        return this._requestPostEvent('SelectedSeriesChanged', data);
    }

    handleSelectedDepthsChanged(_selected) {
        var data = {
            selected: _selected
        };
        return this._requestPostEvent('SelectedDepthsChanged', data);
    }

    handleSelectedDepthsChanged(_selected) {
        var data = {
            selected: _selected
        };
        return this._requestPostEvent('SelectedDepthsChanged', data);
    }
    handleSelectedSpacingsChanged(_selected) {
        var data = {
            selected: _selected
        };
        return this._requestPostEvent('SelectedSpacingsChanged', data);
    }
    handleSelectedPliesChanged(_selected) {
        var data = {
            selected: _selected
        };
        return this._requestPostEvent('SelectedPliesChanged', data);
    }
    handleSelectedOrientationChanged(_selected) {
        var data = {
            selected: _selected
        };
        return this._requestPostEvent('SelectedOrientationsChanged', data);
    }

    handleSelectedTreatmentChanged(_selected) {
        var data = {
            selected: _selected
        };
        return this._requestPostEvent('SelectedPreservativeTreatmentChanged', data);
    }
    handleCustomDepthChanged(_newValue) {
        var data = {
            value: _newValue
        };
        return this._requestPostEvent('CustomDepthChanged', data);
    }
    handleCustomSpacingChanged(_newValue) {
        var data = {
            value: _newValue
        };
        return this._requestPostEvent('CustomSpacingChanged', data);
    }

    //
    //
    //old code prior to ps refactor
    getAllUntreatedProductData() {
        var data = {};
        return this._requestPostEvent('GetAllUntreatedProductData', data);
    }

    getAllTreatedProductData() {
        var data = {};
        return this._requestPostEvent('GetAllTreatedProductData', data);
    }

    getProductSelectionMetadata() {
        var data = {};
        return this._requestPostEvent('GetProductSelectionMetadata', data);
    }

    getProductSelectionSDO() {
        var prData = this.allModelDataService.getPostResponseData();
        return prData.MemberData.ProductSelectionData;
    }

    //Save As Defaults functions

    saveProductSelectionAsDefaults() {
        var currentPostResponseData = this.allModelDataService.getPostResponseData();
        return this._requestPostEvent('SaveProductSelectionAsDefaults', currentPostResponseData);
    }

    // Post Change Events functions

    postTreatmentChangeEvent(newTreatment) {
        return this._requestPostEvent('SelectedPreservativeTreatmentChanged', newTreatment);
    }

    postProductGroupChangeEvent(newProducts) {
        return this._requestPostEvent('SelectedProductGroupChanged', newProducts);
    }

    postSeriesChangeEvent(newSeries) {
        return this._requestPostEvent('SelectedSeriesChanged', newSeries);
    }

    postDepthChangeEvent(newDepths) {
        return this._requestPostEvent('SelectedDepthsChanged', newDepths);
    }

    postCustomDepthchangeEvent(newCustomDepth) {
        return this._requestPostEvent('OnCustomDepthChanged', newCustomDepth);
    }

    postSpacingChangeEvent(newSpacings) {
        return this._requestPostEvent('SelectedSpacingsChanged', newSpacings);
    }

    postCustomSpacingchangeEvent(newCustomSpacing) {
        return this._requestPostEvent('OnCustomSpacingChanged', newCustomSpacing);
    }

    postPliesChangeEvent(newPlies) {
        return this._requestPostEvent('SelectedPliesChanged', newPlies);
    }

    postOrientationChangeEvent(newOrientations) {
        return this._requestPostEvent('SelectedOrientationsChanged', newOrientations);
    }

    // Post Select All functions

    postSelectAllSeries() {
        var data = {};
        return this._requestPostEvent('SelectAllSeries', data);
    }

    postSelectAllDepths() {
        var data = {};
        return this._requestPostEvent('SelectAllDepths', data);
    }

    postSelectAllSpacings() {
        var data = {};
        return this._requestPostEvent('SelectAllSpacings', data);
    }

    postSelectAllPlies() {
        var data = {};
        return this._requestPostEvent('SelectAllPlies', data);
    }

    postSelectAllOrientations() {
        var data = {};
        return this._requestPostEvent('SelectAllOrientations', data);
    }

    postSelectAllProductGroups() {
        var data = {};
        return this._requestPostEvent('SelectAllProductGroups', data);
    }

    _requestPostEvent(holeEvent, holeData) {
        var path = 'api/ProductSelection/';
        return this.postService.requestPostEvent(holeEvent,holeData,path);
    }
}
// // app\services\productSelectionService.js

// (function () {
//     'use strict';
//     var app = angular.module('app');
//     var serviceId = 'productSelectionService';

//     app.factory(serviceId, ['$http', 'ngAuthSettings', 'common', 'allModelDataService', 'postService',  productSelectionService]);

//     function productSelectionService($http, ngAuthSettings, common, allModelDataService, postService) {
//         var $q = common.$q;
//         var serviceBase = ngAuthSettings.apiServiceBaseUri;
//         var httpRequestQueueCounter = 0;
//         var promiseQueue = [];
//         var waiting = false;
//         var service = {
//             getQueueCounter: getQueueCounter,
//             getAllUntreatedProductData: getAllUntreatedProductData,
//             getAllTreatedProductData: getAllTreatedProductData,
//             getProductSelectionMetadata: getProductSelectionMetadata,
//             getProductSelectionSDO: getProductSelectionSDO,
//             postTreatmentChangeEvent: postTreatmentChangeEvent,
//             postProductGroupChangeEvent: postProductGroupChangeEvent,
//             postSeriesChangeEvent: postSeriesChangeEvent,
//             postDepthChangeEvent: postDepthChangeEvent,
//             postCustomDepthchangeEvent: postCustomDepthchangeEvent,
//             postSpacingChangeEvent: postSpacingChangeEvent,
//             postCustomSpacingchangeEvent: postCustomSpacingchangeEvent,
//             postPliesChangeEvent: postPliesChangeEvent,
//             postOrientationChangeEvent: postOrientationChangeEvent,
//             postSelectAllSeries: postSelectAllSeries,
//             postSelectAllDepths: postSelectAllDepths,
//             postSelectAllSpacings: postSelectAllSpacings,
//             postSelectAllPlies: postSelectAllPlies,
//             postSelectAllOrientations: postSelectAllOrientations,
//             postSelectAllProductGroups: postSelectAllProductGroups,
//             saveProductSelectionAsDefaults: saveProductSelectionAsDefaults
//         };


//         // Get Data functions

//         function getAllUntreatedProductData() {
//             var data = {};
//             return requestPSPostEvent('GetAllUntreatedProductData', data);
//         }

//         function getAllTreatedProductData() {
//             var data = {};
//             return requestPSPostEvent('GetAllTreatedProductData', data);
//         }

//         function getProductSelectionMetadata() {
//             var data = {};
//             return requestPSPostEvent('GetProductSelectionMetadata', data);
//         }

//         function getProductSelectionSDO() {
//             var prData = allModelDataService.getPostResponseData();
//             return prData.MemberData.ProductSelectionData;
//         }

//         //Save As Defaults functions

//         function saveProductSelectionAsDefaults() {
//             var currentPostResponseData = allModelDataService.getPostResponseData();
//             return requestPSPostEvent('SaveProductSelectionAsDefaults', currentPostResponseData);
//         }

//         // Post Change Events functions

//         function postTreatmentChangeEvent(newTreatment) {
//             return requestPSPostEvent('SelectedPreservativeTreatmentChanged', newTreatment);
//         }

//         function postProductGroupChangeEvent(newProducts) {
//             return requestPSPostEvent('SelectedProductGroupChanged', newProducts);
//         }

//         function postSeriesChangeEvent(newSeries) {
//             return requestPSPostEvent('SelectedSeriesChanged', newSeries);
//         }

//         function postDepthChangeEvent(newDepths) {
//             return requestPSPostEvent('SelectedDepthsChanged', newDepths);
//         }

//         function postCustomDepthchangeEvent(newCustomDepth) {
//             return requestPSPostEvent('OnCustomDepthChanged', newCustomDepth);
//         }

//         function postSpacingChangeEvent(newSpacings) {
//             return requestPSPostEvent('SelectedSpacingsChanged', newSpacings);
//         }

//         function postCustomSpacingchangeEvent(newCustomSpacing) {
//             return requestPSPostEvent('OnCustomSpacingChanged', newCustomSpacing);
//         }

//         function postPliesChangeEvent(newPlies) {
//             return requestPSPostEvent('SelectedPliesChanged', newPlies);
//         }

//         function postOrientationChangeEvent(newOrientations) {
//             return requestPSPostEvent('SelectedOrientationsChanged', newOrientations);
//         }

//         // Post Select All functions

//         function postSelectAllSeries() {
//             var data = {};
//             return requestPSPostEvent('SelectAllSeries', data);
//         }

//         function postSelectAllDepths() {
//             var data = {};
//             return requestPSPostEvent('SelectAllDepths', data);
//         }

//         function postSelectAllSpacings() {
//             var data = {};
//             return requestPSPostEvent('SelectAllSpacings', data);
//         }

//         function postSelectAllPlies() {
//             var data = {};
//             return requestPSPostEvent('SelectAllPlies', data);
//         }

//         function postSelectAllOrientations() {
//             var data = {};
//             return requestPSPostEvent('SelectAllOrientations', data);
//         }

//         function postSelectAllProductGroups() {
//             var data = {};
//             return requestPSPostEvent('SelectAllProductGroups', data);
//         }

//         function requestPSPostEvent(psEvent, psData) {
//             var path = 'api/ProductSelection/';
//             var deferred = $q.defer();
//             var incremented = false;
//             //console.log('request: ' + psEvent + ' counter: ' + httpRequestQueueCounter);
//             if (httpRequestQueueCounter > 0)
//             {
//                 //console.log('increment outside: ' + psEvent);
//                 incrementQueueCounter();
//                 incremented = true;
//             }
//             getPostReqPromise().then(function () {
//                 if (incremented === false) {
//                     //console.log('increment inside: ' + psEvent);
//                     incrementQueueCounter();
//                 }
//                 //console.log('post: ' + psEvent);
//                 postService.requestPostEvent(psEvent, psData, path).then(function (response) {
//                     //console.log('complete: ' + psEvent);
//                     decrementQueueCounter();
//                     deferred.resolve(response);
//                 });
//             });
//             return deferred.promise;
//         }

//         function getPostReqPromise() {
//             var newPromise = $q.defer();
//             if (httpRequestQueueCounter === 0) {
//                 newPromise.resolve();
//                 return newPromise.promise;
//             }
//             else {
//                 waiting = true;
//                 //postPromise.push(newPromise);
//                 promiseQueue.push(newPromise);
//             }
//             return newPromise.promise;
//         }
//         function incrementQueueCounter() {
//             httpRequestQueueCounter++;
//             //console.log('increment counter: ' + httpRequestQueueCounter);
//         }
//         function decrementQueueCounter() {
//             httpRequestQueueCounter--;
//             if (waiting) {
//                 var thePromise = promiseQueue.splice(0,1);
//                 if (thePromise.length > 0) {
//                     thePromise[0].resolve();
//                 }
//                 if (httpRequestQueueCounter === 0) {
//                     waiting = false;
//                 }
//             }
//         }

//         function getQueueCounter() {
//             var inputQueuePromise = $q.defer();

//             if (httpRequestQueueCounter === 0)
//             {
//                 inputQueuePromise.resolve();
//                 return inputQueuePromise.promise;
//             }
//             else {
//                 waiting = true;
//             }
//             return inputQueuePromise.promise;
//         }

//         return service;
//     }

// }());
