export default class quickLoadsService {
    constructor(common, allModelDataService, postService) {
        'ngInject';

        this.$q = common.$q();
        this.allModelDataService = allModelDataService;
        this.postService = postService;
    }

    addQuickLoadFromMemberLoad(viewPosition, newName, qlData) {
        var data = {
            'NewQuickLoadName': newName,
            'MemberLoadViewPosition': viewPosition,
            'AllQuickLoadsData': qlData
        };

        var path = 'api/QuickLoads/';
        return this.postService.requestPostEvent('AddQuickLoadFromMemberLoad', data, path);
    }

    convertQuickLoadMagnitudes(data) {
        var apiRoute = 'api/UOM/ConvertQuickLoadMagnitudes';
        return this.postService.requestSimplePost(apiRoute, data);
    }

    getMagnitudeFriendly(data) {
        var apiRoute = 'api/UOM/GetMagnitudeFriendly';
        return this.postService.requestSimplePost(apiRoute, data);
    }

    convertPartitionLoadToMagnitude(data) {
        var apiRoute = 'api/UOM/ConvertPartitionLoadToMagnitude';
        return this.postService.requestSimplePost(apiRoute, data);
    }
    convertConcentratedLiveLoadToMagnitude(data) {
        var apiRoute = 'api/UOM/ConvertConcentratedLiveLoadToMagnitude';
        return this.postService.requestSimplePost(apiRoute, data);
    }

    setQuickLoadsModelData() {
        var deferred = this.$q.defer();
        if (this.allModelDataService.getQuickLoadsModelData() === null) {
            this.postService.requestGetEvent('GetQuickLoadsData', 'api/QuickLoads/').then((response) => {
                this.allModelDataService.setQuickLoadsModelData(response);
                deferred.resolve();
            })
            .catch(() => {
                //dont do anything?
            });
        } else {
            deferred.resolve();
        }
        return deferred.promise;
    }

    getQuickLoadsModelData() {
        return this.allModelDataService.getQuickLoadsModelData();
    }

    getMemberQuickLoadsList() {
        var quickLoadMemberInfo = this.allModelDataService.getCurrentMemberQuickLoadInfo();
        var quickLoadsList = [];
        var quickLoadsListItem;
        for (var qlIndex = 0; qlIndex < this.getQuickLoadsModelData().QuickLoadsData.length; qlIndex++) {
            if (this.isQuickLoadValidForMember(quickLoadMemberInfo, this.getQuickLoadsModelData().QuickLoadsData[qlIndex])) {
                quickLoadsListItem = {
                    'QLIndex': qlIndex,
                    'QLName': this.getQuickLoadsModelData().QuickLoadsData[qlIndex].Name
                };
                quickLoadsList.push(quickLoadsListItem);
            }
        }
        return quickLoadsList;
    }

    isMemberLoadValidForQuickload(viewPosition) {
        var validForQuickload = false;
        try {
            var loadDefData = this.allModelDataService.getLoadDefDataByViewPosition(viewPosition);
            if (loadDefData !== null) {
                if (loadDefData.LoadType === 3 || loadDefData.LoadType === 2) {
                    if (loadDefData.IsFullLength) {
                        validForQuickload = true;
                    }
                }
            }
        }
        catch (err) {
            // catch errors return false
        }
        return validForQuickload;
    }

    addMemberLoadFromQuickLoad(quickLoadListItem) {
        var data = {
            'selectedQuickLoadData': this.getQuickLoadsModelData().QuickLoadsData[quickLoadListItem.QLIndex]
        };
        var deferred = this.$q.defer();
        this.postService.requestPostEvent('AddMemberLoadFromQuickLoad', data, 'api/QuickLoads/').then(() => {
            deferred.resolve();
        });
        return deferred.promise;
    }

    saveQuickLoadsData(data) {
        var deferred = this.$q.defer();
        this.postService.requestPostEvent('SaveQuickLoadsData', data, 'api/QuickLoads/').then(() => {
            deferred.resolve();
        });
        return deferred.promise;
    }

    isQuickLoadValidForMember(quickLoadMemberInfo, quickLoadData) {
        var quickLoadValid = false;

        if ((quickLoadMemberInfo.IsIBC && quickLoadData.BuildingCodeIBC) || (!quickLoadMemberInfo.IsIBC && quickLoadData.BuildingCodeNBCC)) {
            if ((quickLoadMemberInfo.IsResidential && quickLoadData.ResidentailClass) || (!quickLoadMemberInfo.IsResidential && quickLoadData.CommercialClass)) {

                // floor=1 wall=3 roof=2
                switch (quickLoadMemberInfo.StructuralSystem) {
                    case 1:
                        if (quickLoadData.FloorMembers) {
                            quickLoadValid = true;
                        }
                        break;
                    case 2:
                        if (quickLoadData.RoofMembers) {
                            quickLoadValid = true;
                        }
                        break;
                    case 3:
                        // header=31
                        if (quickLoadData.WallHeaders && quickLoadMemberInfo.MemberType === 31) {
                            quickLoadValid = true;
                        }
                        break;
                    case 4:
                        if (quickLoadData.CeilingMembers) {
                            quickLoadValid = true;
                        }
                        break;
                }
            }
        }

        return quickLoadValid;
    }
}
