export default class loadingProjectController {
    constructor($rootScope, $timeout, $state, $stateParams, allModelDataService,
        projectManagementService, forteCookieService) {
        'ngInject';

        this.$rootScope = $rootScope;
        this.$timeout = $timeout;
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.allModelDataService = allModelDataService;
        this.projectManagementService = projectManagementService;
        this.forteCookieService = forteCookieService;

        this.activate();
    }

    activate () {
        if (this.$stateParams.fileId) {
            //if a file id was passed in, redirect to login. The ID will be saved and used after the user logs in.
            //if not, redirect to login.
            let fileIdToOpen = this.$stateParams.fileId;
            this.allModelDataService.setFileIdToOpen(fileIdToOpen);
            //this.openFile(fileIdToOpen);
            this.$state.go('account.login');
        } else {
            //the user just logged in.
            this.projectManagementService.getUserInfo().then(
                (response) => {
                    this.allModelDataService.setUserProfileIsIncomplete(response.isUserProfileDataIncomplete);
                    this.$rootScope.$broadcast('launchNecessaryModalPopups');

                    let fileIdToOpen = this.allModelDataService.getFileIdToOpen();
                    // if (fileIdToOpen === null) {
                    //     this.forteCookieService.writeOpenToFileManagerOnLogin(!response.openToCurrentFileOnLogin);
                    // }
                    this.forteCookieService.writeOpenToFileManagerOnLogin(true);
                    this.$state.go('application.job');
                },
                (err) => {
                    //what should we do if we cant get the profile info?  the user will be logged out by the auth interceptor service.
                });
        }
    }
    launchNecessaryModalPopups(profileInfoIsIncomplete) {
        this.$timeout(() => {
            this.$rootScope.$emit('launchNecessaryModalPopups', {profileInfoIsIncomplete});
        });
    }

    openFile(fileId, fromLink) {
        this.$timeout(() => {
            let data = {
                'fileId': fileId,
                'fromLink': fromLink
            };
            this.$rootScope.$emit('openSelectedFile', data);
            //set the localstorage fileIdToOpen to null on success.
        });
    }
    openCurrentProject() {
        this.$timeout(() => {
            this.$rootScope.$emit('justLoggedInGetProject', {});
        }, 0, false);
    }

}
// (function () {
//     'use strict';

//     var controllerId = 'loadingProjectController';
//     angular.module('app').controller(controllerId,
//         ['$scope', '$rootScope', '$timeout', loadingProjectController]);
//     //eslint-disable-next-line max-params
//     function loadingProjectController($scope, $rootScope, $timeout) {
//         /* jshint validthis: true */
//         var vm = this;

//         function activate() {
//             $timeout(function() {
//                 $rootScope.$emit('justLoggedInGetProject', {});
//             },0,false);//pass false here to prevent another digest cycle from firing.
//         }

//         activate();
//     };
// }());
