import ngAuthSettings from '../environmentConfigs/environment.dev';
import FlexSearch from 'flexsearch';
import moment from 'moment';

export default class fileSystemService {
    constructor($http, $timeout, common, allModelDataService, postService, fileTree, searchResults, resourceMessagingService, signalrService) {
        'ngInject';
        this.$http = $http;
        this.allModelDataService = allModelDataService;
        this.signalRService = signalrService,
        this.postService = postService;
        this.$q = common.$q();
        this.$timeout = $timeout;
        this.serviceBase = ngAuthSettings.apiServiceBaseUri;
        this.fileTree = fileTree;
        this.searchIndex = null;
        this.searchResults = searchResults;
        this.resourceMessagingService = resourceMessagingService;

        this.currentlyGettingFileSystemData = false;
        this.gettingFileSystemDataPromise = null;
        this.languages = this.resourceMessagingService.getLanguageOptions();
    }

    getFileTree() {
        return this.fileTree;
    }

    clearFileTreeData() {
        this.fileTree.treeRoots = null;
        this.fileTree.allTreeItems = null;
        this.fileTree.timeDataWasQueried = null;
        this.fileTree.rootItems = [];
    }

    search(query) {
        var deferred = this.$q.defer();
        this.searchIndex.search(query).then((response) => {
            this.searchResults.results = response;
            deferred.resolve();
        });
        return deferred.promise;
    }

    buildRoot(rootItem, sortReverse, sortType) {
        //find the full treeItem in the 'allTreeItems' group
        var treeItem = this.fileTree.allTreeItems.find(root => root.id === rootItem.RootTreeItemID);
        treeItem.isRoot = true;
        if (rootItem.Text === 'Root') {
            treeItem.text = this.getText('myFiles');
        } else {
            treeItem.text = rootItem.Text;
        }
        if (rootItem.IsAdmin && rootItem.IsAdmin === true) {
            treeItem.isAdmin = true;
        }
        //compile the list of child files and folders
        //this.buildTreeNodeChildStructure(treeItem, -1);
        //this.sortChildren(treeItem, sortReverse, sortType);
        //treeItem.collapsed = true;
        //add it to the tree
        this.fileTree.rootItems.push(treeItem);
        //if the rootItem is not in the fileTree.treeRoots collection, add it.
        let foundTeamRoot = this.fileTree.treeRoots.find(element => element.RootTreeItemID === rootItem.RootTreeItemID);
        if (!foundTeamRoot) {
            this.fileTree.treeRoots.push(rootItem);
        }
    }

    getFileSystemData(forceUpdate, sortReverse, sortType) {
        //this method is set up so that multiple requests will be bundled together and a single result set will be returned.
        //Meaning: if you make the call to the API and it hasn't returned yet and then you make another call to this function.
        //The promise generated from the request that still active waiting results from the API will be returned to the second call.
        //The hope here is that you will not be able to call getFileSystemData.  The first call is made during login, if that call is
        //not complete by the time the user tries to load the FM ui, a second call will not be made and the results from the first
        //call will be returned to the second call....hopefully..

        this.$timeout(() => {
            this.currentlyGettingFileSystemData = true;
            var deferred = this.$q.defer();
            var now = this.allModelDataService.getUTCTimeNow();
            var path = 'api/FileSystem/';
            if (!this.fileTree.treeRoots) {
                var data = {
                    lastAccessedDate: null
                };
                this.fileTree.timeDataWasQueried = this.allModelDataService.getUTCTimeNow();
                this.postService.requestPostEvent('GetAllFileSystemData', data, path).then((response) => {
                    this.fileTree.treeRoots = response.Trees;
                    this.fileTree.allTreeItems = response.allTreeItems;
                    this.addFilesToSearchIndex(this.fileTree.allTreeItems);
                    this.currentlyGettingFileSystemData = false;
                    deferred.resolve();
                });
            } else if (now.diff(this.fileTree.timeDataWasQueried,'seconds') > 10 || forceUpdate === true) {
                //if the data is stale update it.
                var data = {
                    lastAccessedDate: this.fileTree.timeDataWasQueried
                };
                var updatedQueryTime = this.allModelDataService.getUTCTimeNow();
                this.postService.requestPostEvent('GetAllFileSystemData', data, path).then((response) => {
                    this.fileTree.timeDataWasQueried = updatedQueryTime;
                    // this.fileTree.treeRoots = response.Trees;
                    // this.fileTree.allTreeItems = response.Items;
                    this._consolidateFileSystemChanges(response, sortReverse, sortType);
                    this.currentlyGettingFileSystemData = false;
                    deferred.resolve();
                });
            } else {
                //data isn't that old, dont bother updating
                this.currentlyGettingFileSystemData = false;
                deferred.resolve();
            }
            return deferred.promise;
        }, 500, false);
    }

    getModifiedDateDisplayText(date) {
        var dateFormat = 'MMMM Do, YYYY';
        if (this.languages[1].isSelected) {
            //moment.updateLocale('fr-ca', '/bower_components/moment/locale/fr-ca.js');
            this.setFrenchText();
            dateFormat = 'LL';
        } else {
            moment.locale('en-US');
        }
        var now = moment.utc();
        var then = moment.utc(date);
        var dayDifference = now.diff(then, 'days');
        //2 d
        if (dayDifference > 2) {
            //full date e.g. May 5
            return then.format(dateFormat);
        } else {
            //relative time e.g. 1 day ago.
            return then.fromNow();
        }
    }

    addFilesToSearchIndex(items) {
        if (this.searchIndex === null) {
            this.searchIndex = new FlexSearch({
                encode: 'icase',
                tokenize: 'forward',
                resolution: 9,
                async: true
            });
        }
        items.forEach(element => {
            if (element.isFile === true) {
                this.searchIndex.add(element.id, element.text);
            }
        });
    }
    removeFilesFromSearchIndex(items) {
        items.forEach(element => {
            this.searchIndex.remove(element.id);
        });
    }

    _consolidateFileSystemChanges(newData, sortReverse, sortType) {
        var newItems = newData.Items;
        var newTrees = newData.Trees;
        //if we make significant changes to the AllTreeItems array that cant be updated one by one (like we remove a team)
        //then we need to rebuild the search index.
        var searchShouldBeReindexed = false;
       ////////HANDLE REMOVALS
        //if any roots have been removed, remove all elements in fileTree pertaining to that root
        this.fileTree.treeRoots.forEach(originalRoot => {
            var foundRoot = newTrees.find(newRoot => newRoot.RootTreeItemID === originalRoot.RootTreeItemID);
            if (!foundRoot) {
                //remove the root from the rootItems array
                let updatedRoots = this.fileTree.rootItems.filter(element => element.id !==  originalRoot.RootTreeItemID);
                this.fileTree.rootItems = updatedRoots;
                //remove the root item from the allTreeItems list
                let updatedItemsWithoutRoot = this.fileTree.allTreeItems.filter(element => element.id !== originalRoot.RootTreeItemID);
                this.fileTree.allTreeItems = updatedItemsWithoutRoot;
                //remove all the tree items that had that root as it's owner.
                let updatedItemsWithoutChildren = this.fileTree.allTreeItems.filter(element => element.rootId !== originalRoot.RootTreeItemId);
                this.fileTree.allTreeItems = updatedItemsWithoutChildren;

                searchShouldBeReindexed = true;
            } else {
                if (foundRoot.Text !== 'Root') {
                    //find the corresponding tree item for the team root and update the name.
                    var foundRootTreeItem = this.fileTree.allTreeItems.find(item => item.id === foundRoot.RootTreeItemID);
                    foundRootTreeItem.text = foundRoot.Text;
                }
            }
        });

        //if any fileTreeItems have been removed, remove them from the this.fileTree.allTreeItems
        var removedItemsFromDB = newItems.filter(item => item.isRemoved === true);
        this.removeFilesFromSearchIndex(removedItemsFromDB);
        var removedItemIDsFromDB = removedItemsFromDB.map(item => item.id);

        //remove these items from the all items list.
        var newAllItemsArrayWithItemsNotIncluded = this.fileTree.allTreeItems.filter(item => !removedItemIDsFromDB.includes(item.id));
        this.fileTree.allTreeItems = newAllItemsArrayWithItemsNotIncluded;
        //since we're finished removing the items, remove them from the new items list.
        var updatedNewItemsArrayWithRemovedItemsRemoved = newItems.filter(item => !removedItemIDsFromDB.includes(item.id));
        newItems = updatedNewItemsArrayWithRemovedItemsRemoved;

        //TODO: (do I also need to search through all trees that have been built out and remove these items from the child collections?)

       ////////UPDATE EXISTING OR ADD NEW ITEMS.
        newItems.sort((a,b) => (new Date(b.dateModified) - new Date(a.dateModified)));
        newItems.forEach(element => {
            //find the corresponding element in the tree
            //if it doesnt exist, add it to the fileTree appropriate parent
            var existingEntry = this.fileTree.allTreeItems.find(item => item.id === element.id);
            if (existingEntry) {
                //its been found, update it.
                this._updateTreeNodeDataFromDB(existingEntry, element);
                //update the found elements in the search index
            } else {
                //it's new, add it.
                if (element.isFile === false) {
                    element.folders = [];
                    element.files = [];
                    element.children = [];
                }
                this.fileTree.allTreeItems.push(element);
                this.searchIndex.add(element.id, element.text);
            }
        });

        //if any roots have been added (new teams created) process those
        //!Important!this must be done after all new tre items have been added to the fileTree
        newTrees.forEach(root => {
            var foundRoot = this.fileTree.rootItems.find(oldRoot => root.RootTreeItemID === oldRoot.id);
            // if the new item didnt exist in the old items, add it
            if (!foundRoot) {
                this.buildRoot(root, sortReverse, sortType);
            }
        });

       ////////INSERT CHILDREN IN THE CORRECT PLACE (if the child lists have been built) (is this where i should handle child removal as well?)
        ////get the list of all folders that have been scanned and built
        /////////Commented out because we dont have expanded folders in the mobile ui yet. still using the old navigation methodology.
        // var allExpandedFolders = this.fileTree.allTreeItems.filter(folder => folder.children !== undefined);
        // allExpandedFolders.forEach(parent => {
        //     //should i short circuit this if the update files list doesnt contain any items that have "parent" as a parent
        //     //in other words, should I skip this if it hasn't been updated?
        //     var allChildrenOfThisParent = this.fileTree.allTreeItems.filter(child => child.parentID === parent.id);
        //     var foldersOfThisParent = allChildrenOfThisParent.filter(item => item.isFile === false);
        //     var filesOfThisParent = allChildrenOfThisParent.filter(item => item.isFile === true);

        //     parent.folders = foldersOfThisParent;
        //     parent.files = filesOfThisParent;
        //     parent.folders.forEach(function(element) {
        //         //if there was no value for collapsed, create and set it to true.
        //         if (element.collapsed === undefined || element.collapsed === null) {
        //             element.collapsed = true;
        //         }
        //     });
        //     parent.children = parent.folders.concat(filesOfThisParent);
        //     //this.sortChildren(parent, sortReverse, sortType);
        // });

        if (searchShouldBeReindexed === true) {
            this.searchIndex = null;
            this.addFilesToSearchIndex(this.fileTree.allTreeItems);
        }
    }

    _updateTreeNodeDataFromDB(originalNode, newNode) {
        if (newNode.isRemoved === false) {
            //dont update the name of the myfiles root
            if (newNode.text !== 'Root' && newNode.rootId === 0) {
                if (newNode.text !== originalNode.text) {
                    //updates to the search index are expensive, only do this if "text" was one of the fields that changed
                    originalNode.text = newNode.text;
                    this.searchIndex.update(originalNode.id, originalNode.text);
                }
            }
            originalNode.dateModified = newNode.dateModified;
            originalNode.lastModifiedBy = newNode.lastModifiedBy;
            originalNode.parentID = newNode.parentID;
            originalNode.rootId = newNode.rootId;
        }
    }

    removeItems(treeItems, currentlyOpenedTreeItemId, receivedDataDate) {
        var deferred = this.$q.defer();
        //set the counter to the length of files to move
        var path = 'api/FileSystem/';
        var commaDelimitedTreeItemIds = '';
        for (var i = 0; i < treeItems.length; i++) {
            commaDelimitedTreeItemIds = commaDelimitedTreeItemIds + treeItems[i].id.substring(1) + ',';
        }
        commaDelimitedTreeItemIds = commaDelimitedTreeItemIds.substring(0, commaDelimitedTreeItemIds.length - 1);

        var data = {
            treeItemIds: commaDelimitedTreeItemIds,
            currentlyOpenedTreeItemId: currentlyOpenedTreeItemId.substring(1),
            receivedDataDate: receivedDataDate,
            sessionId: this.signalRService.getConnectionId()
        };
        return this.postService.requestPostEvent('RemoveItems',data,path);
    }

    sendDamageEmail(fileId, details, damageInfo, numDamaged, customerNotes) {
        var data = {
            ForteFileId: fileId.toString(),
            details: details,
            damageInfo: damageInfo,
            numDamaged: numDamaged,
            customerNotes: customerNotes
        };
        var path = 'api/FileSystem/';
        var postEvent = 'ReportDamage';

        return this.postService.requestPostEvent(postEvent,data,path);
    }

    getPathToOpenFile(fileId) {
        var myData = {
            forteFileTreeItemId: fileId,
            receivedDataDate: ''
        };
        var path = 'api/FileSystem/';
        var postevent = 'GetPathToOpenFile';

        return this.postService.requestPostEvent(postevent,myData,path);
    }

    getFolderFoldersOnly(folderId, receivedDataDate) {
        var myData = {
            forteFileTreeItemId: folderId.substring(1),
            receivedDataDate: receivedDataDate
        };
        var path = 'api/FileSystem/';
        var postevent = 'GetFolderFoldersOnly';

        return this.postService.requestPostEvent(postevent,myData,path);
    }

    getRecentFiles(teamId) {
        var data = {
            teamId: teamId
        };

        var path = 'api/FileSystem/';
        var postevent = 'GetRecentFiles';

        return this.postService.requestPostEvent(postevent,data,path);
    }

    getFolder(folderId, receivedDataDate) {
        var myData = {
            forteFileTreeItemId: folderId.substring(1),
            receivedDataDate: receivedDataDate
        };
        var path = 'api/FileSystem/';
        var postevent = 'GetFolder';

        return this.postService.requestPostEvent(postevent,myData,path);
    }

    importFile(file, parentFolderId, receivedDataDate) {
        var myData = {
            file: file,
            parentFolderId: parentFolderId.substring(1),
            receivedDataDate: receivedDataDate,
            echoOn: this.allModelDataService.getEchoLogOn()
        };
        var deferred = this.$q.defer();
        this.$http.post(this.serviceBase + 'api/FileSystem/FileUpload', myData, {
            headers: { 'Content-Type': undefined },
            transformRequest: function (data, headersGetter) {
                var formData = new FormData();
                // formDAta.append('parentFolderID', angular.toJson(data.parentFolderID));
                angular.forEach(data, function (value, key) {
                    formData.append(key, value);
                });

                var headers = headersGetter();
                delete headers['Content-Type'];

                return formData;
            }
        }).then((response) => {
            deferred.resolve(response.data);
        }, (err, status) => {
            deferred.reject(err);
        });
        return deferred.promise;
    }

    // function downloadFile(requestData) {
    //     var deferred = $q.defer();
    //     $http.post(serviceBase + 'api/FileSystem/FileExport', requestData, {responseType:'blob'})
    //       .then(function (response) {
    //           deferred.resolve(response.data);
    //       }, function error(err, status) {
    //           deferred.reject(err);
    //       });
    //     return deferred.promise;
    // }

    downloadFileContent(requestData) {
        this.saveCurrentFile();

        var path = 'api/FileSystem/';
        var postevent = 'DownloadFileContent';
        return this.postService.requestPostEvent(postevent,requestData,path);
    }

    addNewFile(parentFolderId, fileName, receivedDataDate, previousFileIdToClose) {
        this.saveCurrentFile();
        this._clearJobSpecificLocalStorageData();

        var path = 'api/FileSystem/';
        var postevent = 'AddNewFile';
        var newFileData = {
            parentFolderId: parentFolderId.substring(1),
            fileName: fileName,
            receivedDataDate: receivedDataDate,
            previousFileIdToClose: previousFileIdToClose
        };

        return this.postService.requestPostEvent(postevent,newFileData,path);
    }

    openFile(forteFileTreeItemId, previousFileIdToClose) {
        this.saveCurrentFile();
        this._clearJobSpecificLocalStorageData();

        var path = 'api/FileSystem/';
        var postevent = 'OpenFile';
        var openFileData = {
            forteFileTreeItemId: forteFileTreeItemId,
            receivedDataDate: null,
            previousTreeItemId: previousFileIdToClose,
            thisSessionId: this.signalRService.getConnectionId()
        };
        return this.postService.requestPostEvent(postevent,openFileData,path);
    }

    saveCurrentFile() {
        var prData = this.allModelDataService.getPostResponseData();
        if (prData) {
            var data = {
                postResponseData: prData
            };
            var path = 'api/FileSystem/';
            var postevent = 'SaveFile';
            this.postService.requestPostEvent(postevent,data,path);
        }
    }

    _clearJobSpecificLocalStorageData() {
        this.allModelDataService.clearCurrentTab();
        this.allModelDataService.clearJobTreeData();
        this.allModelDataService.clearSelectedJobTreeNode();
    }

    addFolder(parentFolderId, fileName, order, receivedDataDate) {
        var path = 'api/FileSystem/';
        var postevent = 'AddFolder';
        var data = {
            parentFolderId: parentFolderId.substring(1),
            fileName: fileName,
            order: order,
            receivedDataDate: receivedDataDate
        };
        return this.postService.requestPostEvent(postevent,data,path);
    }

    renameFolder(folderId, newName, receivedDataDate) {
        var path = 'api/FileSystem/';
        var postevent = 'RenameFolder';
        var data = {
            folderId: folderId,
            newName: newName,
            receivedDataDate: receivedDataDate
        };

        return this.postService.requestPostEvent(postevent,data,path);
    }

    renameFileWithTreeItemId(fileId, currentlyOpenedFileId, newName, receivedDataDate) {
        var path = 'api/FileSystem/';
        var postevent = 'RenameFileWithTreeItemId';
        const postResponseData = this.allModelDataService.getPostResponseData();
        var data = {
            fileTreeID: fileId,
            currentlyOpenedFileId: currentlyOpenedFileId,
            newName: newName,
            receivedDataDate: receivedDataDate,
            postResponseData,
            sessionId: this.signalRService.getConnectionId()
        };

        var prData = this.allModelDataService.getPostResponseData();
        data['postResponseData'] = prData;

        return this.postService.requestPostEvent(postevent,data,path);
    }
}

// (function () {
//     'use strict';
//     var app = angular.module('app');
//     var serviceId = 'fileSystemService';
//     app.factory(serviceId, ['$http', 'ngAuthSettings', 'common', 'allModelDataService', 'postService', fileSystemService]);

//     function fileSystemService($http, ngAuthSettings, common, allModelDataService, postService) {
//         var $q = common.$q;
//         var serviceBase = ngAuthSettings.apiServiceBaseUri;

//         //this is the decleration of the public methods that will be accessible
//         var service = {
//             getFolder: getFolder,
//             getFolderFoldersOnly: getFolderFoldersOnly,
//             getRecentFiles: getRecentFiles,
//             addNewFile: addNewFile,
//             openFile: openFile,
//             addFolder: addFolder,
//             saveCurrentFile: saveCurrentFile,
//             renameFolder: renameFolder,
//             renameFileWithTreeItemId: renameFileWithTreeItemId,
//             importFile: importFile,
//             downloadFileContent: downloadFileContent,
//             removeItems: removeItems,
//             getPathToOpenFile: getPathToOpenFile,
//             sendDamageEmail: sendDamageEmail
//         };

//         function removeItems(treeItems, currentlyOpenedTreeItemId, receivedDataDate) {
//             var deferred = $q.defer();
//             //set the counter to the length of files to move
//             var path = 'api/FileSystem/';
//             var commaDelimitedTreeItemIds = '';
//             for (var i = 0; i < treeItems.length; i++) {
//                 commaDelimitedTreeItemIds = commaDelimitedTreeItemIds + treeItems[i].id + ',';
//             }
//             commaDelimitedTreeItemIds = commaDelimitedTreeItemIds.substring(0, commaDelimitedTreeItemIds.length - 1);

//             var data = {
//                 treeItemIds: commaDelimitedTreeItemIds,
//                 currentlyOpenedTreeItemId: currentlyOpenedTreeItemId,
//                 receivedDataDate: receivedDataDate
//             };
//             return postService.requestPostEvent('RemoveItems',data,path);
//         }

//         function sendDamageEmail(fileId, details, damageInfo, numDamaged, customerNotes) {
//             var data = {
//                 ForteFileId: fileId.toString(),
//                 details: details,
//                 damageInfo: damageInfo,
//                 numDamaged: numDamaged,
//                 customerNotes: customerNotes
//             };
//             var path = 'api/FileSystem/';
//             var postEvent = 'ReportDamage';

//             return postService.requestPostEvent(postEvent,data,path);
//         }

//         function getPathToOpenFile(fileId) {
//             var myData = {
//                 forteFileTreeItemId: fileId,
//                 receivedDataDate: ''
//             };
//             var path = 'api/FileSystem/';
//             var postevent = 'GetPathToOpenFile';

//             return postService.requestPostEvent(postevent,myData,path);
//         }

//         function getFolderFoldersOnly(folderId, receivedDataDate) {
//             var myData = {
//                 forteFileTreeItemId: folderId,
//                 receivedDataDate: receivedDataDate
//             };
//             var path = 'api/FileSystem/';
//             var postevent = 'GetFolderFoldersOnly';

//             return postService.requestPostEvent(postevent,myData,path);
//         }

//         function getRecentFiles(teamId) {
//             var data = {
//                 teamId: teamId
//             };

//             var path = 'api/FileSystem/';
//             var postevent = 'GetRecentFiles';

//             return postService.requestPostEvent(postevent,data,path);
//         }

//         function getFolder(folderId, receivedDataDate) {
//             var myData = {
//                 forteFileTreeItemId: folderId,
//                 receivedDataDate: receivedDataDate
//             };
//             var path = 'api/FileSystem/';
//             var postevent = 'GetFolder';

//             return postService.requestPostEvent(postevent,myData,path);
//         }

//         function importFile(file, parentFolderId, receivedDataDate) {
//             var myData = {
//                 file: file,
//                 parentFolderId: parentFolderId,
//                 receivedDataDate: receivedDataDate,
//                 echoOn: allModelDataService.getEchoLogOn()
//             };
//             var deferred = $q.defer();
//             $http.post(serviceBase + 'api/FileSystem/FileUpload', myData, {
//                 headers: { 'Content-Type': undefined },
//                 transformRequest: function (data, headersGetter) {
//                     var formData = new FormData();
//                     // formDAta.append('parentFolderID', angular.toJson(data.parentFolderID));
//                     angular.forEach(data, function (value, key) {
//                         formData.append(key, value);
//                     });

//                     var headers = headersGetter();
//                     delete headers['Content-Type'];

//                     return formData;
//                 }
//             }).then(function success(response) {
//                 deferred.resolve(response.data);
//             }, function error(err, status) {
//                 deferred.reject(err);
//             });
//             return deferred.promise;
//         }

//         // function downloadFile(requestData) {
//         //     var deferred = $q.defer();
//         //     $http.post(serviceBase + 'api/FileSystem/FileExport', requestData, {responseType:'blob'})
//         //       .then(function (response) {
//         //           deferred.resolve(response.data);
//         //       }, function error(err, status) {
//         //           deferred.reject(err);
//         //       });
//         //     return deferred.promise;
//         // }

//         function downloadFileContent(requestData) {
//             saveCurrentFile();

//             var path = 'api/FileSystem/';
//             var postevent = 'DownloadFileContent';
//             return postService.requestPostEvent(postevent,requestData,path);
//         }

//         function addNewFile(parentFolderId, fileName, receivedDataDate) {
//             saveCurrentFile();
//             _clearJobSpecificLocalStorageData();

//             var path = 'api/FileSystem/';
//             var postevent = 'AddNewFile';
//             var newFileData = {
//                 parentFolderId: parentFolderId,
//                 fileName: fileName,
//                 receivedDataDate: receivedDataDate
//             };

//             return postService.requestPostEvent(postevent,newFileData,path);
//         }

//         function openFile(forteFileTreeItemId) {
//             saveCurrentFile();
//             _clearJobSpecificLocalStorageData();

//             var path = 'api/FileSystem/';
//             var postevent = 'OpenFile';
//             var openFileData = {
//                 forteFileTreeItemId: forteFileTreeItemId,
//                 receivedDataDate: null
//             };
//             return postService.requestPostEvent(postevent,openFileData,path);
//         }

//         function saveCurrentFile() {
//             var prData = allModelDataService.getPostResponseData();
//             var data = {
//                 postResponseData: prData
//             };
//             if (prData) {
//                 var path = 'api/FileSystem/';
//                 var postevent = 'SaveFile';
//                 postService.requestPostEvent(postevent,data,path);

//                 var saveFileData = {};
//                 saveFileData['postResponseData'] = prData;
//             }
//         }

//         function _clearJobSpecificLocalStorageData() {
//             allModelDataService.clearCurrentTab();
//             allModelDataService.clearJobTreeData();
//             allModelDataService.clearSelectedJobTreeNode();
//         }

//         function addFolder(parentFolderId, fileName, order, receivedDataDate) {
//             var path = 'api/FileSystem/';
//             var postevent = 'AddFolder';
//             var data = {
//                 parentFolderId: parentFolderId,
//                 fileName: fileName,
//                 order: order,
//                 receivedDataDate: receivedDataDate
//             };
//             return postService.requestPostEvent(postevent,data,path);
//         }

//         function renameFolder(folderId, newName, receivedDataDate) {
//             var path = 'api/FileSystem/';
//             var postevent = 'RenameFolder';
//             var data = {
//                 folderId: folderId,
//                 newName: newName,
//                 receivedDataDate: receivedDataDate
//             };

//             return postService.requestPostEvent(postevent,data,path);
//         }

//         function renameFileWithTreeItemId(fileId, newName, receivedDataDate) {
//             var path = 'api/FileSystem/';
//             var postevent = 'RenameFileWithTreeItemId';
//             var data = {
//                 fileTreeID: fileId,
//                 newName: newName,
//                 receivedDataDate: receivedDataDate
//             };

//             var prData = allModelDataService.getPostResponseData();
//             data['postResponseData'] = prData;

//             return postService.requestPostEvent(postevent,data,path);
//         }

//         return service;
//     }
// }());
