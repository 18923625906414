/* eslint-disable operator-linebreak */
export default class tabNavigationService {
    constructor(
        $rootScope,
        $timeout,
        $state,
        spinner,
        postService,
        allModelDataService,
        memberManagementService,
        resourceMessagingService
    ) {
        'ngInject';

        this.$rootScope = $rootScope;
        this.$timeout = $timeout;
        this.$state = $state;
        this.spinner = spinner;
        this.postService = postService;
        this.allModelDataService = allModelDataService;
        this.memberManagementService = memberManagementService;
        this.resourceMessagingService = resourceMessagingService;

        this._visibleTabs = [];
        this.currentTab = this.allModelDataService.tryGetCurrentTab();

        this.handleUpdateIsDesignable = $rootScope.$on(
            'updateIsDesignable',
            (event, isDesignable, isWaitingOnLinkedMember) => {
                this._setIsDesignableForVisibleTabs(
                    isDesignable,
                    isWaitingOnLinkedMember
                );
            }
        );

        this.handleUpdateTabsForHipValleyBeam = $rootScope.$on(
            'updateHipValleyCompleted',
            (event, isCompleted) => {
                this._setHipValleyIsCompletedForVisibleTabs(isCompleted);
            }
        );

        ///////define all possible pages
        //////////the links listed here must also be defined in the config.route.js
        //////////at that root of the "app" folder.
        //////////this contains the code that handles the routing to the
        //////////correct html page e.g. when you
        //////////go to app.MemberInfo then "app/views/MemberInfo.html"
        //////////gets loaded into the master page.
        this.pageMemberInfo = {
            link: 'application.job.memberInfo',
            title: 'memberInfo',
            active: true,
            disabled: false,
            id: 0,
        };
        this.pageSpansSupports = {
            link: 'application.job.spansAndSupports',
            title: 'spansAndSupports',
            active: false,
            disabled: false,
            id: 1,
        };
        this.pageLoads = {
            link: 'application.job.loadsHorizontalMember',
            title: 'loads',
            active: false,
            disabled: false,
            id: 2,
        };
        this.pageHoles = {
            link: 'application.job.holes',
            title: 'holes',
            active: false,
            disabled: false,
            id: 3,
        };
        this.pageLocationAnalysis = {
            link: 'application.job.locationAnalysis',
            title: 'locationAnalysis',
            active: false,
            disabled: false,
            id: 4,
        };
        this.pageFloorPerformance = {
            link: 'application.job.floorPerformance',
            title: 'mainform_component_floorperform',
            active: false,
            disabled: false,
            id: 5,
        };
        this.pageTaperedEnds = {
            link: 'application.job.taperedEnds',
            title: 'Tapered_Ends',
            active: false,
            disabled: false,
            id: 6,
        };
        this.pageProductSelection = {
            link: 'application.job.productSelection',
            title: 'productSelection',
            active: false,
            disabled: false,
            id: 7,
        };
        this.pageSolutions = {
            link: 'application.job.solutions',
            title: 'mainform_component_solutions',
            active: false,
            disabled: false,
            id: 8,
        };
        this.pageReport = {
            link: 'application.job.report',
            title: 'mainform_component_output',
            active: false,
            disabled: false,
            id: 9,
        };
        this.pageJobSettings = {
            link: 'application.job.jobSettings',
            title: 'jobSettingsTitle',
            active: false,
            disabled: false,
            id: 10,
        };
        this.pageLevelProperties = {
            link: 'application.job.level',
            title: 'levelProperties',
            active: false,
            disabled: false,
            id: 11,
        };
        this.pageJobSummary = {
            link: 'application.job.jobSummary',
            title: 'jobSummaryMenu',
            active: false,
            disabled: false,
            id: 12,
        };
        this.pageRoofGeometry = {
            link: 'application.job.roofGeometry',
            title: 'mainform_component_roofgeometry',
            active: false,
            disabled: false,
            id: 13,
        };
        this.pageWindLoading = {
            link: 'application.job.windLoading',
            title: 'mainform_component_windloading',
            active: false,
            disabled: false,
            id: 14,
        };
        this.pageHeightAndSupports = {
            link: 'application.job.heightAndSupports',
            title: 'heightAndSupports',
            active: false,
            disabled: false,
            id: 15,
        };
        this.pageVerticalLoads = {
            link: 'application.job.verticalLoads',
            title: 'loads',
            active: false,
            disabled: false,
            id: 16,
        };
        this.pageSeismicLoading = {
            link: 'application.job.seismicLoading',
            title: 'seismicTabLabel',
            active: false,
            disabled: false,
            id: 17,
        };
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    tabSwitch(link, id) {
        for (var i = 0; i < this._visibleTabs.length; i++) {
            if (this._visibleTabs[i].link === link) {
                this._visibleTabs[i].active = true;
            }
        }
        //perform the tab switch
        this.$state.go(link);
        this.currentTab = id;
        this.allModelDataService.setCurrentTab(this.currentTab);
    }

    _executeTabSwitch(link, id) {
        var splashScreenShown = true;
        var splashScreenPromise;
        //after 350 milliseconds, show the splash screen.
        splashScreenPromise = this.$timeout(() => {
            if (!splashScreenShown) {
                //console.log('tabNavServiceJS-_executeTabSwitch-spinnerShow');
                this.spinner.spinnerShow(this.getText('pleaseWait'));
            }
        }, 350);
        //create a promise for the timeout.
        splashScreenPromise.then(
            () => {
                //if the timeout is hit before being cancelled
                //set to true.
                splashScreenShown = true;
            },
            () => {
                //the timeout was cancelled, set to false
                splashScreenShown = false;
            }
        );

        this.postService.allowChangeEvent().then(() => {
            this._hideOrDismissSplash(splashScreenShown, splashScreenPromise);
            //set the correct tab to active.
            this._setAllTabsNotActive();
            if (
                link === 'application.job.solutions' ||
                link === 'application.job.report'
            ) {
                if (this.allModelDataService.isCurrentMemberDesignable()) {
                    //check if the solutions and report are available to perform the tab switch
                    this.tabSwitch(link, id);
                } else {
                    this.$state.go(this.pageProductSelection.link);
                    this.pageProductSelection.active = true;
                }
                this._setIsDesignableForVisibleTabs(
                    this.allModelDataService.isCurrentMemberDesignable(),
                    this.allModelDataService.isCurrentMemberWaitingOnDesignOfLinkedMember()
                );
            } else {
                this.tabSwitch(link, id);
            }
        });
    }
    _hideOrDismissSplash(splashScreenShown, splashScreenPromise) {
        if (splashScreenShown) {
            //if the splash screen was shown, then hide it.
            //console.log('tabNavServiceJS-_hideOrDismissSplash-spinnerHide');
            this.spinner.spinnerHide();
        } else {
            //if this http post completes before the splash screen was shown,
            //cancel the timeout that shows the splash.
            var wasCanceled = this.$timeout.cancel(splashScreenPromise);
        }
    }

    getVisibleTabsForMember() {
        this._setAllTabsNotActive();
        var prData = this.allModelDataService.getPostResponseData();
        var buildingCode = prData.ApplicationData.JobSettingsData.BuildingCode;
        var currentMemberSDO =
            this.memberManagementService.getCurrentlySelectedMemberSDO();
        var isHipValley = currentMemberSDO.MemberType === 41;
        var isMemberVertical = this.allModelDataService.getIsMemberVertical();

        var enableFloorPerformance =
            currentMemberSDO.StructuralSystem === 1 &&
            currentMemberSDO.MemberType === 1;
        var enableTaperedEnds =
            (currentMemberSDO.StructuralSystem === 1 &&
                currentMemberSDO.MemberType === 2) ||
            (currentMemberSDO.StructuralSystem === 2 &&
                currentMemberSDO.MemberType === 2 &&
                currentMemberSDO.MemberPitch <= 0.25) ||
            (currentMemberSDO.StructuralSystem === 4);

        if (currentMemberSDO.MemberTypeStringTag === 'windsill') {
            this._visibleTabs = [
                this.pageMemberInfo,
                this.pageSpansSupports,
                this.pageLoads,
                this.pageProductSelection,
                this.pageSolutions,
                this.pageReport,
            ];
        } else if (isMemberVertical === true) {
            this._visibleTabs = [
                this.pageMemberInfo,
                this.pageHeightAndSupports,
                this.pageVerticalLoads,
                this.pageProductSelection,
                this.pageSolutions,
                this.pageReport,
            ];
        } else {
            this._visibleTabs = [
                this.pageMemberInfo,
                this.pageRoofGeometry,
                this.pageSpansSupports,
                this.pageLoads,
                this.pageHoles,
                this.pageLocationAnalysis,
                this.pageFloorPerformance,
                this.pageTaperedEnds,
                this.pageProductSelection,
                this.pageSolutions,
                this.pageReport,
            ];
        }

        var filterarray = [];
        if (!isHipValley) {
            // enbale all tabs in case its disabled expect for solutions and report tab
            for (var i = 2; i < this._visibleTabs.length - 2; i++) {
                this._visibleTabs[i].disabled = false;
            }
            this._visibleTabs = this._visibleTabs.filter((element) => {
                return element.id !== this.pageRoofGeometry.id;
            });
        } else {
            this._visibleTabs = this._visibleTabs.filter((element) => {
                return element.id !== this.pageHoles.id;
            });
            //disable tabs if roof geometry is not complete.
            this._setHipValleyIsCompletedForVisibleTabs(
                this.allModelDataService.isHipValleyCompleted()
            );
        }
        if (!enableTaperedEnds) {
            this._visibleTabs = this._visibleTabs.filter((el) => {
                return el.id !== 6;
            });
        }
        filterarray = this._visibleTabs;
        if (!enableFloorPerformance) {
            this._visibleTabs = this._visibleTabs.filter((el) => {
                return el.id !== 5;
            });
        }

        this._setIsDesignableForVisibleTabs(
            this.allModelDataService.isCurrentMemberDesignable(),
            this.allModelDataService.isCurrentMemberWaitingOnDesignOfLinkedMember()
        );
    }

    _setIsDesignableForVisibleTabs(isDesignable, isWaitingOnLinkedMember) {
        for (var i = 0; i < this._visibleTabs.length; i++) {
            if (
                this._visibleTabs[i].link === 'application.job.solutions' ||
                this._visibleTabs[i].link === 'application.job.report'
            ) {
                this._visibleTabs[i].disabled =
                    !isDesignable || isWaitingOnLinkedMember;
            }
        }
    }

    _setHipValleyIsCompletedForVisibleTabs(isCompleted) {
        for (var i = 2; i < this._visibleTabs.length - 2; i++) {
            this._visibleTabs[i].disabled = !isCompleted;
        }
    }

    getVisibleTabsForLevel() {
        this._setAllTabsNotActive();
        this._visibleTabs = [this.pageLevelProperties];
        this.pageLevelProperties.active = true;
        this.currentTab = this.pageLevelProperties.id;
    }

    getVisibleTabsForJobWithActiveJobSummary() {
        this._setAllTabsNotActive();
        if (this.allModelDataService.getBuildingCodeListType() === 0) {
            this._visibleTabs = [
                this.pageJobSettings,
                this.pageJobSummary,
                this.pageWindLoading,
                this.pageSeismicLoading
            ];
        } else {
            this._visibleTabs = [
                this.pageJobSettings,
                this.pageJobSummary,
                this.pageWindLoading
            ];
        }

        this.pageJobSummary.active = true;
        this.currentTab = this.pageJobSummary.id;
        this.$state.go(this.pageJobSummary.link);
    }

    getVisibleTabsForJobWithActiveJobSettings() {
        this._setAllTabsNotActive();
        if (this.allModelDataService.getBuildingCodeListType() === 0) {
            //IBC
            this._visibleTabs = [
                this.pageJobSettings,
                this.pageJobSummary,
                this.pageWindLoading,
                this.pageSeismicLoading
            ];
        } else {
            //NBCC
            this._visibleTabs = [
                this.pageJobSettings,
                this.pageJobSummary,
                this.pageWindLoading
            ];
        }

        this.pageJobSettings.active = true;
        this.currentTab = this.pageJobSettings.id;
        this.$state.go(this.pageJobSettings.link);
    }

    _setAllTabsNotActive() {
        this.pageMemberInfo.active = false;
        this.pageSpansSupports.active = false;
        this.pageLoads.active = false;
        this.pageHoles.active = false;
        this.pageLocationAnalysis.active = false;
        this.pageFloorPerformance.active = false;
        this.pageTaperedEnds.active = false;
        this.pageProductSelection.active = false;
        this.pageSolutions.active = false;
        this.pageReport.active = false;
        this.pageLevelProperties.active = false;
        this.pageJobSettings.active = false;
        this.pageJobSummary.active = false;
        this.pageRoofGeometry.active = false;
        this.pageWindLoading.active = false;
        this.pageSeismicLoading.active = false;
        this.pageHeightAndSupports.active = false;
        this.pageVerticalLoads.active = false;
    }

    isCurrentTabAvailable() {
        var isAvailable = false;
        var prData = this.allModelDataService.getPostResponseData();
        var isHipValleyCompleted =
            prData.MemberData.RoofGeometryData !== null
                ? prData.MemberData.RoofGeometryData
                      .RoofGeometryCalculationsComplete
                : true;
        var currentMemberSDO =
            this.memberManagementService.getCurrentlySelectedMemberSDO();
        var isHipValley = currentMemberSDO.MemberType === 41;

        for (
            var tabIndex = 0;
            tabIndex < this._visibleTabs.length;
            tabIndex++
        ) {
            //iterate through all current tabs
            if (this._visibleTabs[tabIndex].id === this.currentTab) {
                //when you find the current tab...
                //check to see if is is potentially disabled
                // if member type is hip beam, check if isHipValleyCompleted
                if (isHipValley && !isHipValleyCompleted) {
                    if (
                        this._visibleTabs[tabIndex].link ===
                            'application.job.roofGeometry' ||
                        this._visibleTabs[tabIndex].link ===
                            'application.job.memberinfo'
                    ) {
                        isAvailable = true;
                        this._visibleTabs[tabIndex].active = true;
                    } else {
                        isAvailable = false;
                    }
                } else if (
                    this._visibleTabs[tabIndex].link ===
                        'application.job.solutions' ||
                    this._visibleTabs[tabIndex].link ===
                        'application.job.report'
                ) {
                    //check if it could be disabled
                    if (this.allModelDataService.isCurrentMemberDesignable()) {
                        //not disabled, set to avaialble and activ3
                        isAvailable = true;
                        this._visibleTabs[tabIndex].active = true;
                    } else {
                        //it's disabled so set it to false and set the first tab active.
                        isAvailable = false;
                    }
                } else {
                    //it cant be disabled, so it must be active
                    isAvailable = true;
                    this._visibleTabs[tabIndex].active = true;
                }

                break;
            }
        }
        if (!isAvailable) {
            this._visibleTabs[0].active = true;
            this.currentTab = 0;
        }
        return isAvailable;
    }

    visibleTabs() {
        return this._visibleTabs;
    }

    isSolutionsTabEnabled() {
        var solutiontabenabled = false;
        for (var i = 0; i < this._visibleTabs.length; i++) {
            if (
                this._visibleTabs[i].link === 'application.job.solutions' &&
                this._visibleTabs[i].disabled === false
            ) {
                solutiontabenabled = true;
            }
        }
        return solutiontabenabled;
    }

    isReportTabEnabled() {
        var reporttabenabled = false;
        for (var i = 0; i < this._visibleTabs.length; i++) {
            if (
                this._visibleTabs[i].link === 'application.job.report' &&
                this._visibleTabs[i].disabled === false
            ) {
                reporttabenabled = true;
            } else {
                reporttabenabled = false;
            }
        }
        return reporttabenabled;
    }
}
