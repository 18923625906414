
export default class loadsCllService {

    constructor (common, postService) {
        'ngInject';

        this.$q = common.$q();
        this.postService = postService;
        this.httpRequestQueueCounter = 0;
        this.inputQueuePromise;
        this.somethingWaitingOnQueueToFinish = false;
    }

    getSupplementalCLLData() {
        var data = {};
        return this._requestPostEvent('GetSupplementalCLLData', data);
    }

    modifyCllMag(newValue) {
        var data = {
            'value':newValue
        };
        return this._requestPostEvent('ModifyCllMagnitude', data);
    }

    modifyCllTrib(newValue) {
        var data = {
            'value':newValue
        };
        return this._requestPostEvent('ModifyCllTributaryWidth', data);
    }

    modifyCllComment(newValue) {
        var data = {
            'value':newValue
        };
        return this._requestPostEvent('ModifyCllComment', data);
    }

    modifyCllShared(newValue) {
        var data = {
            'value':newValue
        };
        return this._requestPostEvent('ModifyCllShared', data);
    }

    _requestPostEvent(event, data) {
        var path = 'api/LoadsCommercial/';
        return this.postService.requestPostEvent(event, data, path);
    }
    _requestMemberSwitchSafePostEvent(event, data) {
        var path = 'api/LoadsHorizontalY/';
        return this.postService.requestPostMemberSwitchSafe(event, data, path);
    }
}
// (function() {
//     'use strict';
//     var app = angular.module('app');
//     var serviceId = 'loadsCllService';
//     app.factory(serviceId, ['ngAuthSettings', 'common', 'postService', loadsCllService]);

//     function loadsCllService(ngAuthSettings, common, postService) {
//         var $q = common.$q;
//         var serviceBase = ngAuthSettings.apiServiceBaseUri;
//         var httpRequestQueueCounter = 0;
//         var inputQueuePromise;
//         var somethingWaitingOnQueueToFinish = false;

//         //this is the declaration of the public methods that will be accessible
//         var service = {
//             modifyCllMag: modifyCllMag,
//             modifyCllTrib: modifyCllTrib,
//             modifyCllComment: modifyCllComment,
//             modifyCllShared: modifyCllShared,
//             getSupplementalCLLData: getSupplementalCLLData
//         };

//         function getSupplementalCLLData() {
//             var data = {};
//             return _requestPostEvent('GetSupplementalCLLData', data);
//         }

//         function modifyCllMag(newValue) {
//             var data = {
//                 'value':newValue
//             };
//             return _requestPostEvent('ModifyCllMagnitude', data);
//         }

//         function modifyCllTrib(newValue) {
//             var data = {
//                 'value':newValue
//             };
//             return _requestPostEvent('ModifyCllTributaryWidth', data);
//         }

//         function modifyCllComment(newValue) {
//             var data = {
//                 'value':newValue
//             };
//             return _requestPostEvent('ModifyCllComment', data);
//         }

//         function modifyCllShared(newValue) {
//             var data = {
//                 'value':newValue
//             };
//             return _requestPostEvent('ModifyCllShared', data);
//         }

//         function _requestPostEvent(event, data) {
//             var path = 'api/LoadsCommercial/';
//             return postService.requestPostEvent(event, data, path);
//         }
//         function _requestMemberSwitchSafePostEvent(event, data) {
//             var path = 'api/LoadsHorizontalY/';
//             return postService.requestPostMemberSwitchSafe(event, data, path);
//         }

//         return service;
//     }
// }());
