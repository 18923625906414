import ngAuthSettings from '../../environmentConfigs/environment.dev';
export default class emailConfirmController {
    constructor ($location, $http, $state, $timeout, resourceMessagingService, authService) {
        'ngInject';

        this.finishedProcessing = false;
        this.emailConfirmed = false;
        this.submitted = false;
        this.disableSubmitAction = false;
        this.emailSent = false;

        this.$location = $location;
        this.$http = $http;
        this.$state = $state;
        this.$timeout = $timeout;
        this.resourceMessagingService = resourceMessagingService;
        this.ngAuthSettings = ngAuthSettings;
        this.authService = authService;

        this.activate();
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    activate() {
        //get query strings from url
        var userId = this.$location.search()['userId'];
        var code = this.$location.search()['code'];

        this.tryConfirm(userId, code);
    }

    tryConfirm(userId, code) {
        this.$http.post(this.ngAuthSettings.apiServiceBaseUri + 'api/Account/ConfirmUser?userId=' + encodeURIComponent(userId) + '&code=' + encodeURIComponent(code))
            .then((response) => {
                this.finishedProcessing = true;
                this.emailConfirmed = true;
                this.$timeout(() => {
                    this.$state.go('account.login');
                }, 4000);
            }, (err, status) => {
                this.finishedProcessing = true;
                this.emailConfirmed = false;
                this.$state.go('account.resendEmail', {'message':this.getText('unableToConfirmEmailResend')});
            });
    }
}
// (function () {
//     'use strict';
//     var app = angular.module('app');

//     app.controller('emailConfirmController', ['$location', '$http', '$state', '$timeout', 'resourceMessagingService','ngAuthSettings', 'authService',
//         function ($location, $http, $state, $timeout, resourceMessagingService, ngAuthSettings, authService) {
//             /* jshint validthis: true */
//             var vm = this;

//             var serviceBase = ngAuthSettings.apiServiceBaseUri;


//             vm.finishedProcessing = false;
//             vm.emailConfirmed = false;
//             vm.submitted = false;
//             vm.disableSubmitAction = false;
//             vm.emailSent = false;

//             vm.getText = getText;

//             function getText(key) {
//                 return resourceMessagingService.getText(key);
//             }

//             function activate() {
//                 //get query strings from url
//                 var userId = $location.search()['userId'];
//                 var code = $location.search()['code'];

//                 tryConfirm(userId, code);
//             }

//             function tryConfirm(userId, code) {
//                 $http.post(serviceBase + 'api/Account/ConfirmUser?userId=' + encodeURIComponent(userId) + '&code=' + encodeURIComponent(code))
//                     .then(function success(response) {
//                         vm.finishedProcessing = true;
//                         vm.emailConfirmed = true;
//                         $timeout(function () {
//                             $state.go('account.login');
//                         }, 4000);
//                     }, function error(err, status) {
//                         vm.finishedProcessing = true;
//                         vm.emailConfirmed = false;
//                         $state.go('account.resendEmail', {'message':getText('unableToConfirmEmailResend')});
//                     });
//             }

//             activate();
//         }]);
// }());
