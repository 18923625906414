import toastr from 'toastr';
export default class holesController {
    constructor($scope, $location, $uibModal, $timeout, holesService, dirtyCheckService, allModelDataService, resourceMessagingService) {
        'ngInject';

        this.$scope = $scope;
        this.$location = $location;
        this.$uibModal = $uibModal;
        this.$timeout = $timeout;
        this.holesService = holesService;
        this.dirtyCheckService = dirtyCheckService;
        this.resourceMessagingService = resourceMessagingService;
        this.allModelDataService = allModelDataService;

        this.selectedColumnNumber = null;
        this.selectedRowNumber = null;

        this.holeData = null;
        this.rowHeaders = null;
        this.metadata = null;
        this.holeDirections = null;
        this.messageData = null;
        this.holeDirectionDisabled = false;

        this.holesMidDepths = [];

        //got a better work around for edge problem using timeout.
        //works well but microsoft might fix it eventually. 7/21/16 Spencer Meyer commit f0abba7
        this.stepDirection = '';
        this.addedHole = false;
        this.afterDelete = false;

        this.$holeAccordion = $('#holeAccordion');
        this.$holeAccordion.on('show.bs.collapse', '.collapse', () => {
            this.$holeAccordion.find('.collapse.in').collapse('hide');
        });

        this.activate();
    }

    activate() {
        this.$scope.$emit('showGraphicToggle', true);

        this.getHoleMetadata();
        this.setHoleDirectionData();
        this.getHoleData();
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    keyDown(isPending, $event) {
        //ignore everything accept navigation input if the fidle is "pending"
        if (isPending) {
            if (!($event.keyCode === 9 || $event.keyCode === 16)) { //tab and shift keycodes
                $event.stopPropagation();
                $event.preventDefault();
                //console.log('stopped: ' + $event.keyCode);
                return false;
            }
        }
    }

    addHole() {
        this.addedHole = true;
        this.holesService.addHole().then((response) => {
            this.getHoleData();
            toastr.options = {
                'positionClass': 'toast-top-center',
            };
            toastr.success(this.getText('SuccessfullyAdded'));
            this.$holeAccordion.find('.collapse.in').collapse('hide');
        });
    }

    copyHole(holeIndex) {
        this.addedHole = true;
        this.holesService.copyHole(holeIndex).then((response) => {
            this.getHoleData();
            toastr.options = {
                'positionClass': 'toast-top-center',
            };
            toastr.info(this.getText('SuccessfullyCopied'));
            this.$holeAccordion.find('.collapse.in').collapse('hide');
        });
    }

    deleteHole(holeIndex) {
        this.addedHole = false;
        this.holesService.deleteHole(holeIndex)
            .then((response) => {
                this.getHoleData();
                toastr.options = {
                    'positionClass': 'toast-top-center',
                };
                toastr.warning(this.getText('SuccessfullyDeleted'));
                this.$holeAccordion.find('.collapse.in').removeClass('in');
            });
    }

    getHoleData() {
        this.holesService.getHoleData().then((response) => {
            this.holeData = response;
            this.holesMidDepths = [];

            // MidDepth has been removed, change VerticalOffset instead in API, 999 means midDepth
            // work around to update the view
            for (var i = 0; i < this.holeData.length; i++) {
                this.holesMidDepths.push(this.holeData[i].VerticalOffset === 999);
                this.setDisabledFieldsBasedOnType(this.holeData[i]);
            }

            this.formHoles.$setPristine();
        });
    }

    setDisabledFieldsBasedOnType(hole) {
        hole.pending = false;
        if (hole.HoleDirection === 1) {
            // Vertical
            hole.maxHoleSizeDisabled = true;
            hole.holeTypeDisabled = true;
            hole.midDepthDisabled = true;
        } else {
            // Horizontal
            hole.maxHoleSizeDisabled = false;
            hole.holeTypeDisabled = false;
            hole.midDepthDisabled = false;
        }

          //requirements in story #5040
        switch (hole.HoleType) {
            case 4: // HoleTypes.RectangularMaxHeight:
            case 6: // HoleTypes.SlottedMaxHeight:
                hole.showWidth = true;
                hole.showHeight = false;
                break;
            case 5: // HoleTypes.RectangularMaxWidth:
            case 7: //HoleTypes.SlottedMaxWidth:
                hole.showWidth = false;
                hole.showHeight = true;
                break;
            case 1: // HoleTypes.Rectangular:
            case 2: // HoleTypes.Slotted:
            case 0: // HoleTypes.Circular:
                if (hole.HoleDirection === 1) {
                    // Vertical Hole
                    hole.showWidth = true;
                    hole.showHeight = false;
                } else if (hole.MaxHoleSize) {
                    hole.showWidth = false;
                    hole.showHeight = false;
                } else {
                    hole.showWidth = true;
                    hole.showHeight = true;
                }
                break;
            default:
                hole.showHeight = true;
                hole.showWidth = true;
                break;
        }
    }

    getHoleMetadata() {
        this.holesService.getHoleMetadata().then((response) => {
            this.metadata = response;
        });
    }

    setHoleDirectionData() {
        this.holeDirections = [
            { Key: 0, Value: 'horizontal' },
            { Key: 1, Value: 'vertical' }
        ];

        var prData = this.allModelDataService.getPostResponseData();
        var memberManagerData = prData.ApplicationData.MemberManagerData;
        var currentContainerId = memberManagerData.SelectedContainerID;
        var currentMemberId = memberManagerData.SelectedMemberID;
        var memberData = memberManagerData.MemberContainers[currentContainerId].Members[currentMemberId];

        switch (memberData.MemberType) {
            case 1:  // joist
            case 2:  // flushBeam
            case 4:  // dropBeam
            case 31: // header
                this.holeDirectionDisabled = false;
                if (memberData.StructuralSystem === 2) {
                    // roof
                    if (memberData.MemberPitch > 0.25) {
                        this.holeDirectionDisabled = true;
                    }
                }
                break;

            default:
                this.holeDirectionDisabled = true;
                break;
        }
    }

    columnClicked(holeIndex) {
    }

    deselectAll() {
        this.selectedRowNumber = -1;
        this.selectedColumnNumber = -1;
    }

    inputGotFocus(rowIndex, columnIndex) {
        this.selectedRowNumber = rowIndex;
        this.selectedColumnNumber = columnIndex;
    }

    selectAllTextOnFocus($event, rowIndex, columnIndex) {
        $event.target.select();
        this.inputGotFocus(rowIndex, columnIndex);
    }

    typeChanged(index, newType) {
        this.holeData[index].pending = true;
        this.holesService.modifyHoleType(index, newType)
            .then((response) => {
                this.getHoleData();
            });
    }

    directionChanged(index, newDirection) {
        this.holeData[index].pending = true;
        this.holesService.modifyHoleDirection(index, newDirection)
            .then((response) => {
                this.getHoleData();
            });
        this.deselectAll();
    }

    maxHoleSizeChanged(index, newMaxHoleSize) {
        this.holeData[index].pending = true;
        this.holesService.modifyMaxHoleSize(index, newMaxHoleSize)
            .then((response) => {
                this.getHoleData();
            });
    }

    widthChanged(event, index, newWidth) {
        if (this.dirtyCheckService.isDirty(event)) {
            this.holeData[index].pending = true;
            this.holesService.modifyHoleWidth(index, newWidth)
                .then((response) => {
                    this.getHoleData();
                });
            this.deselectAll();
        }
    }

    heightChanged(event, index, newHeight) {
        if (this.dirtyCheckService.isDirty(event)) {
            this.holeData[index].pending = true;
            this.holesService.modifyHoleHeight(index, newHeight)
                .then((response) => {
                    this.getHoleData();
                });
            this.deselectAll();
        }
    }

    locationChanged(event, index, newLocation) {
        if (this.dirtyCheckService.isDirty(event)) {
            this.holeData[index].pending = true;
            this.holesService.modifyHoleLocation(index, newLocation)
                .then((response) => {
                    this.getHoleData();
                });
            this.deselectAll();
        }
    }

    midDepthChanged(index, newMidDepth) {
        this.holeData[index].pending = true;
        this.holesService.modifyHoleMidDepth(index, newMidDepth)
            .then((response) => {
                this.getHoleData();
            });
        this.deselectAll();
    }

    verticalOffsetChanged(event, index, newVerticalOffset) {
        if (this.dirtyCheckService.isDirty(event)) {
            this.holeData[index].pending = true;
            this.holesService.modifyHoleVerticalOffset(index, newVerticalOffset)
                .then((response) => {
                    this.getHoleData();
                });
            this.deselectAll();
        }
    }

    holeCommentChanged(event, index, newComment) {
        if (this.dirtyCheckService.isDirty(event)) {
            this.holeData[index].pending = true;
            this.holesService.modifyHoleComment(index, newComment)
                .then(() => {
                    this.getHoleData();
                });
            this.deselectAll();
        }
    };

    modifyHoleComment(index, holeComment) {
        var modalInstance = this.$uibModal.open({
            animation: true,
            templateUrl: 'modifyCommentModal.html',
            controller: 'holesModifyCommentController',
            controllerAs: 'modifyCommentCtrl',
            windowClass: 'connectorDialog',
            backdrop: 'static',
            size: 'md',
            resolve: {
                items: {
                    comment: holeComment
                }
            }
        });

        modalInstance.result.then((newComment) => {
            this.holeData[index].pending = true;
            this.holesService.modifyHoleComment(index, newComment).then(() => {
                this.getHoleData();
            });
        }, () => {
            this.holeData[index].pending = true;
            this.getHoleData();
        }).finally(() => {});

        this.deselectAll();
    }
}
