export default class tabNavigationController {
    constructor($scope, $state, $timeout, tabNavigationService, resourceMessagingService, smartNavService) {
        'ngInject';

        this.$scope = $scope;
        this.$state = $state;
        this.$timeout = $timeout;
        this.tabNavigationService = tabNavigationService;
        this.resourceMessagingService = resourceMessagingService;
        this.smartNavService = smartNavService;

        this.scrlTabsApi = {};

        this.basicTabsContent = this.getText('tabsStepContent') + '<ul><li>' + this.getText('tabsStepAction') + '</li></ul>';
        this.basicTabsTitle = 'tabsStepTitle';

        ///data expectation:project, level, member
        this.handleUpdateVisibleTabs = $scope.$on('updateVisibleTabs', (event, data) => {
            var selectedTab;
            switch (data) {
                case 'buildingCodeTypeChanged':
                    tabNavigationService.getVisibleTabsForJobWithActiveJobSettings();
                    break;
                case 'jobSettings':
                    if (smartNavService.addingNewFile === true) {
                        tabNavigationService.getVisibleTabsForJobWithActiveJobSettings();
                        smartNavService.addingNewFile = false;
                    }
                    else {
                        tabNavigationService.getVisibleTabsForJobWithActiveJobSummary();
                    }
                    break;
                case 'level':
                    tabNavigationService.getVisibleTabsForLevel();
                    break;
                case 'member':
                    // we already called this in membermanagementController memberNodeSelected() function.
                    // if we call it again here it will overrite the array and we lose the activeTab
                    //tabNavigationService.getVisibleTabsForMember();
                    break;
            }
            this.visibleTabs = tabNavigationService.visibleTabs();
            //this.reCalcScroll();
            //this.scrollIntoView(selectedTab);

            $timeout(() => {
                this.reCalcScroll();
                this.scrollIntoView();
            }, 10);

        });

        $scope.$on('$destroy', () => {
            this.handleUpdateVisibleTabs();
        });

        $scope.$watch(() => {
            return $state.$current.name;
        }, (current) => {
            if ($state.$current.name === 'application.job.jobSettings') {
                this.basicTabsTitle = 'jobSummaryMenu';
            } else if ($state.$current.name === 'application.job.jobSummary') {
                this.basicTabsTitle = 'jobSettingsTitle';
            } else {
                this.basicTabsTitle = 'tabsStepTitle';
            }
        });

    }

    goOrReloadTab(link, index) {
        if (this.$state.$current.name === link) {
            this.smartNavService.navigateOrReload(link);
        }
        else {
            this.tabNavigationService._executeTabSwitch(link, index);
        }
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }


    reCalcScroll() {
        if (this.scrlTabsApi.doRecalculate) {
            this.scrlTabsApi.doRecalculate();
        }
    };

    scrollIntoView(arg) {
        if (this.scrlTabsApi.scrollTabIntoView) {
            this.scrlTabsApi.scrollTabIntoView(arg);
        }
    };

    tabClicked(link, id) {
        this.tabNavigationService._executeTabSwitch(link, id);
    };
}
