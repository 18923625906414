export default class notchedFlangeService {
    constructor(common, postService) {
        'ngInject';

        this.$q = common.$q();
        this.postService = postService;

        this.httpRequestQueueCounter = 0;
        this.inputQueuePromise;
        this.somethingWaitingOnQueueToFinish = false;

    }

    getQueueCounter() {
        this.inputQueuePromise = this.$q.defer();
        if (this.httpRequestQueueCounter === 0)
        {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.somethingWaitingOnQueueToFinish = true;
        }
        return this.inputQueuePromise.promise;
    }

    getMetaData() {
        var data = {};
        return this._requestPostEvent('GetMetadata', data);
    }

    addNotch() {
        var data = {};
        return this._requestPostEvent('AddNotch', data);
    }

    deleteNotch(notchIndex) {
        var data = {
            index: notchIndex
        };
        return this._requestPostEvent('RemoveNotch', data);
    }

    modifyInputType(notchIndex, newType) {
        var data = {
            index: notchIndex,
            newValue: newType
        };
        return this._requestPostEvent('ModifyInputType', data);
    }

    modifyLocation(notchIndex, newWidth) {
        var data = {
            index: notchIndex,
            newValue: newWidth
        };
        return this._requestPostEvent('ModifyLocation', data);
    }

    modifyLength(notchIndex, newWidth) {
        var data = {
            index: notchIndex,
            newValue: newWidth
        };
        return this._requestPostEvent('ModifyLength', data);
    }

    modifyDepth(notchIndex, newWidth) {
        var data = {
            index: notchIndex,
            newValue: newWidth
        };
        return this._requestPostEvent('ModifyDepth', data);
    }

    modifyComment(notchIndex, newComment) {
        var data = {
            index: notchIndex,
            newValue: newComment
        };
        return this._requestPostEvent('ModifyComment', data);
    }

    _requestPostEvent(notchEvent, notchData) {
        var path = 'api/NotchedFlange/';
        return this.postService.requestPostEvent(notchEvent, notchData, path);
    }
}
// (function () {
//     'use strict';
//     var app = angular.module('app');
//     var serviceId = 'notchedFlangeService';
//     app.factory(serviceId, ['$http', 'ngAuthSettings', 'common', 'resourceMessagingService','postService', notchedFlangeService]);

//     function notchedFlangeService($http, ngAuthSettings, common, resourceMessagingService, postService) {
//         var $q = common.$q;
//         var serviceBase = ngAuthSettings.apiServiceBaseUri;
//         var httpRequestQueueCounter = 0;
//         var inputQueuePromise;
//         var somethingWaitingOnQueueToFinish = false;

//         var service = {
//             getQueueCounter: getQueueCounter,
//             getMetaData: getMetaData,
//             addNotch: addNotch,
//             deleteNotch: deleteNotch,
//             modifyInputType: modifyInputType,
//             modifyLocation: modifyLocation,
//             modifyLength: modifyLength,
//             modifyDepth: modifyDepth,
//             modifyComment: modifyComment
//         };

//         return service;

//         function getQueueCounter() {
//             inputQueuePromise = $q.defer();
//             if (httpRequestQueueCounter === 0)
//             {
//                 inputQueuePromise.resolve();
//                 return inputQueuePromise.promise;
//             }
//             else {
//                 somethingWaitingOnQueueToFinish = true;
//             }
//             return inputQueuePromise.promise;
//         }

//         function getMetaData() {
//             var data = {};
//             return _requestPostEvent('GetMetadata', data);
//         }

//         function addNotch() {
//             var data = {};
//             return _requestPostEvent('AddNotch', data);
//         }

//         function deleteNotch(notchIndex) {
//             var data = {
//                 index: notchIndex
//             };
//             return _requestPostEvent('RemoveNotch', data);
//         }

//         function modifyInputType(notchIndex, newType) {
//             var data = {
//                 index: notchIndex,
//                 newValue: newType
//             };
//             return _requestPostEvent('ModifyInputType', data);
//         }

//         function modifyLocation(notchIndex, newWidth) {
//             var data = {
//                 index: notchIndex,
//                 newValue: newWidth
//             };
//             return _requestPostEvent('ModifyLocation', data);
//         }

//         function modifyLength(notchIndex, newWidth) {
//             var data = {
//                 index: notchIndex,
//                 newValue: newWidth
//             };
//             return _requestPostEvent('ModifyLength', data);
//         }

//         function modifyDepth(notchIndex, newWidth) {
//             var data = {
//                 index: notchIndex,
//                 newValue: newWidth
//             };
//             return _requestPostEvent('ModifyDepth', data);
//         }

//         function modifyComment(notchIndex, newComment) {
//             var data = {
//                 index: notchIndex,
//                 newValue: newComment
//             };
//             return _requestPostEvent('ModifyComment', data);
//         }

//         function _requestPostEvent(notchEvent, notchData) {
//             var path = 'api/NotchedFlange/';
//             return postService.requestPostEvent(notchEvent, notchData, path);
//         }
//     }
// }());
