export default function ngClickDirectiveConfig ($provide) {
    'ngInject';
    $provide.decorator('ngClickDirective', ($delegate,$timeout) => {
        var original = $delegate[0].compile;
        var delay = 500;
        $delegate[0].compile = (element, attrs, transclude) => {

            var disabled = false;
            function onClick(evt) {
                if (disabled) {
                    evt.preventDefault();
                    evt.stopImmediatePropagation();
                } else {
                    disabled = true;
                    $timeout(() => { disabled = false; }, delay, false);
                }
            }
            //   scope.$on('$destroy', function () { iElement.off('click', onClick); });
            element.on('click', onClick);

            return original(element, attrs, transclude);
        };
        return $delegate;
    });
}
    // Override ng-click directive to prevent multiple clicks on a button
    // app.config(['$provide', function ($provide) {
    //     $provide.decorator('ngClickDirective',['$delegate','$timeout', function ($delegate,$timeout) {
    //         var original = $delegate[0].compile;
    //         var delay = 500;
    //         $delegate[0].compile = function (element, attrs, transclude) {

    //             var disabled = false;
    //             function onClick(evt) {
    //                 if (disabled) {
    //                     evt.preventDefault();
    //                     evt.stopImmediatePropagation();
    //                 } else {
    //                     disabled = true;
    //                     $timeout(function () { disabled = false; }, delay, false);
    //                 }
    //             }
    //             //   scope.$on('$destroy', function () { iElement.off('click', onClick); });
    //             element.on('click', onClick);

    //             return original(element, attrs, transclude);
    //         };
    //         return $delegate;
    //     }]);
    // }]);
