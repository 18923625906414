export default class loadsHorizYService {
    constructor(common, allModelDataService, postService) {
        'ngInject';
        this.$q = common.$q();
        this.allModelDataService = allModelDataService;
        this.postService = postService;

        this.httpRequestQueueCounter = 0;
        this.promiseQueue = [];
        this.waiting = false;
    }

    getQueueCounter() {
        this.inputQueuePromise = this.$q.defer();
        if (this.httpRequestQueueCounter === 0) {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        } else {
            this.somethingWaitingOnQueueToFinish = true;
        }
        return this.inputQueuePromise.promise;
    }

    enableLoadEditing() {
        var data = {};
        return this._requestPostEvent('EnableLoadEditing', data);
    }

    getSessionLoadData() {
        var prData = this.allModelDataService.getPostResponseData();
        return prData.MemberData.HorizontalYAxisLoadData;
    }

    getJobSettingsData() {
        var prData = this.allModelDataService.getPostResponseData();
        return prData.ApplicationData.JobSettingsData;
    }

    getTransferableReactionData(data) {
        return this._requestTransferReactionsPostEvent('GetTransferableReactionDataForMemberIdTag', data);
    }

    addNewTransferredReaction(data) {
        return this._requestTransferReactionsSafePostEvent('AddNewTransferredReaction', data);
    }

    updateTransferredReaction(data) {
        return this._requestTransferReactionsSafePostEvent('UpdateTransferredReaction', data);
    }

    modifyLoadPlacement(viewPosition, loadPlacementEnumInt) {
        var data = {
            'viewPosition': viewPosition,
            'integerValue': loadPlacementEnumInt
        };
        return this._requestPostEvent('ModifyLoadPlacement', data);
    }

    modifyLoadType(viewPosition, loadTypeEnumInt) {
        var data = {
            'viewPosition': viewPosition,
            'integerValue': loadTypeEnumInt
        };
        return this._requestPostEvent('ModifyLoadType', data);
    }

    addLoad(viewPosition) {
        var data = {
            'viewPosition': viewPosition
        };
        return this._requestPostEvent('AddLoad', data);
    }

    copyLoad(viewPosition) {
        var data = {
            'viewPosition': viewPosition
        };
        return this._requestPostEvent('CopyLoad', data);
    }

    deleteLoad(viewPosition) {
        var data = {
            'viewPosition': viewPosition
        };
        return this._requestPostEvent('DeleteLoad', data);
    }

    modifyLoadIsFullLength(viewPosition, isFullLength) {
        var data = {
            'viewPosition': viewPosition,
            'boolValue': isFullLength
        };
        return this._requestPostEvent('ModifyLoadIsFullLength', data);
    }

    saveLoadAsDefault() {
        var currentPostResponseData = this.allModelDataService.getPostResponseData();
        return this._requestPostEvent('SaveLoadsAsDefaults', currentPostResponseData);
    }

    modifyLoadStartLocation(viewPosition, value) {
        var data = {
            'viewPosition': viewPosition,
            'newValue': value
        };
        return this._requestPostEvent('ModifyLoadStartLocation', data);
    }

    modifyLoadEndLocation(viewPosition, value) {
        var data = {
            'viewPosition': viewPosition,
            'newValue': value
        };
        return this._requestPostEvent('ModifyLoadEndLocation', data);
    }

    modifyTribWidthStart(viewPosition, value) {
        var data = {
            'viewPosition': viewPosition,
            'newValue': value
        };
        return this._requestPostEvent('ModifyTribWidthStart', data);
    }

    modifyTribWidthEnd(viewPosition, value) {
        var data = {
            'viewPosition': viewPosition,
            'newValue': value
        };
        return this._requestPostEvent('ModifyTribWidthEnd', data);
    }

    modifyRoofSlope(viewPosition, value) {
        var data = {
            'viewPosition': viewPosition,
            'newValue': value
        };
        return this._requestPostEvent('ModifyRoofSlope', data);
    }

    modifyCombine(viewPosition, newValue) {
        var data = {
            'viewPosition': viewPosition,
            'boolValue': newValue
        };
        return this._requestPostEvent('ModifyCombine', data);
    }
    modifyLoadComment(viewPosition, value) {
        var data = {
            'viewPosition': viewPosition,
            'newValue': value
        };
        return this._requestPostEvent('ModifyLoadComment', data);
    }

    modifyDeadLoadStartMagnitude(viewPosition, value) {
        var data = {
            'viewPosition': viewPosition,
            'newValue': value
        };
        return this._requestPostEvent('ModifyDeadLoadStartMagnitude', data);
    }

    modifyDeadLoadEndMagnitude(viewPosition, value) {
        var data = {
            'viewPosition': viewPosition,
            'newValue': value
        };
        return this._requestPostEvent('ModifyDeadLoadEndMagnitude', data);
    }

    modifyFloorLoadStartMagnitude(viewPosition, value) {
        var data = {
            'viewPosition': viewPosition,
            'newValue': value
        };
        return this._requestPostEvent('ModifyFloorLoadStartMagnitude', data);
    }

    modifyFloorLoadEndMagnitude(viewPosition, value) {
        var data = {
            'viewPosition': viewPosition,
            'newValue': value
        };
        return this._requestPostEvent('ModifyFloorLoadEndMagnitude', data);
    }

    modifyRoofLoadStartMagnitude(viewPosition, value) {
        var data = {
            'viewPosition': viewPosition,
            'newValue': value
        };
        return this._requestPostEvent('ModifyRoofLoadStartMagnitude', data);
    }

    modifyRoofLoadEndMagnitude(viewPosition, value) {
        var data = {
            'viewPosition': viewPosition,
            'newValue': value
        };
        return this._requestPostEvent('ModifyRoofLoadEndMagnitude', data);
    }

    modifySnowLoadStartMagnitude(viewPosition, value) {
        var data = {
            'viewPosition': viewPosition,
            'newValue': value
        };
        return this._requestPostEvent('ModifySnowLoadStartMagnitude', data);
    }

    modifySnowLoadEndMagnitude(viewPosition, value) {
        var data = {
            'viewPosition': viewPosition,
            'newValue': value
        };
        return this._requestPostEvent('ModifySnowLoadEndMagnitude', data);
    }

    modifyWindLoadStartMagnitude(viewPosition, value) {
        var data = {
            'viewPosition': viewPosition,
            'newValue': value
        };
        return this._requestPostEvent('ModifyWindLoadStartMagnitude', data);
    }

    modifyWindLoadEndMagnitude(viewPosition, value) {
        var data = {
            'viewPosition': viewPosition,
            'newValue': value
        };
        return this._requestPostEvent('ModifyWindLoadEndMagnitude', data);
    }

    modifySeismicLoadStartMagnitude(viewPosition, value) {
        var data = {
            'viewPosition': viewPosition,
            'newValue': value
        };
        return this._requestPostEvent('ModifySeismicLoadStartMagnitude', data);
    }

    modifySeismicLoadEndMagnitude(viewPosition, value) {
        var data = {
            'viewPosition': viewPosition,
            'newValue': value
        };
        return this._requestPostEvent('ModifySeismicLoadEndMagnitude', data);
    }

    getSupplementalVPData(viewPosition) {
        var data = {
            'viewPosition': viewPosition
        };
        return this._requestPostEvent('GetSupplementalVPData', data);
    }

    getMetaData() {
        var data = {};
        return this._requestPostEvent('GetMetaData', data);
    }

    _requestPostEvent(event, data) {
        var path = 'api/LoadsHorizontalY/';
        return this.postService.requestPostEvent(event, data, path);
    }
    _requestMemberSwitchSafePostEvent(event, data) {
        var path = 'api/LoadsHorizontalY/';
        return this.postService.requestPostMemberSwitchSafe(event, data, path);
    }

    _requestTransferReactionsPostEvent(event, data) {
        var path = 'api/TransferReactions/';
        return this.postService.requestPostEvent(event, data, path);
    }

    _requestTransferReactionsSafePostEvent(event, data) {
        var path = 'api/TransferReactions/';
        return this.postService.requestPostMemberSwitchSafe(event, data, path);
    }

    _incrementQueueCounter() {
        this.httpRequestQueueCounter++;
    }

    _decrementQueueCounter() {
        this.httpRequestQueueCounter--;
        if (this.somethingWaitingOnQueueToFinish && this.httpRequestQueueCounter === 0) {
            this.somethingWaitingOnQueueToFinish = false;
            this.inputQueuePromise.resolve();
        }
    }
}
