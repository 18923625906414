// import ngAuthSettings from '../environment';
export default class connectorDialogService {
    constructor(common, allModelDataService, postService) {
        'ngInject';

        // this.$http = $http;
        // this.common = common;
        var $q = common.$q();

        this.allModelDataService = allModelDataService;
        this.postService = postService;

        // this.$q = common.$q();
        // this.serviceBase = ngAuthSettings.apiServiceBaseUri;
        this.inputQueuePromise = null;
        this.httpRequestQueueCounter = 0;
        this.somethingWaitingOnQueueToFinish = false;

    }

    getQueueCounter() {
        this.inputQueuePromise = this.$q.defer();
        if (this.httpRequestQueueCounter === 0)
        {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.somethingWaitingOnQueueToFinish = true;
        }
        return this.inputQueuePromise.promise;
    }

    _requestPostEvent(connectorEvent, connectorData) {
        var path = 'api/Connector/';
        return this.postService.requestPostEvent(connectorEvent, connectorData, path);
    }

    modifyConcealedFlanges(supportIndex, isConcealed) {
        var data = {
            'index': supportIndex,
            'newValue': isConcealed
        };
        return this._requestPostEvent('modifyConcealedFlanges',data);
    }

    getConnectorData() {
        var data = {};
        return this._requestPostEvent('GetConnectorData', data);
    }

    postConnectorData(data) {
        return this._requestPostEvent('PostData',data);
    }

    getFriendlyDepth(depthString, depthSingle) {
        var prData = this.allModelDataService.getPostResponseData();
        var data = {'UOMSetting': prData.ApplicationData.ProgramSettingsData.UOM,
            'depthFriendly': depthString,
            'depthValue': depthSingle};

        //return _requestPostEvent('UserDepthUOM', data);
        var apiRoute = 'api/UOM/ConvertDepthSmallLengthUnits';
        return this.postService.requestSimplePost(apiRoute,data);
    }

    _incrementQueueCounter() {
        this.httpRequestQueueCounter++;
    }

    _decrementQueueCounter() {
        this.httpRequestQueueCounter--;
        if (this.somethingWaitingOnQueueToFinish && this.httpRequestQueueCounter === 0) {
            this.inputQueuePromise.resolve();
        }
    }
}
// (function () {
//     'use strict';
//     var app = angular.module('app');
//     app.factory('connectorDialogService', ['$http', 'ngAuthSettings', 'common', 'allModelDataService', 'postService',
//         function ($http, ngAuthSettings, common, allModelDataService, postService) {
//             var $q = common.$q;
//             var serviceBase = ngAuthSettings.apiServiceBaseUri;
//             var inputQueuePromise;
//             var httpRequestQueueCounter = 0;
//             var somethingWaitingOnQueueToFinish = false;

//             var service = {
//                 getQueueCounter: getQueueCounter,
//                 modifyConcealedFlanges: modifyConcealedFlanges,
//                 getConnectorData: getConnectorData,
//                 getFriendlyDepth: getFriendlyDepth,
//                 postConnectorData: postConnectorData
//             };

//             function getQueueCounter() {
//                 inputQueuePromise = $q.defer();
//                 if (httpRequestQueueCounter === 0)
//                 {
//                     inputQueuePromise.resolve();
//                     return inputQueuePromise.promise;
//                 }
//                 else {
//                     somethingWaitingOnQueueToFinish = true;
//                 }
//                 return inputQueuePromise.promise;
//             }

//             function _requestPostEvent(connectorEvent, connectorData) {
//                 var path = 'api/Connector/';
//                 return postService.requestPostEvent(connectorEvent, connectorData, path);
//             }

//             function modifyConcealedFlanges(supportIndex, isConcealed) {
//                 var data = {
//                     'index': supportIndex,
//                     'newValue': isConcealed
//                 };
//                 return _requestPostEvent('modifyConcealedFlanges',data);
//             }

//             function getConnectorData() {
//                 var data = {};
//                 return _requestPostEvent('GetConnectorData', data);
//             }

//             function postConnectorData(data) {
//                 return _requestPostEvent('PostData',data);
//             }

//             function getFriendlyDepth(depthString, depthSingle) {
//                 var prData = allModelDataService.getPostResponseData();
//                 var data = {'UOMSetting': prData.ApplicationData.ProgramSettingsData.UOM,
//                     'depthFriendly': depthString,
//                     'depthValue': depthSingle};

//                 //return _requestPostEvent('UserDepthUOM', data);
//                 var apiRoute = 'api/UOM/ConvertDepthSmallLengthUnits';
//                 return postService.requestSimplePost(apiRoute,data);
//             }

//             function _incrementQueueCounter() {
//                 httpRequestQueueCounter++;
//             }

//             function _decrementQueueCounter() {
//                 httpRequestQueueCounter--;
//                 if (somethingWaitingOnQueueToFinish && httpRequestQueueCounter === 0) {
//                     inputQueuePromise.resolve();
//                 }
//             }

//             return service;
//         }]);
// }());
