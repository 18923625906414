export default class productSelectionController {
    constructor(
        $scope,
        $window,
        $timeout,
        $state,
        common,
        spinner,
        allModelDataService,
        productSelectionService,
        materialsManagementService,
        resourceMessagingService,
        tabNavigationService,
        materialAvailabilityService
    ) {
        'ngInject';

        this.$scope = $scope;
        this.$state = $state;
        this.$timeout = $timeout;
        this.spinner = spinner;
        this.allModelDataService = allModelDataService;
        this.productSelectionService = productSelectionService;
        this.materialsManagementService = materialsManagementService;
        this.resourceMessagingService = resourceMessagingService;
        this.tabNavigationService = tabNavigationService;
        this.materialAvailabilityService = materialAvailabilityService;

        this.$q = common.$q();
        this.maxVisibleProductSize = null;
        this.psMetadata = null;
        this.psSDO = null;
        this.customDepth = null;
        this.customSpacing = null;
        this.customDepthVisible = null;
        this.visibleProductSize;
        this.productBlacklist = null;
        this.productDataPrepared = false;
        this.activeProductGroups = null;
        this.untreatedProductGroups = null;
        this.treatedProductGroups = null;
        this.isPlankSelected = false;
        this.seriesProduct = null;
        this.mixedAvailableSeries = null;
        this.isIBC = this.allModelDataService.isBuildingCodeIBC;

        this.ProductGroups = { Available: [], Selected: [] };
        this.ProductSeries = { Available: [], Selected: [], Visible: [] };
        this.ProductDepths = { Available: [], Selected: [], Visible: [] };
        this.ProductSpacings = { Available: [], Selected: [], Visible: [] };
        this.ProductPlies = { Available: [], Selected: [] };
        this.ProductOrientations = { Available: [], Selected: [], Visible: [] };

        this.productSelectionTabStepContent = 'productSelectionTabStepContent';
        this.productSeriesContent =
            this.getText('product_productStepContent') +
            '<ul><li>' +
            this.getText('productSeriesStepAction') +
            '</li></ul>';
        this.selectProductsStepContent =
            this.getText('product_selectPSContent') +
            '<ul><li>' +
            this.getText('selectProductsStepAction') +
            '</li></ul>';
        this.selectDepthsStepContent =
            this.getText('selectDepthsStepContent') +
            '<ul><li>' +
            this.getText('selectDepthsStepAction') +
            '</li></ul>';
        this.selectPliesStepContent =
            this.getText('selectPliesStepContent') +
            '<ul><li>' +
            this.getText('selectPliesStepAction') +
            '</li></ul>';

        this.$scope.$watch(
            () => {
                return $window.innerHeight;
            },
            (value) => {
                this.maxVisibleProductSize = (value - 230) / 20;
                this.updateProductVisibleSize();
            }
        );

        $('#customDepth').keypress((event) => {
            if (event.which === 45 || event.which === 189) {
                event.preventDefault();
            }
        });

        $('#customSpacing').keypress((event) => {
            if (event.which === 45 || event.which === 189) {
                event.preventDefault();
            }
        });

        $scope.$watch(
            () => {
                return $window.innerHeight;
            },
            (value) => {
                this.maxVisibleProductSize = (value - 230) / 20;
                this.updateProductVisibleSize();
            }
        );

        this.activate();
    }

    activate() {
        // Hide the graphic toggle
        this.$scope.$emit('showGraphicToggle', false);
        this.isMemberWindSill = this.allModelDataService.getIsMemberWindSill();
        this.isOrientationsVisible =
            this.allModelDataService.getOrientationsVisible();

        //this.getProductSelectionMetadata();
        this.getProductSelectionSDO();
        this.customDepthVisible =
            this.allModelDataService.getIsMemberVertical() === false;
    }

    getProductSelectionSDO() {
        this.psSDO = this.productSelectionService.getProductSelectionSDO();
        this.productSelectionService.getProductData().then(
            (response) => {
                this.materialAvailabilityService
                    .getmaterialAvailabilityData()
                    .then((materialAvailabilityResponse) => {
                        this.seriesUnavailable =
                            materialAvailabilityResponse.seriesUnavailable;
                        this.seriesSpecialOrder =
                            materialAvailabilityResponse.seriesSpecialOrder;
                        this.productsNotAvailable = Object.keys(materialAvailabilityResponse.productsNotAvailable).map(function(id) {
                            return Number(id);
                        });
                        this.productsSpecialOrder = Object.keys(materialAvailabilityResponse.productsSpecialOrder).map(function(id) {
                            return Number(id);
                        });
                        //this.initialLoadComplete = true;
                        this.availablePreservativeTreatements =
                            response.preservativeTreatmentChoices;
                        this.showSpacingsList = response.showSpacingsList;
                        this.customSpacingFriendly =
                            response.customSpacingFriendly;
                        this.processCustomDepthMetaData(response);
                        this.processProductSelectionResponseForDisplay(
                            response.postResponseData
                        );
                    });
            },
            (error) => {}
        );
    }

    // eslint-disable-next-line complexity
    processProductSelectionResponseForDisplay(prData) {
        this.psData = prData.MemberData.ProductSelectionData;
        //fill a local variable with a list of items that are selected.  might be able to improve this when we move to es6 using something like linq.
        this.psData.SelectedProductGroupKeys = [];
        this.psData.SelectedSeriesKeys = [];
        this.psData.SelectedDepthsKeys = [];
        this.psData.SelectedSpacingsKeys = [];
        this.psData.SelectedPliesKeys = [];
        this.psData.SelectedOrientationsKeys = [];
        for (var index = 0; index < this.psData.ProductGroups.length; index++) {
            var element = this.psData.ProductGroups[index];
            if (element.Value.Selected === true) {
                this.psData.SelectedProductGroupKeys.push(element.Key);
            }
        }
        for (var index = 0; index < this.psData.Series.length; index++) {
            var element = this.psData.Series[index];
            if (element.Value.Selected === true) {
                this.psData.SelectedSeriesKeys.push(element.Key);
            }
            this.updateProductVisibleSize();
        }
        for (var index = 0; index < this.psData.Depths.length; index++) {
            var element = this.psData.Depths[index];
            if (element.Value.Selected === true) {
                this.psData.SelectedDepthsKeys.push(element.Key);
            }
        }
        for (var index = 0; index < this.psData.Spacings.length; index++) {
            var element = this.psData.Spacings[index];
            if (element.Value.Selected === true) {
                this.psData.SelectedSpacingsKeys.push(element.Key);
            }
        }
        for (var index = 0; index < this.psData.Plies.length; index++) {
            var element = this.psData.Plies[index];
            if (element.Value.Selected === true) {
                this.psData.SelectedPliesKeys.push(element.Key);
            }
        }
        for (var index = 0; index < this.psData.Orientations.length; index++) {
            var element = this.psData.Orientations[index];
            this.isPlankSelected = false;
            if (element.Value.Selected === true) {
                this.psData.SelectedOrientationsKeys.push(element.Key);
                if (element.Key === 1) {
                    this.isPlankSelected = true;
                }
            }
        }
        // get the product id list to display icons at depth level. Do this only if there is one series selected
        if (this.seriesProduct === null && this.psData.SelectedSeriesKeys.length === 1) {
            this.handleSelectedSeriesCheckboxChanged();
        }
        // get the mixedAvailableSeries list to display icons at series level. Do this only if there are groups selected
        if (this.mixedAvailableSeries === null && this.psData.SelectedProductGroupKeys.length >= 1) {
            this.handleSelectedProductGroupChanged();
        }
    }
    handleSelectedProductGroupChanged() {
        this.productSelectionService
            .handleSelectedProductGroupChanged(this.psData.ProductGroups)
            .then(
                (response) => {
                    this.processCustomDepthMetaData(response);
                    this.processProductSelectionResponseForDisplay(
                        response.postResponseData
                    );
                    this.mixedAvailableSeries = response.mixedAvailableSeries;
                },
                (error) => {}
            );
    }
    handleSelectedSeriesChanged(updatedList = null) {
        let listToPassToApi = this.psData.SelectedSeriesKeys;
        if (updatedList !== null) {
            listToPassToApi = updatedList;
        }
        this.productSelectionService
            .handleSelectedSeriesChanged(listToPassToApi)
            .then(
                (response) => {
                    this.processCustomDepthMetaData(response);
                    this.processProductSelectionResponseForDisplay(
                        response.postResponseData
                    );
                    this.seriesProduct = response.seriesProducts.filter((prodSeries) =>
                        this.psData.Depths.find((element) => prodSeries.depthId === element.Key)
                    );
                },
                (error) => {}
            );
    }
    handleSelectedSeriesCheckboxChanged() {
        let newSeriesList = [];
        for (var index = 0; index < this.psData.Series.length; index++) {
            var element = this.psData.Series[index];
            if (element.Value.Selected === true) {
                newSeriesList.push(element.Key);
            }
        }
        this.handleSelectedSeriesChanged(newSeriesList);
    }

    handleSelectedDepthsChanged(updatedList = null) {
        let listToPassToApi = this.psData.SelectedDepthsKeys;
        if (updatedList !== null) {
            listToPassToApi = updatedList;
        }
        this.productSelectionService
            .handleSelectedDepthsChanged(listToPassToApi)
            .then(
                (response) => {
                    this.processProductSelectionResponseForDisplay(response);
                },
                (error) => {}
            );
    }
    handleSelectedDepthsCheckboxChanged() {
        let newList = [];
        for (var index = 0; index < this.psData.Depths.length; index++) {
            var element = this.psData.Depths[index];
            if (element.Value.Selected === true) {
                newList.push(element.Key);
            }
        }
        this.handleSelectedDepthsChanged(newList);
    }

    handleSelectedSpacingsChanged(updatedList = null) {
        let listToPassToApi = this.psData.SelectedSpacingsKeys;
        if (updatedList !== null) {
            listToPassToApi = updatedList;
        }
        this.productSelectionService
            .handleSelectedSpacingsChanged(listToPassToApi)
            .then(
                (response) => {
                    this.processProductSelectionResponseForDisplay(response);
                },
                (error) => {}
            );
    }
    handleSelectedSpacingsCheckboxChanged() {
        let newList = [];
        for (var index = 0; index < this.psData.Spacings.length; index++) {
            var element = this.psData.Spacings[index];
            if (element.Value.Selected === true) {
                newList.push(element.Key);
            }
        }
        this.handleSelectedSpacingsChanged(newList);
    }
    handleSelectedPliesChanged(updatedList = null) {
        let listToPassToApi = this.psData.SelectedPliesKeys;
        if (updatedList !== null) {
            listToPassToApi = updatedList;
        }
        this.productSelectionService
            .handleSelectedPliesChanged(listToPassToApi)
            .then(
                (response) => {
                    this.processProductSelectionResponseForDisplay(response);
                },
                (error) => {}
            );
    }
    handleSelectedPliesCheckboxChanged() {
        let newList = [];
        for (var index = 0; index < this.psData.Plies.length; index++) {
            var element = this.psData.Plies[index];
            if (element.Value.Selected === true) {
                newList.push(element.Key);
            }
        }
        this.handleSelectedPliesChanged(newList);
    }
    handleSelectedOrientationChanged(updatedList = null) {
        let listToPassToApi = this.psData.SelectedOrientationsKeys;
        if (updatedList !== null) {
            listToPassToApi = updatedList;
        }
        this.productSelectionService
            .handleSelectedOrientationChanged(listToPassToApi)
            .then(
                (response) => {
                    this.processProductSelectionResponseForDisplay(response);
                },
                (error) => {}
            );
    }
    handleSelectedOrientationCheckboxChanged() {
        let newList = [];
        for (var index = 0; index < this.psData.Orientations.length; index++) {
            var element = this.psData.Orientations[index];
            if (element.Value.Selected === true) {
                newList.push(element.Key);
            }
        }
        this.handleSelectedOrientationChanged(newList);
    }
    handleSelectedTreatmentChanged() {
        this.productSelectionService
            .handleSelectedTreatmentChanged(
                this.psData.SelectedPreservativeTreatment
            )
            .then(
                (response) => {
                    this.processCustomDepthMetaData(response);
                    this.processProductSelectionResponseForDisplay(
                        response.postResponseData
                    );
                },
                (error) => {}
            );
    }
    handleCustomDepthChanged() {
        //Clamp the custom depth. if it's greater than 0 and less than 3.5 set it to 3.5. if it's greater than largestValidCustomDepth, set it to largestValidCustomDepth
        var minimumValidCustomDepth = 3.5;
        if (this.allModelDataService.isUOMMetric()) {
            minimumValidCustomDepth = minimumValidCustomDepth * 25.4;
            this.largestValidCustomDepth = this.largestValidCustomDepth * 25.4;
        }
        if (this.customDepthFriendly > 0) {
            if (this.customDepthFriendly < minimumValidCustomDepth) {
                this.customDepthFriendly = minimumValidCustomDepth;
            } else {
                if (this.customDepthFriendly > this.largestValidCustomDepth) {
                    this.customDepthFriendly = this.largestValidCustomDepth;
                }
            }
        }
        this.productSelectionService
            .handleCustomDepthChanged(this.customDepthFriendly)
            .then(
                (response) => {
                    this.customDepthFriendly = response.friendlyValue;
                },
                (error) => {}
            );
    }
    handleCustomSpacingChanged() {
        this.productSelectionService
            .handleCustomSpacingChanged(this.customSpacingFriendly)
            .then(
                (response) => {
                    this.customSpacingFriendly = response.friendlyValue;
                },
                (error) => {}
            );
    }
    selectAllSeries() {
        if (this.psData.SelectedSeriesKeys.length < this.psData.Series.length) {
            //select them all
            this.selectAllInTheArray(
                this.psData.SelectedSeriesKeys,
                this.psData.Series
            );
        } else {
            //select none
            this.clearSelectedArray(this.psData.SelectedSeriesKeys);
        }
        this.handleSelectedSeriesChanged();
    }
    selectAllDepths() {
        if (this.psData.SelectedDepthsKeys.length < this.psData.Depths.length) {
            //select them all
            this.selectAllInTheArray(
                this.psData.SelectedDepthsKeys,
                this.psData.Depths
            );
        } else {
            //select none
            this.clearSelectedArray(this.psData.SelectedDepthsKeys);
        }
        this.handleSelectedDepthsChanged();
    }
    selectAllSpacings() {
        if (
            this.psData.SelectedSpacingsKeys.length <
            this.psData.Spacings.length
        ) {
            //select them all
            this.selectAllInTheArray(
                this.psData.SelectedSpacingsKeys,
                this.psData.Spacings
            );
        } else {
            //select none
            this.clearSelectedArray(this.psData.SelectedSpacingsKeys);
        }
        this.handleSelectedSpacingsChanged();
    }
    selectAllPlies() {
        if (this.psData.SelectedPliesKeys.length < this.psData.Plies.length) {
            //select them all
            this.selectAllInTheArray(
                this.psData.SelectedPliesKeys,
                this.psData.Plies
            );
        } else {
            //select none
            this.clearSelectedArray(this.psData.SelectedPliesKeys);
        }
        this.handleSelectedPliesChanged();
    }
    selectAllProductGroups() {
        var selectThemAll = true;
        var selectedCount = 0;
        for (var index = 0; index < this.psData.ProductGroups.length; index++) {
            var element = this.psData.ProductGroups[index];
            if (element.Value.Selected === true) {
                selectedCount += 1;
            }
        }
        if (selectedCount === this.psData.ProductGroups.length) {
            selectThemAll = false;
        }
        for (var index = 0; index < this.psData.ProductGroups.length; index++) {
            var element = this.psData.ProductGroups[index];
            element.Value.Selected = selectThemAll;
        }
        this.handleSelectedProductGroupChanged();
    }

    processCustomDepthMetaData(response) {
        this.largestValidCustomDepth = response.largestValidCustomDepth;
        this.customDepthEnabled = response.largestValidCustomDepth > 0;
        this.customDepthFriendly = response.customDepthFriendly;
    }

    selectAllInTheArray(selectedArray, availableArray) {
        this.clearSelectedArray(selectedArray);
        for (var index = 0; index < availableArray.length; index++) {
            var element = availableArray[index];
            selectedArray.push(element.Key);
        }
    }
    clearSelectedArray(selectedArray) {
        selectedArray.length = 0; //empty the array
    }

    rightArrow() {
        $('#rightArrow').css('color', '#4d90fe');
        var $tabs = $('.wizard .nav-tabs li');
        var $active = $('.wizard .nav-tabs li.active');
        $active.next().removeClass('disabled');
        this.nextTab($active);
        $('#leftArrow').css('color', '#4d90fe');
        if ($active.next().length === 0) {
            $('#rightArrow').css('color', 'gray');
        }
    }

    leftArrow() {
        var $tabs = $('.wizard .nav-tabs li');
        var $active = $('.wizard .nav-tabs li.active');
        this.prevTab($active);
        $('#rightArrow').css('color', '#4d90fe');
        if ($active.prev().length === 0) {
            $('#leftArrow').css('color', 'gray');
        }
    }

    nextTab(elem) {
        this.$timeout(() => {
            $(elem).next().find('a[data-toggle="tab"]').click();
        }, 0);
    }
    prevTab(elem) {
        this.$timeout(() => {
            $(elem).prev().find('a[data-toggle="tab"]').click();
        }, 0);
    }

    selectAllTextOnFocus($event) {
        $event.target.select();
    }

    whereToBuy() {
        window.open(
            'https://www.weyerhaeuser.com/woodproducts/where-to-buy/?utm_source=WhereToBuy&utm_medium=referral&utm_campaign=ForteWEB&utm_content=Where+To+Buy'
        );
    }

    updateProductVisibleSize() {
        this.visibleProductSize = Math.min(
            this.ProductSeries.Visible.length,
            this.maxVisibleProductSize
        );
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    navigateSolutions() {
        if (this.$state.$current.name !== 'application.job.solutions') {
            this.tabNavigationService._executeTabSwitch(
                'application.job.solutions',
                8
            );
        }
    }

    isSolutionTabAviliable() {
        var result = this.tabNavigationService.isSolutionsTabEnabled();
        return result;
    }

    anchorClick(e) {
        e.preventDefault();
        $(this).tab('show');
    }

    // getProductSelectionMetadata() {
    //     this.productSelectionService.getProductSelectionMetadata().then((serviceResponse) => {
    //         this.psMetadata = serviceResponse;
    //         this.getProductSelectionSDO();
    //     });
    // }

    // getProductSelectionSDO() {
    //     this.psSDO = this.productSelectionService.getProductSelectionSDO();
    //     this.loadBlacklistAndProductData().then(() => {
    //         if (this.psSDO) {
    //             this.customDepth = this.psSDO.CustomDepthFriendly;
    //             this.customSpacing = this.psSDO.CustomSpacingFriendly;
    //         }

    //         this.updateProductVisibleSize();
    //     });
    // }

    // loadBlacklistAndProductData() {
    //     var deferred = this.$q.defer();
    //     // check for transition state where there is no selected member
    //     // this can happen if the user deletes the current member while on the product selection tab
    //     var prData = this.allModelDataService.getPostResponseData();
    //     if (prData && prData.ApplicationData && prData.ApplicationData.SelectedMemberIDTag > -1) {
    //         // gotta wait on product data and blacklist delivery
    //         this.spinner.spinnerShow(this.getText('jobSettingsResetProcessing'));
    //         this.loadBlacklist().then(() => {
    //             this.loadUntreatedProductData().then(() => {
    //                 this.loadTreatedProductData().then(() => {
    //                     this.spinner.spinnerHide();
    //                     this.prepareProductData();
    //                 }).finally(() => {
    //                     deferred.resolve();
    //                 });
    //             });
    //         });
    //     } else {
    //         deferred.resolve();
    //     }
    //     return deferred.promise;
    // }

    // loadBlacklist() {
    //     var deferred = this.$q.defer();
    //     this.productBlacklist = this.allModelDataService.getProductBlacklist();
    //     if (!this.productBlacklist) {
    //         this.materialsManagementService.getMatManBlacklist().then((mmBlacklist) => {
    //             this.productBlacklist = mmBlacklist;
    //             this.allModelDataService.setProductBlacklist(mmBlacklist);
    //             deferred.resolve();
    //         });
    //     } else {
    //         deferred.resolve();
    //     }
    //     return deferred.promise;
    // }

    // loadUntreatedProductData() {
    //     var deferred = this.$q.defer();
    //     this.productSelectionService.getAllUntreatedProductData().then(() => {
    //         var prData = this.allModelDataService.getPostResponseData();
    //         this.untreatedProductGroups = prData.MemberData.ProductSelectionData.UntreatedProductGroups;
    //         deferred.resolve();
    //     });
    //     return deferred.promise;
    // }

    // loadTreatedProductData() {
    //     var deferred = this.$q.defer();
    //     this.productSelectionService.getAllTreatedProductData().then(() => {
    //         var prData = this.allModelDataService.getPostResponseData();
    //         this.treatedProductGroups = prData.MemberData.ProductSelectionData.TreatedProductGroups;
    //         deferred.resolve();
    //     });
    //     return deferred.promise;
    // }

    // prepareProductData() {
    //     this.productDataPrepared = true;
    //     this.initializeSpacings();
    //     this.initializeOrientations();
    //     this.setSelectedDataFromSDO();
    //     this.updateProductDataForServiceLevel();
    //     this.refreshProductGroups();
    // }

    // setSelectedDataFromSDO() {
    //     if (this.psSDO) {
    //         this.setSelectedSeries(this.psSDO.SelectedSeriesNames);
    //         this.setSelectedDepths(this.psSDO.SelectedDepthNames);
    //         this.setSelectedSpacings(this.psSDO.SelectedSpacingsNames);
    //         this.setSelectedPlies(this.psSDO.SelectedPliesNames);
    //         this.setSelectedOrientations(this.psSDO.SelectedOrientations);
    //     }
    // }

    // setSelectedSeries(selectedSeries) {
    //     if (selectedSeries) {
    //         this.ProductSeries.Selected = [];
    //         for (var sIndex = 0; sIndex < selectedSeries.length; sIndex++) {
    //             this.ProductSeries.Selected.push(selectedSeries[sIndex]);
    //         }
    //     }
    // }

    // setSelectedDepths(selectedDepths) {
    //     if (selectedDepths) {
    //         this.ProductDepths.Selected = [];
    //         for (var dIndex = 0; dIndex < selectedDepths.length; dIndex++) {
    //             var value = this.getSelectedDepthAndSpacingWithUOM(selectedDepths[dIndex], this.psSDO.AvailableDepthNames);
    //             if (value === -1) {
    //                 this.ProductDepths.Selected.push(selectedDepths[dIndex]);
    //             }
    //             else {
    //                 this.ProductDepths.Selected.push(value);
    //             }
    //         }
    //     }
    // }

    // setSelectedSpacings(selectedSpacings) {
    //     if (selectedSpacings) {
    //         this.ProductSpacings.Selected = [];
    //         for (var sIndex = 0; sIndex < selectedSpacings.length; sIndex++) {
    //             var value = this.getSelectedDepthAndSpacingWithUOM(selectedSpacings[sIndex], this.psSDO.AvailableSpacingsNames);
    //             if (value === -1) {
    //                 this.ProductSpacings.Selected.push(selectedSpacings[sIndex]);
    //             }
    //             else {
    //                 this.ProductSpacings.Selected.push(value);
    //             }
    //             for (var vsIndex = 0; vsIndex < this.ProductSpacings.Visible.length; vsIndex++) {
    //                 if (selectedSpacings[sIndex] === this.ProductSpacings.Visible[vsIndex].SpacingName) {
    //                     this.ProductSpacings.Visible[vsIndex].SpacingSelected = true;
    //                     break;
    //                 }
    //             }
    //         }
    //     }
    // }

    // getSelectedDepthAndSpacingWithUOM(valueToSelect, availableValues) {
    //     for (var key in availableValues)
    //     {
    //         if (key.indexOf('mm') >= 0) {
    //             var value = availableValues[key];
    //             if (value === valueToSelect) {
    //                 return key;
    //             }
    //         }
    //         else {
    //             return -1;
    //         }
    //     }
    // }

    // setSelectedPlies(selectedPlies) {
    //     if (selectedPlies) {
    //         this.ProductPlies.Selected = [];
    //         for (var pIndex = 0; pIndex < selectedPlies.length; pIndex++) {
    //             this.ProductPlies.Selected.push(selectedPlies[pIndex]);
    //         }
    //     }
    // }

    // setSelectedOrientations(selectedOrientations) {
    //     if (selectedOrientations) {
    //         this.ProductOrientations.Selected = [];

    //         // if there are no selected orientations, select standard
    //         if (selectedOrientations.length === 0) {
    //             this.ProductOrientations.Selected.push('standardOrientation');
    //         }

    //         for (var oIndex = 0; oIndex < selectedOrientations.length; oIndex++) {
    //             this.ProductOrientations.Selected.push(selectedOrientations[oIndex]);
    //             if (selectedOrientations[oIndex] === 'plank') {
    //                 this.isPlankSelected = true;
    //             }
    //             for (var o2Index = 0; o2Index < this.ProductOrientations.Available.length; o2Index++) {
    //                 if (this.ProductOrientations.Available[o2Index].OrientationName === selectedOrientations[oIndex]) {
    //                     this.ProductOrientations.Available[o2Index].OrientationSelected = true;
    //                 }
    //             }
    //         }
    //     } else {
    //         // if no selected orientations are available, select standard
    //         this.ProductOrientations.Selected = [];
    //         this.ProductOrientations.Selected.push('standardOrientation');
    //     }
    // }

    // updateProductDataForServiceLevel() {
    //     if (!this.psSDO) {
    //         this.activeProductGroups = this.untreatedProductGroups;
    //     } else if (this.psSDO.SelectedPreservativeTreatment > 0) {
    //         this.activeProductGroups = this.treatedProductGroups;
    //     } else {
    //         this.activeProductGroups = this.untreatedProductGroups;
    //     }
    // }

    // initializeSpacings() {
    //     this.ProductSpacings.Available = [];
    //     this.ProductSpacings.Visible = [];

    //     if (this.psMetadata && this.psMetadata.SpacingData) {
    //         for (var sIndex = 0; sIndex < this.psMetadata.SpacingData.length; sIndex++) {
    //             this.ProductSpacings.Available.push({
    //                 'SpacingID': this.psMetadata.SpacingData[sIndex].SpacingID,
    //                 'SpacingName': this.psMetadata.SpacingData[sIndex].SpacingName,
    //                 'SpacingSelected': false
    //             });
    //         }
    //     }

    //     for (var sIndex = 0; sIndex < this.ProductSpacings.Available.length; sIndex++) {
    //         this.ProductSpacings.Visible.push(this.ProductSpacings.Available[sIndex]);
    //     }
    // }

    // initializeOrientations() {
    //     this.ProductOrientations.Available = [];
    //     this.ProductOrientations.Visible = [];

    //     // orientations are also static
    //     this.ProductOrientations.Available.push({
    //         'OrientationName': this.psSDO.AvailableOrientations[oIndex],
    //         'OrientationSelected': false
    //     });
    //     this.ProductOrientations.Available.push({
    //         'OrientationName': 'plank',
    //         'OrientationSelected': false
    //     });

    //     for (var oIndex = 0; oIndex < this.ProductOrientations.Available.length; oIndex++) {
    //         this.ProductOrientations.Visible.push(this.ProductOrientations.Available[oIndex]);
    //     }
    // }

    // refreshProductGroups() {
    //     this.ProductGroups.Available.length = 0;
    //     for (var pgIndex = 0; pgIndex < this.activeProductGroups.length; pgIndex++) {
    //         if (!this.blacklistContainsProductGroup(this.activeProductGroups[pgIndex].ProductGroupID)) {
    //             this.ProductGroups.Available.push({
    //                 'ProductGroupID': this.activeProductGroups[pgIndex].ProductGroupID,
    //                 'ProductGroupName': this.activeProductGroups[pgIndex].ProductGroupNameKey,
    //                 'ProductGroupSelected': this.productGroupIsSelected(this.activeProductGroups[pgIndex].ProductGroupID),
    //                 'ProductSeries': this.activeProductGroups[pgIndex].ProductSeries
    //             });
    //         }
    //     }
    //     this.$timeout(() => {
    //         this.refreshProductSeries();
    //     }, 0);

    // }

    // refreshProductSeries() {
    //     // initialize all available product series based on all available product groups
    //     this.ProductSeries.Available.length = 0;
    //     this.ProductSeries.Visible.length = 0;
    //     for (var pgIndex = 0; pgIndex < this.ProductGroups.Available.length; pgIndex++) {
    //         var curPG = this.ProductGroups.Available[pgIndex];
    //         var allPSinPG = curPG.ProductSeries;
    //         for (var psIndex = 0; psIndex < allPSinPG.length; psIndex++) {
    //             var allowedProducts = this.getAllowedProducts(allPSinPG[psIndex].Products);
    //             if (!this.blacklistContainsProductSeries(allPSinPG[psIndex].ProductSeriesID) && allowedProducts.length > 0) {
    //                 this.ProductSeries.Available.push({
    //                     'ProductSeriesID': allPSinPG[psIndex].ProductSeriesID,
    //                     'ProductSeriesName': allPSinPG[psIndex].ProductSeriesName,
    //                     'ProductSeriesSelected': this.productSeriesIsSelected(allPSinPG[psIndex].ProductSeriesName),
    //                     'Products': allowedProducts
    //                 });

    //                 if (this.productGroupIsSelected(curPG.ProductGroupID) && allowedProducts.length > 0) {
    //                     this.ProductSeries.Visible.push({
    //                         'ProductSeriesID': allPSinPG[psIndex].ProductSeriesID,
    //                         'ProductSeriesName': allPSinPG[psIndex].ProductSeriesName,
    //                         'ProductSeriesSelected': this.productSeriesIsSelected(allPSinPG[psIndex].ProductSeriesName),
    //                         'Products': allowedProducts
    //                     });
    //                 }
    //             } else {
    //                 // remove product series from selected if it is blacklisted or contains no selectable products
    //                 for (var spIndex = 0; spIndex < this.ProductSeries.Selected.length; spIndex++) {
    //                     if (this.getText(allPSinPG[psIndex].ProductSeriesName) === this.ProductSeries.Selected[spIndex]) {
    //                         this.ProductSeries.Selected.splice(spIndex, 1);
    //                     }
    //                 }
    //             }
    //         }
    //     }

    //     this.updateProductVisibleSize();
    //     this.$timeout(() => {
    //         this.refreshProductDepths();
    //     }, 0);
    // }

    // refreshProductDepths() { //eslint-disable-line complexity
    //     // initialize all available products depths based on all available product series
    //     this.ProductDepths.Available.length = 0;

    //     var allAvailableDepths = [];
    //     var allAvailablePlies = [];

    //     // check for a zero-length series selected list
    //     // this indicates the selected series was disabled in materials manager
    //     // if there are no selected series, we should deselect all depths
    //     if (this.ProductSeries.Selected && this.ProductSeries.Selected.length === 0) {
    //         this.ProductDepths.Selected = [];
    //         this.depthSelected();
    //     } else {
    //         if (this.ProductSeries.Visible && this.ProductSeries.Visible.length > 0) {
    //             for (var psIndex = 0; psIndex < this.ProductSeries.Visible.length; psIndex++) {
    //                 if (this.ProductSeries.Visible[psIndex].Products) {
    //                     for (var pIndex = 0; pIndex < this.ProductSeries.Visible[psIndex].Products.length; pIndex++) {
    //                         var curProduct = this.ProductSeries.Visible[psIndex].Products[pIndex];
    //                         var depthVisible = this.productSeriesIsSelected(this.ProductSeries.Visible[psIndex].ProductSeriesName);
    //                         allAvailableDepths.push({
    //                             'DepthID': curProduct.ProductDepth,
    //                             'DepthName': curProduct.ProductDepthName,
    //                             'DepthVisible': depthVisible,
    //                             'DepthSelected': this.depthIsSelected(curProduct.ProductDepth)
    //                         });

    //                         if (depthVisible) { //eslint-disable-line max-depth
    //                             allAvailablePlies = this.addAvailablePlies(allAvailablePlies, curProduct.ProductMinPlies, curProduct.ProductMaxPlies);
    //                         }
    //                     }
    //                 }
    //             }
    //         }

    //         var filteredDepths = [];
    //         if (allAvailableDepths && allAvailableDepths.length > 2) {
    //             // filter duplicates
    //             for (var dIndex = 0; dIndex < allAvailableDepths.length; dIndex++) {
    //                 var depthAlreadyInList = false;
    //                 for (var fdIndex = 0; fdIndex < filteredDepths.length; fdIndex++) {
    //                     if (filteredDepths[fdIndex] && (filteredDepths[fdIndex].DepthID === allAvailableDepths[dIndex].DepthID)) {
    //                         // replace depth in filtered list if available item is visible and filtered item is invisible
    //                         if (filteredDepths[fdIndex].DepthVisible === false && allAvailableDepths[dIndex].DepthVisible === true) { //eslint-disable-line max-depth
    //                             filteredDepths[fdIndex].DepthVisible = true;
    //                         }
    //                         depthAlreadyInList = true;
    //                         break;
    //                     }
    //                 }

    //                 if (!depthAlreadyInList) {
    //                     filteredDepths.push(allAvailableDepths[dIndex]);
    //                 }
    //             }
    //         }
    //         else if (allAvailableDepths && allAvailableDepths.length === 1) {
    //             filteredDepths.push(allAvailableDepths[0]);
    //         }
    //         if (filteredDepths && filteredDepths.length > 0) {
    //             this.ProductDepths.Available = this.sortDepths(filteredDepths);
    //         }

    //         this.$timeout(() => {
    //             this.ProductPlies.Available = allAvailablePlies;
    //         }, 0);

    //         // initialize all visible product depths based on selected product series
    //         this.ProductDepths.Visible = [];
    //         if (this.ProductDepths.Available && this.ProductDepths.Available.length > 0) {
    //             for (var dIndex = 0; dIndex < this.ProductDepths.Available.length; dIndex++) {
    //                 if (this.ProductDepths.Available[dIndex].DepthVisible) {
    //                     this.ProductDepths.Visible.push(this.ProductDepths.Available[dIndex]);
    //                 }
    //             }
    //         }
    //     }

    //     if (this.ProductSeries.Visible && this.ProductSeries.Visible.length > 0) {
    //         for (var psIndex = 0; psIndex < this.ProductSeries.Visible.length; psIndex++) {
    //             if (this.ProductSeries.Visible[psIndex].Products) {
    //                 for (var pIndex = 0; pIndex < this.ProductSeries.Visible[psIndex].Products.length; pIndex++) {
    //                     var curProduct = this.ProductSeries.Visible[psIndex].Products[pIndex];
    //                     var depthVisible = this.productSeriesIsSelected(this.ProductSeries.Visible[psIndex].ProductSeriesName);
    //                     allAvailableDepths.push({
    //                         'DepthID': curProduct.ProductDepth,
    //                         'DepthName': curProduct.ProductDepthName,
    //                         'DepthVisible': depthVisible,
    //                         'DepthSelected': this.depthIsSelected(curProduct.ProductDepth)
    //                     });

    //                     if (depthVisible) {
    //                         allAvailablePlies = this.addAvailablePlies(allAvailablePlies, curProduct.ProductMinPlies, curProduct.ProductMaxPlies);
    //                     }
    //                 }
    //             }
    //         }
    //     }

    //     if (allAvailableDepths && allAvailableDepths.length > 2) {
    //         // filter duplicates
    //         var filteredDepths = this.getUniqueDepths(allAvailableDepths);
    //     }
    //     if (filteredDepths && filteredDepths.length > 0) {
    //         this.ProductDepths.Available = this.sortDepths(filteredDepths);
    //     }
    //     this.$timeout(() => {
    //         this.ProductPlies.Available = allAvailablePlies;
    //     }, 0);

    //     // initialize all visible product depths based on selected product series
    //     this.ProductDepths.Visible = [];
    //     if (this.ProductDepths.Available && this.ProductDepths.Available.length > 0) {
    //         for (var dIndex = 0; dIndex < this.ProductDepths.Available.length; dIndex++) {
    //             if (this.ProductDepths.Available[dIndex].DepthVisible) {
    //                 this.ProductDepths.Visible.push(this.ProductDepths.Available[dIndex]);
    //             }
    //         }
    //     }
    // }

    // //remove duplicates numbers from a list.
    // //generating a unique list of numbers.
    // getUniqueDepths(allAvailableDepths) {
    //     var filteredDepths = [];
    //     for (var dIndex = 0; dIndex < allAvailableDepths.length; dIndex++) {
    //         var depthAlreadyInList = false;
    //         for (var fdIndex = 0; fdIndex < filteredDepths.length; fdIndex++) {
    //             if (filteredDepths[fdIndex] && (filteredDepths[fdIndex].DepthID === allAvailableDepths[dIndex].DepthID)) {
    //                 // replace depth in filtered list if available item is visible and filtered item is invisible
    //                 if (filteredDepths[fdIndex].DepthVisible === false && allAvailableDepths[dIndex].DepthVisible === true) {
    //                     filteredDepths[fdIndex].DepthVisible = true;
    //                 }
    //                 depthAlreadyInList = true;
    //                 break;
    //             }
    //         }

    //         if (!depthAlreadyInList) {
    //             filteredDepths.push(allAvailableDepths[dIndex]);
    //         }
    //     }
    //     return filteredDepths;
    // }

    // sortDepths(allDepths) {
    //     var retDepths = allDepths;
    //     var sortedDepths = [];
    //     if (retDepths && retDepths.length > 0) {
    //         while (retDepths.length > 0) {
    //             var minDepth = 99;
    //             var targetIndex = -1;
    //             var foundOne = false;

    //             for (var pIndex = 0; pIndex < retDepths.length; pIndex++) {
    //                 if (retDepths[pIndex].DepthID < minDepth) {
    //                     minDepth = retDepths[pIndex].DepthID;
    //                     targetIndex = pIndex;
    //                     foundOne = true;
    //                 }
    //             }

    //             if (!foundOne) {
    //                 break;
    //             }
    //             sortedDepths.push(retDepths[targetIndex]);
    //             retDepths.splice(targetIndex, 1);
    //         }

    //         return sortedDepths;
    //     }

    //     return retDepths;
    // }

    // addAvailablePlies(existingPlies, newMinPlies, newMaxPlies) {
    //     if (!existingPlies) {
    //         existingPlies = [];
    //     }

    //     var retPlies = existingPlies;
    //     if (newMinPlies && newMaxPlies) {
    //         for (var plyIndex = newMinPlies; plyIndex <= newMaxPlies; plyIndex++) {
    //             var plyAlreadyInList = false;
    //             for (var eIndex = 0; eIndex < retPlies.length; eIndex++) {
    //                 if (retPlies[eIndex].PliesID === plyIndex) {
    //                     plyAlreadyInList = true;
    //                     break;
    //                 }
    //             }

    //             if (!plyAlreadyInList) {
    //                 retPlies.push({
    //                     'PliesID': plyIndex,
    //                     'PliesName': plyIndex.toString(),
    //                     'PliesSelected': this.pliesIsSelected(plyIndex)
    //                 });
    //             }
    //         }
    //     }

    //     // sort plies ascending
    //     var sortedPlies = [];
    //     while (retPlies.length > 0) {
    //         var minPly = 99;
    //         var targetIndex = -1;
    //         var foundOne = false;

    //         for (var pIndex = 0; pIndex < retPlies.length; pIndex++) {
    //             if (retPlies[pIndex].PliesID < minPly) {
    //                 minPly = retPlies[pIndex].PliesID;
    //                 targetIndex = pIndex;
    //                 foundOne = true;
    //             }
    //         }

    //         if (!foundOne) {
    //             break;
    //         }
    //         sortedPlies.push(retPlies[targetIndex]);
    //         retPlies.splice(targetIndex, 1);
    //     }

    //     return sortedPlies;
    // }

    // getAvailableProductSeries(allSeriesInGroup) {
    //     var retSeries = [];
    //     for (var seriesIndex = 0; seriesIndex < allSeriesInGroup.length; seriesIndex++) {
    //         if (!this.blacklistContainsProductSeries(allSeriesInGroup[seriesIndex].ProductSeriesID)) {
    //             retSeries.push({
    //                 'ProductSeriesID': allSeriesInGroup[seriesIndex].ProductSeriesID,
    //                 'ProductSeriesName': allSeriesInGroup[seriesIndex].ProductSeriesName,
    //                 'Products': this.getAvailableProducts(allSeriesInGroup[seriesIndex].ProductSeriesID)
    //             });
    //         }
    //     }

    //     return retSeries;
    // }

    // getAvailableProducts(seriesID) {
    //     var retProducts = [];
    //     var prodSeries = this.getProductSeriesFromAvailable(seriesID);
    //     if (prodSeries && prodSeries.Products && prodSeries.Products.length > 0) {
    //         var allProductsInSeries = prodSeries.Products;
    //         for (var prodIndex = 0; prodIndex < allProductsInSeries.length; prodIndex++) {
    //             if (!this.blacklistContainsProduct(allProductsInSeries[prodIndex].ProductID)) {
    //                 retProducts.push(allProductsInSeries[prodIndex]);
    //             }
    //         }
    //     }

    //     return retProducts;
    // }

    // getAllowedProducts(allProducts) {
    //     var retProducts = [];

    //     if (allProducts && allProducts.length > 0) {
    //         for (var prodIndex = 0; prodIndex < allProducts.length; prodIndex++) {
    //             if (!this.blacklistContainsProduct(allProducts[prodIndex].ProductID)) {
    //                 retProducts.push(allProducts[prodIndex]);
    //             }
    //         }
    //     }

    //     return retProducts;
    // }

    // productGroupIsSelected(prodGroupID) {
    //     if (this.ProductGroups && this.ProductGroups.Selected) {
    //         for (var pgIndex = 0; pgIndex < this.ProductGroups.Selected.length; pgIndex++) {
    //             if (this.ProductGroups.Selected[pgIndex] === prodGroupID) {
    //                 return true;
    //             }
    //         }
    //     }

    //     if (this.psSDO && this.psSDO.ProductGroupValues) {
    //         var sdoKeys = Object.keys(this.psSDO.ProductGroupValues);
    //         for (var keyIndex = 0; keyIndex < sdoKeys.length; keyIndex++) {
    //             // Object.keys returns an array of strings
    //             // prodGroupID comes in as a number
    //             // triple equals returns false because of type inequality
    //             // double equals doesn't consider type
    //             // eslint-disable-next-line eqeqeq
    //             if (sdoKeys[keyIndex] == prodGroupID && this.psSDO.ProductGroupValues[sdoKeys[keyIndex]].Checked === true) {
    //                 return true;
    //             }
    //         }
    //     }

    //     return false;
    // }

    // productSeriesIsSelected(prodSeriesName) {
    //     if (this.ProductSeries.Selected) {
    //         for (var psIndex = 0; psIndex < this.ProductSeries.Selected.length; psIndex++) {
    //             if (this.ProductSeries.Selected[psIndex] === prodSeriesName) {
    //                 return true;
    //             }
    //         }
    //     }

    //     if (this.psSDO && this.psSDO.SelectedSeriesNames) {
    //         for (var nIndex = 0; nIndex < this.psSDO.SelectedSeriesNames.length; nIndex++) {
    //             if (this.psSDO.SelectedSeriesNames[nIndex] === prodSeriesName) {
    //                 return true;
    //             }
    //         }
    //     }

    //     return false;
    // }

    // depthIsSelected(depthID) {
    //     if (this.ProductDepths.Selected) {
    //         for (var depthIndex = 0; depthIndex < this.ProductDepths.Selected.length; depthIndex++) {
    //             if (this.ProductDepths.Selected[depthIndex] === depthID) {
    //                 return true;
    //             }
    //         }
    //     }

    //     return false;
    // }

    // pliesIsSelected(plyName) {
    //     if (this.ProductPlies.Selected) {
    //         for (var plyIndex = 0; plyIndex < this.ProductPlies.Selected.length; plyIndex++) {
    //             if (this.ProductPlies.Selected[plyIndex] == plyName) { //eslint-disable-line eqeqeq
    //                 return true;
    //             }
    //         }
    //     }

    //     return false;
    // }

    // preservativeTreatmentSelected() {
    //     this.productSelectionService.postTreatmentChangeEvent({
    //         'selected': this.psSDO.SelectedPreservativeTreatment
    //     }).then((resp) => {});
    //     this.updateProductDataForServiceLevel();
    //     this.refreshProductGroups();
    // }

    // blacklistContainsProductGroup(prodGroupID) {
    //     for (var blIndex = 0; blIndex < this.productBlacklist.ProductGroups.length; blIndex++) {
    //         if (this.productBlacklist.ProductGroups[blIndex] === prodGroupID) {
    //             return true;
    //         }
    //     }

    //     return false;
    // }

    // blacklistContainsProductSeries(prodSeriesID) {
    //     for (var blIndex = 0; blIndex < this.productBlacklist.ProductSeries.length; blIndex++) {
    //         if (this.productBlacklist.ProductSeries[blIndex] === prodSeriesID) {
    //             return true;
    //         }
    //     }

    //     return false;
    // }

    // blacklistContainsProduct(productID) {
    //     for (var blIndex = 0; blIndex < this.productBlacklist.Products.length; blIndex++) {
    //         if (this.productBlacklist.Products[blIndex] === productID) {
    //             return true;
    //         }
    //     }

    //     return false;
    // }

    // // function productIsPslPlus(product) {
    // //     return (product.EnvironmentalTreatmentID !== 10);
    // // }

    // productGroupSelected() {
    //     this.ProductGroups.Selected.length = 0;

    //     if (this.ProductGroups.Available && this.ProductGroups.Available.length > 0) {
    //         for (var pgIndex = 0; pgIndex < this.ProductGroups.Available.length; pgIndex++) {
    //             // reset product group checked status here since that dictionary is indexed by product group id
    //             this.psSDO.ProductGroupValues[this.ProductGroups.Available[pgIndex].ProductGroupID].Checked = false;
    //             if (this.ProductGroups.Available[pgIndex].ProductGroupSelected) {
    //                 this.ProductGroups.Selected.push(this.ProductGroups.Available[pgIndex].ProductGroupID);
    //                 this.psSDO.ProductGroupValues[this.ProductGroups.Available[pgIndex].ProductGroupID].Checked = true;
    //             }
    //         }
    //     }

    //     var data = {
    //         'selected': this.psSDO.ProductGroupValues
    //     };
    //     this.productSelectionService.postProductGroupChangeEvent(data)
    //         .then((serviceResponse) => {
    //             this.refreshProductSeries();
    //         });

    // }

    // getSeriesVisibleArrayNames() {

    //     var arr = [];
    //     for (var x = 0; x < this.ProductSeries.Visible.length; x++) {
    //         arr.push(this.ProductSeries.Visible[x].ProductSeriesName);
    //     }

    //     return arr;
    // }

    // isprodSeriesSelected(obj) {
    //     for (var x = 0; x < this.ProductSeries.Selected.length; x++) {
    //         if (this.ProductSeries.Selected[x] === obj.ProductSeriesName) {
    //             return true;
    //         }
    //     }
    //     return false;
    // }

    // newproductSeriesSelected(obj) {

    //     if (obj.ProductSeriesSelected)// Add If Not Exist
    //     {
    //         for (var x = 0; x < this.ProductSeries.Selected.length; x++) {
    //             if (obj.ProductSeriesName === this.ProductSeries.Selected[x]) {
    //                 this.productSeriesSelected();
    //                 return;
    //             }
    //         }
    //         this.ProductSeries.Selected.push(obj.ProductSeriesName);
    //         this.productSeriesSelected();
    //         return;
    //     }
    //     else // Remove
    //     {
    //         for (var x = 0; x < this.ProductSeries.Selected.length; x++) {
    //             if (obj.ProductSeriesName === this.ProductSeries.Selected[x]) {
    //                 if (!obj.ProductSeriesSelected) {
    //                     this.ProductSeries.Selected.splice(x, 1);
    //                     this.productSeriesSelected();
    //                     return;
    //                 }
    //             }
    //         }
    //     }
    //     this.productSeriesSelected();
    // }

    // productSeriesSelected() {
    //     this.psSDO.SelectedSeriesNames = this.ProductSeries.Selected;

    //     this.productSelectionService.postSeriesChangeEvent({
    //         'selected': this.ProductSeries.Selected
    //     }).then((serviceResponse) => {
    //         //set custom depth enabled based on selected series.
    //         this.psSDO.CustomDepthEnable = this.productSelectionService.getProductSelectionSDO().CustomDepthEnable;
    //     });
    //     this.$timeout(() => {
    //         this.refreshProductDepths();
    //     }, 0);
    // }

    // isProductDepthsSelected(obj) {

    //     for (var x = 0; x < this.ProductDepths.Selected.length; x++) {
    //         if (this.ProductDepths.Selected[x] === obj.DepthName) {
    //             return true;
    //         }
    //     }
    //     return false;
    // }

    // setdepthSelected(obj) {
    //     //vm.ProductDepths.Selected
    //     if (obj.DepthSelected)// Add If Not Exist
    //     {
    //         for (var x = 0; x < this.ProductDepths.Selected.length; x++) {
    //             if (obj.DepthName === this.ProductDepths.Selected[x]) {
    //                 this.depthSelected();
    //                 return;
    //             }
    //         }
    //         this.ProductDepths.Selected.push(obj.DepthName);
    //         this.depthSelected();
    //         return;
    //     }
    //     else // Remove
    //     {
    //         for (var x = 0; x < this.ProductDepths.Selected.length; x++) {
    //             if (obj.DepthName === this.ProductDepths.Selected[x]) {
    //                 if (!obj.DepthSelected) {
    //                     this.ProductDepths.Selected.splice(x, 1);
    //                     this.depthSelected();
    //                     return;
    //                 }
    //             }
    //         }
    //     }

    //     this.depthSelected();
    // }

    // depthSelected() {
    //     this.psSDO.SelectedDepthNames = this.ProductDepths.Selected;
    //     this.psSDO.SelectedDepthIDs = [];
    //     var selectedDepthNames = this.ProductDepths.Selected;
    //     if (selectedDepthNames) {
    //         for (var dIndex = 0; dIndex < selectedDepthNames.length; dIndex++) {
    //             for (var allDIndex = 0; allDIndex < this.ProductDepths.Visible.length; allDIndex++) {
    //                 if (this.ProductDepths.Visible[allDIndex].DepthName === selectedDepthNames[dIndex]) {
    //                     this.psSDO.SelectedDepthIDs.push(this.ProductDepths.Visible[allDIndex].DepthID);
    //                 }
    //             }
    //         }
    //         this.productSelectionService.postDepthChangeEvent({
    //             'selected': this.psSDO.SelectedDepthNames
    //         }).then((resp) => {
    //         });
    //     }
    // }

    // isspacingSelected(obj) {
    //     for (var x = 0; x < this.ProductSpacings.Selected.length; x++) {
    //         if (this.ProductSpacings.Selected[x] === obj.SpacingName) {
    //             return true;
    //         }
    //     }
    //     return false;
    // }

    // setspacingSelected(obj) {
    //     if (obj.spacingSelected)// Add If Not Exist
    //     {
    //         for (var x = 0; x < this.ProductSpacings.Selected.length; x++) {
    //             if (obj.SpacingName === this.ProductSpacings.Selected[x]) {
    //                 this.spacingSelected();
    //                 return;
    //             }
    //         }
    //         this.ProductSpacings.Selected.push(obj.SpacingName);
    //         this.spacingSelected();
    //         return;
    //     }
    //     else // Remove
    //     {
    //         for (var x = 0; x < this.ProductSpacings.Selected.length; x++) {
    //             if (obj.SpacingName === this.ProductSpacings.Selected[x]) {
    //                 if (!obj.spacingSelected) {
    //                     this.ProductSpacings.Selected.splice(x, 1);
    //                     this.spacingSelected();
    //                     return;
    //                 }
    //             }
    //         }
    //     }

    //     this.spacingSelected();
    // }

    // spacingSelected() {
    //     this.psSDO.SelectedSpacingsNames = this.ProductSpacings.Selected;
    //     this.psSDO.SelectedSpacingsIDs = [];

    //     if (this.ProductSpacings && this.ProductSpacings.Selected) {
    //         for (var sIndex = 0; sIndex < this.ProductSpacings.Selected.length; sIndex++) {
    //             for (var allSIndex = 0; allSIndex < this.ProductSpacings.Visible.length; allSIndex++) {
    //                 if (this.ProductSpacings.Visible[allSIndex].SpacingName === this.ProductSpacings.Selected[sIndex]) {
    //                     this.psSDO.SelectedSpacingsIDs.push(this.ProductSpacings.Visible[allSIndex].SpacingID);
    //                 }
    //             }
    //         }
    //         this.productSelectionService.postSpacingChangeEvent({
    //             'selected': this.psSDO.SelectedSpacingsNames
    //         }).then((resp) => {
    //         });
    //     }
    // }

    // isproductPliesSelected(obj) {

    //     for (var x = 0; x < this.ProductPlies.Selected.length; x++) {
    //         if (this.ProductPlies.Selected[x] === obj.PliesName) {
    //             return true;
    //         }
    //     }
    //     return false;
    // }

    // setproductPliesSelected(obj) {

    //     if (obj.PliesSelected)// Add If Not Exist
    //     {
    //         for (var x = 0; x < this.ProductPlies.Selected.length; x++) {
    //             if (obj.PliesName === this.ProductPlies.Selected[x]) {
    //                 this.productPliesSelected();
    //                 return;
    //             }
    //         }
    //         this.ProductPlies.Selected.push(obj.PliesName);
    //         this.productPliesSelected();
    //         return;
    //     }
    //     else // Remove
    //     {
    //         for (var x = 0; x < this.ProductPlies.Selected.length; x++) {
    //             if (obj.PliesName === this.ProductPlies.Selected[x]) {
    //                 if (!obj.PliesSelected) {
    //                     this.ProductPlies.Selected.splice(x, 1);
    //                     this.productPliesSelected();
    //                     return;
    //                 }
    //             }
    //         }
    //     }

    //     this.productPliesSelected();
    // }

    // productPliesSelected() {
    //     this.psSDO.SelectedPliesNames = this.ProductPlies.Selected;
    //     this.productSelectionService.postPliesChangeEvent({
    //         'selected': this.psSDO.SelectedPliesNames
    //     }).then((resp) => {
    //     });
    // }
    // isproductOrientationSelected(obj) {

    //     for (var x = 0; x < this.ProductOrientations.Selected.length; x++) {
    //         if (this.ProductOrientations.Selected[x] === obj.OrientationName) {
    //             return true;
    //         }
    //     }
    //     return false;
    // }
    // setproductOrientationSelected(obj) {

    //     if (obj.OrientationSelected)// Add If Not Exist
    //     {
    //         for (var x = 0; x < this.ProductOrientations.Selected.length; x++) {
    //             if (obj.OrientationName === this.ProductOrientations.Selected[x]) {
    //                 this.productOrientationSelected();
    //                 return;
    //             }
    //         }
    //         this.ProductOrientations.Selected.push(obj.OrientationName);
    //         this.productOrientationSelected();
    //         return;
    //     }
    //     else // Remove
    //     {
    //         for (var x = 0; x < this.ProductOrientations.Selected.length; x++) {
    //             if (obj.OrientationName === this.ProductOrientations.Selected[x]) {
    //                 if (!obj.OrientationSelected) {
    //                     this.ProductOrientations.Selected.splice(x, 1);
    //                     this.productOrientationSelected();
    //                     return;
    //                 }
    //             }
    //         }
    //     }

    //     this.productOrientationSelected();
    // }
    // productOrientationSelected() {
    //     this.psSDO.SelectedOrientations = this.ProductOrientations.Selected;

    //     this.isPlankSelected = false;
    //     // update plank message
    //     for (var oIndex = 0; oIndex < this.ProductOrientations.Selected.length; oIndex++) {
    //         if (this.ProductOrientations.Selected[oIndex] === 'plank') {
    //             this.isPlankSelected = true;
    //         }
    //     }
    //     this.productSelectionService.postOrientationChangeEvent({
    //         'selected': this.psSDO.SelectedOrientations
    //     }).then((resp) => {
    //     });
    // }

    // customDepthChanged() {
    //     var data = {
    //         'value': this.customDepth
    //     };
    //     this.productSelectionService.postCustomDepthchangeEvent(data)
    //         .then((serviceResponse) => {
    //             this.getProductSelectionSDO();
    //         });
    // }

    // customSpacingChanged() {
    //     var data = {
    //         'value': this.customSpacing
    //     };
    //     this.productSelectionService.postCustomSpacingchangeEvent(data)
    //         .then((serviceResponse) => {
    //             this.getProductSelectionSDO();
    //         });
    // }

    // getProductGroupFromAvailable(prodGrpID) {
    //     if (this.ProductGroups && this.ProductGroups.Available && this.ProductGroups.Available.length > 0) {
    //         for (var pgIndex = 0; pgIndex < this.ProductGroups.Available.length; pgIndex++) {
    //             if (this.ProductGroups.Available[pgIndex].ProductGroupID === prodGrpID) {
    //                 return this.ProductGroups.Available[pgIndex];
    //             }
    //         }
    //     }

    //     return null;
    // }

    // getProductSeriesFromAvailable(prodSeriesID) {
    //     if (this.ProductSeries.Available && this.ProductSeries.Available.length > 0) {
    //         for (var psIndex = 0; psIndex < this.ProductSeries.Available.length; psIndex++) {
    //             if (this.ProductSeries.Available[psIndex].ProductSeriesID === prodSeriesID) {
    //                 return this.ProductSeries.Available[psIndex];
    //             }
    //         }
    //     }

    //     return null;
    // }

    // // Select All methods

    // selectAllSeries() {
    //     if (this.ProductSeries.Visible && this.ProductSeries.Visible.length > 0) {
    //         if (this.ProductSeries.Selected && this.ProductSeries.Selected.length === this.ProductSeries.Visible.length) {
    //             // all series are selected, so deselect all
    //             this.ProductSeries.Selected = [];
    //             if (this.psSDO) {
    //                 this.psSDO.SelectedSeriesNames = [];
    //             }
    //         } else {
    //             // push all from this.ProductSeries.Visible into:
    //             // this.ProductSeries.Selected (names)
    //             // this.psSDO.SelectedSeriesNames (names)
    //             this.ProductSeries.Selected = [];
    //             if (this.psSDO) {
    //                 this.psSDO.SelectedSeriesNames = [];
    //             }

    //             for (var vpIndex = 0; vpIndex < this.ProductSeries.Visible.length; vpIndex++) {
    //                 this.ProductSeries.Selected.push(this.ProductSeries.Visible[vpIndex].ProductSeriesName);
    //                 if (this.psSDO) {
    //                     this.psSDO.SelectedSeriesNames.push(this.ProductSeries.Visible[vpIndex].ProductSeriesName);
    //                 }
    //             }
    //         }
    //     }

    //     this.productSelectionService.postSeriesChangeEvent({
    //         'selected': this.ProductSeries.Selected
    //     }).then((serviceResponse) => {});

    //     this.refreshProductDepths();
    // }

    // selectAllDepths() {
    //     if (this.ProductDepths.Visible && this.ProductDepths.Visible.length > 0) {
    //         if (this.ProductDepths.Selected && this.ProductDepths.Selected.length === this.ProductDepths.Visible.length) {
    //             // all depths are currently selected, so deselect all
    //             this.ProductDepths.Selected = [];
    //             if (this.psSDO) {
    //                 this.psSDO.SelectedDepthIDs = [];
    //                 this.psSDO.SelectedDepthNames = [];
    //             }

    //             for (var vdIndex = 0; vdIndex < this.ProductDepths.Visible.length; vdIndex++) {
    //                 this.ProductDepths.Visible[vdIndex].DepthSelected = false;
    //             }
    //         } else {
    //             // push this.ProductDepths.Visible names into this.ProductDepths.Selected
    //             // push this.ProductDepths.Visible names into this.psSDO.SelectedDepthNames
    //             // push this.ProductDepths.Visible IDs into this.psSDO.SelectedDepthIDs
    //             this.ProductDepths.Selected = [];
    //             for (var vdIndex = 0; vdIndex < this.ProductDepths.Visible.length; vdIndex++) {
    //                 this.ProductDepths.Selected.push(this.ProductDepths.Visible[vdIndex].DepthName);
    //                 if (this.psSDO) {
    //                     this.psSDO.SelectedDepthIDs.push(this.ProductDepths.Visible[vdIndex].DepthID);
    //                     this.psSDO.SelectedDepthNames.push(this.ProductDepths.Visible[vdIndex].DepthName);
    //                 }
    //             }
    //         }

    //         this.productSelectionService.postDepthChangeEvent({
    //             'selected': this.psSDO.SelectedDepthNames
    //         }).then((resp) => {});
    //     }
    // }

    // selectAllSpacings() {
    //     if (this.ProductSpacings && this.ProductSpacings.Visible && this.ProductSpacings.Visible.length > 0) {
    //         if (this.ProductSpacings.Selected && this.ProductSpacings.Selected.length === this.ProductSpacings.Visible.length) {
    //             // all spacings are selected, so deselect all
    //             this.ProductSpacings.Selected = [];
    //             if (this.psSDO) {
    //                 this.psSDO.SelectedSpacingsIDs = [];
    //                 this.psSDO.SelectedSpacingsNames = [];
    //             }

    //             for (var vsIndex = 0; vsIndex < this.ProductSpacings.Visible.length; vsIndex++) {
    //                 this.ProductSpacings.Visible[vsIndex].SpacingSelected = false;
    //             }
    //         } else {
    //             // select all spacings
    //             this.ProductSpacings.Selected = [];
    //             for (var vsIndex = 0; vsIndex < this.ProductSpacings.Visible.length; vsIndex++) {
    //                 this.ProductSpacings.Selected.push(this.ProductSpacings.Visible[vsIndex].SpacingName);
    //                 if (this.psSDO) {
    //                     this.psSDO.SelectedSpacingsIDs.push(this.ProductSpacings.Visible[vsIndex].SpacingID);
    //                     this.psSDO.SelectedSpacingsNames.push(this.ProductSpacings.Visible[vsIndex].SpacingName);
    //                 }
    //             }
    //         }

    //         this.productSelectionService.postSpacingChangeEvent({
    //             'selected': this.psSDO.SelectedSpacingsNames
    //         }).then((resp) => {});
    //     }
    // }

    // selectAllPlies() {
    //     if (this.ProductPlies && this.ProductPlies.Available && this.ProductPlies.Available.length > 0) {
    //         if (this.ProductPlies.Selected && this.ProductPlies.Selected.length === this.ProductPlies.Available.length) {
    //             // all plies are selected, so deselect all
    //             this.ProductPlies.Selected = [];
    //             if (this.psSDO) {
    //                 this.psSDO.SelectedPliesNames = [];
    //             }
    //             for (var vpIndex = 0; vpIndex < this.ProductPlies.Available.length; vpIndex++) {
    //                 this.ProductPlies.Available[vpIndex].PliesSelected = false;
    //             }
    //         } else {
    //             // select all plies
    //             this.ProductPlies.Selected = [];
    //             if (this.psSDO) {
    //                 this.psSDO.SelectedPliesNames = [];
    //             }

    //             for (var vpIndex = 0; vpIndex < this.ProductPlies.Available.length; vpIndex++) {
    //                 this.ProductPlies.Selected.push(this.ProductPlies.Available[vpIndex].PliesName);
    //                 this.ProductPlies.Available[vpIndex].PliesSelected = true;
    //                 if (this.psSDO) {
    //                     this.psSDO.SelectedPliesNames.push(this.ProductPlies.Available[vpIndex].PliesName);
    //                 }
    //             }
    //         }

    //         this.productSelectionService.postPliesChangeEvent({
    //             'selected': this.psSDO.SelectedPliesNames
    //         }).then((resp) => {});
    //     }
    // }

    // selectAllProductGroups() {
    //     // post to API first since we may as well
    //     this.productSelectionService.postSelectAllProductGroups().then((resp) => {});

    //     if (this.ProductGroups && this.ProductGroups.Selected && this.ProductGroups.Available && this.ProductGroups.Selected.length === this.ProductGroups.Available.length) {
    //         // all product groups are selected; deselect all
    //         this.ProductGroups.Selected.length = 0;
    //         for (var pgIndex = 0; pgIndex < this.ProductGroups.Available.length; pgIndex++) {
    //             this.ProductGroups.Available[pgIndex].ProductGroupSelected = false;
    //         }
    //         var sdoKeys = Object.keys(this.psSDO.ProductGroupValues);
    //         for (var index = 0; index < sdoKeys.length; index++) {
    //             this.psSDO.ProductGroupValues[sdoKeys[index]].Checked = false;
    //         }
    //     } else {
    //         // not all product groups are selected; select all
    //         this.ProductGroups.Selected.length = 0;
    //         for (var pgIndex = 0; pgIndex < this.ProductGroups.Available.length; pgIndex++) {
    //             this.ProductGroups.Available[pgIndex].ProductGroupSelected = true;
    //             this.ProductGroups.Selected.push(this.ProductGroups.Available[pgIndex].ProductGroupID);
    //         }
    //         var sdoKeys = Object.keys(this.psSDO.ProductGroupValues);
    //         for (var index = 0; index < sdoKeys.length; index++) {
    //             this.psSDO.ProductGroupValues[sdoKeys[index]].Checked = true;
    //         }
    //     }

    //     this.refreshProductSeries();
    // }
}
