var angular=window.angular,ngModule;
try {ngModule=angular.module(["ng"])}
catch(e){ngModule=angular.module("ng",[])}
var v1="\n<div class=\"modal-header\">\r\n        <h4>{{getText('commentsText')}}</h4>\r\n    </div>\r\n    <div class=\"modal-body\">\r\n        <textarea class=\"comment-modal-body\" ng-model=\"comment\" ng-focus=\"selectAll($event)\" autofocus></textarea>\r\n    </div>\r\n    <div class=\"modal-footer\">\r\n        <div>\r\n            <button type=\"button\" class=\"btn btn-primary\" ng-click=\"save()\">{{getText('ok')}}</button>\r\n            <button type=\"button\" class=\"btn btn-danger\" ng-click=\"cancel()\">{{getText('cancel')}}</button>\r\n        </div>\r\n    </div>\n";
var id1="modifyCommentModal.html";
var inj=angular.element(window.document).injector();
if(inj){inj.get("$templateCache").put(id1,v1);}
else{ngModule.run(["$templateCache",function(c){c.put(id1,v1)}]);}
var v2="\n<div class=\"modal-header\">\r\n        <h3>{{getText('timedOut')}}</h3>\r\n    </div>\r\n    <div class=\"modal-body\">\r\n        <p>\r\n            {{getText('idleTooLong')}}\r\n        </p>\r\n    </div>\n";
var id2="timedout-dialog.html";
var inj=angular.element(window.document).injector();
if(inj){inj.get("$templateCache").put(id2,v2);}
else{ngModule.run(["$templateCache",function(c){c.put(id2,v2)}]);}
var v3="\n<div class=\"modal-header\">\r\n        <h4>{{getText('idleForTooLong')}}</h4>\r\n    </div>\r\n    <div idle-countdown=\"countdown\" ng-init=\"countdown=20\" class=\"progress\">\r\n        <div class=\"progress progress-striped active\">\r\n            <div class=\"progress-bar progress-bar-danger \" role=\"progressbar\" aria-valuenow=\"{{countdown}}\" aria-valuemin=\"0\" aria-valuemax=\"200\"\r\n                style=\"width: {{countdown/60*100}}%\">\r\n            </div>\r\n        </div>\r\n    </div>\n";
var id3="warning-dialog.html";
var inj=angular.element(window.document).injector();
if(inj){inj.get("$templateCache").put(id3,v3);}
else{ngModule.run(["$templateCache",function(c){c.put(id3,v3)}]);}
var v4="<style>\n.marginForGraphicToggle {\r\n        margin-top: 20px;\r\n    }\r\n\r\n    .modal-title {\r\n        font-size: 20px;\r\n    }\r\n\r\n    .comment-modal-body {\r\n        resize: none;\r\n        width: 100%;\r\n        height: 60%;\r\n    }\n</style>\n<nav class=\"navbar navbar-default navbarheader navbar-fixed-top\">\n<div class=\"container-fluid\">\n<div id=\"header\" class=\"unSelectText\" ng-controller=\"headerController as headerCtrl\">\n<div class=\"navbar-header\">\n<button type=\"button\" class=\"navbar-toggle collapsed reverseColorNavBtn\" data-toggle=\"collapse\" data-target=\"#navbar\" aria-expanded=\"false\" aria-controls=\"navbar\">\n<span class=\"sr-only\">Toggle navigation</span>\n<span class=\"fa fa-th fa-inverse headerIcon\"></span>\n</button>\n<img src=\"" + require("../../../../images/forteWeb_byWY_295x50.png") + "\" class=\"navbar-brand navbar-left img-responsive\"/>\n</div>\n<div ng-include=\"'header.html'\"></div>\n</div>\n</div>\n</nav>\n<div ng-hide=\"shellCtrl.showSplash===false\" class=\"cssFade logged-in-splash\">\n<div class=\"logged-in-splash splash-bg\"> </div>\n<div class=\"logged-in-splash splash-foreground\">\n<div class=\"splash-foreground-content\">\n<img src=\"" + require("../../../../images/forteWeb_byWY_400x68.png") + "\" class=\"img-responsive\"/>\n<h3>{{shellCtrl.splashMessage}}</h3>\n<div class=\"progress progress-striped active\">\n<div class=\"progress-bar progress-bar-danger\" role=\"progressbar\" aria-valuenow=\"100\" aria-valuemin=\"0\" aria-valuemax=\"100\" style=\"width: 100%\">\n</div>\n</div>\n</div>\n</div>\n</div>\n<div style=\"display:inline\">\n<div class=\"shellWrapper container-fluid\" ui-view>\n</div>\n</div>\n\n\n\n<div class=\"modal fade modal-dialogTest\" id=\"whatsNewTourModal\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"myModalLabel\" ng-include=\"'whatsNewTemplate.html'\">\n</div>\n";
var id4="shell.html";
var inj=angular.element(window.document).injector();
if(inj){inj.get("$templateCache").put(id4,v4);}
else{ngModule.run(["$templateCache",function(c){c.put(id4,v4)}]);}
module.exports=v4;