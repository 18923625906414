export default class floorPerformanceController {
    constructor($scope, floorPerformanceService, resourceMessagingService) {
        'ngInject';

        this.$scope = $scope;
        this.floorPerformanceService = floorPerformanceService;
        this.resourceMessagingService = resourceMessagingService;

        this.metadata = null;
        this.floorPerformanceSDO = null;
        this.isTJProRatingAny = null;
        this.tjProRatingValue = 0;
        this.selectedDeckMaterial = null;
        this.selectedStrapping = null;
        this.selectedCeilingType = null;
        this.isBridgingBlocking = null;
        this.isPerpendicular = null;
        this.isPourFlooringOverlay = null;
        this.sheathingAttachmentType = null;

        this.activate();
    }

    activate() {
        this.$scope.$emit('showGraphicToggle', false);

        this.getMetadata();
        this.getFloorPerformanceSDO();
    }

    selectAllTextOnFocus($event) {
        $event.target.select();
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    // Get Data

    getMetadata() {
        this.floorPerformanceService.getMetadata().then((response) => {
            this.metadata = response;
        });
    }

    getFloorPerformanceSDO() {
        this.floorPerformanceSDO = this.floorPerformanceService.getFloorPerformanceSDO();

        this.isTJProRatingAny = this.floorPerformanceSDO.TJProRatingAny;
        this.selectedDeckMaterial = this.floorPerformanceSDO.DeckingID;
        this.selectedStrapping = this.floorPerformanceSDO.Strapping;
        this.selectedCeilingType = this.floorPerformanceSDO.CeilingType;
        this.isBridgingBlocking = this.floorPerformanceSDO.UseTJIBlocking;
        this.isPerpendicular = this.floorPerformanceSDO.PerpendicularPartitions;
        this.isPourFlooringOverlay = this.floorPerformanceSDO.PourFlooringOverlay;
        this.tjProRatingValue = this.floorPerformanceSDO.UserMinimumTJProRating;
        this.tjProRatingValueNumber = this.tjProRatingValue;
        this.sheathingAttachmentType = this.floorPerformanceSDO.SheathingAttachment;
    }

    tjProRatingAnyChanged() {
        var data = {
            'value': this.isTJProRatingAny
        };
        this.floorPerformanceService.modifyTJProRatingAny(data)
            .then((serviceResponse) => {
                this.getFloorPerformanceSDO();
            });
    }

    preventInputsInCustomTJProRating(keyPressEvent) {
        if (keyPressEvent.which  === 8 || keyPressEvent.which  === 0) {
            // backspace, null
            return;
        }
        if (keyPressEvent.which < 48 || keyPressEvent.which > 57) {
            // numbers 0-9 OK
            keyPressEvent.preventDefault();
        }
    }

    tjProRatingValueChanged() {
        var data = {
            'value': this.tjProRatingValue
        };
        this.floorPerformanceService.modifyTJProRatingValue(data)
            .then((serviceResponse) => {
                this.getFloorPerformanceSDO();
            });
    }

    tjProRatingValueNumberChanged() {
        if (this.isValidProRating(this.tjProRatingValueNumber)) {
            var data = {
                'value': this.tjProRatingValueNumber
            };
            this.floorPerformanceService.modifyTJProRatingValue(data)
                .then((serviceResponse) => {
                    this.getFloorPerformanceSDO();
                });
        }
        else if (this.tjProRatingValueNumber <= 0) {
            var data = {
                'value': 20
            };
            this.floorPerformanceService.modifyTJProRatingValue(data)
                .then((serviceResponse) => {
                    this.getFloorPerformanceSDO();
                });
        } else {
            var data = {
                'value': 70
            };
            this.floorPerformanceService.modifyTJProRatingValue(data)
                .then((serviceResponse) => {
                    this.getFloorPerformanceSDO();
                });
        }
    }

    isValidProRating(proRatingValue) {
        var validProRating = false;
        if (this.isNumeric(proRatingValue)) {
            if (proRatingValue > 0 && proRatingValue < 255) {
                validProRating = true;
            }
        }
        return validProRating;
    }

    testIsValidProRating(proRatingValue) {
        return this.isValidProRating(proRatingValue);
    }

    isNumeric(n) {
        return !isNaN(parseInt(n)) && isFinite(n);
    }

    selectedDeckingMaterialChanged() {
        var data = {
            'value': this.selectedDeckMaterial
        };
        this.floorPerformanceService.modifyDeckingMaterial(data)
            .then((serviceResponse) => {
                this.getFloorPerformanceSDO();
            });
    }

    selectedStrappingChanged() {
        var data = {
            'value': this.selectedStrapping
        };
        this.floorPerformanceService.modifyStrapping(data)
            .then((serviceResponse) => {
                this.getFloorPerformanceSDO();
                this.getMetadata(); //need to refresh metadata as CeilingTypeOptions depend on the strapping type selected.
            });
    }

    selectedCeilingTypeChanged() {
        var data = {
            'value': this.selectedCeilingType
        };
        this.floorPerformanceService.modifyCeiling(data)
            .then((serviceResponse) => {
                this.getFloorPerformanceSDO();
            });
    }

    bridgingBlockingChanged() {
        var data = {
            'value': this.isBridgingBlocking
        };
        this.floorPerformanceService.modifyBridgingBlocking(data)
            .then((serviceResponse) => {
                this.getFloorPerformanceSDO();
            });
    }

    perpendicularChanged() {
        var data = {
            'value': this.isPerpendicular
        };
        this.floorPerformanceService.modifyPerpendicular(data)
            .then((serviceResponse) => {
                this.getFloorPerformanceSDO();
            });
    }

    pourFlooringOverlayChanged() {
        var data = {
            'value': this.isPourFlooringOverlay
        };
        this.floorPerformanceService.modifyPourFlooringOverlay(data)
            .then((serviceResponse) => {
                this.getFloorPerformanceSDO();
            });
    }

    sheathingAttachmentChanged() {
        var data = {
            'value': this.sheathingAttachmentType
        };
        this.floorPerformanceService.modifySheathingAttachment(data)
            .then((serviceResponse) => {
                this.getFloorPerformanceSDO();
            });
    }

}
