export default function logProviderConfig ($logProvider) {
    'ngInject';
    if ($logProvider.debugEnabled) {
        $logProvider.debugEnabled(true);
    }
}
// app.config(['$logProvider', function ($logProvider) {
//     // turn debugging off/on (no info or warn)
//     if ($logProvider.debugEnabled) {
//         $logProvider.debugEnabled(true);
//     }
// }]);
