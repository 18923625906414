export default class linkedReactionController {
    constructor($rootScope, $scope, $uibModalInstance, common, allModelDataService, resourceMessagingService, loadsHorizYService, linkLoadService, loadToUpdate) {
        'ngInject';

        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        this.common = common;
        this.allModelDataService = allModelDataService;
        this.resourceMessagingService = resourceMessagingService;
        this.loadsHorizYService = loadsHorizYService;
        this.linkLoadService = linkLoadService;
        this.loadToUpdate = loadToUpdate;

        //var $q = common.$q;

        this.sourceTreeNodeID;

        this.jobTreeData = null;
        this.isEnglish = true;

        this.pointLBContactType = {
            name:this.getText('pointLB'),
            disabled: false,
            apiEnum: 0,
            selected: true,
            showToLocation: false
        };
        this.pointPLFContactType = {
            name:this.getText('pointPLF'),
            disabled: false,
            apiEnum: 1,
            showToLocation: false
        };
        this.uniformPLFContactType = {
            name:this.getText('uniformPLF'),
            disabled: false,
            apiEnum: 2,
            showToLocation: true
        };

        this.contactTypes = [this.pointLBContactType,this.pointPLFContactType,this.uniformPLFContactType];

        this.loadPlacements = [];//Top = 0 FrontSide = 1 BackSide = 2
        this.selectedPlacement = '';
        this.transferReaction = [];
        this.repetativeDisabled = true;
        this.transferReactionDisabled = true;
        this.hideEndLocation = true;
        this.sourceNode = null;
        this.reactionToTransfer = '';
        this.graphicId = 'transferfrom';
        this.selectedFromMemberName = '';
        this.contactType = 0;
        this.selectedContactType;
        this.validNodeSelected;
        this.invalidNodeMessage;

        this.startLocationString = null;
        this.endLocationString = null;

        this.prData = null;
        this.trData = null;

        this.treeOptions = {
        };

        this.activate();

    }

    activate() {

        this.validNodeSelected = false;
        this.invalidNodeMessage = this.getText('lrSelectMember');
        this.selectedContactType = this.pointLBContactType;
        this.prData = this.allModelDataService.getPostResponseData();
        this.sourceTreeNodeID = this.allModelDataService.tryGetSelectedJobTreeNode();
        this.sourceTreeNodeID = this.sourceTreeNodeID.id;
        this.refreshJobTree();

        this.loadPlacements[0] = this.getText('loadPlacementTop');
        this.loadPlacements[1] = this.getText('loadPlacementFrontSide');
        this.loadPlacements[2] = this.getText('loadPlacementBackSide');
        this.selectedPlacement = '1';

        this.isEnglish = this.resourceMessagingService.isCultureEnglish();
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    transferToVerticalLoadsGrid() {
        if (this.hideEndLocation && this.startLocationString !== this.endLocationString) {
            this.linkLoadService.getFriendlyLocation(this.startLocationString, null).then((response) => {
                this.startLocationString = response.locationFriendly;
                this.endLocationString = this.startLocationString;
                this.transferToVerticalLoads();
            })
            .catch((err) => {
            });
        } else {
            this.transferToVerticalLoads();
        }
    }

    transferToVerticalLoads() {
        var dataforpost = {
            fromMemberIdTag: this.fromMemberIdTag,
            fromMemberName: this.fromMemberName,
            fromMemberType: this.fromMemberType,
            toMemberIdTag: this.prData.ApplicationData.SelectedMemberIDTag,
            locationStart: this.startLocationString,
            locationEnd: this.endLocationString,
            supportReactions: this.trData.supportReactions[this.selectedSupportIndex],
            spacingFactor: this.spacingFactor,
            contactType: this.selectedContactType.apiEnum,
            supportIndex: this.selectedSupportIndex,
            tribWidth: 12,
            loadPlacement: this.selectedPlacement
        };
        if (this.loadToUpdate) {
            //append the view position onto the dataforpost.
            dataforpost.viewPosition = this.loadToUpdate.viewPosition;

            this.loadsHorizYService.updateTransferredReaction(dataforpost).then(
                (response) => {
                    this.$uibModalInstance.close('success');
                },
                (response) => {
                    this.$uibModalInstance.close('failure');
                });
        } else {
            this.loadsHorizYService.addNewTransferredReaction(dataforpost).then(
                (response) => {
                    this.$uibModalInstance.close('success');
                },
                (response) => {
                    this.$uibModalInstance.close('failure');
                });
        }
    }

    cancelClicked() {
        this.$uibModalInstance.close('cancel');
    }

    refreshJobTree() {
        this.showPendingColumnOverlay = true;
        this.linkLoadService.getLinkLoadTreeData().then(
                //promise success
                (response) => {
                    this.jobTreeData = [];
                    var tree = response;//createTree(response, this.prData);
                    this.jobTreeData.push(response);
                    var rootNode = response;
                    this.trySelectNodeToEdit(rootNode);
                },
                //promise failure
                (response) => {
                    //log the error
                }
            );
    }

    trySelectNodeToEdit(root) {
        if (this.loadToUpdate) {
            for (var i = 0; i < root.children.length; i++) {
                var level = root.children[i];
                for (var j = 0; j < level.children.length; j++) {
                    var member = level.children[j];
                    if (member.idTag === this.loadToUpdate.linkedReaction.FromMemberIDTag) {
                        this.selectedNode = member;
                    }
                }
            }

            this.treeNodeSelected(this.selectedNode);
        }
    }


    getNodeID(stringID) {
        var nodeIDs = {containerID: -1, memberID: -1};
        var lastUnderscore = stringID.lastIndexOf('_');
        var memberId = stringID.substring(lastUnderscore + 1, stringID.length);
        var containerId = stringID.substring(2, lastUnderscore - 1);
        nodeIDs.containerID = containerId;
        nodeIDs.memberID = memberId;
        return nodeIDs;
    }

    isNodeInvalid(node) {
        if (node.isDesigned === false ||
        node.hasValidPaeSolution === false ||
        node.waitingOnDesignOfLinkedMember === true ||
        node.wouldResultInCircularLink === true) {
            return true;
        }
        return false;
    }

    treeNodeSelected(node) {
        this.validNodeSelected = false;
        this.selectedNode = node;

        if (node.waitingOnDesignOfLinkedMember === true) {
            this.invalidNodeMessage = this.getText('lrWaitingOnDesign');
        } else if (node.isDesigned === false && node.isDesignable === true) {
            this.invalidNodeMessage = this.getText('lrMemberNotDesigned');
        } else if (node.isDesigned === false && node.isDesignable === false) {
            this.invalidNodeMessage = this.getText('lrNotDesignable');
        } else if (node.hasValidPaeSolution === false) {
            this.invalidNodeMessage = this.getText('lrNoValidSolution');
        }  else if (node.wouldResultInCircularLink === true) {
            this.invalidNodeMessage = this.getText('lrCircularLink');
        }
        else {
            this.validNodeSelected = true;

            var lastUnderscore = this.selectedNode.id.lastIndexOf('_');
            var memberId = this.selectedNode.id
                .substring(lastUnderscore + 1, this.selectedNode.id.length);
            var containerId = this.selectedNode.id.substring(2, lastUnderscore - 1);
            var deferred = this.$q.defer();
            this.setUpInputs(containerId, memberId);
            return deferred.promise;
        }
    }

    setUpInputs(containerId, memberId) {
        this.supportDictionary = [];

        var selectedFromMemberSDO = this.prData.ApplicationData.MemberManagerData.MemberContainers[containerId].Members[memberId];
        this.fromMemberIdTag = selectedFromMemberSDO.IDTag;
        this.fromMemberName = selectedFromMemberSDO.MemberName;

        var dataforpost = {memberIdTag: selectedFromMemberSDO.IDTag, postResponseData: this.prData};
        this.loadsHorizYService.getTransferableReactionData(dataforpost).then(function(response) {
            this.trData = response;

            this.spacingFactor = this.trData.spacingFactor;
            this.fromMemberType = this.trData.memberType;

            this.$scope.$broadcast('refreshGraphicFromServer');
            this.$rootScope.$broadcast('refreshGraphic_transferfrom', this.trData.memberGraphic);

            this.supportDictionary = {};
            for (var s = 0; s < this.trData.supportCount; ++s)
            {
                this.supportDictionary[s] = s + 1;
            }

            this.repetativeAvailable();

            if (this.loadToUpdate && this.loadToUpdate.linkedReaction.FromMemberIDTag === selectedFromMemberSDO.IDTag) {
                this.selectedSupportIndex = this.loadToUpdate.linkedReaction.SupportIndex.toString();
                this.selectedPlacement = this.loadToUpdate.loadPlacement.toString();
                switch (this.loadToUpdate.loadType) {
                    case this.pointLBContactType.apiEnum:
                        this.contactTypeChanged(this.pointLBContactType);
                        break;
                    case this.pointPLFContactType.apiEnum:
                        this.contactTypeChanged(this.pointPLFContactType);
                        break;
                    case this.uniformPLFContactType.apiEnum:
                        this.contactTypeChanged(this.uniformPLFContactType);
                        break;
                    default:
                        this.contactTypeChanged(this.pointLBContactType);
                }
                this.startLocationString = this.loadToUpdate.startLocation;
                this.endLocationString = this.loadToUpdate.endLocation;
            }
            else {
                this.selectedSupportIndex = '0';
                this.selectedPlacement = '1';
                this.startLocationString = '0';
                this.endLocationString = '0';
            }

            this.transferReactionDisabled = false;//enable the transfer loads button.

            //reset locations to zero
            this.startLocationChanged();
            this.endLocationChanged();
        });
    }

    repetativeAvailable() {
        var radios = document.getElementsByClassName('radioButton');
        var sourceNodeID = this.getNodeID(this.sourceTreeNodeID);
        var sourceNodeData = this.prData.ApplicationData.MemberManagerData.MemberContainers[sourceNodeID.containerID].Members[sourceNodeID.memberID];
        if (this.trData.memberType === 1)
        {
            this.repetativeDisabled = false;
            this.uniformPLFContactType.disabled = false;
            this.pointPLFContactType.disabled = false;
        }
        else {
            this.repetativeDisabled = true;
            this.uniformPLFContactType.disabled = true;
            this.pointPLFContactType.disabled = true;
        }

        if (this.repetativeDisabled === true)
        {
            //check if any repetative ones are selected and if so force select point
            if (this.selectedContactType === this.uniformPLFContactType)
            {
                this.selectedContactType = this.pointLBContactType;
                this.hideEndLocation = true;
            }
        }
    }

    contactTypeChanged(newContactType) {
        this.selectedContactType = newContactType;

        if (this.selectedContactType.showToLocation)
        {
            this.hideEndLocation = false;
            //endlocation box can be seen, set default value to 0
            this.endLocationString = '0';
        }
        else {
            this.hideEndLocation = true;
        }
    }

    startLocationChanged() {
        this.linkLoadService.getFriendlyLocation(this.startLocationString, null).then((response) => {
            this.startLocationString = response.locationFriendly;
            if (this.hideEndLocation)
            {
                this.endLocationString = this.startLocationString;
            }
        })
        .catch((err) => {
        });
    }

    endLocationChanged() {
        this.linkLoadService.getFriendlyLocation(this.endLocationString, null).then((response) => {
            this.endLocationString = response.locationFriendly;
        })
        .catch((err) => {
        });

    }

    selectAllTextOnFocus($event) {
        $event.target.select();
    }

    helpClicked() {
        var win = window.open('/helpmobile/Content/C_Tabs/05a_link_load.htm', '_blank');
        win.focus();
    }

}
