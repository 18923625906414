import toastr from 'toastr';
export default class logger {

    constructor($log) {
        'ngInject';
        this.$log = $log;
        this.clearToastr();
    }

    clearToastr() {
        toastr.clear();
        toastr.options.timeOut = 40000000;
        toastr.options.positionClass = 'toast-bottom-right';
    }

    getLogFn(moduleId, fnName) {
        fnName = fnName || 'log';
        var logFn;
        switch (fnName.toLowerCase()) { // convert aliases
            case 'success':
                return (msg, data, showToast) => {
                    this.logIt(msg, data, moduleId, (showToast === undefined) ? true : showToast, 'success');
                };
                break;
            case 'error':
                return (msg, data, showToast) => {
                    this.logIt(msg, data, moduleId, (showToast === undefined) ? true : showToast, 'error');
                };
                break;
            case 'warn':
                return (msg, data, showToast) => {
                    this.logIt(msg, data, moduleId, (showToast === undefined) ? true : showToast, 'warning');
                };
                break;
            case 'warning':
                return (msg, data, showToast) => {
                    this.logIt(msg, data, moduleId, (showToast === undefined) ? true : showToast, 'warning');
                };
                break;
            default:
                return (msg, data, showToast) => {
                    this.logIt(msg, data, moduleId, (showToast === undefined) ? true : showToast, 'info');
                };
                break;
        }

        return (msg, data, showToast) => {
            logFn(msg, data, moduleId, (showToast === undefined) ? true : showToast);
        };
    }

    processingToast(message) {
        toastr.clear();
        toastr.options.timeOut = 0;
        toastr.options.positionClass = 'toast-bottom-right';
        toastr.info(message);
    }

    logIt(message, data, source, showToast, toastType) {
        var write = (toastType === 'error') ? this.$log.error : this.$log.log;
        source = source ? '[' + source + '] ' : '';
        write(source, message, data);
        if (showToast) {
            if (toastType === 'error') {
                toastr.error(message); //eslint-disable-line no-undef
            } else if (toastType === 'warning') {
                toastr.warning(message); //eslint-disable-line no-undef
            } else if (toastType === 'success') {
                toastr.success(message); //eslint-disable-line no-undef
            } else {
                toastr.info(message); //eslint-disable-line no-undef
            }
        }
    }
}
// /*eslint-disable no-undef*/
// (function () {
//     'use strict';

//     angular.module('common').factory('logger', ['$log', logger]);

//     function logger($log) {

//         var service = {
//             getLogFn: getLogFn,
//             log: log,
//             logError: logError,
//             logSuccess: logSuccess,
//             logWarning: logWarning,
//             processingToast: processingToast,
//             clearToastr: clearToastr
//         };

//         clearToastr();

//         return service;

//         function clearToastr() {
//             toastr.clear();
//             toastr.options.timeOut = 4000;
//             toastr.options.positionClass = 'toast-bottom-right';
//         }

//         function getLogFn(moduleId, fnName) {
//             fnName = fnName || 'log';
//             switch (fnName.toLowerCase()) { // convert aliases
//                 case 'success':
//                     fnName = 'logSuccess'; break;
//                 case 'error':
//                     fnName = 'logError'; break;
//                 case 'warn':
//                     fnName = 'logWarning'; break;
//                 case 'warning':
//                     fnName = 'logWarning'; break;
//             }

//             var logFn = service[fnName] || service.log;
//             return function (msg, data, showToast) {
//                 logFn(msg, data, moduleId, (showToast === undefined) ? true : showToast);
//             };
//         }

//         function log(message, data, source, showToast) {
//             logIt(message, data, source, showToast, 'info');
//         }

//         function logWarning(message, data, source, showToast) {
//             logIt(message, data, source, showToast, 'warning');
//         }

//         function logSuccess(message, data, source, showToast) {
//             logIt(message, data, source, showToast, 'success');
//         }

//         function logError(message, data, source, showToast) {
//             logIt(message, data, source, showToast, 'error');
//         }

//         function processingToast(message) {
//             toastr.clear();
//             toastr.options.timeOut = 0;
//             toastr.options.positionClass = 'toast-bottom-right';
//             toastr.info(message);
//         }

//         function logIt(message, data, source, showToast, toastType) {
//             var write = (toastType === 'error') ? $log.error : $log.log;
//             source = source ? '[' + source + '] ' : '';
//             write(source, message, data);
//             if (showToast) {
//                 if (toastType === 'error') {
//                     toastr.error(message); //eslint-disable-line no-undef
//                 } else if (toastType === 'warning') {
//                     toastr.warning(message); //eslint-disable-line no-undef
//                 } else if (toastType === 'success') {
//                     toastr.success(message); //eslint-disable-line no-undef
//                 } else {
//                     toastr.info(message); //eslint-disable-line no-undef
//                 }
//             }
//         }
//     }
// }());
// /*eslint-enable no-undef*/
