export default class windLoadingController {

    constructor ($scope, allModelDataService, resourceMessagingService, windLoadingService) {
        'ngInject';

        this.$scope = $scope;
        this.allModelDataService = allModelDataService;
        this.resourceMessagingService = resourceMessagingService;
        this.windLoadingService = windLoadingService;

        this.selectedCategoryKey;
        this.selectedWindSpeed;

        this.activate();
    }

    activate() {
        this.waitingOnMetaData = true;
        this.getMetaData();

        this._refreshWindLoadingData();

        this.getSpeedSelections();

        this.windLoadApplicationOptions = [
            {
                'Key': 0,
                'Value': 'windSelectedLocationLabel'
            },
            {
                'Key': 1,
                'Value': 'windCustomLabel'
            }
        ];

        this.occupancyCategoryOptions = [
            {
                'key':0,
                'title': 'categoryIIBC2015',
                'text': 'categoryIDescription'
            },
            {
                'key':1,
                'title': 'categoryIIIBC2015',
                'text': 'categoryIIDescription'
            },
            {
                'key':2,
                'title': 'categoryIIIIBC2015',
                'text': 'categoryIIIDescription'
            },
            {
                'key':3,
                'title': 'categoryIVIBC2015',
                'text': 'categoryIVDescription'
            }
        ];
        //remove 2015 from title string if building code is 2009
        if (this.isBuildingCodeIBC2009()) {
            for (var index = 0; index < this.occupancyCategoryOptions.length; index++) {
                var element = this.occupancyCategoryOptions[index];
                element.title = element.title.replace('IBC2015', '');
            }
        }
    }

    showMapHelp() {
        window.open('/helpmobile/Content/D_Design%20Concepts/wind_load_maps.htm', '_blank');
    }

    selectAllTextOnFocus($event) {
        $event.target.select();
    }

    preventInputsInWindSpeed(keyPressEvent) {
        if (keyPressEvent.which  === 8 || keyPressEvent.which  === 0) {
            // backspace, null
            return;
        }
        if (keyPressEvent.which < 48 || keyPressEvent.which > 57) {
            // numbers 0-9 OK
            keyPressEvent.preventDefault();
        }
    }

    getSpeedSelections() {
        if (this.isBuildingCodeIBC2009() === true) {
            this.speedSelections = this.getText('speedselection').split('|');
        } else {
            switch (this.windLoadingData.OccupancyCategory) {
                case 0:
                    this.speedSelections = this.getText('speedselectionIBC1').split('|');
                    break;
                case 1:
                    this.speedSelections = this.getText('speedselectionIBC2').split('|');
                    break;
                case 2:
                    this.speedSelections = this.getText('speedselectionIBC3').split('|');
                    break;
                case 3:
                    this.speedSelections = this.getText('speedselectionIBC4').split('|');
                    break;
                default:
                    this.speedSelections = this.getText('speedselection').split('|');
                    break;
            }
        }
    }

    getWindLoadSettingsMessage() {
        switch (this.buildingCodeEnum) {
            case 20: //ibc2012
            case 30: //ibc2015
            case 33: //ibc2018
            case 36: //ibc2021
            case 5: //ibc2009
                return this.getText('windLoadingDescription');
                break;
            case 24: //nbcc2020
            case 23: //nbcc2015
            case 18: //nbcc2010
                return this.getText('windLoadingDescriptionNBCC');
                break;
        }
    }

    loadApplicationChanged(newValue) {
        this.windLoadingService.modifyLoadApplicationOption(newValue).then(
            (response) => {
                this._refreshWindLoadingData();
            }
        );
    }

    customPressureChanged(newValue) {
        this.windLoadingService.modifyWindPressureCustom(newValue).then(
            (response) => {
                this._refreshWindLoadingData();
            }
        );
    }
    _refreshWindLoadingData() {
        var prData = this.allModelDataService.getPostResponseData();
        this.windLoadingData = prData.ApplicationData.JobWindLoadingData;
        this.buildingCodeEnum = prData.ApplicationData.JobSettingsData.BuildingCodeForteWEB;
        // removing mph from the basic wind speed if building code is IBC2018 or IBC2021
        if (this.isBuildingCodeIBC201820212024()) {
            this.windLoadingData.BasicWindSpeed = this.windLoadingData.BasicWindSpeed.substring(0, 3);
        }
    }

    regionChanged(newValue) {
        this.waitingOnMetaData = true;
        this.windLoadingService.modifySelectedRegion(newValue).then(
            (response) => {
                this.getMetaData();
                this._refreshWindLoadingData();
            }
        );
    }

    locationChanged(newValue) {
        this.waitingOnMetaData = true;
        this.windLoadingService.modifySelectedLocation(newValue).then(
            (response) => {
                this._refreshWindLoadingData();
                this.waitingOnMetaData = false;
            }
        );
    }

    getMetaData() {
        this.windLoadingService.getMetaData().then(
            (response) => {
                this.metaData = response;
                this.waitingOnMetaData = false;
            }
        );
    }

    isHurricaneProneRegionChanged() {
        this.windLoadingService.isHurricaneProneRegionChanged(this.windLoadingData.IsHurricaneProneRegion).then(
            (response) => {
                this.windLoadingData = this.allModelDataService.getPostResponseData().ApplicationData.JobWindLoadingData;
            }
        );
    }

    occupancyCategoryChanged(value) {
        this.windLoadingService.occupancyCategoryChanged(value).then(
            (response) => {
                this.windLoadingData = this.allModelDataService.getPostResponseData().ApplicationData.JobWindLoadingData;
                // removing mph from the basic wind speed if building code is IBC2018 or IBC2021
                if (this.isBuildingCodeIBC201820212024()) {
                    this.windLoadingData.BasicWindSpeed = this.windLoadingData.BasicWindSpeed.substring(0, 3);
                }
                this.getSpeedSelections();
            }
        );
    }

    selectedWindSpeedChanged() {
        if (this.isBuildingCodeIBC201820212024())
        {
            if (this.windLoadingData.BasicWindSpeed < 75) {
                this.windLoadingData.BasicWindSpeed = 75;
            }
            else if (this.windLoadingData.BasicWindSpeed > 250) {
                this.windLoadingData.BasicWindSpeed = 250;
            }
        }
        this.windLoadingService.selectedWindSpeedChanged(this.windLoadingData.BasicWindSpeed).then(
            (response) => {
                this.windLoadingData = this.allModelDataService.getPostResponseData().ApplicationData.JobWindLoadingData;
            }
        );
    }

    doNotApplyWindLoadChanged() {
        this.windLoadingService.doNotApplyWindLoadChanged(this.windLoadingData.DoNotApplyWindLoad).then(
            (response) => {
                this.windLoadingData = this.allModelDataService.getPostResponseData().ApplicationData.JobWindLoadingData;
            }
        );
    }

    isBuildingCodeIBC2009() {
        if (this.buildingCodeEnum === 5) {
            return true;
        }
        return false;
    }

    isBuildingCodeIBC201820212024() {
        if (this.buildingCodeEnum === 33 || this.buildingCodeEnum === 36 || this.buildingCodeEnum === 25) {
            // IBC2018 or IBC2021 or IBC2024
            return true;
        }
        return false;
    }


    isBuildingCodeIBC() {
        var isIBC;
        switch (this.buildingCodeEnum) {
            case 18:
            case 23:
            case 24:
                //NBCC
                isIBC = false;
                break;
            default:
                isIBC = true;
                break;
        }
        return isIBC;
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }
}
