import moment from 'moment';
export default class allModelDataService {
    constructor($rootScope, localStorageService) {
        'ngInject';
        this.$rootScope = $rootScope;
        this.localStorageService = localStorageService;

        this.isSpecTesting = false;
        this.allModelDataTestStub = null;
        this.jobTreeData;
        this.selectedJobTreeNode;
        this.isLocalStorageEnabled;
        this.teamOwnerId;
        this.authorizationData;
        this.postResponseData;
        this.productBlacklist;
        this.graphicDataURL;
        this.graphicOverlayLabels;
        this.solutionsList;
        this.currentTab;
        this.echoLogOn;
        //this.paeVersion;
        //this.paeVersionCommercial;
        this.launchWhatsNewTour;
        this.quickLoadsModelData;
        this.isInitialTour;
        this.currentFWDataVersion = '2.0.0';
        this.sessionTimeStamp = null;
        this.solutionsGridData;

        this.testLocalStorageEnabled();
        this.setEchoLogOff();
        this.checkDataVersionAndClearPRDataIfVersionIsOld();
    }

    setUserProfileIsIncomplete(value) {
        this.userProfileIsIncomplete = value;
        if (this.isLocalStorageEnabled) {
            this.localStorageService.set('userProfileIsIncomplete', this.userProfileIsIncomplete);
        }
    }
    getUserProfileIsIncomplete() {
        if (this.isLocalStorageEnabled) {
            this.userProfileIsIncomplete = this.localStorageService.get('userProfileIsIncomplete');
        }
        return this.userProfileIsIncomplete;
    }

    setMaterialAvailabilityData(data) {
        this.materialAvailabilityData = data;
        if (this.isLocalStorageEnabled) {
            this.localStorageService.set('materialAvailabilityData', this.materialAvailabilityData);
        }
    }
    getMaterialAvailabilityData() {
        if (this.isLocalStorageEnabled) {
            this.materialAvailabilityData = this.userProfileIsIncomplete = this.localStorageService.get('materialAvailabilityData');
        }
        return this.materialAvailabilityData;
    }

    setFileIdToOpen(fileId) {
        this.fileIdToOpen = fileId;
        if (this.isLocalStorageEnabled) {
            this.localStorageService.set('fileIdToOpen', this.fileIdToOpen);
        }
    }
    getFileIdToOpen() {
        if (this.isLocalStorageEnabled) {
            this.fileIdToOpen = this.localStorageService.get('fileIdToOpen');
        }
        return this.fileIdToOpen;
    }

    setSessionTimeStamp() {
        this.sessionTimeStamp = this.getUTCTimeNow().format('MM/DD/YYYY HH:mm:ss').toString();
        if (this.isLocalStorageEnabled) {
            this.localStorageService.set('sessionTimeStamp', this.sessionTimeStamp);
        }
    }
    /* eslint-disable no-undef */
    getSessionTimeStamp() {
        if (this.isLocalStorageEnabled) {
            this.sessionTimeStamp = moment.utc(this.localStorageService.get('sessionTimeStamp'));
        }
        return this.sessionTimeStamp;
    }

    getUTCTimeNow() {
        return moment.utc();
    }

    /* eslint-enable no-undef */

    setIsInitialTour(value) {
        this.isInitialTour = value;
    }

    getIsInitialTour() {
        return this.isInitialTour;
    }

    setLaunchWhatsNewTour(isLaunchWhatsNewTour) {
        this.launchWhatsNewTour = isLaunchWhatsNewTour;
    }

    getLaunchWhatsNewTour() {
        return this.launchWhatsNewTour;
    }

    // setPaeVersion(data) {
    //     this.paeVersion = data;
    //     if (this.isLocalStorageEnabled) {
    //         this.localStorageService.set('paeVersion', data);
    //     }
    // }

    // setPaeVersionCommercial(data) {
    //     this.paeVersionCommercial = data;
    //     if (this.isLocalStorageEnabled === true) {
    //         this.localStorageService.set('paeVersionCommercial', data);
    //     }
    // }

    // tryGetPaeVersion() {
    //     if (this.isLocalStorageEnabled) {
    //         this.paeVersion = this.localStorageService.get('paeVersion');
    //     }
    //     return this.paeVersion;
    // }

    // tryGetPaeVersionCommercial() {
    //     if (this.isLocalStorageEnabled === true) {
    //         this.paeVersionCommercial = this.localStorageService.get('paeVersionCommercial');
    //     }
    //     return this.paeVersionCommercial;
    // }

    tryGetFWDataVersion() {
        if (this.isLocalStorageEnabled) {
            this.fwDataVersion = this.localStorageService.get('fwDataVersion');
        }
        return this.fwDataVersion;
    }

    setFWDataVersion(data) {
        this.fwDataVersion = data;
        if (this.isLocalStorageEnabled) {
            this.localStorageService.set('fwDataVersion', data);
        }
    }

    setCurrentTab(data) {
        this.currentTab = data;
        if (this.isLocalStorageEnabled) {
            this.localStorageService.set('currentTab', data);
        }
    }

    tryGetCurrentTab() {
        if (this.isLocalStorageEnabled) {
            this.currentTab = this.localStorageService.get('currentTab');
        }
        return this.currentTab;
    }

    clearCurrentTab() {
        if (this.isLocalStorageEnabled) {
            this.localStorageService.set('currentTab', null);
        } else {
            this.currentTab = null;
        }
    }

    setJobTreeData(data) {
        this.jobTreeData = data;
        if (this.isLocalStorageEnabled) {
            this.localStorageService.set('jobTreeData', data);
        }
    }

    tryGetJobTreeData() {
        if (this.isLocalStorageEnabled) {
            this.jobTreeData = this.localStorageService.get('jobTreeData');
        }
        return this.jobTreeData;
    }

    clearJobTreeData() {
        if (this.isLocalStorageEnabled) {
            this.localStorageService.set('jobTreeData', null);
        } else {
            this.jobTreeData = null;
        }
    }

    setSelectedJobTreeNode(data) {
        this.selectedJobTreeNode = data;
        if (this.isLocalStorageEnabled) {
            this.localStorageService.set('selectedJobTreeNode', data);
        }
    }

    tryGetSelectedJobTreeNode() {
        if (this.isLocalStorageEnabled) {
            this.selectedJobTreeNode = this.localStorageService.get('selectedJobTreeNode');
        }

        return this.selectedJobTreeNode;
    }

    clearSelectedJobTreeNode() {
        if (this.isLocalStorageEnabled) {
            this.localStorageService.set('selectedJobTreeNode', null);
        } else {
            this.selectedJobTreeNode = null;
        }
    }

    setTeamOwnerId(teamId) {
        if (this.isLocalStorageEnabled) {
            this.localStorageService.set('teamOwnerId', teamId);
        } else {
            this.teamOwnerId = teamId;
        }
    }

    getIsMemberVertical() {
        var prData = this.getPostResponseData();
        var memberIDTag = prData.MemberData.MemberIDTag;
        var kvp = prData.ApplicationData.MemberManagerData.MemberTagCollection[memberIDTag];
        var memberType = prData.ApplicationData.MemberManagerData.MemberContainers[kvp.Key].Members[kvp.Value].MemberType;

        if (memberType === 30 || memberType === 8 || memberType === 40) {
            return true;
        }
        return false;
    }

    getIsMemberFreeStandingPost() {
        var prData = this.getPostResponseData();
        var memberIDTag = prData.MemberData.MemberIDTag;
        var kvp = prData.ApplicationData.MemberManagerData.MemberTagCollection[memberIDTag];
        var memberType = prData.ApplicationData.MemberManagerData.MemberContainers[kvp.Key].Members[kvp.Value].MemberType;

        if (memberType === 40) {
            return true;
        }
        return false;
    }

    getOrientationsVisible() {
        var prData = this.getPostResponseData();
        var memberIDTag = prData.MemberData.MemberIDTag;
        var kvp = prData.ApplicationData.MemberManagerData.MemberTagCollection[memberIDTag];
        var memberType = prData.ApplicationData.MemberManagerData.MemberContainers[kvp.Key].Members[kvp.Value].MemberType;
        if (memberType === 8 || memberType === 31 || memberType === 32) {
            return true;
        } else {
            return false;
        }
    }

    getIsMemberWindSill() {
        var prData = this.getPostResponseData();
        var memberIDTag = prData.MemberData.MemberIDTag;
        var kvp = prData.ApplicationData.MemberManagerData.MemberTagCollection[memberIDTag];
        var memberType = prData.ApplicationData.MemberManagerData.MemberContainers[kvp.Key].Members[kvp.Value].MemberType;

        if (memberType === 32) {
            return true;
        }
        return false;
    }

    getIsMemberHeaderOrWindSill() {
        var prData = this.getPostResponseData();
        var memberIDTag = prData.MemberData.MemberIDTag;
        var kvp = prData.ApplicationData.MemberManagerData.MemberTagCollection[memberIDTag];
        var memberType = prData.ApplicationData.MemberManagerData.MemberContainers[kvp.Key].Members[kvp.Value].MemberType;

        if (memberType === 31 || memberType === 32) {
            return true;
        }
        return false;
    }

    isUOMMetric() {
        var prData = this.getPostResponseData();
        return (prData.ApplicationData.ProgramSettingsData.UOM === 3);
    }

    getTeamOwnerId() {
        if (this.isLocalStorageEnabled) {
            return this.localStorageService.get('teamOwnerId');
        } else {
            return this.teamOwnerId;
        }
    }

    getUserName() {
        return this.getAuthorizationData().userName;
    }

    setAuthorizationData(data) {
        if (this.isLocalStorageEnabled) {
            this.localStorageService.set('authorizationData', data);
        } else {
            this.authorizationData = data;
        }
    }

    getAuthorizationData() {
        if (this.isLocalStorageEnabled) {
            return this.localStorageService.get('authorizationData');
        } else {
            return this.authorizationData;
        }
    }

    setSpecTestingOn(testAllModelData) {
        this.allModelDataTestStub = testAllModelData;
        this.isSpecTesting = true;
    }

    getCurrentFileId() {
        var prData = this.getPostResponseData();
        if (prData) {
            if (prData.ApplicationData) {
                if (prData.ApplicationData.ProjectManagerData) {
                    return prData.ApplicationData.ProjectManagerData.ProjectFileTreeID;
                }
            }
        }
        return null;
    }

    getPostResponseData() {
        if (this.isLocalStorageEnabled) {
            return this.localStorageService.get('postResponseData');
        } else {
            return this.postResponseData;
        }
    }

    saveIBCBuildingCode(buildingCodeValue) {
        const prData = this.localStorageService.get('postResponseData');
        prData.ApplicationData.JobSettingsData.BuildingCodeForteWEB = buildingCodeValue;
        switch (buildingCodeValue) {
            case 30: //IBC2015
            case 33: //IBC2018
            case 36: //IBC2021
                prData.ApplicationData.JobSettingsData.BuildingCode = 20; //BuildingCodeTypes.IBC2012
                break;
            default:
                prData.ApplicationData.JobSettingsData.BuildingCode = buildingCodeValue;
                break;
        }
        prData.ApplicationData.JobSettingsData.ProvinceSelected = 'nbccnone';
        prData.ApplicationData.JobSettingsData.ProvincialCode = 'nbcc2015';
        this.localStorageService.set('postResponseData', prData);
    }

    getProductBlacklist() {
        if (this.isLocalStorageEnabled) {
            return this.localStorageService.get('productBlacklist');
        } else {
            return this.productBlacklist;
        }
    }

    setProductBlacklist(prodBlacklist) {
        if (this.isLocalStorageEnabled) {
            this.localStorageService.set('productBlacklist', prodBlacklist);
        } else {
            this.productBlacklist = prodBlacklist;
        }
    }
    setGraphicOverlayLabels(labels) {
        if (this.isLocalStorageEnabled) {
            this.localStorageService.set('graphicOverlayLabels', labels);
        } else {
            this.graphicOverlayLabels = labels;
        }
    }
    getGraphicOverlayLabels() {
        if (this.isLocalStorageEnabled) {
            return this.localStorageService.get('graphicOverlayLabels');
        } else {
            return this.graphicOverlayLabels;
        }
    }

    setGraphicDataURL(graphicURL) {
        if (this.isLocalStorageEnabled) {
            this.localStorageService.set('graphicDataURL', graphicURL);
        } else {
            this.graphicDataURL = graphicURL;
        }
    }

    getGraphicDataURL() {
        if (this.isLocalStorageEnabled) {
            return this.localStorageService.get('graphicDataURL');
        } else {
            return this.graphicDataURL;
        }
    }

    getSolutionsList() {
        if (this.isLocalStorageEnabled) {
            return this.localStorageService.get('solutionsList');
        } else {
            return this.solutionsList;
        }
    }

    setSolutionsGridData(gridData) {
        if (this.isLocalStorageEnabled) {
            this.localStorageService.set('solutionsGridData', gridData);
        } else {
            this.solutionsGridData = gridData;
        }
    }

    getSolutionsGridData() {
        if (this.isLocalStorageEnabled) {
            return this.localStorageService.get('solutionsGridData');
        } else {
            return this.solutionsGridData;
        }
    }

    // getSortedList() {
    //     if (isLocalStorageEnabled) {
    //         return localStorageService.get('sortedSolutionsList');
    //     } else {
    //         return sortedSolutionsList;
    //     }
    // }

    // getAllProductsList() {
    //     if (isLocalStorageEnabled) {
    //         return localStorageService.get('allProductsList');
    //     } else {
    //         return allProductsList;
    //     }
    // }

    clearPostResponseData() {
        this.setFileIdToOpen(null);
        if (this.isLocalStorageEnabled) {
            this.localStorageService.set('postResponseData', null);
        } else {
            this.postResponseData = null;
        }
    }

    getJobTreeViewModel(projectName) {
        var prData = this.getPostResponseData();
        var currentContainerId = prData.ApplicationData.MemberManagerData.SelectedContainerID;
        var currentMemberId = prData.ApplicationData.MemberManagerData.SelectedMemberID;
        var memberContainersLength = prData.ApplicationData.MemberManagerData.MemberContainers.length;
        var root = {};
        root.text = projectName;
        root.id = 'root';
        root.icon = '../../../../images/JobNode.png';
        root.state = {
            opened: true
        };
        root.isleafNode = false;
        root.children = [];
        for (var i = 0; i < memberContainersLength; i++) {
            var folder = {};
            folder.id = 'c_' + prData.ApplicationData.MemberManagerData.MemberContainers[i].ContainerID;
            folder.text = prData.ApplicationData.MemberManagerData.MemberContainers[i].LevelSettingsData.ContainerName;
            folder.icon = '../../../../images/Level.png';
            folder.state = {
                opened: true
            };
            folder.children = [];
            var membersLength = prData.ApplicationData.MemberManagerData.MemberContainers[i].Members.length;
            for (var j = 0; j < membersLength; j++) {
                var problem = [];
                var memberSDO = prData.ApplicationData.MemberManagerData.MemberContainers[i].Members[j];
                problem.icon = this.getProblemIconName(memberSDO.MemberType, memberSDO.StructuralSystem);
                problem.id = 'c_' + prData.ApplicationData.MemberManagerData.MemberContainers[i].ContainerID + 'm_' + prData.ApplicationData.MemberManagerData.MemberContainers[i].Members[j].MemberID;
                problem.text = memberSDO.MemberName;
                problem.state = {
                    opened: true
                };
                problem.isLeafNode = true;
                problem.idTag = memberSDO.IDTag;
                problem.hasValidPaeSolution = memberSDO.HasValidPaeSolution;
                problem.isDesignable = memberSDO.IsDesignable;
                problem.isDesigned = memberSDO.ProblemIsDesigned;
                problem.waitingOnDesignOfLinkedMember = memberSDO.WaitingOnDesignOfLinkedMember;
                folder.children.push(problem);
            }
            root.children.push(folder);
        }
        return root;
    }

    getProblemIconName(memberType, StructuralSystem) {
        if (memberType === 1) {
            if (StructuralSystem === 2) {
                return '../../images/RoofJoist.png';
            }
            else {
                return '../../images/FloorJoist.png';
            }
        }
        else {
            return '../../images/Beam.png';
        }
    }

    setPostResponseData(responseData) {
        if (this.isLocalStorageEnabled) {
            this.localStorageService.set('postResponseData', responseData);
        } else {
            this.postResponseData = responseData;
        }
        if (responseData.ApplicationData)
        {
            if (responseData.ApplicationData.MemberManagerData) {
                this.$rootScope.$broadcast('redrawMemberStatusColors');
            }
        }
        if (responseData.MemberData) {
            if (responseData.MemberData.GraphicOutputData) {
                if (responseData.ApplicationData.SelectedMemberIDTag !== -1) {
                    //refresh the graphic if the data is in prData.
                    if (this.getIsMemberVertical() === true) {
                        this.$rootScope.$broadcast('refreshVerticalGraphic', responseData.MemberData.GraphicOutputData);
                    } else {
                        this.$rootScope.$broadcast('refreshGraphic_main', responseData.MemberData.GraphicOutputData);
                    }
                }
            }
            if (responseData.MemberData.SolutionsData) {
                if (responseData.MemberData.SolutionsData.SolutionList.length > 0) {
                    // Save SolutionsList locally and remove from PostResponseData (not Saved in File)
                    this.localStorageService.set('solutionsList', responseData.MemberData.SolutionsData.SolutionList);
                    responseData.MemberData.SolutionsData.SolutionList = [];

                    if (this.isLocalStorageEnabled) {
                        this.localStorageService.set('postResponseData', responseData);
                    } else {
                        this.postResponseData = responseData;
                    }
                }
            }
            if (responseData.MemberData.RoofGeometryData) {
                this.$rootScope.$broadcast('updateHipValleyCompleted', this.isHipValleyCompleted());
            }
            this.$rootScope.$broadcast('updateIsDesignable', this.isCurrentMemberDesignable(), this.isCurrentMemberWaitingOnDesignOfLinkedMember());
        }
        if (this.isCurrentMemberDesigned(responseData) === false) {
            this.setSolutionsGridData(null);
        }
        return this.postResponseData;
    }

    isHipValleyCompleted() {
        var prData = this.getPostResponseData();
        var isCompleted = false;
        var roofGeometryData = prData.MemberData.RoofGeometryData;
        if (roofGeometryData) {
            isCompleted = roofGeometryData.RoofGeometryCalculationsComplete;
        }
        return isCompleted;
    }

    isCurrentMemberDesignable() {
        var prData = this.getPostResponseData();
        var isDesignable = false;
        var memberManagementData = prData.ApplicationData.MemberManagerData;
        if (memberManagementData.SelectedMemberID > -1) {
            var selectedContainersId = memberManagementData.SelectedContainerID;
            var selectedLevel = memberManagementData.MemberContainers[selectedContainersId];
            var selectedMemberId = memberManagementData.SelectedMemberID;
            var selectedMember = selectedLevel.Members[selectedMemberId];
            isDesignable = selectedMember.IsDesignable;
        }
        return isDesignable;
    }

    isCurrentMemberDesigned(prData) {
        var isProblemDesigned = false;
        var memberManagementData = prData.ApplicationData.MemberManagerData;
        if (memberManagementData.SelectedMemberID > -1) {
            var selectedContainersId = memberManagementData.SelectedContainerID;
            var selectedLevel = memberManagementData.MemberContainers[selectedContainersId];
            var selectedMemberId = memberManagementData.SelectedMemberID;
            var selectedMember = selectedLevel.Members[selectedMemberId];
            isProblemDesigned = selectedMember.ProblemIsDesigned;
        }
        return isProblemDesigned;
    }

    isCurrentMemberWaitingOnDesignOfLinkedMember() {
        var prData = this.getPostResponseData();
        var isWaiting = false;
        var memberManagementData = prData.ApplicationData.MemberManagerData;
        if (memberManagementData.SelectedMemberID > -1) {
            var selectedContainersId = memberManagementData.SelectedContainerID;
            var selectedLevel = memberManagementData.MemberContainers[selectedContainersId];
            var selectedMemberId = memberManagementData.SelectedMemberID;
            var selectedMember = selectedLevel.Members[selectedMemberId];
            isWaiting = selectedMember.WaitingOnDesignOfLinkedMember;
        }
        return isWaiting;
    }

    testLocalStorageEnabled() {
        var testVar = 'ForteWeb';
        try {
            localStorage.setItem(testVar, testVar);
            localStorage.removeItem(testVar);
            this.isLocalStorageEnabled = true;
        } catch (e) {
            this.isLocalStorageEnabled = false;
        }
    }

    getEchoLogOn() {
        if (this.isLocalStorageEnabled) {
            this.echoLogOn = this.localStorageService.get('EchoLogOn');
        }
        return this.echoLogOn;
    }

    setEchoLogOff() {
        this.echoLogOn = false;
        if (this.isLocalStorageEnabled) {
            this.localStorageService.set('EchoLogOn', this.echoLogOn);
        }
    }

    // Quick Loads Processing

    setQuickLoadsModelData(quickLoadsData) {
        if (this.isLocalStorageEnabled) {
            this.localStorageService.set('quickLoadsModelData', quickLoadsData);
        } else {
            this.quickLoadsModelData = quickLoadsData;
        }
    }

    getQuickLoadsModelData() {
        if (this.isLocalStorageEnabled) {
            return this.localStorageService.get('quickLoadsModelData');
        } else {
            return this.quickLoadsModelData;
        }
    }

    clearQuickLoadsModelData() {
        if (this.isLocalStorageEnabled) {
            this.localStorageService.set('quickLoadsModelData', null);
        } else {
            this.quickLoadsModelData = null;
        }
    }

    isBuildingCodeIBC() {
        var prData = this.getPostResponseData();
        switch (prData.ApplicationData.JobSettingsData.BuildingCode) {
            case 5:     //IBC
            case 20:    //IBC2012
            case 30:    //IBC2015
            case 33:    //IBC2018
            case 36:    //IBC2021
            case 25:    //IBC2024
                return true;
                break;
            default:
                return false;
                break;
        }
    }

    isBuildingCodeIBC2021IBC2018IBC2024() {
        var prData = this.getPostResponseData();
        switch (prData.ApplicationData.JobSettingsData.BuildingCodeForteWEB) {
            case 36:    //IBC2021
            case 33:    //IBC2018
            case 25:    //IBC2024
                return true;
                break;
            default:
                return false;
                break;
        }
    }

    isForteWebBuildingCodeIBC() {
        var prData = this.getPostResponseData();
        switch (prData.ApplicationData.JobSettingsData.BuildingCodeForteWEB) {
            case 18:    //NBCC2010
            case 23:    //NBCC2015
            case 24:    //NBCC2020
                return false;
                break;
            default:
                return true;
                break;
        }
    }

    setBuildingCodeListTypeOnLoad() {
        // BuildingCodeListType: IBC=0, NBCC=1
        if (this.isForteWebBuildingCodeIBC()) {
            this.localStorageService.set('buildingCodeListType', 0);
        } else {
            this.localStorageService.set('buildingCodeListType', 1);
        }
    }

    setBuildingCodeListType(listType) {
        // BuildingCodeListType: IBC=0, NBCC=1
        this.localStorageService.set('buildingCodeListType', listType);
    }
    getBuildingCodeListType() {
        return this.localStorageService.get('buildingCodeListType');
    }

    isResidential() {
        var prData = this.getPostResponseData();
        var memberManagementData = prData.ApplicationData.MemberManagerData;
        var selectedContainersId = memberManagementData.SelectedContainerID;
        var selectedLevel = memberManagementData.MemberContainers[selectedContainersId];
        if (selectedLevel.LevelSettingsData.BuildingClass === 11) {
            return true;
        } else {
            return false;
        }
    }

    getCurrentMemberQuickLoadInfo() {
        var prData = this.getPostResponseData();
        var memberManagementData = prData.ApplicationData.MemberManagerData;
        var selectedContainersId = memberManagementData.SelectedContainerID;
        var selectedMemberId = memberManagementData.SelectedMemberID;
        var selectedLevel = memberManagementData.MemberContainers[selectedContainersId];
        var selectedMember = selectedLevel.Members[selectedMemberId];

        var memberQuickLoadInfo = {
            'IsIBC': this.isBuildingCodeIBC(),
            'IsResidential': this.isResidential(),
            'StructuralSystem': selectedMember.StructuralSystem,
            'MemberType': selectedMember.MemberType
        };

        return memberQuickLoadInfo;
    }

    getLoadDefDataByViewPosition(viewPosition) {
        var prData = this.getPostResponseData();
        var loadDefData = null;
        for (var i = 0; i < prData.MemberData.HorizontalYAxisLoadData.LoadGroups[0].LoadDefs.length; i++) {
            if (prData.MemberData.HorizontalYAxisLoadData.LoadGroups[0].LoadDefs[i].ViewPositionValue === viewPosition) {
                loadDefData = prData.MemberData.HorizontalYAxisLoadData.LoadGroups[0].LoadDefs[i];
                break;
            }
        }
        return loadDefData;
    }

    checkDataVersionAndClearPRDataIfVersionIsOld() {
        var usersDataVersion = this.tryGetFWDataVersion();
        if (usersDataVersion !== this.currentFWDataVersion)
        {
            this.setFWDataVersion(this.currentFWDataVersion);
            this.setAuthorizationData(null);
            this.clearPostResponseData(null);
            this.clearSelectedJobTreeNode();
            this.clearJobTreeData();
            this.clearCurrentTab();
            this.clearQuickLoadsModelData();
        }
    }
}
