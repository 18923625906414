import ngAuthSettings from '../environmentConfigs/environment.dev';

export default class projectManagementService {
    constructor($http, $q, allModelDataService, signalrService) {
        'ngInject';
        this.$http = $http;
        this.$q = $q;
        this.allModelDataService = allModelDataService;
        this.signalRService = signalrService;

        this.serviceBase = ngAuthSettings.apiServiceBaseUri;
        this.returnResponseData = null;
    }

    getProject(id) {
        var deferred = this.$q.defer();
        this._getCurrentProject(id).then(
            (response) => {
                this.returnResponseData = response.data.postResponseData;
                deferred.resolve(response.data);
            },
            (err) => {
                deferred.reject(err);
            }
        );

        return deferred.promise;
    }

    getUserInfo() {
        var deferred = this.$q.defer();
        this.$http.get(this.serviceBase +
            'api/Project/GetUserInfo', {
                headers: {'Content-Type': 'application/json' }
            })
            .then((response) => {
                deferred.resolve(response.data);
            }, (err, status) => {
                //_logOut();
                deferred.reject(err);
            });
        return deferred.promise;
    }

    getCurrentFileTreeID() {
        var deferred = this.$q.defer();
        this.$http.get(this.serviceBase + 'api/Project/GetCurrentFileTreeID')
            .then((response) => {
                deferred.resolve(response.data);
            }, (err, status) => {
                deferred.reject(err);
            });

        return deferred.promise;
    }

    clearProject() {
        this.returnResponseData = null;
    }


    _getCurrentProject(id) {
        var deferred = this.$q.defer();
        this.returnResponseData = null;

        let data = {
            'postResponseData': this.allModelDataService.getPostResponseData(),
            'value': id,
            sessionId: this.signalRService.getConnectionId()
        };
        var prData = this.allModelDataService.getPostResponseData();
        var fileIsAlreadyOpen = false;
        if (prData) {
            if (prData.ApplicationData) {
                if (prData.ApplicationData.ProjectManagerData) {
                    if (prData.ApplicationData.ProjectManagerData.ProjectFileTreeID === data.value) {
                        //this is probably a page reload, so dont try to open it again because the file is locked.
                        fileIsAlreadyOpen = true;
                    }
                }
            }
        }
        if (fileIsAlreadyOpen === false) {
            this.$http.post(this.serviceBase +
                'api/Project/OpenJob', data, {
                    headers: {'Content-Type': 'application/json' }
                })
                .then((response) => {
                    deferred.resolve(response);
                }, (err, status) => {
                    //_logOut();
                    deferred.reject(err);
                });
        } else {
            var mimicResponse = {
                'data': {
                    'postResponseData': prData
                }
            };
            deferred.resolve(mimicResponse);
        }

        return deferred.promise;

    }
}
