export default class resendEmailController {
    constructor ($state, $stateParams, resourceMessagingService, authService) {
        'ngInject';
        this.emailAddress;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.resourceMessagingService = resourceMessagingService;
        this.authService = authService;

        this.activate();
    }
    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    activate() {
        if (this.$stateParams.email !== null) {
            this.emailAddress = this.$stateParams.email;
        }
        if (this.$stateParams.message !== null) {
            this.notification = this.$stateParams.message;
        } else {
            this.notification = '';
        }
    }

    sendEmail(isValid) {
        this.submitted = true;
        if (isValid) {
            this.disableSubmitAction = true;
            this.authService.sendConfirmationEmail(this.emailAddress)
            .then((response) => {
                //console.log('confirmation email sent')
                this.$state.go('account.emailSent');
            })
            .catch((message) => {
                this.message = message;
            })
            .finally(() => {
                this.emailSent = true;
            });
        }
    }
}
// (function () {
//     'use strict';
//     var app = angular.module('app');

//     app.controller('resendEmailController', ['$state','$stateParams', 'resourceMessagingService','authService',
//         function ($state, $stateParams, resourceMessagingService, authService) {
//             /* jshint validthis: true */
//             var vm = this;

//             vm.emailAddress;

//             vm.getText = getText;
//             vm.sendEmail = sendEmail;

//             function getText(key) {
//                 return resourceMessagingService.getText(key);
//             }

//             function activate() {
//                 if ($stateParams.email !== null) {
//                     vm.emailAddress = $stateParams.email;
//                 }
//                 if ($stateParams.message !== null) {
//                     vm.notification = $stateParams.message;
//                 } else {
//                     vm.notification = '';
//                 }
//             }

//             function sendEmail(isValid) {
//                 vm.submitted = true;
//                 if (isValid) {
//                     vm.disableSubmitAction = true;
//                     authService.sendConfirmationEmail(vm.emailAddress)
//                         .then(function (response) {
//                             //console.log('confirmation email sent')
//                             $state.go('account.emailSent');
//                         })
//                         .catch(function(message) {
//                             vm.message = message;
//                         })
//                         .finally(function() {
//                             vm.emailSent = true;
//                         });
//                 }
//             };
//             activate();
//         }]);
// }());
