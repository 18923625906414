export default class strictEmailValidator {
    constructor() {
        this.require = '?ngModel';
    }
    controller($scope) {
        'ngInject';
    }
    link (scope, elm, attrs, ctrl) {
        var EMAIL_REGEXP = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        // only apply the validator if ngModel is present and Angular has added the email validator
        if (ctrl && ctrl.$validators.email) {

            // this will overwrite the default Angular email validator
            ctrl.$validators.email = function(modelValue) {
                return ctrl.$isEmpty(modelValue) || EMAIL_REGEXP.test(modelValue);
            };
        }
    }
}
// (function() {
//     'use strict';
//     angular.module('app').directive('strictEmailValidator', function() {
//         var EMAIL_REGEXP = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

//         return {
//             require: '?ngModel',
//             link: function(scope, elm, attrs, ctrl) {
//                 // only apply the validator if ngModel is present and Angular has added the email validator
//                 if (ctrl && ctrl.$validators.email) {

//                     // this will overwrite the default Angular email validator
//                     ctrl.$validators.email = function(modelValue) {
//                         return ctrl.$isEmpty(modelValue) || EMAIL_REGEXP.test(modelValue);
//                     };
//                 }
//             }
//         };
//     });
// }());
