export default class holesModifyCommentController {
    constructor ($rootScope, $scope, $uibModalInstance, items, resourceMessagingService) {
        'ngInject';

        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        this.items = items;
        this.resourceMessagingService = resourceMessagingService;

        this.activate();
    }
    activate() {
        this.comment = this.items.comment;
    }

    save() {
        this.$uibModalInstance.close(this.comment);
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    cancel() {
        this.$uibModalInstance.dismiss('cancel');
    }

    selectAll($event) {
        $event.target.select();
    }
}
// (function () {
//     'use strict';
//     var app = angular.module('app');
//     app.controller('holesModifyCommentController',
//         ['$rootScope', '$scope', '$uibModalInstance', 'items', 'resourceMessagingService',
//             function ($rootScope, $scope, $uibModalInstance, items, resourceMessagingService) {
//                 var vm = $scope;

//                 vm.save = save;
//                 vm.cancel = cancel;
//                 vm.selectAll = selectAll;
//                 vm.getText = getText;

//                 activate();

//                 function activate() {
//                     vm.comment = items.comment;
//                 }

//                 function save() {
//                     $uibModalInstance.close(vm.comment);
//                 }

//                 function getText(key) {
//                     return resourceMessagingService.getText(key);
//                 }

//                 function cancel() {
//                     $uibModalInstance.dismiss('cancel');
//                 }

//                 function selectAll($event) {
//                     $event.target.select();
//                 }
//             }]);
// }());
