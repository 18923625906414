export default class authInterceptorService {
    constructor($q, $injector, $location, allModelDataService, common, notifier, resourceMessagingService) {
        'ngInject';

        this.$q = $q;
        this.$injector = $injector;
        this.$location = $location;
        this.allModelDataService = allModelDataService;
        this.common = common;
        this.notifier = notifier;
        this.resourceMessagingService = resourceMessagingService;

        this.request = (config) => {
            if (config.url.lastIndexOf('api') > -1 && config.method === 'POST') {
                var sessionTimestamp = this.allModelDataService.getSessionTimeStamp();
                this.allModelDataService.setSessionTimeStamp();
                //if these requests didnt come from the login page...
                var currentTime = this.allModelDataService.getUTCTimeNow();
                var timeDifference = currentTime.diff(sessionTimestamp, 'seconds'); //in milliseconds
                //5min10sec = 310 seconds
                if (sessionTimestamp.isValid() === false || timeDifference > 310 || sessionTimestamp === undefined || sessionTimestamp === null) {
                    if (this.$location.$$path !== '/login' &&
                        this.$location.$$path !== '/register' &&
                        this.$location.$$path !== '/forgotPassword' &&
                        this.$location.$$path !== '/emailSent' &&
                        this.$location.$$path !== '/resendEmail' &&
                        this.$location.$$path !== '/resetpassword' &&
                        this.$location.$$path !== '/emailconfim') {
                        this.$location.path('/login');
                        this.$location.url(this.$location.path());
                    }
                }
            }
            config.headers = config.headers || {};

            var authData = this.allModelDataService.getAuthorizationData();
            if (authData) {
                config.headers.Authorization = 'Bearer ' + authData.token;
            }

            return config;
        };

        this.requestError = (request) => {
            return this.$q.reject(request);
        };

        this.response = (response) => {
            if (response.data) {
                // Detect 'postResponseData' in api call response
                if (response.data.IsPostResponseDataObject === 1) {
                    this.allModelDataService.setPostResponseData(response.data);
                } else if (response.data.postResponseData !== undefined && response.data.postResponseData !== null) {
                    this.allModelDataService.setPostResponseData(response.data.postResponseData);
                }
                //detect updated MaterialAvailibility data and update it.
                if (response.data.materialsAvailabilityData !== undefined && response.data.materialsAvailabilityData !== null) {
                    let newMaData = {
                        'currentPostalCode': response.data.materialsAvailabilityData.PostalCode,
                        'seriesSpecialOrder': response.data.materialsAvailabilityData.SeriesSpecialOrder,
                        'seriesUnavailable': response.data.materialsAvailabilityData.SeriesNotAvailable,
                        'productsSpecialOrder': response.data.materialsAvailabilityData.ProductsSpecialOrder,
                        'productsNotAvailable': response.data.materialsAvailabilityData.ProductsNotAvailable
                    };
                    this.allModelDataService.setMaterialAvailabilityData(newMaData);
                }
            }
            return response || this.$q.when(response);
        };

        this.responseError = (rejection) => {
            if (rejection.data.ExceptionType) {
                if (rejection.data.ExceptionType.indexOf('AppDataMemberCountException') > -1) {
                    this.notifier.showGenericError(this.resourceMessagingService.getText('error'), this.resourceMessagingService.getText('saveExeptionMemberCountError'));
                    this.$location.path('/login');
                    this.$location.url($location.path());
                }

                if (rejection.data.ExceptionType.indexOf('ForteFileIOException') > -1) {
                    this.notifier.showGenericError('Forte File IO', rejection.data.ExceptionMessage);
                    this.$location.path('/login');
                    this.$location.url($location.path());
                }
            }
            if (rejection.status === 401 || rejection.status === 500 || rejection.status === 409) {
                //authService.logOut(); this causes a circular reference.
                this.$location.path('/login');
                this.$location.url(this.$location.path());
            }
            if (rejection.status === 403 && rejection.data.ExceptionMessage === 'noLoadsToTransfer') {
                this.notifier.showGenericError(this.resourceMessagingService.getText('error'), this.resourceMessagingService.getText('noLoadsToTransfer'));
                this.$location.path('/login');
                this.$location.url($location.path());
            }
            if (rejection.status === -1) {
                //this is here to forcibly reload the page when the site is down for maintenance.
                //clears the cache and forces the browser to get the underConstruction.html page.
                window.location.reload(true);
            }
            return this.$q.reject(rejection);
        };
    }
}
