export default class settingsModalController {
    constructor(
        $scope,
        $uibModalInstance,
        common,
        $timeout,
        programSettingsService,
        resourceMessagingService,
        quickLoadsService,
        allModelDataService,
        dirtyCheckService,
        forteCookieService
    ) {
        'ngInject';

        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        this.common = common;
        this.$timeout = $timeout;
        this.forteCookieService = forteCookieService;
        this.programSettingsService = programSettingsService;
        this.resourceMessagingService = resourceMessagingService;
        this.quickLoadsService = quickLoadsService;
        this.allModelDataService = allModelDataService;
        this.dirtyCheckService = dirtyCheckService;

        var vm = $scope;
        var $q = common.$q();

        // this.settingsSaveClicked = settingsSaveClicked;
        // this.settingsCancelClicked = settingsCancelClicked;
        // this.getProgramSettingsProcessData = getProgramSettingsProcessData;
        // this.getText = getText;
        // this.helpClicked = helpClicked;
        // this.resetDefaultsClicked = resetDefaultsClicked;
        // this.measureChanged = measureChanged;
        // this.select = select;

        // this.setSelectedLoad = setSelectedLoad;
        // this.updateMagnitudeGrid = updateMagnitudeGrid;
        // this.addNewQuickLoad = addNewQuickLoad;
        // this.deleteQuickLoad = deleteQuickLoad;
        // this.quickLoadTypeChanged = quickLoadTypeChanged;
        // this.selectAllTextOnFocus = selectAllTextOnFocus;
        // this.moveQuickLoadUp = moveQuickLoadUp;
        // this.moveQuickLoadDown = moveQuickLoadDown;
        // this.updateCommentIfEmpty = updateCommentIfEmpty;
        this.selectedLoadId = 0;
        this.selectedLoadIndex = 0;
        this.resetDefaultsMessage = 'jobSettingsResetMessage';
        this.resetFinished = false;
        this.selectedType = '';
        this.ldfData = [];
        this.selectedLaod = {};
        this.quickLoadsList = null;
        this.productSelected = 2;
        this.loadTypeMetaData = null;
        this.loadAdded = false;
        this.loadMoved = false;
        this.constructionDetailImageSettingMemberReport = 0;
        this.constructionDetailImageSettingJobSummary = 0;
        this.memberReportImageSetting = -1;
        this.jobSummaryImageSetting = -1;
        this.constructionDetailsImageSettingMemberReportList = null;
        this.constructionDetailsImageSettingJobSummaryReportList = null;
        // Start in File Manager CheckBox removed v3.4 - always default to true
        //this.openToFileManagerOnLogin = true;
        this.reformatedLoadGridData = [
            { LoadClass: 5, StartMagnitude: 0, StartMagnitudeFriendly: '0.0' },
            { LoadClass: 4, StartMagnitude: 0, StartMagnitudeFriendly: '0.0' },
            { LoadClass: 3, StartMagnitude: 0, StartMagnitudeFriendly: '0.0' },
            { LoadClass: 2, StartMagnitude: 0, StartMagnitudeFriendly: '0.0' },
            { LoadClass: 1, StartMagnitude: 0, StartMagnitudeFriendly: '0.0' },
            { LoadClass: 0, StartMagnitude: 0, StartMagnitudeFriendly: '0.0' },
        ];

        $('body').on('keydown', 'input, select, textarea', function (e) {
            var self = $(this),
                form = self.parents('form:eq(0)'),
                focusable,
                next;
            if (e.keyCode === 13) {
                focusable = $('body')
                    .find('input,a,select,button,textarea')
                    .filter(':visible');
                next = focusable.eq(focusable.index(this) + 1);
                if (next.length) {
                    next.focus();
                } else {
                    form.submit();
                }
                return false;
            }
        });

        var $accordionSettings = $('#accordionSettings');
        $accordionSettings.on('show.bs.collapse', '.collapse', function () {
            $accordionSettings.find('.collapse.in').collapse('hide');
        });

        this.activate();
    }

    activate() {
        // Start in File Manager CheckBox removed v3.4
        // this.openToFileManagerOnLogin =
        //     this.forteCookieService.getOpenToFileManagerOnLogin();
        this.materialAvailibilityEnabled =
            this.forteCookieService.getMaterialAvailabilityEnabled();
        this.suggestedSolutionsEnabled =
            this.forteCookieService.getSuggestedSolutionsEnabled();
        this.factoredTitleEnabled =
            this.forteCookieService.getFactoredTitleEnabled();
        this.utilizationPercentageEnabled =
            this.forteCookieService.getUtilizationPercentageEnabled();
        this.getProgramSettingsProcessData();
        this.getConstructionDetailsImageSettingMemberReportList();
        this.getConstructionDetailsImageSettingJobSummaryReportList();
        this.constructionDetailImageSettingMemberReport = Number(
            this.forteCookieService.getConstructionDetailImageSettingMemberReport()
        );
        this.memberReportImageSetting = this.constructionDetailImageSettingMemberReport;
        this.constructionDetailImageSettingJobSummary = Number(
            this.forteCookieService.getConstructionDetailImageSettingJobSummary()
        );
        this.jobSummaryImageSetting = this.constructionDetailImageSettingJobSummary;
        this.resetFinished = false;
    }

    getConstructionDetailsImageSettingMemberReportList() {
        this.constructionDetailsImageSettingMemberReportList = [
          { Key: 0, Value: 'none' },
          { Key: 2, Value: 'coverPage' },
          { Key: 3, Value: 'afterReport' }
        ];
    }

    getConstructionDetailsImageSettingJobSummaryReportList() {
        this.constructionDetailsImageSettingJobSummaryReportList = [
          { Key: 0, Value: 'none' },
          { Key: 1, Value: 'coverPage' },
          { Key: 3, Value: 'individualReport' }
        ];
    }

    getProgramSettingsProcessData() {
        this.programSettingsService
            .getProgramSettingsProcessData()
            .then((response) => {
                this.metaData = response;
                this.selectedTabIndex = 0;
                this.setLoadTypeSelections();
                this.setQuickLoadsList();
            });
    }

    measureChanged() {
        this.setLoadTypeSelections();
        this._GetMagnitudeFriendly(this.selectedLoadIndex);
        if (this.metaData.ProgramSettingsClientData.UOM === 3) {
            this.metaData.ProgramSettingsClientData.MaterialListRounding = 0; // 0=none, 1=nextFoot, 2=next2Feet
        }
    }

    setLoadTypeSelections() {
        if (this.metaData.ProgramSettingsClientData.UOM === 3) {
            this.loadTypeMetaData = [
                { Key: 2, Value: 'uniformkNm' },
                { Key: 3, Value: 'uniformkPa' },
            ];
        } else {
            this.loadTypeMetaData = [
                { Key: 2, Value: 'uniformPLF' },
                { Key: 3, Value: 'uniformPSF' },
            ];
        }
    }

    setQuickLoadsList() {
        this.quickLoadsService.setQuickLoadsModelData().then((response) => {
            this.quickLoadList =
                this.quickLoadsService.getQuickLoadsModelData().QuickLoadsData;
            this._reIndexQuickLoadList();
            if (this.quickLoadList.length > 0) {
                this.selectedLoadId = 0;
                this.selectedLoadIndex = 0;
                this._GetMagnitudeFriendly(0);
            } else {
                this.selectedLoadId = undefined;
                this.selectedLoadIndex = undefined;
            }
        });
    }

    selectAllTextOnFocus($event) {
        $event.target.select();
    }

    addNewQuickLoad() {
        // QuickLoadsDescriptionDefault
        // { 'LoadTypeValue': 2, 'BuildingCodeIBC': true, 'BuildingCodeNBCC': true, 'ResidentailClass': true, 'CommercialClass': false, 'FloorMembers': false, 'WallHeaders': false, 'RoofMembers': true, 'ConcentratedLiveLoad': 0, 'PartitionLiveLoad': 0, 'Name': 'Floor', 'Comment': 'NOT FLOOR', 'QuickLoads': [ { 'LoadClass': 4, 'StartMagnitude': 228.405884, 'EndMagnitude': 228.405884 }, { 'LoadClass': 5, 'StartMagnitude': 68.52177, 'EndMagnitude': 68.52177 }, { 'LoadClass': 2, 'StartMagnitude': 68.52177, 'EndMagnitude': 68.52177 }, { 'LoadClass': 3, 'StartMagnitude': 10.25, 'EndMagnitude': 10.25 }, { 'LoadClass': 1, 'StartMagnitude': 3.75, 'EndMagnitude': 3.75 }, { 'LoadClass': 0, 'StartMagnitude': 19.5, 'EndMagnitude': 19.5 } ] }
        //var quickLoadModel = { 'LoadTypeValue': 3, 'BuildingCodeIBC': true, 'BuildingCodeNBCC': true, 'ResidentailClass': true, 'CommercialClass': false, 'FloorMembers': true, 'WallHeaders': true, 'RoofMembers': false, 'ConcentratedLiveLoad': 0, 'PartitionLiveLoad': 0, 'Name': getText('QuickLoadsDescriptionDefault'), 'Comment': '', 'QuickLoads': [{ 'LoadClass': 5, 'StartMagnitude': 0, 'StartMagnitudeFriendly': '0.0' }, { 'LoadClass': 4, 'StartMagnitude': 0, 'StartMagnitudeFriendly': '0.0' }, { 'LoadClass': 3, 'StartMagnitude': 0, 'StartMagnitudeFriendly': '0.0' }, { 'LoadClass': 2, 'StartMagnitude': 0, 'StartMagnitudeFriendly': '0.0' }, { 'LoadClass': 1, 'StartMagnitude': 0, 'StartMagnitudeFriendly': '0.0' }, { 'LoadClass': 0, 'StartMagnitude': 0, 'StartMagnitudeFriendly': '0.0' }] };
        var quickLoadModel = {
            LoadTypeValue: 3,
            BuildingCodeIBC: true,
            BuildingCodeNBCC: true,
            ResidentailClass: true,
            CommercialClass: false,
            FloorMembers: true,
            WallHeaders: true,
            RoofMembers: true,
            ConcentratedLiveLoad: 0,
            PartitionLiveLoad: 0,
            Name: this.getText('QuickLoadsDescriptionDefault'),
            Comment: '',
            QuickLoads: [],
        };
        this.quickLoadList.push(quickLoadModel);
        this.quickLoadList[this.quickLoadList.length - 1].id =
            this.quickLoadList.length - 1;
        this.setSelectedLoad(
            this.quickLoadList[this.quickLoadList.length - 1].id
        );
        var $accordionSettings = $('#accordionSettings');
        var accor = $accordionSettings.find('.collapse.in');
        accor.collapse('hide');
    }

    updateCommentIfEmpty() {
        if (this.quickLoadList[this.selectedLoadIndex].Comment === '') {
            this.quickLoadList[this.selectedLoadIndex].Comment =
                this.quickLoadList[this.selectedLoadIndex].Name;
        }
        if (this.quickLoadList[this.selectedLoadIndex].Name === '') {
            this.quickLoadList[this.selectedLoadIndex].Name = this.getText(
                'QuickLoadsDescriptionDefault'
            );
        }
    }

    _reIndexQuickLoadList() {
        for (var i = 0; i < this.quickLoadList.length; i++) {
            this.quickLoadList[i].id = i;
        }
    }

    deleteQuickLoad() {
        this.loadAdded = false;
        this.quickLoadList.splice(this.selectedLoadIndex, 1);
        this._reIndexQuickLoadList();
        if (this.quickLoadList.length <= this.selectedLoadIndex) {
            if (this.quickLoadList.length > 0) {
                this.selectedLoadIndex = this.quickLoadList.length - 1;
                this.selectedLoadId =
                    this.quickLoadList[this.selectedLoadIndex].id;
                this._GetMagnitudeFriendly(this.selectedLoadIndex);
            } else {
                this.selectedLoadIndex = undefined;
                this.selectedLoadId = undefined;
            }
        } else {
            // select next element, so the index is the same
            this.selectedLoadId = this.quickLoadList[this.selectedLoadIndex].id;
            this._GetMagnitudeFriendly(this.selectedLoadIndex);
        }
        console.log(this.selectedLoadId);
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    moveQuickLoadUp() {
        var curindex = this.selectedLoadIndex;
        var newindex = this.newIndex(curindex, true);
        var $accordionSettings = $('#accordionSettings');
        $accordionSettings.find('.collapse.in').removeClass('in');
        $accordionSettings.find('.accordion-toggle').addClass('collapsed');
        var curindex = this.selectedLoadIndex;
        var newindex = this.newIndex(curindex, true);
        this.moveElement(curindex, newindex);
        this.scrollToSelected(newindex);
    }

    moveQuickLoadDown() {
        this.loadMoved = true;
        this.loadAdded = false;
        var $accordionSettings = $('#accordionSettings');
        $accordionSettings.find('.collapse.in').removeClass('in');
        $accordionSettings.find('.accordion-toggle').addClass('collapsed');
        var curindex = this.selectedLoadIndex;
        var newindex = this.newIndex(curindex, false);
        this.moveElement(curindex, newindex);
        this.scrollToSelected(newindex);
    }

    moveElement(curindex, newindex) {
        var elem = this.quickLoadList.splice(curindex, 1);
        this.quickLoadList.splice(newindex, 0, elem[0]);
        this._reIndexQuickLoadList();
        //set selected index to the new position of the droped element
        this.selectedLoadIndex = newindex;
        this.selectedLoadId = this.quickLoadList[this.selectedLoadIndex].id;
        this._GetMagnitudeFriendly(this.selectedLoadIndex);
    }

    newIndex(index, up) {
        if (index - 1 === -1 && up) {
            return this.quickLoadList.length - 1;
        } else if (index + 1 === this.quickLoadList.length && !up) {
            return 0;
        }

        if (up) {
            return index - 1;
        } else {
            return index + 1;
        }
    }

    scrollToSelected(id) {
        this.$timeout(() => {
            var li = document.getElementById(id);
            li.scrollIntoView(false);
        });
    }

    _GetMagnitudeFriendly(index) {
        var magnitudeList = this._buildOrignalMagnitudeList(index);
        var data = {
            UOMSetting: this.metaData.ProgramSettingsClientData.UOM,
            loadType: this.quickLoadList[index].LoadTypeValue,
            magnitudesList: magnitudeList,
            partitionLiveLoad: {
                loadClass: '',
                magnitudeValue: this.quickLoadList[index].PartitionLiveLoad,
                magnitudeFriendly: '',
            },
            concentratedLiveLoad: {
                loadClass: '',
                magnitudeValue: this.quickLoadList[index].ConcentratedLiveLoad,
                magnitudeFriendly: '',
            },
        };
        this.quickLoadsService.getMagnitudeFriendly(data).then((response) => {
            this.clearReformatedLoadGridData();
            this.quickLoadList[index].PartitionLiveLoadFriendly =
                response.partitionLiveLoad.magnitudeFriendly;
            this.quickLoadList[index].ConcentratedLiveLoadFriendly =
                response.concentratedLiveLoad.magnitudeFriendly;
            this._reformatLoadGridData(index, response.magnitudesList);
            if (this.quickLoadList.length === 1) {
                var $accordionSettings = $('#accordionSettings');
                $accordionSettings.find('.collapse.in').removeClass('in');
                $accordionSettings.find('.collapse').collapse('show');
            }
        });
    }

    partitionLoadChanged() {
        var data = {
            UOMSetting: this.metaData.ProgramSettingsClientData.UOM,
            value: this.quickLoadList[this.selectedLoadIndex]
                .PartitionLiveLoadFriendly,
        };

        this.quickLoadsService
            .convertPartitionLoadToMagnitude(data)
            .then((response) => {
                this.quickLoadList[this.selectedLoadIndex].PartitionLiveLoad =
                    response;
                this._GetMagnitudeFriendly(this.selectedLoadIndex);
            });
    }
    concentratedLiveLoadChanged() {
        var data = {
            UOMSetting: this.metaData.ProgramSettingsClientData.UOM,
            value: this.quickLoadList[this.selectedLoadIndex]
                .ConcentratedLiveLoadFriendly,
        };

        this.quickLoadsService
            .convertConcentratedLiveLoadToMagnitude(data)
            .then((response) => {
                this.quickLoadList[
                    this.selectedLoadIndex
                ].ConcentratedLiveLoad = response;
                this._GetMagnitudeFriendly(this.selectedLoadIndex);
            });
    }

    _buildOrignalMagnitudeList(index) {
        var selectedQuickLoad = this.quickLoadList[index];
        var magnitudeList = [];
        for (var i = 0; i < selectedQuickLoad.QuickLoads.length; i++) {
            magnitudeList.push({
                loadClass: selectedQuickLoad.QuickLoads[i].LoadClass,
                magnitudeValue: selectedQuickLoad.QuickLoads[i].StartMagnitude,
                magnitudeFriendly: '',
            });
        }
        return magnitudeList;
    }

    clearReformatedLoadGridData() {
        for (var i = 0; i < this.reformatedLoadGridData.length; i++) {
            this.reformatedLoadGridData[i].StartMagnitude = 0;
            this.reformatedLoadGridData[i].StartMagnitudeFriendly = '0.0';
        }
    }

    quickLoadTypeChanged() {
        var data = {
            UOMSetting: this.metaData.ProgramSettingsClientData.UOM,
            loadType: this.quickLoadList[this.selectedLoadIndex].LoadTypeValue,
            magnitudesList: this.getLoadGridMagnitudeList(),
        };
        this.quickLoadsService
            .convertQuickLoadMagnitudes(data)
            .then((response) => {
                this._reformatLoadGridData(
                    this.selectedLoadIndex,
                    response.magnitudesList
                );
                this.saveCurrentQuickLoad();
            });
    }

    getLoadGridMagnitudeList() {
        var magnitudeList = [];
        for (var i = 0; i < this.reformatedLoadGridData.length; i++) {
            if (this.reformatedLoadGridData[i].StartMagnitude !== 0) {
                magnitudeList.push({
                    loadClass: this.reformatedLoadGridData[i].LoadClass,
                    magnitudeValue: 0,
                    magnitudeFriendly:
                        this.reformatedLoadGridData[i].StartMagnitudeFriendly,
                });
            }
        }
        return magnitudeList;
    }

    saveCurrentQuickLoad() {
        for (var i = 0; i < this.reformatedLoadGridData.length; i++) {
            var loadClass = this.reformatedLoadGridData[i].LoadClass;
            var loadIndex = this.quickLoadList[
                this.selectedLoadIndex
            ].QuickLoads.map((x) => {
                return x.LoadClass;
            }).indexOf(loadClass);
            if (loadIndex !== -1) {
                if (this.reformatedLoadGridData[i].StartMagnitude === 0) {
                    this.quickLoadList[
                        this.selectedLoadIndex
                    ].QuickLoads.splice(loadIndex, 1);
                } else {
                    this.quickLoadList[this.selectedLoadIndex].QuickLoads[
                        loadIndex
                    ].StartMagnitude =
                        this.reformatedLoadGridData[i].StartMagnitude;
                    this.quickLoadList[this.selectedLoadIndex].QuickLoads[
                        loadIndex
                    ].EndMagnitude =
                        this.reformatedLoadGridData[i].StartMagnitude;
                }
            } else {
                if (this.reformatedLoadGridData[i].StartMagnitude !== 0) {
                    this.quickLoadList[this.selectedLoadIndex].QuickLoads.push({
                        LoadClass: loadClass,
                        StartMagnitude:
                            this.reformatedLoadGridData[i].StartMagnitude,
                        EndMagnitude:
                            this.reformatedLoadGridData[i].StartMagnitude,
                    });
                }
            }
        }
    }

    setSelectedLoad(id) {
        if (this.isUpdatingMagnitude) {
            this.selectLoadIdAfterUpdated = id;
            return;
        }
        if (this.selectedLoadId) {
            this.saveCurrentQuickLoad();
        }
        // clear reformatedLoadGridData
        this.selectedLoadIndex = this.quickLoadList
            .map((x) => {
                return x.id;
            })
            .indexOf(id);
        this._GetMagnitudeFriendly(this.selectedLoadIndex);
        this.selectedLoadId = id;
        this.scrollToSelected(this.selectedLoadId);
        var $accordionSettings = $('#accordionSettings');
        $accordionSettings.on('show.bs.collapse', '.collapse', function () {
            $accordionSettings.find('.collapse.in').collapse('hide');
        });
    }

    // In reformatedLoadGridData, display the friendly value
    _reformatLoadGridData(index, allMagnitudesList) {
        for (
            var gridIndex = 0;
            gridIndex < allMagnitudesList.length;
            gridIndex++
        ) {
            var loadClass = allMagnitudesList[gridIndex].loadClass;
            var quickLoadIndex = this.reformatedLoadGridData
                .map((x) => {
                    return x.LoadClass;
                })
                .indexOf(loadClass);
            if (quickLoadIndex !== -1) {
                this.reformatedLoadGridData[quickLoadIndex].StartMagnitude =
                    allMagnitudesList[gridIndex].magnitudeValue;
                this.reformatedLoadGridData[
                    quickLoadIndex
                ].StartMagnitudeFriendly =
                    allMagnitudesList[gridIndex].magnitudeFriendly;
            } else {
                this.reformatedLoadGridData[quickLoadIndex].StartMagnitude = 0;
                this.reformatedLoadGridData[
                    quickLoadIndex
                ].StartMagnitudeFriendly = '0.0';
            }
        }
    }

    updateMagnitudeGrid(event, loadClass, newStartMagnitude) {
        if (this.dirtyCheckService.isDirty(event)) {
            this.isUpdatingMagnitude = true;
            var data = {
                UOMSetting: this.metaData.ProgramSettingsClientData.UOM,
                loadType:
                    this.quickLoadList[this.selectedLoadIndex].LoadTypeValue,
                magnitudesList: [
                    {
                        loadClass: loadClass,
                        magnitudeValue: 0,
                        magnitudeFriendly: newStartMagnitude,
                    },
                ],
            };

            this.quickLoadsService
                .convertQuickLoadMagnitudes(data)
                .then((response) => {
                    this._reformatLoadGridData(
                        this.selectedLoadIndex,
                        response.magnitudesList
                    );
                    this.saveCurrentQuickLoad();
                    this.isUpdatingMagnitude = undefined;
                    if (this.selectLoadIdAfterUpdated !== undefined) {
                        this.setSelectedLoad(this.selectLoadIdAfterUpdated);
                        this.selectLoadIdAfterUpdated = undefined;
                    }
                });
        }
    }

    settingsSaveClicked() {
        var data = {};
        data['NewProgramSettingsData'] =
            this.metaData.ProgramSettingsClientData;
        // Start in File Manager CheckBox removed v3.4
        //data['OpenToFileManagerOnLogin'] = this.openToFileManagerOnLogin;

        if (this.quickLoadList.length > 0) {
            this.saveCurrentQuickLoad();
        }
        var quickLoadModelData =
            this.quickLoadsService.getQuickLoadsModelData();
        quickLoadModelData.QuickLoadsData = this.quickLoadList;
        var quickLoadData = {};
        quickLoadData['AllQuickLoadsData'] = quickLoadModelData;
        //save the MA cookie.
        this.forteCookieService.writeMaterialAvailabilityEnabled(this.materialAvailibilityEnabled);
        this.forteCookieService.writeSuggestedSolutionsEnabled(this.suggestedSolutionsEnabled);
        this.forteCookieService.writeFactoredTitleEnabled(this.factoredTitleEnabled);
        this.forteCookieService.writeUtilizationPercentageEnabled(this.utilizationPercentageEnabled);
        this.forteCookieService.writeConstructionDetailImageSettingMemberReport(
            this.constructionDetailImageSettingMemberReport
        );
        this.forteCookieService.writeConstructionDetailImageSettingJobSummary(
            this.constructionDetailImageSettingJobSummary
        );

        if (this.memberReportImageSetting !== this.constructionDetailImageSettingMemberReport) {
            this.memberReportImageSetting = this.constructionDetailImageSettingMemberReport;
        } else {
            this.memberReportImageSetting = -1;
        }
        data['MemberReportImageSetting'] = this.memberReportImageSetting;
        if (this.jobSummaryImageSetting !== this.constructionDetailImageSettingJobSummary) {
            this.jobSummaryImageSetting = this.constructionDetailImageSettingJobSummary;
        } else {
            this.jobSummaryImageSetting = -1;
        }
        data['JobSummaryImageSetting'] = this.jobSummaryImageSetting;
        this.programSettingsService
            .saveProgramSettingsData(data)
            .then((serviceResponse) => {
                // Start in File Manager CheckBox removed v3.4
                // this.forteCookieService.writeOpenToFileManagerOnLogin(
                //     this.openToFileManagerOnLogin
                // );
                this.getProgramSettingsProcessData();
                this.quickLoadsService
                    .saveQuickLoadsData(quickLoadData)
                    .then((response) => {
                        this.allModelDataService.setQuickLoadsModelData(
                            quickLoadModelData
                        );
                    })
                    .finally(() => {
                        console.log('dismiss');
                        this.$uibModalInstance.dismiss('save');
                    });
            });
    }

    settingsCancelClicked() {
        this.$uibModalInstance.dismiss('cancel');
    }

    helpClicked() {
        var win;
        if (this.selectedTabIndex === 0) {
            win = window.open(
                '/helpmobile/Content/B_Getting Started/04_user.htm',
                '_blank'
            );
        } else {
            win = window.open(
                '/helpmobile/Content/B_Getting Started/04a_quick_loads.htm',
                '_blank'
            );
        }
        win.focus();
    }

    resetDefaultsClicked() {
        //post resetalldefaluts
        this.programSettingsService.resetDefaults().then(() => {
            //should we display msg immediatly, or display loading and then msg?
            this.resetDefaultsMessage = 'jobSettingsResetComplete';
            this.resetFinished = true;
        });
    }

    select(selectedTabIndex) {
        this.selectedTabIndex = selectedTabIndex;
    }

    sigfig(curentdigits, newvalue) {
        if (newvalue > curentdigits) {
            curentdigits = newvalue;
        }
        return curentdigits;
    }
}
