import accttemplate from './account/masterPages/accountLayout.html';
import logintemplate from './account/login/login.html';
import registertemplate from './account/register/register.html';
import emailSentTemplate from './account/emailSent/emailSent.html';
import resendEmailTemplate from './account/resendEmail/resendEmail.html';
import resetPasswordTemplate from './account/resetPassword/resetPassword.html';
import emailConfirmTemplate from './account/emailConfirm/emailConfim.html';
import shelltemplate from './loggedIn/masterPages/shell/shell.html';
import jobtemplate from './loggedIn/masterPages/job/job.html';
import loadingtemplate from './loggedIn/masterPages/shell/loadingProject/loadingProject.html';
import memberInfoTemplate from './loggedIn/job/member/memberInfo.html';
import spansAndSupportsTemplate from './loggedIn/job/spansAndSupports/spansAndSupports.html';
import holesTemplate from './loggedIn/job/holes/holes.html';
import floorPerformanceTemplate from './loggedIn/job/floorPerformance/floorPerformance.html';
import productSelectionTemplate from './loggedIn/job/productSelection/productSelection.html';
import jobSummaryTemplate from './loggedIn/job/jobSummary/jobSummary.html';
import manageTemplate from './loggedIn/masterPages/manage/manage.html';
import fileManagement from './loggedIn/manage/fileManagement/fileManagement.html';
import teamManagement from './loggedIn/manage/teamManagement/teamManagement.html';
import locationAnalysisTemplate from './loggedIn/job/locationAnalysis/locationAnalysis.html';
import reportTemplate from './loggedIn/job/report/report.html';
// import loadsHorizYTemplate from './loggedIn/job/loads/loadsHorizY.html';
import solutionsTemplate from './loggedIn/job/solutions/solutions.html';
import roofGeometryTemplate from './loggedIn/job/roofGeometry/roofGeometry.html';
import levelTemplate from './loggedIn/job/level/level.html';
import forgotPasswordTemplate from './account/forgotPassword/forgotPassword.html';
import jobSettingsTemplate from './loggedIn/job/jobSettings/jobSettings.html';
import windLoadingTemplate from './loggedIn/job/windLoading/windLoading.html';
import seismicLoadingTemplate from './loggedIn/job/seismicLoading/seismicLoading.html';
import loadsVertTemplate from './loggedIn/job/loadsVertical/loadsVert.html';
import loadsHorizTemplate from './loggedIn/job/loads/loadsHoriz.html';
import heightAndSupportsTemplate from './loggedIn/job/heightAndSupports/heightAndSupports.html';
import taperedEndsTemplate from './loggedIn/job/taperedEnds/taperedEnds.html';
import materialsManagementTemplate from './loggedIn/materials/materialsManagement.html';

export default function routes($httpProvider, $stateProvider, $urlRouterProvider, $locationProvider) {
    'ngInject';

    $httpProvider.interceptors.push('authInterceptorService');
    $httpProvider.defaults.withCredentials = true;
    $httpProvider.defaults.headers.common = {};
    $httpProvider.defaults.headers.post = {};
    $httpProvider.defaults.headers.put = {};
    $httpProvider.defaults.headers.patch = {};

    $urlRouterProvider.otherwise('/login');


    $stateProvider
    .state('account', {
        template: accttemplate
    })
    .state('account.login', {
        url: '/login',
        controller: 'loginController',
        controllerAs:'loginCtrl',
        template: logintemplate,
    })
    .state('account.register', {
        url: '/register',
        controller: 'registerController',
        controllerAs: 'registerCtrl',
        template: registertemplate,
    })
    .state('account.forgotPassword', {
        url: '/forgotPassword',
        controller: 'forgotPasswordController',
        controllerAs: 'forgotPasswordCtrl',
        template: forgotPasswordTemplate,
    })
    .state('account.emailSent', {
        url: '/emailsent',
        controller: 'emailSentController',
        controllerAs: 'emailSentCtrl',
        template: emailSentTemplate
    })
    .state('account.resendEmail', {
        url: '/resendEmail',
        controller: 'resendEmailController',
        controllerAs: 'resendEmailCtrl',
        template: resendEmailTemplate,
        params: {
            message: '',
            emailAddress: ''
        }
    })
    .state('account.resetPassword', {
        url: '/resetpassword',
        controller: 'resetPasswordController',
        controllerAs: 'resetPasswordCtrl',
        template: resetPasswordTemplate
    })
    .state('account.emailConfirm', {
        url: '/emailconfim',
        controller: 'emailConfirmController',
        controllerAs: 'emailConfirmCtrl',
        template: emailConfirmTemplate
    })
    .state('application', {
        url: '/application',
        template: shelltemplate,
        controller: 'shellController',
        controllerAs: 'shellCtrl',
        resolve: {
            loggedin: ['datacontext', function (datacontext) {
                return datacontext.getIsAuthenticated();
            }]
        }
    })
    .state('application.loading', {
        url: '/loading',
        template: loadingtemplate,
        controller: 'loadingProjectController',
        controllerAs: 'loadingCtrl',
        data: {showGraphicToggle: false}
    })
    .state('application.job', {
        url: '/job',
        template: jobtemplate,
        controller: 'jobController',
        controllerAs: 'jobCtrl',
        resolve: {
            loggedin: ['datacontext', function (datacontext) {
                return datacontext.getIsAuthenticated();
            }]
        }
    })
    .state('application.job.memberInfo', {
        url: '/memberinfo',
        template: memberInfoTemplate,
        controller: 'memberInfoController',
        controllerAs: 'mIController',
        data: {showGraphicToggle: false}
    })
    .state('application.job.level', {
        url: '/level',
        template: levelTemplate,
        controller: 'levelController',
        controllerAs: 'levelCtrl',
        data: {showGraphicToggle: false}
    })
    .state('application.job.spansAndSupports', {
        url: '/spansandsupports',
        template: spansAndSupportsTemplate,
        controller: 'spansAndSupportsController',
        controllerAs: 'ssCtrl',
        data: {showGraphicToggle: true}
    })
    .state('application.job.holes', {
        url: '/holes',
        template: holesTemplate,
        controller: 'holesController',
        controllerAs: 'holesCtrl',
        data: {showGraphicToggle: true}
    })
    .state('application.job.floorPerformance', {
        url: '/floorPerformance',
        template: floorPerformanceTemplate,
        controller: 'floorPerformanceController',
        controllerAs: 'fpCtrl',
        data: {showGraphicToggle: false}
    })
    .state('application.job.productSelection', {
        url: '/productSelection',
        template: productSelectionTemplate,
        controller: 'productSelectionController',
        controllerAs: 'productSelectionCtrl',
        data: {showGraphicToggle: false}
    })
    .state('application.job.loadsHorizontalMember', {
        url: '/loadshorizontalmember',
        template: loadsHorizTemplate,
        controller: 'loadsHorizController',
        controllerAs:'loadsHorizCtrl',
        data: {showGraphicToggle: true}
    })
    .state('application.job.locationAnalysis', {
        url: '/locationanalysis',
        template: locationAnalysisTemplate,
        controller: 'locationAnalysisController',
        controllerAs: 'locAnalysisCtrl',
        data: {showGraphicToggle: true}
    })
    .state('application.job.taperedEnds', {
        url: '/taperedEnds',
        template: taperedEndsTemplate,
        controller: 'taperedEndsController',
        controllerAs: 'taperedEndsCtrl',
        data: {showGraphicToggle: true}
    })
    .state('application.job.jobSummary', {
        url: '/jobsummary',
        template: jobSummaryTemplate,
        controller: 'jobSummaryController',
        controllerAs: 'jobSummaryCtrl',
        data: {showGraphicToggle: false}
    })
    .state('application.job.jobSettings', {
        url: '/jobsettings',
        template: jobSettingsTemplate,
        controller: 'jobSettingsController',
        controllerAs: 'jobSettingsCtrl',
        data: {showGraphicToggle: false}
    })
    .state('application.job.windLoading', {
        url: '/windLoading',
        template: windLoadingTemplate,
        controller: 'windLoadingController',
        controllerAs: 'wlCtrl',
        data: {showGraphicToggle: false}
    })
    .state('application.job.seismicLoading', {
        url: '/seismicLoading',
        template: seismicLoadingTemplate,
        controller: 'seismicLoadingController',
        controllerAs: 'slCtrl',
        data: {showGraphicToggle: false}
    })
    .state('application.job.roofGeometry', {
        url: '/roofgeometry',
        template: roofGeometryTemplate,
        controller: 'roofGeometryController',
        controllerAs: 'rgCtrl',
        data: {showGraphicToggle: false}
    })
    .state('application.job.heightAndSupports', {
        url: '/heightandsupports',
        template: heightAndSupportsTemplate,
        controller: 'heightAndSupportsController',
        controllerAs: 'hasCtrl',
        data: {showGraphicToggle: false}
    })
    .state('application.job.verticalLoads', {
        url: '/loadsverticalmember',
        template: loadsVertTemplate,
        controller: 'loadsVertController',
        controllerAs: 'loadsVertCtrl',
        data: {showGraphicToggle: false}
    })
    .state('application.job.solutions', {
        url: '/solutions',
        template: solutionsTemplate,
        controller: 'solutionsController',
        controllerAs: 'solutionsCtrl',
        data: {showGraphicToggle: false}
    })
    .state('application.job.report', {
        url: '/report',
        template: reportTemplate,
        controller: 'reportController',
        controllerAs: 'reportCtrl',
        data: {showGraphicToggle: false}
    })
    .state('application.manage', {
        url: '/manage',
        template: manageTemplate,
        controller: 'manageController',
        controllerAs: 'manageCtrl',
    })
    .state('application.materials', {
        url: '/materials',
        template: materialsManagementTemplate,
        controller: 'materialsManagementController',
        controllerAs: 'mmCtrl',
        params: {
            fromState: '',
            currentTeamId: ''
        }
    })
    //browsing private files.
    .state('application.manage.files', {
        url: '/files',
        template: fileManagement,
        controller: 'fileManagementController',
        controllerAs: 'fmCtrl',
        params: {
            fromState: '',
            openToCurrentFile: ''
        }
    })
    .state('application.manage.files.recent', {
        url: '/recent',
        template: fileManagement,
        controller: 'fileManagementController',
        controllerAs: 'fmCtrl',
        params: {
            fromState: '',
            openToCurrentFile: ''
        }
    })
    //browsing team files.
    .state('application.manage.team', {
        url: '/team/:teamId',
        template: fileManagement,
        controller: 'fileManagementController',
        controllerAs: 'fmCtrl',
        params: {
            teamId: '',
            openToCurrentFile: ''
        }
    })
    .state('application.manage.team.recent', {
        url: '/recent',
        template: fileManagement,
        controller: 'fileManagementController',
        controllerAs: 'fmCtrl',
        params: {
            teamId: '',
            openToCurrentFile: ''
        }
    })
    //this state allows a query parameter, the paramater gets passed by calling
    //$state.go('application.manage.teamInfo', {teamId: id});
    //adding this parameter to the url preserves the id on page refresh.
    .state('application.manage.teamInfo', {
        url: '/teaminfo/:teamId',
        template: teamManagement,
        controller: 'teamManagementController',
        controllerAs: 'teamManagementCtrl',
        params: {
            teamId: ''
        }
    });

    $locationProvider.html5Mode(true);
}
