export default class userProfileController {
    constructor(
        $timeout,
        $uibModalInstance,
        $http,
        $sce,
        resourceMessagingService,
        fileSystemService,
        authService,
        smartNavService,
        $scope,
        postService,
        profileParams,
        allModelDataService
    ) {
        'ngInject';

        this.$timeout = $timeout;
        this.$uibModalInstance = $uibModalInstance;
        this.$http = $http;
        this.$sce = $sce;
        this.resourceMessagingService = resourceMessagingService;
        this.fileSystemService = fileSystemService;
        this.authService = authService;
        this.smartNavService = smartNavService;
        this.$scope = $scope;
        this.postService = postService;
        this.profileParams = profileParams;
        this.allModelDataService = allModelDataService;

        this.generalInfoShown;
        this.changePasswordShown;

        this.newPassword;
        this.submitted = false;
        this.passwordsMatch = true;
        this.passwordChangeSuccess = false;
        this.passwordChangeMessage;
        this.showCriteriaList = false;
        this.showConfirmPasswordMsg = false;
        this.passwordLengthValid = false;
        this.passwordUpperCaseValid = false;
        this.passwordNumberValid = false;
        this.cancelEnabled = false;
        this.invalidPhoneNumber = false;
        this.invalidZip = false;
        this.displayedStates = null;
        this.selectedCountry = null;
        this.otherCountrySelected = '';
        this.currentPassword = '';
        this.newPassword = '';
        this.confirmPassword = '';
        this.passwordChangeMessage = '';
        this.userProfileSubmitted = false;
        this.changePasswordSubmitted = false;
        this.showChangePasswordSection = false;

        this.userProfileData = null;
        this.userFirstName = '';
        this.userLastName = '';

        this.canadaStates = this.getText('canadaStatesList').split('|');
        this.USAStates = this.getText('USStatesList').split('|');

        this.$scope.$watch(
            () => {
                return this.newPassword;
            },
            (value) => {
                if (value && value.length >= 6) {
                    this.passwordLengthValid = true;
                } else {
                    this.passwordLengthValid = false;
                }

                if (value && value.match(/[A-Z]/)) {
                    this.passwordUpperCaseValid = true;
                } else {
                    this.passwordUpperCaseValid = false;
                }

                if (value && value.match(/\d/)) {
                    this.passwordNumberValid = true;
                } else {
                    this.passwordNumberValid = false;
                }

                if (
                    this.passwordLengthValid &&
                    this.passwordHasUppercase &&
                    this.passwordHasNumber
                ) {
                    this.showCriteriaList = false;
                }
            }
        );

        this.$scope.$watch(
            () => {
                return this.confirmPassword;
            },
            (value) => {
                this.comparePasswords();
            }
        );

        this.activate();
    }

    activate() {
        this.languageList = this.resourceMessagingService.getLanguageOptions();
        this.createDropDownInputLists();

        this.userProfileData = this.profileParams.userProfileData;
        this.setUserFirstLastName();

        if (this.profileParams.calledFrom === 'login') {
            this.showChangePasswordSection = false;
        } else {
            this.showChangePasswordSection = true;
        }

        // Setup cancel button status before initializing Country and StateProvince defaults
        if (this.isUserProfileDataIncomplete()) {
            this.cancelEnabled = false;
        } else {
            this.cancelEnabled = true;
        }

        switch (this.userProfileData.Country.toLowerCase()) {
            case 'united states of america':
                this.selectedCountry = '1';
                break;
            case 'canada':
                this.selectedCountry = '0';
                break;
            default:
                this.selectedCountry = '2';
                break;
        }
        if (!this.selectedCountry) {
            this.selectedCountry = '1';
        } else if (
            this.selectedCountry !== '0' &&
            this.selectedCountry !== '1'
        ) {
            this.selectedCountry = '2';
            this.otherCountrySelected = this.userProfileData.Country;
            this.getAllCountriesFromFile();
        }

        this.updateDisplayedStates(true);
    }

    setUserFirstLastName() {
        this.userFirstName = this.userProfileData.FirstName;
        this.userLastName = this.userProfileData.LastName;
        if (!this.userFirstName || !this.userLastName) {

            if (this.userProfileData.Name !== null) {
                let nameslist = this.userProfileData.Name.trim().split(' ');
                if (nameslist.length === 1) {
                    this.userFirstName = '';
                    this.userLastName = this.userProfileData.Name;
                } else if (nameslist.length > 1) {
                    this.userFirstName = nameslist[0].trim();
                    this.userLastName = this.userProfileData.Name.substring(
                        this.userFirstName.length
                    ).trim();
                }
            }
        }
    }

    setUserFullName() {
        this.userProfileData.Name =
            this.userFirstName.trim() + ' ' + this.userLastName.trim();
    }

    getAllCountriesFromFile() {
        this.postService
            .requestSimplePost('api/Account/GetCountriesList', '')
            .then((response) => {
                this.otherCountriesList = response.CountriesList;
            });
    }

    setOtherCountry() {
        this.userProfileData.Country = this.otherCountrySelected;
    }

    createDropDownInputLists() {
        this.contriesObject = {};
        var contriesList = this.getText('countriesList').split('|');
        this.contriesObject['0'] = contriesList[0];
        this.contriesObject['1'] = contriesList[1];
        this.contriesObject['2'] = contriesList[2];

        this.occupationsList = {};
        var occupationList = this.getText('occupationsList').split('|');
        for (var i = 0; i < occupationList.length; i++) {
            this.occupationsList[i] = occupationList[i];
        }
    }

    callback(data) {
        alert(data);
    }

    showCurrentPasswordNotCorrect() {
        if (!this.passwordChangeSuccess && this.passwordChangeMessage !== '') {
            return true;
        } else {
            return false;
        }
    }

    isOtherSelected() {
        if (this.userProfileData === null) {
            return false;
        } else {
            return this.selectedCountry === '2';
        }
    }

    updateDisplayedStates(isFirstShown) {
        if (this.selectedCountry === '0') {
            this.userProfileData.Country = this.selectedCountry;
            this.otherCountrySelected = null;
            this.displayedStates = this.canadaStates;
            this.invalidZip = false;
            if (this.userProfileData.Phone) {
                this.userProfileData.Phone =
                    this.userProfileData.Phone.substring(0, 10);
            }
        } else if (this.selectedCountry === '1') {
            this.userProfileData.Country = this.selectedCountry;
            this.displayedStates = this.USAStates;
            this.otherCountrySelected = null;
            this.invalidZip = false;
            if (this.userProfileData.Phone) {
                this.userProfileData.Phone =
                    this.userProfileData.Phone.substring(0, 10);
            }
        } else if (this.selectedCountry === '2' && !isFirstShown) {
            this.userProfileData.Country = null;
            this.userProfileData.StateProvince = null;
            this.displayedStates = '';
            this.userProfileData.PostalCode = '';
            this.getAllCountriesFromFile();
        }
    }

    showPwdCriteriaList() {
        this.showCriteriaList = true;
        this.passwordChangeSuccess = false;
        this.passwordChangeMessage = '';
    }

    hidePwdCriteriaList() {
        this.showCriteriaList = false;
    }

    isPostalCodeValid() {
        // verify zip code (5 digits)
        if (
            this.selectedCountry !== '2' &&
            this.userProfileData.PostalCode &&
            this.userProfileSubmitted
        ) {
            // RegEx for Canada and United States
            //  (^\d{5}(-\d{4})?$)|(^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$)
            let uniqueCharacters = this.getUniqueCharactersFromString(
                this.userProfileData.PostalCode
            );
            //if canada has less than 2 unuque characters
            //or us has less than 1 unique character
            this.userProfileData.PostalCode = this.userProfileData.PostalCode.toUpperCase();
            if (
                (this.selectedCountry === '0' &&
                    (!this.userProfileData.PostalCode.match(
                        /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/
                    ) ||
                        uniqueCharacters.length < 2)) ||
                (this.selectedCountry === '1' &&
                    (!this.userProfileData.PostalCode.match(
                        /^\d{5}(-\d{4})?$/
                    ) ||
                        uniqueCharacters.length < 2))
            ) {
                this.invalidZip = true;
                var inputToFocus = document.getElementById('inputZip');
                inputToFocus.focus();
            } else {
                this.invalidZip = false;
            }
        }
    }

    isPhoneNumberValid() {
        // verify phone number
        if (this.userProfileData.Phone && this.userProfileSubmitted) {
            let uniqueNumbersInPhoneNumber = this.getUniqueCharactersFromString(
                this.userProfileData.Phone
            );

            if (
                (this.selectedCountry !== '2' &&
                    this.userProfileData.Phone.length !== 10) ||
                uniqueNumbersInPhoneNumber <= 1
            ) {
                this.invalidPhoneNumber = true;
                var inputToFocus = document.getElementById('inputPhone');
                inputToFocus.focus();
            } else {
                this.invalidPhoneNumber = false;
            }
        }
    }

    isCityValid() {
        let uniqueCharsInCity = this.getUniqueLettersFromString(
            this.userProfileData.City
        );
        this.cityInvalid = uniqueCharsInCity.length <= 1;
    }

    getUniqueCharactersFromString(str) {
        str = str.replace(' ', '');
        str = str.toLowerCase();
        let unique = [...str].reduce((previousVal, currentVal) => {
            if (previousVal.includes(currentVal)) {
                return previousVal;
            } else {
                return previousVal + currentVal;
            }
        }, '');
        return unique;
    }
    validateFirstName() {
        let unique = this.getUniqueLettersFromString(this.userFirstName);
        this.firstNameInvalid = unique.length <= 1;
    }

    validateLastName() {
        let uniqueLast = this.getUniqueLettersFromString(this.userLastName);
        this.lastNameInvalid = uniqueLast.length <= 1;
    }

    validateNamesNotTheSame() {
        let uniqueFirst = this.getUniqueLettersFromString(this.userFirstName);
        let uniqueLast = this.getUniqueLettersFromString(this.userLastName);
        this.namesInvalidIdentical = uniqueFirst === uniqueLast;
    }

    validateCompanyName() {
        let uniqueCompany = this.getUniqueLettersFromString(
            this.userProfileData.Company
        );
        if (
            this.userProfileData.Occupation !== '6' &&
            this.userProfileData.Occupation !== '10'
        ) {
            this.invalidCompany = uniqueCompany.length <= 1;
        } else {
            this.invalidCompany = false;
        }
    }

    getUniqueLettersFromString(str) {
        if (str) {
            //strip out symbols.
            str = str.replace(/[^a-zA-Z ]/g, '');
            str = str.toLowerCase();
            let unique = [...str].reduce((previousVal, currentVal) => {
                if (previousVal.includes(currentVal)) {
                    return previousVal;
                } else {
                    return previousVal + currentVal;
                }
            }, '');
            return unique;
        } else {
            return '';
        }
    }

    isStatesValid() {
        if (
            this.displayedStates.indexOf(this.userProfileData.StateProvince) >
            -1
        ) {
            return true;
        } else {
            return false;
        }
    }

    // OK clicked
    updateUserProfile(isValid) {
        this.userProfileSubmitted = true;

        if (!isValid) {
            return;
        }

        this.isPostalCodeValid();

        this.isPhoneNumberValid();

        this.isCityValid();

        this.validateFirstName();

        this.validateLastName();

        this.validateNamesNotTheSame();

        this.validateCompanyName();

        if (
            this.firstNameInvalid ||
            this.lastNameInvalid ||
            this.namesInvalidIdentical
        ) {
            return;
        }

        if (this.invalidCompany) {
            return;
        }

        if (this.selectedCountry !== '2' && !this.isStatesValid()) {
            this.userProfileData.StateProvince = '';
            return;
        }

        if (
            this.invalidPhoneNumber ||
            (this.selectedCountry !== '2' && this.invalidZip)
        ) {
            return;
        }

        if (this.cityInvalid) {
            return;
        }

        if (this.userProfileData.Country === null) {
            return;
        }

        if (isValid) {
            this.setUserFullName();
            this.userProfileData.FirstName = this.userFirstName;
            this.userProfileData.LastName = this.userLastName;
            this.postService
                .requestSimplePost(
                    'api/Account/SaveUserProfile',
                    this.userProfileData
                )
                .then(() => {
                    this.allModelDataService.setUserProfileIsIncomplete(false);
                    this.$uibModalInstance.dismiss('ok');
                })
                .catch((err) => {});
        }
    }

    helpClicked() {
        var win = window.open(
            '/helpmobile/Content/B_Getting Started/04_user.htm',
            '_blank'
        );
        win.focus();
    }

    comparePasswords() {
        if (!this.confirmPassword) {
            this.passwordsMatch = false;
            this.showConfirmPasswordMsg = false;
        } else if (this.newPassword !== this.confirmPassword) {
            this.passwordsMatch = false;
            this.showConfirmPasswordMsg = true;
        } else {
            this.passwordsMatch = true;
            this.$timeout(() => {
                this.showConfirmPasswordMsg = false;
            }, 100);
        }
    }

    changePassword(isValid) {
        this.changePasswordSubmitted = true;
        this.comparePasswords();
        if (!this.passwordsMatch) {
            isValid = false;
        }

        if (
            !this.passwordLengthValid ||
            !this.passwordNumberValid ||
            !this.passwordUpperCaseValid
        ) {
            isValid = false;
            this.showCriteriaList = true;
        }

        if (isValid) {
            this.authService
                .changePassword(
                    this.currentPassword,
                    this.newPassword,
                    this.confirmPassword
                )
                .then((response) => {
                    this.passwordChangeSuccess = true;
                    this.passwordChangeMessage = this.getText('success');
                })
                .catch((message) => {
                    this.passwordChangeSuccess = false;
                    this.passwordChangeMessage = message;
                })
                .finally(() => {
                    this.newPassword = '';
                    this.currentPassword = '';
                    this.confirmPassword = '';
                    this.changePasswordSubmitted = false;
                });
        }
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    // verify we have data saved first and then enbale cancel button
    cancelClicked() {
        this.$uibModalInstance.dismiss('cancel');
    }

    isUserProfileDataIncomplete() {
        var profileDataMissing = false;

        if (
            this.isPropertyNullOrEmpty(this.userFirstName) ||
            this.isPropertyNullOrEmpty(this.userLastName) ||
            this.isPropertyNullOrEmpty(this.userProfileData.Occupation) ||
            this.isPropertyNullOrEmpty(this.userProfileData.City) ||
            this.isPropertyNullOrEmpty(this.userProfileData.Phone)
        ) {
            profileDataMissing = true;
        } else if (
            this.userProfileData.Occupation !== '6' && this.userProfileData.Occupation !== '10' &&
            this.isPropertyNullOrEmpty(this.userProfileData.Company)
        ) {
            // not a Home Owner and the company is not entered (Home Owner does not have to enter a company)
            profileDataMissing = true;
        } else {
            if (
                this.userProfileData.Country === '1' ||
                this.userProfileData.Country === '0'
            ) {
                if (
                    this.isPropertyNullOrEmpty(
                        this.userProfileData.StateProvince
                    ) ||
                    this.isPropertyNullOrEmpty(this.userProfileData.PostalCode)
                ) {
                    profileDataMissing = true;
                }
            } else {
                if (this.isPropertyNullOrEmpty(this.userProfileData.Country)) {
                    profileDataMissing = true;
                }
            }
        }
        return profileDataMissing;
    }

    isPropertyNullOrEmpty(propertyValue) {
        if (propertyValue) {
            if (propertyValue === '') {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    privacyPolicyClicked() {
        var win = window.open(
            'https://www.weyerhaeuser.com/privacy/privacy-policy/',
            '_blank'
        );
        win.focus();
    }
}
