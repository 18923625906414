// app\services\materialsManagementService.js

export default class materialsManagementService {
    constructor(common, postService) {
        'ngInject';
        this.$q = common.$q();
        this.postService = postService;

        this.httpRequestQueueCounter = 0;
        this.inputQueuePromise = null;
        this.somethingWaitingOnQueueToFinish = false;
    }

    getQueueCounter() {
        this.inputQueuePromise = this.$q.defer();
        if (this.httpRequestQueueCounter === 0)
        {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.somethingWaitingOnQueueToFinish = true;
        }
        return this.inputQueuePromise.promise;
    }

    getMatManData() {
        return this.requestMMGetEvent('GetMaterialsManagementData');
    }

    getMatManBlacklist() {
        return this.requestMMGetEvent('GetMaterialsManagementBlacklist');
    }

    postModifyProductGroupEnabled(groupID, enabled) {
        var data = {'pgID':groupID,'pgEnabled':enabled};
        return this.requestMMPostEvent('ModifyProductGroupEnabled', data);
    }

    postToggleProductSeriesEnabled(seriesID) {
        var data = {'data':seriesID};
        return this.requestMMPostEvent('ToggleProductSeriesEnabled', data);
    }

    postToggleProductEnabled(productID) {
        var data = {'data':productID};
        return this.requestMMPostEvent('ToggleProductEnabled', data);
    }

    requestMMGetEvent(mmEvent) {
        return this.postService.requestGetEvent(mmEvent, 'api/MaterialsManagement/');
    }

    requestMMPostEvent(mmEvent, mmData) {
        var path = 'api/MaterialsManagement/';
        return this.postService.requestPostEvent(mmEvent, mmData, path);
    }

}
// // app\services\materialsManagementService.js

// (function () {
//     'use strict';
//     var app = angular.module('app');
//     var serviceId = 'materialsManagementService';

//     app.factory(serviceId, ['$http', 'ngAuthSettings', 'common', 'allModelDataService', 'postService',  materialsManagementService]);

//     function materialsManagementService($http, ngAuthSettings, common, allModelDataService, postService) {
//         var $q = common.$q;
//         var serviceBase = ngAuthSettings.apiServiceBaseUri;
//         var httpRequestQueueCounter = 0;
//         var inputQueuePromise;
//         var somethingWaitingOnQueueToFinish = false;
//         var service = {
//             getMatManData: getMatManData,
//             getMatManBlacklist: getMatManBlacklist,
//             postModifyProductGroupEnabled: postModifyProductGroupEnabled,
//             postToggleProductSeriesEnabled: postToggleProductSeriesEnabled,
//             postToggleProductEnabled: postToggleProductEnabled
//         };

//         function getQueueCounter() {
//             inputQueuePromise = $q.defer();
//             if (httpRequestQueueCounter === 0)
//             {
//                 inputQueuePromise.resolve();
//                 return inputQueuePromise.promise;
//             }
//             else {
//                 somethingWaitingOnQueueToFinish = true;
//             }
//             return inputQueuePromise.promise;
//         }

//         function getMatManData() {
//             return requestMMGetEvent('GetMaterialsManagementData');
//         }

//         function getMatManBlacklist() {
//             return requestMMGetEvent('GetMaterialsManagementBlacklist');
//         }

//         function postModifyProductGroupEnabled(groupID, enabled) {
//             var data = {'pgID':groupID,'pgEnabled':enabled};
//             return requestMMPostEvent('ModifyProductGroupEnabled', data);
//         }

//         function postToggleProductSeriesEnabled(seriesID) {
//             var data = {'data':seriesID};
//             return requestMMPostEvent('ToggleProductSeriesEnabled', data);
//         }

//         function postToggleProductEnabled(productID) {
//             var data = {'data':productID};
//             return requestMMPostEvent('ToggleProductEnabled', data);
//         }

//         function requestMMGetEvent(mmEvent) {
//             return postService.requestGetEvent(mmEvent, 'api/MaterialsManagement/');
//         }

//         function requestMMPostEvent(mmEvent, mmData) {
//             var path = 'api/MaterialsManagement/';
//             return postService.requestPostEvent(mmEvent, mmData, path);
//         }

//         return service;
//     }

// }());
