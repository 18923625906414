export default class loadsHorizZService {
    constructor ($http, common, allModelDataService, postService) {
        'ngInject';

        this.$q = common.$q();
        this.$http = $http;
        this.allModelDataService = allModelDataService;
        this.postService = postService;
        this.httpRequestQueueCounter = 0;
        this.inputQueuePromise;
        this.somethingWaitingOnQueueToFinish = false;
    }

    modifyLoadType(viewPosition, loadTypeEnumInt) {
        var data = {
            'viewPosition': viewPosition,
            'integerValue': loadTypeEnumInt
        };
        return this._requestPostEvent('ModifyLoadType', data);
    }

    addLateralLoad(viewPosition) {
        var data = {
            'viewPosition': viewPosition
        };
        return this._requestPostEvent('AddLateralLoad', data);
    }

    deleteLoad(viewPosition) {
        var data = {
            'viewPosition': viewPosition
        };
        return this._requestPostEvent('DeleteLateralLoad', data);
    }

    saveLoadAsDefault() {
        var currentPostResponseData = this.allModelDataService.getPostResponseData();
        return this._requestPostEvent('SaveLoadsAsDefaults', currentPostResponseData);
    }

    modifyTribWidth(viewPosition, value) {
        var data = {
            'viewPosition': viewPosition,
            'newValue': value
        };
        return this._requestPostEvent('ModifyTributaryWidth', data);
    }

    modifyLoadComment(viewPosition, value) {
        var data = {
            'viewPosition': viewPosition,
            'newValue': value
        };
        return this._requestPostEvent('ModifyLoadComment', data);
    }


    modifyWindLoadMagnitude(viewPosition, value) {
        var data = {
            'viewPosition': viewPosition,
            'newValue': value
        };
        return this._requestPostEvent('ModifyWindLoadMagnitude', data);
    }

    getSupplementalVPData(viewPosition) {
        var data = {
            'viewPosition': viewPosition
        };
        return this._requestPostEvent('GetSupplementalVPData', data);
    }

    getMetaData() {
        var data = {};
        return this._requestPostEvent('GetMetaData', data);
    }

    _requestPostEvent(event, data) {
        var path = 'api/LoadsHorizontalZ/';
        return this.postService.requestPostEvent(event, data, path);
    }
    _requestMemberSwitchSafePostEvent(event, data) {
        var path = 'api/LoadsHorizontalZ/';
        return this.postService.requestPostMemberSwitchSafe(event, data, path);
    }
}
// (function() {
//     'use strict';
//     var app = angular.module('app');
//     var serviceId = 'loadsHorizZService';
//     app.factory(serviceId, ['$http', 'ngAuthSettings', 'common', 'allModelDataService', 'postService', loadsHorizYService]);

//     function loadsHorizYService($http, ngAuthSettings, common, allModelDataService, postService) {
//         var $q = common.$q;
//         var serviceBase = ngAuthSettings.apiServiceBaseUri;
//         var httpRequestQueueCounter = 0;
//         var inputQueuePromise;
//         var somethingWaitingOnQueueToFinish = false;

//         //this is the declaration of the public methods that will be accessible
//         var service = {
//             getMetaData: getMetaData,
//             addLateralLoad: addLateralLoad,
//             deleteLoad: deleteLoad,
//             getSupplementalVPData: getSupplementalVPData,
//             modifyLoadComment: modifyLoadComment,
//             modifyWindLoadMagnitude: modifyWindLoadMagnitude,
//             modifyLoadType: modifyLoadType,
//             modifyTribWidth: modifyTribWidth
//         };

//         function modifyLoadType(viewPosition, loadTypeEnumInt) {
//             var data = {
//                 'viewPosition': viewPosition,
//                 'integerValue': loadTypeEnumInt
//             };
//             return _requestPostEvent('ModifyLoadType', data);
//         }

//         function addLateralLoad(viewPosition) {
//             var data = {
//                 'viewPosition': viewPosition
//             };
//             return _requestPostEvent('AddLateralLoad', data);
//         }

//         function deleteLoad(viewPosition) {
//             var data = {
//                 'viewPosition': viewPosition
//             };
//             return _requestPostEvent('DeleteLateralLoad', data);
//         }

//         function saveLoadAsDefault() {
//             var currentPostResponseData = allModelDataService.getPostResponseData();
//             return _requestPostEvent('SaveLoadsAsDefaults', currentPostResponseData);
//         }

//         function modifyTribWidth(viewPosition, value) {
//             var data = {
//                 'viewPosition': viewPosition,
//                 'newValue': value
//             };
//             return _requestPostEvent('ModifyTributaryWidth', data);
//         }

//         function modifyLoadComment(viewPosition, value) {
//             var data = {
//                 'viewPosition': viewPosition,
//                 'newValue': value
//             };
//             return _requestPostEvent('ModifyLoadComment', data);
//         }


//         function modifyWindLoadMagnitude(viewPosition, value) {
//             var data = {
//                 'viewPosition': viewPosition,
//                 'newValue': value
//             };
//             return _requestPostEvent('ModifyWindLoadMagnitude', data);
//         }

//         function getSupplementalVPData(viewPosition) {
//             var data = {
//                 'viewPosition': viewPosition
//             };
//             return _requestPostEvent('GetSupplementalVPData', data);
//         }

//         function getMetaData() {
//             var data = {};
//             return _requestPostEvent('GetMetaData', data);
//         }

//         function _requestPostEvent(event, data) {
//             var path = 'api/LoadsHorizontalZ/';
//             return postService.requestPostEvent(event, data, path);
//         }
//         function _requestMemberSwitchSafePostEvent(event, data) {
//             var path = 'api/LoadsHorizontalZ/';
//             return postService.requestPostMemberSwitchSafe(event, data, path);
//         }

//         return service;
//     }
// }());
