export default class materialsManagementController {
    constructor($rootScope, $state, resourceMessagingService, allModelDataService,
        smartNavService, spinner, materialsManagementService, materialAvailabilityService, forteCookieService) {
        'ngInject';

        this.$rootScope = $rootScope;
        this.$state = $state;
        this.resourceMessagingService = resourceMessagingService;
        this.allModelDataService = allModelDataService;
        this.smartNavService = smartNavService;
        this.spinner = spinner;
        this.materialsManagementService = materialsManagementService;
        this.materialAvailabilityService = materialAvailabilityService;
        this.forteCookieService = forteCookieService;

        this.currentTeamId = '';
        this.pendingDataRetrieval = false;
        this.selectedProductGroupID = -1;
        this.productGroups = [];
        this.productSeries = [];

        this.displayMaterialAvailability = true;
        this.postalRegex = '(\\d{5}?|[A-Za-z]\\d[A-Za-z] ?\\d[A-Za-z]\\d)';
        this.currentPostalCode = '54327';

        this.showBackButton = true;
        // this.backToJobSetting = backToJobSetting();
        // this.selectProductGroup = selectProductGroup();
        // this.modifyProductGroupEnabled = modifyProductGroupEnabled;
        // this.toggleProductSeriesEnabled = toggleProductSeriesEnabled;
        // this.toggleProductEnabled = toggleProductEnabled;
        // this.getText = getText;
        // this.setActionBtn = setActionBtn;

        // vm.toggleProductSeriesRow = toggleProductSeriesRow;
        // vm.productSeriesRowCollapsed = productSeriesRowCollapsed;
        this.collapsedProductSeriesRows = [];

        angular.element(document).ready(() => {
            this.closeallcollapses();
        });

        this.activate();
    }

    activate() {
        this.selectedProductGroupID = 1;
        this.currentTeamId = this.$state.params.currentTeamId;
        this.spinner.spinnerShow(this.getText('jobSettingsResetProcessing'));
        this.displayMaterialAvailability = this.forteCookieService.getMaterialAvailabilityEnabled();
        this.refreshMMdata(true);
    }

    postalFinished(isValid) {
        if (isValid === true) {
            this.materialAvailabilityService.updatePostalCode(this.currentPostalCode).then(
                (materialAvailabilityResponse) => {
                    this.seriesUnavailable = materialAvailabilityResponse.seriesUnavailable;
                    this.seriesSpecialOrder = materialAvailabilityResponse.seriesSpecialOrder;
                    this.productsNotAvailable = Object.keys(materialAvailabilityResponse.productsNotAvailable).map(function(id) {
                        return Number(id);
                    });
                    this.productsSpecialOrder = Object.keys(materialAvailabilityResponse.productsSpecialOrder).map(function(id) {
                        return Number(id);
                    });
                    this.currentPostalCode = materialAvailabilityResponse.postalCode;
                }
            );
        }
    }

    toggleProductSeriesRow(prodSeriesID) {
        if (this.collapsedProductSeriesRows.indexOf(prodSeriesID) > -1) {
            this.collapsedProductSeriesRows.splice(this.collapsedProductSeriesRows.indexOf(prodSeriesID), 1);
        } else {
            this.collapsedProductSeriesRows.push(prodSeriesID);
        }
    }

    productSeriesRowCollapsed(prodSeriesID) {
        return (this.collapsedProductSeriesRows.indexOf(prodSeriesID) > -1);
    }

    resetCollapsedRows() {
        this.collapsedProductSeriesRows = [];
        this.productSeries.forEach((prodSeries) => {
            this.collapsedProductSeriesRows.push(prodSeries.ProductSeriesID);
        });
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    prepareMatManData(matManData) {
        this.productGroups = matManData.ProductGroups;
        this.pendingDataRetrieval = false;

        var prodGrpIndex = this.getArrayIndexForProductGroup(this.selectedProductGroupID);
        this.productSeries = this.productGroups[prodGrpIndex].ProductSeries;
    }

    selectProductGroup(prodGrpID) {
        this.selectedProductGroupID = prodGrpID;
        this.ischecked = false;
        this.prepareMatManProductSeries();
        this.resetCollapsedRows();
        var $matrialAccordion = $('#productpanel');
        $matrialAccordion.find('.collapse.in').collapse('hide');
    }

    modifyProductGroupEnabled(groupID, enabled) {
        this.materialsManagementService.postModifyProductGroupEnabled(groupID, enabled).then(() => {
            this.refreshMMdata();
        });
    }

    toggleProductSeriesEnabled(seriesID, event) {
        event.stopPropagation();
        this.materialsManagementService.postToggleProductSeriesEnabled(seriesID).then(() => {
            this.refreshMMdata();
        });
    }

    toggleProductEnabled(prodID) {
        this.materialsManagementService.postToggleProductEnabled(prodID).then(() => {
            this.refreshMMdata();
        });
    }

    refreshMMdata(resetCollapsed) {
        this.pendingDataRetrieval = true;
        this.materialAvailabilityService.getMaterialAvailabilityAndPostalDataForMM().then(
            (materialAvailabilityResponse) => {
                this.seriesUnavailable = materialAvailabilityResponse.seriesUnavailable;
                this.seriesSpecialOrder = materialAvailabilityResponse.seriesSpecialOrder;
                this.productsNotAvailable = Object.keys(materialAvailabilityResponse.productsNotAvailable).map(function(id) {
                    return Number(id);
                });
                this.productsSpecialOrder = Object.keys(materialAvailabilityResponse.productsSpecialOrder).map(function(id) {
                    return Number(id);
                });
                this.currentPostalCode = materialAvailabilityResponse.postalCode;
                this.materialsManagementService.getMatManData().then((mmData) => {
                    this.allModelDataService.setProductBlacklist(mmData.MaterialBlacklist);
                    this.prepareMatManData(mmData.MaterialsManagementData);
                    if (resetCollapsed) { this.resetCollapsedRows(); }
                    this.spinner.spinnerHide();
                    this.getAllproductSeriseStatuse();
                });
            });
    }

    prepareMatManProductSeries() {
        var targetProdGroupIndex = this.getArrayIndexForProductGroup(this.selectedProductGroupID);
        this.productSeries = this.productGroups[targetProdGroupIndex].ProductSeries;
        this.getAllproductSeriseStatuse();
    }

    getArrayIndexForProductGroup(prodGroupID) {
        var currentIndex = -1;
        var targetIndex = -1;
        this.productGroups.forEach((prodGrp) => {
            currentIndex++;
            if (prodGrp.ProductGroupID === prodGroupID) {
                targetIndex = currentIndex;
            }
        });

        return targetIndex;
    }

    getArrayIndexForProductSeries(allProdSeries, prodSeriesID) {
        var currentIndex = -1;
        var targetIndex = -1;
        allProdSeries.forEach((prodSeries) => {
            currentIndex++;
            if (prodSeries.ProductSeriesID === prodSeriesID) {
                targetIndex = currentIndex;
            }
        });
        return targetIndex;
    }

    backToJobSetting() {
        var toState = this.$state.params.fromState;

        if (toState === '') {
            //forcing the user back to the Job Node in the tree (to prevent refresh issues if the user was on the prod sel tab)
            //this must be cleared out to prevent the tree from restoring to it's previous state.
            this.allModelDataService.clearSelectedJobTreeNode();
            this.$rootScope.$broadcast('projectReady', {forgetState: false});
            this.smartNavService.navigateOrReload('application.job.jobSummary');
        } else if (toState.indexOf('application.job.') > -1) {
            //forcing the user back to the Job Node in the tree (to prevent refresh issues if the user was on the prod sel tab)
            //this must be cleared out to prevent the tree from restoring to it's previous state.
            this.allModelDataService.clearSelectedJobTreeNode();
            this.$rootScope.$broadcast('projectReady', {forgetState: false});
            this.smartNavService.navigateOrReload('application.job.jobSummary');
        } else if (toState === 'application.manage.team' || toState === 'application.manage.team.recent' || toState === 'application.manage.teamInfo') {
            this.$state.go(toState, {teamId: this.currentTeamId});
        } else {
            this.smartNavService.navigateOrReload(toState);
        }

        if (this.rename) {
            this.rename = false;
            this.$rootScope.$broadcast('refreshJobTree', {forgetState: false});
        }
    }

    closeallcollapses() {
        var $matrialAccordion = $('#productpanel');
        $matrialAccordion.on('show.bs.collapse', '.collapse', () => {
            $matrialAccordion.find('.collapse.in').collapse('hide');
        });

    }

    getAllproductSeriseStatuse()
    {
        var allselected = true;
        //eslint-disable-next-line eqeqeq
        var selectedobject = this.productGroups.find((obj) => { return obj.ProductGroupID == this.selectedProductGroupID; });
        var selectedProduct = this.productGroups[this.productGroups.indexOf(selectedobject)];
        var productSeriesInProduct = selectedProduct.ProductSeries;
        for (var i = 0; i < productSeriesInProduct.length; i++)
        {
            //eslint-disable-next-line eqeqeq
            if (productSeriesInProduct[i].Enabled == 1)
            {
                continue;
            }
            else
            {
                allselected = false;
            }
        }
        if (allselected === true)
        {
            this.ischecked = true;
            return true;
        }
        this.ischecked = false;
        return false;
    }

    isMixedAvailability (products) {
        var result = false;

        for (var i = 0; i < products.length; i++) {
            var product = products[i];
            if (
                (this.productsSpecialOrder && this.productsSpecialOrder.indexOf(product.ProductID) !== -1) ||
                (this.productsNotAvailable && this.productsNotAvailable.indexOf(product.ProductID) !== -1)
            ) {
                result = true;
                break;
            }
        }
        return result;
    };
}
// (function () {
//     'use strict';
//     var app = angular.module('app');
//     var controllerId = 'materialsManagementController';

//     app.controller(controllerId,
//         ['$scope', '$rootScope', '$state', 'ngAuthSettings', 'uiTourService', 'resourceMessagingService', 'allModelDataService', 'smartNavService', 'spinner', 'materialsManagementService', materialsManagementController]);

//     function materialsManagementController($scope, $rootScope, $state, ngAuthSettings, uiTourService, resourceMessagingService, allModelDataService, smartNavService, spinner, materialsManagementService) {
//         var vm = this;

//         vm.currentTeamId = '';

//         vm.pendingDataRetrieval = false;
//         vm.ischecked = false;

//         vm.selectedProductGroupID = -1;
//         vm.productGroups = [];
//         vm.productSeries = [];

//         vm.backToJobSetting = backToJobSetting;
//         vm.showBackButton = true;

//         vm.selectProductGroup = selectProductGroup;

//         vm.getAllproductSeriseStatuse = getAllproductSeriseStatuse;

//         vm.modifyProductGroupEnabled = modifyProductGroupEnabled;
//         vm.toggleProductSeriesEnabled = toggleProductSeriesEnabled;
//         vm.toggleProductEnabled = toggleProductEnabled;
//         vm.getText = getText;
//         vm.setActionBtn = setActionBtn;

//         vm.toggleProductSeriesRow = toggleProductSeriesRow;
//         vm.productSeriesRowCollapsed = productSeriesRowCollapsed;
//         vm.collapsedProductSeriesRows = [];
//         var materialsManagerTour = uiTourService.getTourByName('materialsManagerTour');
//         materialsManagerTour.previousBtn = getText('prev');
//         materialsManagerTour.nextBtn = getText('laNext');
//         materialsManagerTour.endTourBtn = getText('endTour');

//         activate();

//         function activate() {
//             vm.selectedProductGroupID = 1;
//             vm.currentTeamId = $state.params.currentTeamId;
//             spinner.spinnerShow(getText('jobSettingsResetProcessing'));
//             refreshMMdata(true);
//         }

//         function setActionBtn(prev, next) {
//             materialsManagerTour.prevDisabled = prev;
//             materialsManagerTour.nextDisabled = next;
//         }

//         function toggleProductSeriesRow(prodSeriesID) {
//             if (vm.collapsedProductSeriesRows.indexOf(prodSeriesID) > -1) {
//                 vm.collapsedProductSeriesRows.splice(vm.collapsedProductSeriesRows.indexOf(prodSeriesID), 1);
//             } else {
//                 vm.collapsedProductSeriesRows.push(prodSeriesID);
//             }
//         }

//         function productSeriesRowCollapsed(prodSeriesID) {
//             return (vm.collapsedProductSeriesRows.indexOf(prodSeriesID) > -1);
//         }

//         function resetCollapsedRows() {
//             vm.collapsedProductSeriesRows = [];
//             vm.productSeries.forEach(function (prodSeries) {
//                 vm.collapsedProductSeriesRows.push(prodSeries.ProductSeriesID);
//             });
//         }

//         function getText(key) {
//             return resourceMessagingService.getText(key);
//         }

//         function prepareMatManData(matManData) {
//             vm.productGroups = matManData.ProductGroups;
//             vm.pendingDataRetrieval = false;

//             var prodGrpIndex = getArrayIndexForProductGroup(vm.selectedProductGroupID);
//             vm.productSeries = vm.productGroups[prodGrpIndex].ProductSeries;
//         }
//         function getAllproductSeriseStatuse()
//         {
//             var allselected = true;
//             var selectedobject = vm.productGroups.find(function (obj) { return obj.ProductGroupID ==  vm.selectedProductGroupID; });
//             var selectedProduct =vm.productGroups[vm.productGroups.indexOf(selectedobject)];
//             var productSeriesInProduct = selectedProduct.ProductSeries;
//             for(var i =0; i<productSeriesInProduct.length; i++)
//             {
//                 if (productSeriesInProduct[i].Enabled == 1)
//                 {
//                     continue;
//                 }
//                 else
//                 {
//                     allselected = false;
//                 }
//             }
//             if (allselected == true)
//             {
//                 vm.ischecked = true;
//                 return true;
//             }
//             vm.ischecked = false;
//             return false;
//         }

//         function selectProductGroup(prodGrpID) {
//             vm.selectedProductGroupID = prodGrpID;
//             vm.ischecked = false;
//             prepareMatManProductSeries();
//             resetCollapsedRows();
//             var $matrialAccordion = $('#productpanel');
//             $matrialAccordion.find('.collapse.in').collapse('hide');

//         }

//         function modifyProductGroupEnabled(groupID, enabled) {
//             materialsManagementService.postModifyProductGroupEnabled(groupID, enabled).then(function () {
//                 refreshMMdata();
//             });
//         }

//         function toggleProductSeriesEnabled(seriesID, event) {
//             event.stopPropagation();
//             materialsManagementService.postToggleProductSeriesEnabled(seriesID).then(function () {
//                 refreshMMdata();
//             });
//         }

//         function toggleProductEnabled(prodID) {
//             materialsManagementService.postToggleProductEnabled(prodID).then(function () {
//                 refreshMMdata();
//             });
//         }

//         function refreshMMdata(resetCollapsed) {
//             vm.pendingDataRetrieval = true;
//             materialsManagementService.getMatManData().then(function (mmData) {
//                 allModelDataService.setProductBlacklist(mmData.MaterialBlacklist);
//                 prepareMatManData(mmData.MaterialsManagementData);
//                 if (resetCollapsed) { resetCollapsedRows(); }
//                 spinner.spinnerHide();
//                 vm.getAllproductSeriseStatuse();
//             });
//         }

//         function prepareMatManProductSeries() {
//             var targetProdGroupIndex = getArrayIndexForProductGroup(vm.selectedProductGroupID);
//             vm.productSeries = vm.productGroups[targetProdGroupIndex].ProductSeries;
//             vm.getAllproductSeriseStatuse()
//         }

//         function getArrayIndexForProductGroup(prodGroupID) {
//             var currentIndex = -1;
//             var targetIndex = -1;
//             vm.productGroups.forEach(function (prodGrp) {
//                 currentIndex++;
//                 if (prodGrp.ProductGroupID === prodGroupID) {
//                     targetIndex = currentIndex;
//                 }
//             });

//             return targetIndex;
//         }

//         function getArrayIndexForProductSeries(allProdSeries, prodSeriesID) {
//             var currentIndex = -1;
//             var targetIndex = -1;
//             allProdSeries.forEach(function (prodSeries) {
//                 currentIndex++;
//                 if (prodSeries.ProductSeriesID === prodSeriesID) {
//                     targetIndex = currentIndex;
//                 }
//             });
//             return targetIndex;
//         }

//         function backToJobSetting() {
//             var toState = $state.params.fromState;

//             if (toState === '') {
//                 //forcing the user back to the Job Node in the tree (to prevent refresh issues if the user was on the prod sel tab)
//                 //this must be cleared out to prevent the tree from restoring to it's previous state.
//                 allModelDataService.clearSelectedJobTreeNode();
//                 $rootScope.$broadcast('projectReady', { forgetState: false });
//                 smartNavService.navigateOrReload('application.job.jobSummary');
//             } else if (toState.indexOf('application.job.') > -1) {
//                 //forcing the user back to the Job Node in the tree (to prevent refresh issues if the user was on the prod sel tab)
//                 //this must be cleared out to prevent the tree from restoring to it's previous state.
//                 allModelDataService.clearSelectedJobTreeNode();
//                 $rootScope.$broadcast('projectReady', { forgetState: false });
//                 smartNavService.navigateOrReload('application.job.jobSummary');
//             } else if (toState === 'application.manage.team' || toState === 'application.manage.team.recent' || toState === 'application.manage.teamInfo') {
//                 $state.go(toState, { teamId: vm.currentTeamId });
//             } else {
//                 smartNavService.navigateOrReload(toState);
//             }

//             if (vm.rename) {
//                 vm.rename = false;
//                 $rootScope.$broadcast('refreshJobTree', { forgetState: false });
//             }

//             if (materialsManagerTour.getStatus() === 1) {
//                 materialsManagerTour.end();
//             }
//         }

//         function closeallcollapses() {
//             var $matrialAccordion = $('#productpanel');
//             $matrialAccordion.on('show.bs.collapse', '.collapse', function () {
//                 $matrialAccordion.find('.collapse.in').collapse('hide');
//             });

//         }
//         angular.element(document).ready(function () {
//             closeallcollapses();
//         });
//     }
// }());
