export default class loadsVertYService {

    constructor (allModelDataService, postService) {
        'ngInject';

        this.allModelDataService = allModelDataService;
        this.postService = postService;
    }

    getSessionLoadData() {
        var prData = this.allModelDataService.getPostResponseData();
        return prData.MemberData.VerticalYAxisLoadData;
    }

    getJobSettingsData() {
        var prData = this.allModelDataService.getPostResponseData();
        return prData.ApplicationData.JobSettingsData;
    }

    getTransferableReactionData(data) {
        return this._requestTransferReactionsPostEvent('GetTransferableReactionDataForMemberIdTag', data);
    }

    addNewTransferredReaction(data) {
        return this._requestTransferReactionsSafePostEvent('AddNewTransferredReaction', data);
    }

    updateTransferredReaction(data) {
        return this._requestTransferReactionsSafePostEvent('UpdateTransferredReaction', data);
    }

    modifyLoadType(viewPosition, loadTypeEnumInt) {
        var data = {
            'viewPosition': viewPosition,
            'integerValue': loadTypeEnumInt
        };
        return this._requestPostEvent('ModifyLoadType', data);
    }

    modifyApplyEccentricity(newValue) {
        var data = {
            'value': newValue
        };
        return this._requestMemberSwitchSafePostEvent('ModifyVerticalEccentricityType', data);
    }

    modifyTributaryWidth(newValue) {
        var data = {
            'value': newValue
        };
        return this._requestMemberSwitchSafePostEvent('ModifyTributaryWidth', data);
    }

    addLoad(viewPosition) {
        var data = {
            'viewPosition': viewPosition
        };
        return this._requestPostEvent('AddLoad', data);
    }

    copyLoad(viewPosition) {
        var data = {
            'viewPosition': viewPosition
        };
        return this._requestPostEvent('CopyLoad', data);
    }

    deleteLoad(viewPosition) {
        var data = {
            'viewPosition': viewPosition
        };
        return this._requestPostEvent('DeleteLoad', data);
    }

    saveLoadAsDefault() {
        var currentPostResponseData = this.allModelDataService.getPostResponseData();
        return this._requestPostEvent('SaveLoadsAsDefaults', currentPostResponseData);
    }

    modifyLoadComment(viewPosition, value) {
        var data = {
            'viewPosition': viewPosition,
            'newValue': value
        };
        return this._requestPostEvent('ModifyLoadComment', data);
    }

    modifyDeadLoadMagnitude(viewPosition, value) {
        var data = {
            'viewPosition': viewPosition,
            'newValue': value
        };
        return this._requestPostEvent('ModifyDeadLoadMagnitude', data);
    }

    modifyFloorLoadMagnitude(viewPosition, value) {
        var data = {
            'viewPosition': viewPosition,
            'newValue': value
        };
        return this._requestPostEvent('ModifyFloorLoadMagnitude', data);
    }

    modifyRoofLoadMagnitude(viewPosition, value) {
        var data = {
            'viewPosition': viewPosition,
            'newValue': value
        };
        return this._requestPostEvent('ModifyRoofLoadMagnitude', data);
    }

    modifySnowLoadMagnitude(viewPosition, value) {
        var data = {
            'viewPosition': viewPosition,
            'newValue': value
        };
        return this._requestPostEvent('ModifySnowLoadMagnitude', data);
    }

    modifyWindLoadMagnitude(viewPosition, value) {
        var data = {
            'viewPosition': viewPosition,
            'newValue': value
        };
        return this._requestPostEvent('ModifyWindLoadMagnitude', data);
    }

    modifySeismicLoadMagnitude(viewPosition, value) {
        var data = {
            'viewPosition': viewPosition,
            'newValue': value
        };
        return this._requestPostEvent('ModifySeismicLoadMagnitude', data);
    }

    getSupplementalVPData(viewPosition) {
        var data = {
            'viewPosition': viewPosition
        };
        return this._requestPostEvent('GetSupplementalVPData', data);
    }

    getMetaData() {
        var data = {};
        return this._requestPostEvent('GetMetaData', data);
    }

    _requestPostEvent(event, data) {
        var path = 'api/LoadsVerticalY/';
        return this.postService.requestPostEvent(event, data, path);
    }

    _requestMemberSwitchSafePostEvent(event, data) {
        var path = 'api/LoadsVerticalY/';
        return this.postService.requestPostMemberSwitchSafe(event, data, path);
    }

    _requestTransferReactionsPostEvent(event, data) {
        var path = 'api/TransferReactions/';
        return this.postService.requestPostEvent(event, data, path);
    }

    _requestTransferReactionsSafePostEvent(event, data) {
        var path = 'api/TransferReactions/';
        return this.postService.requestPostMemberSwitchSafe(event, data, path);
    }
}
