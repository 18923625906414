export const ngAuthSettings = {
    'apiServiceBaseUri': 'http://localhost/ForteWebApi/'
};
export default ngAuthSettings;
// (function () {

//     var app = angular.module('app');

//     var serviceBase = 'http://localhost/ForteWebApi/';

//     app.constant('ngAuthSettings', {
//         apiServiceBaseUri: serviceBase,
//         clientId: 'ngAuthApp'
//     });
// }());
