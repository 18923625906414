export default class common {
    constructor($q) {
        'ngInject';
        this._$q = $q;
    }

    $q() {
        return this._$q;
    }
}
