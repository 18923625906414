export default class seismicLoadingService {
    constructor ($http, common, allModelDataService, postService) {
        'ngInject';

        this.$q = common.$q();
        this.$http = $http;
        this.allModelDataService = allModelDataService;
        this.postService = postService;
        this.httpRequestQueueCounter = 0;
        this.inputQueuePromise;
        this.somethingWaitingOnQueueToFinish = false;
    }

    saveAsDefaults() {
        var data = {};
        return this._requestPostEvent('SaveJobSeismicLoadingAsDefaults', data);
    }

    seismicOmegaChanged(newOmega) {
        var data = {
            value: newOmega
        };
        return this._requestPostEvent('ModifyOverStrengthOmega', data);
    }

    seismicSDSChanged(newSDS) {
        var data = {
            value: newSDS
        };
        return this._requestPostEvent('ModifyOverStrengthSDS', data);
    }

    seismicCategoryChanged(newCategory) {
        var data = {
            value: newCategory
        };
        return this._requestPostEvent('ModifyDesignCategory', data);
    }

    _requestPostEvent(seismicEvent, seismicData) {
        var path = 'api/JobSeismicLoading/';
        return this.postService.requestPostEvent(seismicEvent, seismicData, path);
    }

    selectAllTextOnFocus($event) {
        $event.target.select();
    }

    preventNonNumericInputs(keyPressEvent) {
        if (keyPressEvent.which  === 8 || keyPressEvent.which  === 0) {
            // backspace, null
            return;
        }
        if ((keyPressEvent.which < 48 || keyPressEvent.which > 57) && keyPressEvent.which !== 46) {
            // numbers 0-9 and '.' OK
            keyPressEvent.preventDefault();
        }
    }
}
