export default class dragDrop {
    constructor() {
        this.restrict = 'A',
        this.scope = {
            draggable: '=draggable',
            dropped: '&',
            list: '=list'
        };
    }
    controller($scope, $timeout, $window, $document, dragHelper) {
        'ngInject';
        $scope.$timeout = $timeout;
        $scope.$window = $window;
        $scope.$document = $document;
        $scope.dragHelper = dragHelper;
    }

    link(scope, element, attrs) {
        var pos,
            placeholder,
            eventObj,
            dragElm,
            elements,
            body = document.body,
            html = document.documentElement,
            documentHeight,
            documentWidth,
            scrollContainerElm,
            targetElm,
            dragInfo;

        var dragStart = (event) => {
            //Disable right click.
            if ((event.button === 2 || event.which === 3)) {
                return;
            }
            eventObj = scope.dragHelper.eventObj(event);
            event.preventDefault();
            //Setting drag info properties and methods in scope of node being moved.
            scope.dragInfo = scope.dragHelper.dragInfo();
            var movedObjIndex;
            if (eventObj.target.id === '')
            {
                movedObjIndex = scope.list.map((e) => { return e.id; }).indexOf(parseInt(eventObj.target.parentElement.id));
            }
            else {
                movedObjIndex = scope.list.map((e) => { return e.id; }).indexOf(parseInt(eventObj.target.id));
            }
            dragInfo.setFromIndex(movedObjIndex);

            // create placeholder element
            placeholder = angular.element(scope.$window.document.createElement('li'))
                .addClass('angular-ui-tree-placeholder');
            pos = scope.dragHelper.positionStarted(eventObj, element);
            placeholder.css('height', element.prop('offsetHeight') + 'px');

            dragElm = angular.element(scope.$window.document.createElement('div'));

            dragElm = dragElm.addClass('listItemDrag');
            dragElm.css('width', element.prop('scrollWidth') + 'px');
            dragElm.css('height', element.prop('offsetHeight') + 'px');
            dragElm.css('z-index', 9999);
            element.after(placeholder);
            dragElm.append(element);

            scope.$document.find('body').append(dragElm);
            dragElm.css({
                'left': eventObj.pageX - pos.offsetX + 'px',
                'top': eventObj.pageY - pos.offsetY + 'px'
            });
            elements = {
                placeholder: placeholder,
                dragging: dragElm
            };
            /*eslint-disable no-use-before-define */
            bindDragMoveEvents();
            /*eslint-enable no-use-before-define */

            scope.$apply(() => {

            });

            //Get bounds of document.
            documentHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
            documentWidth = Math.max(body.scrollWidth, body.offsetWidth, html.clientWidth, html.scrollWidth, html.offsetWidth);
        };

        var dragDelay = (() => {
            var to;

            return {
                exec: (fn, ms) => {
                    if (!ms) {
                        ms = 0;
                    }
                    this.cancel();
                    to = scope.$timeout(fn, ms);
                },
                cancel: () => {
                    scope.$timeout.cancel(to);
                }
            };
        });

        /*eslint-disable complexity */
        var dragMoveEvent = (event) => {
            var leftElmPos,
                topElmPos,
                topScroll,
                bottomScroll,
                scrollDownBy,
                scrollUpBy,
                targetX,
                targetY,
                displayElm,
                targetOffset,
                targetBeforeBuffer,
                targetBefore;

            eventObj = scope.dragHelper.eventObj(event);
            if (dragElm) {
                event.preventDefault();
                // Get top left positioning of element being moved
                leftElmPos = eventObj.pageX - pos.offsetX;
                topElmPos = eventObj.pageY - pos.offsetY;

                // dragElm can't leave the screen on the left
                if (leftElmPos < 0) {
                    leftElmPos = 0;
                }

                // dragElm can't leave the screen on the top
                if (topElmPos < 0) {
                    topElmPos = 0;
                }

                // dragElm can't leave the screen on the bottom
                if ((topElmPos + 10) > documentHeight) {
                    topElmPos = documentHeight - 10;
                }

                // dragElm can't leave the screen on the right
                if ((leftElmPos + 10) > documentWidth) {
                    leftElmPos = documentWidth - 10;
                }

                // updating element being moved css
                dragElm.css({
                    'left': leftElmPos + 'px',
                    'top': topElmPos + 'px'
                });

                // call service to update position coordinates based on move
                scope.dragHelper.positionMoved(event, pos);

                // Setting X point for elementFromPoint.
                targetX = eventObj.pageX - (scope.$window.pageXOffset ||
                        scope.$window.document.body.scrollLeft ||
                        scope.$window.document.documentElement.scrollLeft) -
                    (scope.$window.document.documentElement.clientLeft || 0);

                targetY = eventObj.pageY - (scope.$window.pageYOffset ||
                        scope.$window.document.body.scrollTop ||
                        scope.$window.document.documentElement.scrollTop) -
                    (scope.$window.document.documentElement.clientTop || 0);

                // Set target element (element in specified x/y coordinates).
                scope.$window.document.elementFromPoint(targetX, targetY);

                if (angular.isFunction(dragElm.hide)) {
                    dragElm.hide();
                } else {
                    displayElm = dragElm[0].style.display;
                    dragElm[0].style.display = 'none';
                }

                if (angular.isFunction(dragElm.show)) {
                    dragElm.show();
                } else {
                    dragElm[0].style.display = displayElm;
                }

                var possibleTarget = angular.element(scope.$window.document.elementFromPoint(targetX, targetY));
                if (possibleTarget[0] && possibleTarget[0].className !== 'angular-ui-tree-placeholder') {
                    targetElm = possibleTarget;
                }

                if (!targetElm) {
                    console.log('no target!');
                    return;
                }

                // issue: will fire drag and drop while clicking
                // if (eventObj.pageY % 25 >= 12)
                targetOffset = scope.dragHelper.offset(targetElm);
                targetBeforeBuffer = 25 * 0.25;
                targetBefore = eventObj.pageY < (targetOffset.top + targetBeforeBuffer);
                var insertedIndex = scope.list.map((e) => { return e.id; }).indexOf(parseInt(targetElm[0].id));
                var listLength = scope.list.length;
                if (!targetBefore) {
                    if (targetElm[0].id) {
                        console.log('Insert After!' + scope.list[insertedIndex].id);
                        dragInfo.setTargetId(insertedIndex < listLength - 1 ? parseInt(scope.list[insertedIndex + 1].id) : 'last');
                        // console.log(targetElm[0].id + ' ' + insertedIndex < scope.list.Length ? insertedIndex + 1 : insertedIndex);
                        targetElm.after(placeholder);
                    }
                } else {
                    if (targetElm[0].id) {
                        console.log('Insert Before!' + targetElm[0].id);
                        dragInfo.setTargetId(parseInt(targetElm[0].id));
                        // console.log(targetElm[0].id + ' ' + insertedIndex > 0 ? insertedIndex - 1 : 0);
                        targetElm[0].parentNode.insertBefore(placeholder[0], targetElm[0]);
                    }
                }

                scope.$apply(() => {

                });
            }
        };

        var dropped = (event) => {
            event.preventDefault();
            /*eslint-disable no-use-before-define */
            unbindDragMoveEvents();
            /*eslint-enable no-use-before-define */

            // var movedObj = scope.list.filter(function(obj) {
            //     return obj.id === dragInfo.source;
            // });
            // var movedObjIndex = scope.list.map(function(e) { return e.id; }).indexOf(dragInfo.source);
            // var insertedIndex = scope.list.map(function(e) { return e.id; }).indexOf(dragInfo.dest.target);
            // var isAfter = dragInfo.dest.isAfter === true ? 1 : -1;
            placeholder.replaceWith(element);
            // if (dragInfo.dest.target === undefined || movedObjIndex === insertedIndex + isAfter) {
            //     placeholder.replaceWith(element);
            // } else {
            scope.dropped()(event, dragInfo);
            // }
            // placeholder.remove();
            if (dragElm) {
                dragElm.remove();
                dragElm = null;
            }

            dragInfo = null;

        };

        /*
            * Binds mouse events that handle moving/dropping this dragged node
            */
        var bindDragMoveEvents = () => {
            angular.element(scope.$document).bind('mouseup', dropped);
            angular.element(scope.$document).bind('mousemove', dragMoveEvent);
            angular.element(scope.$document).bind('mouseleave', dropped);
        };

        /*
            * Unbinds mouse events that handle moving/dropping this dragged node.
            */
        var unbindDragMoveEvents = () => {
            angular.element(scope.$document).unbind('mouseup', dropped);
            angular.element(scope.$document).unbind('mousemove', dragMoveEvent);
            angular.element(scope.$document).unbind('mouseleave', dropped);
        };

        var bindDragStartEvents = () => {
            element.bind('mousedown', (e) => {
                //Don't call drag delay if no delay was specified.
                dragDelay.exec(() => {
                    dragStart(e);
                }, 150);
            });
            element.bind('mouseup', () => {
                dragDelay.cancel();
            });
        };
        bindDragStartEvents();

    }
}
// (function() {
//     'use strict';

//     var app = angular.module('app');
//     app.factory('dragHelper', ['$document', '$window', function($document, $window) {
//         return {

//             // need dragInfo to return the dragged node and dest
//             // commented the action within the directive
//             // handle the action in controller
//             dragInfo: function() {
//                 return {
//                     fromIndex: null,
//                     targetId: null,

//                     setFromIndex: function(index) {
//                         this.fromIndex = index;
//                     },

//                     setTargetId: function(id) {
//                         this.targetId = id;
//                     },

//                     //Method that actually manipulates the node being moved.
//                     apply: function() {
//                         if (this.source && this.dest && this.source === this.dest.target) {
//                             return;
//                         }
//                         // TODO: will handle the action in controller
//                         // this.source.remove();
//                         // this.parent.insertNode(this.index, nodeData);
//                     }
//                 };
//             },
//             eventObj: function(e) {
//                 var obj = e;
//                 if (e.targetTouches !== undefined) {
//                     //Set obj equal to the first Touch object in the TouchList.
//                     obj = e.targetTouches.item(0);
//                     //Logic to set obj to original TouchEvent.
//                 } else if (e.originalEvent !== undefined && e.originalEvent.targetTouches !== undefined) {
//                     obj = e.originalEvent.targetTouches.item(0);
//                 }
//                 return obj;
//             },
//             offset: function(element) {
//                 var boundingClientRect = element[0].getBoundingClientRect();

//                 return {
//                     width: element.prop('offsetWidth'),
//                     height: element.prop('offsetHeight'),
//                     top: boundingClientRect.top + ($window.pageYOffset || $document[0].body.scrollTop || $document[0].documentElement.scrollTop),
//                     left: boundingClientRect.left + ($window.pageXOffset || $document[0].body.scrollLeft || $document[0].documentElement.scrollLeft)
//                 };
//             },
//             positionStarted: function(e, target) {
//                 var pos = {},
//                     pageX = e.pageX,
//                     pageY = e.pageY;

//                 //Check to set correct data for TouchEvents
//                 if (e.originalEvent && e.originalEvent.touches && (e.originalEvent.touches.length > 0)) {
//                     pageX = e.originalEvent.touches[0].pageX;
//                     pageY = e.originalEvent.touches[0].pageY;
//                 }
//                 pos.offsetX = pageX - this.offset(target).left;
//                 pos.offsetY = pageY - this.offset(target).top;
//                 pos.startX = pos.lastX = pageX;
//                 pos.startY = pos.lastY = pageY;
//                 pos.nowX = pos.nowY = pos.distX = pos.distY = pos.dirAx = 0;
//                 pos.dirX = pos.dirY = pos.lastDirX = pos.lastDirY = pos.distAxX = pos.distAxY = 0;
//                 return pos;
//             },
//             positionMoved: function(e, pos) {

//                 var pageX = e.pageX,
//                     pageY = e.pageY,
//                     newAx;

//                 //If there are multiple touch points, choose one to use as X and Y.
//                 if (e.originalEvent && e.originalEvent.touches && (e.originalEvent.touches.length > 0)) {
//                     pageX = e.originalEvent.touches[0].pageX;
//                     pageY = e.originalEvent.touches[0].pageY;
//                 }

//                 //Mouse position last event.
//                 pos.lastX = pos.nowX;
//                 pos.lastY = pos.nowY;

//                 //Mouse position this event.
//                 pos.nowX = pageX;
//                 pos.nowY = pageY;

//                 //Distance mouse moved between events.
//                 pos.distX = pos.nowX - pos.lastX;
//                 pos.distY = pos.nowY - pos.lastY;

//                 //Direction mouse was moving.
//                 pos.lastDirX = pos.dirX;
//                 pos.lastDirY = pos.dirY;

//                 //Direction mouse is now moving (on both axis).
//                 pos.dirX = pos.distX === 0 ? 0 : pos.distX > 0 ? 1 : -1;
//                 pos.dirY = pos.distY === 0 ? 0 : pos.distY > 0 ? 1 : -1;

//                 //Axis mouse is now moving on.
//                 newAx = Math.abs(pos.distX) > Math.abs(pos.distY) ? 1 : 0;

//                 //Calc distance moved on this axis (and direction).
//                 if (pos.dirAx !== newAx) {
//                     pos.distAxX = 0;
//                     pos.distAxY = 0;
//                 } else {
//                     pos.distAxX += Math.abs(pos.distX);
//                     if (pos.dirX !== 0 && pos.dirX !== pos.lastDirX) {
//                         pos.distAxX = 0;
//                     }
//                     pos.distAxY += Math.abs(pos.distY);
//                     if (pos.dirY !== 0 && pos.dirY !== pos.lastDirY) {
//                         pos.distAxY = 0;
//                     }
//                 }
//                 pos.dirAx = newAx;
//             }
//         };
//     }]);

//     app.directive('dragDrop', ['$timeout', '$window', '$document', 'dragHelper', function($timeout, $window, $document, dragHelper) {
//         return {
//             restrict: 'A',
//             scope: {
//                 draggable: '=draggable',
//                 dropped: '&',
//                 list: '=list'
//             },
//             // templateUrl: ''
//             link: function(scope, element, attrs) {
//                 var pos,
//                     placeholder,
//                     eventObj,
//                     dragElm,
//                     elements,
//                     body = document.body,
//                     html = document.documentElement,
//                     documentHeight,
//                     documentWidth,
//                     scrollContainerElm,
//                     targetElm,
//                     dragInfo;

//                 var dragStart = function(event) {
//                     //Disable right click.
//                     if ((event.button === 2 || event.which === 3)) {
//                         return;
//                     }
//                     eventObj = dragHelper.eventObj(event);
//                     event.preventDefault();
//                     //Setting drag info properties and methods in scope of node being moved.
//                     dragInfo = dragHelper.dragInfo();
//                     var movedObjIndex;
//                     if (eventObj.target.id === '')
//                     {
//                         movedObjIndex = scope.list.map(function(e) { return e.id; }).indexOf(parseInt(eventObj.target.parentElement.id));
//                     }
//                     else {
//                         movedObjIndex = scope.list.map(function(e) { return e.id; }).indexOf(parseInt(eventObj.target.id));
//                     }
//                     dragInfo.setFromIndex(movedObjIndex);

//                     // create placeholder element
//                     placeholder = angular.element($window.document.createElement('li'))
//                         .addClass('angular-ui-tree-placeholder');
//                     pos = dragHelper.positionStarted(eventObj, element);
//                     placeholder.css('height', element.prop('offsetHeight') + 'px');

//                     dragElm = angular.element($window.document.createElement('div'));

//                     dragElm = dragElm.addClass('listItemDrag');
//                     dragElm.css('width', element.prop('scrollWidth') + 'px');
//                     dragElm.css('height', element.prop('offsetHeight') + 'px');
//                     dragElm.css('z-index', 9999);
//                     element.after(placeholder);
//                     dragElm.append(element);

//                     $document.find('body').append(dragElm);
//                     dragElm.css({
//                         'left': eventObj.pageX - pos.offsetX + 'px',
//                         'top': eventObj.pageY - pos.offsetY + 'px'
//                     });
//                     elements = {
//                         placeholder: placeholder,
//                         dragging: dragElm
//                     };
//                     /*eslint-disable no-use-before-define */
//                     bindDragMoveEvents();
//                     /*eslint-enable no-use-before-define */

//                     scope.$apply(function() {

//                     });

//                     //Get bounds of document.
//                     documentHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
//                     documentWidth = Math.max(body.scrollWidth, body.offsetWidth, html.clientWidth, html.scrollWidth, html.offsetWidth);
//                 };

//                 var dragDelay = (function() {
//                     var to;

//                     return {
//                         exec: function(fn, ms) {
//                             if (!ms) {
//                                 ms = 0;
//                             }
//                             this.cancel();
//                             to = $timeout(fn, ms);
//                         },
//                         cancel: function() {
//                             $timeout.cancel(to);
//                         }
//                     };
//                 }());

//                 /*eslint-disable complexity */
//                 var dragMoveEvent = function(event) {
//                     var leftElmPos,
//                         topElmPos,
//                         topScroll,
//                         bottomScroll,
//                         scrollDownBy,
//                         scrollUpBy,
//                         targetX,
//                         targetY,
//                         displayElm,
//                         targetOffset,
//                         targetBeforeBuffer,
//                         targetBefore;

//                     eventObj = dragHelper.eventObj(event);
//                     if (dragElm) {
//                         event.preventDefault();
//                         // Get top left positioning of element being moved
//                         leftElmPos = eventObj.pageX - pos.offsetX;
//                         topElmPos = eventObj.pageY - pos.offsetY;

//                         // dragElm can't leave the screen on the left
//                         if (leftElmPos < 0) {
//                             leftElmPos = 0;
//                         }

//                         // dragElm can't leave the screen on the top
//                         if (topElmPos < 0) {
//                             topElmPos = 0;
//                         }

//                         // dragElm can't leave the screen on the bottom
//                         if ((topElmPos + 10) > documentHeight) {
//                             topElmPos = documentHeight - 10;
//                         }

//                         // dragElm can't leave the screen on the right
//                         if ((leftElmPos + 10) > documentWidth) {
//                             leftElmPos = documentWidth - 10;
//                         }

//                         // updating element being moved css
//                         dragElm.css({
//                             'left': leftElmPos + 'px',
//                             'top': topElmPos + 'px'
//                         });

//                         // call service to update position coordinates based on move
//                         dragHelper.positionMoved(event, pos);

//                         // Setting X point for elementFromPoint.
//                         targetX = eventObj.pageX - ($window.pageXOffset ||
//                                 $window.document.body.scrollLeft ||
//                                 $window.document.documentElement.scrollLeft) -
//                             ($window.document.documentElement.clientLeft || 0);

//                         targetY = eventObj.pageY - ($window.pageYOffset ||
//                                 $window.document.body.scrollTop ||
//                                 $window.document.documentElement.scrollTop) -
//                             ($window.document.documentElement.clientTop || 0);

//                         // Set target element (element in specified x/y coordinates).
//                         $window.document.elementFromPoint(targetX, targetY);

//                         if (angular.isFunction(dragElm.hide)) {
//                             dragElm.hide();
//                         } else {
//                             displayElm = dragElm[0].style.display;
//                             dragElm[0].style.display = 'none';
//                         }

//                         if (angular.isFunction(dragElm.show)) {
//                             dragElm.show();
//                         } else {
//                             dragElm[0].style.display = displayElm;
//                         }

//                         var possibleTarget = angular.element($window.document.elementFromPoint(targetX, targetY));
//                         if (possibleTarget[0] && possibleTarget[0].className !== 'angular-ui-tree-placeholder') {
//                             targetElm = possibleTarget;
//                         }

//                         if (!targetElm) {
//                             console.log('no target!');
//                             return;
//                         }

//                         // issue: will fire drag and drop while clicking
//                         // if (eventObj.pageY % 25 >= 12)
//                         targetOffset = dragHelper.offset(targetElm);
//                         targetBeforeBuffer = 25 * 0.25;
//                         targetBefore = eventObj.pageY < (targetOffset.top + targetBeforeBuffer);
//                         var insertedIndex = scope.list.map(function(e) { return e.id; }).indexOf(parseInt(targetElm[0].id));
//                         var listLength = scope.list.length;
//                         if (!targetBefore) {
//                             if (targetElm[0].id) {
//                                 console.log('Insert After!' + scope.list[insertedIndex].id);
//                                 dragInfo.setTargetId(insertedIndex < listLength - 1 ? parseInt(scope.list[insertedIndex + 1].id) : 'last');
//                                 // console.log(targetElm[0].id + ' ' + insertedIndex < scope.list.Length ? insertedIndex + 1 : insertedIndex);
//                                 targetElm.after(placeholder);
//                             }
//                         } else {
//                             if (targetElm[0].id) {
//                                 console.log('Insert Before!' + targetElm[0].id);
//                                 dragInfo.setTargetId(parseInt(targetElm[0].id));
//                                 // console.log(targetElm[0].id + ' ' + insertedIndex > 0 ? insertedIndex - 1 : 0);
//                                 targetElm[0].parentNode.insertBefore(placeholder[0], targetElm[0]);
//                             }
//                         }

//                         scope.$apply(function() {

//                         });
//                     }
//                 };

//                 var dropped = function(event) {
//                     event.preventDefault();
//                     /*eslint-disable no-use-before-define */
//                     unbindDragMoveEvents();
//                     /*eslint-enable no-use-before-define */

//                     // var movedObj = scope.list.filter(function(obj) {
//                     //     return obj.id === dragInfo.source;
//                     // });
//                     // var movedObjIndex = scope.list.map(function(e) { return e.id; }).indexOf(dragInfo.source);
//                     // var insertedIndex = scope.list.map(function(e) { return e.id; }).indexOf(dragInfo.dest.target);
//                     // var isAfter = dragInfo.dest.isAfter === true ? 1 : -1;
//                     placeholder.replaceWith(element);
//                     // if (dragInfo.dest.target === undefined || movedObjIndex === insertedIndex + isAfter) {
//                     //     placeholder.replaceWith(element);
//                     // } else {
//                     scope.dropped()(event, dragInfo);
//                     // }
//                     // placeholder.remove();
//                     if (dragElm) {
//                         dragElm.remove();
//                         dragElm = null;
//                     }

//                     dragInfo = null;

//                 };

//                 /*
//                  * Binds mouse events that handle moving/dropping this dragged node
//                  */
//                 var bindDragMoveEvents = function() {
//                     angular.element($document).bind('mouseup', dropped);
//                     angular.element($document).bind('mousemove', dragMoveEvent);
//                     angular.element($document).bind('mouseleave', dropped);
//                 };

//                 /*
//                  * Unbinds mouse events that handle moving/dropping this dragged node.
//                  */
//                 var unbindDragMoveEvents = function() {
//                     angular.element($document).unbind('mouseup', dropped);
//                     angular.element($document).unbind('mousemove', dragMoveEvent);
//                     angular.element($document).unbind('mouseleave', dropped);
//                 };

//                 var bindDragStartEvents = function() {
//                     element.bind('mousedown', function(e) {
//                         //Don't call drag delay if no delay was specified.
//                         dragDelay.exec(function() {
//                             dragStart(e);
//                         }, 150);
//                     });
//                     element.bind('mouseup', function() {
//                         dragDelay.cancel();
//                     });
//                 };
//                 bindDragStartEvents();

//             }
//         };
//     }]);
// }());
