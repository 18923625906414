import graphicTemplate from './myHorizGraphic.html';

export default class MyHorizGraphic {
    constructor() {
        this.template = graphicTemplate;
        this.restrict = 'AE';
        this.scope = {
            graphicid: '=',
        };
    }

    controller($rootScope, $scope, allModelDataService, forteCookieService) {
        'ngInject';

        $scope.$rootScope = $rootScope;
        $scope.allModelDataService = allModelDataService;
        $scope.forteCookieService = forteCookieService;

        function refreshGraphic(graphicData) {
            $scope.graphicDataURL =
                'data:image/png;base64,' + graphicData.imageDataURL;
            allModelDataService.setGraphicDataURL($scope.graphicDataURL);
        }
        //this event handler handles the broadcast from the shell controller
        $scope.refreshGraphicListner = $rootScope.$on(
            'refreshGraphic_' + $scope.graphicid,
            (event, data) => {
                refreshGraphic(data);
            }
        );

        $scope.$on('$destroy', () => {
            $scope.refreshGraphicListner();
        });
    }
}
// (function () {
//     'use strict';
//     angular.module('app').directive('myHorizGraphic', [ function () {
//         return {
//             restrict: 'AE',
//             controller: ['$rootScope','$scope', 'datacontext', '$http', '$timeout', 'ngAuthSettings', 'common', 'allModelDataService', 'drawingService', 'forteCookieService',
//                 function($rootScope, $scope, datacontext, $http, $timeout, ngAuthSettings, common, allModelDataService, drawingService, forteCookieService) {
//                     var $q = common.$q;

//                     var serviceBase = ngAuthSettings.apiServiceBaseUri;
//                     var refreshGraphicListner;
//                     var landscapeMode = false;
//                     var renderedTextUrl;
//                     var renderedGfx;
//                     var activate = function () {
//                         //webGLStart();
//                     };

//                     activate();

//                     function refreshGraphic(graphicData, graphicId) {
//                         $scope.graphicDataURL = 'data:image/png;base64,' + graphicData.imageDataURL;
//                         allModelDataService.setGraphicDataURL($scope.graphicDataURL);
//                         // var canvasName = 'drawingCanvas_' + graphicId ;
//                         // var canvas = document.getElementById(canvasName);//ProcessDrawingXML(response, canvasName);
//                         // var shaderfs = document.getElementById('shader-fs');
//                         // var shadervs = document.getElementById('shader-vs');
//                         // var drawingLabelsContainer = document.getElementById('drawingLabelsContainer_' +  graphicId);
//                         if ($scope.graphicid === graphicId) {
//                             switch (graphicId) {
//                                 case 'main':
//                                     //set the graphic visibility in the drawing service.
//                                     drawingService.setIsGraphicVisible(forteCookieService.getIsGraphicVisible());//need to change if the initial load animation of drawing graphic is fixed.
//                                     //dont draw the main graphic unless the graphic is visible on the page.
//                                     //drawingService.drawGraphic(canvas, shaderfs, shadervs, drawingLabelsContainer, graphicData);
//                                     break;
//                                 case 'printAllPdf':
//                                     //var deferred = $q.defer();
//                                     //$timeout(function() {
//                                     //    var graphicDataUrl = drawingService.drawGraphicReport(canvas, shaderfs, shadervs,drawingLabelsContainer,graphicData);
//                                     //    var resultObject = {
//                                     //        graphicUrl: graphicDataUrl,
//                                     //        graphicData: {Labels: graphicData.Labels}
//                                     //    };
//                                     //    deferred.resolve(resultObject);
//                                     //}, 30);
//                                     //return deferred.promise;
//                                 default:
//                                     ////the drawGraphicReport returns a stream of the current graphic. this is used for PDF generation later.
//                                     //var graphicDataURL = drawingService.drawGraphicReport(canvas, shaderfs, shadervs, drawingLabelsContainer, graphicData);
//                                     ////set it in local storage so we can use it when they render the report.
//                                     //allModelDataService.setGraphicDataURL(graphicDataURL);
//                                     //allModelDataService.setGraphicOverlayLabels(graphicData.Labels);
//                             }
//                         }
//                     }

//                     //this event handler handles the broadcast from the shell controller
//                     refreshGraphicListner = $rootScope.$on('refreshGraphic_' + $scope.graphicid, function (event, data) {
//                         refreshGraphic(data, $scope.graphicid);
//                     });

//                     // var handleDrawGraphicForPrintReport = $scope.$on('drawGraphicForPrintReport', function(event, data) {
//                     //     drawGraphicForPrintReport()
//                     //         .then(function (response) {
//                     //             window.print();
//                     //             document.body.removeChild(document.getElementById('printSection'));
//                     //         })
//                     //         .catch(function (err) {

//                     //         });
//                     // });

//                     $scope.$on('$destroy', function() {
//                         refreshGraphicListner();
//                         //handleDrawGraphicForPrintReport();
//                     });
//                 }
//             ],
//             scope: {
//                 graphicid: '='
//             },
//             templateUrl: 'app/directives/myHorizGraphic.html'
//         };
//     } ]);
// }());
