// Include in index.html so that app level exceptions are handled.
// Exclude from testRunner.html which should run exactly what it wants to run
export default function exceptionHandlerConfig ($provide) {
    'ngInject';
    $provide.decorator('$exceptionHandler', ($delegate, logger) => {
        var appErrorPrefix = '[HT Error] ';
        var logError = logger.getLogFn('app', 'error');
        return (exception, cause) => {
            $delegate(exception, cause);
            if (exception.message) {
                if (appErrorPrefix && exception.message.indexOf(appErrorPrefix) === 0) { return; }
            }
            var errorData = { exception: exception, cause: cause };
            var msg = appErrorPrefix + exception.message;
            var url = window.location;
            //only show this error popup in testing staging or localhost
            if (/testing/.test(url) ||
                /staging/.test(url) ||
                /localhost/.test(url)) {
                logError(msg, errorData, true);
            }

        };
    });
}
// (function () {
//     'use strict';

//     var app = angular.module('app');

//     // Configure by setting an optional string value for appErrorPrefix.
//     // Accessible via config.appErrorPrefix (via config value).

//     app.config(['$provide', function ($provide) {
//         $provide.decorator('$exceptionHandler',
//             ['$delegate', 'config', 'logger', extendExceptionHandler]);
//     }]);

//     // Extend the $exceptionHandler service to also display a toast.
//     function extendExceptionHandler($delegate, config, logger) {
//         var appErrorPrefix = config.appErrorPrefix;
//         var logError = logger.getLogFn('app', 'error');
//         return function (exception, cause) {
//             $delegate(exception, cause);
//             if (exception.message) {
//                 if (appErrorPrefix && exception.message.indexOf(appErrorPrefix) === 0) { return; }
//             }
//             var errorData = { exception: exception, cause: cause };
//             var msg = appErrorPrefix + exception.message;
//             var url = window.location;
//             //only show this error popup in testing staging or localhost
//             if (/testing/.test(url) ||
//                 /staging/.test(url) ||
//                 /localhost/.test(url)) {
//                 logError(msg, errorData, true);
//             }

//         };
//     }
// }());
