import pendingColumnOverlayTemplate from './myPendingColumnOverlay.html';

export default class myPendingColumnOverlay {
    constructor() {
        this.template = pendingColumnOverlayTemplate;
        this.transclude = true;
        this.scope = {
            pending: '=pending'
        };
    }
    controller($scope, $element, $transclude) {
        'ngInject';

        let transcludedContent, transclusionScope;

        $transclude((clone, scope) => {
            $element.append(clone);
            transcludedContent = clone;
            transclusionScope = scope;
        });
    }
}
// (function () {
//     'use strict';
//     angular.module('app').directive('myPendingColumnOverlay', ['$uibModal', function ($uibModal) {
//         return {
//             transclude: true,
//             controller: ['$scope', '$element', '$transclude',function($scope, $element, $transclude) {
//                 var transcludedContent, transclusionScope;

//                 $transclude(function(clone, scope) {
//                     $element.append(clone);
//                     transcludedContent = clone;
//                     transclusionScope = scope;
//                 });
//             }],
//             scope: {
//                 pending: '=pending'
//             },
//             templateUrl: 'app/directives/myPendingColumnOverlay.html'
//         };
//     }]);
// }());
