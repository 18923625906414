export default class roofGeometryService {
    constructor(common, postService) {
        'ngInject';

        this.$q = common.$q();
        this.postService = postService;

        this.httpRequestQueueCounter = 0;
        this.promiseQueue = [];
        this.waiting = false;
    }
    modifyGeometryType(type) {
        var data = {
            value: type
        };
        return this._requestPostEvent('ModifyGeometryType', data);
    }

    modifyIntermediateSupport(length) {
        var data = {
            value: length
        };
        return this._requestPostEvent('ModifyIntermediateSupport', data);
    }

    modifyIntermediateSupportDimensionIsSide(length) {
        var data = {
            value: length
        };
        return this._requestPostEvent('ModifyIntermediateSupportDimensionIsSide', data);
    }

    modifyIntermediateSupportLength(length) {
        var data = {
            value: length
        };
        return this._requestPostEvent('ModifyIntermediateSupportLength', data);
    }

    modifySideLength(sideLength) {
        var data = {
            value: sideLength
        };
        return this._requestPostEvent('ModifySideLength', data);
    }

    modifySideSlope(sideSlope) {
        var data = {
            value: sideSlope
        };
        return this._requestPostEvent('ModifySideSlope', data);
    }

    modifyEndLength(endLength) {
        var data = {
            value: endLength
        };
        return this._requestPostEvent('ModifyEndLength', data);
    }

    modifyEndSlope(endSlope) {
        var data = {
            value: endSlope
        };
        return this._requestPostEvent('ModifyEndSlope', data);
    }

    modifySideOverhang(sideOverhang) {
        var data = {
            value: sideOverhang
        };
        return this._requestPostEvent('ModifySideOverhang', data);
    }

    modifyEndOverhang(endOverhang) {
        var data = {
            value: endOverhang
        };
        return this._requestPostEvent('ModifyEndOverhang', data);
    }

    modifyLiveLoadType(type) {
        var data = {
            value: type
        };
        return this._requestPostEvent('ModifyLiveLoadType', data);
    }

    modifyDeadLoad(deadload) {
        var data = {
            value: deadload
        };
        return this._requestPostEvent('ModifyDeadLoad', data);
    }

    modifyLiveLoad(liveload) {
        var data = {
            value: liveload
        };
        return this._requestPostEvent('ModifyLiveLoad', data);
    }

    modifyHighEndSupportType(type) {
        var data = {
            value: type
        };
        return this._requestPostEvent('ModifyHighEndSupportType', data);
    }

    saveRoofGeometryAsDefaults() {
        var data = {};
        return this._requestPostEvent('SaveRoofGeometryAsDefaults', data);
    }

    clearRoofGeometryValues() {
        var data = {};
        return this._requestPostEvent('ClearRoofGeometryValues', data);
    }

    convertLoadMagnitudes(data) {
        var apiRoute = 'api/UOM/ConvertQuickLoadMagnitudes';
        return this.postService.requestSimplePost(apiRoute, data);
    }

    getMagnitudeFriendly(data) {
        var apiRoute = 'api/UOM/GetMagnitudeFriendly';
        return this.postService.requestSimplePost(apiRoute, data);
    }

    convertReactionLocation(data) {
        var apiRoute = 'api/UOM/ConvertReactionLocation';
        return this.postService.requestSimplePost(apiRoute, data);
    }

    convertLengthsList(data) {
        var apiRoute = 'api/UOM/ConvertLengthsList';
        return this.postService.requestSimplePost(apiRoute, data);
    }

    convertLengthsListLargeUnits(data) {
        var apiRoute = 'api/UOM/ConvertLengthsListLargeUnits';
        return this.postService.requestSimplePost(apiRoute, data);
    }

    getQueueCounter() {
        this.inputQueuePromise = this.$q.defer();
        if (this.httpRequestQueueCounter === 0)
        {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.somethingWaitingOnQueueToFinish = true;
        }
        return this.inputQueuePromise.promise;
    }

    getMetaData() {
        var data = {};
        return this._requestPostEvent('GetMetadata', data);
    }

    _requestPostEvent(holeEvent, holeData) {
        var path = 'api/RoofGeometry/';
        return this.postService.requestPostEvent(holeEvent,holeData,path);
    }

}
// (function () {
//     'use strict';
//     var app = angular.module('app');
//     var serviceId = 'roofGeometryService';
//     app.factory(serviceId, ['$http', 'ngAuthSettings', 'common','postService', roofGeometryService]);

//     function roofGeometryService($http, ngAuthSettings, common, postService) {
//         var $q = common.$q;
//         var serviceBase = ngAuthSettings.apiServiceBaseUri;
//         var httpRequestQueueCounter = 0;
//         var inputQueuePromise;
//         var somethingWaitingOnQueueToFinish = false;

//         var service = {
//             getQueueCounter: getQueueCounter,
//             getMetaData: getMetaData,
//             modifyGeometryType: modifyGeometryType,
//             modifyIntermediateSupport: modifyIntermediateSupport,
//             modifyIntermediateSupportDimensionIsSide: modifyIntermediateSupportDimensionIsSide,
//             modifyIntermediateSupportLength: modifyIntermediateSupportLength,
//             modifySideLength: modifySideLength,
//             modifySideSlope: modifySideSlope,
//             modifyEndLength: modifyEndLength,
//             modifyEndSlope: modifyEndSlope,
//             modifySideOverhang: modifySideOverhang,
//             modifyEndOverhang: modifyEndOverhang,
//             modifyLiveLoadType: modifyLiveLoadType,
//             modifyDeadLoad: modifyDeadLoad,
//             modifyLiveLoad: modifyLiveLoad,
//             modifyHighEndSupportType: modifyHighEndSupportType,
//             saveRoofGeometryAsDefaults: saveRoofGeometryAsDefaults,
//             clearRoofGeometryValues: clearRoofGeometryValues,
//             convertLoadMagnitudes: convertLoadMagnitudes,
//             getMagnitudeFriendly: getMagnitudeFriendly,
//             convertReactionLocation: convertReactionLocation,
//             convertLengthsList: convertLengthsList,
//             convertLengthsListLargeUnits: convertLengthsListLargeUnits
//         };
//         return service;

//         function modifyGeometryType(type) {
//             var data = {
//                 value: type
//             };
//             return _requestPostEvent('ModifyGeometryType', data);
//         }

//         function modifyIntermediateSupport(length) {
//             var data = {
//                 value: length
//             };
//             return _requestPostEvent('ModifyIntermediateSupport', data);
//         }

//         function modifyIntermediateSupportDimensionIsSide(length) {
//             var data = {
//                 value: length
//             };
//             return _requestPostEvent('ModifyIntermediateSupportDimensionIsSide', data);
//         }

//         function modifyIntermediateSupportLength(length) {
//             var data = {
//                 value: length
//             };
//             return _requestPostEvent('ModifyIntermediateSupportLength', data);
//         }

//         function modifySideLength(sideLength) {
//             var data = {
//                 value: sideLength
//             };
//             return _requestPostEvent('ModifySideLength', data);
//         }

//         function modifySideSlope(sideSlope) {
//             var data = {
//                 value: sideSlope
//             };
//             return _requestPostEvent('ModifySideSlope', data);
//         }

//         function modifyEndLength(endLength) {
//             var data = {
//                 value: endLength
//             };
//             return _requestPostEvent('ModifyEndLength', data);
//         }

//         function modifyEndSlope(endSlope) {
//             var data = {
//                 value: endSlope
//             };
//             return _requestPostEvent('ModifyEndSlope', data);
//         }

//         function modifySideOverhang(sideOverhang) {
//             var data = {
//                 value: sideOverhang
//             };
//             return _requestPostEvent('ModifySideOverhang', data);
//         }

//         function modifyEndOverhang(endOverhang) {
//             var data = {
//                 value: endOverhang
//             };
//             return _requestPostEvent('ModifyEndOverhang', data);
//         }

//         function modifyLiveLoadType(type) {
//             var data = {
//                 value: type
//             };
//             return _requestPostEvent('ModifyLiveLoadType', data);
//         }

//         function modifyDeadLoad(deadload) {
//             var data = {
//                 value: deadload
//             };
//             return _requestPostEvent('ModifyDeadLoad', data);
//         }

//         function modifyLiveLoad(liveload) {
//             var data = {
//                 value: liveload
//             };
//             return _requestPostEvent('ModifyLiveLoad', data);
//         }

//         function modifyHighEndSupportType(type) {
//             var data = {
//                 value: type
//             };
//             return _requestPostEvent('ModifyHighEndSupportType', data);
//         }

//         function saveRoofGeometryAsDefaults() {
//             var data = {};
//             return _requestPostEvent('SaveRoofGeometryAsDefaults', data);
//         }

//         function clearRoofGeometryValues() {
//             var data = {};
//             return _requestPostEvent('ClearRoofGeometryValues', data);
//         }

//         function convertLoadMagnitudes(data) {
//             var apiRoute = 'api/UOM/ConvertQuickLoadMagnitudes';
//             return postService.requestSimplePost(apiRoute, data);
//         }

//         function getMagnitudeFriendly(data) {
//             var apiRoute = 'api/UOM/GetMagnitudeFriendly';
//             return postService.requestSimplePost(apiRoute, data);
//         }

//         function convertReactionLocation(data) {
//             var apiRoute = 'api/UOM/ConvertReactionLocation';
//             return postService.requestSimplePost(apiRoute, data);
//         }

//         function convertLengthsList(data) {
//             var apiRoute = 'api/UOM/ConvertLengthsList';
//             return postService.requestSimplePost(apiRoute, data);
//         }

//         function convertLengthsListLargeUnits(data) {
//             var apiRoute = 'api/UOM/ConvertLengthsListLargeUnits';
//             return postService.requestSimplePost(apiRoute, data);
//         }

//         function getQueueCounter() {
//             inputQueuePromise = $q.defer();
//             if (httpRequestQueueCounter === 0)
//             {
//                 inputQueuePromise.resolve();
//                 return inputQueuePromise.promise;
//             }
//             else {
//                 somethingWaitingOnQueueToFinish = true;
//             }
//             return inputQueuePromise.promise;
//         }

//         function getMetaData() {
//             var data = {};
//             return _requestPostEvent('GetMetadata', data);
//         }

//         function _requestPostEvent(holeEvent, holeData) {
//             var path = 'api/RoofGeometry/';
//             return postService.requestPostEvent(holeEvent,holeData,path);
//         }

//     }
// }());
