export default class simpleGetTextController {
    constructor($scope, resourceMessagingService) {
        'ngInject';

        this.$scope = $scope;
        this.resourceMessagingService = resourceMessagingService;

        this.activate();
    }
    activate() {
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }
}
// var test;
// (function () {
//     'use strict';
//     var app = angular.module('app');
//     //this controller is only used for modal dialogs that need access to the getText function
//     app.controller('simpleGetTextController',
//         ['$scope','resourceMessagingService',
//             function ($scope, resourceMessagingService) {
//                 ///NOTE: this does not follow convention.  normally we would write
//                 //var vm = this;
//                 //but for some reason, this modal controller will not work this way.
//                 //it could be because of the way it is injected in the modal.open method.
//                 var vm = $scope;

//                 vm.getText = resourceMessagingService.getText;

//                 function activate() {

//                 }

//                 activate();

//             }]);
// }());
