/* eslint-disable operator-linebreak */
import hipGraphicEndWallImage from '../../../../images/mHipGraphic_EndWall.png';
import hipGraphicEndWallNoLeaderImage from '../../../../images/mHipGraphic_EndWall_NoLeader.png';
import hipGraphicNoPostImage from '../../../../images/mHipGraphic_NoPost.png';
import hipGraphicNoPostNoLeaderImage from '../../../../images/mHipGraphic_NoPost_NoLeader.png';
import hipGraphicSideWallImage from '../../../../images/mHipGraphic_SideWall.png';
import hipGraphicSideWallNoLeaderImage from '../../../../images/mHipGraphic_SideWall_NoLeader.png';
import valleyGraphicEndWallImage from '../../../../images/mValleyGraphic_EndWall.png';
import valleyGraphicNoPostImage from '../../../../images/mValleyGraphic_NoPost.png';
import valleyGraphicSideWallImage from '../../../../images/mValleyGraphic_SideWall.png';
export default class roofGeometryController {
    constructor(
        $scope,
        common,
        resourceMessagingService,
        allModelDataService,
        roofGeometryService,
        dirtyCheckService,
        notifier
    ) {
        'ngInject';

        this.$scope = $scope;
        this.common = common;
        this.resourceMessagingService = resourceMessagingService;
        this.allModelDataService = allModelDataService;
        this.roofGeometryService = roofGeometryService;
        this.dirtyCheckService = dirtyCheckService;
        this.notifier = notifier;

        this.roofGeometryData = null;
        this.roofGeometryMetaData = null;
        this.liveLoadFriendly = null;
        this.deadLoadFriendly = null;
        this.sideLengthFriendly = null;
        this.sideOverhangFriendly = null;
        this.sideSlopeFriendly = null;
        this.endLengthFriendly = null;
        this.endOverhangFriendly = null;
        this.endSlopeFriendly = null;
        this.intermediateSupportLengthFriendly = null;
        this.disableElementId = null;
        this.disableElementIdOverhang = null;
        this.warningMessage = this.getText('hipChangeInputWarning').replace(
            /~1/g,
            '\n'
        );

        this.activate();
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    selectAllTextOnFocus($event) {
        // console.log($event.currentTarget.name + '**********' + this.disableElementId + '**********' + this.disableElementIdOverhang);
        $event.target.select();
    }

    isUOMMetric() {
        return this.allModelDataService.isUOMMetric();
    }

    keyDown(isPending, $event) {
        //ignore everything accept navigation input if the column is "pending"
        if (isPending) {
            if (!($event.keyCode === 9 || $event.keyCode === 16)) {
                //tab and shift keycodes
                $event.stopPropagation();
                $event.preventDefault();
                //console.log('stopped: ' + $event.keyCode);
                return false;
            }
        }
    }

    getIntermediateSupportClass() {
        if (this.roofGeometryData.GeometryType === 0) {
            if (this.roofGeometryData.IntermediateSupportDimensionIsSide) {
                return 'sideIntermediateSupportHip';
            } else {
                return 'endIntermediateSupportHip';
            }
        } else {
            if (this.roofGeometryData.IntermediateSupportDimensionIsSide) {
                return 'sideIntermediateSupportValley';
            } else {
                return 'endIntermediateSupportValley';
            }
        }
    }

    getRoofGeometryMetaData() {
        this.roofGeometryService.getMetaData().then((res) => {
            this.roofGeometryMetaData = res;
        });
    }

    getRoofGeometryDataFromPRData() {
        this.roofGeometryData =
            this.allModelDataService.getPostResponseData().MemberData.RoofGeometryData;
        this.disableElementId = this.roofGeometryData.CalculatedValueID;
        this._getMagnitudeFriendly(true, this.roofGeometryData.LiveLoad);
        this._getMagnitudeFriendly(false, this.roofGeometryData.DeadLoad);
        this._convertAllLengths();
        this.sideSlopeFriendly =
            this.roofGeometryData.SideSlope === 'NaN'
                ? ''
                : this.roofGeometryData.SideSlope;
        this.endSlopeFriendly =
            this.roofGeometryData.EndSlope === 'NaN'
                ? ''
                : this.roofGeometryData.EndSlope;
    }

    _convertAllLengths() {
        var UOMSetting =
            this.allModelDataService.getPostResponseData().ApplicationData
                .ProgramSettingsData.UOM;
        var data = {
            UOMSetting: UOMSetting,
            lengthsList: [
                {
                    lengthFriendly: '',
                    lengthValue:
                        this.roofGeometryData.SideLength === 'NaN'
                            ? 0
                            : this.roofGeometryData.SideLength,
                },
                {
                    lengthFriendly: '',
                    lengthValue:
                        this.roofGeometryData.SideOverhang === 'NaN'
                            ? 0
                            : this.roofGeometryData.SideOverhang,
                },
                {
                    lengthFriendly: '',
                    lengthValue:
                        this.roofGeometryData.EndLength === 'NaN'
                            ? 0
                            : this.roofGeometryData.EndLength,
                },
                {
                    lengthFriendly: '',
                    lengthValue:
                        this.roofGeometryData.EndOverhang === 'NaN'
                            ? 0
                            : this.roofGeometryData.EndOverhang,
                },
                {
                    lengthFriendly: '',
                    lengthValue:
                        this.roofGeometryData.IntermediateSupportLength ===
                        'NaN'
                            ? 0
                            : this.roofGeometryData.IntermediateSupportLength,
                },
            ],
        };
        this.roofGeometryService.convertLengthsList(data).then((response) => {
            this.sideLengthFriendly =
                response.lengthsList[0].lengthValue === 0
                    ? ''
                    : response.lengthsList[0].lengthFriendly;
            this.sideOverhangFriendly =
                response.lengthsList[1].lengthValue === 0
                    ? ''
                    : response.lengthsList[1].lengthFriendly;
            this.endLengthFriendly =
                response.lengthsList[2].lengthValue === 0
                    ? ''
                    : response.lengthsList[2].lengthFriendly;
            this.endOverhangFriendly =
                response.lengthsList[3].lengthValue === 0
                    ? ''
                    : response.lengthsList[3].lengthFriendly;
            this.intermediateSupportLengthFriendly =
                response.lengthsList[4].lengthValue === 0
                    ? ''
                    : response.lengthsList[4].lengthFriendly;
            this.formRoofGeometry.$setPristine();
        });
    }

    _getMagnitudeFriendly(isLiveLoad, rawValue) {
        var UOMSetting =
            this.allModelDataService.getPostResponseData().ApplicationData
                .ProgramSettingsData.UOM;
        // LoadType: Uniform (PSF)
        var data = {
            UOMSetting: UOMSetting,
            loadType: 3,
            magnitudesList: [
                {
                    loadClass: this.roofGeometryData.LiveLoadType,
                    magnitudeValue: rawValue,
                    magnitudeFriendly: '',
                },
            ],
        };

        this.roofGeometryService.getMagnitudeFriendly(data).then((response) => {
            if (isLiveLoad) {
                this.liveLoadFriendly =
                    response.magnitudesList[0].magnitudeFriendly;
            } else {
                this.deadLoadFriendly =
                    response.magnitudesList[0].magnitudeFriendly;
            }
        });
    }

    activate() {
        this.getRoofGeometryDataFromPRData();
        this.getRoofGeometryMetaData();
        this.$scope.$emit('showGraphicToggle', false);
    }

    getImage() {
        if (this.roofGeometryData.GeometryType === 0) {
            return this._getGraphicAccordingToDimension(true);
        } else {
            return this._getGraphicAccordingToDimension(false);
        }
    }

    _getGraphicAccordingToDimension(isHipBeam) {
        if (isHipBeam) {
            if (!this.roofGeometryData.HasIntermediateSupport) {
                if (
                    this.roofGeometryData.GeometryType === 0 &&
                    !this.roofGeometryData.OverhangInputsVisible
                ) {
                    return hipGraphicNoPostNoLeaderImage;
                } else {
                    return hipGraphicNoPostImage;
                }
            } else if (
                this.roofGeometryData.IntermediateSupportDimensionIsSide
            ) {
                if (
                    this.roofGeometryData.GeometryType === 0 &&
                    !this.roofGeometryData.OverhangInputsVisible
                ) {
                    return hipGraphicSideWallNoLeaderImage;
                } else {
                    return hipGraphicSideWallImage;
                }
            } else {
                if (
                    this.roofGeometryData.GeometryType === 0 &&
                    !this.roofGeometryData.OverhangInputsVisible
                ) {
                    return hipGraphicEndWallNoLeaderImage;
                } else {
                    return hipGraphicEndWallImage;
                }
            }
        } else {
            if (!this.roofGeometryData.HasIntermediateSupport) {
                return valleyGraphicNoPostImage;
            } else if (
                this.roofGeometryData.IntermediateSupportDimensionIsSide
            ) {
                return valleyGraphicSideWallImage;
            } else {
                return valleyGraphicEndWallImage;
            }
        }
    }

    modifyGeometryType() {
        this.roofGeometryService
            .modifyGeometryType(this.roofGeometryData.GeometryType)
            .then(() => {
                this.getRoofGeometryDataFromPRData();
            });
    }

    modifyIntermediateSupport() {
        this.roofGeometryService
            .modifyIntermediateSupport(
                this.roofGeometryData.HasIntermediateSupport
            )
            .then(() => {
                this.getRoofGeometryDataFromPRData();
            },
            (exception) => {
                if (exception.status === 403) {
                    this.getRoofGeometryDataFromPRData();
                    var message = this.getText(exception.data);
                    this.notifier.fileManagementError(this.getText('error'), message);
                }
            });
    }

    modifyIntermediateSupportDimensionIsSide() {
        this.roofGeometryService
            .modifyIntermediateSupportDimensionIsSide(
                this.roofGeometryData.IntermediateSupportDimensionIsSide
            )
            .then(() => {
                this.getRoofGeometryDataFromPRData();
            });
    }

    modifyIntermediateSupportLength(event) {
        if (this.dirtyCheckService.isDirty(event)) {
            this.roofGeometryData.pending = true;
            this.roofGeometryService
                .modifyIntermediateSupportLength(
                    this.intermediateSupportLengthFriendly
                )
                .then(() => {
                    this.getRoofGeometryDataFromPRData();
                });
        }
    }

    modifySideLength(event) {
        if (this.dirtyCheckService.isDirty(event)) {
            this.roofGeometryData.pending = true;
            this.roofGeometryService
                .modifySideLength(this.sideLengthFriendly)
                .then(() => {
                    this.getRoofGeometryDataFromPRData();
                });
        }
    }

    modifySideSlope(event) {
        if (this.dirtyCheckService.isDirty(event)) {
            this.roofGeometryData.pending = true;
            this.roofGeometryService
                .modifySideSlope(this.sideSlopeFriendly)
                .then(() => {
                    this.getRoofGeometryDataFromPRData();
                });
        }
    }

    modifyEndLength(event) {
        if (this.dirtyCheckService.isDirty(event)) {
            this.roofGeometryData.pending = true;
            this.roofGeometryService
                .modifyEndLength(this.endLengthFriendly)
                .then(() => {
                    this.getRoofGeometryDataFromPRData();
                });
        }
    }

    modifyEndSlope(event) {
        if (this.dirtyCheckService.isDirty(event)) {
            this.roofGeometryData.pending = true;
            this.roofGeometryService
                .modifyEndSlope(this.endSlopeFriendly)
                .then(() => {
                    this.getRoofGeometryDataFromPRData();
                });
        }
    }

    modifySideOverhang(event) {
        if (this.dirtyCheckService.isDirty(event)) {
            this.roofGeometryData.pending = true;
            this.roofGeometryService
                .modifySideOverhang(this.sideOverhangFriendly)
                .then(() => {
                    if (this.sideOverhangFriendly === '') {
                        this.disableElementIdOverhang = '';
                    } else {
                        this.disableElementIdOverhang = 'EndOverhang';
                    }
                    this.getRoofGeometryDataFromPRData();
                });
        }
    }

    modifyEndOverhang(event) {
        if (this.dirtyCheckService.isDirty(event)) {
            this.roofGeometryData.pending = true;
            this.roofGeometryService
                .modifyEndOverhang(this.endOverhangFriendly)
                .then(() => {
                    if (this.endOverhangFriendly === '') {
                        this.disableElementIdOverhang = '';
                    } else {
                        this.disableElementIdOverhang = 'SideOverhang';
                    }
                    this.getRoofGeometryDataFromPRData();
                });
        }
    }

    modifyLiveLoadType() {
        this.roofGeometryService
            .modifyLiveLoadType(this.roofGeometryData.LiveLoadType)
            .then(() => {
                this.getRoofGeometryDataFromPRData();
            });
    }

    modifyDeadLoad() {
        this.roofGeometryService
            .modifyDeadLoad(this.deadLoadFriendly)
            .then(() => {
                this.getRoofGeometryDataFromPRData();
            });
    }

    modifyLiveLoad() {
        this.roofGeometryService
            .modifyLiveLoad(this.liveLoadFriendly)
            .then(() => {
                this.getRoofGeometryDataFromPRData();
            });
    }

    modifyHighEndSupportType() {
        this.roofGeometryService
            .modifyHighEndSupportType(this.roofGeometryData.HighEndSupportType)
            .then(() => {
                this.getRoofGeometryDataFromPRData();
            });
    }

    saveRoofGeometryAsDefaults() {
        this.roofGeometryService.saveRoofGeometryAsDefaults().then(() => {
            this.getRoofGeometryDataFromPRData();
        });
    }

    clearRoofGeometryValues() {
        this.roofGeometryData.pending = true;
        this.roofGeometryService.clearRoofGeometryValues().then(() => {
            this.disableElementId = null;
            this.disableElementIdOverhang = null;
            this.getRoofGeometryDataFromPRData();
        });
    }
}
// (function () {
//     'use strict';
//     var app = angular.module('app');

//     app.controller('roofGeometryController', ['$scope', '$timeout', 'common', 'resourceMessagingService', 'allModelDataService', 'roofGeometryService', 'dirtyCheckService', 'tabNavigationService',
//         function ($scope, $timeout, common, resourceMessagingService, allModelDataService, roofGeometryService, dirtyCheckService, tabNavigationService) {
//             var vm = this;
//             var $q = common.$q;

//             vm.getText = resourceMessagingService.getText;
//             vm.getImage = getImage;
//             vm.modifyGeometryType = modifyGeometryType;
//             vm.modifyIntermediateSupport = modifyIntermediateSupport;
//             vm.modifyIntermediateSupportDimensionIsSide = modifyIntermediateSupportDimensionIsSide;
//             vm.modifyIntermediateSupportLength = modifyIntermediateSupportLength;
//             vm.modifySideLength = modifySideLength;
//             vm.modifySideSlope = modifySideSlope;
//             vm.modifyEndLength = modifyEndLength;
//             vm.modifyEndSlope = modifyEndSlope;
//             vm.modifySideOverhang = modifySideOverhang;
//             vm.modifyEndOverhang = modifyEndOverhang;
//             vm.modifyLiveLoadType = modifyLiveLoadType;
//             vm.modifyDeadLoad = modifyDeadLoad;
//             vm.modifyLiveLoad = modifyLiveLoad;
//             vm.modifyHighEndSupportType = modifyHighEndSupportType;
//             vm.saveRoofGeometryAsDefaults = saveRoofGeometryAsDefaults;
//             vm.clearRoofGeometryValues = clearRoofGeometryValues;
//             vm.selectAllTextOnFocus = selectAllTextOnFocus;
//             vm.keyDown = keyDown;
//             vm.getIntermediateSupportClass = getIntermediateSupportClass;

//             vm.roofGeometryData = null;
//             vm.roofGeometryMetaData = null;
//             vm.liveLoadFriendly = null;
//             vm.deadLoadFriendly = null;
//             vm.sideLengthFriendly = null;
//             vm.sideOverhangFriendly = null;
//             vm.sideSlopeFriendly = null;
//             vm.endLengthFriendly = null;
//             vm.endOverhangFriendly = null;
//             vm.endSlopeFriendly = null;
//             vm.intermediateSupportLengthFriendly = null;
//             vm.disableElementId = null;
//             vm.disableElementIdOverhang = null;
//             vm.warningMessage = vm.getText('hipChangeInputWarning').replace(/~1/g, '\n');

//             activate();

//             function selectAllTextOnFocus($event) {
//                 // console.log($event.currentTarget.name + '**********' + vm.disableElementId + '**********' + vm.disableElementIdOverhang);
//                 $event.target.select();
//             }

//             function keyDown(isPending, $event) {
//                 //ignore everything accept navigation input if the column is "pending"
//                 if (isPending) {
//                     if (!($event.keyCode === 9 || $event.keyCode === 16)) { //tab and shift keycodes
//                         $event.stopPropagation();
//                         $event.preventDefault();
//                         //console.log('stopped: ' + $event.keyCode);
//                         return false;
//                     }
//                 }
//             }

//             function getIntermediateSupportClass() {
//                 if (vm.roofGeometryData.GeometryType === 0) {
//                     if (vm.roofGeometryData.IntermediateSupportDimensionIsSide) {
//                         return 'sideIntermediateSupportHip';
//                     } else {
//                         return 'endIntermediateSupportHip';
//                     }
//                 } else {
//                     if (vm.roofGeometryData.IntermediateSupportDimensionIsSide) {
//                         return 'sideIntermediateSupportValley';
//                     } else {
//                         return 'endIntermediateSupportValley';
//                     }
//                 }
//             }

//             function getRoofGeometryMetaData() {
//                 roofGeometryService.getMetaData().then(function(res) {
//                     vm.roofGeometryMetaData = res;
//                 });
//             }

//             function getRoofGeometryDataFromPRData() {
//                 vm.roofGeometryData = allModelDataService.getPostResponseData().MemberData.RoofGeometryData;
//                 vm.disableElementId = vm.roofGeometryData.CalculatedValueID;
//                 _getMagnitudeFriendly(true, vm.roofGeometryData.LiveLoad);
//                 _getMagnitudeFriendly(false, vm.roofGeometryData.DeadLoad);
//                 _convertAllLengths();
//                 vm.sideSlopeFriendly = vm.roofGeometryData.SideSlope === 'NaN' ? '' : vm.roofGeometryData.SideSlope;
//                 vm.endSlopeFriendly = vm.roofGeometryData.EndSlope === 'NaN' ? '' : vm.roofGeometryData.EndSlope;
//             }

//             function _convertAllLengths() {
//                 var UOMSetting = allModelDataService.getPostResponseData().ApplicationData.ProgramSettingsData.UOM;
//                 var data = {
//                     'UOMSetting': UOMSetting,
//                     'lengthsList': [
//                         {'lengthFriendly': '', 'lengthValue': vm.roofGeometryData.SideLength === 'NaN' ? 0 : vm.roofGeometryData.SideLength},
//                         {'lengthFriendly': '', 'lengthValue': vm.roofGeometryData.SideOverhang === 'NaN' ? 0 : vm.roofGeometryData.SideOverhang},
//                         {'lengthFriendly': '', 'lengthValue': vm.roofGeometryData.EndLength === 'NaN' ? 0 : vm.roofGeometryData.EndLength},
//                         {'lengthFriendly': '', 'lengthValue': vm.roofGeometryData.EndOverhang === 'NaN' ? 0 : vm.roofGeometryData.EndOverhang},
//                         {'lengthFriendly': '', 'lengthValue': vm.roofGeometryData.IntermediateSupportLength === 'NaN' ? 0 : vm.roofGeometryData.IntermediateSupportLength}
//                     ]
//                 };
//                 roofGeometryService.convertLengthsList(data).then(function(response) {
//                     vm.sideLengthFriendly = response.lengthsList[0].lengthValue === 0 ? '' : response.lengthsList[0].lengthFriendly;
//                     vm.sideOverhangFriendly = response.lengthsList[1].lengthValue === 0 ? '' : response.lengthsList[1].lengthFriendly;
//                     vm.endLengthFriendly = response.lengthsList[2].lengthValue === 0 ? '' : response.lengthsList[2].lengthFriendly;
//                     vm.endOverhangFriendly = response.lengthsList[3].lengthValue === 0 ? '' : response.lengthsList[3].lengthFriendly;
//                     vm.intermediateSupportLengthFriendly = response.lengthsList[4].lengthValue === 0 ? '' : response.lengthsList[4].lengthFriendly;
//                     vm.formRoofGeometry.$setPristine();
//                 });
//             }

//             function _getMagnitudeFriendly(isLiveLoad, rawValue) {
//                 var UOMSetting = allModelDataService.getPostResponseData().ApplicationData.ProgramSettingsData.UOM;
//                 // LoadType: Uniform (PSF)
//                 var data = {
//                     'UOMSetting': UOMSetting,
//                     'loadType': 3,
//                     'magnitudesList': [ {
//                         'loadClass': vm.roofGeometryData.LiveLoadType,
//                         'magnitudeValue': rawValue,
//                         'magnitudeFriendly': ''
//                     } ]
//                 };

//                 roofGeometryService.getMagnitudeFriendly(data).then(function(response) {
//                     if (isLiveLoad) {
//                         vm.liveLoadFriendly = response.magnitudesList[0].magnitudeFriendly;
//                     } else {
//                         vm.deadLoadFriendly = response.magnitudesList[0].magnitudeFriendly;
//                     }
//                 });
//             }

//             function activate() {
//                 getRoofGeometryDataFromPRData();
//                 getRoofGeometryMetaData();
//                 $scope.$emit('showGraphicToggle', false);
//             }

//             function getImage() {
//                 if (vm.roofGeometryData.GeometryType === 0) {
//                     return _getGraphicAccordingToDimension(true);
//                 } else {
//                     return _getGraphicAccordingToDimension(false);
//                 }
//             }

//             function _getGraphicAccordingToDimension(isHipBeam) {
//                 var imgName = isHipBeam ? 'mHipGraphic_' : 'mValleyGraphic_';

//                 if (!vm.roofGeometryData.HasIntermediateSupport) {
//                     imgName += 'NoPost';
//                 } else if (vm.roofGeometryData.IntermediateSupportDimensionIsSide) {
//                     imgName += 'SideWall';
//                 } else {
//                     imgName += 'EndWall';
//                 }

//                 if (vm.roofGeometryData.GeometryType === 0 && !vm.roofGeometryData.OverhangInputsVisible) {
//                     imgName += '_NoLeader';
//                 }
//                 return imgName + '.png';
//             }

//             function modifyGeometryType() {
//                 roofGeometryService.modifyGeometryType(vm.roofGeometryData.GeometryType).then(function() {
//                     getRoofGeometryDataFromPRData();
//                 });
//             }

//             function modifyIntermediateSupport() {
//                 roofGeometryService.modifyIntermediateSupport(vm.roofGeometryData.HasIntermediateSupport).then(function() {
//                     getRoofGeometryDataFromPRData();
//                 });
//             }

//             function modifyIntermediateSupportDimensionIsSide() {
//                 roofGeometryService.modifyIntermediateSupportDimensionIsSide(vm.roofGeometryData.IntermediateSupportDimensionIsSide).then(function() {
//                     getRoofGeometryDataFromPRData();
//                 });
//             }

//             function modifyIntermediateSupportLength(event) {
//                 if (dirtyCheckService.isDirty(event)) {
//                     vm.roofGeometryData.pending = true;
//                     roofGeometryService.modifyIntermediateSupportLength(vm.intermediateSupportLengthFriendly).then(function() {
//                         getRoofGeometryDataFromPRData();
//                     });
//                 }
//             }

//             function modifySideLength(event) {
//                 if (dirtyCheckService.isDirty(event)) {
//                     vm.roofGeometryData.pending = true;
//                     roofGeometryService.modifySideLength(vm.sideLengthFriendly).then(function() {
//                         getRoofGeometryDataFromPRData();
//                     });
//                 }
//             }

//             function modifySideSlope(event) {
//                 if (dirtyCheckService.isDirty(event)) {
//                     vm.roofGeometryData.pending = true;
//                     roofGeometryService.modifySideSlope(vm.sideSlopeFriendly).then(function() {
//                         getRoofGeometryDataFromPRData();
//                     });
//                 }
//             }

//             function modifyEndLength(event) {
//                 if (dirtyCheckService.isDirty(event)) {
//                     vm.roofGeometryData.pending = true;
//                     roofGeometryService.modifyEndLength(vm.endLengthFriendly).then(function() {
//                         getRoofGeometryDataFromPRData();
//                     });
//                 }
//             }

//             function modifyEndSlope(event) {
//                 if (dirtyCheckService.isDirty(event)) {
//                     vm.roofGeometryData.pending = true;
//                     roofGeometryService.modifyEndSlope(vm.endSlopeFriendly).then(function() {
//                         getRoofGeometryDataFromPRData();
//                     });
//                 }
//             }

//             function modifySideOverhang(event) {
//                 if (dirtyCheckService.isDirty(event)) {
//                     vm.roofGeometryData.pending = true;
//                     roofGeometryService.modifySideOverhang(vm.sideOverhangFriendly).then(function() {
//                         if (vm.sideOverhangFriendly === '') {
//                             vm.disableElementIdOverhang = '';
//                         }
//                         else {
//                             vm.disableElementIdOverhang = 'EndOverhang';
//                         }
//                         getRoofGeometryDataFromPRData();
//                     });
//                 }
//             }

//             function modifyEndOverhang(event) {
//                 if (dirtyCheckService.isDirty(event)) {
//                     vm.roofGeometryData.pending = true;
//                     roofGeometryService.modifyEndOverhang(vm.endOverhangFriendly).then(function() {
//                         if (vm.endOverhangFriendly === '') {
//                             vm.disableElementIdOverhang = '';
//                         }
//                         else {
//                             vm.disableElementIdOverhang = 'SideOverhang';
//                         }
//                         getRoofGeometryDataFromPRData();
//                     });
//                 }
//             }

//             function modifyLiveLoadType() {
//                 roofGeometryService.modifyLiveLoadType(vm.roofGeometryData.LiveLoadType).then(function() {
//                     getRoofGeometryDataFromPRData();
//                 });
//             }

//             function modifyDeadLoad() {
//                 roofGeometryService.modifyDeadLoad(vm.deadLoadFriendly).then(function() {
//                     getRoofGeometryDataFromPRData();
//                 });
//             }

//             function modifyLiveLoad() {
//                 roofGeometryService.modifyLiveLoad(vm.liveLoadFriendly).then(function() {
//                     getRoofGeometryDataFromPRData();
//                 });
//             }

//             function modifyHighEndSupportType() {
//                 roofGeometryService.modifyHighEndSupportType(vm.roofGeometryData.HighEndSupportType).then(function() {
//                     getRoofGeometryDataFromPRData();
//                 });
//             }

//             function saveRoofGeometryAsDefaults() {
//                 roofGeometryService.saveRoofGeometryAsDefaults().then(function() {
//                     getRoofGeometryDataFromPRData();
//                 });
//             }

//             function clearRoofGeometryValues() {
//                 vm.roofGeometryData.pending = true;
//                 roofGeometryService.clearRoofGeometryValues().then(function() {
//                     vm.disableElementId = null;
//                     vm.disableElementIdOverhang = null;
//                     getRoofGeometryDataFromPRData();
//                 });
//             }

//         }]);
// }());
