export default class locationAnalysisService {
    constructor($http, common, allModelDataService, postService) {
        'ngInject';

        this.$http = $http;
        // this.serviceBase = ngAuthSettings.apiServiceBaseUri;
        this.$q = common.$q();
        this.allModelDataService = allModelDataService;
        this.postService = postService;

        this.httpRequestQueueCounter = 0;
        this.inputQueuePromise;
        this.somethingWaitingOnQueueToFinish = false;
    }

    getQueueCounter() {
        this.inputQueuePromise = this.$q.defer();
        if (this.httpRequestQueueCounter === 0)
        {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.somethingWaitingOnQueueToFinish = true;
        }
        return this.inputQueuePromise.promise;
    }

    getLocationData() {
        var prData = this.allModelDataService.getPostResponseData();
        return prData.MemberData.LocationAnalysisData.LocationAnalysisPoints;
    }

    getLocationMetaData() {
        var data = {};
        return this._requestPostEvent('GetLocationMetaData', data);
    }

    getMeasureFromLeftNote() {
        var data = {};
        return this._requestPostEvent('GetMeasureFromLeftNote', data);
    }

    getAllDimensionsAreHorizontalNote() {
        var data = {};
        return this._requestPostEvent('GetAllDimensionsAreHorizontalNote', data);
    }

    getNotes() {
        return this._requestGetEvent('GetNotes');
    }

    getAllData() {
        var data = {};
        return this._requestPostEvent('GetAllData', data);
    }

    addLocation() {
        var data = {};
        return this._requestPostEvent('AddLocation', data);
    }


    deleteLocation(locationIndex) {
        var data = {
            index: locationIndex
        };
        return this._requestPostEvent('RemoveLocation', data);
    }

    modifyLocation(locationIndex, newLocation) {
        var data = {
            index: locationIndex,
            newValue: newLocation
        };
        return this._requestPostEvent('ModifyLocation', data);
    }

    modifyLocationComment(locationIndex, newLocationComment) {
        var data = {
            index: locationIndex,
            newValue: newLocationComment
        };
        return this._requestPostEvent('ModifyComment', data);
    }

    _requestGetEvent(event) {
        var deferred = this.$q.defer();
        this._incrementQueueCounter();

        this.$http.get(this.serviceBase + 'api/LocationAnalysis/' + event)
            .then((response) => {
                this._decrementQueueCounter();
                deferred.resolve(response);
            }, (err, status) => {
                this._decrementQueueCounter();
                deferred.reject(err);
            });

        return deferred.promise;
    }

    _requestPostEvent(locationEvent, locationData) {
        var path = 'api/LocationAnalysis/';
        return this.postService.requestPostEvent(locationEvent,locationData,path);
    }

    _incrementQueueCounter() {
        this.httpRequestQueueCounter++;
    }
    _decrementQueueCounter() {
        this.httpRequestQueueCounter--;
        if (this.somethingWaitingOnQueueToFinish && this.httpRequestQueueCounter === 0) {
            this.somethingWaitingOnQueueToFinish = false;
            this.inputQueuePromise.resolve();
        }
    }
}
// (function() {
//     'use strict';
//     var app = angular.module('app');
//     var serviceId = 'locationAnalysisService';
//     app.factory(serviceId, ['$http', 'ngAuthSettings', 'common',
//         'allModelDataService', 'postService', locationAnalysisService]);


//     function locationAnalysisService($http, ngAuthSettings,
//         common, allModelDataService, postService) {
//         var $q = common.$q;
//         var notesPromise = $q.defer();
//         var locationDataPromise = $q.defer();
//         var serviceBase = ngAuthSettings.apiServiceBaseUri;
//         var httpRequestQueueCounter = 0;
//         var inputQueuePromise;
//         var somethingWaitingOnQueueToFinish = false;
//         var service = {
//             getQueueCounter: getQueueCounter,
//             getLocationData: getLocationData,
//             getLocationMetaData: getLocationMetaData,
//             getMeasureFromLeftNote: getMeasureFromLeftNote,
//             getAllDimensionsAreHorizontalNote: getAllDimensionsAreHorizontalNote,
//             getNotes: getNotes,
//             getAllData: getAllData,
//             addLocation: addLocation,
//             deleteLocation: deleteLocation,
//             modifyLocation: modifyLocation,
//             modifyLocationComment: modifyLocationComment
//         };
//         function getQueueCounter() {
//             inputQueuePromise = $q.defer();
//             if (httpRequestQueueCounter === 0)
//             {
//                 inputQueuePromise.resolve();
//                 return inputQueuePromise.promise;
//             }
//             else {
//                 somethingWaitingOnQueueToFinish = true;
//             }
//             return inputQueuePromise.promise;
//         }

//         function getLocationData() {
//             var prData = allModelDataService.getPostResponseData();
//             return prData.MemberData.LocationAnalysisData.LocationAnalysisPoints;
//         }

//         function getLocationMetaData() {
//             var data = {};
//             return _requestPostEvent('GetLocationMetaData', data);
//         }

//         function getMeasureFromLeftNote() {
//             var data = {};
//             return _requestPostEvent('GetMeasureFromLeftNote', data);
//         }

//         function getAllDimensionsAreHorizontalNote() {
//             var data = {};
//             return _requestPostEvent('GetAllDimensionsAreHorizontalNote', data);
//         }

//         function getNotes() {
//             return _requestGetEvent('GetNotes');
//         }

//         function getAllData() {
//             var data = {};
//             return _requestPostEvent('GetAllData', data);
//         }

//         function addLocation() {
//             var data = {};
//             return _requestPostEvent('AddLocation', data);
//         }


//         function deleteLocation(locationIndex) {
//             var data = {
//                 index: locationIndex
//             };
//             return _requestPostEvent('RemoveLocation', data);
//         }

//         function modifyLocation(locationIndex, newLocation) {
//             var data = {
//                 index: locationIndex,
//                 newValue: newLocation
//             };
//             return _requestPostEvent('ModifyLocation', data);
//         }

//         function modifyLocationComment(locationIndex, newLocationComment) {
//             var data = {
//                 index: locationIndex,
//                 newValue: newLocationComment
//             };
//             return _requestPostEvent('ModifyComment', data);
//         }

//         function _requestGetEvent(event) {
//             var deferred = $q.defer();
//             _incrementQueueCounter();

//             $http.get(serviceBase + 'api/LocationAnalysis/' + event)
//                 .then(function success(response) {
//                     _decrementQueueCounter();
//                     deferred.resolve(response);
//                 }, function error(err, status) {
//                     _decrementQueueCounter();
//                     deferred.reject(err);
//                 });

//             return deferred.promise;
//         }

//         function _requestPostEvent(locationEvent, locationData) {
//             var path = 'api/LocationAnalysis/';
//             return postService.requestPostEvent(locationEvent,locationData,path);
//         }

//         function _incrementQueueCounter() {
//             httpRequestQueueCounter++;
//         }
//         function _decrementQueueCounter() {
//             httpRequestQueueCounter--;
//             if (somethingWaitingOnQueueToFinish && httpRequestQueueCounter === 0) {
//                 somethingWaitingOnQueueToFinish = false;
//                 inputQueuePromise.resolve();
//             }
//         }

//         return service;
//     }

// }());
