import angular from 'angular';

export const preservativeTreatment = {
    none: 0,
    PSLWolmanizedSL1DryUse: 1,
    PSLWolmanizedSL2Moist: 2,
    PSLWolmanizedSL3Wet: 3
};
export default angular.module('forteEnumDefinitions', [])
    .constant('preservativeTreatment', preservativeTreatment)
    .name;
