import overrideBootstrapTabs from './overrideTabs.html';
export default function overrideTabs ($provide) {
    'ngInject';
    $provide.decorator('uibTabDirective', ($delegate) => {
        var directive = $delegate[0];
        directive.templateUrl = 'overrideTabs.html';//= '<li class=\'uib-tab\'><a ng-click=\'select()\' uib-tab-heading-transclude>{{heading}}</a></li>';

        return $delegate;
    });
}
