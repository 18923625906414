export default function idleProviderConfig (IdleProvider) {
    'ngInject';
    IdleProvider.idle(60 * 30);
    IdleProvider.timeout(20);
}

// app.config(['IdleProvider', 'KeepaliveProvider', 'ngAuthSettings',
// function (IdleProvider, KeepaliveProvider, ngAuthSettings) {
//     IdleProvider.idle(60 * 30);
//     IdleProvider.timeout(60);
// }]);
