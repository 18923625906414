export default class saveAsDefaultButtonController {
    constructor(common, allModelDataService, postService) {
        'ngInject';

        this.$q = common.$q();
        this.allModelDataService = allModelDataService;
        this.postService = postService;
    }

    getMetadata() {
        var data = {};
        return this._requestPostEvent('GetJobSettingsMetaData', data);
    }

    getJobSettingsData() {
        var prData = this.allModelDataService.getPostResponseData();
        return prData.ApplicationData.JobSettingsData;
    }

    getProjectManagerData() {
        var prData = this.allModelDataService.getPostResponseData();
        return prData.ApplicationData.ProjectManagerData;
    }

    _requestPostEvent(jobSettingsEvent, jobSettingsData) {
        var path = 'api/JobSettings/';
        return this.postService.requestPostEvent(jobSettingsEvent, jobSettingsData, path);
    }

    modifyBuildingCode(data) {
        return this._requestPostEvent('ModifyBuildingCode', data);
    }

    modifyNBCCProvincialCodeData(data) {
        return this._requestPostEvent('ModifyProvincialCode', data);
    }

    modifyVibrationModification(data) {
        return this._requestPostEvent('ModifyVibrationModification', data);
    }


    modifyConnectorMfg(data) {
        return this._requestPostEvent('ModifyConnectorMfg', data);
    }

    modifyPostalCode(data) {
        return this._requestPostEvent('ModifyPostalCode', data);
    }

    modifyJobNotes(data) {
        return this._requestPostEvent('ModifyJobNotes', data);
    }

    modifySoftwareOperator(data) {
        return this._requestPostEvent('ModifySoftwareOperator', data);
    }

    modifyLDFFloor(data) {
        return this._requestPostEvent('ModifyLDFValue', data);
    }

    modifyLDFRoof(data) {
        return this._requestPostEvent('ModifyLDFValue', data);
    }

    modifyLDFSnow(data) {
        return this._requestPostEvent('ModifyLDFValue', data);
    }

    modifyLDFWind(data) {
        return this._requestPostEvent('ModifyLDFValue', data);
    }

    modifyLDFSeismic(data) {
        return this._requestPostEvent('ModifyLDFValue', data);
    }

    saveJobSettingsUserDefaults() {
        var currentPostResponseData = this.allModelDataService.getPostResponseData();
        return this._requestPostEvent('SaveJobSettingsAsDefaults', currentPostResponseData);
    }
}
