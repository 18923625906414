import { all } from '@uirouter/core';
import myVerticalGraphicTemplate from './myVertGraphic.html';
export default class myVerticalGraphic {
    constructor() {
        this.template = myVerticalGraphicTemplate;
        this.restrict = 'AE';
        this.scope = {
            graphicid: '=',
        };
    }
    controller($rootScope, $scope, allModelDataService) {
        'ngInject';
        $scope.refreshVerticalGraphicListner;
        $scope.$rootScope = $rootScope;
        $scope.$scope = $scope;
        $scope.allModelDataService = allModelDataService;

        $scope.graphicDataURL = null;

        $scope.newGraphicToggle = true;

        function refreshVerticalGraphic(graphicData, graphicId) {
            $scope.graphicDataURL =
                'data:image/png;base64,' + graphicData.imageDataURL;
            allModelDataService.setGraphicDataURL($scope.graphicDataURL);
        }

        //this event handler handles the broadcast from the shell controller
        $scope.refreshVerticalGraphicListner = $rootScope.$on(
            'refreshVerticalGraphic',
            (event, data) => {
                refreshVerticalGraphic(data, $scope.graphicid);
            }
        );

        $scope.$on('$destroy', () => {
            $scope.refreshVerticalGraphicListner();
        });
    }
}
// (function () {
//     'use strict';
//     angular.module('app').directive('myVertGraphic', [ function () {
//         return {
//             restrict: 'AE',
//             controller: ['$rootScope','$scope', 'ngAuthSettings', 'common', 'allModelDataService',
//                 function($rootScope, $scope, ngAuthSettings, common, allModelDataService) {
//                     var $q = common.$q;

//                     var refreshGraphicListner;
//                     var landscapeMode = false;
//                     var renderedTextUrl;
//                     var renderedGfx;
//                     $scope.isGraphicVisible;

//                     var activate = function () {
//                         $scope.isGraphicVisible = false;
//                     };

//                     activate();

//                     function refreshGraphic(graphicData, graphicId) {
//                         $scope.graphicDataURL = 'data:image/png;base64,' + graphicData.imageDataURL;
//                         allModelDataService.setGraphicDataURL($scope.graphicDataURL);
//                     }

//                     //this event handler handles the broadcast from the shell controller
//                     refreshGraphicListner = $rootScope.$on('refreshVerticalGraphic', function (event, data) {
//                         refreshGraphic(data, $scope.graphicid);
//                     });

//                     $scope.$on('$destroy', function() {
//                         refreshGraphicListner();
//                         //handleDrawGraphicForPrintReport();
//                     });
//                 }
//             ],
//             scope: {
//                 graphicid: '='
//             },
//             templateUrl: 'app/directives/myVertGraphic.html'
//         };
//     } ]);
// }());
