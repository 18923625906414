export default class smartNavService {
    constructor($state) {
        'ngInject';

        this.$state = $state;
        this.addingNewFile = false;

    }
    navigateOrReload(link) {
        if (this.$state.current.name === link) {
            //this refreshes the scope for this link as opposed to reloading all scopes
            //(e.g. reload member info instead of shell & member info)
            this.$state.go(link, {}, {reload: link});
        } else {
            this.$state.go(link);
        }
    }
    //not entirely sure this is necessary. it looked like this var was exposed by the old iffe code.
    addingNewFile() {
        return this.addingNewFile;
    }
    reloadCurrent() {
        var current = this.$state.current.name;
        this.$state.go(current, {}, {reload: current});
    }

    reloadIfCurrentStateIsReport() {
        if (this.$state.current.name === 'application.job.report') {
            this.navigateOrReload('application.job.report');
        }
    }
}
// (function () {
//     'use strict';
//     var app = angular.module('app');

//     app.factory('smartNavService', ['$state',
//         function ($state) {

//             var smartNavService = {
//                 navigateOrReload: navigateOrReload,
//                 reloadCurrent: reloadCurrent,
//                 reloadIfCurrentStateIsReport: reloadIfCurrentStateIsReport,
//             };
//             smartNavService.addingNewFile = false;
//             function navigateOrReload(link) {
//                 if ($state.current.name === link) {
//                     //this refreshes the scope for this link as opposed to reloading all scopes
//                     //(e.g. reload member info instead of shell & member info)
//                     $state.go(link, {}, {reload: link});
//                 } else {
//                     $state.go(link);
//                 }
//             }

//             function reloadCurrent() {
//                 var current = $state.current.name;
//                 $state.go(current, {}, {reload: current});
//             }

//             function reloadIfCurrentStateIsReport() {
//                 if ($state.current.name === 'application.job.report') {
//                     navigateOrReload('application.job.report');
//                 }
//             }

//             return smartNavService;
//         }]);
// }());
