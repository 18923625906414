import ngAuthSettings from '../environmentConfigs/environment.dev';
export default class reportService {
    constructor(common,  allModelDataService, spinner, forteCookieService, postService, resourceMessagingService) {
        'ngInject';
        this.$q = common.$q();
        this.allModelDataService = allModelDataService;
        this.spinner = spinner;
        this.forteCookieService = forteCookieService;
        this.postService = postService;
        this.resourceMessagingService = resourceMessagingService;
        this.serviceBase = ngAuthSettings.apiServiceBaseUri;

        this.httpRequestQueueCounter = 0;
        this.inputQueuePromise;
        this.somethingWaitingOnQueueToFinish = false;
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    assembleMessage(message) {
        var retMsg = this.resourceMessagingService.getText(message.MessageKey);
        var needsSpace;
        if (message.MessageKey === 'AdditionalFloorPerformanceConsiderations') {
            retMsg = retMsg.slice(0,-2); // remove ~1
            for (var pindex = 0; pindex < message.MessageParameters.length; pindex++)
            {
                if (message.MessageParameters[pindex] === ',') {
                    retMsg += ', ';
                }
                else {
                    if (message.MessageParameters[pindex] === ' ') {
                        retMsg += ' ';
                    }
                    else {
                        retMsg += this.resourceMessagingService.getText(message.MessageParameters[pindex]);
                    }
                }
            }
            retMsg += '.';
        }
        else {
            for (var paramIndex = 0; paramIndex < message.MessageParameters.length; paramIndex++) {
                var replacee = '~' + (paramIndex + 1);
                var replacer = message.MessageParameters[paramIndex];
                var parameter = this.getText(replacer);
                if (parameter) {
                    replacer = parameter;
                }
                if (retMsg) {
                    retMsg = retMsg.replace(replacee, replacer);
                } else {
                    retMsg = this.resourceMessagingService.getText(message.MessageKey);
                }
            }
        }

        return retMsg;
    }

    getQueueCounter() {
        this.inputQueuePromise = this.$q.defer();
        if (this.httpRequestQueueCounter === 0)
        {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.somethingWaitingOnQueueToFinish = true;
        }
        return this.inputQueuePromise.promise;
    }

    modifyLateralSelectedMethod(supportIndex, newValue) {
        var data = {
            'supportIndex': supportIndex,
            'selectedMethodType': newValue
        };
        return this._requestPostEvent('ModifyLateralSelectedMethod', data);
    }

    modifyLateralSelectedConnector(supportIndex, newValue) {
        var data = {
            'supportIndex': supportIndex,
            'selectedConnectorName': newValue
        };
        return this._requestPostEvent('ModifyLateralSelectedConnector', data);
    }

    getFullDetailReportData(memberIdTag) {
        var data = {
            'MemberIdTag': memberIdTag,
            'echoLogOn': this.allModelDataService.getEchoLogOn()
        };
        return this._requestPostEvent('GetFullDetailReportData', data);
    }

    getHorizReportData() {
        var dto = this._getHorizReportFromPostResponseData();
        if (dto) {
            return this.updateAnyJobLevelInfoThatMightHaveChanged();
        }
        else {
            return this._getHorizReportFromServer();
        }
    }

    getVertReportData() {
        //todo: Zach get it from local storage if it already exists.
        return this._getVertReportFromServer();
    }

    getHorizPDF(graphicData, graphicOverlayData) {
        var prData = this.allModelDataService.getPostResponseData();
        //var horizReportDTO = prData.MemberData.ReportOutputHorizontalData.HorizontalMemberReportData;
        //horizReportDTO.MiscellaneousData[0].MemberGraphic = graphicData;
        var data = {
            postResponseData: prData,
            showFactoredLoadColumn: this.forteCookieService.getFactoredTitleEnabled(),
            constructionDetailImageSetting: this.forteCookieService.getConstructionDetailImageSettingMemberReport(),
            graphicData: graphicData,
            graphicOverlayData: {Labels: graphicOverlayData}
        };

        return this.postService.requestPDFPostEvent(data);
    }

    modifySelectedConnector (eventName, data) {
        return this._requestPostEvent(eventName, data);
    }

    modifySelectedFastener (eventName, data) {
        return this._requestPostEvent(eventName, data);
    }

    updateAnyJobLevelInfoThatMightHaveChanged() {
        var deferred = this.$q.defer();
        var data = {};
        this._requestPostEvent('UpdateAnyJobLevelInfoThatMightHaveChanged', data).then(() => {
            var prData = this.allModelDataService.getPostResponseData();
            if (this.allModelDataService.getIsMemberVertical()) {
                deferred.resolve(prData.MemberData.ReportOutputVerticalData.VerticalMemberReportData);
            }
            else {
                let dto = prData.MemberData.ReportOutputHorizontalData.HorizontalMemberReportData;
                if (dto === null) {
                    deferred.resolve(this._getHorizReportFromServer());
                }
                else {
                    deferred.resolve(dto);
                }
            }
        });
        return deferred.promise;
    }

    _getVertReportFromServer() {
        var deferred = this.$q.defer();
        this.spinner.spinnerShow(this.getText('jobSettingsResetProcessing'));
        var data = {
            'echoLogOn': this.allModelDataService.getEchoLogOn()
        };
        this._requestPostEvent('GetVerticalReportData', data).then(() => {
            var prData = this.allModelDataService.getPostResponseData();
            this.allModelDataService.setEchoLogOff();
            deferred.resolve(prData.MemberData.ReportOutputVerticalData.VerticalMemberReportData);
        });
        return deferred.promise;
    }

    _getHorizReportFromServer() {
        var deferred = this.$q.defer();
        this.spinner.spinnerShow(this.getText('jobSettingsResetProcessing'));
        var data = {
            'echoLogOn': this.allModelDataService.getEchoLogOn()
        };
        this._requestPostEvent('GetReportData', data).then(() => {
            var prData = this.allModelDataService.getPostResponseData();
            this.allModelDataService.setEchoLogOff();
            deferred.resolve(prData.MemberData.ReportOutputHorizontalData.HorizontalMemberReportData);
        });
        return deferred.promise;
    }

    getConnectorTableData(eventName, data) {
        return this._requestPostEvent(eventName, data);
    }

    _getHorizReportFromPostResponseData() {
        var prData = this.allModelDataService.getPostResponseData();
        var reportOutputData = prData.MemberData.ReportOutputHorizontalData;
        if (reportOutputData) {
            var dto = reportOutputData.HorizontalMemberReportData;
            return dto;
        } else {
            return null;
        }
    }

    _requestPostEvent(eventName, data) {
        var path = 'api/ReportOutput/';
        return this.postService.requestPostEvent(eventName,data,path);
    }
    _requestPDFPostEvent(eventName, data) {
        var path = 'api/Values/';
        return this.postService.requestPDFPostEvent(eventName,data,path);
    }

    _incrementQueueCounter() {
        this.httpRequestQueueCounter++;
    }
    _decrementQueueCounter() {
        this.httpRequestQueueCounter--;
        if (this.somethingWaitingOnQueueToFinish && this.httpRequestQueueCounter === 0) {
            this.somethingWaitingOnQueueToFinish = false;
            this.inputQueuePromise.resolve();
        }
    }

}
