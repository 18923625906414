export default class canadaZipInput {
    constructor() {
        this.require = 'ngModel';
        this.scope = {
        };
    }
    controller($scope, $filter, $browser) {
        'ngInject';
        $scope.$filter = $filter;
        $scope.$browser = $browser;
    }
    link(scope, $element, $attrs, ngModelCtrl) {
        var listener = () => {
            // var value = $element.val().replace(/[^0-9a-zA-Z]/g, '');
            var value = $element.val();
            var newValue = '';
            for (var i = 0; i < value.length; i++) {
                if (value[i].match(/[0-9]/)) {
                    newValue += value[i].toString();
                }
                else if (value[i].match(/[A-Z]/)) {
                    newValue += value[i].toString();
                }
                else if (value[i].match(/[a-z]/)) {
                    newValue += value[i].toUpperCase();
                }
            }
            $element.val(scope.$filter('canadaZipInputFilter')(newValue, false));
        };

        // This runs when we update the text field
        ngModelCtrl.$parsers.push((viewValue) => {
            var newValue = '';
            for (var i = 0; i < viewValue.length; i++) {
                if (viewValue[i].match(/[0-9]/)) {
                    newValue += viewValue[i].toString();
                }
                else if (viewValue[i].match(/[A-Z]/)) {
                    newValue += viewValue[i].toString();
                }
                else if (viewValue[i].match(/[a-z]/)) {
                    newValue += viewValue[i].toUpperCase();
                }
            }

            return newValue.slice(0, 6);
        });

        // This runs when the model gets updated on the scope directly and keeps our view in sync
        ngModelCtrl.$render = () => {
            $element.val(scope.$filter('canadaZipInputFilter')(ngModelCtrl.$viewValue, false));
        };

        $element.bind('change', listener);
        $element.bind('keydown', (event) => {
            var key = event.keyCode;
            // If the keys include the CTRL, SHIFT, ALT, or META keys, or the arrow keys, do nothing.
            // This lets us support copy and paste too
            if (key === 91 || (15 < key && key < 19) || (37 <= key && key <= 40)) {
                return;
            }
            scope.$browser.defer(listener); // Have to do this or changes don't get picked up properly
        });

        $element.bind('paste cut', () => {
            scope.$browser.defer(listener);
        });
    }
}
// (function () {
//     'use strict';

//     angular.module('app').directive('canZipInput', ['$filter', '$browser', function($filter, $browser) {
//         return {
//             require: 'ngModel',
//             link: function($scope, $element, $attrs, ngModelCtrl) {
//                 var listener = function() {
//                     // var value = $element.val().replace(/[^0-9a-zA-Z]/g, '');
//                     var value = $element.val();
//                     var newValue = '';
//                     for (var i = 0; i < value.length; i++) {
//                         if (value[i].match(/[0-9]/)) {
//                             newValue += value[i].toString();
//                         }
//                         else if (value[i].match(/[A-Z]/)) {
//                             newValue += value[i].toString();
//                         }
//                         else if (value[i].match(/[a-z]/)) {
//                             newValue += value[i].toUpperCase();
//                         }
//                     }
//                     $element.val($filter('zip')(newValue, false));
//                 };

//                 // This runs when we update the text field
//                 ngModelCtrl.$parsers.push(function(viewValue) {
//                     var newValue = '';
//                     for (var i = 0; i < viewValue.length; i++) {
//                         if (viewValue[i].match(/[0-9]/)) {
//                             newValue += viewValue[i].toString();
//                         }
//                         else if (viewValue[i].match(/[A-Z]/)) {
//                             newValue += viewValue[i].toString();
//                         }
//                         else if (viewValue[i].match(/[a-z]/)) {
//                             newValue += viewValue[i].toUpperCase();
//                         }
//                     }

//                     return newValue.slice(0, 6);
//                 });

//                 // This runs when the model gets updated on the scope directly and keeps our view in sync
//                 ngModelCtrl.$render = function() {
//                     $element.val($filter('zip')(ngModelCtrl.$viewValue, false));
//                 };

//                 $element.bind('change', listener);
//                 $element.bind('keydown', function(event) {
//                     var key = event.keyCode;
//                     // If the keys include the CTRL, SHIFT, ALT, or META keys, or the arrow keys, do nothing.
//                     // This lets us support copy and paste too
//                     if (key === 91 || (15 < key && key < 19) || (37 <= key && key <= 40)) {
//                         return;
//                     }
//                     $browser.defer(listener); // Have to do this or changes don't get picked up properly
//                 });

//                 $element.bind('paste cut', function() {
//                     $browser.defer(listener);
//                 });
//             }

//         };
//     }]);

//     angular.module('app').filter('zip', function () {
//         return function (tel) {
//             if (!tel) { return ''; }

//             var value = tel.toString().trim().replace(/^\+/, '');

//             if (value) {
//                 if (value.length > 3) {
//                     value = value.slice(0, 3) + ' ' + value.slice(3,7);
//                 }
//                 else {
//                     value = value;
//                 }
//             }
//             return value;
//         };
//     });
// }());
