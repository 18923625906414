export default function canadaZipInputFilter () {
    'ngInject';
    return (tel) => {
        if (!tel) { return ''; }

        var value = tel.toString().trim().replace(/^\+/, '');

        if (value) {
            if (value.length > 3) {
                value = value.slice(0, 3) + ' ' + value.slice(3,7);
            }
            else {
                value = value;
            }
        }
        return value;
    };
}
