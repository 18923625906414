import ngAuthSettings from '../environmentConfigs/environment.dev';
export default class levelPropertiesService {
    constructor(common, allModelDataService, postService) {
        'ngInject';
        this.$q = common.$q();
        this.allModelDataService = allModelDataService;
        this.postService = postService;
        this.httpRequestQueueCounter = 0;
        this.inputQueuePromise = null;
        this.somethingWaitingOnQueueToFinish = false;
        this.serviceBase = ngAuthSettings.apiServiceBaseUri;
    }

    getLevelPropertiesData() {
        var prData = this.allModelDataService.getPostResponseData();
        return prData.ApplicationData.MemberManagerData;
    }

    getLevelPropertiesMetaData() {
        var data = {};
        return this._requestPostEvent('GetLevelPropertiesMetaData', data);
    }

    // saveLevelPropertiesAsDefaults(data) {
    //     return _requestPostEvent('SaveLevelPropertiesAsDefaults', data);
    // }

    saveLevelPropertiesAsDefaults() {
        var currentPostResponseData = this.allModelDataService.getPostResponseData();
        return this._requestPostEvent('SaveLevelPropertiesAsDefaults', currentPostResponseData);
    }

    modifyLevelName(data) {
        return this._requestPostEvent('ModifyLevelName', data);
    }

    modifyBuildingClass(data) {
        return this._requestPostEvent('ModifyBuildingClass', data);
    }

    modifyLOverValue(data) {
        return this._requestPostEvent('ModifyLOverValue', data);
    }

    modifyExposureCategory(newValue) {
        var data = {
            'value': newValue
        };
        return this._requestPostEvent('ModifyExposureCategory', data);
    }

    modifyMeanRoofHeight(newValue) {
        var data = {
            'value': newValue
        };
        return this._requestPostEvent('ModifyMeanRoofHeight', data);
    }

    modifyImportanceCategory(newValue) {
        var data = {
            'value': newValue
        };
        return this._requestPostEvent('ModifyImportanceCategory', data);
    }

    modifyExposureCategoryNBCC(newValue) {
        var data = {
            'value': newValue
        };
        return this._requestPostEvent('ModifyExposureCategoryNBCC', data);
    }

    modifyLOverValueNBCC(data) {
        return this._requestPostEvent('ModifyLOverValueNBCC', data);
    }

    modifyRidgeHeight(newValue) {
        var data = {
            'value': newValue
        };
        return this._requestPostEvent('ModifyRidgeHeight', data);
    }

    modifyEaveHeight(newValue) {
        var data = {
            'value': newValue
        };
        return this._requestPostEvent('ModifyEaveHeight', data);
    }

    modifyDistanceChange(newValue) {
        var data = {
            'value': newValue
        };
        return this._requestPostEvent('ModifyDistanceChange', data);
    }

    _requestPostEvent(LevelPropertiesEvent, LevelPropertiesData) {
        var path = 'api/LevelProperties/';
        return this.postService.requestPostEvent(LevelPropertiesEvent, LevelPropertiesData, path);
    }

    _incrementQueueCounter() {
        this.httpRequestQueueCounter++;
    }

    _decrementQueueCounter() {
        this.httpRequestQueueCounter--;
        if (this.somethingWaitingOnQueueToFinish && this.httpRequestQueueCounter === 0) {
            this.somethingWaitingOnQueueToFinish = false;
            this.inputQueuePromise.resolve();
        }
    }


    getQueueCounter() {
        this.inputQueuePromise = this.$q.defer();
        if (this.httpRequestQueueCounter === 0) {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.somethingWaitingOnQueueToFinish = true;
        }
        return this.inputQueuePromise.promise;
    }
}
// (function () {
//     'use strict';
//     var app = angular.module('app');
//     var serviceId = 'levelPropertiesService';
//     app.factory(serviceId, ['$http', 'ngAuthSettings', 'common', 'allModelDataService', 'postService', levelPropertiesService]);

//     function levelPropertiesService($http, ngAuthSettings, common, allModelDataService, postService) {
//         var $q = common.$q;
//         var serviceBase = ngAuthSettings.apiServiceBaseUri;
//         var httpRequestQueueCounter = 0;
//         var inputQueuePromise;
//         var somethingWaitingOnQueueToFinish = false;
//         //public methods declarations
//         var service = {
//             getQueueCounter: getQueueCounter,
//             getLevelPropertiesMetaData: getLevelPropertiesMetaData,
//             getLevelPropertiesData: getLevelPropertiesData,
//             saveLevelPropertiesAsDefaults: saveLevelPropertiesAsDefaults,
//             modifyLevelName: modifyLevelName,
//             modifyBuildingClass: modifyBuildingClass,
//             modifyLOverValue: modifyLOverValue,
//             modifyExposureCategory: modifyExposureCategory,
//             modifyMeanRoofHeight: modifyMeanRoofHeight,
//             modifyImportanceCategory: modifyImportanceCategory,
//             modifyExposureCategoryNBCC: modifyExposureCategoryNBCC,
//             modifyLOverValueNBCC: modifyLOverValueNBCC,
//             modifyRidgeHeight: modifyRidgeHeight,
//             modifyEaveHeight: modifyEaveHeight,
//             modifyDistanceChange: modifyDistanceChange,
//         };

//         function getLevelPropertiesData() {
//             var prData = allModelDataService.getPostResponseData();
//             return prData.ApplicationData.MemberManagerData;
//         }

//         function getLevelPropertiesMetaData() {
//             var data = {};
//             return _requestPostEvent('GetLevelPropertiesMetaData', data);
//         }

//         // function saveLevelPropertiesAsDefaults(data) {
//         //     return _requestPostEvent('SaveLevelPropertiesAsDefaults', data);
//         // }

//         function saveLevelPropertiesAsDefaults() {
//             var currentPostResponseData = allModelDataService.getPostResponseData();
//             return _requestPostEvent('SaveLevelPropertiesAsDefaults', currentPostResponseData);
//         }

//         function modifyLevelName(data) {
//             return _requestPostEvent('ModifyLevelName', data);
//         }

//         function modifyBuildingClass(data) {
//             return _requestPostEvent('ModifyBuildingClass', data);
//         }

//         function modifyLOverValue(data) {
//             return _requestPostEvent('ModifyLOverValue', data);
//         }

//         function modifyExposureCategory(newValue) {
//             var data = {
//                 'value': newValue
//             };
//             return _requestPostEvent('ModifyExposureCategory', data);
//         }

//         function modifyMeanRoofHeight(newValue) {
//             var data = {
//                 'value': newValue
//             };
//             return _requestPostEvent('ModifyMeanRoofHeight', data);
//         }

//         function modifyImportanceCategory(newValue) {
//             var data = {
//                 'value': newValue
//             };
//             return _requestPostEvent('ModifyImportanceCategory', data);
//         }

//         function modifyExposureCategoryNBCC(newValue) {
//             var data = {
//                 'value': newValue
//             };
//             return _requestPostEvent('ModifyExposureCategoryNBCC', data);
//         }

//         function modifyLOverValueNBCC(data) {
//             return _requestPostEvent('ModifyLOverValueNBCC', data);
//         }

//         function modifyRidgeHeight(newValue) {
//             var data = {
//                 'value': newValue
//             };
//             return _requestPostEvent('ModifyRidgeHeight', data);
//         }

//         function modifyEaveHeight(newValue) {
//             var data = {
//                 'value': newValue
//             };
//             return _requestPostEvent('ModifyEaveHeight', data);
//         }

//         function modifyDistanceChange(newValue) {
//             var data = {
//                 'value': newValue
//             };
//             return _requestPostEvent('ModifyDistanceChange', data);
//         }

//         function _requestPostEvent(LevelPropertiesEvent, LevelPropertiesData) {
//             var path = 'api/LevelProperties/';
//             return postService.requestPostEvent(LevelPropertiesEvent, LevelPropertiesData, path);
//         }

//         function _incrementQueueCounter() {
//             httpRequestQueueCounter++;
//         }

//         function _decrementQueueCounter() {
//             httpRequestQueueCounter--;
//             if (somethingWaitingOnQueueToFinish && httpRequestQueueCounter === 0) {
//                 somethingWaitingOnQueueToFinish = false;
//                 inputQueuePromise.resolve();
//             }
//         }


//         function getQueueCounter() {
//             inputQueuePromise = $q.defer();
//             if (httpRequestQueueCounter === 0) {
//                 inputQueuePromise.resolve();
//                 return inputQueuePromise.promise;
//             }
//             else {
//                 somethingWaitingOnQueueToFinish = true;
//             }
//             return inputQueuePromise.promise;
//         }

//         return service;
//     }
// }());
