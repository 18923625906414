export default class hasScrollbar {
    constructor() {
        this.restrict = 'A';
        this.scope = {
            setVisibility: '='
        };
    }
    controller($scope, $parse) {
        'ngInject';
        $scope.$parse = $parse;
    }

    link(scope, element, attrs) {
        // var fn = scope.$parent.setScrollVisibility;
        // var fn = scope.solutionsCtrl.setScrollVisibility;
        scope.$watch(() => {
            return $(element)[0].scrollHeight >  element[0].offsetHeight;
        },
        function (visible) {
            scope.setVisibility(visible);
        }, true);
    }
}
// (function () {
//     'use strict';
//     angular.module('app').directive('hasScrollbar', ['$parse', function($parse) {
//         return {
//             restrict: 'A',
//             scope: {
//                 setVisibility: '='
//             },
//             link: function (scope, element, attrs) {
//                 // var fn = scope.$parent.setScrollVisibility;
//                 // var fn = scope.solutionsCtrl.setScrollVisibility;
//                 scope.$watch(function () {
//                     return $(element)[0].scrollHeight >  element[0].offsetHeight;
//                 },
//                 function (visible) {
//                     scope.setVisibility(visible);
//                 }, true);
//             }
//         };
//     }]);
// }());
