export default class loadsVertZService {

    constructor (allModelDataService, postService) {
        'ngInject';

        this.allModelDataService = allModelDataService;
        this.postService = postService;
    }

    getSessionLoadData() {
        var prData = this.allModelDataService.getPostResponseData();
        return prData.MemberData.VerticalZAxisLoadData;
    }

    getMetadata() {
        var data = {};
        return this._requestPostEvent('GetMetadata', data);
    }

    getSupplementalVPData(viewPosition) {
        var data = {
            'viewPosition': viewPosition
        };
        return this._requestPostEvent('GetSupplementalVPData', data);
    }

    addLateralLoad() {
        var data = {};
        return this._requestPostEvent('AddLateralLoad', data);
    };

    deleteLateralLoad(viewPosition) {
        var data = {
            'viewPosition': viewPosition
        };
        return this._requestPostEvent('DeleteLateralLoad', data);
    }

    modifyLateralLoadType(viewPosition, value) {
        var data = {
            'viewPosition': viewPosition,
            'integerValue': value
        };
        return this._requestPostEvent('ModifyLoadType', data);
    }

    modifyLateralLoadMagnitude(viewPosition, value) {
        var data = {
            'viewPosition': viewPosition,
            'newValue': value
        };
        return this._requestPostEvent('ModifyWindLoadMagnitude', data);
    }

    modifyLateralLoadTribWidth(viewPosition, value) {
        var data = {
            'viewPosition': viewPosition,
            'newValue': value
        };
        return this._requestPostEvent('ModifyLoadTribWidth', data);
    }

    modifyLateralLoadComment(viewPosition, value) {
        var data = {
            'viewPosition': viewPosition,
            'newValue': value
        };
        return this._requestPostEvent('ModifyLoadComment', data);
    }

    _requestPostEvent(event, data) {
        var path = 'api/LoadsVerticalZ/';
        return this.postService.requestPostEvent(event, data, path);
    }
}
// (function() {
//     'use strict';
//     var app = angular.module('app');
//     var serviceId = 'loadsVertZService';
//     app.factory(serviceId, ['allModelDataService', 'postService', loadsVertZService]);

//     function loadsVertZService(allModelDataService, postService) {
//         //this is the declaration of the public methods that will be accessible
//         var service = {
//             getSessionLoadData: getSessionLoadData,
//             getMetadata: getMetadata,
//             getSupplementalVPData: getSupplementalVPData,
//             addLateralLoad: addLateralLoad,
//             deleteLateralLoad: deleteLateralLoad,
//             modifyLateralLoadType: modifyLateralLoadType,
//             modifyLateralLoadMagnitude: modifyLateralLoadMagnitude,
//             modifyLateralLoadTribWidth: modifyLateralLoadTribWidth,
//             modifyLateralLoadComment: modifyLateralLoadComment
//         };

//         function getSessionLoadData() {
//             var prData = allModelDataService.getPostResponseData();
//             return prData.MemberData.VerticalZAxisLoadData;
//         }

//         function getMetadata() {
//             var data = {};
//             return _requestPostEvent('GetMetadata', data);
//         }

//         function getSupplementalVPData(viewPosition) {
//             var data = {
//                 'viewPosition': viewPosition
//             };
//             return _requestPostEvent('GetSupplementalVPData', data);
//         }

//         function addLateralLoad() {
//             var data = {};
//             return _requestPostEvent('AddLateralLoad', data);
//         };

//         function deleteLateralLoad(viewPosition) {
//             var data = {
//                 'viewPosition': viewPosition
//             };
//             return _requestPostEvent('DeleteLateralLoad', data);
//         }

//         function modifyLateralLoadType(viewPosition, value) {
//             var data = {
//                 'viewPosition': viewPosition,
//                 'integerValue': value
//             };
//             return _requestPostEvent('ModifyLoadType', data);
//         }

//         function modifyLateralLoadMagnitude(viewPosition, value) {
//             var data = {
//                 'viewPosition': viewPosition,
//                 'newValue': value
//             };
//             return _requestPostEvent('ModifyWindLoadMagnitude', data);
//         }

//         function modifyLateralLoadTribWidth(viewPosition, value) {
//             var data = {
//                 'viewPosition': viewPosition,
//                 'newValue': value
//             };
//             return _requestPostEvent('ModifyLoadTribWidth', data);
//         }

//         function modifyLateralLoadComment(viewPosition, value) {
//             var data = {
//                 'viewPosition': viewPosition,
//                 'newValue': value
//             };
//             return _requestPostEvent('ModifyLoadComment', data);
//         }

//         function _requestPostEvent(event, data) {
//             var path = 'api/LoadsVerticalZ/';
//             return postService.requestPostEvent(event, data, path);
//         }
//         return service;
//     }
// }());
