export default class memberManagementService {
    constructor(common, allModelDataService, postService) {
        'ngInject';

        this.$q = common.$q();
        this.allModelDataService = allModelDataService;
        this.postService = postService;
        this.apiEndpoint = 'api/MemberManagement/';

    }

    getProjectManagerData() {
        var prData = this.allModelDataService.getPostResponseData();
        return prData.ApplicationData.ProjectManagerData;
    }

    getJobTreeData() {
        var deferred = this.$q.defer();
        var data = {};
        var prData = this.allModelDataService.getPostResponseData();
        return this.postService.requestPostEvent('GetJobTreeData', data, this.apiEndpoint);
    }

    addLevel() {
        var data = {};
        return this.postService.requestPostMemberSwitchSafe('AddJobTreeLevel', data, this.apiEndpoint);
    }

    deleteMember() {
        var data = {};
        return this.postService.requestPostMemberSwitchSafe('DeleteMember', data, this.apiEndpoint);
    }

    deleteLevel() {
        var data = {};
        return this.postService.requestPostMemberSwitchSafe('DeleteLevel', data, this.apiEndpoint);
    }

    copyMember() {
        var data = {};
        return this.postService.requestPostMemberSwitchSafe('CopyMember', data, this.apiEndpoint);
    }

    addMemberFloorJoist() {
        var data = {};
        return this.postService.requestPostMemberSwitchSafe('AddFloorJoist',data, this.apiEndpoint);
    }

    addMemberFloorDropBeam() {
        var data = {};
        return this.postService.requestPostMemberSwitchSafe('AddFloorDropBeam',data, this.apiEndpoint);
    }

    addMemberFloorFlushBeam() {
        var data = {};
        return this.postService.requestPostMemberSwitchSafe('AddFloorFlushBeam',data, this.apiEndpoint);
    }

    addMemberWallHeader() {
        var data = {};
        return this.postService.requestPostMemberSwitchSafe('AddWallHeader',data, this.apiEndpoint);
    }

    addMemberWallWindSill() {
        var data = {};
        return this.postService.requestPostMemberSwitchSafe('AddWallWindSill',data, this.apiEndpoint);
    }

    addMemberWallStud() {
        var data = {};
        return this.postService.requestPostMemberSwitchSafe('AddWallStud',data, this.apiEndpoint);
    }

    addMemberFreeStandingPost() {
        var data = {};
        return this.postService.requestPostMemberSwitchSafe('AddFreeStandingPost',data, this.apiEndpoint);
    }

    addMemberWallColumn() {
        var data = {};
        return this.postService.requestPostMemberSwitchSafe('AddWallColumn',data, this.apiEndpoint);
    }

    addMemberCeilingJoist() {
        var data = {};
        return this.postService.requestPostMemberSwitchSafe('AddCeilingJoist',data, this.apiEndpoint);
    }

    addMemberRoofJoist() {
        var data = {};
        return this.postService.requestPostMemberSwitchSafe('AddRoofJoist',data, this.apiEndpoint);
    }

    addMemberRoofDropBeam() {
        var data = {};
        return this.postService.requestPostMemberSwitchSafe('AddRoofDropBeam',data, this.apiEndpoint);
    }

    addMemberRoofHipBeam() {
        var data = {};
        return this.postService.requestPostMemberSwitchSafe('AddRoofHipBeam', data, this.apiEndpoint);
    }

    addMemberRoofFlushBeam() {
        var data = {};
        return this.postService.requestPostMemberSwitchSafe('AddRoofFlushBeam',data, this.apiEndpoint);
    }

    deleteTreeNode() {
        var data = {};
        return this.postService.requestPostMemberSwitchSafe('DeleteTreeNode', data, this.apiEndpoint);
    }

    selectedJobTreeNodeChanged(_containerId, _memberId) {
        var deferred = this.$q.defer();
        var data = {
            containerId: _containerId,
            memberId: _memberId
        };

        this.postService.requestPostMemberSwitchSafe('SelectedJobTreeNodeChanged', data, this.apiEndpoint).then(
            (response) => {
                deferred.resolve(response.data);
            },
            (response) => {
                deferred.reject(response.data);
            });

        return deferred.promise;
    }

    getCurrentlySelectedMemberSDO() {
        var prData = this.allModelDataService.getPostResponseData();
        var selectedMemberIDTag = prData.ApplicationData.SelectedMemberIDTag;
        var memberManagerData = prData.ApplicationData.MemberManagerData;

        for (var c = 0; c < memberManagerData.MemberContainers.length; c++) {
            var currentContainer = memberManagerData.MemberContainers[c];
            for (var m = 0; m < currentContainer.Members.length; m++) {
                var currentMember = currentContainer.Members[m];
                if (currentMember.IDTag === selectedMemberIDTag) {
                    return currentMember;
                }
            }
        }
    }

}
