export default class taperedEndsController {
    constructor($scope, $rootScope, $location, $timeout, $uibModal, taperedEndsService, resourceMessagingService, allModelDataService, dirtyCheckService) {
        'ngInject';

        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$location = $location;
        this.$timeout = $timeout;
        this.$uibModal = $uibModal;
        this.taperedEndsService = taperedEndsService;
        this.resourceMessagingService = resourceMessagingService;
        this.allModelDataService = allModelDataService;
        this.dirtyCheckService = dirtyCheckService;

        this.activate();
    }

    activate() {
        this.getTaperedEndsDataFromPRData();

        this.taperedEndsService.getMetaData().then((response) => {
            this.metadata = response;
        });
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    deleteOneTaperedEndCut(isLeft) {
        if (isLeft) {
            this.modelData.TaperedLeftEnd.pending = true;
        } else {
            this.modelData.TaperedRightEnd.pending = true;
        }
        this.taperedEndsService.clearOneTaperedEndCut(isLeft).then((response) => {
            this.getTaperedEndsDataFromPRData();
        });
    }

    isEmptyTaperedEndCut(isLeft) {
        if (this.modelData) {
            if (isLeft) {
                if (this.modelData.TaperedLeftEnd.Height !== 0 || this.modelData.TaperedLeftEnd.Length !== 0 || this.modelData.TaperedLeftEnd.Slope !== 0 || this.modelData.TaperedLeftEnd.Comment !== '') {
                    return false;
                }
            } else {
                if (this.modelData.TaperedRightEnd.Height !== 0 || this.modelData.TaperedRightEnd.Length !== 0 || this.modelData.TaperedRightEnd.Slope !== 0 || this.modelData.TaperedRightEnd.Comment !== '') {
                    return false;
                }
            }
        }
        return true;
    }

    preventCharInputs(keyPressEvent) {
        if (keyPressEvent.which  === 8 || keyPressEvent.which  === 0) {
            // backspace, null
            return;
        }
        if ((keyPressEvent.which < 48 || keyPressEvent.which > 57) && keyPressEvent.which !== 46) {
            // numbers 0-9 and decimal point '.' OK
            keyPressEvent.preventDefault();
        }
    }

    typeChanged(newType) {
        this.modelData.TaperedLeftEnd.pending = true;
        this.modelData.TaperedRightEnd.pending = true;
        this.taperedEndsService.modifyInputType(newType).then((response) => {
            this.getTaperedEndsDataFromPRData();
        });
    }

    heightChanged(event, isLeft, newValue) {
        if (this.dirtyCheckService.isDirty(event)) {
            if (isLeft === true) {
                this.modelData.TaperedLeftEnd.pending = true;
            } else {
                this.modelData.TaperedRightEnd.pending = true;
            }
            this.taperedEndsService.modifyHeelHeight(isLeft,newValue)
                .then((response) => {
                    this.getTaperedEndsDataFromPRData();
                });
            this.deselectAll();
        }
    }

    lengthChanged(event,isLeft, newValue) {
        if (this.dirtyCheckService.isDirty(event)) {
            if (isLeft === true) {
                this.modelData.TaperedLeftEnd.pending = true;
            } else {
                this.modelData.TaperedRightEnd.pending = true;
            }
            this.taperedEndsService.modifyLength(isLeft,newValue)
                .then((response) => {
                    this.getTaperedEndsDataFromPRData();
                });
            this.deselectAll();
        }
    }

    _roundString(value, decimals) {
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
    }

    slopeChanged(event, isLeft, newValue) {
        if (this.dirtyCheckService.isDirty(event)) {
            if (isLeft === true) {
                this.modelData.TaperedLeftEnd.pending = true;
            } else {
                this.modelData.TaperedRightEnd.pending = true;
            }

            if (newValue.indexOf('/12') > -1) {
                newValue = newValue.substring(0, newValue.length - 3);
            }

            this.taperedEndsService.modifySlope(isLeft, this._roundString(newValue, 2))
                .then((response) => {
                    this.getTaperedEndsDataFromPRData();
                });
            this.deselectAll();
        }
    }

    commentChanged(event, isLeft, newComment) {
        if (this.dirtyCheckService.isDirty(event)) {
            if (isLeft === true) {
                this.modelData.TaperedLeftEnd.pending = true;
            } else {
                this.modelData.TaperedRightEnd.pending = true;
            }
            this.taperedEndsService.modifyComment(isLeft, newComment).then(() => {
                this.getTaperedEndsDataFromPRData();
            });
            this.deselectAll();
        }
    };

    modifyComment(isLeft, holeComment) {
        var modalInstance = this.$uibModal.open({
            animation: true,
            templateUrl: 'modifyCommentModal.html',
            controller: 'holesModifyCommentController',
            controllerAs: 'modifyCommentCtrl',
            windowClass: 'connectorDialog',
            backdrop: 'static',
            size: 'md',
            resolve: {
                items: {
                    comment: holeComment
                }
            }
        });

        modalInstance.result.then((newComment) => {
            if (isLeft === true) {
                this.modelData.TaperedLeftEnd.pending = true;
            } else {
                this.modelData.TaperedRightEnd.pending = true;
            }

            this.taperedEndsService.modifyComment(isLeft, newComment).then(() => {
                this.getTaperedEndsDataFromPRData();
            });
        }, () => {
            if (isLeft === true) {
                this.modelData.TaperedLeftEnd.pending = true;
            } else {
                this.modelData.TaperedRightEnd.pending = true;
            }
            this.getTaperedEndsDataFromPRData();
        }).finally(() => {
        });

        this.deselectAll();
    }

    selectAllTextOnFocus($event, rowIndex, columnIndex) {
        $event.target.select();
        this.inputGotFocus(rowIndex, columnIndex);
    }

    inputGotFocus(rowIndex, columnIndex) {
        this.selectedRowNumber = rowIndex;
        this.selectedColumnNumber = columnIndex;
    }

    getTaperedEndsDataFromPRData() {
        this.modelData = this.allModelDataService.getPostResponseData().MemberData.TaperedEndsData;
    }

    keyDown(isPending, $event) {
        //ignore everything accept navigation input if the column is "pending"
        if (isPending) {
            if (!($event.keyCode === 9 || $event.keyCode === 16)) { //tab and shift keycodes
                $event.stopPropagation();
                $event.preventDefault();
                //console.log('stopped: ' + $event.keyCode);
                return false;
            }
        }
    }

    deselectAll() {
        this.selectedRowNumber = -1;
        this.selectedColumnNumber = -1;
    }

}
// (function () {
//     'use strict';
//     var app = angular.module('app');

//     app.controller('taperedEndsController', ['$scope', '$rootScope', '$location', '$timeout', '$uibModal',
//         'uiTourService', 'taperedEndsService', 'resourceMessagingService', 'allModelDataService', 'dirtyCheckService', taperedEndsController]);

//     function taperedEndsController($scope, $rootScope, $location, $timeout, $uibModal, uiTourService, taperedEndsService,
//         resourceMessagingService, allModelDataService, dirtyCheckService) {
//         //controller code goes here.
//         var vm = this;

//         vm.getText = resourceMessagingService.getText;
//         vm.typeChanged = typeChanged;
//         vm.heightChanged = heightChanged;
//         vm.lengthChanged = lengthChanged;
//         vm.slopeChanged = slopeChanged;
//         vm.commentChanged = commentChanged;
//         vm.modifyComment = modifyComment;
//         vm.selectAllTextOnFocus = selectAllTextOnFocus;
//         vm.deleteOneTaperedEndCut = deleteOneTaperedEndCut;
//         vm.isEmptyTaperedEndCut = isEmptyTaperedEndCut;
//         vm.preventCharInputs = preventCharInputs;

//         activate();

//         function activate() {
//             getTaperedEndsDataFromPRData();

//             taperedEndsService.getMetaData().then(function (response) {
//                 vm.metadata = response;
//             });
//         }

//         function deleteOneTaperedEndCut(isLeft) {
//             if (isLeft) {
//                 vm.modelData.TaperedLeftEnd.pending = true;
//             } else {
//                 vm.modelData.TaperedRightEnd.pending = true;
//             }
//             taperedEndsService.clearOneTaperedEndCut(isLeft).then(function(response) {
//                 getTaperedEndsDataFromPRData();
//             });
//         }

//         function isEmptyTaperedEndCut(isLeft) {
//             if (vm.modelData) {
//                 if (isLeft) {
//                     if (vm.modelData.TaperedLeftEnd.Height !== 0 || vm.modelData.TaperedLeftEnd.Length !== 0 || vm.modelData.TaperedLeftEnd.Slope !== 0 || vm.modelData.TaperedLeftEnd.Comment !== '') {
//                         return false;
//                     }
//                 } else {
//                     if (vm.modelData.TaperedRightEnd.Height !== 0 || vm.modelData.TaperedRightEnd.Length !== 0 || vm.modelData.TaperedRightEnd.Slope !== 0 || vm.modelData.TaperedRightEnd.Comment !== '') {
//                         return false;
//                     }
//                 }
//             }
//             return true;
//         }

//         function preventCharInputs(keyPressEvent) {
//             if (keyPressEvent.which  === 8 || keyPressEvent.which  === 0) {
//                 // backspace, null
//                 return;
//             }
//             if ((keyPressEvent.which < 48 || keyPressEvent.which > 57) && keyPressEvent.which !== 46) {
//                 // numbers 0-9 and decimal point '.' OK
//                 keyPressEvent.preventDefault();
//             }
//         }

//         function typeChanged(newType) {
//             vm.modelData.TaperedLeftEnd.pending = true;
//             vm.modelData.TaperedRightEnd.pending = true;
//             taperedEndsService.modifyInputType(newType).then(function(response) {
//                 getTaperedEndsDataFromPRData();
//             });
//         }

//         function heightChanged(event, isLeft, newValue) {
//             if (dirtyCheckService.isDirty(event)) {
//                 if (isLeft === true) {
//                     vm.modelData.TaperedLeftEnd.pending = true;
//                 } else {
//                     vm.modelData.TaperedRightEnd.pending = true;
//                 }
//                 taperedEndsService.modifyHeelHeight(isLeft,newValue)
//                     .then(function(response) {
//                         getTaperedEndsDataFromPRData();
//                     });
//                 deselectAll();
//             }
//         }

//         function lengthChanged(event,isLeft, newValue) {
//             if (dirtyCheckService.isDirty(event)) {
//                 if (isLeft === true) {
//                     vm.modelData.TaperedLeftEnd.pending = true;
//                 } else {
//                     vm.modelData.TaperedRightEnd.pending = true;
//                 }
//                 taperedEndsService.modifyLength(isLeft,newValue)
//                     .then(function(response) {
//                         getTaperedEndsDataFromPRData();
//                     });
//                 deselectAll();
//             }
//         }

//         function _roundString(value, decimals) {
//             return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
//         }

//         function slopeChanged(event, isLeft, newValue) {
//             if (dirtyCheckService.isDirty(event)) {
//                 if (isLeft === true) {
//                     vm.modelData.TaperedLeftEnd.pending = true;
//                 } else {
//                     vm.modelData.TaperedRightEnd.pending = true;
//                 }

//                 if (newValue.indexOf('/12') > -1) {
//                     newValue = newValue.substring(0, newValue.length - 3);
//                 }

//                 taperedEndsService.modifySlope(isLeft, _roundString(newValue, 2))
//                     .then(function(response) {
//                         getTaperedEndsDataFromPRData();
//                     });
//                 deselectAll();
//             }
//         }

//         function commentChanged(event, isLeft, newComment) {
//             if (dirtyCheckService.isDirty(event)) {
//                 if (isLeft === true) {
//                     vm.modelData.TaperedLeftEnd.pending = true;
//                 } else {
//                     vm.modelData.TaperedRightEnd.pending = true;
//                 }
//                 taperedEndsService.modifyComment(isLeft, newComment).then(function () {
//                     getTaperedEndsDataFromPRData();
//                 });
//                 deselectAll();
//             }
//         };

//         function modifyComment(isLeft, holeComment) {
//             var modalInstance = $uibModal.open({
//                 animation: true,
//                 templateUrl: 'modifyCommentModal.html',
//                 controller: 'holesModifyCommentController',
//                 windowClass: 'connectorDialog',
//                 backdrop: 'static',
//                 size: 'md',
//                 resolve: {
//                     items: {
//                         comment: holeComment
//                     }
//                 }
//             });

//             modalInstance.result.then(function (newComment) {
//                 if (isLeft === true) {
//                     vm.modelData.TaperedLeftEnd.pending = true;
//                 } else {
//                     vm.modelData.TaperedRightEnd.pending = true;
//                 }

//                 taperedEndsService.modifyComment(isLeft, newComment).then(function () {
//                     getTaperedEndsDataFromPRData();
//                 });
//             }, function () {
//                 if (isLeft === true) {
//                     vm.modelData.TaperedLeftEnd.pending = true;
//                 } else {
//                     vm.modelData.TaperedRightEnd.pending = true;
//                 }
//                 getTaperedEndsDataFromPRData();
//             }).finally(function() {
//             });

//             deselectAll();
//         }

//         function selectAllTextOnFocus($event, rowIndex, columnIndex) {
//             $event.target.select();
//             inputGotFocus(rowIndex, columnIndex);
//         }

//         function inputGotFocus(rowIndex, columnIndex) {
//             vm.selectedRowNumber = rowIndex;
//             vm.selectedColumnNumber = columnIndex;
//         }

//         function getTaperedEndsDataFromPRData() {
//             vm.modelData = allModelDataService.getPostResponseData().MemberData.TaperedEndsData;
//         }

//         function keyDown(isPending, $event) {
//             //ignore everything accept navigation input if the column is "pending"
//             if (isPending) {
//                 if (!($event.keyCode === 9 || $event.keyCode === 16)) { //tab and shift keycodes
//                     $event.stopPropagation();
//                     $event.preventDefault();
//                     //console.log('stopped: ' + $event.keyCode);
//                     return false;
//                 }
//             }
//         }

//         function deselectAll() {
//             vm.selectedRowNumber = -1;
//             vm.selectedColumnNumber = -1;
//         }


//     };
// }());
