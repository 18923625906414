
import iziToast from 'izitoast';

export default class notifier {
    constructor() {

    }

    showGenericError(title, message) {
        this._showFileManagementToast(title, message, 'fa fa-times-circle', '#c9302c');
    }

    fileManagementError(title, message) {
        this._showFileManagementToast(title, message, 'fa fa-times-circle', '#c9302c');
    }

    fileManagementWarning(title, message) {
        this._showFileManagementToast(title, message, 'fa fa-exclamation-triangle', '#ECA136');
    }

    _showFileManagementToast(title, message, icon, iconColor) {
        //compute the timeout time based on average words per minute reading time
        var averageWordsPerMinute = 200; //not made up, it's based on reasearch, I swear.
        var averageWordsPerSecond = averageWordsPerMinute / 60;
        var totalWords = message.trim().split(/\s+/g).length;

        var totalReadingTimeSeconds = totalWords / averageWordsPerSecond;
        var totalReadintTimeMiliseconds = totalReadingTimeSeconds * 1000;
        //add 2 seconds to the reading time to account for locating the message and reading the title.
        totalReadintTimeMiliseconds = totalReadintTimeMiliseconds + 3000;

        iziToast.show({
            theme: 'dark',
            icon: icon,
            position:'topCenter',
            iconColor: iconColor,
            transitionIn: 'fadeInDown',
            transitionOut: 'fadeOutUp',
            layout: 3,
            title: title,
            message: message,
            timeout: totalReadintTimeMiliseconds
        });
    }

    showAutoLogoutToast(promise, title, message) {
        iziToast.show({
            theme: 'dark',
            icon: 'fa fa-exclamation-triangle',
            iconColor: '#ECA136',
            position: 'center',
            transitionIn: 'fadeInDown',
            transitionOut: 'fadeOutUp',
            drag: true,
            close: false,
            pauseOnHover: false,
            overlay: true,
            layout: 1,
            title: title,
            message: message,
            timeout: 10000,
            onClosed: () => {
                promise.resolve();
            }
        });
    }

    showLoginPreventedToast(title, message) {
        iziToast.show({
            theme: 'dark',
            icon: 'fa fa-times-circle',
            iconColor: '#c9302c',
            position: 'topCenter',
            transitionIn: 'fadeInDown',
            transitionOut: 'fadeOutUp',
            drag: true,
            close: false,
            pauseOnHover: false,
            overlay: false,
            layout: 1,
            title: title,
            message: message,
            timeout: 10000
        });
    }

    showLinkedReactionBrokenPrompt(title, message, buttonText, promise) {
        var okbutton = '<button>' + buttonText.ok + '</button>';
        var ignorebutton = '<button>' + buttonText.ignore + '</button>';
        iziToast.show({
            theme: 'dark',
            icon: 'fa fa-times-circle',
            position:'topCenter',
            iconColor: '#c9302c',
            transitionIn: 'fadeInDown',
            transitionOut: 'fadeOutUp',
            layout: 3,
            close: false,
            title: title,
            message: message,
            timeout: false,
            drag: false,
            overlay: true,
            buttons:[
                [okbutton,
                    (instance, toast) => {
                        promise.reject('ok');
                        instance.hide({ transitionOut: 'fadeOutUp' },toast,  'button');
                    },
                    true],
                [ignorebutton,
                    (instance, toast) => {
                        promise.resolve('ignore');
                        instance.hide({ transitionOut: 'fadeOutUp' }, toast,  'button');
                    }
                ]
            ]
        });
    }

    showEnableLoadEditingWarning(title, message, buttonText, promise) {
        var okbutton = '<button style="background-color: #fff; color: #333333;">' + buttonText.ok + '</button>';
        var cancelbutton = '<button style="color: white; background-color: #d9534f">' + buttonText.cancel + '</button>';
        iziToast.show({
            theme: 'dark',
            icon: 'fa fa-exclamation-triangle',
            position:'topCenter',
            iconColor: '#ECA136',
            transitionIn: 'fadeInDown',
            transitionOut: 'fadeOutUp',
            layout: 3,
            close: false,
            title: title,
            message: message,
            timeout: false,
            drag: false,
            overlay: true,
            buttons:[
                [okbutton,
                    (instance, toast) => {
                        promise.resolve('ok');
                        instance.hide({ transitionOut: 'fadeOutUp' },toast,  'button');
                    },
                    true],
                [cancelbutton,
                    (instance, toast) => {
                        promise.reject('cancel');
                        instance.hide({ transitionOut: 'fadeOutUp' }, toast,  'button');
                    }
                ]
            ]
        });
    }
    showSaveAsQuickLoadPrompt(promise, promptText, saveText, cancelText) {
        var name;
        var saveButton = '<button style="color: white; background-color: #337ab7;">' + saveText + '</button>';
        var cancelButton = '<button style="color: white; background-color: #d9534f">' + cancelText + '</button>';
        iziToast.show({
            theme: 'light',
            icon: 'fa fa-floppy-o',
            position:'center',
            iconColor: '#238ae6',
            transitionIn: 'fadeInDown',
            transitionOut: 'fadeOutUp',
            layout: 1,
            close: false,
            title: promptText + ':',
            message: '',
            timeout: false,
            drag: false,
            overlay: true,
            inputs: [
                ['<input type="text" style="Width: 200px;">', 'keyup', (instance, toast, input, e) => {
                    console.info(input.value);
                    name = input.value;
                }, true]
            ],
            buttons:[
                [saveButton,
                    (instance, toast) => {
                        promise.resolve(name);
                        instance.hide({ transitionOut: 'fadeOutUp' }, toast,  'button');
                    }],
                [cancelButton,
                    (instance, toast) => {
                        promise.reject('error');
                        instance.hide({ transitionOut: 'fadeOutUp' }, toast,  'button');
                    }
                ]
            ]
        });
    }
}
// /*eslint-disable no-undef*/
// (function () {
//     'use strict';

//     angular.module('common').factory('notifier', [ notifier ]);

//     function notifier() {

//         var service = {
//             fileManagementWarning: fileManagementWarning,
//             fileManagementError: fileManagementError,
//             showLinkedReactionBrokenPrompt: showLinkedReactionBrokenPrompt,
//             showSaveAsQuickLoadPrompt: showSaveAsQuickLoadPrompt,
//             showAutoLogoutToast: showAutoLogoutToast,
//             showLoginPreventedToast: showLoginPreventedToast,
//             showEnableLoadEditingWarning: showEnableLoadEditingWarning,
//             showSuccessToast: showSuccessToast,
//             showErrorToast: showErrorToast
//         };

//         return service;

//         function fileManagementError(title, message) {
//             _showFileManagementToast(title, message, 'fa fa-times-circle', '#c9302c');
//         }
//         function fileManagementWarning(title, message) {
//             _showFileManagementToast(title, message, 'fa fa-exclamation-triangle', '#ECA136');
//         }

//         function _showFileManagementToast(title, message, icon, iconColor) {
//             //compute the timeout time based on average words per minute reading time
//             var averageWordsPerMinute = 200; //not made up, it's based on reasearch, I swear.
//             var averageWordsPerSecond = averageWordsPerMinute / 60;
//             var totalWords = message.trim().split(/\s+/g).length;

//             var totalReadingTimeSeconds = totalWords / averageWordsPerSecond;
//             var totalReadintTimeMiliseconds = totalReadingTimeSeconds * 1000;
//             //add 2 seconds to the reading time to account for locating the message and reading the title.
//             totalReadintTimeMiliseconds = totalReadintTimeMiliseconds + 3000;

//             iziToast.show({
//                 theme: 'dark',
//                 icon: icon,
//                 position:'topCenter',
//                 iconColor: iconColor,
//                 transitionIn: 'fadeInDown',
//                 transitionOut: 'fadeOutUp',
//                 layout: 3,
//                 title: title,
//                 message: message,
//                 timeout: totalReadintTimeMiliseconds
//             });
//         }

//         function showSuccessToast(title, message) {
//             iziToast.show({
//                 theme: 'dark',
//                 icon: 'fa fa-check-circle',
//                 iconColor: '#008000',
//                 position:'topCenter',
//                 transitionIn: 'fadeInDown',
//                 transitionOut: 'fadeOutUp',
//                 drag: false,
//                 close: false,
//                 pauseOnHover: false,
//                 overlay: false,
//                 layout: 3,
//                 title: title,
//                 message: message,
//                 timeout: 10000
//             });

//         }

//         function showErrorToast(title, message) {
//             iziToast.show({
//                 theme: 'dark',
//                 icon: 'fa fa fa-times-circle',
//                 iconColor: '#c9302c',
//                 position:'topCenter',
//                 transitionIn: 'fadeInDown',
//                 transitionOut: 'fadeOutUp',
//                 drag: false,
//                 close: false,
//                 pauseOnHover: false,
//                 overlay: false,
//                 layout: 1,
//                 title: title,
//                 message: message,
//                 timeout: 10000
//             });

//         }

//         function showAutoLogoutToast(promise, title, message) {
//             iziToast.show({
//                 theme: 'dark',
//                 icon: 'fa fa-exclamation-triangle',
//                 iconColor: '#ECA136',
//                 position: 'center',
//                 transitionIn: 'fadeInDown',
//                 transitionOut: 'fadeOutUp',
//                 drag: true,
//                 close: false,
//                 pauseOnHover: false,
//                 overlay: true,
//                 layout: 1,
//                 title: title,
//                 message: message,
//                 timeout: 10000,
//                 onClosed: function() {
//                     promise.resolve();
//                 }
//             });
//         }

//         function showLoginPreventedToast(title, message) {
//             iziToast.show({
//                 theme: 'dark',
//                 icon: 'fa fa-times-circle',
//                 iconColor: '#c9302c',
//                 position: 'topCenter',
//                 transitionIn: 'fadeInDown',
//                 transitionOut: 'fadeOutUp',
//                 drag: true,
//                 close: false,
//                 pauseOnHover: false,
//                 overlay: false,
//                 layout: 1,
//                 title: title,
//                 message: message,
//                 timeout: 10000
//             });
//         }

//         function showLinkedReactionBrokenPrompt(title, message, buttonText, promise) {
//             var okbutton = '<button>' + buttonText.ok + '</button>';
//             var ignorebutton = '<button>' + buttonText.ignore + '</button>';
//             iziToast.show({
//                 theme: 'dark',
//                 icon: 'fa fa-times-circle',
//                 position:'topCenter',
//                 iconColor: '#c9302c',
//                 transitionIn: 'fadeInDown',
//                 transitionOut: 'fadeOutUp',
//                 layout: 3,
//                 close: false,
//                 title: title,
//                 message: message,
//                 timeout: false,
//                 drag: false,
//                 overlay: true,
//                 buttons:[
//                     [okbutton,
//                         function (instance, toast) {
//                             promise.reject('ok');
//                             instance.hide({ transitionOut: 'fadeOutUp' },toast,  'button');
//                         },
//                         true],
//                     [ignorebutton,
//                         function (instance, toast) {
//                             promise.resolve('ignore');
//                             instance.hide({ transitionOut: 'fadeOutUp' }, toast,  'button');
//                         }
//                     ]
//                 ]
//             });
//         }

//         function showEnableLoadEditingWarning(title, message, buttonText, promise) {
//             var okbutton = '<button style="background-color: #fff; color: #333333;">' + buttonText.ok + '</button>';
//             var cancelbutton = '<button style="color: white; background-color: #d9534f">' + buttonText.cancel + '</button>';
//             iziToast.show({
//                 theme: 'dark',
//                 icon: 'fa fa-exclamation-triangle',
//                 position:'topCenter',
//                 iconColor: '#ECA136',
//                 transitionIn: 'fadeInDown',
//                 transitionOut: 'fadeOutUp',
//                 layout: 3,
//                 close: false,
//                 title: title,
//                 message: message,
//                 timeout: false,
//                 drag: false,
//                 overlay: true,
//                 buttons:[
//                     [okbutton,
//                         function (instance, toast) {
//                             promise.resolve('ok');
//                             instance.hide({ transitionOut: 'fadeOutUp' },toast,  'button');
//                         },
//                         true],
//                     [cancelbutton,
//                         function (instance, toast) {
//                             promise.reject('cancel');
//                             instance.hide({ transitionOut: 'fadeOutUp' }, toast,  'button');
//                         }
//                     ]
//                 ]
//             });
//         }

//         function showSaveAsQuickLoadPrompt(promise, promptText, saveText, cancelText) {
//             var name;
//             var saveButton = '<button style="color: white; background-color: #337ab7;">' + saveText + '</button>';
//             var cancelButton = '<button style="color: white; background-color: #d43f3a">' + cancelText + '</button>';
//             iziToast.show({
//                 theme: 'light',
//                 icon: 'fa fa-floppy-o',
//                 position:'center',
//                 iconColor: '#238ae6',
//                 transitionIn: 'fadeInDown',
//                 transitionOut: 'fadeOutUp',
//                 layout: 1,
//                 close: false,
//                 title: promptText + ':',
//                 message: '',
//                 timeout: false,
//                 drag: false,
//                 overlay: true,
//                 inputs: [
//                     ['<input type="text" style="Width: 200px;">', 'keyup', function (instance, toast, input, e) {
//                         console.info(input.value);
//                         name = input.value;
//                     }, true]
//                 ],
//                 buttons:[
//                     [saveButton,
//                         function (instance, toast) {
//                             promise.resolve(name);
//                             instance.hide({ transitionOut: 'fadeOutUp' }, toast,  'button');
//                         }],
//                     [cancelButton,
//                         function (instance, toast) {
//                             promise.reject('error');
//                             instance.hide({ transitionOut: 'fadeOutUp' }, toast,  'button');
//                         }
//                     ]
//                 ]
//             });
//         }
//     }
// }());
// /*eslint-enable no-undef*/
