export default class timeoutWarningController {
    constructor ($scope, resourceMessagingService) {
        'ngInject';
        this.$scope = $scope;
        this.resourceMessagingService = resourceMessagingService;
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }
}
// (function () {
//     'use strict';
//     var app = angular.module('app');
//     app.controller('timeoutWarningController',
//         ['$scope', 'resourceMessagingService',
//             function ($scope, resourceMessagingService) {
//                 var vm = $scope;

//                 vm.getText = getText;

//                 function getText(key) {
//                     return resourceMessagingService.getText(key);
//                 }

//             }]);
// }());
