import ngAuthSettings from '../environmentConfigs/environment.dev';
/*eslint-disable complexity */
export default class postService {
    constructor($http, allModelDataService, common, resourceMessagingService,
        $timeout, spinner) {
        'ngInject';
        this.$http = $http;
        this.ngAuthSettings = ngAuthSettings;
        this.allModelDataService = allModelDataService;
        this.common = common;
        this.resourceMessagingService = resourceMessagingService;
        this.$timeout = $timeout;
        this.spinner = spinner;

        this.serviceBase = ngAuthSettings.apiServiceBaseUri;
        this.$q = common.$q();
        this.inputQueuePromise = null;
        this.postPromises = [];
        this.queueingData = [];

        this.postPromises.spansandsupports = {promiseQueue: []};
        this.postPromises.hole = {promiseQueue: []};
        this.postPromises.locationAnalysis = {promiseQueue: []};
        this.postPromises.productSelection = {promiseQueue: []};
        this.postPromises.loadsHorizY = {promiseQueue: []};
        this.postPromises.floorPerformance = {promiseQueue: []};
        this.postPromises.memberManagement = {promiseQueue: []};
        this.postPromises.fileSystem = {promiseQueue: []};
        this.postPromises.projectManagement = {promiseQueue: []};
        this.postPromises.report = {promiseQueue: []};
        this.postPromises.solutions = {promiseQueue: []};
        this.postPromises.settings = {promiseQueue: []};
        this.postPromises.jobSettings = {promiseQueue: []};
        this.postPromises.resourceMessaging = {promiseQueue: []};
        this.postPromises.levelProperties = {promiseQueue: []};
        this.postPromises.memberProperties = {promiseQueue: []};
        this.postPromises.connector = {promiseQueue: []};
        this.postPromises.materialsManagement = {promiseQueue: []};
        this.postPromises.team = {promiseQueue: []};
        this.postPromises.jobSummary = {promiseQueue: []};
        this.postPromises.quickLoads = {promiseQueue: []};
        this.postPromises.taperedEnds = {promiseQueue: []};
        this.postPromises.notchedFlange = {promiseQueue: []};
        this.postPromises.roofGeometry = {promiseQueue: []};
        this.postPromises.windLoading = {promiseQueue: []};
        this.postPromises.seismicLoading = {promiseQueue: []};
        this.postPromises.heightAndSupports = {promiseQueue: []};
        this.postPromises.loadsVericalY = {promiseQueue: []};
        this.postPromises.loadsHorizZ = {promiseQueue: []};
        this.postPromises.loadsVertZ = {promiseQueue: []};
        this.postPromises.loadsCll = {promiseQueue: []};
        this.postPromises.transferReactions = {promiseQueue: []};

        this.queueingData.spansandsupports = {httpRequestCounter: 0, waiting: false};
        this.queueingData.hole = {httpRequestCounter: 0, waiting: false};
        this.queueingData.locationAnalysis = {httpRequestCounter: 0, waiting: false};
        this.queueingData.productSelection = {httpRequestCounter: 0, waiting: false};
        this.queueingData.loadsHorizY = {httpRequestCounter: 0, waiting: false};
        this.queueingData.floorPerformance = {httpRequestCounter: 0, waiting: false};
        this.queueingData.memberManagement = {httpRequestCounter: 0, waiting: false};
        this.queueingData.fileSystem = {httpRequestCounter: 0, waiting: false};
        this.queueingData.projectManagement = {httpRequestCounter: 0, waiting: false};
        this.queueingData.report = {httpRequestCounter: 0, waiting: false};
        this.queueingData.solutions = {httpRequestCounter: 0, waiting: false};
        this.queueingData.settings = {httpRequestCounter: 0, waiting: false};
        this.queueingData.jobSettings = {httpRequestCounter: 0, waiting: false};
        this.queueingData.resourceMessaging = {httpRequestCounter: 0, waiting: false};
        this.queueingData.levelProperties = {httpRequestCounter: 0, waiting: false};
        this.queueingData.memberProperties = {httpRequestCounter: 0, waiting: false};
        this.queueingData.connector = {httpRequestCounter: 0, waiting: false};
        this.queueingData.materialsManagement = {httpRequestCounter: 0, waiting: false};
        this.queueingData.team = {httpRequestCounter: 0, waiting: false};
        this.queueingData.jobSummary = {httpRequestCounter: 0, waiting: false};
        this.queueingData.quickLoads = {httpRequestCounter: 0, waiting: false};
        this.queueingData.taperedEnds = {httpRequestCounter: 0, waiting: false};
        this.queueingData.notchedFlange = {httpRequestCounter: 0, waiting: false};
        this.queueingData.roofGeometry = {httpRequestCounter: 0, waiting: false};
        this.queueingData.windLoading = {httpRequestCounter: 0, waiting: false};
        this.queueingData.seismicLoading = {httpRequestCounter: 0, waiting: false};
        this.queueingData.heightAndSupports = {httpRequestCounter: 0, waiting: false};
        this.queueingData.loadsVericalY = {httpRequestCounter: 0, waiting: false};
        this.queueingData.loadsHorizZ = {httpRequestCounter: 0, waiting: false};
        this.queueingData.loadsVertZ = {httpRequestCounter: 0, waiting: false};
        this.queueingData.loadsCll = {httpRequestCounter: 0, waiting: false};
        this.queueingData.transferReactions = {httpRequestCounter: 0, waiting: false};
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    requestGetEvent(getEvent, path) {
        var currentQueueData = this.getHTTPRequestCounter(path);
        //console.log('counter value: ' + currentQueueData.httpRequestCounter);
        var deferred = this.$q.defer();
        var incremented = false;
        if (currentQueueData.httpRequestCounter > 0)
        {
            //console.log('increment outside: ' + postEvent);
            this.incrementQueueCounter(currentQueueData);
            incremented = true;
        }
        this.getPostReqPromise(currentQueueData, path).then(() => {
            if (incremented === false) {
                //console.log('increment inside: ' + postEvent);
                this.incrementQueueCounter(currentQueueData);
            }

            this.$http.get(this.serviceBase + path + getEvent)
              .then((response) => {
                  //console.log('decrement: ' + postEvent);
                  this.decrementQueueCounter(currentQueueData, path);
                  deferred.resolve(response.data);
                  //console.log('resolved promise: ' + postEvent);
              }, (err, status) => {
                  //console.log('decrement: ' + postEvent);
                  this.decrementQueueCounter(currentQueueData, path);
                  deferred.reject(err);
                  //console.log('resolved promise error: ' + postEvent);
              });
        })
        .catch((error) => {
            console.log('There has been a problem with your post operation: ' + error.message);
        });
        return deferred.promise;
    }

    requestPrintJobSummaryPDFPostEvent(eventData) {
        return this._requestPDFPostEvent('api/JobSummary/PrintJobSummaryPDF', eventData);
    }

    requestPrintMaterialListPDFPostEvent(eventData) {
        return this._requestPDFPostEvent('api/JobSummary/PrintMaterialListPDF', eventData);
    }

    requestPrintAllPDFPostEvent(eventData) {
        return this._requestPDFPostEvent('api/JobSummary/PrintAllPDF', eventData);
    }

    requestPDFPostEvent(eventData) {
        return this._requestPDFPostEvent('api/ReportOutput/GetPDFReport', eventData);
    }

    requestSolutionsPDFPostEvent(eventData) {
        return this._requestPDFPostEvent('api/Solutions/GetPDFReport', eventData);
    }

    _requestPDFPostEvent(eventPath, eventData) {
        var deferred = this.$q.defer();
        this.$http.post(this.serviceBase + eventPath, eventData, {
            headers: { 'Content-Type': 'application/json' },
            responseType: 'arraybuffer'
        })
          .then((response) => {
              deferred.resolve(response);
          }, (err, status) => {
              deferred.reject(err);
          });

        return deferred.promise;
    }

    requestPostEvent(postEvent, eventData, path) {
        var currentQueueData = this.getHTTPRequestCounter(path);
        //console.log('counter value: ' + currentQueueData.httpRequestCounter);
        var deferred = this.$q.defer();
        var incremented = false;
        if (currentQueueData.httpRequestCounter > 0)
        {
            //console.log('increment outside: ' + postEvent);
            this.incrementQueueCounter(currentQueueData);
            incremented = true;
        }
        this.getPostReqPromise(currentQueueData, path).then(() => {
            if (incremented === false) {
                //console.log('increment inside: ' + postEvent);
                this.incrementQueueCounter(currentQueueData);
            }

            var prData = this.allModelDataService.getPostResponseData();
            eventData['postResponseData'] = prData;

            this.$http.post(this.serviceBase + path + postEvent, eventData, {
                headers: { 'Content-Type': 'application/json' }
            })
              .then((response) => {
                  //console.log('decrement: ' + postEvent);
                  this. decrementQueueCounter(currentQueueData, path);
                  deferred.resolve(response.data);
                  //console.log('resolved promise: ' + postEvent);
              }, (err, status) => {
                  //console.log('decrement: ' + postEvent);
                  this.decrementQueueCounter(currentQueueData, path);
                  deferred.reject(err);
                  //console.log('resolved promise error: ' + postEvent);
              });
        })
        .catch((error) => {
            console.log('There has been a problem with your post operation: ' + error.message);
        });
        return deferred.promise;
    }

    /// This function prevents a user from switching members until
    /// all api requests that modify member data have completed.
    /// if the user tries to make a change that would switch members while
    /// a request is still pending, the splash screen is show with a message that
    /// says please wait.
    requestPostMemberSwitchSafe(postEvent, eventData, path) {
        var deferred = this.$q.defer();

        var splashScreenShown = false;
        var splashScreenPromise;
        //after 350 milliseconds, show the splash screen.
        splashScreenPromise = this.$timeout(() => {
            //console.log('postServiceJS-requestPostMemberSwitchSafe-_showSplashWaiting: ' + postEvent);
            this._showSplashWaiting();
        }, 350);
        //create a promise for the timeout.
        splashScreenPromise.then(
            () => {
                //if the timeout is hit before being cancelled
                //set to true.
                splashScreenShown = true;
                //console.log('postServiceJS-requestPostMemberSwitchSafe-splashScreenShown = true');
            },
            () => {
                //the timeout was cancelled, set to false
                splashScreenShown = false;
                //console.log('postServiceJS-requestPostMemberSwitchSafe-splashScreenShown = true');
            });
        this.allowChangeEvent().then(() => {
            var currentQueueData = this.getHTTPRequestCounter(path);
            var incremented = false;
            if (currentQueueData.httpRequestCounter > 0)
            {
                //console.log('increment outside: ' + postEvent);
                this.incrementQueueCounter(currentQueueData);
                incremented = true;
            }
            this.getPostReqPromise(currentQueueData, path).then(() => {
                if (incremented === false) {
                    //console.log('increment inside: ' + postEvent);
                    this.incrementQueueCounter(currentQueueData);
                }
                var prData = this.allModelDataService.getPostResponseData();
                eventData['postResponseData'] = prData;

                this.$http.post(this.serviceBase + path + postEvent, eventData,
                    { headers: { 'Content-Type': 'application/json' } })
                    .then((response) => {
                        this.decrementQueueCounter(currentQueueData, path);
                        deferred.resolve(response.data);
                    }, (err, status) => {
                        this.decrementQueueCounter(currentQueueData, path);
                        deferred.reject(err);
                    })
                    .finally(() => {
                        //console.log('postServiceJS-requestPostMemberSwitchSafe-splashScreenShown:' + splashScreenShown);
                        if (splashScreenShown) {
                            //if the splash screen was shown, then hide it.
                            //console.log('postServiceJS-requestPostMemberSwitchSafe-spinnerHide');
                            this.spinner.spinnerHide();
                        }
                        else {
                            //if this http post completes before the splash screen was shown,
                            //cancel the timeout that shows the splash.
                            var wasCanceled = this.$timeout.cancel(splashScreenPromise);
                        }
                    })
                    .catch((error) => {
                        console.log('There has been a problem with your post operation: ' + error.message);
                    });
            });
        });
        return deferred.promise;
    }

    _showSplashWaiting() {
        //console.log('postServiceJS-_showSplashWaiting-spinnerShow');
        this.spinner.spinnerShow(this.getText('pleaseWait'));
    }

    /////////////////////methods to handle promise waiting queues /////////////////////
    allowChangeEvent() {
        var deferred = this.$q.defer();
        this.getPendingRequestPromise('api/Spans/').then(() => {
            this.getPendingRequestPromise('api/Supports/').then(() => {
                this.getPendingRequestPromise('api/Holes/').then(() => {
                    this.getPendingRequestPromise('api/LocationAnalysis/').then(() => {
                        this.getPendingRequestPromise('api/ProductSelection/').then(() => {
                            this.getPendingRequestPromise('api/LoadsHorizontalY/').then(() => {
                                this.getPendingRequestPromise('api/FloorPerformance/').then(() => {
                                    this.getPendingRequestPromise('api/MemberManagement/').then(() => {
                                        this.getPendingRequestPromise('api/Solutions/').then(() => {
                                            this.getPendingRequestPromise('api/FileSystem/').then(() => {
                                                this.getPendingRequestPromise('api/Project/').then(() => {
                                                    this.getPendingRequestPromise('api/ReportOutput/').then(() => {
                                                        this.getPendingRequestPromise('api/ProgramSettings/').then(() => {
                                                            this.getPendingRequestPromise('api/JobSettings/').then(() => {
                                                                this.getPendingRequestPromise('api/LevelProperties/').then(() => {
                                                                    this.getPendingRequestPromise('api/MemberProperties/').then(() => {
                                                                        this.getPendingRequestPromise('api/Connector/').then(() => {
                                                                            this.getPendingRequestPromise('api/Team/').then(() => {
                                                                                this.getPendingRequestPromise('api/JobSummary/').then(() => {
                                                                                    this.getPendingRequestPromise('api/QuickLoads/').then(() => {
                                                                                        this.getPendingRequestPromise('api/TaperedEnds/').then(() => {
                                                                                            this.getPendingRequestPromise('api/NotchedFlange/').then(() => {
                                                                                                this.getPendingRequestPromise('api/RoofGeometry/').then(() => {
                                                                                                    this.getPendingRequestPromise('api/JobWindLoading/').then(() => {
                                                                                                        this.getPendingRequestPromise('api/HeightAndSupports/').then(() => {
                                                                                                            this.getPendingRequestPromise('api/LoadsVerticalY/').then(() => {
                                                                                                                this.getPendingRequestPromise('api/LoadsHorizontalZ/').then(() => {
                                                                                                                    this.getPendingRequestPromise('api/LoadsCommercial/').then(() => {
                                                                                                                        this.getPendingRequestPromise('api/LoadsVerticalZ/').then(() => {
                                                                                                                            this.getPendingRequestPromise('api/TransferReactions/').then(() => {
                                                                                                                                this.getPendingRequestPromise('api/JobSeismicLoading/').then(() => {
                                                                                                                                    deferred.resolve();
                                                                                                                                });
                                                                                                                            });
                                                                                                                        });
                                                                                                                    });
                                                                                                                });
                                                                                                            });
                                                                                                        });
                                                                                                    });
                                                                                                });
                                                                                            });
                                                                                        });
                                                                                    });
                                                                                });
                                                                            });
                                                                        });
                                                                    });
                                                                });
                                                            });
                                                        });
                                                    });
                                                });
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        });
        return deferred.promise;
    }

    getPostReqPromise(currentQueueData, path) {
        var newPromise = this.$q.defer();

        if (currentQueueData.httpRequestCounter === 0) {
            newPromise.resolve();
            return newPromise.promise;
        }
        else {
            currentQueueData.waiting = true;
            //postPromise.push(newPromise);
            this.getPostPromiseQueue(path).push(newPromise);
        }
        return newPromise.promise;
    }

    getPostPromiseQueue(path) {
        switch (path) {
            case 'api/Spans/':
                return this.postPromises.spansandsupports.promiseQueue;
            case 'api/Supports/':
                return this.postPromises.spansandsupports.promiseQueue;
            case 'api/Holes/':
                return this.postPromises.hole.promiseQueue;
            case 'api/LocationAnalysis/':
                return this.postPromises.locationAnalysis.promiseQueue;
            case 'api/ProductSelection/':
                return this.postPromises.productSelection.promiseQueue;
            case 'api/LoadsHorizontalY/':
                return this.postPromises.loadsHorizY.promiseQueue;
            case 'api/FloorPerformance/':
                return this.postPromises.floorPerformance.promiseQueue;
            case 'api/MemberManagement/':
                return this.postPromises.memberManagement.promiseQueue;
            case 'api/Solutions/':
                return this.postPromises.solutions.promiseQueue;
            case 'api/FileSystem/':
                return this.postPromises.fileSystem.promiseQueue;
            case 'api/Project/':
                return this.postPromises.projectManagement.promiseQueue;
            case 'api/ReportOutput/':
                return this.postPromises.report.promiseQueue;
            case 'api/ProgramSettings/':
                return this.postPromises.settings.promiseQueue;
            case 'api/JobSettings/':
                return this.postPromises.jobSettings.promiseQueue;
            case 'api/ResourceMessaging/':
                return this.postPromises.resourceMessaging.promiseQueue;
            case 'api/LevelProperties/':
                return this.postPromises.levelProperties.promiseQueue;
            case 'api/MemberProperties/':
                return this.postPromises.memberProperties.promiseQueue;
            case 'api/Connector/':
                return this.postPromises.connector.promiseQueue;
            case 'api/MaterialsManagement/':
                return this.postPromises.materialsManagement.promiseQueue;
            case 'api/Team/':
                return this.postPromises.team.promiseQueue;
            case 'api/JobSummary/':
                return this.postPromises.jobSummary.promiseQueue;
            case 'api/QuickLoads/':
                return this.postPromises.quickLoads.promiseQueue;
            case 'api/TaperedEnds/':
                return this.postPromises.taperedEnds.promiseQueue;
            case 'api/NotchedFlange/':
                return this.postPromises.notchedFlange.promiseQueue;
            case 'api/RoofGeometry/':
                return this.postPromises.roofGeometry.promiseQueue;
            case 'api/JobWindLoading/':
                return this.postPromises.windLoading.promiseQueue;
            case 'api/JobSeismicLoading/':
                return this.postPromises.seismicLoading.promiseQueue;
            case 'api/HeightAndSupports/':
                return this.postPromises.heightAndSupports.promiseQueue;
            case 'api/LoadsVerticalY/':
                return this.postPromises.loadsVericalY.promiseQueue;
            case 'api/LoadsHorizontalZ/':
                return this.postPromises.loadsHorizZ.promiseQueue;
            case 'api/LoadsVerticalZ/':
                return this.postPromises.loadsVertZ.promiseQueue;
            case 'api/LoadsCommercial/':
                return this.postPromises.loadsCll.promiseQueue;
            case 'api/TransferReactions/':
                return this.postPromises.transferReactions.promiseQueue;
        }
    }

    getHTTPRequestCounter(path) {
        switch (path) {
            case 'api/Spans/':
                return this.queueingData.spansandsupports;
            case 'api/Supports/':
                return this.queueingData.spansandsupports;
            case 'api/Holes/':
                return this.queueingData.hole;
            case 'api/LocationAnalysis/':
                return this.queueingData.locationAnalysis;
            case 'api/ProductSelection/':
                return this.queueingData.productSelection;
            case 'api/LoadsHorizontalY/':
                return this.queueingData.loadsHorizY;
            case 'api/FloorPerformance/':
                return this.queueingData.floorPerformance;
            case 'api/MemberManagement/':
                return this.queueingData.memberManagement;
            case 'api/FileSystem/':
                return this.queueingData.fileSystem;
            case 'api/Project/':
                return this.queueingData.projectManagement;
            case 'api/ReportOutput/':
                return this.queueingData.report;
            case 'api/Solutions/':
                return this.queueingData.solutions;
            case 'api/ProgramSettings/':
                return this.queueingData.settings;
            case 'api/JobSettings/':
                return this.queueingData.jobSettings;
            case 'api/ResourceMessaging/':
                return this.queueingData.resourceMessaging;
            case 'api/LevelProperties/':
                return this.queueingData.levelProperties;
            case 'api/MemberProperties/':
                return this.queueingData.memberProperties;
            case 'api/Connector/':
                return this.queueingData.connector;
            case 'api/MaterialsManagement/':
                return this.queueingData.materialsManagement;
            case 'api/Team/':
                return this.queueingData.team;
            case 'api/JobSummary/':
                return this.queueingData.jobSummary;
            case 'api/QuickLoads/':
                return this.queueingData.quickLoads;
            case 'api/TaperedEnds/':
                return this.queueingData.taperedEnds;
            case 'api/NotchedFlange/':
                return this.queueingData.notchedFlange;
            case 'api/RoofGeometry/':
                return this.queueingData.roofGeometry;
            case 'api/JobWindLoading/':
                return this.queueingData.windLoading;
            case 'api/JobSeismicLoading/':
                return this.queueingData.seismicLoading;
            case 'api/HeightAndSupports/':
                return this.queueingData.heightAndSupports;
            case 'api/LoadsVerticalY/':
                return this.queueingData.loadsVericalY;
            case 'api/LoadsHorizontalZ/':
                return this.queueingData.loadsHorizZ;
            case 'api/LoadsVerticalZ/':
                return this.queueingData.loadsVertZ;
            case 'api/LoadsCommercial/':
                return this.queueingData.loadsCll;
            case 'api/TransferReactions/':
                return this.queueingData.transferReactions;
        }
    }

    getPendingRequestPromise(tab) {
        switch (tab) {
            case 'api/Spans/':
                return this.getSpanServicePendingRequestPromise();
            case 'api/Supports/':
                return this.getSupportServicePendingRequestPromise();
            case 'api/Holes/':
                return this.getHolesServicePendingRequestPromise();
            case 'api/LocationAnalysis/':
                return this.getLocationAnalysisServicePendingRequestPromise();
            case 'api/ProductSelection/':
                return this.getProductSelectionServicePendingRequestPromise();
            case 'api/LoadsHorizontalY/':
                return this.getLoadsHorizYServicePendingRequestPromise();
            case 'api/FloorPerformance/':
                return this.getFloorPerformanceServicePendingRequestPromise();
            case 'api/MemberManagement/':
                return this.getMemberManagementServicePendingRequestPromise();
            case 'api/FileSystem/':
                return this.getFileSystemPendingRequestPromise();
            case 'api/Project/':
                return this.getProjectManagementRequestPromise();
            case 'api/ReportOutput/':
                return this.getReportRequestPromise();
            case 'api/Solutions/':
                return this.getSolutionsServicePendingRequestPromise();
            case 'api/ProgramSettings/':
                return this.getSettingsServicePendingRequestPromise();
            case 'api/JobSettings/':
                return this.getJobSettingsServicePendingRequestPromise();
            case 'api/LevelProperties/':
                return this.getLevelPropertiesServicePendingRequestPromise();
            case 'api/MemberProperties/':
                return this.getMemberPropertiesServicePendingRequestPromise();
            case 'api/Connector/':
                return this.getConnectorServicePendingRequestPromise();
            case 'api/MaterialsManagement':
                return this.getMaterialsManagementPendingRequestPromise();
            case 'api/Team/':
                return this.getTeamServicePendingRequestPromise();
            case 'api/JobSummary/':
                return this.getJobSummaryServicePendingRequestPromise();
            case 'api/QuickLoads/':
                return this.getQuickLoadsServicePendingRequestPromise();
            case 'api/TaperedEnds/':
                return this.getTaperedEndsServicePendingRequestPromise();
            case 'api/NotchedFlange/':
                return this.getNotchedFlangeServicePendingRequestPromise();
            case 'api/RoofGeometry/':
                return this.getRoofGeometryServicePendingRequestPromise();
            case 'api/JobWindLoading/':
                return this.getWindLoadingServicePendingRequestPromise();
            case 'api/JobSeismicLoading/':
                return this.getSeismicLoadingServicePendingRequestPromise();
            case 'api/HeightAndSupports/':
                return this.getHeightAndSupportsServicePendingRequestPromise();
            case 'api/LoadsVerticalY/':
                return this.getLoadsVerticalYServicePendingRequestPromise();
            case 'api/LoadsHorizontalZ/':
                return this.getLoadsHorizZServicePendingRequestPromise();
            case 'api/LoadsVerticalZ/':
                return this.getLoadsVerticalZServicePendingRequestPromise();
            case 'api/LoadsCommercial/':
                return this.getLoadsCllServicePendingRequestPromise();
            case 'api/TransferReactions/':
                return this.getTransferReactionsServicePendingRequestPromise();
        }
    }

    incrementQueueCounter(currentQueueData) {
        currentQueueData.httpRequestCounter++;
    }

    decrementQueueCounter(currentQueueData, path) {
        currentQueueData.httpRequestCounter--;
        if (currentQueueData.waiting) {
            var thePromise = this.getPostPromiseQueue(path).splice(0,1);
            if (thePromise.length > 0) {
                thePromise[0].resolve();
            }
            if (currentQueueData.httpRequestCounter === 0) {
                currentQueueData.waiting = false;
                this.inputQueuePromise.resolve();
            }
        }
    }

    getRoofGeometryServicePendingRequestPromise() {
        this.inputQueuePromise = this.$q.defer();
        if (this.queueingData.roofGeometry.httpRequestCounter === 0) {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.queueingData.roofGeometry.waiting = true;
        }
        return this.inputQueuePromise.promise;
    }

    getLoadsVerticalYServicePendingRequestPromise() {
        this.inputQueuePromise = this.$q.defer();
        if (this.queueingData.loadsVericalY.httpRequestCounter === 0) {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.queueingData.loadsVericalY.waiting = true;
        }
        return this.inputQueuePromise.promise;
    }

    getLoadsVerticalZServicePendingRequestPromise() {
        this.inputQueuePromise = this.$q.defer();
        if (this.queueingData.loadsVertZ.httpRequestCounter === 0) {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.queueingData.loadsVertZ.waiting = true;
        }
        return this.inputQueuePromise.promise;
    }

    getHeightAndSupportsServicePendingRequestPromise() {
        this.inputQueuePromise = this.$q.defer();
        if (this.queueingData.heightAndSupports.httpRequestCounter === 0) {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.queueingData.heightAndSupports.waiting = true;
        }
        return this.inputQueuePromise.promise;
    }

    getLoadsCllServicePendingRequestPromise() {
        this.inputQueuePromise = this.$q.defer();
        if (this.queueingData.loadsCll.httpRequestCounter === 0) {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.queueingData.loadsCll.waiting = true;
        }
        return this.inputQueuePromise.promise;
    }

    getLoadsHorizZServicePendingRequestPromise() {
        this.inputQueuePromise = this.$q.defer();
        if (this.queueingData.loadsHorizZ.httpRequestCounter === 0) {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.queueingData.loadsHorizZ.waiting = true;
        }
        return this.inputQueuePromise.promise;
    }

    getWindLoadingServicePendingRequestPromise() {
        this.inputQueuePromise = this.$q.defer();
        if (this.queueingData.windLoading.httpRequestCounter === 0) {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.queueingData.windLoading.waiting = true;
        }
        return this.inputQueuePromise.promise;
    }

    getSeismicLoadingServicePendingRequestPromise() {
        this.inputQueuePromise = this.$q.defer();
        if (this.queueingData.seismicLoading.httpRequestCounter === 0) {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.queueingData.seismicLoading.waiting = true;
        }
        return this.inputQueuePromise.promise;
    }

    getTaperedEndsServicePendingRequestPromise() {
        this.inputQueuePromise = this.$q.defer();
        if (this.queueingData.taperedEnds.httpRequestCounter === 0)
        {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.queueingData.taperedEnds.waiting = true;
        }
        return this.inputQueuePromise.promise;
    }

    getNotchedFlangeServicePendingRequestPromise() {
        this.inputQueuePromise = this.$q.defer();
        if (this.queueingData.notchedFlange.httpRequestCounter === 0)
        {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.queueingData.notchedFlange.waiting = true;
        }
        return this.inputQueuePromise.promise;
    }

    getSettingsServicePendingRequestPromise() {
        this.inputQueuePromise = this.$q.defer();
        if (this.queueingData.settings.httpRequestCounter === 0)
        {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.queueingData.settings.waiting = true;
        }
        return this.inputQueuePromise.promise;
    }

    getJobSummaryServicePendingRequestPromise() {
        this.inputQueuePromise = this.$q.defer();
        if (this.queueingData.jobSummary.httpRequestCounter === 0)
        {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.queueingData.jobSummary.waiting = true;
        }
        return this.inputQueuePromise.promise;
    }

    getQuickLoadsServicePendingRequestPromise() {
        this.inputQueuePromise = this.$q.defer();
        if (this.queueingData.quickLoads.httpRequestCounter === 0)
        {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.queueingData.quickLoads.waiting = true;
        }
        return this.inputQueuePromise.promise;
    }

    getJobSettingsServicePendingRequestPromise() {
        this.inputQueuePromise = this.$q.defer();
        if (this.queueingData.jobSettings.httpRequestCounter === 0)
        {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.queueingData.jobSettings.waiting = true;
        }
        return this.inputQueuePromise.promise;
    }
    getTeamServicePendingRequestPromise() {
        this.inputQueuePromise = this.$q.defer();
        if (this.queueingData.team.httpRequestCounter === 0)
        {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.queueingData.team.waiting = true;
        }
        return this.inputQueuePromise.promise;
    }

    getLevelPropertiesServicePendingRequestPromise() {
        this.inputQueuePromise = this.$q.defer();
        if (this.queueingData.levelProperties.httpRequestCounter === 0)
        {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.queueingData.levelProperties.waiting = true;
        }
        return this.inputQueuePromise.promise;
    }

    getMemberPropertiesServicePendingRequestPromise() {
        this.inputQueuePromise = this.$q.defer();
        if (this.queueingData.memberProperties.httpRequestCounter === 0)
        {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.queueingData.memberProperties.waiting = true;
        }
        return this.inputQueuePromise.promise;
    }

    getSolutionsServicePendingRequestPromise() {
        this.inputQueuePromise = this.$q.defer();
        if (this.queueingData.solutions.httpRequestCounter === 0)
        {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.queueingData.solutions.waiting = true;
        }
        return this.inputQueuePromise.promise;
    }

    getSpanServicePendingRequestPromise() {
        this.inputQueuePromise = this.$q.defer();
        if (this.queueingData.spansandsupports.httpRequestCounter === 0)
        {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.queueingData.spansandsupports.waiting = true;
        }
        return this.inputQueuePromise.promise;
    }

    getSupportServicePendingRequestPromise() {
        this.inputQueuePromise = this.$q.defer();
        if (this.queueingData.spansandsupports.httpRequestCounter === 0)
        {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.queueingData.spansandsupports.waiting = true;
        }
        return this.inputQueuePromise.promise;
    }

    getHolesServicePendingRequestPromise() {
        this.inputQueuePromise = this.$q.defer();
        if (this.queueingData.hole.httpRequestCounter === 0)
        {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.queueingData.hole.waiting = true;
        }
        return this.inputQueuePromise.promise;
    }

    getLocationAnalysisServicePendingRequestPromise() {
        this.inputQueuePromise = this.$q.defer();
        if (this.queueingData.locationAnalysis.httpRequestCounter === 0)
        {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.queueingData.locationAnalysis.waiting = true;
        }
        return this.inputQueuePromise.promise;
    }

    getProductSelectionServicePendingRequestPromise() {
        this.inputQueuePromise = this.$q.defer();
        if (this.queueingData.productSelection.httpRequestCounter === 0)
        {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.queueingData.productSelection.waiting = true;
        }
        return this.inputQueuePromise.promise;
    }

    getLoadsHorizYServicePendingRequestPromise() {
        this.inputQueuePromise = this.$q.defer();
        if (this.queueingData.loadsHorizY.httpRequestCounter === 0)
        {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.queueingData.loadsHorizY.waiting = true;
        }
        return this.inputQueuePromise.promise;
    }

    getFloorPerformanceServicePendingRequestPromise() {
        this.inputQueuePromise = this.$q.defer();
        if (this.queueingData.floorPerformance.httpRequestCounter === 0) {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.queueingData.floorPerformance.waiting = true;
        }
        return this.inputQueuePromise.promise;
    }

    getMemberManagementServicePendingRequestPromise() {
        this.inputQueuePromise = this.$q.defer();
        if (this.queueingData.memberManagement.httpRequestCounter === 0) {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.queueingData.memberManagement.waiting = true;
        }
        return this.inputQueuePromise.promise;
    }

    getFileSystemPendingRequestPromise() {
        this.inputQueuePromise = this.$q.defer();
        if (this.queueingData.fileSystem.httpRequestCounter === 0) {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.queueingData.fileSystem.waiting = true;
        }
        return this.inputQueuePromise.promise;
    }

    getProjectManagementRequestPromise() {
        this.inputQueuePromise = this.$q.defer();
        if (this.queueingData.projectManagement.httpRequestCounter === 0) {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.queueingData.projectManagement.waiting = true;
        }
        return this.inputQueuePromise.promise;
    }

    getReportRequestPromise() {
        this.inputQueuePromise = this.$q.defer();
        if (this.queueingData.report.httpRequestCounter === 0) {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.queueingData.report.waiting = true;
        }
        return this.inputQueuePromise.promise;
    }

    getConnectorServicePendingRequestPromise() {
        this.inputQueuePromise = this.$q.defer();
        if (this.queueingData.connector.httpRequestCounter === 0) {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.queueingData.connector.waiting = true;
        }
        return this.inputQueuePromise.promise;
    }

    getMaterialsManagementPendingRequestPromise() {
        this.inputQueuePromise = this.$q.defer();
        if (this.queueingData.materialsManagement.httpRequestCounter === 0) {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.queueingData.materialsManagement.waiting = true;
        }
        return this.inputQueuePromise.promise;
    }

    getTransferReactionsServicePendingRequestPromise() {
        this.inputQueuePromise = this.$q.defer();
        if (this.queueingData.transferReactions.httpRequestCounter === 0) {
            this.inputQueuePromise.resolve();
            return this.inputQueuePromise.promise;
        }
        else {
            this.queueingData.transferReactions.waiting = true;
        }
        return this.inputQueuePromise.promise;
    }

    requestSimplePost(apiRoute,data) {
        //'api/<apicontrollername>/<apimethodname>'
        var deferred = this.$q.defer();
        this.$http.post(this.serviceBase + apiRoute, data, {
            headers: { 'Content-Type': 'application/json' }
        })
        .then((response) => {
            deferred.resolve(response.data);
        }, (err, status) => {
            deferred.reject(err);
        });
        return deferred.promise;
    }
}
