import ngAuthSettings from '../../environmentConfigs/environment.dev';

export default class resetPasswordController {
    constructor ($scope, $location, $http, $state, $timeout, resourceMessagingService, authService) {
        /* jshint validthis: true */
        'ngInject';

        this.$scope = $scope;
        this.$location = $location;
        this.$http = $http;
        this.$state = $state;
        this.$timeout = $timeout;
        this.resourceMessagingService = resourceMessagingService;
        this.ngAuthSettings = ngAuthSettings;
        this.authService = authService;

        var serviceBase = ngAuthSettings.apiServiceBaseUri;

        this.passwordsMatch = false;
        this.submitted = false;
        this.disableSubmitAction = false;
        this.emailSent = false;
        this.passwordLengthValid = false;
        this.passwordHasUppercase = false;
        this.passwordHasNumber = false;
        this.showPassword = false;
        this.showConfirmPassword = false;

        this.$scope.$watch(() => {
            return this.password;
        }, (password) => {
            if (password && password.length >= 6) {
                this.passwordLengthValid = true;
            } else {
                this.passwordLengthValid = false;
            }
            // Validate Capital Letter
            if (password && password.match(/[A-Z]/)) {
                this.passwordHasUppercase = true;
            } else {
                this.passwordHasUppercase = false;
            }
            // Validate number
            if (password && password.match(/\d/)) {
                this.passwordHasNumber = true;
            } else {
                this.passwordHasNumber = false;
            }
            if (this.passwordLengthValid && this.passwordHasUppercase && this.passwordHasNumber) {
                this.showPassword = false;
            }
            if ((password && password.length >= 1) && (!this.passwordLengthValid || !this.passwordHasUppercase || !this.passwordHasNumber)) {
                this.showPassword = true;
            }
        });
        this.$scope.$watch(() => {
            return this.confirmPassword;
        }, (confirmPassword) => {
            this.comparePasswords();
        });

        this.activate();
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    activate() {
        //get query strings from url
        this.userId = this.$location.search()['userId'];
        this.code = this.$location.search()['code'];
    }

    comparePasswords() {
        if (this.password !== this.confirmPassword) {
            this.passwordsMatch = false;
            this.showConfirmPassword = true;
        }
        else {
            this.passwordsMatch = true;
            this.$timeout(() => {
                this.showConfirmPassword = false;
            }, 100);
        }
    }

    tryReset(isValid) {
        this.submitted = true;
        this.comparePasswords();
        if (!this.passwordsMatch || !this.passwordLengthValid || !this.passwordHasUppercase || !this.passwordHasNumber) {
            isValid = false;
            this.showPassword = true;
        }
        if (this.passwordLengthValid && this.passwordHasUppercase && this.passwordHasNumber) {
            this.showPassword = false;
        }
        if (this.showPassword === true) {
            this.showConfirmPassword = false;
        }
        if (isValid) {
            this.disableSubmitAction = true;

            this.authService.resetPassword(this.userId, this.code, this.password)
                .then((response) => {
                    this.finishedProcessing = true;
                    this.changeSuccess = true;
                    this.message = '';
                    this.$timeout(() => {
                        this.$state.go('account.login');
                    }, 4000);
                })
                .catch((message) => {
                    this.finishedProcessing = true;
                    this.changeSuccess = false;
                    this.disableSubmitAction = false;
                    this.message = message;
                });
        }

    }

    showPasswordRules() {
        this.showPassword = true;
    }

    hidePasswordRules() {
        this.showPassword = false;
    }

    showConfirmPasswordRules() {
        this.showConfirmPassword = true;
    }

    hideConfirmPasswordRules() {
        this.showConfirmPassword = false;
    }
}
// (function () {
//     'use strict';
//     var app = angular.module('app');

//     app.controller('resetPasswordController', ['$scope', '$location', '$http', '$state', '$timeout', 'resourceMessagingService','ngAuthSettings', 'authService',
//         function ($scope, $location, $http, $state, $timeout, resourceMessagingService, ngAuthSettings, authService) {
//             /* jshint validthis: true */
//             var vm = this;

//             var serviceBase = ngAuthSettings.apiServiceBaseUri;

//             vm.passwordsMatch = false;
//             vm.submitted = false;
//             vm.disableSubmitAction = false;
//             vm.emailSent = false;
//             vm.passwordLengthValid = false;
//             vm.passwordHasUppercase = false;
//             vm.passwordHasNumber = false;
//             vm.showPassword = false;
//             vm.showConfirmPassword = false;

//             vm.getText = getText;
//             vm.tryReset = tryReset;
//             vm.comparePasswords = comparePasswords;
//             vm.showPasswordRules = showPasswordRules;
//             vm.hidePasswordRules = hidePasswordRules;
//             vm.showConfirmPasswordRules = showConfirmPasswordRules;
//             vm.hideConfirmPasswordRules = hideConfirmPasswordRules;

//             function getText(key) {
//                 return resourceMessagingService.getText(key);
//             }

//             function activate() {
//                 //get query strings from url
//                 vm.userId = $location.search()['userId'];
//                 vm.code = $location.search()['code'];
//             }

//             function comparePasswords() {
//                 if (vm.password !== vm.confirmPassword) {
//                     vm.passwordsMatch = false;
//                     vm.showConfirmPassword = true;
//                 }
//                 else {
//                     vm.passwordsMatch = true;
//                     $timeout(function() {
//                         vm.showConfirmPassword = false;
//                     }, 100);
//                 }
//             }

//             function tryReset(isValid) {
//                 vm.submitted = true;
//                 comparePasswords();
//                 if (!vm.passwordsMatch || !vm.passwordLengthValid || !vm.passwordHasUppercase || !vm.passwordHasNumber) {
//                     isValid = false;
//                     vm.showPassword = true;
//                 }
//                 if (vm.passwordLengthValid && vm.passwordHasUppercase && vm.passwordHasNumber) {
//                     vm.showPassword = false;
//                 }
//                 if (vm.showPassword === true) {
//                     vm.showConfirmPassword = false;
//                 }
//                 if (isValid) {
//                     vm.disableSubmitAction = true;

//                     authService.resetPassword(vm.userId, vm.code, vm.password)
//                         .then(function success(response) {
//                             vm.finishedProcessing = true;
//                             vm.changeSuccess = true;
//                             vm.message = '';
//                             $timeout(function () {
//                                 $state.go('account.login');
//                             }, 4000);
//                         })
//                         .catch(function error(message) {
//                             vm.finishedProcessing = true;
//                             vm.changeSuccess = false;
//                             vm.disableSubmitAction = false;
//                             vm.message = message;
//                         });
//                 }

//             }

//             function showPasswordRules() {
//                 vm.showPassword = true;
//             }

//             function hidePasswordRules() {
//                 vm.showPassword = false;
//             }

//             function showConfirmPasswordRules() {
//                 vm.showConfirmPassword = true;
//             }

//             function hideConfirmPasswordRules() {
//                 vm.showConfirmPassword = false;
//             }

//             $scope.$watch(function() {
//                 return vm.password;
//             }, function (password) {
//                 if (password && password.length >= 6) {
//                     vm.passwordLengthValid = true;
//                 } else {
//                     vm.passwordLengthValid = false;
//                 }
//                 // Validate Capital Letter
//                 if (password && password.match(/[A-Z]/)) {
//                     vm.passwordHasUppercase = true;
//                 } else {
//                     vm.passwordHasUppercase = false;
//                 }
//                 // Validate number
//                 if (password && password.match(/\d/)) {
//                     vm.passwordHasNumber = true;
//                 } else {
//                     vm.passwordHasNumber = false;
//                 }
//                 if (vm.passwordLengthValid && vm.passwordHasUppercase && vm.passwordHasNumber) {
//                     vm.showPassword = false;
//                 }
//                 if ((password && password.length >= 1) && (!vm.passwordLengthValid || !vm.passwordHasUppercase || !vm.passwordHasNumber)) {
//                     vm.showPassword = true;
//                 }
//             });
//             $scope.$watch(function() {
//                 return vm.confirmPassword;
//             }, function (confirmPassword) {
//                 comparePasswords();
//             });
//             activate();
//         }]);
// }());
