export default class indexController {
    constructor($scope, $location, authService) {
        'ngInject';
        $scope.$on('$viewContentLoaded', () => {
            $scope.isAuthenticated = authService.authentication.isAuth;
            var pageDoesNotRequireAuth = $location.$$path.indexOf('/account/') > -1;
            //$scope.hideSplash = $scope.isAuthenticated || pageDoesNotRequireAuth
        });
    }
}
//use the code below if you dont want to use 'ngInject'
//indexController.$inject['$scope', '$location', 'authService'];
