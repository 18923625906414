import wyLegalFooterTemplate from './wyLegalFooter.html';

export default class wyLegalFooter {
    constructor() {
        this.template = wyLegalFooterTemplate;
        this.scope = {};
    }

    controller($scope, $rootScope, $state, resourceMessagingService) {
        'ngInject';
        $scope.$rootScope = $rootScope;
        $scope.$state = $state;
        $scope.resourceMessagingService = resourceMessagingService;
    }

    link(scope, element, attrs) {
        scope.getText = getText;
        scope.getCopyrightYear = getCopyrightYear;
        scope.eulaClicked = eulaClicked;
        scope.privacyPolicyClicked = privacyPolicyClicked;
        scope.ccpaCaliforniaClicked = ccpaCaliforniaClicked;

        function getText(key) {
            return scope.resourceMessagingService.getText(key);
        }

        function getCopyrightYear() {
            let currentDate = new Date();
            return currentDate.getFullYear().toString();
        }

        function eulaClicked() {
            let win = window.open('http://eula.weyerhaeusersoftware.com/', '_blank');
            win.focus();
        }

        function privacyPolicyClicked() {
            let win = window.open('https://www.weyerhaeuser.com/privacy/privacy-policy/', '_blank');
            win.focus();
        }

        function ccpaCaliforniaClicked() {
            let win = window.open('https://www.weyerhaeuser.com/privacy/CCPA/', '_blank');
            win.focus();
        }
    }
}
