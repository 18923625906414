import ngAuthSettings from '../environmentConfigs/environment.dev';
export default class authService {
    constructor($injector,  $q, $state, $http, allModelDataService,
        projectManagementService, fileSystemService, dataMiningService) {
        'ngInject';


        this.$state = $state;
        this.projectManagementService = projectManagementService;
        this.allModelDataService = allModelDataService;
        this.$q = $q;
        this.$http = $http;
        this.serviceBase = ngAuthSettings.apiServiceBaseUri;
        this.fileSystemService = fileSystemService;
        this.dataMiningService = dataMiningService;

        this.authentication = {
            isAuth: false,
            userName: '',
            useRefreshTokens: false
        };

        //this.authServiceFactory.authentication = this.authentication;// dont know what this was for?
    }
    resetPassword(userId, code, password) {
        var data = {
            'userId': userId,
            'code': code,
            'password': password
        };
        var deferred = this.$q.defer();
        this.$http.post(this.serviceBase + 'api/Account/ResetPassword', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            deferred.resolve(response.data);
        }, (err, status) => {
            var message = this._getErrorsFromAccountControllerCalls(err);
            deferred.reject(message);
        });
        return deferred.promise;
    }

    // getPaeVersion() {
    //     this.$http.get(this.serviceBase + 'api/Account/PaeVersion')
    //         .then((response) => {
    //             this.allModelDataService.setPaeVersion(response.data.paeVersion);
    //             this.allModelDataService.setPaeVersionCommercial(response.data.paeVersionCommercial);
    //         }, (err, status) => {
    //             this.allModelDataService.setPaeVersion(null);
    //             this.allModelDataService.setPaeVersionCommercial(null);
    //         });
    // }

    getWebApiVersion() {
        return this.$http.get(this.serviceBase + 'api/Account/WebApiVersion');
    }

    getNextMaintenanceDate() {
        return this.$http.get(this.serviceBase + 'api/Account/NextMaintenanceDate');
    }

    getLoginPageData() {
        return this.$http.get(this.serviceBase + 'api/Account/GetLoginPageData');
    }

    // getCurrentClientIP() {
    //     //return this.$http.get('https://geolocation-db.com/json/');
    //     //return this.$http.get('http://l2.io/ip.js');
    //     return this.$http.get('http://gd.geobytes.com/gd?after=-1&amp;variables=GeobytesCountry,GeobytesCity');
    // }

    saveRegistration(registration) {

        //_logOut();
        return this.$http({
            method: 'POST',
            url: this.serviceBase + 'api/Account/Register',
            data: registration,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            return response;
        });

    }

    changePassword(currentPassword, newPassword, confirmPassword) {
        var data = {
            'OldPassword': currentPassword,
            'NewPassword': newPassword,
            'ConfirmPassword': confirmPassword
        };
        var deferred = this.$q.defer();
        this.$http.post(this.serviceBase + 'api/Account/ChangePassword', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            deferred.resolve(response.data);
        }, (err, status) => {
            var message = this._getErrorsFromAccountControllerCalls(err);
            deferred.reject(message);
        });

        return deferred.promise;
    }

    sendConfirmationEmail(emailAddress) {
        var data = {
            'Email': emailAddress
        };
        var deferred = this.$q.defer();
        this.$http.post(this.serviceBase + 'api/Account/RegenerateEmailConfirmation', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            deferred.resolve(response.data);
        }, (err, status) => {
            var message = this._getErrorsFromAccountControllerCalls(err);
            deferred.reject(message);
        });

        return deferred.promise;
    }

    forgotPassword(emailAddress) {
        var data = {
            'Email': emailAddress
        };
        var deferred = this.$q.defer();
        this.$http.post(this.serviceBase + 'api/Account/ForgotPassword', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            deferred.resolve(response.data);
        }, (err, status) => {
            //var message = this._getErrorsFromAccountControllerCalls(err);
            //deferred.reject(message);
            deferred.reject(err);
        });

        return deferred.promise;
    }

    login(loginData) {

        var encodedPassword = encodeURIComponent(loginData.password);

        var data = 'grant_type=password&username=' + loginData.userName +
            '&password=' + encodedPassword;

        var deferred = this.$q.defer();

        this.$http.post(this.serviceBase + 'token', data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response)=> {

            var displayMe = {
                'token': response.data.access_token,
                'username': loginData.userName
            };
            //logger.logWarning('successful login, save data', displayMe, '', false);
            this.fileSystemService.getFileSystemData(false, null, null);

            this.dataMiningService.sendLoginData();
            this.allModelDataService.setAuthorizationData({
                token: response.data.access_token,
                userName: loginData.userName
            });
            //clear out the current project since a new project was
            //just created on the server the new project will be retrieved
            //by the job tree in a subsequent call.
            this.projectManagementService.clearProject();
            this.authentication.isAuth = true;
            this.authentication.userName = loginData.userName;
            this.authentication.useRefreshTokens = loginData.useRefreshTokens;

            deferred.resolve(response);

        }, (err, status) => {
            //logOut();
            deferred.reject(err);
        });

        return deferred.promise;
    };

    clearLocalStorageData() {
        this.allModelDataService.setAuthorizationData(null);
        this.allModelDataService.clearPostResponseData(null);
        this.allModelDataService.clearSelectedJobTreeNode();
        this.allModelDataService.clearJobTreeData();
        this.allModelDataService.clearCurrentTab();
        this.allModelDataService.clearQuickLoadsModelData();
    }

    logOut() {
        this.fileSystemService.saveCurrentFile();
        //call the logout api method.
        let fileToUnlock = '';
        let prData = this.allModelDataService.getPostResponseData();
        try {
            fileToUnlock = this.allModelDataService.getPostResponseData().ApplicationData.ProjectManagerData.ProjectFileTreeID;
        } catch (error) {

        }
        let data = {
            'postResponseData': prData,
            'value': fileToUnlock
        };
        //call the logout api method.
        return this.$http.post(this.serviceBase + 'api/Account/TerminateSession', data,{
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            //clear the authentication data from local storage.
            this.clearLocalStorageData();
            this.fileSystemService.clearFileTreeData();
            //set the authentication object values
            this.authentication.isAuth = false;
            this.authentication.userName = '';
            this.authentication.useRefreshTokens = false;
            //redirect to login.
            this.$state.go('account.login');
            return response;
        });

    };

    fillAuthData() {

        var authData = this.allModelDataService.getAuthorizationData();
        if (authData) {
            this.authentication.isAuth = true;
            this.authentication.userName = authData.userName;
            this.authentication.useRefreshTokens = authData.useRefreshTokens;
        }

    };

    _getErrorsFromAccountControllerCalls(response) {
        var errors = [];
        for (var key in response.data.ModelState) {
            if ({}.hasOwnProperty.call(response.data.ModelState, key)) {
                for (var i = 0; i < response.data.ModelState[key].length; i++) {
                    errors.push(response.data.ModelState[key][i]);
                }
            }
        }
        return errors.join(' ');
    }
}
