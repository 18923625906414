import createTeamTemplate from '../../modals/createTeam/createTeam.html';

export default class manageController {
    constructor($scope, $state, $uibModal, resourceMessagingService, teamService) {
        'ngInject';

        this.$scope = $scope;
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.resourceMessagingService = resourceMessagingService;
        this.teamService = teamService;

        this.showSplash = false;
        this.showDeleteSplash = false;
        this.showCopyMove = false;
        this.showDeleteWarning = false;
        this.loadingTeams;
        this.allTeams;

        this.handleMoveCopySplashVisible = $scope.$on('moveCopySplashVisible', (event, isVisible) => {
            this.showSplash = isVisible;
            this.showCopyMove = isVisible;
        });
        this.handleDeleteSplashVisible = $scope.$on('deleteSplashVisible', (event, isVisible) => {
            this.showSplash = isVisible;
            this.showDeleteSplash = isVisible;
            this.showDeleteWarning = isVisible;
        });

        this.$scope.$on('$destroy', () => {
            this.handleMoveCopySplashVisible();
            this.handleDeleteSplashVisible();
        });

        this.$scope.$on('teamsUpdated', (event) => {
            this._getTeams(false, '');
        });

        this.activate();
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    activate() {
        this._getTeams(false, '');
    }

    getCurrentTeamId() {
        if (this.$state.current.name.indexOf('application.manage.team') > -1 ||
        this.$state.current.name.indexOf('application.manage.teamInfo') > -1) {
            return this.$state.params.teamId;
        } else {
            return null;
        }
    }
    getIsRecent() {
        if (this.$state.current.name.indexOf('recent') > -1) {
            return true;
        } else {
            return false;
        }
    }

    getIsTeamInfo() {
        if (this.$state.current.name.indexOf('application.manage.teamInfo') > -1) {
            return true;
        } else {
            return false;
        }
    }

    hideCreateTeamButton() {
        if (this.$state.$current.name === 'application.manage.createTeam') {
            return true;
        }
        return false;
    }

    createTeam() {
        this._showCreateTeamModal();
    }
    showPrivateFiles(isRecent) {
        var route;
        if (isRecent) {
            route = 'application.manage.files.recent';
        } else {
            route = 'application.manage.files';
        }
        this.$state.go(route,{},{reload:'application.manage.files'});
    }
    showTeamFiles(teamId,isRecent) {
        var route;
        if (isRecent) {
            route = 'application.manage.team.recent';
        } else {
            route = 'application.manage.team';
        }
        this.$state.go(route,{teamId: teamId, openToCurrentFile: false},{reload:'application.manage.team'});
    }

    _getTeams(goToFiles, teamId) {
        this.loadingTeams = true;
        this.teamService.getAllTeamsForUser().then(
            (response) => {
                this.loadingTeams = false;
                this.allTeams = response;
                if (goToFiles) {
                    this.$state.go('application.manage.team',{teamId: teamId, openToCurrentFile: false},{reload:'application.manage.team'});
                }
            },
            (error) => {
                //console.log(error);
            }
        );
    }

    showTeamInfo(id) {
        this.$state.go('application.manage.teamInfo', {teamId: id});
    }

    hideSplash() {
        this.showSplash = false;
        this.showCopyMove = false;
        this.showDeleteWarning = false;
    }

    deleteWarningComplete(canDelete) {
        this.$scope.$broadcast('deleteDialogComplete', canDelete);
        this.showSplash = false;
        this.showDeleteWarning = false;
    }

    moveCopyComplete(destinationFolderId) {
        this.$scope.$broadcast('moveCopyComplete', destinationFolderId);
        this.showSplash = false;
        this.showCopyMove = false;
    }

    _showCreateTeamModal() {
        var modalInstance = this.$uibModal.open({
            animation: true,
            template: createTeamTemplate,
            controller: 'createTeamController',
            controllerAs: 'createTeamCtrl',
            backdrop: 'static'
        });

        modalInstance.result.then((result) => {
            //refresh the team list
            this._getTeams(true, result);

        }, (error) => {
        });
    }
}
// (function () {
//     'use strict';

//     var controllerId = 'manageController';
//     angular.module('app').controller(controllerId,
//         ['$scope', '$rootScope', '$state', '$uibModal', '$templateCache', 'uiTourService', 'common',
//             'resourceMessagingService', 'teamService',  manage]);
//     //eslint-disable-next-line max-params
//     function manage($scope, $rootScope, $state, $uibModal, $templateCache, uiTourService, common,
//             resourceMessagingService, teamService) {
//         /* jshint validthis: true */
//         var vm = this;

//         var createTeamTemplate = $templateCache.get('app/loggedIn/modals/createTeam/createTeam.html');

//         vm.hideSplash = hideSplash;
//         vm.moveCopyComplete = moveCopyComplete;

//         vm.showSplash = false;
//         vm.showCopyMove = false;
//         vm.showDeleteWarning = false;
//         vm.allTeams;
//         vm.getText = resourceMessagingService.getText;
//         vm.showTeamInfo = showTeamInfo;
//         vm.getIsTeamInfo = getIsTeamInfo;
//         vm.showPrivateFiles = showPrivateFiles;
//         vm.showTeamFiles = showTeamFiles;
//         vm.deleteWarningComplete = deleteWarningComplete;
//         vm.createTeam = createTeam;
//         vm.hideCreateTeamButton = hideCreateTeamButton;
//         vm.getCurrentTeamId = getCurrentTeamId;
//         vm.setActionBtn = setActionBtn;
//         vm.getIsRecent = getIsRecent;

//         var basicTour = uiTourService.getTourByName('basicTour');

//         function activate() {
//             _getTeams(false, '');
//         }

//         function setActionBtn(prev, next) {
//             basicTour.prevDisabled = prev;
//             basicTour.nextDisabled = next;
//         }

//         function getCurrentTeamId() {
//             if ($state.current.name.indexOf('application.manage.team') > -1 ||
//                 $state.current.name.indexOf('application.manage.teamInfo') > -1) {
//                 return $state.params.teamId;
//             } else {
//                 return null;
//             }
//         }
//         function getIsRecent() {
//             if ($state.current.name.indexOf('recent') > -1) {
//                 return true;
//             } else {
//                 return false;
//             }
//         }

//         function getIsTeamInfo() {
//             if ($state.current.name.indexOf('application.manage.teamInfo') > -1) {
//                 return true;
//             } else {
//                 return false;
//             }
//         }

//         function hideCreateTeamButton() {
//             if ($state.$current.name === 'application.manage.createTeam') {
//                 return true;
//             }
//             return false;
//         }

//         function createTeam() {
//             if (basicTour.getStatus() === 1) {
//                 basicTour.pause();
//             }

//             _showCreateTeamModal();
//         }
//         function showPrivateFiles(isRecent) {
//             var route;
//             if (isRecent) {
//                 route = 'application.manage.files.recent';
//             } else {
//                 route = 'application.manage.files';
//             }
//             $state.go(route,{},{reload:'application.manage.files'});
//         }
//         function showTeamFiles(teamId,isRecent) {
//             var route;
//             if (isRecent) {
//                 route = 'application.manage.team.recent';
//             } else {
//                 route = 'application.manage.team';
//             }
//             $state.go(route,{teamId: teamId, openToCurrentFile: false},{reload:'application.manage.team'});
//         }

//         function _getTeams(goToFiles, teamId) {
//             teamService.getAllTeamsForUser().then(
//                 function success (response) {
//                     vm.allTeams = response;
//                     if (goToFiles) {
//                         $state.go('application.manage.team',{teamId: teamId, openToCurrentFile: false},{reload:'application.manage.team'});
//                     }

//                     if (basicTour && basicTour.getStatus() === 2 && basicTour.getCurrentStep() && basicTour.getCurrentStep().stepId === 'createATeam') {
//                         basicTour.startAt('teamDisplay');
//                     }
//                 },
//                 function failure (error) {
//                     //console.log(error);
//                 }
//             );
//         }

//         function showTeamInfo(id) {
//             $state.go('application.manage.teamInfo', {teamId: id});
//         }

//         function hideSplash() {
//             vm.showSplash = false;
//             vm.showCopyMove = false;
//             vm.showDeleteWarning = false;
//         }

//         var handleMoveCopySplashVisible = $scope.$on('moveCopySplashVisible', function(event, isVisible) {
//             vm.showSplash = isVisible;
//             vm.showCopyMove = isVisible;
//         });

//         var handleDeleteSplashVisible = $scope.$on('deleteSplashVisible', function(event, isVisible) {
//             vm.showSplash = isVisible;
//             vm.showDeleteWarning = isVisible;
//         });

//         $scope.$on('$destroy', function() {
//             handleMoveCopySplashVisible();
//             handleDeleteSplashVisible();
//         });

//         $scope.$on('teamsUpdated', function(event) {
//             _getTeams(false, '');
//         });

//         function deleteWarningComplete(canDelete) {
//             $scope.$broadcast('deleteDialogComplete', canDelete);
//             vm.showSplash = false;
//             vm.showDeleteWarning = false;
//         }

//         function moveCopyComplete(destinationFolderId) {
//             $scope.$broadcast('moveCopyComplete', destinationFolderId);
//             vm.showSplash = false;
//             vm.showCopyMove = false;
//         }

//         function _showCreateTeamModal() {
//             var modalInstance = $uibModal.open({
//                 animation: true,
//                 template: createTeamTemplate,
//                 controller: 'createTeamController',
//                 controllerAs: 'createTeamCtrl',
//                 backdrop: 'static'
//             });

//             modalInstance.result.then(function (result) {
//                 //refresh the team list
//                 _getTeams(true, result);

//             }, function (error) {
//                 // cancel clicked: tour continue
//                 if (basicTour.getStatus() === 2 && basicTour.getCurrentStep() && basicTour.getCurrentStep().stepId === 'createATeam') {
//                     basicTour.startAt('teamDisplay');
//                 }
//             });
//         }

//         activate();
//     };
// }());
