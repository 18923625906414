import ngAuthSettings from '../environmentConfigs/environment.dev';
export default class datacontext {
    constructor($http, common, $timeout,
        $location, $rootScope, allModelDataService) {
        'ngInject';

        this.$http = $http;
        this.common = common;
        this.ngAuthSettings = ngAuthSettings;
        this.$timeout = $timeout;
        this.$location = $location;
        this.$rootScope = $rootScope;
        this.allModelDataService = allModelDataService;

        this.$q = this.common.$q();
        this.serviceBase = ngAuthSettings.apiServiceBaseUri;
    }

    getIsAuthenticated() {
        let deferred = this.$q.defer();

        let authData = this.allModelDataService.getAuthorizationData();
        if (authData) {
            deferred.resolve();
        }
        else {
            this.$rootScope.message = 'You need to log in.';
            deferred.reject();
            this.$location.path('/account/login');
        }
    }

    APIGetTest() {
        var deferred = this.$q.defer();

        this.$http.get(this.serviceBase + 'api/Values/Values/5')
        .then((response) => {
            deferred.resolve(response);
        }, (err, status) => {
            deferred.reject(err);
        });

        return deferred.promise;
    }

}
// (function () {
//     'use strict';

//     var app = angular.module('app');

//     var serviceId = 'datacontext';
//     app.factory(serviceId, ['$http', 'common', 'ngAuthSettings',
//         '$timeout', '$location', '$rootScope', 'allModelDataService', datacontext]);

//     function datacontext($http, common, ngAuthSettings, $timeout,
//         $location, $rootScope, allModelDataService) {
//         var $q = common.$q;
//         var serviceBase = ngAuthSettings.apiServiceBaseUri;

//         var service = {
//             getIsAuthenticated: getIsAuthenticated,
//             APIGetTest: APIGetTest
//         };

//         function getIsAuthenticated() {
//             var deferred = $q.defer();

//             var authData = allModelDataService.getAuthorizationData();
//             if (authData) {
//                 deferred.resolve();
//             }
//             else {
//                 $rootScope.message = 'You need to log in.';
//                 deferred.reject();
//                 $location.path('/account/login');
//                 //$location.url('/account/login');
//             }
//         }

//         function APIGetTest() {
//             var deferred = $q.defer();

//             $http.get(serviceBase + 'api/Values/Values/5').then(function success(response) {
//                 deferred.resolve(response);
//             }, function error(err, status) {
//                 deferred.reject(err);
//             });

//             return deferred.promise;
//         }

//         return service;
//     }
// }());
