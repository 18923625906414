import fileSystemService from '../../../services/fileSystemService';

export default class jobSummaryController {
    constructor(
        $scope,
        $rootScope,
        $timeout,
        $compile,
        $sce,
        common,
        allModelDataService,
        jobSummaryService,
        FileSaver,
        forteCookieService,
        resourceMessagingService,
        spinner
    ) {
        'ngInject';

        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$timeout = $timeout;
        this.$compile = $compile;
        this.$sce = $sce;
        this.common = common;
        this.allModelDataService = allModelDataService;
        this.jobSummaryService = jobSummaryService;
        this.FileSaver = FileSaver;
        this.forteCookieService = forteCookieService;
        this.resourceMessagingService = resourceMessagingService;
        this.spinner = spinner;

        this.$q = common.$q();
        this.graphicsForReports = null;

        this.hiddenLevels = [];
        this.jsData = null;
        this.printAllUI = false;
        this.printing = false;
        this.isJobSummary = false;
        this.isMaterialList = false;
        this.printingAllPDF = false;
        this.printAllMemberIDs = [];
        this.printAllGraphicId = 'printAllPdf';
        this.disableNextInLastStep = false;
        this.showUtilizationPercentage = false;

        this.handleJobTreeUpdated = $scope.$on(
            'handleJobTreeUpdated',
            (event, data) => {
                this._refreshJSData();
            }
        );

        this.$scope.$on('$destroy', () => {
            this.handleJobTreeUpdated();
        });

        this.activate();
    }

    activate() {
        this._refreshJSData();
        this._initColumnWidths();
        var prData = this.allModelDataService.getPostResponseData();
        this.programSettingsData = prData.ApplicationData.ProgramSettingsData;
        this.showUtilizationPercentage = this.forteCookieService.getUtilizationPercentageEnabled();
        this.softwareOperator =
            prData.ApplicationData.JobSettingsData.SoftwareOperator;
        this.fileName = prData.ApplicationData.ProjectManagerData.ProjectName;
        this.uom = prData.ApplicationData.ProgramSettingsData.UOM;
        this.jobNotes = prData.ApplicationData.JobSettingsData.JobNotes;
        this.jobNotesTrustedHtml = this.$sce.trustAsHtml(
            this.jobNotes.replace(/\n/g, '<br/>')
        );
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    goToMemberReport(containerId, member) {
        var nodeId = 'c_' + containerId + 'm_' + member.MemberID;
        var isDesignable = member.IsDesignable;

        var data = {
            nodeId: nodeId,
            isDesignable: isDesignable,
        };
        this.$rootScope.$broadcast('selectNodeFromJobSummary', data);
    }

    designAll() {
        this.startingDesignAllCount = this.validUndesignedMembers.length;
        //show the splash with a progress bar
        //console.log('jobSumCtrl-designAll-spinnerShow');
        this.spinner.spinnerShow(
            this.getText('designing') +
                ' ' +
                this.startingDesignAllCount +
                ' ' +
                this.getText('memberOrMembers')
        );
        this._popNextMemberAndDesign();
    }

    //this function is utilized to force this process to execute synchronusly
    _popNextMemberAndDesign() {
        if (this.validUndesignedMembers.length > 0) {
            var currentMember = this.validUndesignedMembers.shift();
            this.jobSummaryService
                .designMemberInBackground(
                    currentMember.IDTag,
                    this.allModelDataService.getEchoLogOn
                )
                .then((response) => {
                    this._popNextMemberAndDesign();
                });
        } else {
            //were done. refresh the view and hide the splash
            //console.log('jobSumCtrl-popNextMemberAndDesign-spinnerHide');
            this.spinner.spinnerHide();
            this._refreshJSData();
        }
    }

    printJobSummaryPDF() {
        this.printing = true;
        this.isJobSummary = true;
        var memberIDs = [];
        //console.log('jobSumCtrl-printJobSummaryPDF-spinnerShow');
        this.spinner.spinnerShow(this.getText('mainform_menu_print'));
        for (var i = 0; i < this.jsData.length; i++) {
            var container = this.jsData[i];
            container.print = 1;
            for (var j = 0; j < container.Members.length; j++) {
                var member = container.Members[j];
                member.print = 1;
                memberIDs.push(member.IDTag);
            }
        }

        this._printJobSummaryPDF(memberIDs).then(() => {
            //console.log('jobSumCtrl-printJobSummaryPDF-spinnerHide');
            this.spinner.spinnerHide();
        });
    }

    printMaterialListPDF() {
        this.printing = true;
        this.isMaterialList = true;
        this.spinner.spinnerShow(this.getText('mainform_menu_print'));
        this._printMaterialListPDF().then(() => {
            this.spinner.spinnerHide();
        });
    }

    selectionsComplete() {
        this.printAllUI = false;
        this.printAllMemberIDs = [];

        for (var i = 0; i < this.jsData.length; i++) {
            var container = this.jsData[i];
            for (var j = 0; j < container.Members.length; j++) {
                var member = container.Members[j];
                if (member.print === 1) {
                    this.printAllMemberIDs.push(member.IDTag);
                }
            }
        }

        if (this.printAllMemberIDs.length > 0) {
            this._printAllPDF(this.printAllMemberIDs);
        }
    }

    _printJobSummaryPDF(memberIDs) {
        var deferred = this.$q.defer();
        var prData = this.allModelDataService.getPostResponseData();
        var projectName = prData.ApplicationData.ProjectManagerData.ProjectName;
        var progSettingsData = prData.ApplicationData.ProgramSettingsData;
        var jobNotes = prData.ApplicationData.JobSettingsData.JobNotes;
        this.jobSummaryService
            .printJobSummaryPDF(
                projectName,
                jobNotes,
                progSettingsData,
                this.jsData,
                memberIDs
            )
            .then((response) => {
                var fileData = new Blob([response.data], {
                    type: 'application/pdf',
                });
                this.FileSaver.saveAs(
                    fileData,
                    projectName + '_jobsummary.pdf'
                );

                //console.log('jobSumCtrl-printJobSummaryPDF-spinnerHide');
                this.spinner.spinnerHide();
                this.printing = false;
                deferred.resolve();
            });
        return deferred.promise;
    }

    _printMaterialListPDF() {
        var deferred = this.$q.defer();
        var prData = this.allModelDataService.getPostResponseData();
        var projectName = prData.ApplicationData.ProjectManagerData.ProjectName;
        this.jobSummaryService
            .printMaterialListPDF(
                prData
            )
            .then((response) => {
                var fileData = new Blob([response.data], {
                    type: 'application/pdf',
                });
                this.FileSaver.saveAs(
                    fileData,
                    projectName + '_materiallist.pdf'
                );
                this.spinner.spinnerHide();
                this.printing = false;
                deferred.resolve();
            });
        return deferred.promise;
    }

    _printAllPDF(memberIDs) {
        //console.log('jobSumCtrl-_printAllPDF-spinnerShow');
        this.spinner.spinnerShow(this.getText('mainform_menu_print'));
        //destroy the event handler by calling the varialbe that the addhandler generated.
        var deferred = this.$q.defer();
        var prData = this.allModelDataService.getPostResponseData();
        var projectName = prData.ApplicationData.ProjectManagerData.ProjectName;

        this.jobSummaryService
            .printAllMembersPDF(prData, memberIDs)
            .then((response) => {
                var fileData = new Blob([response.data], {
                    type: 'application/pdf',
                });
                this.FileSaver.saveAs(
                    fileData,
                    projectName + '_calcpackage.pdf'
                );

                //console.log('jobSumCtrl-printAllPDF-spinnerHide');
                this.spinner.spinnerHide();
                this.printing = false;
                this.printPDFcleanup();
                deferred.resolve();
            });
        return deferred.promise;
    }

    printPDFcleanup() {
        document.body.removeChild(document.getElementById('printSection'));
        this.memberSDOs = [];

        for (var index = 0; index < this.compiledElements.length; index++) {
            this.compiledElements[index].isolateScope().$destroy();
        }
    }

    _populateGraphicObjects() {
        this.graphicsForReports = {
            count: this.memberSDOs.length,
            graphicData: [],
        };
        for (var i = 0; i < this.memberSDOs.length; i++) {
            this.graphicsForReports.graphicData.push(
                this.memberSDOs[i].GraphicOutputData
            );
        }
    }

    _replaceCanvasWithCompiledDirective(domClone) {
        this.compiledElements = [];
        this.printDirectiveIds = [];
        var currentCount = 0;
        while (domClone.getElementsByTagName('canvas').length > 0) {
            var currentCanvas = domClone.getElementsByTagName('canvas')[0];
            this.printDirectiveIds.push('printAll_preview_' + currentCount);
            var divContainer = currentCanvas.parentNode;
            var rowContainer = divContainer.parentNode;
            rowContainer.removeChild(divContainer);
            var elementToCompile =
                '<div class="col-xs-12 reportGraphic" style="height:350px;"my-horiz-graphic graphicid="jobSummaryCtrl.printDirectiveIds[' +
                currentCount +
                ']"></div>';
            var compiledElement = this.$compile(elementToCompile)(this.$scope);

            rowContainer.appendChild(compiledElement[0]);
            this.compiledElements.push(compiledElement);
            currentCount += 1;
        }
    }

    cancelPrint() {
        this.printAllUI = false;
    }

    containerPrintChanged(container) {
        if (container.print === 1) {
            container.print = 0;
        } else {
            container.print = 1;
        }
        for (var i = 0; i < container.Members.length; i++) {
            var member = container.Members[i];
            if (member.ProblemIsDesigned) {
                member.print = container.print;
            }
        }
    }

    memberPrintChanged(container, member) {
        if (member.print === 1) {
            member.print = 0;
        } else {
            member.print = 1;
        }
        var membersToPrintCount = 0;
        var designedMembersCount = 0;

        for (var i = 0; i < container.Members.length; i++) {
            var member = container.Members[i];
            if (member.print === 1) {
                membersToPrintCount += 1;
            }
            if (member.ProblemIsDesigned === true) {
                designedMembersCount += 1;
            }
        }
        // if none are selected. deselect the container check
        if (membersToPrintCount === 0) {
            container.print = 0;
        } else if (membersToPrintCount === designedMembersCount) {
            //if all are selected, select the container check
            container.print = 1;
        } else {
            //if some are selected, put it in an indeterminate state
            container.print = 2;
        }
    }

    showPrintAllInterimUI() {
        this.hiddenLevels = [];
        this.printAllUI = true;
        this.isJobSummary = false;
        this.printingAllPDF = false;
        for (var i = 0; i < this.jsData.length; i++) {
            var container = this.jsData[i];
            container.print = 0;
            if (container.hasDesignedMembers) {
                container.print = 1;
            }
            for (var j = 0; j < container.Members.length; j++) {
                var member = container.Members[j];
                member.print = 0;
                if (member.ProblemIsDesigned === true) {
                    member.print = 1;
                }
            }
        }
    }

    showPrintAllPDFInterimUI() {
        this.hiddenLevels = [];
        this.printAllUI = true;
        this.isJobSummary = false;
        this.printingAllPDF = true;
        for (var i = 0; i < this.jsData.length; i++) {
            var container = this.jsData[i];
            container.print = 0;
            if (container.hasDesignedMembers) {
                container.print = 1;
            }
            for (var j = 0; j < container.Members.length; j++) {
                var member = container.Members[j];
                member.print = 0;
                if (member.ProblemIsDesigned === true) {
                    member.print = 1;
                }
            }
        }
    }

    toggleLevelVisibility(levelIndex) {
        var foundIndex = this.hiddenLevels.indexOf(levelIndex);
        if (foundIndex > -1) {
            this.hiddenLevels.splice(foundIndex, 1);
        } else {
            this.hiddenLevels.push(levelIndex);
        }
    }

    _refreshJSData() {
        this.jsData =
            this.allModelDataService.getPostResponseData().ApplicationData.MemberManagerData.MemberContainers;
        this.designedCount = 0;
        this.validUndesignedMembers = [];
        this.memberCount = 0;
        for (var i = 0; i < this.jsData.length; i++) {
            var currentContainer = this.jsData[i];
            currentContainer.hasDesignedMembers = false;
            for (var j = 0; j < currentContainer.Members.length; j++) {
                var currentMember = currentContainer.Members[j];
                this.memberCount = this.memberCount + 1;

                if (currentMember.ProblemIsDesigned === true) {
                    this.designedCount = this.designedCount + 1;
                    currentContainer.hasDesignedMembers = true;
                } else {
                    currentMember.ProblemIsDesigned = false;
                    currentMember.DesignResult = 'nd';
                    if (currentMember.IsDesignable === true) {
                        this.validUndesignedMembers.push(currentMember);
                    }
                }
            }
        }
        this.undesignedCount = this.memberCount - this.designedCount;
    }

    _compileListOfDesignedMembers() {
        var designedMembersList = [];
        for (var i = 0; i < this.jsData.length; i++) {
            var currentLevel = this.jsData[i];
            for (var i = 0; i < currentLevel.Members.length; i++) {
                var currentMember = currentLevel.Members[i];
                if (currentMember.ProblemIsDesigned === true) {
                    designedMembersList.push(currentMember);
                }
            }
        }
    }

    _initColumnWidths() {
        this.colWidths = [];
        this.scrollIsVisible = false;
        for (var i = 0; i < 7; i++) {
            this.colWidths[i] = 0;
        }
    }
}
