export default class registerController {
    constructor(
        $scope,
        $state,
        $timeout,
        $cookies,
        authService,
        postService,
        resourceMessagingService,
        userNameValue
    ) {
        'ngInject';
        /* jshint validthis: true */
        this.$scope = $scope;
        this.$state = $state;
        this.$timeout = $timeout;
        this.$cookies = $cookies;
        this.authService = authService;
        this.postService = postService;
        this.resourceMessagingService = resourceMessagingService;
        this.userNameValue = userNameValue;

        this.savedSuccessfully = false;
        this.message = '';
        this.registration = {
            Email: this.userNameValue.user,
            Password: '',
            ConfirmPassword: '',
            FirstName: '',
            LastName: '',
            Company: '',
            Country: 'Country',
        };
        this.submitted = false;
        this.passwordsMatch = false;
        this.disableRegisterAction = false;
        this.doneRegistering = false;
        this.registrationApprovalState = 0;
        this.passwordLengthValid = false;
        this.passwordHasUppercase = false;
        this.passwordHasNumber = false;
        this.showPassword = false;
        this.showConfirmPassword = false;
        this.agreeForLicense = false;
        this.showSignUpProgressBar = false;
        this.registerFail = 'hide';
        this.otherCountriesList = [];
        this.firstNameInvalid = true;
        this.lastNameInvalid = true;
        this.namesInvalidIdentical = false;
        this.companyInvalid = true;

        this.getAllCountriesFromFile();

        //set up the occupations list
        this.occupationsList = [];
        var occupations = this.getText('occupationsList').split('|');
        this.occupationsList = this.occupationsList.concat(occupations);
        this.occupationsList.push('Occupation');
        //set the value of occupation to "Occupation" in the drop down (it's the last item in the list)
        this.registration.Occupation = (
            this.occupationsList.length - 1
        ).toString();

        this.$scope.$watch(
            () => {
                return this.registration.Password;
            },
            (password) => {
                if (password && password.length >= 6) {
                    this.passwordLengthValid = true;
                } else {
                    this.passwordLengthValid = false;
                }
                // Validate Capital Letter
                if (password && password.match(/[A-Z]/)) {
                    this.passwordHasUppercase = true;
                } else {
                    this.passwordHasUppercase = false;
                }
                // Validate number
                if (password && password.match(/\d/)) {
                    this.passwordHasNumber = true;
                } else {
                    this.passwordHasNumber = false;
                }
                if (
                    this.passwordLengthValid &&
                    this.passwordHasUppercase &&
                    this.passwordHasNumber
                ) {
                    this.showPassword = false;
                }
                if (
                    password &&
                    password.length >= 1 &&
                    (!this.passwordLengthValid ||
                        !this.passwordHasUppercase ||
                        !this.passwordHasNumber)
                ) {
                    this.showPassword = true;
                }
            }
        );
        this.$scope.$watch(
            () => {
                return this.registration.ConfirmPassword;
            },
            (ConfirmPassword) => {
                this.comparePasswords();
            }
        );
    }
    isCultureEnglish() {
        var languages = this.resourceMessagingService.getLanguageOptions();
        var i = 0;
        //var retMsg = '';

        for (i; i < languages.length; ++i) {
            if (languages[i].isSelected === true) {
                break;
            }
        }
        if (languages[i].culture === 'fr-CA') {
            return false;
        } else {
            return true;
        }
    }

    validateFirstName() {
        //for some reason, first time this function is called, this.registration.FirstName is undefined
        if (!this.registration.FirstName) {
            this.firstNameInvalid = true;
            return;
        }
        let uniqueFirst = this.getUniqueCharsFromString(
            this.registration.FirstName
        );
        this.firstNameInvalid = uniqueFirst.length <= 1;
        if (this.registration.LastName.length > 0 && uniqueFirst.length > 0) {
            this.validateNamesNotTheSame();
        }
    }
    validateLastName() {
        if (!this.registration.LastName) {
            this.lastNameInvalid = true;
            return;
        }
        let uniqueLast = this.getUniqueCharsFromString(
            this.registration.LastName
        );
        this.lastNameInvalid = uniqueLast.length <= 1;
        if (this.registration.FirstName.length > 0 && uniqueLast.length > 0) {
            this.validateNamesNotTheSame();
        }
    }
    validateNamesNotTheSame() {
        let uniqueFirst = this.getUniqueCharsFromString(
            this.registration.FirstName
        );
        let uniqueLast = this.getUniqueCharsFromString(
            this.registration.LastName
        );
        this.namesInvalidIdentical = uniqueFirst === uniqueLast;
    }
    validateCompany() {
        //for some reason, first time this function is called, this.registration.FirstName is undefined
        if (!this.registration.Company) {
            this.companyInvalid = true;
            return;
        }
        let uniqueFirst = this.getUniqueCharsFromString(
            this.registration.Company
        );
        this.companyInvalid = uniqueFirst.length <= 1;
    }

    getUniqueCharsFromString(str) {
        //strip out symbols.
        str = str.replace(/[^a-zA-Z ]/g, '');
        str = str.toLowerCase();
        let unique = [...str].reduce((previousVal, currentVal) => {
            if (previousVal.includes(currentVal)) {
                return previousVal;
            } else {
                return previousVal + currentVal;
            }
        }, '');
        return unique;
    }

    comparePasswords() {
        if (this.registration.ConfirmPassword) {
            this.showConfirmPassword = true;
        } else {
            this.showConfirmPassword = false;
        }
        if (this.registration.Password !== this.registration.ConfirmPassword) {
            this.passwordsMatch = false;
            this.showConfirmPassword = true;
        } else {
            this.passwordsMatch = true;
            this.$timeout(() => {
                this.showConfirmPassword = false;
            }, 100);
        }
    }

    isPasswordValid() {
        if (this.passwordLengthValid === false) {
            return false;
        }
        if (this.passwordHasNumber === false) {
            return false;
        }
        if (this.passwordHasUppercase === false) {
            return false;
        }
        return true;
    }

    passwordsMatchAndAreNotEmpty() {
        if (this.registration.Password) {
            if (this.registration.ConfirmPassword) {
                return (
                    this.registration.Password ===
                    this.registration.ConfirmPassword
                );
            }
        }

        return false;
    }

    goToAgreement() {
        var url = '';
        if (this.isCultureEnglish()) {
            url = 'http://eula.weyerhaeusersoftware.com';
        } else {
            url = 'http://eula.weyerhaeusersoftware.com/licensefrench';
        }
        var win = window.open(url, '_blank');
        win.focus();
    }

    toggleCheckbox(e) {
        if (e.keyCode === 32) {
            this.agreeForLicense = !this.agreeForLicense;
        }
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    signUpReady() {
        if (this.isPasswordValid() === false) {
            return false;
        }
        if (this.passwordsMatchAndAreNotEmpty() === false) {
            return false;
        }
        if (this.agreeForLicense === false) {
            return false;
        }
        if (this.passwordLengthValid === false) {
            return false;
        }
        if (this.passwordHasUppercase === false) {
            return false;
        }
        if (this.passwordHasNumber === false) {
            return false;
        }
        if (this.firstNameInvalid === true) {
            return false;
        }
        if (this.lastNameInvalid === true) {
            return false;
        }
        if (this.namesInvalidIdentical === true) {
            return false;
        }
        if (
            this.registration.Occupation !== '6' &&
            this.registration.Occupation !== '10' &&
            this.companyInvalid === true
        ) {
            return false;
        }
        if (
            this.registration.Occupation ===
            (this.occupationsList.length - 1).toString()
        ) {
            return false;
        }
        if (
            this.registration.Country === this.otherCountriesList[0].toString()
        ) {
            return false;
        }

        return true;
    }

    signUp(hasValidEmail) {
        //vm.submitted = true;

        if (!hasValidEmail) {
            return;
        }
        if (this.signUpReady() === false) {
            return;
        }
        this.registerFail = '';
        this.disableRegisterAction = true;
        this.showSignUpProgressBar = true;
        this.authService
            .saveRegistration(this.registration)
            .then((response) => {
                this.showSignUpProgressBar = false;
                this.savedSuccessfully = true;
                //vm.message = 'Great! We will send an email to confirm your email address.' ;
                this.doneRegistering = true;
                //registrationApprovalState:
                //1 = approved
                //2 = flagged by watchdog(international)
                //3 = rejected email
                //4 = already registered (approved)
                this.registrationApprovalState = response.data;
            })
            .catch((response) => {
                var errors = [];
                if (response && response.data && response.data.ModelState) {
                    for (var key in response.data.ModelState) {
                        if (
                            {}.hasOwnProperty.call(
                                response.data.ModelState,
                                key
                            )
                        ) {
                            for (
                                var i = 0;
                                i < response.data.ModelState[key].length;
                                i++
                            ) {
                                errors.push(response.data.ModelState[key][i]);
                            }
                        }
                    }
                    this.message =
                        this.getText('failedToRegisterUser') + errors.join(' ');
                } else if (response && response.data && response.data === 'underConstruction') {
                    window.location.replace('underconstruction.html');
                } else {
                    this.message = this.getText('sometingWentWrong');
                }
                this.registerFail = 'show';
                this.showSignUpProgressBar = false;
                this.disableRegisterAction = false;
            });
    }

    getAllCountriesFromFile() {
        this.postService
            .requestSimplePost('api/Account/GetCountriesList', '')
            .then((response) => {
                this.otherCountriesList.push('Country');
                this.otherCountriesList = this.otherCountriesList.concat(
                    response.CountriesList
                );
            });
    }

    startTimer() {
        var timer = this.$timeout(() => {
            this.$timeout.cancel(timer);
            this.$state.go('account.emailSent');
        }, 4000);
    }

    showPasswordRules() {
        this.showPassword = true;
    }

    hidePasswordRules() {
        this.showPassword = false;
    }

    showConfirmPasswordRules() {
        this.showConfirmPassword = true;
    }

    hideConfirmPasswordRules() {
        this.showConfirmPassword = false;
    }
}
