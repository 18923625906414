export default class reportDamageController {
    constructor ($scope, $uibModalInstance, $uibModal, common, resourceMessagingService) {
        ///NOTE: this does not follow convention.  normally we would write
        //var vm = this;
        //but for some reason, this modal controller will not work this way.
        //it could be because of the way it is injected in the modal.open method.
        'ngInject';

        this.$q = common.$q();

        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        this.$uibModal = $uibModal;
        this.resourceMessagingService = resourceMessagingService;
        this.damageData;
        this.reportDamageSubmitted = false;

        this.activate();
    }

    activate() {
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    reportDamageCancelClicked() {
        this.$uibModalInstance.dismiss('cancel');
    }

    reportDamageOKClicked(isValid) {
        this.reportDamageSubmitted = true;
        if (!isValid) {
            return;
        }
        this.$uibModalInstance.close(this.damageData);
    }

    helpClicked() {
        window.open('/helpmobile/Content/A_ForteWEB%20Overview/02_training_support.htm', '_blank');
    }

    preventInputs(keyPressEvent) {
        if (keyPressEvent.which  === 8 || keyPressEvent.which  === 0) {
            // backspace, null
            return;
        }
        if (keyPressEvent.which < 48 || keyPressEvent.which > 57) {
            // numbers 0-9 OK
            keyPressEvent.preventDefault();
        }
    }
}
// (function () {
//     'use strict';
//     var app = angular.module('app');

//     app.controller('reportDamageController',
//         ['$rootScope', '$scope', '$uibModalInstance', '$uibModal', 'common', '$timeout', 'loadsHorizYService',
//             'programSettingsService', 'resourceMessagingService', 'quickLoadsService', 'allModelDataService', 'dirtyCheckService',
//             function ($rootScope, $scope, $uibModalInstance, $uibModal, common, $timeout, loadsHorizYService,
//                 programSettingsService, resourceMessagingService, quickLoadsService, allModelDataService, dirtyCheckService) {
//                 ///NOTE: this does not follow convention.  normally we would write
//                 //var vm = this;
//                 //but for some reason, this modal controller will not work this way.
//                 //it could be because of the way it is injected in the modal.open method.
//                 var vm = $scope;

//                 var $q = common.$q;

//                 vm.getText = getText;
//                 vm.reportDamageCancelClicked = reportDamageCancelClicked;
//                 vm.reportDamageOKClicked = reportDamageOKClicked;
//                 vm.helpClicked = helpClicked;
//                 vm.preventInputs = preventInputs;
//                 vm.damageData;
//                 vm.reportDamageSubmitted = false;

//                 activate();

//                 function activate() {
//                 }

//                 function getText(key) {
//                     return resourceMessagingService.getText(key);
//                 }

//                 function reportDamageCancelClicked() {
//                     $uibModalInstance.dismiss('cancel');
//                 }

//                 function reportDamageOKClicked(isValid) {
//                     vm.reportDamageSubmitted = true;
//                     if (!isValid) {
//                         return;
//                     }
//                     $uibModalInstance.close(vm.damageData);
//                 }

//                 function helpClicked() {
//                     window.open('/helpmobile/Content/A_ForteWEB%20Overview/02_training_support.htm', '_blank');
//                 }

//                 function preventInputs(keyPressEvent) {
//                     if (keyPressEvent.which  === 8 || keyPressEvent.which  === 0) {
//                         // backspace, null
//                         return;
//                     }
//                     if (keyPressEvent.which < 48 || keyPressEvent.which > 57) {
//                         // numbers 0-9 OK
//                         keyPressEvent.preventDefault();
//                     }
//                 }

//             }]);
// }());
