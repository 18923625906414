export default class windLoadingController {

    constructor ($scope, allModelDataService, resourceMessagingService, seismicLoadingService) {
        'ngInject';

        this.$scope = $scope;
        this.allModelDataService = allModelDataService;
        this.resourceMessagingService = resourceMessagingService;
        this.seismicLoadingService = seismicLoadingService;

        this.seismicOmegaInput;
        this.seismicSDSInput;
        this.seismicCategoryInput;

        this.activate();
    }

    activate() {
        this.seismicCategoryOptions = [
            { 'key': 1, 'text': 'A' },
            { 'key': 2, 'text': 'B' },
            { 'key': 3, 'text': 'C' },
            { 'key': 4, 'text': 'D' },
            { 'key': 5, 'text': 'E' },
            { 'key': 6, 'text': 'F' }
        ];
        this.setSeismicData();
    }

    seismicOmegaChanged() {
        this.seismicLoadingService.seismicOmegaChanged(Number(this.seismicOmegaInput)).then(
            (response) => {
                this.setSeismicData();
            }
        );
    }

    seismicSDSChanged() {
        this.seismicLoadingService.seismicSDSChanged(Number(this.seismicSDSInput)).then(
            (response) => {
                this.setSeismicData();
            }
        );
    }

    seismicCategoryChanged() {
        this.seismicLoadingService.seismicCategoryChanged(Number(this.seismicCategoryInput)).then(
            (response) => {
                this.setSeismicData();
            }
        );
    }

    setSeismicData() {
        var prData = this.allModelDataService.getPostResponseData();
        this.seismicOmegaInput = prData.ApplicationData.JobSeismicLoadingData.OverStrengthOmega.toFixed(1);
        this.seismicSDSInput = prData.ApplicationData.JobSeismicLoadingData.OverStrengthSDS.toFixed(3);
        this.seismicCategoryInput = prData.ApplicationData.JobSeismicLoadingData.DesignCategory;
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }
}
