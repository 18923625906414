// Code has some changes to match version 2.1, I have update the new changes in webpack version so it matches v2.1(KM)
export default class jobSettingsController {
    constructor ($rootScope, $scope, $location, $timeout, jobSettingsService, resourceMessagingService, allModelDataService, fileSystemService, smartNavService, postService) {
        //controller code goes here.
        /* jshint validthis: true */
        'ngInject';
        /* jshint validthis: true */
        this.metadata = null;
        this.connectorMfgSelected = null;
        this.LDFFloorSelected = null;
        this.LDFRoofSelected = null;
        this.LDFSnowSelected = null;
        this.LDFWindSelected = null;
        this.LDFSeismicSelected = null;

        //this.isJobSettingsDataInitialized = false;
        this.jobSettingsData = null;
        this.isNBCC = false;
        this.buildingCodeTypeSelected = null;
        this.buildingCodeTypeDisabled = false;
        this.ibcBuildingCodeSelected = null;
        this.nbccProvinceSelected = null;
        this.provincialCodeSelected = null;
        this.provincialCodesList = null;

        this.canChangeBuildingCode = true;
        this.projectManagerData = null;
        this.displaySaved = false;

        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$location = $location;
        this.$timeout = $timeout;
        this.jobSettingsService = jobSettingsService;
        this.resourceMessagingService = resourceMessagingService;
        this.allModelDataService = allModelDataService;
        this.fileSystemService = fileSystemService;
        this.smartNavService = smartNavService;
        this.postService = postService;

        this.activate();
    }

    activate() {
        //this.isJobSettingsDataInitialized = false;
        this.$scope.$emit('showGraphicToggle', false);
        this.jobSettingsService.getMetadata().then((response) => {
            this.metadata = response;
            if (this.metadata.BuildingCodeTypeList.length === 1) {
                this.buildingCodeTypeDisabled = true;
            } else {
                this.buildingCodeTypeDisabled = false;
            }
            this.getJobSettingsData();
            this.getProjectManagerData();
            //this.isJobSettingsDataInitialized = true;
        });
    }

    // Get Data
    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    getProjectManagerData() {
        this.projectManagerData = this.jobSettingsService.getProjectManagerData();
    }

    modifyPostalCode() {
        this.hideSavedMsg();
        var data = {
            'value': this.jobSettingsData.PostalCode
        };

        this.jobSettingsService.modifyPostalCode(data)
            .then((serviceResponse) => {
                this.getJobSettingsData();
            });
    }

    modifyJobNotes() {
        this.hideSavedMsg();
        var data = {
            'value': this.jobSettingsData.JobNotes
        };

        this.jobSettingsService.modifyJobNotes(data)
            .then((serviceResponse) => {
                this.getJobSettingsData();
            });
    }

    modifySoftwareOperator() {
        this.hideSavedMsg();
        var data = {
            'value': this.jobSettingsData.SoftwareOperator
        };

        this.jobSettingsService.modifySoftwareOperator(data)
            .then((serviceResponse) => {
                this.getJobSettingsData();
            });
    }

  // Building Code / Provincial Code processing

    buildingCodeTypeChanged() {
        this.allModelDataService.setBuildingCodeListType(this.buildingCodeTypeSelected);
        if (this.buildingCodeTypeSelected === 1) {
            // NBCC
            this.isNBCC = true;
            this.nbccProvinceSelected = this.metadata.ProvinceNameList[0];
            this.nbccProvinceChanged();
        } else {
            // IBC
            this.isNBCC = false;
            this.ibcBuildingCodeSelected = this.metadata.IBCBuildingCodeList[0].Key;
            this.allModelDataService.saveIBCBuildingCode(this.ibcBuildingCodeSelected);
        }
        this.$scope.$parent.$broadcast('updateVisibleTabs', 'buildingCodeTypeChanged');
    }

    ibcBuildingCodeChanged() {
        this.hideSavedMsg();
        var data = {
            'value': this.ibcBuildingCodeSelected
        };
        this.jobSettingsService
            .modifyBuildingCode(data)
            .then((response) => {
                this.getJobSettingsData();
            });
    }

    nbccProvinceChanged() {
        this.provincialCodesList =
            this.metadata.NBCCProvincialBuildingCodeList[this.nbccProvinceSelected];
        this.provincialCodeSelected = this.provincialCodesList[0];
        this.nbccProvincialCodeChanged();
    }

    nbccProvincialCodeChanged() {
        this.hideSavedMsg();
        const nbccBuildingCode = this.metadata.NBCCPAEBuildingCodeList[this.provincialCodeSelected];
        var data = {
            'provinceSelected': this.nbccProvinceSelected,
            'provincialCodeSelected': this.provincialCodeSelected,
            'nbccBuildingCode': nbccBuildingCode
        };
        this.jobSettingsService
            .modifyNBCCProvincialCodeData(data)
            .then((response) => {
                this.getJobSettingsData();
            });
    }

    getNBCCProvinceNameText(provinceName) {
        if (provinceName === 'nbccnone') {
            return this.resourceMessagingService.getText(provinceName);
        } else {
            // Actual Province Names NOT translated
            return provinceName;
        }
    }

    getNBCCProvinceCodeText(provinceCode) {
        if (provinceCode.startsWith('nbcc') || provinceCode.startsWith('obc')) {
            return this.resourceMessagingService.getText(provinceCode);
        } else {
            // Actual Province Codes NOT translated
            return provinceCode;
        }
    }

    chkVibrationModificationChanged() {
        var data = {
            'value': this.jobSettingsData.VibrationModification
        };
        this.jobSettingsService.modifyVibrationModification(data)
            .then((serviceResponse) => {
                this.getJobSettingsData();
            });
    }

    selectedConnectorMfgChanged() {
        this.hideSavedMsg();
        var data = {
            'value': this.connectorMfgSelected
        };
        this.jobSettingsService.modifyConnectorMfg(data)
            .then((serviceResponse) => {
                this.getJobSettingsData();
            });
    }

    selectedLDFFloorChanged() {
        this.hideSavedMsg();
        var data = {
            'typeValue': 4,
            'factorValue': this.LDFFloorSelected
        };
        this.jobSettingsService.modifyLDFFloor(data)
            .then((serviceResponse) => {
                this.getJobSettingsData();
            });
    }

    selectedLDFRoofChanged() {
        this.hideSavedMsg();
        var data = {
            'typeValue': 3,
            'factorValue': this.LDFRoofSelected
        };
        this.jobSettingsService.modifyLDFRoof(data)
            .then((serviceResponse) => {
                this.getJobSettingsData();
            });
    }

    selectedLDFSnowChanged() {
        this.hideSavedMsg();
        var data = {
            'typeValue': 0,
            'factorValue': this.LDFSnowSelected
        };
        this.jobSettingsService.modifyLDFSnow(data)
            .then((serviceResponse) => {
                this.getJobSettingsData();
            });
    }

    selectedLDFWindChanged() {
        this.hideSavedMsg();
        var data = {
            'typeValue': 1,
            'factorValue': this.LDFWindSelected
        };
        this.jobSettingsService.modifyLDFWind(data)
            .then((serviceResponse) => {
                this.getJobSettingsData();
            });
    }

    selectedLDFSeismicChanged() {
        this.hideSavedMsg();
        var data = {
            'typeValue': 2,
            'factorValue': this.LDFSeismicSelected
        };
        this.jobSettingsService.modifyLDFSeismic(data)
            .then((serviceResponse) => {
                this.getJobSettingsData();
            });
    }

    saveDefaultJobSettings() {
        this.displaySaved = true;
        this.jobSettingsService.saveJobSettingsUserDefaults();
    }

    getJobSettingsData() {
        this.jobSettingsData = this.jobSettingsService.getJobSettingsData();
        this.connectorMfgSelected = this.jobSettingsData.ConnectorMfg;
        this.LDFFloorSelected = this.jobSettingsData.LDFFloor;
        this.LDFRoofSelected = this.jobSettingsData.LDFRoof;
        this.LDFSnowSelected = this.jobSettingsData.LDFSnow;
        this.LDFWindSelected = this.jobSettingsData.LDFWind;
        this.LDFSeismicSelected = this.jobSettingsData.LDFSeismic;

        this.setBuildingCodeSpecificVariables();
    }

    setBuildingCodeSpecificVariables() {
        switch (this.jobSettingsData.BuildingCodeForteWEB) {
            case 18:
            case 23:
            case 24:
                // NBCC
                this.isNBCC = true;
                this.buildingCodeTypeSelected = 1;
                this.nbccProvinceSelected = this.jobSettingsData.ProvinceSelected;
                this.provincialCodesList =
                    this.metadata.NBCCProvincialBuildingCodeList[this.nbccProvinceSelected];
                this.provincialCodeSelected = this.jobSettingsData.ProvincialCode;
                break;

            default:
                // IBC
                this.isNBCC = false;
                this.buildingCodeTypeSelected = 0;
                this.ibcBuildingCodeSelected = this.jobSettingsData.BuildingCodeForteWEB;
                this.nbccProvinceSelected = 'nbccnone';
                this.provincialCodeSelected = 'nbcc2015';
                break;
        }
        this.allModelDataService.setBuildingCodeListType(this.buildingCodeTypeSelected);
    }

    changeBuildingCode() {
        var prData = this.allModelDataService.getPostResponseData();
        var memberContainers = prData.ApplicationData.MemberManagerData.MemberContainers;
        for (var i = 0; i < memberContainers.length; i++) {
            if (memberContainers[i].Members.length > 0) {
                this.canChangeBuildingCode = false;
            }
        }
        return this.canChangeBuildingCode;
    }

    hideSavedMsg() {
        if (this.displaySaved) {
            this.displaySaved = false;
        }
    }
}
