export default class solutionsService {
    constructor(common, allModelDataService, postService) {
        'ngInject';

        this.$q = common.$q();
        this.allModelDataService = allModelDataService;
        this.postService = postService;
    }

    changeSort(index) {
        var data = {
            'integerValue': index,
            'solutionsList': this.allModelDataService.getSolutionsList()
        };
        return this._requestPostEvent('ChangeSort', data);
    }

    getSolutionsData(suggestSolutions) {
        var data = {
            'value': suggestSolutions
        };
        return this._requestPostEvent('GetSolutionsData', data);
    }

    getVerticalSolutionsData(suggestSolutions) {
        var data = {
            'value': suggestSolutions
        };
        return this._requestPostEvent('GetVerticalSolutionsData', data);
    }

    setSelectedSolution(depth, plies, productId, spacing, isPlank, suggestSolutions) {
        var data = {
            'depth': depth,
            'plies': plies,
            'productId': productId,
            'spacing': spacing,
            'isPlank': isPlank,
            'isAlternateProductSuggestionsOn': suggestSolutions
        };
        return this._requestPostEvent('SetSelectedSolution', data);
    }

    setSelectedVerticalSolution(depth, plies, productId, spacing, isPlank, suggestSolutions) {
        var data = {
            'depth': depth,
            'plies': plies,
            'productId': productId,
            'spacing': spacing,
            'isPlank': isPlank,
            'isAlternateProductSuggestionsOn': suggestSolutions
        };
        return this._requestPostEvent('SetSelectedVerticalSolution', data);
    }

    getSolutionsPDF(graphicData, graphicOverlayData, allProductSolutions) {
        var prData = this.allModelDataService.getPostResponseData();
        //var horizReportDTO = prData.MemberData.ReportOutputHorizontalData.HorizontalMemberReportData;
        //horizReportDTO.MiscellaneousData[0].MemberGraphic = graphicData;
        var data = {
            graphicData: graphicData,
            graphicOverlayData: {Labels: graphicOverlayData},
            postResponseData: prData,
            allProductSolutions: allProductSolutions
        };

        return this.postService.requestSolutionsPDFPostEvent(data);
    }

    _requestPostEvent(event, data) {
        var path = 'api/Solutions/';
        return this.postService.requestPostEvent(event,data,path);
    }
}
// (function () {
//     'use strict';
//     var app = angular.module('app');
//     var serviceId = 'solutionsService';
//     app.factory(serviceId, ['$http', 'ngAuthSettings', 'common', 'allModelDataService','postService', solutionsService]);

//     function solutionsService($http, ngAuthSettings, common, allModelDataService, postService) {
//         var $q = common.$q;
//         var serviceBase = ngAuthSettings.apiServiceBaseUri;
//         var httpRequestQueueCounter = 0;
//         var inputQueuePromise;
//         var somethingWaitingOnQueueToFinish = false;
//         //this is the declaration of the public methods that will be accessible
//         var service = {
//             getSolutionsData: getSolutionsData,
//             getVerticalSolutionsData: getVerticalSolutionsData,
//             setSelectedSolution: setSelectedSolution,
//             setSelectedVerticalSolution: setSelectedVerticalSolution,
//             changeSort: changeSort,
//             getSolutionsPDF: getSolutionsPDF
//         };

//         function getSolutionsPDF(graphicData, graphicOverlayData, allProductSolutions) {
//             var prData = allModelDataService.getPostResponseData();
//             //var horizReportDTO = prData.MemberData.ReportOutputHorizontalData.HorizontalMemberReportData;
//             //horizReportDTO.MiscellaneousData[0].MemberGraphic = graphicData;
//             var data = {
//                 graphicData: graphicData,
//                 graphicOverlayData: {Labels: graphicOverlayData},
//                 postResponseData: prData,
//                 allProductSolutions: allProductSolutions
//             };

//             return postService.requestSolutionsPDFPostEvent(data);
//         }

//         function changeSort(index) {
//             var data = {
//                 'integerValue': index,
//                 'solutionsList': allModelDataService.getSolutionsList()
//             };
//             return _requestPostEvent('ChangeSort', data);
//         }

//         function getSolutionsData() {
//             var data = {};
//             return _requestPostEvent('GetSolutionsData', data);
//         }

//         function getVerticalSolutionsData() {
//             var data = {};
//             return _requestPostEvent('GetVerticalSolutionsData', data);
//         }

//         function setSelectedSolution(depth, plies, productId, spacing, isPlank) {
//             var data = {
//                 'depth': depth,
//                 'plies': plies,
//                 'productId': productId,
//                 'spacing': spacing,
//                 'isPlank': isPlank
//             };
//             return _requestPostEvent('SetSelectedSolution', data);
//         }

//         function setSelectedVerticalSolution(depth, plies, productId, spacing, isPlank) {
//             var data = {
//                 'depth': depth,
//                 'plies': plies,
//                 'productId': productId,
//                 'spacing': spacing,
//                 'isPlank': isPlank
//             };
//             return _requestPostEvent('SetSelectedVerticalSolution', data);
//         }

//         function _requestPostEvent(event, data) {
//             var path = 'api/Solutions/';
//             return postService.requestPostEvent(event,data,path);
//         }

//         return service;
//     }
// }());
