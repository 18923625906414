export default class addTeamMemberController {
    // constructor($scope, $uibModalInstance, $uibModal, common, resourceMessagingService, teamService) {
    constructor($scope, $uibModalInstance, $uibModal, resourceMessagingService) {
        'ngInject';

        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        this.$uibModal = $uibModal;
        this.resourceMessagingService = resourceMessagingService;

        this.submitted = false;
        this.email = '';

        this.activate();
    }

    activate() { }

    addMember(isValid) {
        this.submitted = true;
        if (isValid) {
            this.$uibModalInstance.close(this.$scope.email);
        }
    }

    getText(key) {
        return this.resourceMessagingService.getText(key);
    }

    cancelClicked() {
        this.$uibModalInstance.dismiss('cancel');
    }
}
// var test;
// (function () {
//     'use strict';
//     var app = angular.module('app');

//     app.controller('addTeamMemberController',
//         ['$rootScope', '$scope', '$uibModalInstance', '$uibModal', 'common',
//             'resourceMessagingService','teamService',
//             function ($rootScope, $scope, $uibModalInstance, $uibModal, common,
//                 resourceMessagingService, teamService) {
//                 ///NOTE: this does not follow convention.  normally we would write
//                 //var vm = this;
//                 //but for some reason, this modal controller will not work this way.
//                 //it could be because of the way it is injected in the modal.open method.
//                 var vm = $scope;

//                 var $q = common.$q;
//                 vm.getText = resourceMessagingService.getText;
//                 vm.addMember = addMember;
//                 vm.cancelClicked = cancelClicked;
//                 vm.submitted = false;
//                 vm.email = '';

//                 function activate() {

//                 }

//                 function addMember(isValid) {
//                     vm.submitted = true;
//                     if (isValid) {
//                         $uibModalInstance.close(vm.email);
//                     }
//                 }
//                 function cancelClicked() {
//                     $uibModalInstance.dismiss('cancel');
//                 }

//                 activate();

//             }]);
// }());
