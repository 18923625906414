//Info about this directive
//it passes a function from the controller, so when the rename is finished, it will call
//the instantiating controllers method passing the arguments that we've generated inside this
//directive. e.g. scope.renamecomplete()(event);
import renameInPlaceTemplate from './renameInPlace.html';
export default class renameInPlace {
    constructor() {
        this.template = renameInPlaceTemplate;
        this.restrict = 'E';
        this.scope = {
            isrenaming: '=isrenaming',
            showedit: '=showedit',
            node: '=node',
            renamecomplete: '=',
            parent: '='
        };
    }
    //pass in $scope and attach injected elements to $scope.
    //then use scope. instead of this. when referencing injected elements.
    controller($scope, $timeout, allModelDataService, mobileNavMenu) {
        'ngInject';
        $scope.$timeout = $timeout;
        $scope.allModelDataService = allModelDataService;
        $scope.mobileNavMenu = mobileNavMenu;
    }
    //Info about this directive
    //it passes a function from the controller, so when the rename is finished, it will call
    //the instantiating controllers method passing the arguments that we've generated inside this
    //directive. e.g. scope.renamecomplete()(event);
    link(scope, element, attrs) {
        var renameOriginalName;
        scope.editNameButtonClicked = editNameButtonClicked;
        scope.finished = finished;
        scope.hasTransitionLink = hasTransitionLink;
        scope.statusOfTransitionLink = statusOfTransitionLink;
        scope.isLevelWithoutMember = isLevelWithoutMember;
        scope.scrollToEnd = scrollToEnd;
        scope.getJobTreeIcon = getJobTreeIcon;
        scope.isLevelNode = isLevelNode;
        scope.isJobNode = isJobNode;

        this.renameFormSubmitted = false;

        scope.$on('editAddedJobTreeNode', (event, data) => {
            if (scope.node.id === data.id) {
                scope.isrenaming = true;
                renameOriginalName = data.text;
                scope.showedit = true;
                _selectAllTextInRenameTextbox();
            }
        });

        function editNameButtonClicked(event) {
            scope.isrenaming = true;
            renameOriginalName = scope.node.text;
            scope.showedit = true;
            _selectAllTextInRenameTextbox();
            event.stopPropagation();
        }
        function hasTransitionLink() {
            var prData = scope.allModelDataService.getPostResponseData();
            var linkedReactions = prData.ApplicationData.MemberManagerData.MemberTagToLinkedReactionDictionary;
            if (linkedReactions !== null && scope.node.isLeafNode) {
                if (linkedReactions[scope.node.idTag]) {
                    if (linkedReactions[scope.node.idTag].FromReactions.length > 0) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
            } else {
                return false;
            }
        }
        function isLevelWithoutMember() {
            if (scope.node.id.length === 3 && scope.node.children.length === 0) {
                return true;
            } else {
                return false;
            }
        }
        function statusOfTransitionLink() {
            if (scope.node.isLeafNode) {
                if (scope.node.waitingOnDesignOfLinkedMember) {
                    return 'changeToOrange';
                }
                else {
                    return 'changeToBlack';
                }
            }
        }
        function finished(event) {
            scope.showedit = false;
            if (this.editNameForm.$valid &&
                this.editNameForm.$dirty &&
                scope.node.text.trim() !== '') {
                //tell the controller
                scope.parent.renameComplete(event, scope.node);
            } else {
                //reset the value
                scope.node.text = renameOriginalName;
                scope.isrenaming = false;
            }
        }
        function _selectAllTextInRenameTextbox() {
            //this timeout fires immediately after digest cycle completes.
            scope.$timeout(() => {
                var elementToSelctText = document.getElementById('editInPlaceRenameTextbox');
                elementToSelctText.select();
            },0,false);//pass false here to prevent another digest cycle from firing.
        }
        function scrollToEnd() {
            this.mobileNavMenu.scrollToEnd();
        }
        function getJobTreeIcon() {
            switch (scope.node.icon) {
                case 'jobNode' :
                    return 'forteIcon-job-file';
                case 'level' :
                    return 'forteIcon-level';
                case 'column' :
                    return 'forteIcon-wall_column';
                case 'post':
                    return 'forteIcon-post_free-standing';
                case 'stud' :
                    return 'forteIcon-wall_stud';
                case 'floorFlushBeam' :
                    return 'forteIcon-floor_flush-beam';
                case 'roofFlushBeam':
                    return 'forteIcon-roof_flush-beam';
                case 'roofDropBeam':
                    return 'forteIcon-roof_drop-beam';
                case 'floorDropBeam':
                    return 'forteIcon-floor_drop-beam';
                case 'hipBeam':
                    return 'forteIcon-roof_hip-valley';
                case 'header':
                    return 'forteIcon-wall_header';
                case 'windSill':
                    return 'forteIcon-wall_wind-still';
                case 'roofJoist' :
                    return 'forteIcon-roof_joist';
                case 'floorJoist' :
                    return 'forteIcon-floor_joist';
                default :
                    return 'forteIcon-floor_joist';
            }
        }
        function isLevelNode() {
            if (scope.node.icon === 'level') {
                return true;
            } else {
                return false;
            }
        }
        function isJobNode() {
            if (scope.node.icon === 'jobNode') {
                return true;
            } else {
                return false;
            }
        }
    }
}
// (function () {
//     'use strict';
//     angular.module('app').directive('renameInPlace', ['$timeout', 'allModelDataService',function($timeout, allModelDataService) {
//         return {
//             restrict: 'E',
//             scope: {
//                 isrenaming: '=isrenaming',
//                 showedit: '=showedit',
//                 node: '=node',
//                 renamecomplete: '&'
//             },
//             templateUrl: 'app/directives/renameInPlace.html',
//             link: function(scope, element, attrs) {

//                 var renameOriginalName;
//                 scope.editNameButtonClicked = editNameButtonClicked;
//                 scope.finished = finished;
//                 scope.hasTransitionLink = hasTransitionLink;
//                 scope.statusOfTransitionLink = statusOfTransitionLink;
//                 scope.isLevelWithoutMember = isLevelWithoutMember;

//                 scope.renameFormSubmitted = false;

//                 function editNameButtonClicked(event) {
//                     scope.isrenaming = true;
//                     renameOriginalName = scope.node.text;
//                     scope.showedit = true;
//                     _selectAllTextInRenameTextbox();
//                     event.stopPropagation();
//                 }
//                 function hasTransitionLink() {
//                     var prData = allModelDataService.getPostResponseData();
//                     var linkedReactions = prData.ApplicationData.MemberManagerData.MemberTagToLinkedReactionDictionary;
//                     if (linkedReactions !== null && scope.node.isLeafNode) {
//                         if (linkedReactions[scope.node.idTag]) {
//                             if (linkedReactions[scope.node.idTag].FromReactions.length > 0) {
//                                 return true;
//                             }
//                             else {
//                                 return false;
//                             }
//                         }
//                     } else {
//                         return false;
//                     }
//                 }
//                 function isLevelWithoutMember() {
//                     if (scope.node.id.length === 3 && scope.node.children.length === 0) {
//                         return true;
//                     } else {
//                         return false;
//                     }
//                 }
//                 function statusOfTransitionLink() {
//                     if (scope.node.isLeafNode) {
//                         if (scope.node.waitingOnDesignOfLinkedMember) {
//                             return 'changeToOrange';
//                         }
//                         else {
//                             return 'changeToBlack';
//                         }
//                     }
//                 }
//                 function finished(event) {
//                     scope.showedit = false;
//                     if (this.editNameForm.$valid &&
//                         this.editNameForm.$dirty &&
//                         scope.node.text.trim() !== '') {
//                         //tell the controller
//                         scope.renamecomplete()(event, scope.node);
//                     } else {
//                         //reset the value
//                         scope.node.text = renameOriginalName;
//                         scope.isrenaming = false;
//                     }
//                 }
//                 function _selectAllTextInRenameTextbox() {
//                     //this timeout fires immediately after digest cycle completes.
//                     $timeout(function() {
//                         var elementToSelctText = document.getElementById('editInPlaceRenameTextbox');
//                         elementToSelctText.select();
//                     },0,false);//pass false here to prevent another digest cycle from firing.
//                 }
//             }
//         };
//     }]);
// }());
